export const CHANGE_ASSETTYPE_TAB = "CHANGE_ASSETTYPE_TAB";
export const CLOSE_ASSETTYPE_DETAILS_PANEL = "CLOSE_ASSETTYPE_DETAILS_PANEL";

//DASHBOARD
export const CLEAN_ASSETTYPE_DASHBOARD = 'CLEAN_ASSETTYPE_DASHBOARD';
export const LOAD_ASSETTYPE_DASHBOARD = 'LOAD_ASSETTYPE_DASHBOARD';
export const FIX_ASSETTYPE_DASHBOARD = 'FIX_ASSETTYPE_DASHBOARD';
export const SORT_ASSETTYPE_DASHBOARD = 'SORT_ASSETTYPE_DASHBOARD';
export const OPEN_CLOSE_ASSET_TYPES_ALERT_MODAL = 'OPEN_CLOSE_ASSET_TYPES_ALERT_MODAL';
export const TOGGLE_ASSETTYPE_VIEW = 'TOGGLE_ASSETTYPE_VIEW';
export const CHANGE_ASSETTYPE_NAME_FILTER_VALUE = 'CHANGE_ASSETTYPE_NAME_FILTER_VALUE';

//PANEL
export const LOAD_ASSETTYPE_ATTRIBUTES = 'LOAD_ASSETTYPE_ATTRIBUTES';

// DELETE
export const OPEN_CLOSE_ASSETTYPE_CONFIRM_MODAL = 'OPEN_CLOSE_ASSETTYPE_CONFIRM_MODAL';
export const DELETE_ASSET_TYPE = 'DELETE_ASSET_TYPE';

//Description
export const CHANGE_ASSETTYPE_NAME = 'CHANGE_ASSETTYPE_NAME';
export const CHANGE_ASSETTYPE_ACRONYM_CODE = 'CHANGE_ASSETTYPE_ACRONYM_CODE';
export const CHANGE_ASSETTYPE_GROUP_NAME = 'CHANGE_ASSETTYPE_GROUP_NAME';
export const CHANGE_ASSETTYPE_CATEGORY = 'CHANGE_ASSETTYPE_CATEGORY';
export const CHANGE_ASSETTYPE_DESCRIPTION = 'CHANGE_ASSETTYPE_DESCRIPTION';
export const CHANGE_ASSETTYPE_DISPLAY_NAME = 'CHANGE_ASSETTYPE_DISPLAY_NAME';

//PREVIEW
export const CHANGE_ASSETTYPE_FORM_ATTRIBUTE = 'CHANGE_ASSETTYPE_FORM_ATTRIBUTE';

// SAVE
export const SAVE_ASSETTYPE_DETAILS = 'SAVE_ASSETTYPE_DETAILS';

//URL
export const GET_ASSETTYPES_URL = "Setting";
export const GET_FORM_ATTRIBUTE_LIST = "Setting/FormObject";

export const ASSET_TYPE_TABS = {
    0: "DESCRIPTION",
    1: "ATTRIBUTE",
    2: "FILES",
};


export const ASSET_TYPE_BREADCRUMBS = [
    { text: 'SETTINGS', key: 'Settings' },
    { text: 'Asset Types', key: 'AssetTypes' }
];


export const ASSET_TYPE_CATEGORY = [
    { value: 'MACHINERY', label: 'MACHINERY' },
    { value: 'BTH&PPE', label: 'BTH&PPE' },
    { value: 'TRAINEE', label: 'TRAINEE' },
];

