export const GET_DATA_URL = `CustomerRegistration`;
export const GET_COUNT_URL = `CustomerRegistration/count`;
export const ADD_UPDATE_DELETE_COUNT_URL = `CustomerRegistration`;
export const UPLOAD_CUSTOMER_IMAGE_URL = `CustomerRegistration/uploadImage`;
export const GET_CUSTOMER_IMAGE_URL = `CustomerRegistration/getImage`;

export const UserTypeChoice = [
    { key: 0, text: 'Lite User' },
    { key: 1, text: 'Non Lite User' },
];

export const ProjectPhaseChoice = [
    { key: 'STRUCTURAL', text: 'STRUCTURAL' },
    { key: 'FINISHING', text: 'FINISHING' },
    { key: 'SNAGGING', text: 'SNAGGING' },
    { key: 'INTERIOR', text: 'INTERIOR', disabled: true },
];