import * as React from 'react';
import { Breadcrumb, Label, Dropdown } from 'office-ui-fabric-react';
import { ASSESSMENT_BREADCRUMBS } from '../Constants/AssessmentsConstants';
import { connect } from 'react-redux';
import { IAssessmentStateProps } from '../Interfaces/IAssessmentStateProps';
import { bindActionCreators, Dispatch } from 'redux';
import { AssessmentsActions } from '../Actions/AssessentActions';
import AssessmentMainFilter from './AssessmentMainFilter';
import { TaskDropDownStyles } from '../../Tasks/Constants/TaskStylesConstants';


const mapStateToProps = (state: any): IAssessmentStateProps => {
    return {
        AssessmentsState: state.AssessmentsReducer
    }
}

class AssessmentComponent extends React.PureComponent<IAssessmentStateProps, {}> {

    public render(): JSX.Element {

        let breadcrumbs = ASSESSMENT_BREADCRUMBS;
        breadcrumbs[1].text = sessionStorage.getItem('link');
        let {
            isLoadingAssessmentClients,
            isLoadingAssessmentSites,
            isLoadingAssessmentsDashboard,
            showColumns
        } = this.props.AssessmentsState;

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={ASSESSMENT_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    { /*   <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <Label
                                styles={{
                                    root: {
                                        marginTop: "2.25%"
                                    }
                                }}
                            >
                                {`${assessments?.length} Record(s) Found`}
                            </Label>
                            </div>*/}
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Columns: </Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <Dropdown
                                disabled={isLoadingAssessmentClients || isLoadingAssessmentSites || isLoadingAssessmentsDashboard}
                                placeholder="Select columns"
                                selectedKeys={showColumns}
                                onChange={(event, selectedColumn) => {
                                    this.props.showHideAssesmentDashboardColumns({
                                        selectedColumn: selectedColumn
                                    })
                                }}
                                multiSelect
                                options={[
                                    { key: 'location', text: 'Name' },
                                    { key: 'acronymCode', text: 'Id' },
                                    { key: 'specifications', text: 'Specifications' },
                                    { key: 'taskType', text: 'Course' },
                                    { key: 'certificateNumber', text: 'Certificate' },
                                    { key: 'result', text: 'Last Result' },
                                    { key: 'actualInspectionDate', text: 'Issue Date' },
                                    { key: 'dueDate', text: 'Expiry Date' },
                                    { key: 'site', text: 'Site' },
                                    { key: 'id', text: 'Report' },
                                    { key: 'itemStatus', text: 'Current Status' },
                                ]}
                                styles={TaskDropDownStyles}
                            />
                        </div>
                    </div>
                </div>
                <AssessmentMainFilter {...this.props} />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    let assessmentActions: AssessmentsActions = new AssessmentsActions();

    return bindActionCreators({ ...assessmentActions }, dispatch);
}

const Assessments = connect(mapStateToProps, mapDispatchToProps)(AssessmentComponent);
export default Assessments;