import { IKendoGridColumn } from "../../../CommonInterfaces/CommoInterfaces";

export const CLEAN_REPORT_DASHBOARD: string = 'CLEAN_REPORT_DASHBOARD';
export const SHOW_LOADER_DASHBOARD: string = 'SHOW_LOADER_DASHBOARD';
export const LOAD_REPORT_DASHBOARD: string = 'LOAD_REPORT_DASHBOARD';
export const CHANGE_REPORT_FILTER: string = 'CHANGE_REPORT_FILTER';
export const SHOW_HIDE_REPORT_CERTIFICATE_PANEL: string = 'SHOW_HIDE_REPORT_CERTIFICATE_PANEL';
export const LOAD_TASK_DETAILS: string = 'LOAD_TASK_DETAILS';
export const LOAD_REPORT_CERTIFICATE: string = 'LOAD_REPORT_CERTIFICATE';
export const HANDLE_REPORT_GRID_EXPAND_CHANGE: string = "HANDLE_REPORT_GRID_EXPAND_CHANGE";
export const HANDLE_REPORT_GRID_FILTER_CHANGE: string = "HANDLE_REPORT_GRID_FILTER_CHANGE";
export const HANDLE_REPORT_GRID_GROUP_SORT_CHANGE: string = "HANDLE_REPORT_GRID_GROUP_SORT_CHANGE";
export const SWITCH_TASK_AND_SERVICE_REPORT: string = "SWITCH_TASK_AND_SERVICE_REPORT";

// Report Kendo Grid Columns
export const REPORT_VIEW_GRID_COLUMNS: IKendoGridColumn[] = [
    {
        title: "Report",
        field: "id",
        show: true,
        filter: "text",
        width: 80,
        colIndex: 1
    },
    {
        title: "Job",
        field: "jobNumber",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 2
    },
    {
        title: "Quote / WO",
        field: "quotationNumber",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 2
    },
    {
        title: "Certificate",
        field: "certificateNumber",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 4
    },
    {
        title: "Client",
        field: "client",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 5
    },
    {
        title: "Site",
        field: "site",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 6
    },
    {
        title: "Asset",
        field: "location",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 7
    },
    {
        title: "Activity",
        field: "taskType",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 8
    },
    {
        title: "Result",
        field: "result",
        show: true,
        filter: "custom",
        width: 150,
        colIndex: 9
    },
    {
        title: "Inspector",
        field: "inspector",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 10
    },
    {
        title: "Description",
        field: "description",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 11
    },
    {
        title: "Inspection",
        field: "actualInspectionDate",
        show: true,
        filter: "date",
        width: 150,
        colIndex: 12
    },
    {
        title: "Certified",
        field: "certificateDate",
        show: true,
        filter: "date",
        width: 150,
        colIndex: 13
    },
    {
        title: "Status",
        field: "status",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 14
    },
    {
        title: "Asset Type",
        field: "assetType",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 15
    },
    {
        title: "End User Name",
        field: "endUserName",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 16
    },
    {
        title: "End User Address",
        field: "endUserAddress",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 17
    }
];

export const REPORT_BREADCRUMBS = [
    { text: 'OPERATIONS', key: 'Operations' },
    { text: 'Reports', key: 'Reports' }
];

export const RESULT_DETAILS =
{
    null: { showText: "NO RESULT", color: "#000" },
    0: { showText: "NO RESULT", color: "#000" },
    1: { showText: "ACCEPTED", color: "#29a133" },
    2: { showText: "REJECTED", color: "#c53a1a" }
};
