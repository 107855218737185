import { IQSFile, ISelect } from "../../../CommonInterfaces/CommoInterfaces";
import { CLIENT_USER_ROLE, SITE_USER_ROLE } from "../../../Constants/CommonConstants";
import { IAssessmentsActionKeys } from "../../Assessment/Interfaces/IAssessmentsActions";
import { IAssetPayload } from "../AssetInterface/IAsset";
import * as CONSTANTS from './../Constants/AssetConstants';


export class AssetActions {

    public resetAssetView = (payload: IAssetPayload) => {
        return { type: CONSTANTS.RESET_ASSET_VIEW, payload }
    }

    public loadClientsAsync = (payload: IAssetPayload) => {
        return { type: CONSTANTS.LOAD_ASSET_CLIENTS, payload }
    }

    public loadClients = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            dispatch(this.resetAssetView(payload));
            payload.apiObject.loadClientsAPI(payload.companyId).then((response: any) => {
                payload.Locations = response.data;
                dispatch(this.loadClientsAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                // payload.alertJSON = {
                //     hideAlert: false,
                //     alertHeader: "Ooops!!",
                //     alertSubText: "Error while loading Clients"
                // }
                // dispatch(this.openCloseAlertModal(payload));

                // setTimeout(() => {
                //     payload.alertJSON = {
                //         hideAlert: true,
                //         alertHeader: "",
                //         alertSubText: ""
                //     }
                //     dispatch(this.openCloseAlertModal(payload));
                // }, 2000);
            })
        }
    }

    public loadSitesAsync = (payload: IAssetPayload) => {
        return { type: CONSTANTS.LOAD_ASSET_SITES, payload }
    }

    public changeClientFilterNameAsync = (payload: IAssetPayload) => {
        return { type: CONSTANTS.CHANGE_CLIENT_FILTER_NAME, payload }
    }

    public changeClientFilterName = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            dispatch(this.changeClientFilterNameAsync(payload));

            payload.apiObject.loadSitesAPI(payload.selectedLocation.value).then((response: any) => {
                let currentUserRole = window.loggedInUserDetails.role;
                if ([SITE_USER_ROLE, CLIENT_USER_ROLE].indexOf(currentUserRole) > -1) {
                    payload.Locations = response.data.filter((data: any) =>
                        window.loggedInUserDetails.siteIds.split(",").indexOf(data.id.toString()) > -1)
                }
                else {
                    payload.Locations = response.data;
                }
                dispatch(this.loadSitesAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                // payload.alertJSON = {
                //     hideAlert: false,
                //     alertHeader: "Ooops!!",
                //     alertSubText: "Error while loading Clients"
                // }
                // dispatch(this.openCloseAlertModal(payload));

                // setTimeout(() => {
                //     payload.alertJSON = {
                //         hideAlert: true,
                //         alertHeader: "",
                //         alertSubText: ""
                //     }
                //     dispatch(this.openCloseAlertModal(payload));
                // }, 2000);
            })
        }
    }

    public changSiteFilterNameAsync = (payload: IAssetPayload) => {
        return { type: CONSTANTS.CHANGE_SITE_FILTER_NAME, payload }
    }

    public loadAssetDashboard = (payload: IAssetPayload) => {
        return { type: CONSTANTS.LOAD_ASSET_DASHBOARD, payload }
    }

    public changeFilterDateChange = (payload: IAssetPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.CHANGE_FILTER_DATE_RANGE, payload }
    }

    public changeSiteFilterName = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            dispatch(this.changSiteFilterNameAsync(payload));
            // if (payload.selectedLocation) {
            if (payload.selectedLocations) {
                let siteIds = payload.selectedLocations
                    .filter((location: ISelect) => location.value > 0).map((location: ISelect) => location.value);
                payload.apiObject.loadAssetDashboardAPI(payload.selectedClient.value, siteIds.length > 0 ? siteIds : null).then((response: any) => {
                    payload.Locations = response.data;
                    dispatch(this.loadAssetDashboard(payload));
                }).catch((error) => {
                    console.error("Error: ", error);
                    // payload.alertJSON = {
                    //     hideAlert: false,
                    //     alertHeader: "Ooops!!",
                    //     alertSubText: "Error while loading Clients"
                    // }
                    // dispatch(this.openCloseAlertModal(payload));

                    // setTimeout(() => {
                    //     payload.alertJSON = {
                    //         hideAlert: true,
                    //         alertHeader: "",
                    //         alertSubText: ""
                    //     }
                    //     dispatch(this.openCloseAlertModal(payload));
                    // }, 2000);
                })
            }
        }
    }

    public handleAssetGridGroupSortChanged = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.HANDLE_ASSET_GRID_GROUP_SORT_CHANGE, payload }
    }

    public handleAssetGridSortChanged = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.HANDLE_ASSET_GRID_SORT_CHANGE, payload }
    }

    public handleAssetGridExpandChanged = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.HANDLE_ASSET_GRID_EXPAND_CHANGE, payload }
    }

    public handleAssetGridFilterChangedAsync = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.HANDLE_ASSET_GRID_FILTER_CHANGE, payload }
    }

    public handleAssetGridFilterChanged = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            dispatch(this.handleAssetGridFilterChangedAsync(payload));
            let siteIds = payload.selectedLocations.map((location: ISelect) => location.value);
            payload.apiObject.loadAssetDashboardAPI(payload.selectedClient.value, siteIds, payload.config).then((response: any) => {
                payload.Locations = response.data;
                dispatch(this.loadAssetDashboard(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                // payload.alertJSON = {
                //     hideAlert: false,
                //     alertHeader: "Ooops!!",
                //     alertSubText: "Error while loading Clients"
                // }
                // dispatch(this.openCloseAlertModal(payload));

                // setTimeout(() => {
                //     payload.alertJSON = {
                //         hideAlert: true,
                //         alertHeader: "",
                //         alertSubText: ""
                //     }
                //     dispatch(this.openCloseAlertModal(payload));
                // }, 2000);
            })
        }
    }

    public openCloseAddEditAssetPanel = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.OPEN_CLOSE_ADD_EDIT_ASSET_PANEL, payload }
    }

    // Panel 

    public changeAssetPanelTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.CHANGE_ASSET_PANEL_TAB, payload }
    }

    // Description Tab

    public openAssetDescriptionTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.OPEN_ASSET_DESCRIPTION_TAB, payload }
    }

    public loadAssetDescriptionTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.LOAD_ASSET_DESCRIPTION_TAB, payload }
    }

    public loadAssetDescription = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            dispatch(this.openAssetDescriptionTab(payload));
            payload.apiObject.loadAssetTypeDescriptionAPI(payload.selectedAssetId).then((response: any) => {
                payload.assetTypes = response[0].data;
                payload.Asset = response[1].data;
                dispatch(this.loadAssetDescriptionTab(payload));
            }).catch((error) => {
                console.error("Error: ", error);
            })
        }
    }

    public openCloseAlertModal = (payload: IAssetPayload) => {
        return { type: CONSTANTS.OPEN_CLOSE_ASSET_ALERT_MODAL, payload };
    }

    public loadOpenObservationsAsync = (payload: IAssetPayload) => {
        return { type: CONSTANTS.LOAD_OPEN_OBSERVATION_COUNT, payload }
    }

    public changeAssetSite = (payload: IAssetPayload) => {
        return { type: CONSTANTS.CHANGE_ASSET_SITE, payload }
    }

    public loadOpenObservations = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            // dispatch(this.resetAssetView(payload));
            payload.isLoading = true;
            dispatch(this.loadOpenObservationsAsync(payload));
            payload.apiObject.getOpenObservaionsForAsset(payload.selectedAssetId).then((response: any) => {
                payload.observationCount = response.data;
                payload.isLoading = false;
                if (response.data > 0) {
                    payload.hideShiftAssetDialogue = true;
                    payload.alertJSON = {
                        hideAlert: false,
                        alertHeader: "Ooops!!",
                        alertSubText: `Site Can not be shifted due to Open / Ongoing / Amended Task(s) attached`
                    }
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.alertJSON = {
                            hideAlert: true,
                            alertHeader: "",
                            alertSubText: ""
                        }
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);

                }
                else {
                    payload.hideShiftAssetDialogue = false;
                }
                dispatch(this.loadOpenObservationsAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Observations"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    // Asset Files Tab

    public openAssetFilesTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.OPEN_ASSET_FILES_TAB, payload }
    }

    public loadAssetFilesTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.LOAD_ASSET_FILES_TAB, payload }
    }

    public loadAssetFiles = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            dispatch(this.openAssetFilesTab(payload));
            payload.apiObject.loadAssetTypeDescriptionAPI(payload.selectedAssetId).then((response: any) => {
                payload.assetTypes = response[0].data;
                payload.Asset = response[1].data;
                dispatch(this.loadAssetFilesTab(payload));
            }).catch((error) => {
                console.error("Error: ", error);
            })
        }
    }

    public changeFieldAssetDescriptionTab = (payload: IAssetPayload): any => {
        switch (payload.changedField) {
            case "site":
                return { type: CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_SITE, payload }
            case "name":
                return { type: CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_ASSET_NAME, payload }
            case "acronymCode":
                return { type: CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_ACRONYM_CODE, payload }
            case "assetType":
                return { type: CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_ASSET_TYPE_NAME, payload }
            case "endUserName":
                return { type: CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_END_USER_NAME, payload }
            case "endUserAddress":
                return { type: CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_END_USER_ADDRESS, payload }
            case "externalId":
                return { type: CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_EXTERNAL_ID, payload }
            default:
                break;
        }
    }

    public saveAssetDescriptionAsync = (payload: IAssetPayload) => {
        return { type: CONSTANTS.SAVE_ASSET_DESCRIPTION_TAB, payload }
    }

    public saveAssetDescription = (payload: IAssetPayload) => {
        return (dispatch: any) => {
            dispatch(this.openAssetDescriptionTab(payload));
            payload.apiObject.saveAssetDescriptionAPI(payload.locationPayload).then(() => {
                payload.responseStatusDetails = {
                    errorFlag: false,
                    statusMessage: "Asset Saved Successfully"
                };
                dispatch(this.saveAssetDescriptionAsync(payload));
                setTimeout(() => {
                    payload.responseStatusDetails = {
                        errorFlag: false,
                        statusMessage: ""
                    };
                    dispatch(this.saveAssetDescriptionAsync(payload));
                    let currentUserRole = window.loggedInUserDetails.role;
                    let currentUserCompanyId = window.loggedInUserDetails.companyId;

                    if (currentUserRole === "CLIENT-USER" || currentUserRole === "SITE-USER") {
                        payload.companyId = currentUserCompanyId;
                    }
                    dispatch(this.resetAssetView(payload));
                    dispatch(this.loadClients(payload));
                }, 2000);
            }).catch((error) => {
                payload.responseStatusDetails = {
                    errorFlag: true,
                    statusMessage: error
                };
                dispatch(this.saveAssetDescriptionAsync(payload));
                setTimeout(() => {
                    payload.responseStatusDetails = {
                        errorFlag: false,
                        statusMessage: ""
                    };
                    dispatch(this.saveAssetDescriptionAsync(payload));
                }, 2000);
            })
        }
    }

    // Asset Reports

    public openAssetReportsTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.OPEN_ASSET_REPORTS_TAB, payload }
    }

    public loadAssetReportsTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.LOAD_ASSET_REPORTS_TAB, payload }
    }

    public sortAssetReports = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.SORT_ASSET_REPORTS, payload }
    }

    public changeLimitOfReportPerPage = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.CHANGE_LIMIT_OF_REPORT_PER_PAGE, payload }
    }

    public loadAssetReports = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            if (payload.assetReportSortedColumn) {
                dispatch(this.sortAssetReports(payload));
            }
            dispatch(this.openAssetReportsTab(payload));
            payload.apiObject.loadAssetObservationsAPI(payload.selectedAssetId, payload.offset, payload.limit, payload.assetReportSortedColumn)
                .then((response: any) => {
                    payload.observations = response.data;
                    dispatch(this.loadAssetReportsTab(payload));
                }).catch((error) => {
                    console.error("Error loadAssetReports: ", error);
                })
        }
    }

    // Asset Attributes Tab

    public openAssetAttributesTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.OPEN_ASSET_ATTRIBUTES_TAB, payload }
    }

    public loadAssetAttributesTab = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.LOAD_ASSET_ATTRIBUTES_TAB, payload }
    }

    public changeAssetAttributeValues = (payload: IAssetPayload): any => {
        return { type: CONSTANTS.CHANGE_ASSET_ATTRIBUTES_VALUE, payload }
    }

    public loadAssetAttributes = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            dispatch(this.openAssetAttributesTab(payload));
            payload.apiObject.loadAssetAttributesFormAPI(payload.formId).then((response: any) => {
                payload.attributeFormData = response.data;
                dispatch(this.loadAssetAttributesTab(payload));
            }).catch((error) => {
                console.error("Error: ", error);
            })
        }
    }

    public saveAssetAttributes = (payload: IAssetPayload): any => {
        return (dispatch: any) => {
            dispatch(this.openAssetAttributesTab(payload));
            payload.apiObject.saveAssetAttributesAPI(payload.selectedAssetId, payload.selectedAssetUserData).then((response: any) => {
                payload.Asset = response.data;
                payload.responseStatusDetails = {
                    errorFlag: false,
                    statusMessage: "Asset Saved Successfully"
                };
                dispatch(this.loadAssetAttributesTab(payload));
                setTimeout(() => {
                    payload.responseStatusDetails = {
                        errorFlag: false,
                        statusMessage: ""
                    };
                    dispatch(this.loadAssetAttributesTab(payload));
                    let currentUserRole = window.loggedInUserDetails.role;
                    let currentUserCompanyId = window.loggedInUserDetails.companyId;

                    if (currentUserRole === "CLIENT-USER" || currentUserRole === "SITE-USER") {
                        payload.companyId = currentUserCompanyId;
                    }
                    dispatch(this.resetAssetView(payload));
                    dispatch(this.loadClients(payload));
                }, 2000);
            }).catch((error) => {
                console.error("Error: ", error);
            })
        }
    }

    // Asset File Upload start
    public uploadDeleteAssetFilesAsync = (payload: IAssetPayload) => {
        return { type: CONSTANTS.UPLOAD_ASSET_FILES, payload }
    }

    public uploadAssetFiles = (payload: IAssetPayload) => {
        return (dispatch: any) => {
            payload.isLoading = true;
            dispatch(this.uploadDeleteAssetFilesAsync(payload));
            payload.apiObject._uploadAssetFiles(payload.selectedAssetId, payload.uploadedFiles).then((response: IQSFile[]) => {
                payload.isLoading = false;
                payload.files = response;
                payload.fileUploadDeleteStatus = {
                    error: false,
                    errorMessage: "File uploaded successfully!!"
                }
                dispatch(this.uploadDeleteAssetFilesAsync(payload));
                setTimeout(() => {
                    payload.fileUploadDeleteStatus = {
                        error: false,
                        errorMessage: ""
                    }
                    dispatch(this.uploadDeleteAssetFilesAsync(payload));
                }, 2000);
            }).catch((error) => {
                payload.fileUploadDeleteStatus = {
                    error: true,
                    errorMessage: "Error while uploading Asset file!!"
                }
                dispatch(this.uploadDeleteAssetFilesAsync(payload));

                setTimeout(() => {
                    payload.fileUploadDeleteStatus = {
                        error: false,
                        errorMessage: ""
                    }
                    dispatch(this.uploadDeleteAssetFilesAsync(payload));
                }, 2000);
            });
        }
    }


    public deleteAssetFile = (payload: IAssetPayload) => {
        return (dispatch: any) => {
            payload.isLoading = true;
            dispatch(this.uploadDeleteAssetFilesAsync(payload));
            payload.apiObject._deleteAssetFile(payload.selectedAssetId, payload.deletedFile).then((response: IQSFile[]) => {
                payload.isLoading = false;
                payload.files = response;
                payload.fileUploadDeleteStatus = {
                    error: false,
                    errorMessage: "File archived successfully!!"
                }
                dispatch(this.uploadDeleteAssetFilesAsync(payload));

                setTimeout(() => {
                    payload.fileUploadDeleteStatus = {
                        error: false,
                        errorMessage: ""
                    }
                    dispatch(this.uploadDeleteAssetFilesAsync(payload));
                }, 2000);
            }).catch((error) => {
                console.log("Error while delete Asset file: ", error);
                payload.fileUploadDeleteStatus = {
                    error: true,
                    errorMessage: "Error while deleting Asset file!!"
                }
                dispatch(this.uploadDeleteAssetFilesAsync(payload));

                setTimeout(() => {
                    payload.fileUploadDeleteStatus = {
                        error: false,
                        errorMessage: ""
                    }
                    dispatch(this.uploadDeleteAssetFilesAsync(payload));
                }, 2000);
            });
        }
    }

    public openCloseConfirmModal = (payload: IAssetPayload) => {
        return { type: CONSTANTS.OPEN_CLOSE_ASSET_CONFIRM_MODAL, payload };
    }
}