import { ISequence } from "../Interfaces/ISequence";
import { ISequenceState } from "../Interfaces/ISequenceState";

export const SequenceInitialState: ISequenceState = {
    isLoadingSequenceDashboard: true,
    isLoadingSequenceAddEditPanel: false,
    isShowSequenceAddEditPanel: false,
    sequences: [] as ISequence[],
    selectedSequence: null,
    sequenceGridDataState: {
        sort: [{ field: "sequenceName", dir: "asc" }],
        filter: { filters: [], logic: 'and' },
        group: [{ field: "officeName", dir: 'desc' }],
        skip: 0
    },
    proccessedSequences: [],
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    }
}
