import { IAssetAction, IAssetInitialState, IAssetPayload } from "../AssetInterface/IAsset";
import { AssetInitialState } from "../Constants/AssetInitialState";
import * as CONSTANTS from './../Constants/AssetConstants';

import { AssetUtility } from "../Utility/AssetUtility";
import { ISelect } from "../../../CommonInterfaces/CommoInterfaces";

export function AssetReducer(state = AssetInitialState, action: IAssetAction): IAssetInitialState {

    let tempState: IAssetInitialState = { ...state };
    let payload: IAssetPayload = action.payload;
    let utility: AssetUtility = new AssetUtility();
    let newAssetGridDataState = { ...tempState.assetGridDataState };

    switch (action.type) {

        // Dashboard
        case CONSTANTS.RESET_ASSET_VIEW:
            if (!payload.isUnderTaskDetailsView) {
                tempState.selectedClientFilter = null;
                tempState.selectedSiteFilter = null;
                tempState.selectedSiteFilters = null;
                tempState.TabIndex = 0;
                tempState.selectedTask = null;

            }
            tempState.isUnderTaskDetailsView = payload.isUnderTaskDetailsView;
            tempState.clients = [];
            tempState.sites = [];
            tempState.assets = [];
            tempState.isLoadingMainClientFilter = true;
            tempState.assetGridDataState = {
                sort: [{ field: "name", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                skip: 0
            };
            tempState.processedAssets = [];
            tempState.isOpenAddEditAssetPanel = false;
            tempState.hideShiftAssetDialogue = true;
            tempState.selectedAssetOtherFiles = [];
            tempState.issueDateRange = null;
            tempState.expiryDateRange = null;
            break;

        case CONSTANTS.RESET_ASSET_TAB:
            tempState.TabIndex = 0;
            tempState.isUnderTaskDetailsView = false;
            tempState.selectedTask = null;
            break;

        case CONSTANTS.LOAD_ASSET_CLIENTS:
            tempState.clients = utility.loadSelectDropDownValue(payload.Locations);
            tempState.sites = [];
            tempState.isLoadingMainClientFilter = false;
            break;

        case CONSTANTS.LOAD_ASSET_SITES:
            tempState.sites = utility.loadSelectDropDownValue(payload.Locations);
            tempState.isLoadingMainSiteFilter = false;
            break;

        case CONSTANTS.CHANGE_CLIENT_FILTER_NAME:
            tempState.issueDateRange = null;
            tempState.expiryDateRange = null;
            tempState.selectedClientFilter = payload.selectedLocation;
            tempState.selectedSiteFilter = null;
            tempState.selectedSiteFilters = [];
            tempState.sites = [];
            tempState.isLoadingMainSiteFilter = true;
            break;

        case CONSTANTS.CHANGE_SITE_FILTER_NAME:
            // tempState.selectedSiteFilter = payload.selectedLocation;
            tempState.issueDateRange = null;
            tempState.expiryDateRange = null;
            tempState.selectedSiteFilters = payload.selectedLocations;
            tempState.assetGridDataState = {
                sort: [{ field: "name", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                skip: 0
            };
            tempState.processedAssets = [];
            tempState.TabIndex = 0;
            tempState.isLoadingAssetDashboard = payload.selectedLocations ? true : false;
            break;

        case CONSTANTS.LOAD_ASSET_DASHBOARD:
            tempState.assets = payload.Locations;
            tempState.processedAssets = utility.processAssetsForKendoGrid(payload.Locations, {
                sort: [{ field: "name", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                skip: 0
            });
            tempState.isLoadingAssetDashboard = false;
            break;

        case CONSTANTS.HANDLE_ASSET_GRID_GROUP_SORT_CHANGE:
            newAssetGridDataState.sort = payload.event.dataState.sort;
            let indexOfId = payload.event.dataState.group.findIndex(d => d.field === "id");
            if (indexOfId > -1) {
                payload.event.dataState.group.splice(indexOfId, 1);
            }
            newAssetGridDataState.group = payload.event.dataState.group;
            tempState.assetGridDataState = newAssetGridDataState;
            tempState.processedAssets = utility.processAssetsForKendoGrid(tempState.assets, {
                sort: newAssetGridDataState.sort,
                filter: { filters: [], logic: 'and' },
                group: newAssetGridDataState.group,
                skip: 0
            });
            break;

        case CONSTANTS.HANDLE_ASSET_GRID_SORT_CHANGE:
            newAssetGridDataState.sort = payload.event.sort;
            tempState.assetGridDataState = newAssetGridDataState;
            tempState.processedAssets = utility.processAssetsForKendoGrid(tempState.assets, {
                sort: tempState.assetGridDataState.sort,
                filter: { filters: [], logic: 'and' },
                group: tempState.assetGridDataState.group,
                skip: 0
            });
            break;

        case CONSTANTS.HANDLE_ASSET_GRID_EXPAND_CHANGE:
            payload.event.dataItem[payload.event.target.props.expandField] = payload.event.value;
            tempState.assetGridDataState = newAssetGridDataState;
            break;

        case CONSTANTS.CHANGE_FILTER_DATE_RANGE:
            switch (payload.dateField) {
                case "actualInspectionDate":
                    tempState.issueDateRange = payload.dateRangeValue;
                    break;
                case "dueDate":
                    tempState.expiryDateRange = payload.dateRangeValue;
                    break;

                default:
                    break;
            }
            break;
        case CONSTANTS.HANDLE_ASSET_GRID_FILTER_CHANGE:
            let assetGridFilter = payload.event.filter ? payload.event.filter : { filters: [], logic: 'and' };
            newAssetGridDataState.filter = assetGridFilter;
            tempState.assetGridDataState = newAssetGridDataState;
            tempState.isLoadingAssetDashboard = true;
            break;

        //Panel

        case CONSTANTS.CHANGE_ASSET_PANEL_TAB:
            tempState.TabIndex = payload.TabIndex;
            break;

        case CONSTANTS.OPEN_CLOSE_ADD_EDIT_ASSET_PANEL:
            tempState.isOpenAddEditAssetPanel = !payload.hideAddEditAssetPanel;
            tempState.TabIndex = 0;
            tempState.selectedAssetId = payload.selectedAssetId;
            if (payload.SelectedTask) {
                tempState.isUnderTaskDetailsView = true;
                tempState.selectedTask = payload.SelectedTask;
                tempState.selectedClientFilter = {
                    label: payload.SelectedTask.client,
                    value: parseInt(payload.SelectedTask.companyId)
                }
                tempState.selectedSiteFilters = [{
                    label: payload.SelectedTask.site,
                    value: parseInt(payload.SelectedTask.siteId)
                }]
            }
            break;

        // Description
        case CONSTANTS.OPEN_ASSET_DESCRIPTION_TAB:
            tempState.isLoadingAssetDescriptionTab = true;
            tempState.hideShiftAssetDialogue = true;
            break;

        case CONSTANTS.OPEN_CLOSE_ASSET_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON ?
                payload.alertJSON :
                {
                    alertHeader: "",
                    alertSubText: "",
                    hideAlert: true,
                };
            tempState.isLoadingAssetAttributesTab = false;
            tempState.isLoadingAssetDescriptionTab = false;
            tempState.isLoadingAssetDashboard = false;
            tempState.isLoadingAssetFilesTab = false;
            tempState.isLoadingAssetReportsTab = false;
            tempState.isLoadingAssetHistoryTab = false;
            break;

        case CONSTANTS.LOAD_OPEN_OBSERVATION_COUNT:
            tempState.isLoadingAssetDescriptionTab = payload.isLoading;
            if (!payload.isLoading) {
                tempState.hideShiftAssetDialogue = payload.hideShiftAssetDialogue;
                tempState.newAssetParentSite = tempState.selectedAssetDescription.parentSite;
            }
            break;

        case CONSTANTS.LOAD_ASSET_DESCRIPTION_TAB:
            tempState.isLoadingAssetDescriptionTab = false;
            tempState.assetTypes = utility.loadSelectDropDownValue(payload.assetTypes);
            let selectedSite: ISelect =
                tempState.selectedSiteFilters.find((site: ISelect) => site.value === parseInt(payload.Asset.parentId));
            tempState.selectedAssetDescription = {
                name: {
                    value: payload.Asset.name,
                    error: false,
                    errorMessage: ""
                },
                parentSite: payload.selectedAssetId === -1 ? null : {
                    value: selectedSite.value,
                    label: selectedSite.label,
                    acronymCode: selectedSite.acronymCode
                },
                assetType: payload.selectedAssetId === -1 ? null :
                    utility.populateAssetType(payload.Asset, payload.assetTypes),
                endUserName: payload.Asset.endUserName,
                externalId:  payload.Asset.externalId,
                endUserAddress: payload.Asset.endUserAddress,
                acronymCode: payload.Asset.acronymCode,
            }
            tempState.selectedAssetFiles = {
                image: payload.Asset.image,
                fileList: payload.Asset.fileList,
                otherFiles: payload.Asset.otherFiles,
                userData: payload.Asset.userData
            }
            tempState.selectedAssetOtherFiles = payload.Asset.otherFiles;
            tempState.selectedAssetUserData = payload.Asset.userData;
            break;

        case CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_ASSET_NAME:
            let asetNameRegex = localStorage.getItem("assetNameRegex").split("");
            tempState.selectedAssetDescription = {
                name: {
                    value: payload.changedText,
                    error: !payload.changedText.trim() || asetNameRegex.map((item) => payload.changedText.trim().includes(item)).filter(item => item === true).length > 0,
                    errorMessage: !payload.changedText.trim() ?
                        "Asset Name is Mandatory" :
                        asetNameRegex.map((item) => payload.changedText.trim().includes(item)).filter(item => item === true).length > 0 ? "Invalid character(s) in Asset Name" : ""
                },
                parentSite: tempState.selectedAssetDescription.parentSite,
                assetType: tempState.selectedAssetDescription.assetType,
                endUserName: tempState.selectedAssetDescription.endUserName,
                endUserAddress: tempState.selectedAssetDescription.endUserAddress,
                externalId: tempState.selectedAssetDescription.externalId,
                acronymCode: tempState.selectedAssetDescription.acronymCode
            }
            break;

        case CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_SITE:
            tempState.selectedAssetDescription = {
                name: tempState.selectedAssetDescription.name,
                parentSite: payload.selectedLocation,
                assetType: tempState.selectedAssetDescription.assetType,
                endUserName: tempState.selectedAssetDescription.endUserName,
                externalId: tempState.selectedAssetDescription.externalId,
                endUserAddress: tempState.selectedAssetDescription.endUserAddress,
                acronymCode: tempState.selectedAssetDescription.acronymCode
            }
            break;


        case CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_ASSET_TYPE_NAME:
            tempState.selectedAssetDescription = {
                name: tempState.selectedAssetDescription.name,
                parentSite: tempState.selectedAssetDescription.parentSite,
                assetType: payload.changedOption,
                endUserName: tempState.selectedAssetDescription.endUserName,
                externalId: tempState.selectedAssetDescription.externalId,
                endUserAddress: tempState.selectedAssetDescription.endUserAddress,
                acronymCode: tempState.selectedAssetDescription.acronymCode
            }
            break;

        case CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_END_USER_NAME:
            tempState.selectedAssetDescription = {
                name: tempState.selectedAssetDescription.name,
                parentSite: tempState.selectedAssetDescription.parentSite,
                assetType: tempState.selectedAssetDescription.assetType,
                endUserName: payload.changedText,
                externalId: tempState.selectedAssetDescription.externalId,
                endUserAddress: tempState.selectedAssetDescription.endUserAddress,
                acronymCode: tempState.selectedAssetDescription.acronymCode
            }
            break;

        case CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_END_USER_ADDRESS:
            tempState.selectedAssetDescription = {
                name: tempState.selectedAssetDescription.name,
                parentSite: tempState.selectedAssetDescription.parentSite,
                assetType: tempState.selectedAssetDescription.assetType,
                endUserName: tempState.selectedAssetDescription.endUserName,
                externalId: tempState.selectedAssetDescription.externalId,
                endUserAddress: payload.changedText,
                acronymCode: tempState.selectedAssetDescription.acronymCode
            }
            break;
        case CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_EXTERNAL_ID:
            tempState.selectedAssetDescription = {
                name: tempState.selectedAssetDescription.name,
                parentSite: tempState.selectedAssetDescription.parentSite,
                assetType: tempState.selectedAssetDescription.assetType,
                endUserName: tempState.selectedAssetDescription.endUserName,
                endUserAddress: tempState.selectedAssetDescription.endUserName,
                externalId: payload.changedText,
                acronymCode: tempState.selectedAssetDescription.acronymCode,
            }
            break;
        case CONSTANTS.CHANGE_ASSET_DESCRIPTION_TAB_ACRONYM_CODE:
            tempState.selectedAssetDescription = {
                name: tempState.selectedAssetDescription.name,
                parentSite: tempState.selectedAssetDescription.parentSite,
                assetType: tempState.selectedAssetDescription.assetType,
                endUserName: tempState.selectedAssetDescription.endUserName,
                endUserAddress: tempState.selectedAssetDescription.endUserName,
                externalId: tempState.selectedAssetDescription.externalId,
                acronymCode: payload.changedText,
            }
            break;

        case CONSTANTS.SAVE_ASSET_DESCRIPTION_TAB:
            tempState.responseStatusDetails = payload.responseStatusDetails;
            tempState.isLoadingAssetDescriptionTab = false;
            break;

        case CONSTANTS.CHANGE_ASSET_SITE:
            tempState.newAssetParentSite = payload.selectedLocation;
            break;

        // Reports
        case CONSTANTS.OPEN_ASSET_REPORTS_TAB:
            tempState.isLoadingAssetReportsTab = true;
            break;

        case CONSTANTS.SORT_ASSET_REPORTS:
            let tempAssetReportSortedColumn = { ...tempState.assetReportSortedColumn };
            tempAssetReportSortedColumn = {
                columnName: payload.assetReportSortedColumn.columnName,
                sortDesc: payload.assetReportSortedColumn.sortDesc,
            }
            tempState.assetReportSortedColumn = tempAssetReportSortedColumn;
            break;

        case CONSTANTS.CHANGE_LIMIT_OF_REPORT_PER_PAGE:
            tempState.assetReportMaxDataPerPage = payload.limit;
            break;

        case CONSTANTS.LOAD_ASSET_REPORTS_TAB:
            tempState.isLoadingAssetReportsTab = false;
            tempState.observations = utility.populateAssetObservations(payload.observations);
            tempState.assetReportOffset = payload.offset;
            break;

        // Files
        case CONSTANTS.OPEN_ASSET_FILES_TAB:
            tempState.isLoadingAssetFilesTab = true;
            break;

        case CONSTANTS.LOAD_ASSET_FILES_TAB:
            tempState.isLoadingAssetFilesTab = false;
            break;

        // Asset Attributes
        case CONSTANTS.CHANGE_ASSET_ATTRIBUTES_VALUE:
            tempState.selectedAssetUserData = payload.selectedAssetUserData;
            break;

        case CONSTANTS.OPEN_ASSET_ATTRIBUTES_TAB:
            tempState.isLoadingAssetAttributesTab = true;
            break;

        case CONSTANTS.LOAD_ASSET_ATTRIBUTES_TAB:
            tempState.isLoadingAssetAttributesTab = false;
            tempState.selectedAssetFormAttributes = payload.attributeFormData ?
                payload.attributeFormData : tempState.selectedAssetFormAttributes;
            tempState.selectedAssetUserData = payload.Asset ? payload.Asset.userData : tempState.selectedAssetUserData;
            tempState.responseStatusDetails = payload.responseStatusDetails;
            break;

        // Asset File upload

        case CONSTANTS.UPLOAD_ASSET_FILES:
        case CONSTANTS.DELETE_ASSET_FILE:
            tempState.hideConfirmArchive = true;
            tempState.isLoadingAssetFilesTab = payload.isLoading;
            if (!payload.isLoading) {
                tempState.selectedAssetOtherFiles = payload.files;
                tempState.fileUploadDeleteStatus = payload.fileUploadDeleteStatus;
            }
            break;

        case CONSTANTS.OPEN_CLOSE_ASSET_CONFIRM_MODAL:
            tempState.deletedFile = payload.deletedFile;
            tempState.hideConfirmArchive = payload.hideConfirm;
            break;

        default:
            break;
    }

    return tempState;

}
