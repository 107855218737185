// Dashboard

export const CLEAN_LO_OFFICE_DASHBOARD: string = "CLEAN_LO_OFFICE_DASHBOARD";
export const LOAD_LO_OFFICE_DASHBOARD: string = "LOAD_LO_OFFICE_DASHBOARD";
export const RESET_LO_OFFICE_BREADCRUMB: string = "RESET_LO_OFFICE_BREADCRUMB";
export const SHOW_LO_OFFICE_DETAILS_TAB: string = "SHOW_LO_OFFICE_DETAILS_TAB";
export const CLEAN_LO_OFFICE_LOCATION_DASHBOARD: string = "CLEAN_LO_OFFICE_LOCATION_DASHBOARD";
export const CHANGE_LO_OFFICE_NAME_FILTER_VALUE: string = "CHANGE_LO_OFFICE_NAME_FILTER_VALUE";
export const CHANGE_LO_OFFICE_CODE_FILTER_VALUE: string = "CHANGE_LO_OFFICE_CODE_FILTER_VALUE";

export const SHOW_HIDE_LO_OFFICE_ADD_EDIT_LOCATION_POP_UP: string = "SHOW_HIDE_LO_OFFICE_ADD_EDIT_LOCATION_POP_UP";
// Add Pop Up
export const LOAD_ADD_LO_OFFICE: string = "LOAD_LO_OFFICE";

export const SHOW_HIDE_ADD_EDIT_LO_OFFICE_POP_UP: string = "SHOW_HIDE_ADD_EDIT_LO_OFFICE_POP_UP";

export const CHANGE_LO_OFFICE_NAME: string = "CHANGE_LO_OFFICE_NAME";
export const CHANGE_LO_OFFICE_STREET: string = "CHANGE_LO_OFFICE_STREET";
export const CHANGE_LO_OFFICE_CITY: string = "CHANGE_LO_OFFICE_CITY";
export const CHANGE_LO_OFFICE_STATE: string = "CHANGE_LO_OFFICE_STATE";
export const CHANGE_LO_OFFICE_COUNTRY: string = "CHANGE_LO_OFFICE_COUNTRY";
export const CHANGE_LO_OFFICE_PIN: string = "CHANGE_LO_OFFICE_PIN";
export const CHANGE_LO_OFFICE_PHONE: string = "CHANGE_LO_OFFICE_PHONE";
export const CHANGE_LO_OFFICE_CODE: string = "CHANGE_LO_OFFICE_CODE";
export const CHANGE_LO_EXTERNAL_ID: string = "CHANGE_LO_EXTERNAL_ID";

export const SAVE_NEW_LO_OFFICE: string = "SAVE_NEW_LO_OFFICE";

export const DELETE_LO_OFFICE_LOCATION: string = "DELETE_LO_OFFICE_LOCATION";
export const SAVE_LO_OFFICE_LOCATION: string = "SAVE_LO_OFFICE_LOCATION";
// Alert
export const OPEN_CLOSE_LO_OFFICE_ALERT_MODAL: string = "OPEN_CLOSE_LO_OFFICE_ALERT_MODAL";

// API Get
export const LOAD_LO_OFFICE_LOCATION_DATA_URL: string = "Location/view/loOffice";

//post
export const ADD_NEW_LO_OFFICE_URL = "Location/addNewLoOffice";

//URL
export const GET_LO_OFFICE_DELETE_URL = "Location/deleteLoOffice";
// DELETE
export const OPEN_CLOSE_LO_OFFICE_CONFIRM_MODAL = 'OPEN_CLOSE_LO_OFFICE_CONFIRM_MODAL';



