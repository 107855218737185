import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TaskTypeDashboard from './TaskTypeDashboard';
import { TaskTypeActions } from '../Actions/TaskTypeActions';
import { TASK_TYPE_BREADCRUMBS } from '../Constants/TaskTypeConstants';
import { Breadcrumb } from 'office-ui-fabric-react';

const mapStateToProps = (state) => {
    return {
        TaskType: state.TaskTypeReducer
    }
}

class TaskTypesComponent extends PureComponent {

    render() {

        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Breadcrumb
                            className="custom-breadcrumb"
                            items={TASK_TYPE_BREADCRUMBS}
                            ariaLabel="With last item rendered as heading"
                            overflowAriaLabel="More links"
                        />
                    </div>

                </div>
                <TaskTypeDashboard
                    {...this.props}
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let TaskTypeAction = new TaskTypeActions();

    return bindActionCreators({ ...TaskTypeAction }, dispatch);
}

const TaskTypes = connect(mapStateToProps, mapDispatchToProps)(TaskTypesComponent);
export default TaskTypes;
