import * as React from 'react';
import { DefaultButton, Dialog, DialogType, IModalProps, Label, PrimaryButton } from 'office-ui-fabric-react';
import { AssetAPI } from '../Services/AssetAPI';
import { IconConstants } from '../../../Constants/StylesConstants';
import Select from 'react-select';
import { ISelect } from '../../../CommonInterfaces/CommoInterfaces';

const modalProps: IModalProps = {
    isBlocking: false,
    styles: {
        main: {
            width: 750,
        }
    },
};

export default class ShiftAssetModal extends React.Component<any, any> {

    private _assetApi: AssetAPI;

    public constructor(props: any) {
        super(props);
        this._assetApi = new AssetAPI(window.API_URL, window.loggedInUserDetails);
    }

    public saveorUpdateAsset = (): void => {

        let {
            selectedClientFilter,
            selectedAssetDescription,
            selectedAssetId,
            newAssetParentSite
        } = this.props.Asset;

        let assetPayload = {
            id: selectedAssetId,
            assetTypeId: selectedAssetDescription.assetType.value,
            companyId: selectedClientFilter.value,
            parentId: newAssetParentSite.value,
            objectType: "ASSET",
            name: selectedAssetDescription.name.value,
            endUserName: selectedAssetDescription.endUserName,
            endUserAddress: selectedAssetDescription.endUserAddress,
            type: 1,
            status: 1,
            locationLevel: 1,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        this.props.saveAssetDescription({
            apiObject: this._assetApi,
            locationPayload: assetPayload
        });
    }

    public render(): JSX.Element {

        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "SHIFT SITE",
            subText: ""
        };

        let {
            hideShiftAssetDialogue,
            selectedAssetDescription,
            newAssetParentSite,
            sites
        } = this.props.Asset;

        return (
            <Dialog
                hidden={hideShiftAssetDialogue}
                // onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                className="shift-asset-modal"
            >
                <React.Fragment>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <Label className="breadcrumb-label">Current Site</Label>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8">
                                <Label className="breadcrumb-label">{selectedAssetDescription?.parentSite?.label}</Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label>Site</Label>
                                <Select
                                    value={newAssetParentSite}
                                    options={sites}
                                    onChange={(newVal: ISelect) => {
                                        this.props.changeAssetSite({
                                            selectedLocation: newVal
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    text="Save"
                                    menuIconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.saveorUpdateAsset();
                                    }}
                                />
                                {` `}
                                <DefaultButton
                                    text="Cancel"
                                    menuIconProps={IconConstants.cancelIcon}
                                    onClick={() => {
                                        this.props.loadOpenObservationsAsync({
                                            hideShiftAssetDialogue: true,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </Dialog >
        )
    }
}
