import { ISelect } from "../../../CommonInterfaces/CommoInterfaces";
import { IAssessment } from "../Interfaces/IAssessment";
import { IAssessmentsInitialState } from "../Interfaces/IAssessmentsInitialState";

export const AssessmentsInitialState: IAssessmentsInitialState = {
    isLoadingAssessmentsDashboard: true,
    assessmentsCount: 0,
    isHideCertificatePanel: true,
    isLoadingCertificatePanel: false,
    assessments: [] as IAssessment[],
    offset: -1,
    limit: 500,
    selectedAssessment: null,
    isOpenAssessmentHistoryPanel: false,
    hasMoreAssessments: true,
    assessmentGridDataState: {
        sort: [{ field: "name", dir: "asc" }],
        filter: { filters: [], logic: 'and' },
        skip: 0
    },
    proccessedAssessments: [],
    selectedTaskId: -1,
    PDFURL: "",
    assessmentType: "TASK",
    assessmentClientFilters: [] as ISelect[],
    assessmentSiteFilters: [] as ISelect[],
    isLoadingAssessmentClients: true,
    isLoadingAssessmentSites: false,
    selectedAssessmentClient: null,
    selectedAssessmentSite: null,
    alertJSON: {
        alertHeader: "",
        alertSubText: "",
        hideAlert: true
    },
    issueDateRange: {
        start: null,
        end: null
    },
    expiryDateRange: {
        start: null,
        end: null
    },
    assessmentReportSortedColumn: null,
    assessmentReportMaxDataPerPage: 7,
    showColumns: [
        'location',
        'acronymCode',
        'specifications',
        'taskType',
        'result',
        'actualInspectionDate',
        'dueDate',
        'certificateNumber',
        'site',
        'id',
        'itemStatus'
    ],
}
