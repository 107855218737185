import * as React from 'react'
import { Label, PrimaryButton, TextField } from 'office-ui-fabric-react';
import { TrialFitPicLibraryAPI } from '../Service/TrialFitPicLibraryAPI';
import { ITrialFitPicLibraryAPI } from '../Interfaces/ITrialFitPicLibraryAPI';
import { IconConstants } from '../../../../Constants/StylesConstants';
import { TrialFitPicLibraryUtility } from '../Utility/TrialFitPicLibraryUtility';
export default class AddEditFileSection extends React.PureComponent<any, any> {

    private _apiObject: ITrialFitPicLibraryAPI = null;
    constructor(props) {
        super(props);
        this._apiObject = new TrialFitPicLibraryAPI(window.API_URL, window.loggedInUserDetails);
    }

    public componentDidMount() {
    }

    public saveOrUpdateFile = (event: any) => {

        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;

        let { selectedDocument } = this.props.TrialFitPicLibraryState;
        selectedDocument.clientId = selectedClient.value;
        selectedDocument.siteId = selectedSite.value;
        let saveflag: boolean = true;
        const formData = new FormData();

        if (event) {
            let files = event.target.files;
            let totalFileSize = 0;
            for (let index = 0; index < files.length; index++) {
                totalFileSize += files[0].size;
                if (window.location.hash.split("/")[2] !== "trialFitPicLibrary") {
                    saveflag = files[index].type === "image/png"
                        || files[index].type === "image/jpeg"
                        || files[index].type === "application/pdf"
                        || files[index].type === "application/msword"
                        || files[index].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        || files[index].type === "application/vnd.ms-excel"
                        || files[index].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        || files[index].type === "text/csv";
                } else {
                    saveflag = files[index].type === "image/png" || files[index].type === "image/jpeg";
                }
                if (totalFileSize > 47185920) {
                    saveflag = false;
                    break;
                }
                formData.append(`technicalLibraryFile`, files[index], files[index].name);
            }

        }
        formData.append("documentDTO", JSON.stringify(selectedDocument));

        if (!saveflag) {
            this.props.showHideFileUploadErrorMessage({
                fileUploadErrorMessage: TrialFitPicLibraryUtility.getFileUploadErrorMessage()
            })
        } else {
            this.props.saveFile({
                apiObject: this._apiObject,
                selectedDocument: selectedDocument,
                libraryName: TrialFitPicLibraryUtility.getLibraryName(window.location.hash.split("/")[2]),
                fileUploadErrorMessage: "",
                formData,
            });
        }

    }

    public render(): JSX.Element {

        let {
            selectedDocument,
            entityNameErrorMessage,
            fileUploadErrorMessage
        } = this.props.TrialFitPicLibraryState;

        return (

            <div className="ms-Grid" dir="ltr">
                {selectedDocument.id ?
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                placeholder={`File Name`}
                                value={selectedDocument.name}
                                onChange={(ev, newVal) => {
                                    this.props.changeDocumentName({
                                        documentName: newVal,
                                        documentType: "file"
                                    })
                                }}
                                errorMessage={entityNameErrorMessage}
                                required
                                underlined
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
                <br />
                {selectedDocument.id ?
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10" />
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <PrimaryButton
                                text="Save"
                                className="horizontal-button"
                                menuIconProps={IconConstants.saveicon}
                                disabled={!selectedDocument.name || entityNameErrorMessage}
                                onClick={() => this.saveOrUpdateFile(null)}
                            />
                        </div>
                    </div>
                    :
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <input
                                type="file"
                                multiple
                                disabled={entityNameErrorMessage}
                                onChange={(event: any) => this.saveOrUpdateFile(event)}
                                accept={TrialFitPicLibraryUtility.getFiletypeAllowed(window.location.hash.split("/")[2])}
                            />
                            <Label><span className="info-span">{TrialFitPicLibraryUtility.getFileUploadInfo(window.location.hash.split("/")[2])}</span></Label>
                            {fileUploadErrorMessage &&
                                <Label><span className="mandatory-span">{fileUploadErrorMessage}</span></Label>
                            }
                        </div>
                    </div>
                }
            </div>


        )
    }
}
