import { ILoOfficeInitialState, ILoOfficeLocation,} from "../LoOfficeInterface/ILoOffice";

export const LoOfficeInitialState: ILoOfficeInitialState = {
    LoOfficeLocations: [],

    LoadingLoOfficeDashboard: true,
    ShowAddEditLoOfficePopUp: false,
    limit: 50,
    offset: 0,
    locOffset: 0,
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    loOffice_name_filter: "",
    loOffice_code_filter: "",
    hideConfirm: true,
    LoadingAddEditLoOfficePopUp: false,

    CurrentLoOffice: {
        id: -1,
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin: 0,
        phone: "",
        acronymCode: "",
        externalId: ""
    } as ILoOfficeLocation,

    LoOfficeBreadCrumb: [
        { text: 'Admin', key: 'Admin' },
        { text: 'LO Office', key: 'LOOffice' }
    ],
    
    ShowLoOfficeDetailsTab: false,
    IsLoOfficeEdit: false,

    SelectedLoOfficeLocation: {
        id: -1,
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin: 0,
        phone: "",
        acronymCode: "",
        externalId: ""
    },
    PanelHeaderText: "",

    
}