// Actions

import { IKendoGridColumn } from "../../../CommonInterfaces/CommoInterfaces";

// Header
export const CHANGE_CLIENT_FILTER_NAME: string = "CHANGE_CLIENT_FILTER_NAME";
export const CHANGE_SITE_FILTER_NAME: string = "CHANGE_SITE_FILTER_NAME";
export const LOAD_ASSET_CLIENTS: string = "LOAD_ASSET_CLIENTS";
export const LOAD_ASSET_SITES: string = "LOAD_ASSET_SITES";

export const ASSET_BREADCRUMBS = [
    { text: 'RESOURCES', key: 'Resources' },
    { text: 'Assets', key: 'Assets' }
];

// Dashboard
export const RESET_ASSET_VIEW: string = "RESET_ASSET_VIEW";
export const RESET_ASSET_TAB: string = "RESET_ASSET_TAB";
export const LOAD_ASSET_DASHBOARD: string = "LOAD_ASSET_DASHBOARD";
export const HANDLE_ASSET_GRID_GROUP_SORT_CHANGE: string = "HANDLE_ASSET_GRID_GROUP_SORT_CHANGE";
export const HANDLE_ASSET_GRID_SORT_CHANGE: string = "HANDLE_ASSET_GRID_SORT_CHANGE";
export const HANDLE_ASSET_GRID_EXPAND_CHANGE: string = "HANDLE_ASSET_GRID_EXPAND_CHANGE";
export const HANDLE_ASSET_GRID_FILTER_CHANGE: string = "HANDLE_ASSET_GRID_FILTER_CHANGE";
export const LOAD_FILTERED_ASSET_GRID_DATA: string = "LOAD_FILTERED_ASSET_GRID_DATA";
export const CHANGE_FILTER_DATE_RANGE: string = "CHANGE_FILTER_DATE_RANGE";
export const OPEN_CLOSE_ASSET_ALERT_MODAL: string = "OPEN_CLOSE_ASSET_ALERT_MODAL";

// Panel
export const OPEN_CLOSE_ADD_EDIT_ASSET_PANEL: string = "OPEN_CLOSE_ADD_EDIT_ASSET_PANEL";
export const CHANGE_ASSET_PANEL_TAB: string = "CHANGE_ASSET_PANEL_TAB";

// Description
export const OPEN_ASSET_DESCRIPTION_TAB: string = "OPEN_ASSET_DESCRIPTION_TAB";
export const LOAD_ASSET_DESCRIPTION_TAB: string = "LOAD_ASSET_DESCRIPTION_TAB";
export const SAVE_ASSET_DESCRIPTION_TAB: string = "SAVE_ASSET_DESCRIPTION_TAB";
export const LOAD_OPEN_OBSERVATION_COUNT: string = "LOAD_OPEN_OBSERVATION_COUNT";
export const CHANGE_ASSET_DESCRIPTION_TAB_SITE: string = "CHANGE_ASSET_DESCRIPTION_TAB_SITE";
export const CHANGE_ASSET_DESCRIPTION_TAB_ASSET_NAME: string = "CHANGE_ASSET_DESCRIPTION_TAB_ASSET_NAME";
export const CHANGE_ASSET_DESCRIPTION_TAB_ACRONYM_CODE: string = "CHANGE_ASSET_DESCRIPTION_TAB_ACRONYM_CODE";
export const CHANGE_ASSET_DESCRIPTION_TAB_ASSET_TYPE_NAME: string = "CHANGE_ASSET_DESCRIPTION_TAB_ASSET_TYPE_NAME";
export const CHANGE_ASSET_DESCRIPTION_TAB_END_USER_NAME: string = "CHANGE_ASSET_DESCRIPTION_TAB_END_USER_NAME";
export const CHANGE_ASSET_DESCRIPTION_TAB_END_USER_ADDRESS: string = "CHANGE_ASSET_DESCRIPTION_TAB_END_USER_ADDRESS";
export const CHANGE_ASSET_DESCRIPTION_TAB_EXTERNAL_ID: string = "CHANGE_ASSET_DESCRIPTION_TAB_EXTERNAL_ID";
export const CHANGE_ASSET_SITE: string = "CHANGE_ASSET_SITE";

// Reports
export const OPEN_ASSET_REPORTS_TAB: string = "OPEN_ASSET_REPORTS_TAB";
export const LOAD_ASSET_REPORTS_TAB: string = "LOAD_ASSET_REPORTS_TAB";
export const SORT_ASSET_REPORTS: string = "SORT_ASSET_REPORTS";
export const CHANGE_LIMIT_OF_REPORT_PER_PAGE: string = "CHANGE_LIMIT_OF_REPORT_PER_PAGE";

// Attributes
export const OPEN_ASSET_ATTRIBUTES_TAB: string = "OPEN_ASSET_ATTRIBUTES_TAB";
export const CHANGE_ASSET_ATTRIBUTES_VALUE: string = "CHANGE_ASSET_ATTRIBUTES_VALUE";
export const LOAD_ASSET_ATTRIBUTES_TAB: string = "LOAD_ASSET_ATTRIBUTES_TAB";

// Files
export const OPEN_ASSET_FILES_TAB: string = "OPEN_ASSET_FILES_TAB";
export const LOAD_ASSET_FILES_TAB: string = "LOAD_ASSET_FILES_TAB";
export const OPEN_CLOSE_ASSET_CONFIRM_MODAL: string = "OPEN_CLOSE_ASSET_CONFIRM_MODAL";

// URL
export const GET_LOCATION_OBJECT_URL: string = "Location/Object";
export const GET_FORM_JSON_URL: string = "Setting/FormJSON";
export const SAVE_ASSET_ATTRIBUTES_URL: string = "Location/Asset";
export const GET_FORM_DATA_URL: string = "Setting/formdata";
export const SAVE_LOCATION_URL: string = "Location";
export const GET_SINGLE_LOCATION_OBJECT_URL: string = "Location/location_id";
export const GET_ASSET_DASHBOARD_URL: string = "Location/AssetDashboard";
export const GET_ALL_ASSET_TYPES_URL: string = "Setting/Others";
export const GET_ALL_OBSERVATIONS_FOR_ASSET_URL: string = "Observation/Asset";
export const GET_ALL_OPEN_OBSERVATIONS_FOR_ASSET_URL: string = "Location/Observation/";
export const SAVE_ASSET_FILES_URL = "Location/saveAttachment";
export const DELETE_ASSET_FILES_URL = "Location/deleteAttachment";
export const SAVE_CHILD_LLG_TASK_URL = "createChildLLGObservation";

// Status
export const ASSET_STATUS = {
    1: { status: "ACTIVE", color: "#99ff99" },
    0: { status: "INACTIVE", color: "#ffcccb" },
}

// Asset Kendo Grid Columns
export const ASSET_VIEW_GRID_COLUMNS: IKendoGridColumn[] = [
    {
        title: "History",
        field: "id",
        show: true,
        filter: "text",
        width: 80,
        colIndex: 1
    },
    {
        title: "Type",
        field: "object_type",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 2
    },
    {
        title: "ID",
        field: "name",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 2
    },
    {
        title: "Serial No.",
        field: "acronymCode",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 3
    },
    {
        title: "Item",
        field: "assetTypeDesc",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 4
    },
    {
        title: "Specifications",
        field: "specifications",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 5
    },
    {
        title: "Client",
        field: "client",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 6
    },
    {
        title: "Site",
        field: "site",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 7
    },
    {
        title: "Status",
        field: "status",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 8
    },
    {
        title: "External",
        field: "externalId",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 9
    },
    {
        title: "Asset Type",
        field: "assetTypeId",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 10
    },
    {
        title: "End User Name",
        field: "endUserName",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 11
    },
    {
        title: "End User Address",
        field: "endUserAddress",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 12
    },
    {
        title: "Last Inspection",
        field: "actualInspectionDate",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 13
    },
    {
        title: "Due Date",
        field: "dueDate",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 14
    },
    {
        title: "Last Result",
        field: "result",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 15
    },
    {
        title: "Last Cert.",
        field: "certificateNumber",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 16
    },
    {
        title: "Report",
        field: "certificateFile",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 17
    },
    {
        title: "Current Status",
        field: "itemStatus",
        show: true,
        filter: "custom",
        width: 150,
        colIndex: 18
    },

];

export const ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH: number = 200;
export const ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH: number = 150;

//Asset Files
export const UPLOAD_ASSET_FILES: string = 'UPLOAD_ASSET_FILES';
export const DELETE_ASSET_FILE: string = 'DELETE_ASSET_FILE';
