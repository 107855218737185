import { IClientInventory, IClientInventoryActionKeys, IClientInventoryInitialState, IClientInventoryPayload }
    from "../ClientInventoryInteface/IClientInventory";
import { ClientInventoryInitialState } from "../Constants/ClientInventoryInitialState";
import ClientInventoryUtility from "../Utility/ClientInventoryUtility";
import * as Constants from '../Constants/ClientInventoryConstants';

export function ClientInventoryReducer(state: IClientInventoryInitialState = ClientInventoryInitialState,
    action: IClientInventoryActionKeys) {

    let tempState: IClientInventoryInitialState = { ...state };
    let payload: IClientInventoryPayload = action.payload;
    let tempClientInventories: IClientInventory[] = [];
    let tempClientInventory: IClientInventory = null;
    let indexOfItem: number = -1;

    switch (action.type) {

        case Constants.RESET_CLIENT_INVENTORY_DASHBOARD:
            tempState.isLoadingClientInventory = true;
            tempState.clientInventories = [];
            tempState.searchedItem = "";
            tempState.searchedDescription = "";
            tempState.searchedQuantity = "";
            tempState.isOpenDeleteConfirmModal = false;
            tempState.selectedClientInventory = null;
            break;

        case Constants.LOAD_CLIENT_INVENTORY:
        case Constants.DELETE_CLIENT_INVENTORY:
            tempState.isLoadingClientInventory = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {
                tempState.clientInventories = ClientInventoryUtility.populateClientInventoryTable([...payload.clientInventories]);
            }
            break;


        case Constants.CHANGE_CLIENT_INVENTORY_ITEM:
            tempClientInventories = [...tempState.clientInventories];
            indexOfItem = tempClientInventories.findIndex((inventory: IClientInventory) =>
                inventory.id === payload.selectedClientInventory.id);
            tempClientInventory = { ...tempClientInventories[indexOfItem] }
            tempClientInventory.productCategory = payload.value;
            tempClientInventories[indexOfItem] = tempClientInventory;
            tempState.clientInventories = tempClientInventories;
            break;

        case Constants.CHANGE_CLIENT_INVENTORY_DESCRIPTION:
            tempClientInventories = [...tempState.clientInventories];
            indexOfItem = tempClientInventories.findIndex((inventory: IClientInventory) =>
                inventory.id === payload.selectedClientInventory.id);
            tempClientInventory = { ...tempClientInventories[indexOfItem] }
            tempClientInventory.description = payload.value;
            tempClientInventories[indexOfItem] = tempClientInventory;
            tempState.clientInventories = tempClientInventories;
            break;

        case Constants.CHANGE_CLIENT_INVENTORY_QUANTITY:
            tempClientInventories = [...tempState.clientInventories];
            indexOfItem = tempClientInventories.findIndex((inventory: IClientInventory) =>
                inventory.id === payload.selectedClientInventory.id);
            tempClientInventory = { ...tempClientInventories[indexOfItem] }
            tempClientInventory.quantity = parseInt(payload.value);
            tempClientInventories[indexOfItem] = tempClientInventory;
            tempState.clientInventories = tempClientInventories;
            break;

        case Constants.CHANGE_CLIENT_INVENTORY_TONNE:
            tempClientInventories = [...tempState.clientInventories];
            indexOfItem = tempClientInventories.findIndex((inventory: IClientInventory) =>
                inventory.id === payload.selectedClientInventory.id);
            tempClientInventory = { ...tempClientInventories[indexOfItem] }
            tempClientInventory.tonne = parseFloat(payload.value);
            tempClientInventories[indexOfItem] = tempClientInventory;
            tempState.clientInventories = tempClientInventories;
            break;
        
        case Constants.CHANGE_CLIENT_INVENTORY_MANUFACTURER:
            tempClientInventories = [...tempState.clientInventories];
            indexOfItem = tempClientInventories.findIndex((inventory: IClientInventory) =>
                inventory.id === payload.selectedClientInventory.id);
            tempClientInventory = { ...tempClientInventories[indexOfItem] }
            tempClientInventory.manufacturer = payload.value;
            tempClientInventories[indexOfItem] = tempClientInventory;
            tempState.clientInventories = tempClientInventories;
            break;
        
        case Constants.CHANGE_CLIENT_INVENTORY_LOCATION:
            tempClientInventories = [...tempState.clientInventories];
            indexOfItem = tempClientInventories.findIndex((inventory: IClientInventory) =>
                inventory.id === payload.selectedClientInventory.id);
            tempClientInventory = { ...tempClientInventories[indexOfItem] }
            tempClientInventory.location = payload.value;
            tempClientInventories[indexOfItem] = tempClientInventory;
            tempState.clientInventories = tempClientInventories;
            break;
        
        case Constants.CHANGE_CLIENT_INVENTORY_COMMENTS:
                tempClientInventories = [...tempState.clientInventories];
                indexOfItem = tempClientInventories.findIndex((inventory: IClientInventory) =>
                    inventory.id === payload.selectedClientInventory.id);
                tempClientInventory = { ...tempClientInventories[indexOfItem] }
                tempClientInventory.comments = payload.value;
                tempClientInventories[indexOfItem] = tempClientInventory;
                tempState.clientInventories = tempClientInventories;
                break;
    
        case Constants.CHANGE_CLIENT_INVENTORY_ROW_EDIT:
            tempClientInventories = [...tempState.clientInventories];
            indexOfItem = tempClientInventories.findIndex((inventory: IClientInventory) =>
                inventory.id === payload.selectedClientInventory.id);
            tempClientInventory = { ...tempClientInventories[indexOfItem] }
            tempClientInventory.isEnabledEdit = payload.value ? true : false;
            tempClientInventories[indexOfItem] = tempClientInventory;
            tempState.clientInventories = tempClientInventories;
            break;

        case Constants.CHANGE_CLIENT_INVENTORY_ITEM_SEARCH:
            tempState.searchedItem = payload.value;
            break;

        case Constants.CHANGE_CLIENT_INVENTORY_DESCRIPTION_SEARCH:
            tempState.searchedDescription = payload.value;
            break;

        case Constants.CHANGE_CLIENT_INVENTORY_QUANTITY_SEARCH:
            tempState.searchedQuantity = payload.value;
            break;
        case Constants.CHANGE_CLIENT_INVENTORY_TONNE_SEARCH:
            tempState.searchedTonne = payload.value;
            break;
        case Constants.CHANGE_CLIENT_INVENTORY_LOCATION_SEARCH:
            tempState.searchedLocation = payload.value;
            break;
        case Constants.CHANGE_CLIENT_INVENTORY_MANUFACTURER_SEARCH:
            tempState.searchedManufacturer = payload.value;
            break;
        case Constants.CHANGE_CLIENT_INVENTORY_COMMENTS_SEARCH:
            tempState.searchedComments = payload.value;
            break;
    

        case Constants.OPEN_CLOSE_CLIENT_INVENTORY_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON;
            break;

        case Constants.OPEN_CLOSE_CLIENT_INVENTORY_CONFIRM_DELETE_MODAL:
            tempState.isOpenDeleteConfirmModal = payload.isOpenDeleteConfirmModal;
            tempState.selectedClientInventory = payload.selectedClientInventory;
            break;


        case Constants.SAVE_CLIENT_INVENTORY:
            tempState.isLoadingClientInventory = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {
                tempState.clientInventories = ClientInventoryUtility.populateClientInventoryTable([...payload.clientInventories]);
            }
            break;

        default:
            break;
    }

    return tempState;

}