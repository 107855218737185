import axios from "axios";
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import { QRSTICKER_MAIN_URL } from "../Constants/QRStickerConstants";

export class QRStickerAPI {

    _URL = "";
    constructor(URL) {
        this._URL = URL;
    }
    saveQRSticker(qrStickerPayload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(QRSTICKER_MAIN_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                , qrStickerPayload
            ).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.error("Error QRSticker method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

}