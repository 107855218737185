import * as React from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { ApplicationTypes } from './TreeData';
import { ActionButton, Dropdown, IDropdownOption, Separator, TextField } from 'office-ui-fabric-react';
import { IconConstants } from '../../Constants/StylesConstants';



export default class ReactTreeDemo extends React.Component<any, any> {

    public constructor(props: any) {
        super(props);
        this.state = {
            nodes: [],
            expandedKeys: {}
        };
    }

    public onEditorValueChange = (props: any, value: any): void => {
        let newNodes = JSON.parse(JSON.stringify(this.state.nodes));
        let editedNode = this.findNodeByKey(newNodes, props.node.key);
        editedNode.data[props.field] = value;

        if (props.field === "type") {
            editedNode.data["typeId"] = value;
            editedNode.data[props.field] = ApplicationTypes.find((d: IDropdownOption) => d.key === value).text;
        }
        else {
            editedNode.data[props.field] = value;
        }

        this.setState({
            nodes: newNodes
        });
    }

    public findNodeByKey = (nodes: any, key?: any): any => {
        let path: any = key.split('-');
        let node: any;

        while (path.length) {
            let list = node ? node.children : nodes;
            node = list[parseInt(path[0], 10)];
            path.shift();
        }

        return node;
    }

    public addNewNode = (): any => {

        let tempNodes: any[] = [...this.state.nodes];
        tempNodes.push({
            key: tempNodes.length.toString(),
            data: {
                name: `MyFolder-${tempNodes.length}`,
                size: "500kb",
                typeId: null,
                type: ""
            },
            children: []
        });

        this.setState({
            nodes: tempNodes
        })

    }

    public editNode = (node: any) => {
        let newNodes = JSON.parse(JSON.stringify(this.state.nodes));
        let editedNode: any = this.findNodeByKey(newNodes, node.key);
        let newNode: any = {
            key: `${node.key}-${editedNode.children.length}`,
            data: { name: `${editedNode.data.name}${editedNode.children.length}`, size: "10kb", typeId: null, type: "" },
            children: []
        }
        editedNode.children.push(newNode);
        this.setState({
            nodes: newNodes
        })
    }

    public deleteNode = (node: any) => {
        let newNodes = JSON.parse(JSON.stringify(this.state.nodes));
        let parentNodeKey: any = this.getParentNodeKey(node);
        if (parentNodeKey) {
            let editedNode: any = this.findNodeByKey(newNodes, this.getParentNodeKey(node));
            let indexOfDeletedNode: any = editedNode.children.findIndex((data: any) => data.key === node.key);
            editedNode.children.splice(indexOfDeletedNode, 1);
        }
        else {
            let indexOfDeletedNode: any = newNodes.findIndex((data: any) => data.key === node.key);
            newNodes.splice(indexOfDeletedNode, 1);
        }

        this.setState({
            nodes: newNodes
        })
    }

    public getParentNodeKey = (node: any): any => {

        let nodeKeyArray: string[] = node.key.split("-");
        if (nodeKeyArray.length === 1) {
            return null;
        }
        else {
            return nodeKeyArray.slice(0, nodeKeyArray.length - 1).join("-")
        }
    }

    public inputTextEditor = (props: any, field: any): any => {
        return (
            <TextField
                value={props.node.data[field]}
                onChange={(event: any, newVal: string) => this.onEditorValueChange(props, newVal)} />
        );
    }

    public dropDownEditor = (props: any, field: any): any => {
        return (
            <Dropdown
                selectedKey={props.node.data[field]}
                onChange={(event: any, option: IDropdownOption) => this.onEditorValueChange(props, option.key)}
                options={ApplicationTypes}
            />
        );
    }

    nameEditor = (props: any): any => {
        return this.inputTextEditor(props, 'name');
    }

    sizeEditor = (props: any): any => {
        return this.inputTextEditor(props, 'size');
    }

    typeEditor = (props: any): any => {
        return this.dropDownEditor(props, 'size');
    }

    public actionTemplate = (node: any, column: any) => {
        return (
            <React.Fragment>
                <ActionButton
                    text="Add Child"
                    iconProps={IconConstants.addIcon}
                    onClick={() => {
                        this.editNode(node)
                    }}
                />
                {` `}
                <ActionButton
                    text="Delete"
                    iconProps={IconConstants.deleteicon}
                    onClick={() => {
                        this.deleteNode(node)
                    }}
                />
            </React.Fragment>
        );
    }


    public render(): JSX.Element {
        return (
            <React.Fragment>
                <Separator />
                <ActionButton
                    text="Add New Node"
                    menuIconProps={IconConstants.addIcon}
                    onClick={() => {
                        this.addNewNode()
                    }}
                />
                {` `}
                <ActionButton
                    text="Refresh"
                    menuIconProps={IconConstants.refreshIcon}
                    onClick={() => {
                        this.setState({
                            nodes: []
                        })
                    }}
                />
                <TreeTable value={this.state.nodes}>
                    <Column field="name" editor={this.nameEditor} header="Name" expander></Column>
                    <Column field="size" editor={this.sizeEditor} header="Size"></Column>
                    <Column field="type" editor={this.typeEditor} header="Type"></Column>
                    <Column body={this.actionTemplate} />
                </TreeTable>
            </React.Fragment>
        );
    }
}
