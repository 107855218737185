
export class UserUtility {

    resetSelectedUser = (SelectedUser, CompanyOptions, OfficeOptions, SiteOptions, RoleOptions, DivisionOptions) => {

        let OtherOffices = [];
        let Divisions = [];
        let ClientSites = [];

        if (SelectedUser) {
            if (SelectedUser.divisionIds) {
                SelectedUser.divisionIds.split(",").forEach(element => {
                    Divisions.push({
                        value: DivisionOptions.find(data => data.value === parseInt(element)).value,
                        label: DivisionOptions.find(data => data.value === parseInt(element)).label
                    })
                });
            }
            else {
                Divisions = null;
            }
            if (SelectedUser.secondaryOfficeIds) {
                SelectedUser.secondaryOfficeIds.split(",").forEach(element => {
                    OtherOffices.push({
                        value: OfficeOptions.find(data => data.value === parseInt(element)).value,
                        label: OfficeOptions.find(data => data.value === parseInt(element)).label
                    })
                });
            }
            else {
                OtherOffices = null;
            }
            if (SelectedUser.siteIds) {
                SelectedUser.siteIds.split(",").forEach(element => {
                    ClientSites.push({
                        value: SiteOptions.find(data => data.value === parseInt(element)).value,
                        label: SiteOptions.find(data => data.value === parseInt(element)).label
                    })
                });
            }
            else {
                ClientSites = null;
            }
        }
        else {
            Divisions = null;
            OtherOffices = null;
            ClientSites = null;
        }

        return {
            id: SelectedUser ? SelectedUser.id : null,
            name: {
                error: true,
                message: "",
                value: SelectedUser ? SelectedUser.name : ""
            },
            email: {
                error: true,
                message: "",
                value: SelectedUser ? SelectedUser.email : ""
            },
            phone: {
                error: false,
                message: "",
                value: SelectedUser ? SelectedUser.phone : ""
            },
            description: {
                error: false,
                message: "",
                value: SelectedUser ? SelectedUser.description ? SelectedUser.description : "" : ""
            },
            Company: {
                value: (SelectedUser && SelectedUser.companyId) ? {
                    value: CompanyOptions.find(data => data.value === SelectedUser.companyId).value,
                    label: CompanyOptions.find(data => data.value === SelectedUser.companyId).label
                } : null,
                error: true,
                message: ""
            },
            // LO
            PrimaryOffice: {
                value: (SelectedUser && SelectedUser.officeId) ? {
                    value: OfficeOptions.find(data => data.value === SelectedUser.officeId).value,
                    label: OfficeOptions.find(data => data.value === SelectedUser.officeId).label
                } : null,
                error: true,
                message: ""
            },
            OtherOffices: {
                value: OtherOffices,
                error: true,
                message: ""
            },
            //Client
            ClientSites: {
                value: ClientSites,
                error: true,
                message: ""
            },
            Divisions: {
                value: Divisions,
                error: true,
                message: ""
            },
            Designation: {
                value: SelectedUser ? {
                    value: RoleOptions.find(data => data.value === SelectedUser.roleId).value,
                    label: RoleOptions.find(data => data.value === SelectedUser.roleId).label
                } : null,
                error: true,
                message: ""
            },
            externalId:{
                value: SelectedUser?.externalId,
                error: true,
                message: ""
            },
            Signature: {
                imageURL: (SelectedUser && SelectedUser.signatureFile) ?
                    `${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${JSON.parse(SelectedUser.signatureFile).blobKey}`
                    : "",
                uploadImageErrorText: "",
                deleteImageErrorText: ""
            },
            type: SelectedUser ? SelectedUser.type : null,
            createdDate: SelectedUser ? SelectedUser.createdDate : null,
            modifiedDate: SelectedUser ? SelectedUser.modifiedDate : null
        }
    }

    populateDropDownMenu = (PreItemArray) => {

        let PostItemArray = [];
        PreItemArray.forEach(element => {
            element.value = element.id;
            element.label = element.name;
            PostItemArray.push(element);
        });

        return PostItemArray;

    }
}