import React, { Component } from 'react'
import { AssetTypeAPI } from '../Services/AssetTypeAPI';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Attributes from './Attributes';
import Description from './Description';
import AssetTypeFiles from './AssetTypeFiles';
import { Panel, PanelType, Spinner, SpinnerSize, ActionButton, PrimaryButton } from 'office-ui-fabric-react';
import * as AssetTypeConstants from './../Constants/AssetTypeConstants';
import AlertRedux from '../../../Alert/AlertRedux';
import { IconConstants } from '../../../../Constants/StylesConstants';

export default class AddEditAssetTypePanel extends Component {

    _assetTypeAPI = null;
    constructor(props) {
        super(props);
        this._assetTypeAPI = new AssetTypeAPI(window.API_URL);
    }

    // UNSAFE_componentWillMount() {
    //     this.props.loadDropDownDataAsync({
    //         apiObject: this._assetTypeAPI,
    //     });
    // }

    render() {

        let {
            PanelHeaderText,
            ShowAssetTypeAddEditPanel,
            TabIndex,
            LoadingAssetTypeAddEditPanel,
            CurrentAssetType,
            alertJSON
        } = this.props.AssetType

        return (
            <Panel
                headerText={PanelHeaderText + (CurrentAssetType.id > -1 ? CurrentAssetType.name : "")}
                isOpen={ShowAssetTypeAddEditPanel}
                type={PanelType.extraLarge}
                onDismiss={() => this.props.closeAssetTypePanel({
                    ShowPanel: false,
                    PanelHeaderText: "",
                })}
                closeButtonAriaLabel="Close">
                {LoadingAssetTypeAddEditPanel ?
                    <Spinner
                        size={SpinnerSize.large}
                        label="Please wait..."
                    />
                    :
                    <React.Fragment>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ActionButton
                                    disabled={!CurrentAssetType.name}
                                    text="SAVE"
                                    iconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveAssetTypeDetailsAsync({
                                            CurrentAssetType: CurrentAssetType,
                                            apiObject: this._assetTypeAPI
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <Tabs selectedIndex={TabIndex} onSelect={(index) => this.props.changeAssetTypeTab({ TabIndex: index })}>
                            <TabList>
                                <Tab>{AssetTypeConstants.ASSET_TYPE_TABS[0]}</Tab>
                                <Tab>{AssetTypeConstants.ASSET_TYPE_TABS[1]}</Tab>
                                <Tab disabled>{AssetTypeConstants.ASSET_TYPE_TABS[2]}</Tab>
                            </TabList>

                            <TabPanel>
                                <Description
                                    {...this.props}
                                />
                            </TabPanel>

                            <TabPanel>
                                <Attributes
                                    {...this.props}
                                />
                            </TabPanel>

                            <TabPanel>
                                <AssetTypeFiles
                                    {...this.props}
                                />
                            </TabPanel>

                        </Tabs>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    disabled={!CurrentAssetType.name}
                                    text="SAVE"
                                    menuIconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveAssetTypeDetailsAsync({
                                            CurrentAssetType: CurrentAssetType,
                                            apiObject: this._assetTypeAPI
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                }
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </Panel>
        )
    }
}
