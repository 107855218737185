export const UserInitialState = {
    Users: [],
    loadingDashboard: false,
    loadingAddEditUserPanel: false,
    loadingCompanyData: false,
    loadingSiteOfficeData: false,
    ShowPanel: false,
    FilteredUserEmails: [],
    PanelHeaderText: "",
    LocationNodes: [],
    TaskTypeNodes: [],
    AllDesignations: [],
    AllContractors: [],
    ShowActiveUsers: true,
    
    ClientOptions: [],
    CompanyOptions: [],
    SiteOptions: [],
    RoleOptions: [],
    DivisionOptions: [],
    OfficeOptions: [],
    tabIndex: 0,
    offset: 0,
    limit: 100,
    filters: {},
    TotalUsersCount: 0,
    HasMoreUsers: true,
    Expanded: [],
    hideConfirm: true,
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    isLoadingUserSignature: false,
    CurrentUserId: -1,
    CurrentUser: {
        id: null,
        name: {
            error: true,
            message: "",
            value: ""
        },
        email: {
            error: true,
            message: "",
            value: ""
        },
        phone: {
            error: false,
            message: "",
            value: ""
        },
        description: {
            error: false,
            message: "",
            value: ""
        },
        Designation: {
            value: null,
            error: true,
            message: ""
        },
        Company: {
            value: null,
            error: true,
            message: ""
        },
        PrimaryOffice: {
            value: null,
            error: true,
            message: ""
        },
        OtherOffices: {
            value: null,
            error: true,
            message: ""
        },
        ClientSites: {
            value: null,
            error: true,
            message: ""
        },
        Divisions: {
            value: null,
            error: true,
            message: ""
        },
        externalId: {
            value: null,
            error: true,
            message: ""
        },
        type: null,
        createdDate: new Date(),
        modifiedDate: new Date(),
        Signature: {
            imageURL: "",
            uploadImageErrorText: "",
            deleteImageErrorText: "",
        },
        
    },
    sortedColumn: "",
    sortOrder: "asc",
    ToBeUpdatedStatus: "",
    user_name_filter_value: "",
    user_email_filter_value: "",
    user_designation_filter_value: "",
    user_location_filter_value: "",
    user_division_filter_value: "",
    user_status_filter_value: "",
}