// Dashboard

import { CLIENT_ADMIN_ROLE, REQUESTOR_ROLE, SITE_USER_ROLE } from "../../../Constants/CommonConstants";

export const CLEAN_CLIENT_DASHBOARD: string = "CLEAN_CLIENT_DASHBOARD";
export const LOAD_CLIENT_DASHBOARD: string = "LOAD_CLIENT_DASHBOARD";
export const RESET_CLIENT_BREADCRUMB: string = "RESET_CLIENT_BREADCRUMB";
export const SHOW_CLIENT_DETAILS_TAB: string = "SHOW_CLIENT_DETAILS_TAB";
export const CLEAN_LOCATION_DASHBOARD: string = "CLEAN_LOCATION_DASHBOARD";
export const LOAD_LOCATION_DASHBOARD: string = "LOAD_LOCATION_DASHBOARD";
export const CHANGE_CLIENT_NAME_FILTER_VALUE: string = "CHANGE_CLIENT_NAME_FILTER_VALUE";
export const CHANGE_CLIENT_CODE_FILTER_VALUE: string = "CHANGE_CLIENT_CODE_FILTER_VALUE";

// Add Pop Up
export const SHOW_HIDE_CLIENT_ADD_CLIENT_POP_UP: string = "SHOW_HIDE_CLIENT_ADD_CLIENT_POP_UP";
export const LOAD_USER_ROLES: string = "LOAD_USER_ROLES";
export const LOAD_ADD_CLIENT_POP_UP_SCREEN: string = "LOAD_ADD_CLIENT_POP_UP_SCREEN";

export const CHANGE_CLIENT_NAME: string = "CHANGE_CLIENT_NAME";
export const CHANGE_CLIENT_STREET: string = "CHANGE_CLIENT_STREET";
export const CHANGE_CLIENT_CITY: string = "CHANGE_CLIENT_CITY";
export const CHANGE_CLIENT_STATE: string = "CHANGE_CLIENT_STATE";
export const CHANGE_CLIENT_COUNTRY: string = "CHANGE_CLIENT_COUNTRY";
export const CHANGE_CLIENT_PIN: string = "CHANGE_CLIENT_PIN";
export const CHANGE_CLIENT_PHONE: string = "CHANGE_CLIENT_PHONE";
export const CHANGE_CLIENT_CODE: string = "CHANGE_CLIENT_CODE";
export const CHANGE_CLIENT_EXTERNAL_ID: string = "CHANGE_CLIENT_EXTERNAL_ID";
export const CHANGE_ADD_CLIENT_OFFICE_ID: string = "CHANGE_ADD_CLIENT_OFFICE_ID";

export const CHANGE_SITE_NAME: string = "CHANGE_SITE_NAME";
export const POPULATE_SITE_ADDRESS_FROM_CLIENT: string = "POPULATE_SITE_ADDRESS_FROM_CLIENT";
export const CHANGE_SITE_STREET: string = "CHANGE_SITE_STREET";
export const CHANGE_SITE_CITY: string = "CHANGE_SITE_CITY";
export const CHANGE_SITE_STATE: string = "CHANGE_SITE_STATE";
export const CHANGE_SITE_COUNTRY: string = "CHANGE_SITE_COUNTRY";
export const CHANGE_SITE_PIN: string = "CHANGE_SITE_PIN";
export const CHANGE_SITE_PHONE: string = "CHANGE_SITE_PHONE";
export const CHANGE_SITE_CODE: string = "CHANGE_SITE_CODE";
export const CHANGE_SITE_EXTERNAL_ID: string = "CHANGE_SITE_EXTERNAL_ID";


export const ADD_NEW_USER: string = "ADD_NEW_USER";
export const DELETE_LAST_USER: string = "DELETE_LAST_USER";
export const CHANGE_CLIENT_USER_ROLE: string = "CHANGE_CLIENT_USER_ROLE";
export const CHANGE_CLIENT_USER_NAME: string = "CHANGE_CLIENT_USER_NAME";
export const CHANGE_CLIENT_USER_EMAIL: string = "CHANGE_CLIENT_USER_EMAIL";
export const CHANGE_CLIENT_USER_PHONE: string = "CHANGE_CLIENT_USER_PHONE";
export const LOAD_EDIT_CLIENT_SCREEN: string = "LOAD_EDIT_CLIENT_SCREEN";
export const SAVE_CLIENT_LOGO: string = "SAVE_CLIENT_LOGO";

export const SAVE_NEW_CLIENT: string = "SAVE_NEW_CLIENT";

// Add Location Pop Up
export const SHOW_HIDE_CLIENT_ADD_EDIT_LOCATION_POP_UP: string = "SHOW_HIDE_CLIENT_ADD_EDIT_LOCATION_POP_UP";
export const SAVE_LOCATION: string = "SAVE_LOCATION";
export const CHANGE_LOCATION_SITE_NAME: string = "CHANGE_LOCATION_SITE_NAME";
export const CHANGE_LOCATION_SITE_STREET: string = "CHANGE_LOCATION_SITE_STREET";
export const CHANGE_LOCATION_SITE_CITY: string = "CHANGE_LOCATION_SITE_CITY";
export const CHANGE_LOCATION_SITE_STATE: string = "CHANGE_LOCATION_SITE_STATE";
export const CHANGE_LOCATION_SITE_COUNTRY: string = "CHANGE_LOCATION_SITE_COUNTRY";
export const CHANGE_LOCATION_SITE_PIN: string = "CHANGE_LOCATION_SITE_PIN";
export const CHANGE_LOCATION_SITE_PHONE: string = "CHANGE_LOCATION_SITE_PHONE";
export const CHANGE_LOCATION_SITE_CODE: string = "CHANGE_LOCATION_SITE_CODE";
export const CHANGE_LOCATION_SITE_EXTERNAL_ID: string = "CHANGE_LOCATION_SITE_EXTERNAL_ID";
export const CHANGE_CLIENT_OFFICE_ID: string = "CHANGE_CLIENT_OFFICE_ID";

// Alert
export const OPEN_CLOSE_CLIENT_ALERT_MODAL: string = "OPEN_CLOSE_CLIENT_ALERT_MODAL";

// API
export const LOAD_LOCATION_BY_TYPE_URL: string = "Location/Object";
export const LOAD_LOCATION_DATA_URL: string = "Location/view";
export const LOAD_LOCATION_COUNT_URL: string = "Location/view/count";
export const GET_SETTING_OBJECTS = "Setting/Objects";
export const ADD_NEW_CLIENT_URL = "Location/addNewClient";
export const SAVE_LOCATION_URL = "Location";
export const LOCATION_ROLE_CONFIG_URL = "Location/RoleConfig/";

export const UPLOAD_CLIENT_IMAGE_URL = `Location/uploadImage/`;
export const GET_CLIENT_IMAGE_URL = `Location/getImage/`;


// DropDownConstants
export const ADD_CLIENT_USER_TYPES: string[] = [REQUESTOR_ROLE, SITE_USER_ROLE, CLIENT_ADMIN_ROLE];
