import { IError, ISelect } from "../../../CommonInterfaces/CommoInterfaces";

export const JobLitesInitialState = {
    Jobs: [],
    TotalJobsCount: 0,
    HasMoreJobs: true,
    SelectedJob: null,
    tabIndex: 0,
    offset: 0,
    limit: 250,
    filters: {},
    ActiveStep: 0,
    CurrentJobDetails: {
        ClientName: {
            value: null,
            error: false,
            errorMessage: ""
        },
        SiteDetails: {
            value: null,
            error: false,
            errorMessage: ""
        },
        StartDate: {
            value: new Date(),
            error: false,
            errorMessage: ""
        },
        RequesterName: {
            value: null,
            error: false,
            errorMessage: ""
        },
        ClientUsers: {
            value: null,
            error: false,
            errorMessage: ""
        },
        AssignOffice: {
            value: null,
            error: false,
            errorMessage: ""
        },
        Price: {
            value: 0,
            error: false,
            errorMessage: ""
        },
        Comments: {
            value: "",
            error: false,
            errorMessage: ""
        },
        MainInspector: {
            value: null,
            error: false,
            errorMessage: ""
        },
        TaskDetails: [
            {
                isNew: true,
                status: 1,
                Division: {
                    value: null,
                    error: false,
                    errorMessage: ""
                },
                TaskType: {
                    value: null,
                    error: false,
                    errorMessage: ""
                },
                AssetType: {
                    value: null,
                    error: false,
                    errorMessage: ""
                },
                Asset: {
                    value: null,
                    error: false,
                    errorMessage: "",
                    loading: false
                },
                Inspector: {
                    value: null,
                    error: false,
                    errorMessage: ""
                },
                AssetOptions: [],
                isDirty: true
            }
        ],
        isDayJob: true,
    },
    scheduleId: -1,
    LoadingDashboard: false,
    LoadingPanel: false,
    LoadingSites: false,
    ShowPanel: false,
    ShowActiveJobs: true,
    PanelHeaderText: "",
    showLLGButton: false,
    showTimeClock: false,
    ClientOptions: [] as ISelect[],
    OfficeOptions: [] as ISelect[],
    SiteOptions: [] as ISelect[],
    RequesterOptions: [] as ISelect[],
    AllInspectorOptions: [] as ISelect[],
    InspectorOptions: [] as ISelect[],
    AllTaskTypeOptions: [] as ISelect[],
    SelectedAssetType: null as ISelect,
    NewAsset: {
        value: "",
        error: true,
        errorMessage: ""
    } as IError,
    LoadingAssetSection: false,
    DivisionOptions: [],
    Roles: [],
    AssetTypeOptions: [],
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    hideConfirm: true,
    sortedColumn: "",
    sortOrder: "asc",
    job_status_filter_value: "",
    job_number_filter_value: "",
    job_client_filter_value: "",
    job_site_filter_value: "",
    job_qn_filter_value: "",
    ShowAddNewSiteSection: false,
    LoadingNewSiteSection: false,
    NewSiteSaveStatus: {
        flag: true,
        message: ""
    },
    NewSite: {
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin: 0,
        phone: "",
        locationLevel: 0
    },
    ShowAddNewUserSection: false,
    LoadingNewUserSection: false,
    NewUserSaveStatus: {
        flag: true,
        message: ""
    },
    NewUser: {
        name: "",
        email: "",
        phone: ""
    },
    IsJobEditable: true,
    closeOrCancel: "",
    jobDashboardColumns: [
        'jobNumber',
        'client',
        'site',
        'inspector',
        'comments',
        'startDate',
        'quotationNumber',
        'status',
    ],
    JobBreadCrumb: [
        { text: 'OPERATIONS', key: 'Operations' },
        { text: 'Job Lites', key: 'JobLites' }
    ],
    isOpenAddNewAsset: false,
}