import { Breadcrumb, IBreadcrumbItem } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { CLEAN_DOCUMENT_LIST, LOAD_DOCUMENT_LIST, TRIAL_FIT_PIC_LIBRARY_BREADCRUMBS } from '../Constants/TrialFitPicLibraryConstants';
import TrialFitPicLibraryDashboard from './TrialFitPicLibraryDashboard';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TrialFitPicLibraryActions } from '../Actions/TrialFitPicLibraryActions';
import { TrialFitPicLibraryUtility } from '../Utility/TrialFitPicLibraryUtility';
import { store } from './../../../../Store/store';
import watch from 'redux-watch';
import { TrialFitPicLibraryAPI } from './../Service/TrialFitPicLibraryAPI';

const mapStateToProps = (state: any): any => {
    return {
        TrialFitPicLibraryState: state.TrialFitPicLibraryReducer,
        ClientSiteSelectorState: state.ClientSiteSelectorReducer,
    }
}

let w = watch(store.getState, 'ClientSiteSelectorReducer.selectedSite')
store.subscribe(w(() => {
    let _trialFitLibraryAPI = new TrialFitPicLibraryAPI(window.API_URL, window.loggedInUserDetails);
    store.dispatch({ type: CLEAN_DOCUMENT_LIST, payload: { isOngoingLoad: false } })
    const selectedClient: any = store?.getState()['ClientSiteSelectorReducer']['selectedClient'];
    const selectedSite: any = store?.getState()['ClientSiteSelectorReducer']['selectedSite'];
    if (selectedSite) {
        _trialFitLibraryAPI.loadInitData(selectedClient.value,
            selectedSite.value,
            TrialFitPicLibraryUtility.getLibraryName(window.location.hash.split("/")[2]))
            .then((resp: any) => {
                _trialFitLibraryAPI.getEntityByParentId(resp[0].data.id).then((response) => {
                    store.dispatch({
                        type: LOAD_DOCUMENT_LIST,
                        payload: {
                            isOngoingLoad: false,
                            parentLibrary: resp[0].data,
                            llgAssetTypes: resp[1].data,
                            documentEntities: response.data
                        }
                    })
                })
            })
    }

}))


export class TrialFitPicLibraryComponent extends Component {


    render() {
        let breadCrumbs: IBreadcrumbItem[] = [...TRIAL_FIT_PIC_LIBRARY_BREADCRUMBS];
        breadCrumbs.push(
            {
                text: TrialFitPicLibraryUtility.getDisplayName(window.location.hash.split("/")[2]),
                key: TrialFitPicLibraryUtility.getLibraryKey(window.location.hash.split("/")[2])
            }
        )

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={breadCrumbs}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                </div>
                <TrialFitPicLibraryDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    let reportsActions: TrialFitPicLibraryActions = new TrialFitPicLibraryActions();

    return bindActionCreators({ ...reportsActions }, dispatch);
}

const TrialFitPicLibrary = connect(mapStateToProps, mapDispatchToProps)(TrialFitPicLibraryComponent);
export default TrialFitPicLibrary;
