import React, { Component } from 'react'
import Select from 'react-select';
import { Label, Separator } from 'office-ui-fabric-react';
import { JobLitesAPI } from '../Services/JobLitesAPI';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { seperatorTheme } from '../../../Constants/StylesConstants';

export default class TrackingDetails extends Component {

    _apiObject = null;
    constructor(props) {
        super(props);
        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails);
    }

    render() {
        let {
            OfficeOptions,
            CurrentJobDetails,
        } = this.props.JobLites;

        const loggedInUser = window.loggedInUserDetails;

        let currentUserOfficeIds = [...new Set(loggedInUser.secondaryOfficeIds
            .split(",").map(officeId => +officeId).concat(loggedInUser.primaryOfficeId))];

        let filteredOfficeOptions = [];

        OfficeOptions.forEach((office) => {
            if (currentUserOfficeIds.indexOf(office.value) > -1) {
                filteredOfficeOptions.push(office);
            }
        });

        return (
            <div className="ms-Grid" dir="ltr">
                <Separator theme={seperatorTheme}>Tracking Details</Separator>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>Assign Office <span className="mandatory-span">*</span></Label>
                        <Select
                            value={CurrentJobDetails?.AssignOffice.value}
                            onChange={(value) => {
                                if (value) {
                                    this.props.changeAssignOffice({
                                        Location: value
                                    })
                                }
                            }}
                            options={CommonUtility.sortByColumn(filteredOfficeOptions, "label", "asc")}
                        />
                    </div>

                </div>
            </div>

        )
    }
}
