import React, { Component } from 'react'
import { TextField, Label, Separator } from 'office-ui-fabric-react'
import Select from 'react-select';
import { JobLitesAPI } from '../Services/JobLitesAPI';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { FIELD_ENGG_ROLE } from '../../../Constants/CommonConstants';
import { seperatorTheme } from '../../../Constants/StylesConstants';

export default class SiteDetails extends Component {
    _apiObject = null;
    constructor(props) {
        super(props);

        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails)
    }

    render() {
        let {
            AllInspectorOptions,
            CurrentJobDetails,
            Roles
        } = this.props.JobLites;

        let filteredInspectorOptions = [];
        const loggedInUser = window.loggedInUserDetails;

        let currentUserDivisionIds = loggedInUser.divisionIds ? loggedInUser.divisionIds.split(",") : [];
        let primaryOfficeId = CurrentJobDetails?.AssignOffice?.value?.value;

        if (AllInspectorOptions && AllInspectorOptions.length > 0 && currentUserDivisionIds.length > 0) {
            [...AllInspectorOptions.filter(d => d.divisionIds)].forEach((inspector) => {
                let inspectorDivisionIds = inspector.divisionIds.split(",");
                //find common divisions
                let filteredArray = inspectorDivisionIds.filter(value => currentUserDivisionIds.includes(value));

                let officeIdArray = inspector.secondaryOfficeIds.split`,`.map(officeId => +officeId).concat(inspector.officeId);


                if (filteredArray.length > 0 && officeIdArray.indexOf(primaryOfficeId) > -1) {
                    filteredInspectorOptions.push(inspector);
                }
            })
        }

        return (
            <div className="ms-Grid" dir="ltr">
                <Separator theme={seperatorTheme}>Site Details</Separator>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>Main Inspector <span className="mandatory-span">*</span></Label>
                        <Select
                            value={CurrentJobDetails?.MainInspector.value}
                            onChange={(newval) => {
                                this.props.changeMainInspector({
                                    User: newval
                                });
                            }}
                            disabled={CurrentJobDetails && loggedInUser.role !== FIELD_ENGG_ROLE}
                            options={
                                CommonUtility.sortByColumn(filteredInspectorOptions.filter((element) =>
                                (element.type === 0 &&
                                    Roles.filter(d => d.name === FIELD_ENGG_ROLE || d.name === FIELD_ENGG_ROLE)
                                        .map(x => x.id).indexOf(element.roleId) > -1)
                                ), "label", "asc")
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            label="Comments"
                            value={CurrentJobDetails?.Comments.value}
                            onChange={(event, newval) => {
                                this.props.changeComments({
                                    Comments: newval
                                });
                            }}
                            multiline={true}
                        />
                    </div>
                </div>
            </div>

        )
    }
}
