import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Label } from 'office-ui-fabric-react';
import { TemplatesAction } from '../Actions/TemplatesAction';

const mapStateToProps = (state) => {
    return {
        Templates: state.TemplatesReducer
    }
}

class TemplatesComponent extends PureComponent {

    render() {
        return (
            <React.Fragment>
                <Label className="breadcrumb-label">Lifting / Settings / Templates</Label>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let templatesAction = new TemplatesAction();

    return bindActionCreators({ ...templatesAction }, dispatch);
}

const Templates = connect(mapStateToProps, mapDispatchToProps)(TemplatesComponent);
export default Templates;
