import axios, { AxiosError, AxiosResponse } from "axios";
import { ILoggedInUser } from "../../../CommonInterfaces/CommoInterfaces";
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import { IClientSiteSelectorAPI } from "../ClientSiteSelectorInteface/IClientSiteSelector";
import * as CONSTANTS from './../../Assets/Constants/AssetConstants';

export class ClientSiteSelectorAPI implements IClientSiteSelectorAPI {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUserDetails: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUserDetails;
    }

    public loadClientsAPI = (companyId?: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_LOCATION_OBJECT_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=CLIENT_COMPANY`)
                .concat(companyId ? `&companyId=${companyId}` : ``)
            ).then((response: AxiosResponse<any>) => {
                resolve(response);
            }).catch((error: AxiosError<any>) => {
                console.error("Error loadClientsAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public loadSitesAPI = (clientId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_LOCATION_OBJECT_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=SITE&`)
                .concat(`companyId=${clientId}`)
            ).then((response: AxiosResponse<any>) => {
                resolve(response);
            }).catch((error: AxiosError<any>) => {
                console.error("Error loadSitesAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

}