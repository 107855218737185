import { process } from "@progress/kendo-data-query";
import { IKendoGridColumn, IKendoGridFilter } from "../../../CommonInterfaces/CommoInterfaces";
import { ASSESSMENT_VIEW_GRID_COLUMNS } from "../Constants/AssessmentsConstants";
import { IAssessment } from "../Interfaces/IAssessment";

export class AssessmentsUtility {

    public processAssessmentsForKendoGrid = (reports: IAssessment[], kendoGridDataState: any): any => {

        let processAssessments: any = process([...reports], { ...kendoGridDataState });
        return processAssessments;
    }

    public getFilteredAssessments = (reports: IAssessment[]): IAssessment[] => {

        // let filteredAssessments: IAssessment[] = reports.filter((report: IAssessment) =>
        //     report.observationType === 256 || (report.observationType === 1024 && report.taskType !== "LLG")
        // )

        let filteredAssessments: IAssessment[] = reports.filter((report: IAssessment) =>
            (report.taskType !== "LLG")
        )

        return filteredAssessments;
    }

    public static getFilterDetails = (reportGridDataState: any): string[] => {
        let filterStringArr: string[] = [];
        reportGridDataState.filter.filters.forEach((filterDetails: IKendoGridFilter) => {
            let fieldName : string = ASSESSMENT_VIEW_GRID_COLUMNS.find((column: IKendoGridColumn) => column.field === filterDetails.field).title;
            
            filterStringArr.push(`${fieldName} ${filterDetails?.operator} ${filterDetails.value}`)
        });
        return filterStringArr;
    }


}
