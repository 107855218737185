import * as React from 'react';
import { ActionButton, DefaultButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react';
import { ISequenceStateProps } from '../Interfaces/ISequenceStateProps';
import { ISequenceService } from '../Interfaces/ISequenceService';
import { SequenceService } from '../Services/SequenceAPI';
import { ISequenceState } from '../Interfaces/ISequenceState';
import { IconConstants } from '../../../Constants/StylesConstants';

// payload.PDFURL = `${window.API_URL}filedownload;jsessionid=${window.loggedInUserDetails.jsessionid}?observationId=${payload.SelectedTask.id}&id=${payload.SelectedTask.certificateFile ? JSON.parse(payload.SelectedTask.certificateFile).blobKey : ""}`;

export class AddEditSequencePanel extends React.PureComponent<ISequenceStateProps, {}> {

    private _apiPayload: ISequenceService;

    public constructor(props: ISequenceStateProps) {
        super(props);
        this._apiPayload = new SequenceService(window.API_URL, window.loggedInUserDetails);
    }

    public render(): JSX.Element {

        let {
            isShowSequenceAddEditPanel,
            isLoadingSequenceAddEditPanel,
            selectedSequence,
        }: ISequenceState = this.props.SequenceState;

        return (
            <div>
                <Panel
                    headerText={`Sequence: ${selectedSequence?.sequenceName}`}
                    isOpen={isShowSequenceAddEditPanel}
                    onDismiss={() => {
                        this.props.showHideAddEditSequenceNumberingPanel({
                            isShowSequenceAddEditPanel: false
                        })
                    }}
                    type={PanelType.large}
                    styles={{
                        content: {
                            height: "40em"
                        },
                        scrollableContent: {
                            height: "92vh"
                        }
                    }}
                >
                    {isLoadingSequenceAddEditPanel ?
                        <div className="login-spinner-div">
                            <Spinner
                                size={SpinnerSize.large}
                                label="Please wait..."
                            />
                        </div>
                        :
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <ActionButton
                                        disabled={isLoadingSequenceAddEditPanel}
                                        text="Save"
                                        iconProps={IconConstants.saveicon}
                                    />
                                    {` `}
                                    <ActionButton
                                        text="Cancel"
                                        onClick={() => {
                                            this.props.showHideAddEditSequenceNumberingPanel({
                                                isShowSequenceAddEditPanel: false
                                            })
                                        }}
                                        iconProps={IconConstants.cancelIcon}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        readOnly
                                        label="Office Name"
                                        value={selectedSequence.officeName}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        readOnly
                                        label="Division"
                                        value={selectedSequence.divisionName ? selectedSequence.divisionName : ""}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        readOnly
                                        label="Sequence Name"
                                        value={selectedSequence.sequenceName}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        label="Current Number"
                                        value={`${selectedSequence.sequenceNumber}`}
                                        type="number"
                                        min={selectedSequence.sequenceNumber}
                                        onChange={(ev: any, newVal: string) => {
                                            this.props.changeSequenceNumber({
                                                sequenceNumber: parseInt(newVal)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <PrimaryButton
                                        disabled={isLoadingSequenceAddEditPanel}
                                        text="Save"
                                        iconProps={IconConstants.saveicon}
                                        onClick={() => {
                                            this.props.saveSequence({
                                                selectedSequence: selectedSequence,
                                                apiPayload: this._apiPayload
                                            })
                                        }}
                                    />
                                    {` `}
                                    <DefaultButton
                                        text="Cancel"
                                        onClick={() => {
                                            this.props.showHideAddEditSequenceNumberingPanel({
                                                isShowSequenceAddEditPanel: false
                                            })
                                        }}
                                        iconProps={IconConstants.cancelIcon}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </Panel>
            </div>
        );
    }

}