import React from 'react'
import {
    Persona,
    PersonaSize,
    ActionButton,
    Image,
    Callout,
    Panel,
    PanelType,
    ComboBox
} from 'office-ui-fabric-react'
import { LoggedInUserPersona } from '../../Constants/StylesConstants';
import { LoginAPI } from '../Login/LoginAPI';
import LogoutConfirm from '../Alert/LogoutConfirm.jsx';
import * as CONSTANTS from './../../Constants/CommonConstants';
//import { IconConstants } from './../../Constants/StylesConstants';

export default class Header extends React.PureComponent {
    _hoverCard = React.createRef();
    _apiService;
    _menuButtonElement = React.createRef();
    _menuClientButtonElement = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            HideLogoutConfirmationDialog: true,
            isShowFullLogo: false,
            isShowFullClientLogo: false,
            isLoadingClientLogo: true,
            isShowConfigurationPanel: false,
            clientLogoFile: "",
            clientLogoType: "",
            isLoggingOut: false,
            assetNameRegexStr: localStorage.getItem("assetNameRegex").split("")
        }
        this._apiService = new LoginAPI(window.API_URL);
    }

    componentDidMount() {
        if (!window.loggedInUserDetails.clientLogoType) {
            this._apiService.getClientLogo()
                .then((response) => {
                    window.loggedInUserDetails.clientLogoType = response.clientLogoType;
                    window.loggedInUserDetails.clientLogoFile = response.clientLogoFile;
                    this.setState({
                        isLoadingClientLogo: false
                    })

                }).catch((error) => {
                    console.log("Error while loading client logo:: Error:", error);
                });

        }
        else {
            this.setState({
                isLoadingClientLogo: false
            })
        }
    }

    logoutConfirmOrCancel = (status) => {

        if (status) {

            this.setState({
                isLoggingOut: true
            });
            setTimeout(() => {
                this._apiService.doLogout()
                    .then(() => {
                        window.isAuthenticated = false;
                        // window.loggedInUserDetails = null;
                        this.setState({
                            HideLogoutConfirmationDialog: true
                        }, () => {
                            this.props.history.push("/")
                        });

                    }).catch((error) => {
                        console.log("Error whille Log out", error);
                    });
            }, 1500);
        }
        else {
            this.setState({
                HideLogoutConfirmationDialog: true
            });
        }

    }

    showLogoutConfirm = () => {
        this.setState({
            HideLogoutConfirmationDialog: false
        })
    }

    showConfigurationPanel = () => {
        this.setState({
            isShowConfigurationPanel: !this.state.isShowConfigurationPanel
        })
    }

    render() {

        const loggedInUser = window.loggedInUserDetails;

        let loggedInUserPersona = LoggedInUserPersona;
        let loggedInUserName = loggedInUser.name ? loggedInUser.name :
            loggedInUser.customer;
        let loggedInUserNameArray = loggedInUserName.split(" ");

        loggedInUserPersona.text = loggedInUserName;
        loggedInUserPersona.secondaryText = loggedInUser.role.toUpperCase();

        loggedInUserPersona.imageInitials =
            loggedInUserNameArray.length > 1 ? loggedInUserNameArray[0][0]?.toUpperCase()
                + (loggedInUserNameArray[1] ? loggedInUserNameArray[1][0].toUpperCase() : "")
                :
                loggedInUserNameArray[0].substr(0, 1).toUpperCase()
            ;

        let {
            HideLogoutConfirmationDialog,
            isShowConfigurationPanel,
            isShowFullLogo,
            assetNameRegexStr,
            isShowFullClientLogo,
            isLoggingOut
        } = this.state;

        let {
            customerLogo, customerLogoType, clientLogoFile, clientLogoType
        } = loggedInUser;

        let companyPersona = {
            imageUrl: `data:${customerLogoType};base64,${customerLogo}`,
            clientImageUrl: `data:${clientLogoType};base64,${clientLogoFile}`
        }

        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row header-qwikspec">
                    <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg2">
                        {customerLogo &&
                            <div style={{ cursor: "Pointer" }} ref={this._menuButtonElement}>
                                <>
                                    <Image
                                        src={companyPersona.imageUrl}
                                        styles={{
                                            image: {
                                                display: "block",
                                                maxHeight: "2.85em",
                                                height: "auto"
                                            }
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                isShowFullLogo: !isShowFullLogo
                                            })
                                        }}
                                    />
                                    {isShowFullLogo &&
                                        <Callout
                                            className="ms-CalloutExample-callout"
                                            ariaLabelledBy={CONSTANTS._labelId}
                                            ariaDescribedBy={CONSTANTS._descriptionId}
                                            role="alertdialog"
                                            gapSpace={0}
                                            target={this._menuButtonElement.current}
                                            onDismiss={() => {
                                                this.setState({
                                                    isShowFullLogo: false
                                                })
                                            }}
                                            setInitialFocus={true}
                                            hidden={!isShowFullLogo}
                                        >
                                            <Image
                                                src={companyPersona.imageUrl}
                                                styles={{
                                                    image: {
                                                        display: "block",
                                                        width: "auto",
                                                        height: "auto",
                                                        maxWidth: "50em",
                                                        maxHeight: "50em",
                                                    }
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        isShowFullLogo: !isShowFullLogo
                                                    })
                                                }}
                                            />
                                        </Callout>
                                    }
                                </>
                            </div>
                        }

                    </div>
                    <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg4">
                        {clientLogoFile &&
                            <div style={{ cursor: "Pointer" }} ref={this._menuClientButtonElement}>
                                <>
                                    <Image
                                        src={companyPersona.clientImageUrl}
                                        alt=""
                                        styles={{
                                            image: {
                                                display: "block",
                                                maxHeight: "2.85em",
                                                height: "auto"
                                            }
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                isShowFullClientLogo: !isShowFullClientLogo
                                            })
                                        }}
                                    />
                                    {isShowFullClientLogo &&
                                        <Callout
                                            className="ms-CalloutExample-callout"
                                            ariaLabelledBy={CONSTANTS._labelId}
                                            ariaDescribedBy={CONSTANTS._descriptionId}
                                            role="alertdialog"
                                            gapSpace={0}
                                            target={this._menuClientButtonElement.current}
                                            onDismiss={() => {
                                                this.setState({
                                                    isShowFullClientLogo: false
                                                })
                                            }}
                                            setInitialFocus={true}
                                            hidden={!isShowFullClientLogo}
                                        >
                                            <Image
                                                src={companyPersona.clientImageUrl}
                                                alt=""
                                                styles={{
                                                    image: {
                                                        display: "block",
                                                        width: "auto",
                                                        height: "auto",
                                                        maxWidth: "50em",
                                                        maxHeight: "50em",
                                                    }
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        isShowFullClientLogo: !isShowFullClientLogo
                                                    })
                                                }}
                                            />
                                        </Callout>
                                    }
                                </>
                            </div>
                        }

                    </div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg6">
                        <Persona
                            className="header-items"
                            {...loggedInUserPersona}
                            size={PersonaSize.size40}
                            onRenderPersonaCoin={(props) => (
                                <ActionButton
                                    // text={props.text}
                                    className="persona-menu-button"
                                    menuProps={{
                                        items: [
                                            {
                                                key: "2",
                                                text: window.loggedInUserDetails.email,
                                                iconProps: {
                                                    iconName: 'PublicEmail'
                                                },
                                                disabled: true
                                            },
                                            {
                                                key: "3",
                                                text: "Configuration",
                                                disabled: window.loggedInUserDetails.role.toUpperCase().indexOf(CONSTANTS.LO_ADMIN_ROLE) === -1,
                                                iconProps: {
                                                    iconName: 'Settings'
                                                },
                                                onClick: this.showConfigurationPanel
                                            },
                                            {
                                                key: "1",
                                                text: "Logout",
                                                iconProps: {
                                                    iconName: 'SignOut'
                                                },
                                                onClick: this.showLogoutConfirm
                                            },
                                        ]
                                    }}>
                                    <div className="circle-text">
                                        {props.imageInitials}
                                    </div>
                                </ActionButton>
                            )}
                            primaryText={loggedInUserPersona.primaryText}
                            secondaryText={loggedInUserPersona.secondaryText}

                        />

                        {/* <ActionButton
                            text="Log out"
                            className="header-items"
                            iconProps={IconConstants.signOutIcon}
                            onClick={() =>
                                this.setState({
                                    HideLogoutConfirmationDialog: false
                                })
                            }
                        /> */}
                    </div>
                </div>
                {
                    !HideLogoutConfirmationDialog &&
                    <LogoutConfirm
                        isLoggingOut={isLoggingOut}
                        HideLogoutConfirmationDialog={HideLogoutConfirmationDialog}
                        logoutConfirmOrCancel={this.logoutConfirmOrCancel}
                    />
                }
                {
                    <Panel
                        headerText={'Configuration Panel'}
                        isOpen={isShowConfigurationPanel}
                        type={PanelType.medium}
                        onDismiss={() =>
                            this.showConfigurationPanel()
                        }
                        styles={{
                            scrollableContent: {
                                height: "80vh"
                            }
                        }}
                        closeButtonAriaLabel="Close">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <ComboBox
                                        multiSelect
                                        selectedKey={assetNameRegexStr}
                                        label="Asset Name Disallowed Characters:"
                                        allowFreeform={false}
                                        autoComplete="on"
                                        options={CONSTANTS.SPECIAL_CHARACTERS}
                                        onChange={(event, option) => {
                                            let tempAssetNameRegexStr = [...assetNameRegexStr];

                                            const index = tempAssetNameRegexStr.indexOf(option.key);
                                            if (option.selected && index < 0) {
                                                tempAssetNameRegexStr.push(option.key);
                                            } else {
                                                tempAssetNameRegexStr.splice(index, 1);
                                            }

                                            this.setState({
                                                assetNameRegexStr: tempAssetNameRegexStr
                                            }, () => {
                                                localStorage.setItem("assetNameRegex", tempAssetNameRegexStr.join(""))

                                            })
                                        }}
                                    // onChange={this._onChangeMulti}
                                    // text={state.initialDisplayValueMulti}
                                    />
                                </div>
                            </div>
                        </div>
                    </Panel>
                }
            </div >
        )
    }
}
