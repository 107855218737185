import React from 'react'
import { ClientAPI } from '../Services/ClientAPI';
import AlertRedux from '../../Alert/AlertRedux';
import { IconButton, CommandButton, ActionButton, TooltipHost } from 'office-ui-fabric-react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import BootstrapTable from 'react-bootstrap-table-next';
import { DeletedRowStyle, IconConstants, calloutProps, tooltipId, hostStyles } from '../../../Constants/StylesConstants';
import filterFactory from 'react-bootstrap-table2-filter';
import AddEditLocationPanel from './AddEditLocationPanel';

const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
}];

// const MyExportCSV = (props) => {
//     const handleClick = () => {
//         props.onExport();
//     };
//     return (
//         <div className="table-export-button-div">
//             <PrimaryButton
//                 onClick={handleClick}
//                 text="Export to CSV"
//                 menuIconProps={IconConstants.exportIcon}
//             />
//         </div>
//     );
// };

const RemoteAll = ({
    data, columns, onTableChange, page, hideSizePerPage, style
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
            exportCSV={{
                fileName: 'Qwikspec_Locations.csv',
                noAutoBOM: false,
                blobType: 'text/csv;charset=ansi'
            }}
        >
            {
                props => (
                    <React.Fragment>
                        {/* <MyExportCSV {...props.csvProps} /> */}
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({ page, hideSizePerPage })}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            rowClasses={DeletedRowStyle}
                            wrapperClasses="table-responsive" />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class LocationDashboard extends React.PureComponent {

    _clientApi = null;

    constructor(props) {
        super(props);
        this._clientApi = new ClientAPI(window.API_URL, window.loggedInUserDetails);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {

        this.props.cleanLocationDashboard({
            offset: 0,
        });

        this.props.loadLocationDashboard({
            offset: 0,
            limit: this.props.Client.limit,
            apiObject: this._clientApi,
            SelectedClient: this.props.Client.SelectedClient
        });

    }

    fetchMoreData = () => {

        this.props.loadLocationDashboard({
            offset: this.props.Client.locOffset,
            limit: this.props.Client.limit,
            apiObject: this._clientApi,
            SelectedClient: this.props.Client.SelectedClient
        });

    };


    // handleTableChange = (type, { filters, sortField, sortOrder }) => {

    //     // console.log(sortOrder);

    //     let filterJson = {};

    //     for (let dataField in filters) {
    //         filterJson[dataField] = filters[dataField].filterVal
    //     }

    //     let config = {
    //         headers: {
    //         }
    //     }
    //     if (Object.keys(filterJson).length > 0) {
    //         config = {
    //             headers: {
    //                 filter: JSON.stringify(filterJson)
    //             }
    //         }
    //     }

    // }

    render() {

        let columns = [
            {
                dataField: 'manageClientIcons',
                isDummyField: true,
                text: 'Action',
                formatter: (cellContent, row) => {
                    return (
                        <span>
                            <React.Fragment>
                                <IconButton
                                    onClick={() => this.props.showHideClientAddEditLocationPopUp({
                                        ShowAddEditLocationPopUp: true,
                                        SelectedLocation: row,
                                        PanelHeaderText: row.name,
                                        IsClientEdit: false
                                    })}
                                    iconProps={IconConstants.editicon} />
                            </React.Fragment>
                        </span>
                    )
                },
                csvExport: false
            },
            {
                dataField: 'id',
                text: 'Client ID',
                hidden: true,
                csvExport: false
            },
            {
                dataField: 'acronymCode',
                text: 'Code',
            },
            {
                dataField: 'name',
                text: 'Name',
            },
            {
                dataField: 'dummyField',
                text: 'Address',

                formatter: (cellContent, row) => {
                    return (
                        <TooltipHost
                            content={`${row.street} ${row.city} ${row.state} ${row.pin} ${row.country}`}
                            id={tooltipId}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            <span>{row.street} {row.city} {row.pin} {row.country}</span>

                        </TooltipHost>
                    )
                }
            },
            {
                dataField: 'externalId',
                text: 'External',
            },

        ];

        let {
            LoadingLocationDashboard,
            Locations,
            alertJSON,
            HasMoreLocations,
            ShowAddEditLocationPopUp
        } = this.props.Client;

        let filteredLocations = [...Locations];

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <ActionButton
                                disabled={LoadingLocationDashboard}
                                text="Reload"
                                iconProps={IconConstants.refreshIcon}
                                onClick={() => this.loadInitData()}
                            />
                            {HasMoreLocations && !LoadingLocationDashboard &&
                                <CommandButton
                                    text="Load more"
                                    iconProps={IconConstants.moreicon}
                                    onClick={() => this.fetchMoreData()}
                                />
                            }
                            <ActionButton
                                text="Add Location"
                                iconProps={IconConstants.addIcon}
                                onClick={() => this.props.showHideClientAddEditLocationPopUp({
                                    ShowAddEditLocationPopUp: true,
                                    PanelHeaderText: "Add New Location"
                                })}
                            />
                        </div>
                    </div>
                </div>

                <LoadingOverlay
                    active={LoadingLocationDashboard}
                    spinner={<FadeLoader />}
                    text="Loading Locations...">
                    <div className="lazy-load-div" >
                        <RemoteAll
                            data={filteredLocations}
                            columns={columns}
                            page={1}
                            hideSizePerPage={true}
                        // onTableChange={this.handleTableChange}
                        />
                    </div>

                </LoadingOverlay>

                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
                {
                    ShowAddEditLocationPopUp &&
                    <AddEditLocationPanel
                        {...this.props}
                    />
                }
            </React.Fragment >
        )
    }
}
