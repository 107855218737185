import { DivisionInitialState } from "../Constants/DivisionIntialState";
import { IDivisionData } from "../Interfaces/IDivision";
import * as CONSTANTS from "./../Constants/DivisionConstants";
import { DivisionUtility } from "../Utility/DivisionUtility";
import { ISelect } from "./../../../../CommonInterfaces/CommoInterfaces";

export function DivisionReducer(state = DivisionInitialState, action) {
  let tempState = { ...state };
  let payload = action.payload;
  let utility: DivisionUtility = new DivisionUtility();
  let CurrentDivision: IDivisionData = { ...tempState.CurrentDivision };

  switch (action.type) {
    case CONSTANTS.CLEAN_DIVISION_DASHBOARD:
      tempState.offset = 0;
      tempState.LoadingDivisionDashboard = true;
      tempState.Divisions = [];
      break;

    case CONSTANTS.LOAD_DIVISION_DASHBOARD:
      tempState.LoadingDivisionDashboard = payload.isOngoingLoading;
      if (!payload.isOngoingLoading) {
        tempState.offset += tempState.limit;
        let newDivision: IDivisionData[] = utility.loadDivisionTableStructure(
          payload.Divisions
        );
        tempState.Divisions = tempState.Divisions.concat(newDivision);
        tempState.HasMoreDivisions =
          payload.Divisions.length >= tempState.limit;
      }

      break;

    case CONSTANTS.LOAD_DIVISION_ATTRIBUTES:
      tempState.LoadingDivisionAddEditPanel = payload.isOngoingLoading;
      tempState.PanelHeaderText = payload.PanelHeaderText;
      tempState.ShowDivisionAddEditPanel = true;
      if (!payload.isOngoingLoading) {
        tempState.FormAttributeOptions = utility.loadFormAttributesOptions(
          payload.FormAttributes
        );
        CurrentDivision = payload.SelectedDivision
          ? payload.SelectedDivision
          : CurrentDivision;
        CurrentDivision.attribute_form =
          payload.SelectedDivision && payload.SelectedDivision.attribute_form_id
            ? {
                value: tempState.FormAttributeOptions.find(
                  (d: ISelect) =>
                    d.value === payload.SelectedDivision.attribute_form_id
                ).value,
                label: tempState.FormAttributeOptions.find(
                  (d: ISelect) =>
                    d.value === payload.SelectedDivision.attribute_form_id
                ).label,
              }
            : null;
        tempState.CurrentDivision = CurrentDivision;
      }
      break;

    case CONSTANTS.CHANGE_DIVISION_TAB:
      tempState.TabIndex = payload.TabIndex;
      break;
    // Description

    case CONSTANTS.CHANGE_DIVISION_NAME:
      CurrentDivision.name = payload.name;
      tempState.CurrentDivision = CurrentDivision;
      break;

    case CONSTANTS.CHANGE_DIVISION_ACRONYM_CODE:
      CurrentDivision.acronym_code = payload.acronym_code;
      tempState.CurrentDivision = CurrentDivision;
      break;

    case CONSTANTS.CHANGE_DIVISION_DESCRIPTION:
      CurrentDivision.description = payload.description;
      tempState.CurrentDivision = CurrentDivision;
      break;

    case CONSTANTS.CHANGE_DIVISION_DISPLAY_NAME:
      CurrentDivision.displayName = payload.displayName;
      tempState.CurrentDivision = CurrentDivision;
      break;

    // Attributes
    case CONSTANTS.CHANGE_DIVISION_FORM_ATTRIBUTE:
      CurrentDivision.attribute_form = payload.attribute_form;
      tempState.CurrentDivision = CurrentDivision;
      break;

    // Save Division
    case CONSTANTS.SAVE_DIVISION_DETAILS:
      tempState.LoadingDivisionAddEditPanel = payload.isOnGoingSave;
      break;

    case CONSTANTS.OPEN_CLOSE_DIVISION_ALERT_MODAL:
      tempState.alertJSON = {
        hideAlert: payload.hideAlert,
        alertHeader: payload.alertHeader,
        alertSubText: payload.alertSubText,
      };
      tempState.LoadingDivisionAddEditPanel = false;
      break;

    case CONSTANTS.OPEN_CLOSE_DIVISION_CONFIRM_MODAL:
      tempState.hideConfirm = payload.hideConfirm;
      if (payload.hideConfirm) {
        tempState.CurrentDivision = utility.resetCurrentDivision();
      } else {
        tempState.CurrentDivision = payload.CurrentDivision;
      }
      break;

    case CONSTANTS.DELETE_DIVISION:
      tempState.LoadingDivisionDashboard = payload.isOngoingDelete;
      tempState.hideConfirm = true;
      break;

    case CONSTANTS.CLOSE_DIVISION_DETAILS_PANEL:
      tempState.ShowDivisionAddEditPanel = false;
      tempState.PanelHeaderText = "";
      tempState.TabIndex = 0;
      tempState.CurrentDivision = utility.resetCurrentDivision();
      break;

    default:
      break;
  }

  return tempState;
}
