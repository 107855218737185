import React, { PureComponent } from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { JobLitesAPI } from '../Services/JobLitesAPI';
import { IconConstants } from '../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class ConfirmJobLitesRedux extends PureComponent {

    _jobAPi = null;

    constructor(props) {
        super(props);
        this._jobAPi = new JobLitesAPI(window.API_URL, window.loggedInUserDetails);

    }

    render() {
        let { hideConfirm, scheduleId, closeOrCancel } = this.props.JobLites;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: closeOrCancel === "Cancel" ?
                `Are you sure want to cancel the job? All dependent tasks will also be cancelled!!`
                :
                `Are you sure want to close the job?`
        };
        return (
            <Dialog
                hidden={hideConfirm}
                onDismiss={() => this.props.openCloseJobConfirmModal({
                    hideConfirm: true,
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            this.props.closeOrCancelJob({
                                scheduleId: scheduleId,
                                hideConfirm: true,
                                status: closeOrCancel,
                                apiObject: this._jobAPi
                            })
                        }}
                        text="Yes"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseJobConfirmModal({
                            hideConfirm: true,
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
