import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridFilterCellProps, GridToolbar } from '@progress/kendo-react-grid';
import { ActionButton,PrimaryButton, Label, Toggle } from 'office-ui-fabric-react';
import * as React from 'react';
import { FadeLoader } from 'react-spinners';
import { IKendoGridColumn, IKendoGridFilter } from '../../../CommonInterfaces/CommoInterfaces';
import { IconConstants } from '../../../Constants/StylesConstants';
import { IReportsInitialState } from '../Interfaces/IReportsInitialState';
import { IReportService } from '../Interfaces/IReportsService';
import { IReportStateProps } from '../Interfaces/IReportStateProps';
import { ReportsService } from '../Services/ReportsAPI';
import * as CONSTANTS from './../Constants/ReportsConstants';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT_USER } from '../../../Constants/MessageConstants';
import { IdCustomCell, DateCustomCell, ResultCustomCell } from './CustomReportsColumnViews';
import { CertificatePreviewPanel } from './CertificatePreviewPanel';
import { ReportsUtility } from '../Utility/ReportsUtility';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import DropdownFilterCell from '../../Assessment/Views/DropdownFilterCell';

export default class ReportsDasboard extends React.PureComponent<IReportStateProps, {}> {

    private _apiPayload: IReportService;
    private _excelExport: any;

    public constructor(props: IReportStateProps) {
        super(props);
        this._apiPayload = new ReportsService(window.API_URL, window.loggedInUserDetails);
    }

    public componentDidMount(): void {
        this.loadInitData();
    }

    public export = (): void => {
        // this._excelExport.save();
        this.savetoExcel(this._excelExport);
    }

    reportsDateCustomCell = (props) => (
        <DateCustomCell {...props} currentProps={this.props} />
    );

    idCustomCell = (props) => (
        <IdCustomCell {...props} currentProps={this.props} />
    );

    resultCustomCell = (props) => (
        <ResultCustomCell {...props} currentProps={this.props} />
    );

    cellRender = (td: any, props: any) => {
        let groupColDetails = CONSTANTS.REPORT_VIEW_GRID_COLUMNS.find(
            (d) => d.field === props.dataItem.field
        );
        let groupColTitle = '';
        if (groupColDetails) {
            groupColTitle = groupColDetails.title;
        }

        if (td && td.props.children && props.rowType === 'groupHeader') {
            let children = (
                <span>
                    {td.props.children.props.children[0]}{' '}
                    {groupColTitle + ': ' + props.dataItem.value}
                </span>
            );
            return React.cloneElement(td, td.props, children);
        }
        return td;
    };

    public savetoExcel = (component: any): void => {
        const options = component.workbookOptions();
        const rows = options.sheets[0].rows;

        let cellIndexOfStatus = [];
        rows.forEach((row: any) => {
            if (row.type === "data") {
                row.cells.forEach((cell: any, index: any) => {
                    cell.background = "#fff";
                    if (cell.format === "date") {
                        cell.value = cell.value ? moment.utc(new Date(cell.value)).local().format(DATE_TIME_FORMAT_USER) : ""
                    }
                    if (index === 7) {
                        cell.value = cell.value ? cell.value === '1' ? "Accepted" : cell.value === '2' ? "Rejected" : "OTHERS" : "";
                    }
                    // if (index === cellIndexOfStatus[0] && CONSTANTS.ASSET_STATUS[cell.value]) {
                    //     cell.background = CONSTANTS.ASSET_STATUS[cell.value].color;
                    //     cell.value = CONSTANTS.ASSET_STATUS[cell.value].status;
                    // }
                });
            }
            if (row.type === "header") {
                row.cells.forEach((cell: any, index: number) => {
                    if (cell.value === "Status") {
                        cellIndexOfStatus.push(index);
                    }
                });
            }
        });

        component.save(options);
    };

    public loadInitData = (): void => {

        let filterJson = {};

        filterJson["obsType"] = 1024;
        filterJson["status"] = 16;
        let config = {
            headers: {
                filter: JSON.stringify(filterJson)
            }
        }

        this.props.loadReportDashboardAsync({
            isLoading: true,
            apiPayload: this._apiPayload,
            offset: 0,
            limit: this.props.ReportsState.limit,
            config: config
        })
    }

    public CategoryFilterCell = (props: GridFilterCellProps) => (
        <DropdownFilterCell
            {...props}
            data={["1-Accepted", "2-Rejected"]}
            defaultValue={"--Select--"}
        />
    );

    public render(): JSX.Element {

        let {
            isLoadingReports,
            proccessedReports,
            reportGridDataState,
            reports,
            isHideCertificatePanel,
            reportType,
            hasMoreReports
        }: IReportsInitialState = this.props.ReportsState;

        let filterDetails: string = ReportsUtility.getFilterDetails(reportGridDataState).join("; ");

        return (
            <React.Fragment>

                <LoadingOverlay
                    active={isLoadingReports}
                    spinner={<FadeLoader />}
                    text="Loading Reports...">

                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 report-grid">
                                <Grid
                                    onExpandChange={(event: any) => {
                                        this.props.handleReportsGridExpandChanged({
                                            event: event
                                        })
                                    }}
                                    data={proccessedReports}
                                    expandField="expanded"
                                    resizable
                                    reorderable
                                    // groupable
                                    sortable
                                    filterable
                                    {...reportGridDataState}
                                    // cellRender={this.cellRender}
                                    style={{ width: '100%' }}
                                    onDataStateChange={(event) => {
                                        this.props.handleReportsGridGroupSortChanged({
                                            event: event
                                        })
                                    }}
                                    onFilterChange={(event) => {

                                        let filterJson = {};

                                        if (reportType === "SERVICE") {
                                            filterJson["obsType"] = 512;
                                        }
                                        else {
                                            filterJson["obsType"] = 1024;
                                            filterJson["status"] = 16;
                                        }
                                        if (event.filter) {
                                            event.filter.filters.forEach((filter: IKendoGridFilter) => {
                                                filterJson[filter.field] = filter.value;
                                            });
                                        }
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify(filterJson)
                                            }
                                        }
                                        this.props.handleReportsGridFilterChanged({
                                            event,
                                            config,
                                            apiPayload: this._apiPayload,
                                            offset: 0,
                                            limit: this.props.ReportsState.limit
                                        })

                                    }}
                                    filterOperators={{
                                        text: [
                                            {
                                                text: 'grid.filterContainsOperator',
                                                operator: 'contains',
                                            }
                                        ]
                                    }}
                                >
                                    {CommonUtility.sortByColumn(CONSTANTS.REPORT_VIEW_GRID_COLUMNS, "colIndex", "asc")
                                        .filter((column) => column.show)
                                        .filter((column) => reportType === "SERVICE" ?
                                            column.field !== "result" : column.field.match(/.*/g))
                                        .map((column: IKendoGridColumn, index: number) => {
                                            return (
                                                <GridColumn
                                                    key={index}
                                                    field={column.field}
                                                    title={column.title}
                                                    width={column.width}
                                                    filter={column.filter === 'text' ? 'text' : 'date'}
                                                    filterable={
                                                        column.field === 'jobNumber' ||
                                                        column.field === 'quotationNumber' ||
                                                        column.field === 'asset' ||
                                                        column.field === 'client' ||
                                                        column.field === 'site' ||
                                                        column.field === 'certificateNumber' ||
                                                        column.field === 'description' ||
                                                        column.field === 'inspector' ||
                                                        column.field === 'endUserName' ||
                                                        column.field === 'endUserAddress' ||
                                                        column.field === 'location' ||
                                                        column.field === 'taskType' ||
                                                        column.field === 'result'
                                                    }
                                                    cell={
                                                        column.filter === 'date'
                                                            ? this.reportsDateCustomCell :
                                                            column.field === 'id'
                                                                ? this.idCustomCell
                                                                :
                                                                column.field === 'result'
                                                                    ? this.resultCustomCell
                                                                    : reports[column.field]
                                                    }
                                                    filterCell={
                                                        column.field === 'result'
                                                            ? this.CategoryFilterCell :
                                                            null}
                                                />
                                            );
                                        })}
                                    <GridToolbar>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                                                    <ActionButton
                                                        disabled={isLoadingReports}
                                                        text="Reload"
                                                        iconProps={IconConstants.refreshIcon}
                                                        onClick={() => this.loadInitData()}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    {hasMoreReports &&
                                                        <ActionButton
                                                            disabled={isLoadingReports}
                                                            iconProps={IconConstants.moreicon}
                                                            text="Load More"
                                                            onClick={() => {
                                                                let filterJson = {};

                                                                if (reportType === "TASK") {
                                                                    filterJson["obsType"] = 1024;
                                                                    filterJson["status"] = 16;
                                                                }
                                                                else {
                                                                    filterJson["obsType"] = 512;
                                                                }

                                                                if (reportGridDataState.filter) {
                                                                    reportGridDataState.filter.filters.forEach((filter: IKendoGridFilter) => {
                                                                        filterJson[filter.field] = filter.value;
                                                                    });
                                                                }

                                                                let config = {
                                                                    headers: {
                                                                        filter: JSON.stringify(filterJson)
                                                                    }
                                                                }
                                                                this.props.loadReportDashboardAsync({
                                                                    config,
                                                                    apiPayload: this._apiPayload,
                                                                    offset: this.props.ReportsState.offset,
                                                                    limit: this.props.ReportsState.limit,
                                                                    reportType: reportType === "TASK" ? "SERVICE" : "TASK"
                                                                })
                                                            }}
                                                        />
                                                    }

                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                                    <Label
                                                        styles={{
                                                            root: {
                                                                marginTop: "1%"
                                                            }
                                                        }}
                                                    >
                                                        {filterDetails && `Filters: ${filterDetails}`}
                                                    </Label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <Label
                                                        styles={{
                                                            root: {
                                                                marginTop: "2.25%"
                                                            }
                                                        }}
                                                    >
                                                        {reports?.length} report(s) found
                                                    </Label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">

                                                    <Toggle
                                                        styles={{
                                                            root: {
                                                                marginTop: "5%"
                                                            }
                                                        }}
                                                        checked={reportType === "TASK"}
                                                        onText="Task Report"
                                                        offText="Service Report"
                                                        onChange={() => {
                                                            let filterJson = {};

                                                            if (reportType === "TASK") {
                                                                filterJson["obsType"] = 512;
                                                            }
                                                            else {
                                                                filterJson["obsType"] = 1024;
                                                                filterJson["status"] = 16;
                                                            }

                                                            let config = {
                                                                headers: {
                                                                    filter: JSON.stringify(filterJson)
                                                                }
                                                            }
                                                            this.props.switchTaskAndServiceReport({
                                                                config,
                                                                apiPayload: this._apiPayload,
                                                                offset: 0,
                                                                limit: this.props.ReportsState.limit,
                                                                reportType: reportType === "TASK" ? "SERVICE" : "TASK"
                                                            })
                                                        }}
                                                    />
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2.0">
                                                    <PrimaryButton
                                                        className="table-export-button-div"
                                                        text="Export to Excel"
                                                        iconProps={IconConstants.excelIcon}
                                                        onClick={() => this.export()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </GridToolbar>
                                </Grid>

                                <ExcelExport
                                    data={reports}
                                    ref={exporter => this._excelExport = exporter}
                                    fileName={`Tasks_on_${moment.utc(new Date()).local().format(DATE_TIME_FORMAT_USER)}.xlsx`}
                                >
                                    {CONSTANTS.REPORT_VIEW_GRID_COLUMNS
                                        .filter((column) => column.field !== "id"&&column.field !== "result"&&column.field !== "description"&&column.field !== "endUserName"&&column.field !== "endUserAddress"&&column.field !== "taskType"&&reportType==="SERVICE" && column.show)
                                        .map((column, index) => {
                                            return (
                                                <ExcelExportColumn
                                                    key={index}
                                                    field={column.field}
                                                    title={column.title}
                                                    cellOptions={{
                                                        format: column.filter === "date" ? "date" : "",
                                                    }}
                                                />
                                            );
                                        })}
                                        {CONSTANTS.REPORT_VIEW_GRID_COLUMNS
                                        .filter((column) => column.field !== "id"&&reportType==="TASK" && column.show)
                                        .map((column, index) => {
                                            return (
                                                <ExcelExportColumn
                                                    key={index}
                                                    field={column.field}
                                                    title={column.title}
                                                    cellOptions={{
                                                        format: column.filter === "date" ? "date" : "",
                                                    }}
                                                />
                                            );
                                        })}
                                </ExcelExport>

                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
                {
                    !isHideCertificatePanel &&
                    <CertificatePreviewPanel
                        {...this.props}
                    />
                }
            </React.Fragment >
        )
    }
}
