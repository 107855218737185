import React, { Component } from 'react'
import { IconButton, Label, Panel, ActionButton, PanelType, PrimaryButton, DefaultButton, Spinner, SpinnerSize } from 'office-ui-fabric-react'
import { JobSteps } from '../Constants/JobConstants';
import ClientDetails from './ClientDetails';
import TrackingDetails from './TrackingDetails';
import SiteDetails from './SiteDetails';
import TaskDetails from './TaskDetails';
import Stepper from 'react-stepper-horizontal';
import { JobAPI } from '../Services/JobAPI';
import AlertRedux from '../../Alert/AlertRedux';
import { JobUtility } from '../Utility/JobUtility';
import { IconConstants } from '../../../Constants/StylesConstants';
import ConfirmJobRedux from './ConfirmJobRedux';
import { LO_ADMIN_ROLE } from '../../../Constants/CommonConstants';

export default class AddEditJobPanel extends Component {

    _apiObject = null;
    _jobutility;
    constructor(props) {
        super(props);
        let config = {
            headers: {}
        }

        this._apiObject = new JobAPI(window.API_URL, config);
        this._jobutility = new JobUtility();
    }

    UNSAFE_componentWillMount() {

        this.props.loadClientsAndUsersAsync({
            apiObject: this._apiObject,
            locationLevelClient: 0,
            locationLevelOffice: 2,
            IsEdit: false,
            SelectedJob: this.props.Job.SelectedJob
        })
    }

    saveJob = (cancelFlag) => {

        let { CurrentJobDetails, AllTaskTypeOptions, AssetTypeOptions } = this.props.Job;

        this.props.addJobAsync({
            apiObject: this._apiObject,
            CurrentJobDetails: CurrentJobDetails,
            cancelFlag: cancelFlag,
            AllTaskTypeOptions,
            AssetTypeOptions
        });

    }

    render() {

        let {
            PanelHeaderText,
            LoadingPanel,
            ShowPanel,
            ActiveStep,
            alertJSON,
            SelectedJob,
            CurrentJobDetails,
            hideConfirm,
            ShowAddNewSiteSection,
            IsJobEditable
        } = this.props.Job;

        const loggedInUser = window.loggedInUserDetails;
        let isDisabledCloseJobButton =
            ["SCHEDULER", LO_ADMIN_ROLE].indexOf(loggedInUser.role) === -1 ||
            !CurrentJobDetails.isDayJob ||
            CurrentJobDetails.isDayJobClosed;

        let IsSaveDisbaled = this._jobutility.isDisabledNextOrSave(CurrentJobDetails, ActiveStep);


        return (
            <Panel
                headerText={PanelHeaderText}
                isOpen={ShowPanel}
                type={ActiveStep < 3 ? PanelType.large : PanelType.extraLarge}
                onDismiss={() => this.props.openClosePanel({
                    ShowPanel: false,
                    PanelHeaderText: "",
                })}
                closeButtonAriaLabel="Close">
                {IsJobEditable ?
                    <React.Fragment>

                        {ActiveStep === 3 ?
                            <ActionButton
                                text="Save"
                                disabled={IsSaveDisbaled || (SelectedJob && SelectedJob.status === 16) || (SelectedJob && SelectedJob.status === 64)}
                                iconProps={IconConstants.saveicon}
                                onClick={() => {
                                    this.saveJob(false)
                                }}
                            />
                            :
                            <ActionButton
                                disabled={LoadingPanel || IsSaveDisbaled || ShowAddNewSiteSection}
                                text="Next"
                                iconProps={IconConstants.nextIcon}
                                onClick={() => {
                                    this.props.changeStep({
                                        flag: "I"
                                    })
                                }}
                            />
                        }
                        {` `}
                        <ActionButton
                            text="Clear"
                            disabled={SelectedJob}
                            onClick={() => {
                                this.props.resetValue({
                                    step: ActiveStep
                                });
                            }}
                            iconProps={IconConstants.clearIcon}
                        />
                        {` `}
                        {ActiveStep === 3 &&
                            <ActionButton
                                text="Cancel Job"
                                disabled={!SelectedJob || (SelectedJob && SelectedJob.status === 16)}
                                onClick={() => {
                                    this.props.openCloseJobConfirmModal({
                                        CurrentJobDetails: CurrentJobDetails,
                                        hideConfirm: false,
                                        closeOrCancel: "Cancel"
                                    });
                                }}
                                iconProps={IconConstants.cancelIcon}
                            />
                        }
                        {` `}
                        {ActiveStep === 3 &&
                            <ActionButton
                                text="Close Job"
                                disabled={isDisabledCloseJobButton}
                                onClick={() => {
                                    this.props.openCloseJobConfirmModal({
                                        CurrentJobDetails: CurrentJobDetails,
                                        hideConfirm: false,
                                        closeOrCancel: "Close"
                                    });
                                }}
                                iconProps={IconConstants.closeIcon}
                            />
                        }
                        {/* Stepper */}
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Stepper
                                        steps={[
                                            { title: 'Client Details' },
                                            { title: 'Tracking Details' },
                                            { title: 'Site Details' },
                                            { title: 'Task Details' }]
                                        }
                                        completeColor="#0F9D58"
                                        activeTitleColor="#2F76C8"
                                        activeColor="#2F76C8"
                                        activeStep={ActiveStep}
                                    />

                                </div>
                            </div>
                            <br />
                            {LoadingPanel ?
                                <Spinner
                                    label="Please Wait..."
                                    size={SpinnerSize.large}
                                />
                                :
                                <React.Fragment>
                                    {/* Stepper Header */}
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm4 ms-md12 ms-lg1">
                                                <IconButton
                                                    disabled={ActiveStep < 1}
                                                    iconProps={IconConstants.chevronLeftIcon}
                                                    onClick={() => {
                                                        this.props.changeStep({
                                                            flag: "D"
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm4 ms-md12 ms-lg10">
                                                <Label className="step-header">{JobSteps[ActiveStep]}</Label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm4 ms-md12 ms-lg1">
                                                <IconButton
                                                    disabled={ActiveStep > 2 || IsSaveDisbaled || ShowAddNewSiteSection}
                                                    iconProps={IconConstants.chevronRightIcon}
                                                    onClick={() => {
                                                        this.props.changeStep({
                                                            flag: "I"
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Input Field */}
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                {ActiveStep === 0 &&
                                                    <ClientDetails
                                                        {...this.props}
                                                    />
                                                }
                                                {ActiveStep === 1 &&
                                                    <TrackingDetails
                                                        {...this.props}
                                                    />
                                                }
                                                {ActiveStep === 2 &&
                                                    <SiteDetails
                                                        {...this.props}
                                                    />
                                                }
                                                {ActiveStep === 3 &&
                                                    <TaskDetails
                                                        {...this.props}
                                                    />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                    {/* Button */}
                                    <div className="ms-Grid" dir="ltr">

                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                {ActiveStep === 3 ?
                                                    <PrimaryButton
                                                        disabled={IsSaveDisbaled || (SelectedJob && SelectedJob.status === 16) || (SelectedJob && SelectedJob.status === 64)}
                                                        text="Save"
                                                        menuIconProps={IconConstants.saveicon}
                                                        onClick={() => {
                                                            this.saveJob(false)
                                                        }}
                                                    />
                                                    :
                                                    <PrimaryButton
                                                        disabled={IsSaveDisbaled || ShowAddNewSiteSection}
                                                        text="Next"
                                                        menuIconProps={IconConstants.nextIcon}
                                                        onClick={() => {
                                                            this.props.changeStep({
                                                                flag: "I"
                                                            })
                                                        }}
                                                    />
                                                }
                                                {` `}
                                                <DefaultButton
                                                    text="Clear"
                                                    className={SelectedJob ? "disable-button" : "cancel-button"}
                                                    disabled={SelectedJob}
                                                    onClick={() => {
                                                        this.props.resetValue({
                                                            step: ActiveStep
                                                        });
                                                    }}
                                                    menuIconProps={IconConstants.clearIcon}
                                                />
                                                {` `}
                                                {ActiveStep === 3 &&
                                                    <DefaultButton
                                                        text="Cancel Job"
                                                        className={(!SelectedJob || (SelectedJob && SelectedJob.status === 16)) ? "disable-button" : "info-button"}
                                                        disabled={!SelectedJob || (SelectedJob && SelectedJob.status === 16)}
                                                        onClick={() => {
                                                            this.props.openCloseJobConfirmModal({
                                                                CurrentJobDetails: CurrentJobDetails,
                                                                hideConfirm: false,
                                                                closeOrCancel: "Cancel"
                                                            });
                                                        }}
                                                        menuIconProps={IconConstants.cancelIcon}
                                                    />
                                                }
                                                {` `}
                                                {ActiveStep === 3 &&
                                                    <DefaultButton
                                                        text="Close Job"
                                                        className={isDisabledCloseJobButton ? "disable-button" : "info-button"}
                                                        disabled={isDisabledCloseJobButton}
                                                        onClick={() => {
                                                            this.props.openCloseJobConfirmModal({
                                                                CurrentJobDetails: CurrentJobDetails,
                                                                hideConfirm: false,
                                                                closeOrCancel: "Close"
                                                            });
                                                        }}
                                                        menuIconProps={IconConstants.closeIcon}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }

                        </div>

                    </React.Fragment>
                    :
                    <React.Fragment>
                        <h2 className="text-secondary">
                            <Label
                                styles={{
                                    root: {
                                        transform: "rotate(-25deg)",
                                        fontSize: "1.75em",
                                        margin: "4.25em 0.5em"
                                    }
                                }}
                                className="mandatory-span">
                                Sorry!! you can not edit this job
                            </Label>
                        </h2>
                    </React.Fragment>
                }

                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
                {
                    !hideConfirm &&
                    <ConfirmJobRedux
                        {...this.props}
                    />
                }
            </Panel >
        )
    }
}
