import {
  IDivisionData,
  ISetting,
  ISettingMetadata,
} from "../Interfaces/IDivision";
import moment from "moment-timezone";
import { ISelect } from "./../../../../CommonInterfaces/CommoInterfaces";

export class DivisionUtility {
  loadDivisionTableStructure = (Divisions: any[]): IDivisionData[] => {
    let TableFormattedDivision: IDivisionData[] = [];

    Divisions.forEach((element: any) => {
      let settingWithMetadata: ISetting = JSON.parse(
        element.settingWithMetadata
      );

      let settingMetatDatas: ISettingMetadata[] =
        settingWithMetadata.settingmetadata;

      let attribute_form_object:
        | ISettingMetadata
        | undefined = settingMetatDatas.find(
        (d: ISettingMetadata) => d.meta_key === "attribute_form_id"
      );
      let reference_object:
        | ISettingMetadata
        | undefined = settingMetatDatas.find(
        (d: ISettingMetadata) => d.meta_key === "certificate_reference"
      );

      TableFormattedDivision.push({
        id: settingWithMetadata.id,
        name: settingWithMetadata.name,
        acronym_code: settingWithMetadata.acronym_code,
        description: settingWithMetadata.description,
        attribute_form_id: attribute_form_object
          ? parseInt(attribute_form_object.meta_value)
          : 0,
        reference: reference_object ? reference_object.meta_value : "",
        created_date: new Date(settingWithMetadata.created_date),
        modified_date: new Date(settingWithMetadata.modified_date),
        status: settingWithMetadata.status,
        displayName: settingWithMetadata.display_name,
      });
    });

    return TableFormattedDivision;
  };

  loadFormAttributesOptions = (FormAttributes: any[]): ISelect[] => {
    let FormAttributeOptions: ISelect[] = [];

    FormAttributes.forEach((element: any) => {
      FormAttributeOptions.push({
        value: element.id,
        label: element.name,
      });
    });
    return FormAttributeOptions;
  };

  resetCurrentDivision = (): IDivisionData => {
    let CurrentDivision: IDivisionData = {
      acronym_code: "",
      attribute_form_id: -1,
      description: "",
      id: -1,
      name: "",
      reference: "",
      attribute_form: null as ISelect,
      created_date: new Date(),
      modified_date: new Date(),
      status: 1,
      displayName: "",
    };

    return CurrentDivision;
  };

  generateDivisionJSONPayLoad = (DivisionDetails: IDivisionData) => {
    let payloadJSON = {};
    if (DivisionDetails.id === -1) {
      payloadJSON = {
        tenant_id: 2,
        object_type: "DIVISION",
        name: DivisionDetails.name,
        description: DivisionDetails.description,
        type: 0,
        status: 1,
        parent_id: null,
        user_data: null,
        created_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        modified_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        created_by: null,
        last_modified_by: null,
        external_id: null,
        client_id: null,
        display_name: DivisionDetails.displayName,
        acronym_code: DivisionDetails.acronym_code,
        archived: 0,
        settingmetadata: [
          // {
          //     "tenant_id": 2,
          //     "meta_key": "certificate_reference",
          //     "meta_value": AssetTypeDetails.reference,
          //     "data_type": "STRING",
          //     "object_type": null,
          //     "display_name": "Certificate Reference"
          // },
          {
            tenant_id: 2,
            meta_key: "attribute_form_id",
            meta_value: DivisionDetails.attribute_form
              ? DivisionDetails.attribute_form.value
              : null,
            data_type: "INTEGER",
            object_type: null,
            display_name: "Form Attribute",
          },
        ],
      };
    } else {
      payloadJSON = {
        id: DivisionDetails.id,
        tenant_id: 2,
        object_type: "DIVISION",
        name: DivisionDetails.name,
        description: DivisionDetails.description,
        type: 0,
        status: 1,
        parent_id: null,
        user_data: null,
        created_date: moment(DivisionDetails.created_date).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        modified_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        created_by: null,
        last_modified_by: null,
        external_id: null,
        client_id: null,
        display_name: DivisionDetails.displayName,
        acronym_code: DivisionDetails.acronym_code,
        archived: 0,
        settingmetadata: [
          // {
          //     "setting_id": AssetTypeDetails.id,
          //     "tenant_id": 2,
          //     "meta_key": "certificate_reference",
          //     "meta_value": AssetTypeDetails.reference,
          //     "data_type": "STRING",
          //     "object_type": null,
          //     "display_name": "Certificate Reference"
          // },
          {
            setting_id: DivisionDetails.id,
            tenant_id: 2,
            meta_key: "attribute_form_id",
            meta_value: DivisionDetails.attribute_form
              ? DivisionDetails.attribute_form.value
              : null,
            data_type: "INTEGER",
            object_type: null,
            display_name: "Form Attribute",
          },
        ],
      };
    }

    return payloadJSON;
  };
}
