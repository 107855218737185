import { IReport } from "../Interfaces/IReport";
import { IReportsInitialState } from "../Interfaces/IReportsInitialState";

export const ReportInitialState: IReportsInitialState = {
    isLoadingReports: true,
    isHideCertificatePanel: true,
    isLoadingCertificatePanel: false,
    reports: [] as IReport[],
    offset: -1,
    limit: 500,
    hasMoreReports: true,
    reportGridDataState: {
        sort: [{ field: "name", dir: "asc" }],
        filter: { filters: [], logic: 'and' },
        skip: 0
    },
    proccessedReports: [],
    selectedTaskId: -1,
    PDFURL: "",
    reportType: "TASK"
}
