import { DayOfWeek, FontWeights, getId, getTheme, IComboBoxOption, IDatePickerStrings, mergeStyleSets } from "office-ui-fabric-react";
import { ICredential } from "../CommonInterfaces/CommoInterfaces";


export const DayPickerStrings: IDatePickerStrings = {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker'
};

export const firstDayOfWeek = DayOfWeek.Sunday;

export const _labelId: string = getId('callout-label');
export const _descriptionId: string = getId('callout-description');
export const theme = getTheme();
export const callOutStyles = mergeStyleSets({
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center'
    },
    callout: {
        maxWidth: 300
    },
    header: {
        padding: '18px 24px 12px'
    },
    title: [
        theme.fonts.xLarge,
        {
            margin: 0,
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semilight
        }
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px'
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap'
    },
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary
        }
    ]
});

export const SPECIAL_CHARACTERS: IComboBoxOption[] = [
    { key: '~', text: '~' },
    { key: '`', text: '`' },
    { key: '!', text: '!' },
    { key: '@', text: '@' },
    { key: '#', text: '#', disabled: true },
    { key: '%', text: '%' },
    { key: '^', text: '^' },
    { key: '&', text: '&' },
    { key: '*', text: '*' },
    { key: '(', text: '(' },
    { key: ')', text: ')' },
    { key: '+', text: '+' },
    { key: '_', text: '_' },
    { key: '-', text: '-' },
    { key: '<', text: '<' },
    { key: '>', text: '>' },
    { key: '\\', text: '\\' },
    { key: '/', text: '/' },
    { key: '.', text: '.' },
    { key: '?', text: '?' },
    { key: '|', text: '|' },
    { key: '"', text: '"' },
    { key: "'", text: "'" },
    { key: "}", text: "}" },
    { key: "{", text: "{" },
    { key: "[", text: "[" },
    { key: "]", text: "]" },
];

export const FIELD_ENGG_ROLE: string = "FIELD-ENGG";
export const FIELD_JUNIOR_ROLE: string = "FIELD-JUNIOR";
export const CLIENT_USER_ROLE: string = "CLIENT-USER";
export const SCHEDULER_ROLE: string = "SCHEDULER";
export const SITE_USER_ROLE: string = "SITE-USER";
export const REQUESTOR_ROLE: string = "REQUESTER";
export const CLIENT_ADMIN_ROLE: string = "CLIENT-ADMIN";
export const MASTER_ADMIN_ROLE: string = "MASTER_ADMIN";
export const LO_ADMIN_ROLE: string = "LO-ADMIN";
export const CONFIG_ADMIN_ROLE: string = "CONFIG_ADMIN";
export const CORPORATE_ROLE: string = "CORPORATE";
export const APPROVER_ROLE: string = "APPROVER";
export const APPROVER_ONLY_ROLE: string = "APPROVER-ONLY";
export const SALES_PERSON_ROLE: string = "SALES-PERSON";

export const CREDENTIALS = {
    1: {
        email: "scheduler@xlsys.co", password: "password",
    } as ICredential,
    2: {
        email: "midhunlal2126921@gmail.com", password: "password@123",
    } as ICredential,
    3: {
        email: "admin@xlsys.co", password: "password",
    } as ICredential,
    4: {
        email: "admin-astr@xlsys.co", password: "password",
    } as ICredential,
    5: {
        email: "roby.kurien@qwikspec.com", password: "password1",
    } as ICredential,
    6: {
        email: "midhunsheduler2@gmail.com", password: "password",
    } as ICredential,
    7: {
        email: "amartya.bhattacharyya@wiwitness.com", password: "password2",
    } as ICredential,
    8: {
        email: "astron@qwikspec.com", password: "qwik@1234#"
    } as ICredential,
    9: {
        email: "jawed-admin@xlsys.co", password: "password"
    } as ICredential,
    10: {
        email: "qsadmin@qwikspec.com", password: "qwik@1234#"
    } as ICredential,
    11: {
        email: "roby.kurien@xlsystech.com", password: "password"
    } as ICredential,
    12: {
        email: "midhunadmin1@gmail.com", password: "password"
    } as ICredential,
    13: {
        email: "admin@xlsys.co", password: "password"
    } as ICredential,
    14: {
        email: "midhun.k@trackdfect.com", password: "password"
    } as ICredential,
    15: {
        email: "amartya.bhattacharyya@xlsys.co", password: "password"
    } as ICredential,
    16: {
        email: "Midhuntest@gmail.com", password: "password"
    } as ICredential,
    17: {
        email: "client-admin@xlsys.co", password: "password"
    } as ICredential,
    18: {
        email: "roby.kurien@gmail.com", password: "password"
    } as ICredential,
    19: {
        email: "midhun.k@trackdfect.com", password: "password"
    } as ICredential,
    20: {
        email: "approver@xlsys.co", password: "abc123"
    } as ICredential,
    21: {
        email: "Client@gmail.com", password: "password"
    } as ICredential,
    22: {
        email: "midhunclientuser@gmail.com", password: "password"
    } as ICredential,
    23: {
        email: "shanthi-clientadmin@xlsys.co", password: "password"
    } as ICredential,
    24: {
        email: "app2@xlsys.co", password: "password"
    } as ICredential,
    25: {
        email: "abc@gmail.com", password: "password"
    } as ICredential,
    26: {
        email: "templatemerge", password: "qwik@1234#"
    } as ICredential,
    27: {
        email: "app1@xlsys.co", password: "password"
    } as ICredential,
    28: {
      //  email:  "shanthi-appr@xlsys.co", password: "password"
       /* email: "shanthi-appr@xlsys.co", password: "password", isAsn:true,
        asn:74137*/
        email: "client1@xlsys.co", password: "password", isAsn:true,
        asn:73706
    } as ICredential,
    29: {
        email: "shanthi-sched@xlsys.co", password: "password"
    } as ICredential,
    30: {
        email: "shanthi-insp@xlsys.com", password: "password"
    } as ICredential,
    31: {
        email: "shanthi-appr@xlsys.co", password: "password"
    } as ICredential
};