import { Icon, TooltipHost } from 'office-ui-fabric-react';
import React from 'react';
import { calloutProps, IconConstants, tooltipId } from '../../../Constants/StylesConstants';
import moment from 'moment-timezone';
import { DATE_FORMAT } from '../../../Constants/MessageConstants';
import { RESULT_DETAILS } from '../Constants/AssessmentsConstants';

export class DateCustomCell extends React.Component<any, any> {
    render() {

        const value = this.props.dataItem[this.props.field];

        return (
            <td className="no-group-show">
                {value ?
                    <span>{moment.utc(new Date(value)).local().format(DATE_FORMAT)}</span>
                    :
                    <span></span>
                }
            </td>
        );
    }
}

export class IdCustomCell extends React.Component<any, any> {
    render() {

        const value = this.props.dataItem[this.props.field];

        return (
            <td className="no-group-show">
                <Icon
                    onClick={() => {
                        this.props.currentProps.showHideCertificatePanel({
                            isHideCertificatePanel: false,
                            selectedTaskId: value
                        })

                    }}
                    iconName={IconConstants.pdfIcon.iconName}
                    styles={{
                        root: {
                            color: "#403cbd"
                        }
                    }}
                />
            </td>
        );
    }
}

export class HistoryCustomCell extends React.Component<any, any> {
    render() {

        return (
            <td className="no-group-show">
                <TooltipHost
                    id={tooltipId}
                    calloutProps={calloutProps}
                    styles={{
                        root:
                        {
                            display: 'inline-block',

                        }
                    }}
                >

                    <Icon
                        onClick={() => {
                            this.props.currentProps.openCloseObservationHistoryPanel({
                                isOpenAssessmentHistoryPanel: true,
                                selectedAssessment: this.props.dataItem
                            })
                        }}
                        iconName={IconConstants.editicon.iconName}
                    />
                </TooltipHost>
            </td>
        );
    }
}

export class ResultCustomCell extends React.Component<any, any> {
    render() {

        const value = this.props.dataItem[this.props.field];
        const showText = RESULT_DETAILS[value]?.showText;
        const spanClassName = (`${value}`) === `1` ? 'success-span' : (`${value}`) === `2` ? 'mandatory-span' : 'info-span';

        return (
            <td className="no-group-show">
                <span className={spanClassName}>{showText}</span>
            </td>
        );
    }
}

export class SpecificationsCustomCell extends React.Component<any, any> {
    render() {

        const value = this.props.dataItem[this.props.field];

        return (
            <td className="no-group-show">
                <span>{value}</span>
            </td>
        );
    }
}