import { SequenceInitialState } from "../Constants/SequenceInitialState";
import * as CONSTANTS from "../Constants/SequenceNumberingConstants";
import { ISequenceState } from "../Interfaces/ISequenceState";
import { ISequenceActionKeys } from "../Interfaces/ISequenceActions";
import { ISequencePayload } from "../Interfaces/ISequencePayload";
import { SequenceNumberingUtility } from "../Utility/SequenceNumberingUtility";

export function SequenceReducer(state: ISequenceState = SequenceInitialState, action: ISequenceActionKeys): ISequenceState {

    let tempState: ISequenceState = { ...state };
    let payload: ISequencePayload = action.payload;
    let utility: SequenceNumberingUtility = new SequenceNumberingUtility();
    let newSequenceGridDataState = { ...tempState.sequenceGridDataState };

    switch (action.type) {

        case CONSTANTS.CLEAN_SEQUENCE_NUMBERING_DASHBOARD:
            tempState.isLoadingSequenceDashboard = true;
            tempState.sequenceGridDataState = {
                sort: [{ field: "sequenceName", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                group: [{ field: "officeName", dir: 'desc' }],
                skip: 0
            };
            tempState.isLoadingSequenceAddEditPanel = false;
            tempState.sequences = [];
            tempState.proccessedSequences = [];
            tempState.isShowSequenceAddEditPanel = false;
            tempState.selectedSequence = null;
            break;


        case CONSTANTS.LOAD_SEQUENCE_NUMBERING_DASHBOARD:
            tempState.isLoadingSequenceDashboard = payload.isLoading;
            if (!payload.isLoading) {
                tempState.sequences = [...payload.sequences];
                tempState.proccessedSequences = utility.processSequencesForKendoGrid([...tempState.sequences], newSequenceGridDataState);

            }
            break;

        case CONSTANTS.HANDLE_SEQUENCE_NUMBERING_GRID_GROUP_SORT_CHANGE:
            newSequenceGridDataState.sort = payload.event.dataState.sort;
            let indexOfId = -1;
            indexOfId = payload.event.dataState.group?.findIndex((d: any) => d.field === "officeName");
            if (
                (indexOfId > -1 && payload.event.dataState.group?.length === 1) ||
                payload.event.dataState.group?.length === 0
            ) {
                newSequenceGridDataState.group = payload.event.dataState.group;
            }
            tempState.sequenceGridDataState = newSequenceGridDataState;
            tempState.proccessedSequences = utility.processSequencesForKendoGrid(tempState.sequences, newSequenceGridDataState);
            break;

        case CONSTANTS.OPEN_CLOSE_SEQUENCE_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON;
            break;

        case CONSTANTS.SHOW_HIDE_SEQUENCE_NUMBERING_ADD_EDIT_PANEL:
            tempState.isShowSequenceAddEditPanel = payload.isShowSequenceAddEditPanel;
            tempState.isLoadingSequenceAddEditPanel = false;
            tempState.selectedSequence = payload.isShowSequenceAddEditPanel ? payload.selectedSequence : null;
            break;

        case CONSTANTS.CHANGE_SEQUENCE_NUMBER:
            tempState.selectedSequence.sequenceNumber = payload.sequenceNumber;
            break;

        case CONSTANTS.SAVE_SEQUENCE_NUMBER:
            tempState.isLoadingSequenceAddEditPanel = payload.isLoading;
            break;

        default:
            break;
    }

    return tempState;
}