import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AssetTypeDashboard from './AssetTypeDashboard';
import { AssetTypeActions } from '../Actions/AssetTypeActions';
import { Breadcrumb } from 'office-ui-fabric-react';
import { ASSET_TYPE_BREADCRUMBS } from '../Constants/AssetTypeConstants';

const mapStateToProps = (state) => {
    return {
        AssetType: state.AssetTypeReducer
    }
}

class AssetTypesComponent extends PureComponent {

    render() {

        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Breadcrumb
                            className="custom-breadcrumb"
                            items={ASSET_TYPE_BREADCRUMBS}
                            ariaLabel="With last item rendered as heading"
                            overflowAriaLabel="More links"
                        />
                    </div>

                </div>
                <AssetTypeDashboard
                    {...this.props}
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let AssetTypeAction = new AssetTypeActions();

    return bindActionCreators({ ...AssetTypeAction }, dispatch);
}

const AssetTypes = connect(mapStateToProps, mapDispatchToProps)(AssetTypesComponent);
export default AssetTypes;
