import {
    ISetting, ITaskTypeData, ISettingMetadata
} from '../Interfaces/TaskTypeInterface';
import { ISelect } from '../../../../CommonInterfaces/CommoInterfaces';
import moment from 'moment';
import { IDropdownOption } from 'office-ui-fabric-react';

export class TaskTypeUtility {

    loadTaskTypeTableStructure = (TaskTypes: any[]): ITaskTypeData[] => {

        let TableFormattedAsssetType: ITaskTypeData[] = [];

        TaskTypes.forEach((element: any) => {
            let settingWithMetadata: ISetting = JSON.parse(element.settingWithMetadata);

            let settingMetatDatas: ISettingMetadata[] = settingWithMetadata.settingmetadata;

            let attribute_form_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "attribute_form_id");
            let reference_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "reference_standard");
            let inspection_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "inspection_method");
            let division_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "division_id");
            let asset_type_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "asset_type_id");
            let sections_json_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "sectionsJson");
            let validity_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "validity");
            let eiac_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "EIAC");

            TableFormattedAsssetType.push({
                id: settingWithMetadata.id,
                name: settingWithMetadata.name,
                acronym_code: settingWithMetadata.acronym_code,
                description: settingWithMetadata.description,
                validity: validity_object ? parseFloat(validity_object.meta_value) : 0,
                eiac: eiac_object ? parseFloat(eiac_object.meta_value) : 0,
                attribute_form_id: attribute_form_object ? parseInt(attribute_form_object.meta_value) : 0,
                reference_standard: reference_object ? reference_object.meta_value : "",
                inspection_method: inspection_object ? inspection_object.meta_value : "",
                division_id: division_object ? parseInt(division_object.meta_value) : 0,
                created_date: new Date(settingWithMetadata.created_date),
                modified_date: new Date(settingWithMetadata.modified_date),
                status: settingWithMetadata.status,
                asset_type_id: asset_type_object ? parseInt(asset_type_object.meta_value) : 0,
                // sectionsJson: sections_json_object ? JSON.parse(sections_json_object.meta_value) : {}
                sectionsJson: sections_json_object ? JSON.stringify(JSON.parse(sections_json_object.meta_value), null, 2) : "{}",
                IsValidSectionJSON: true,
                external_id: settingWithMetadata.external_id
            });

        });

        return TableFormattedAsssetType;

    }

    loadFormAttributesOptions = (FormAttributes: any[]): ISelect[] => {
        let FormAttributeOptions: ISelect[] = [];

        FormAttributes.forEach((element: any) => {
            FormAttributeOptions.push({
                value: element.id,
                label: element.name
            });
        })
        return FormAttributeOptions;
    }

    loadDashboardDropDownOptions = (FormAttributes: any[]): IDropdownOption[] => {
        let FormAttributeOptions: IDropdownOption[] = [];

        FormAttributes.forEach((element: any) => {
            FormAttributeOptions.push({
                key: element.id,
                text: element.name
            });
        })
        return FormAttributeOptions;
    }

    resetCurrentTaskType = (): ITaskTypeData => {
        let CurrentTaskType: ITaskTypeData = {
            external_id: "",
            eiac: 0,
            acronym_code: "",
            attribute_form_id: -1,
            division_id: -1,
            asset_type_id: -1,
            description: "",
            id: -1,
            name: "",
            reference_standard: "",
            inspection_method: "",
            division: null as ISelect,
            attribute_form: null as ISelect,
            created_date: new Date(),
            modified_date: new Date(),
            status: 1,
            sectionsJson: '{}',
            IsValidSectionJSON: true,
            validity: 0
        };

        return CurrentTaskType;
    }

    generateTaskTypeJSONPayLoad = (TaskTypeDetails: ITaskTypeData) => {

        let payloadJSON = {};
        if (TaskTypeDetails.id === -1) {
            payloadJSON = {
                "tenant_id": 2,
                "object_type": "TaskType",
                "name": TaskTypeDetails.name,
                "description": TaskTypeDetails.description,
                "type": 0,
                "status": 1,
                "parent_id": null,
                "user_data": null,
                "created_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "modified_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "created_by": 2,
                "last_modified_by": null,
                "external_id": null,
                "client_id": null,
                "display_name": null,
                "acronym_code": TaskTypeDetails.acronym_code,
                "archived": 0,
                "eiac": TaskTypeDetails.eiac ? 1 : 0,
                "settingmetadata": [
                    {
                        "tenant_id": 2,
                        "meta_key": "inspection_method",
                        "meta_value": TaskTypeDetails.inspection_method,
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Inspection Method"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "reference_standard",
                        "meta_value": TaskTypeDetails.reference_standard,
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Reference Standard"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "attribute_form_id",
                        "meta_value": TaskTypeDetails.attribute_form ? TaskTypeDetails.attribute_form.value : null,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Form Attribute"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "division_id",
                        "meta_value": TaskTypeDetails.division ? TaskTypeDetails.division.value : null,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Division"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "asset_type_id",
                        "meta_value": TaskTypeDetails.asset_type ? TaskTypeDetails.asset_type.value : null,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Asset Type"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "sectionsJson",
                        "meta_value": TaskTypeDetails.sectionsJson ? TaskTypeDetails.sectionsJson : "{}",
                        "data_type": "JSON",
                        "object_type": null,
                        "display_name": "Checklist"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "validity",
                        "meta_value": TaskTypeDetails.validity,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Validity"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "EIAC",
                        "meta_value": TaskTypeDetails.eiac ? 1 : 0,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "EIAC"
                    }
                ]
            }
        }
        else {
            payloadJSON = {
                "id": TaskTypeDetails.id,
                "tenant_id": 2,
                "object_type": "TaskType",
                "name": TaskTypeDetails.name,
                "description": TaskTypeDetails.description,
                "type": 0,
                "status": 1,
                "parent_id": null,
                "user_data": null,
                "created_date": moment(TaskTypeDetails.created_date).format("YYYY-MM-DD HH:mm:ss"),
                "modified_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "created_by": 2,
                "last_modified_by": null,
                "external_id": null,
                "eiac": TaskTypeDetails.eiac ? 1 : 0,
                "client_id": null,
                "display_name": null,
                "acronym_code": TaskTypeDetails.acronym_code,
                "archived": 0,
                "settingmetadata": [
                    {
                        "setting_id": TaskTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "reference_standard",
                        "meta_value": TaskTypeDetails.reference_standard,
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Reference Standard"
                    },
                    {
                        "setting_id": TaskTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "inspection_method",
                        "meta_value": TaskTypeDetails.inspection_method,
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Inspection Method"
                    },
                    {
                        "setting_id": TaskTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "attribute_form_id",
                        "meta_value": TaskTypeDetails.attribute_form ? TaskTypeDetails.attribute_form.value : null,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Form Attribute"
                    },
                    {
                        "setting_id": TaskTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "division_id",
                        "meta_value": TaskTypeDetails.division ? TaskTypeDetails.division.value : null,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "DIVISION"
                    },
                    {
                        "setting_id": TaskTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "asset_type_id",
                        "meta_value": TaskTypeDetails.asset_type ? TaskTypeDetails.asset_type.value : null,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Asset Type"
                    },
                    {
                        "setting_id": TaskTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "sectionsJson",
                        "meta_value": TaskTypeDetails.sectionsJson ? TaskTypeDetails.sectionsJson : null,
                        "data_type": "JSON",
                        "object_type": null,
                        "display_name": "Checklist"
                    },
                    {
                        "setting_id": TaskTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "validity",
                        "meta_value": TaskTypeDetails.validity,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Validity"
                    },
                    {
                        "setting_id": TaskTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "EIAC",
                        "meta_value": TaskTypeDetails.eiac ? 1 : 0,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "EIAC"
                    }
                ]
            }
        }



        return payloadJSON;
    }
}
