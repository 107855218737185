import * as CONSTANTS from "../Constants/TaskTypeConstants";
import { TaskTypeUtility } from "../Utility/TaskTypeUtility";
import { Dispatch } from "redux";
import { TaskTypeInitialState } from "../Constants/TaskTypeInitialState";

export class TaskTypeActions {

    cleanDashboard = (payload) => {
        return { type: CONSTANTS.CLEAN_TASKTYPE_DASHBOARD, payload }
    }


    loadTaskTypeDashboard = (payload) => {
        return (dispatch: Dispatch) => {
            payload.isOngoingLoading = true;
            dispatch(this.loadTaskTypeDashboardAsync(payload));
            payload.apiObject.getTaskTypeDetails(payload.objectType, payload.offset, payload.limit)
                .then((response) => {
                    payload.isOngoingLoading = false;
                    payload.TaskTypes = response[0].data;
                    payload.Divisions = response[1].data;
                    dispatch(this.loadTaskTypeDashboardAsync(payload));
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = "Error while loading TaskTypes";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
        }
    }

    loadTaskTypeDashboardAsync = (payload) => {
        return { type: CONSTANTS.LOAD_TASKTYPE_DASHBOARD, payload }
    }

    sortTaskTypeDashboard = (payload) => {
        return { type: CONSTANTS.SORT_TASKTYPE_DASHBOARD, payload }
    }

    changeTaskTypeDashboardFilterValue = (payload: any) => {
        switch (payload.filterType) {

            case "name":
                return { type: CONSTANTS.CHANGE_TASKTYPE_NAME_FILTER_VALUE, payload }

            case "referenceStandard":
                return { type: CONSTANTS.CHANGE_TASKTYPE_REFERENCE_STANDARD_FILTER_VALUE, payload }

            case "inspectionMethod":
                return { type: CONSTANTS.CHANGE_TASKTYPE_INSPECTION_METHOD_FILTER_VALUE, payload }

            case "validity":
                return { type: CONSTANTS.CHANGE_TASKTYPE_VALIDITY_FILTER_VALUE, payload }

            case "division":
                return { type: CONSTANTS.CHANGE_TASKTYPE_DIVISION_FILTER_VALUE, payload }

            default:
                break;
        }
    }

    loadAttributeDropDowns = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.loadAttributeDropDownsAsync(payload));
            payload.apiObject.getSettingObjects(payload.objectType, payload.division, payload.assetType)
                .then((response) => {
                    payload.isOngoingLoading = false;
                    payload.FormAttributes = response[0].data;
                    payload.Divisions = response[1].data;
                    payload.AssetTypes = response[2].data;
                    dispatch(this.loadAttributeDropDownsAsync(payload));
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = "Error while loading Attributes";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
        }
    }

    loadAttributeDropDownsAsync = (payload) => {
        return { type: CONSTANTS.LOAD_TASKTYPE_ATTRIBUTES, payload }
    }

    changeTaskTypeTab = (payload) => {
        return { type: CONSTANTS.CHANGE_TASKTYPE_TAB, payload }
    }

    closeTaskTypePanel = (payload) => {
        return { type: CONSTANTS.CLOSE_TASKTYPE_DETAILS_PANEL, payload }
    }

    changeTaskTypeDescription = (payload) => {
        switch (payload.field) {
            case "name":
                return { type: CONSTANTS.CHANGE_TASKTYPE_NAME, payload }

            case "acronymCode":
                return { type: CONSTANTS.CHANGE_TASKTYPE_ACRONYM_CODE, payload }

            case "description":
                return { type: CONSTANTS.CHANGE_TASKTYPE_DESCRIPTION, payload }

            case "reference_standard":
                return { type: CONSTANTS.CHANGE_TASKTYPE_REFERENCE_STANDARD, payload }

            case "inspection_method":
                return { type: CONSTANTS.CHANGE_TASKTYPE_INSPECTION_METHOD, payload }

            case "division":
                return { type: CONSTANTS.CHANGE_TASKTYPE_DIVISION, payload }

            case "validity":
                return { type: CONSTANTS.CHANGE_TASKTYPE_VALIDITY, payload }

            case "eiac":
                return { type: CONSTANTS.CHANGE_TASKTYPE_IS_EIAC_APPROVED, payload }

            default:
                break;
        }
    }

    changeTaskTypeAttributes = (payload) => {
        switch (payload.field) {
            case "form":
                return { type: CONSTANTS.CHANGE_TASKTYPE_FORM_ATTRIBUTE, payload }
            case "assetType":
                return { type: CONSTANTS.CHANGE_TASKTYPE_ASSET_TYPE, payload }
            case "checkList":
                return { type: CONSTANTS.CHANGE_TASKTYPE_CHECKLIST, payload }
            default:
                break;
        }
    }

    // Save Task Type
    saveTaskTypeDetails = (payload) => {
        return { type: CONSTANTS.SAVE_TASKTYPE_DETAILS, payload };
    }

    saveTaskTypeDetailsAsync = (payload) => {
        return dispatch => {
            payload.isOnGoingSave = true;
            dispatch(this.saveTaskTypeDetails(payload));

            let utility = new TaskTypeUtility();
            let assetTypePayload = utility.generateTaskTypeJSONPayLoad(payload.CurrentTaskType);

            payload.apiObject.saveTaskTypeConfigData(assetTypePayload).then((response) => {
                payload.isOnGoingSave = false;
                dispatch(this.saveTaskTypeDetails(payload))
                payload.hideAlert = false;
                payload.alertHeader = "Success !!";
                payload.alertSubText = "Data saved successfully";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    payload.objectType = "TaskType";
                    payload.offset = 0;
                    payload.limit = TaskTypeInitialState.limit;
                    dispatch(this.openCloseAlertModal(payload));
                    dispatch(this.cleanDashboard(payload));
                    dispatch(this.closeTaskTypePanel(payload));
                    dispatch(this.loadTaskTypeDashboard(payload));
                }, 2000);

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);

            }).catch((error) => {
                console.error("Error: ", error);

                payload.hideAlert = false;
                payload.alertHeader = "Error !!";
                payload.alertSubText = "Error while saving Task Type Details";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2500);

            })
        }
    }

    openCloseAlertModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_TASK_TYPE_ALERT_MODAL, payload };
    }

    openCloseTaskTypeConfirmModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_TASKTYPE_CONFIRM_MODAL, payload };
    }

    deleteTaskType = (payload) => {
        return { type: CONSTANTS.DELETE_TASK_TYPE, payload };
    }

    deleteTaskTypeAsync = (payload) => {
        return dispatch => {
            payload.isOngoingDelete = true;
            dispatch(this.deleteTaskType(payload));

            payload.apiObject.deleteTaskType(payload.CurrentTaskType).then((response) => {
                payload.isOngoingDelete = false;
                payload.CurrentTaskType = response.data;
                dispatch(this.deleteTaskType(payload))
                payload.hideAlert = false;
                payload.alertHeader = "Success !!";
                payload.alertSubText = "Task Type Archived successfully. Please refresh to check data";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);

            }).catch((error) => {
                console.error("Error: ", error);

                payload.hideAlert = false;
                payload.alertHeader = "Error !!";
                payload.alertSubText = "Error while deleting Task Type";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2500);

            })
        }
    }

}