import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TaskDashboard from './TaskDashboard';
import { Breadcrumb, Dropdown, Label } from 'office-ui-fabric-react';
import { TaskActions } from '../Actions/TaskActions';
import { TaskDropDownStyles } from '../Constants/TaskStylesConstants';
import { AssetActions } from '../../Assets/Actions/AssetActions';

const mapStateToProps = (state) => {
    return {
        Task: state.TaskReducer,
        Asset: state.AssetReducer

    }
}

class TaskComponent extends PureComponent {

    onClickTaskBreadCrumbItem = (ev, item) => {
        switch (item.key) {
            case "TASK":
                this.props.showTaskDetailsTab({
                    SelectedTask: null,
                    ShowTaskDetailsTab: false
                });
                break;
            default:
                break;
        }
    }

    render() {

        let { TaskBreadCrumb, showColumns, LoadingTaskDashboard } = this.props.Task;

        let ConfiguredBreadCrumb = [];
        TaskBreadCrumb.forEach((element, index) => {
            ConfiguredBreadCrumb.push({
                text: element.text,
                key: element.key,
                onClick: index < TaskBreadCrumb.length - 1 ? this.onClickTaskBreadCrumbItem : null,
            })
        });


        return (
            <React.Fragment>
                {/* <Label>Operations / Tasks</Label> */}
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={ConfiguredBreadCrumb}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Columns: </Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <Dropdown
                                disabled={LoadingTaskDashboard}
                                placeholder="Select columns"
                                selectedKeys={showColumns}
                                onChange={(event, selectedColumn) => {
                                    this.props.showHideDashboardColumns({
                                        selectedColumn: selectedColumn
                                    })
                                }}
                                multiSelect
                                options={[
                                    { key: 'jobNumber', text: 'Job Number' },
                                    { key: 'location', text: 'Asset' },
                                    { key: 'assetType', text: 'Asset Type' },
                                    { key: 'taskType', text: 'Activity' },
                                    { key: 'client', text: 'Client' },
                                    { key: 'site', text: 'Site' },
                                    { key: 'inspector', text: 'Inspector' },
                                    { key: 'certificateNumber', text: 'Certificate Number' },
                                    { key: 'actualInspectionDate', text: 'Actual Inspection Date' },
                                    { key: 'observationType', text: 'Observation Type' },
                                    { key: 'quotationNumber', text: 'Quote / WO' },
                                    { key: 'modifiedDate', text: 'Modified Date' },
                                    { key: 'endUserName', text: 'End User Name' },
                                    { key: 'endUserAddress', text: 'End User Address' },
                                ]}
                                styles={TaskDropDownStyles}
                            />
                        </div>
                    </div>
                </div>
                <TaskDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let TaskAction = new TaskActions();
    let AssetAction = new AssetActions();

    return bindActionCreators(Object.assign({}, TaskAction, AssetAction), dispatch);

    // return bindActionCreators({ ...TaskAction }, dispatch);
}

const Tasks = connect(mapStateToProps, mapDispatchToProps)(TaskComponent);
export default Tasks;
