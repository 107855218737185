import React, { Component } from 'react';
import { Dialog, DialogType } from 'office-ui-fabric-react';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class Alert extends Component {

    render() {
        let { hideAlert, alertHeader, alertSubText } = this.props.alertJSON;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: alertHeader,
            subText: alertSubText
        };
        return (
            <Dialog
                hidden={hideAlert}
                // onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
            </Dialog>
        )
    }
}
