import { IKendoGridColumn } from "../../../CommonInterfaces/CommoInterfaces";

export const CLEAN_ASSESSMENT_DASHBOARD: string = 'CLEAN_ASSESSMENT_DASHBOARD';
export const SHOW_HIDE_ASSESMENT_DASHBOARD_COLUMNS: string = 'SHOW_HIDE_ASSESMENT_DASHBOARD_COLUMNS';
export const SHOW_LOADER_DASHBOARD: string = 'SHOW_LOADER_DASHBOARD';
export const LOAD_ASSESSMENT_DASHBOARD: string = 'LOAD_ASSESSMENT_DASHBOARD';
export const CHANGE_ASSESSMENT_FILTER: string = 'CHANGE_ASSESSMENT_FILTER';
export const SHOW_HIDE_ASSESSMENT_CERTIFICATE_PANEL: string = 'SHOW_HIDE_ASSESSMENT_CERTIFICATE_PANEL';
export const OPEN_CLOSE_ASSESSMENT_HISTORY_PANEL: string = 'OPEN_CLOSE_ASSESSMENT_HISTORY_PANEL';
export const LOAD_TASK_DETAILS: string = 'LOAD_TASK_DETAILS';
export const LOAD_ASSESSMENT_CERTIFICATE: string = 'LOAD_ASSESSMENT_CERTIFICATE';
export const CHANGE_FILTER_DATE_RANGE: string = "CHANGE_FILTER_DATE_RANGE";
export const HANDLE_ASSESSMENT_GRID_EXPAND_CHANGE: string = "HANDLE_ASSESSMENT_GRID_EXPAND_CHANGE";
export const HANDLE_ASSESSMENT_GRID_FILTER_CHANGE: string = "HANDLE_ASSESSMENT_GRID_FILTER_CHANGE";
export const HANDLE_ASSESSMENT_GRID_GROUP_SORT_CHANGE: string = "HANDLE_ASSESSMENT_GRID_GROUP_SORT_CHANGE";
export const SWITCH_TASK_AND_SERVICE_ASSESSMENT: string = "SWITCH_TASK_AND_SERVICE_ASSESSMENT";
export const CLEAN_ASSESSMENT_FILTER: string = 'CLEAN_ASSESSMENT_FILTER';
export const LOAD_CLIENTS_ASSESSMENT: string = 'LOAD_CLIENTS_ASSESSMENT';
export const LOAD_SITES_ASSESSMENT: string = 'LOAD_SITES_ASSESSMENT';
export const CHANGE_ASSESSMENT_CLIENT_FILTER: string = 'CHANGE_ASSESSMENT_CLIENT_FILTER';
export const CHANGE_ASSESSMENT_SITE_FILTER: string = 'CHANGE_ASSESSMENT_SITE_FILTER';
export const CHANGE_ASSESSMENT_DASHBOARD_FILTER: string = 'CHANGE_ASSESSMENT_DASHBOARD_FILTER';
export const OPEN_CLOSE_ASSESSMENT_ALERT_MODAL: string = 'OPEN_CLOSE_ASSESSMENT_ALERT_MODAL';

// Assesment Kendo Grid Columns
export const ASSESSMENT_VIEW_GRID_COLUMNS: IKendoGridColumn[] = [
    {
        title: "Report",
        field: "id",
        show: true,
        filter: "text",
        width: 80,
        colIndex: 16
    },
    {
        title: "History",
        field: "id",
        show: false,
        filter: "text",
        // width:150,
        colIndex: 1
    },
    {
        title: "Job",
        field: "jobNumber",
        show: false,
        filter: "text",
        // width:150,
        colIndex: 2
    },
    {
        title: "Id",
        field: "acronymCode",
        show: true,
        filter: "text",
        // width:150,
        colIndex: 3
    },
    {
        title: "Quote / WO",
        field: "quotationNumber",
        show: false,
        filter: "text",
        // width:150,
        colIndex: 2
    },
    {
        title: "Certificate",
        field: "certificateNumber",
        show: true,
        filter: "text",
        // width:150,
        colIndex: 6
    },
    {
        title: "Client",
        field: "client",
        show: false,
        filter: "text",
        // width:150,
        colIndex: 5
    },
    {
        title: "Site",
        field: "site",
        show: true,
        filter: "text",
        // width:150,
        colIndex: 10
    },
    {
        title: "Name",
        field: "location",
        show: true,
        filter: "text",
        // width:150,
        colIndex: 2
    },
    {
        title: "Specifications",
        field: "specifications",
        show: true,
        filter: "text",
        // width:150,
        colIndex: 3
    },
    {
        title: "Course",
        field: "taskType",
        show: true,
        filter: "text",
        // width:150,
        colIndex: 5
    },
    {
        title: "Last Result",
        field: "result",
        show: true,
        filter: "numeric",
        // width:150,
        colIndex: 6
    },
    {
        title: "Inspector",
        field: "inspector",
        show: false,
        filter: "text",
        // width:150,
        colIndex: 11
    },
    {
        title: "Description",
        field: "description",
        show: false,
        filter: "text",
        // width:150,
        colIndex: 12
    },
    {
        title: "Inspection",
        field: "actualInspectionDate",
        show: false,
        filter: "date",
        // width:150,
        colIndex: 13
    },
    {
        title: "Certificate Date",
        field: "certificateDate",
        show: false,
        filter: "date",
        // width:150,
        colIndex: 10
    },
    {
        title: "Expiry Date",
        field: "dueDate",
        show: true,
        filter: "date",
        // width:150,
        colIndex: 9
    },
    {
        title: "Issue Date",
        field: "actualInspectionDate",
        show: true,
        filter: "date",
        // width:150,
        colIndex: 8
    },
    {
        title: "Last Result",
        field: "status",
        show: false,
        filter: "text",
        // width:150,
        colIndex: 15
    },
    {
        title: "Asset Type",
        field: "assetType",
        show: false,
        filter: "text",
        // width:150,
        colIndex: 16
    },
    {
        title: "Current Status",
        field: "itemStatus",
        show: true,
        filter: "custom",
        // width:150,
        colIndex: 17
    }
];


export const ASSESSMENT_BREADCRUMBS = [
    { text: 'RESOURCES', key: 'Resources' },
    { text: 'Assessment', key: 'Assessment' }
];

export const RESULT_DETAILS =
{
    null: { showText: "No Result", color: "#000" },
    0: { showText: "No Result", color: "#000" },
    1: { showText: "Accepted", color: "#29a133" },
    2: { showText: "Rejected", color: "#a4262c" }
};
