import { getId, createTheme } from "office-ui-fabric-react";

export const LoginCardStyles = {
  root: {
    backgroundColor: '#49A9D9',
    backgroundImage: 'linear-gradient(#000067, #49A9D9, #b8e2fc)',
    boxShadow: '1px 1px 5px'
  },
};

export const IFrameCardStyles = {
  root: {
    boxShadow: '1px 1px 5px'
  },
};

export const LoginCardItemTokens = { padding: 50 };

export const IFrameCardItemTokens = { padding: 10, margin: 5 };

export const LoggedInUserPersona = {
  imageInitials: '',
  text: '',
  secondaryText: '',
  tertiaryText: 'In a meeting',
  optionalText: 'Available at 4:00pm',
  showSecondaryText: true,
}

export const navStylesForm = {
  root: {
    boxSizing: 'border-box',
    border: 'none',
    overflowY: 'auto',
    height: 'auto'
  },
  link: {
    height: "2em"
  },
}

export const navStylesSideBar = {
  root: {
    boxSizing: 'border-box',
    border: 'none',
    overflowY: 'hidden',
  },
  link: {
    height: "2.5em",
    padding: "0px 20px 0px 28px"
  },
}

export const labelId = getId('callout-label');
export const descriptionId = getId('callout-description');

export const calloutProps = { gapSpace: 0 };
export const tooltipId = getId('tooltip');
export const hostStyles = {
  root:
  {
    display: 'inline-block',

  }
};

export const columnHostStyles = {
  root:
  {
    textAlign: "center",
    display: 'inline-block',
    cursor: 'pointer'
  }
};


export const DeletedRowStyle = (row, rowIndex) => {
  let classes = null;
  if (row.status === 1024) {
    classes = 'mandatory-span';
  }
  return classes;
};

export const DropdownStyles = {
  dropdown: {
    width: "100%",
    outline: "none"
  },
};

export const IconConstants = {
  fileIcon: { iconName: "OpenFile" },
  folderIcon: { iconName: "FabricFolder" },
  uploadIcon: { iconName: "Upload" },
  folderOpenIcon: { iconName: "FabricOpenFolderHorizontal" },
  previewIcon: { iconName: "Preview" },
  searchIcon: { iconName: "Search" },
  addIcon: { iconName: "Add" },
  moveIcon: { iconName: "Move" },
  showPasswordIcon: { iconName: "RedEye" },
  hidePasswordIcon: { iconName: "Hide" },
  editicon: { iconName: "Edit" },
  clearIcon: { iconName: "ClearFormatting" },
  nextIcon: { iconName: "Next" },
  previousIcon: { iconName: "ChevronLeft" },
  suspendIcon: { iconName: "CircleStopSolid" },
  confirmIcon: { iconName: "CheckMark" },
  passwordIcon: { iconName: "Unlock" },
  numberIcon: { iconName: "NumberSequence" },
  resetIcon: { iconName: "Redo" },
  expandicon: { iconName: "MiniExpand" },
  deleteicon: { iconName: "Delete" },
  archiveIcon: { iconName: "Archive" },
  exportIcon: { iconName: "Export" },
  moreicon: { iconName: "More" },
  saveicon: { iconName: "Save" },
  cancelIcon: { iconName: "Cancel" },
  refreshIcon: { iconName: "Refresh" },
  copyIcon: { iconName: "Copy" },
  pdfIcon: { iconName: "PDF" },
  addFolderIcon: { iconName: "FabricNewFolder" },
  addFileIcon: { iconName: "PageAdd" },
  imageIcon: { iconName: "FileImage" },
  excelIcon: { iconName: "ExcelLogo" },
  wordIcon: { iconName: "WordDocument" },
  generateIcon: { iconName: "Generate" },
  approveIcon: { iconName: "DocumentApproval" },
  revertIcon: { iconName: "RevToggleKey" },
  downloadIcon: { iconName: "Download" },
  reopenIcon: { iconName: "ReopenPages" },
  removeIcon: { iconName: "Remove" },
  amendIcon: { iconName: "ChangeEntitlements" },
  verticalIcon: { iconName: "MoreVertical" },
  timePickerIcon: { iconName: "TimePicker" },
  closeIcon: { iconName: "ClosePane" },
  holdIcon: { iconName: "Blocked" },
  seperatorIcon: { iconName: "Separator" },
  viewIcon: { iconName: "View" },
  completedStatusIcon: { iconName: "CompletedSolid" },
  configIcon: { iconName: "ConfigurationSolid" },
  onGoingStatusIcon: { iconName: "Running" },
  closeStatusIcon: { iconName: "StatusErrorFull" },
  inActiveStatusIcon: { iconName: "CircleStop" },
  activeStatusIcon: { iconName: "ActivateOrders" },
  cancelStatusIcon: { iconName: "Error" },
  helpIcon: { iconName: "Help" },
  hideIcon: { iconName: "Hide" },
  attachIcon: { iconName: "Attach" },
  chevronLeftEndIcon: { iconName: "ChevronLeftEnd6" },
  chevronLeftIcon: { iconName: "ChevronLeft" },
  chevronRightIcon: { iconName: "ChevronRight" },
  publicEmailIcon: { iconName: "PublicEmail" },
  signinIcon: { iconName: "Signin" },
  activateOrdersIcon: { iconName: "ActivateOrders" },
  entitlementRedemptionIcon: { iconName: "EntitlementRedemption" },
  globalNavButtonIcon: { iconName: "GlobalNavButton" },
  signOutIcon: { iconName: "SignOut" },
  settingsIcon: { iconName: "Settings" },
};

export const JSONViewColorConstants = {
  background: "#fff", color: "#000"
};

export const JSONViewStyleConstants = {
  outerBox: {
    width: "100%"
  },
  container: {
    width: "100%"
  },
  body: {
    fontSize: "16px",
    fontFamily: "Roboto"
  },
  errorMessage: {
    fontSize: "18px",
    color: "#ef7b7e"
  },
}

export const GRID_FILE_STYLES = {
  image: {
    display: "block",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    border: "1px solid #c6c6c6",
    padding: "2.5%"
  }

}

export const seperatorTheme = createTheme({
  fonts: {
    medium: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 700,
    }
  }
});

