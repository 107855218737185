import * as React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { TaskAPI } from '../Services/TaskAPI';
import { IconConstants } from '../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: {
        main: {
            width: 750,
        }
    },
};

export default class ConfirmDeleteLLGChildTasks extends React.PureComponent<any, any> {

    _taskApi = null;

    constructor(props) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);

    }

    render() {
        let {
            hideConfirmDeleteChildLLGTask,
            SelectedLLGTaskToBeDeleted,
        } = this.props.Task;

        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `Delete this record?`
        };
        return (
            <Dialog
                hidden={hideConfirmDeleteChildLLGTask}
                onDismiss={() => this.props.openCloseChildLLGTaskDeleteConfirmModal({
                    SelectedLLGTaskToBeDeleted: SelectedLLGTaskToBeDeleted,
                    hideConfirmDeleteChildLLGTask: true,
                    ToBeUpdatedStatus: -1
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => this.props.deleteLLGTask({
                            SelectedLLGTaskToBeDeleted: SelectedLLGTaskToBeDeleted,
                            hideConfirmDeleteChildLLGTask: true,
                            apiObject: this._taskApi
                        })}
                        text="OK"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => {
                            this.props.openCloseChildLLGTaskDeleteConfirmModal({
                                SelectedLLGTaskToBeDeleted: SelectedLLGTaskToBeDeleted,
                                hideConfirmDeleteChildLLGTask: true
                            })
                        }}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog >
        )
    }
}
