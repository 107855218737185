import * as React from 'react'
import { IClientInventoryStateProps } from '../ClientInventoryInteface/IClientInventoryStateProps'
import Papa from "papaparse";
import { Icon } from 'office-ui-fabric-react';
import { IconConstants } from '../../../Constants/StylesConstants';
import { IClientInventory, IClientInventoryAPI } from '../ClientInventoryInteface/IClientInventory';
import { ClientInventoryAPI } from '../Services/ClientInventoryAPI';

export default class ImportCSV extends React.PureComponent<IClientInventoryStateProps, {}> {

    private _ClientInventoryAPI: IClientInventoryAPI = null;

    constructor(props: IClientInventoryStateProps) {
        super(props);
        this._ClientInventoryAPI = new ClientInventoryAPI(window.API_URL, window.loggedInUserDetails);
    }

    public changeHandler = (event) => {
        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;

        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: (results: any) => {
                let tobeSavedClientInventories = results.data.map((eachResult: any) => {
                    let clientInventoryDTO: IClientInventory = { ...eachResult };
                    clientInventoryDTO.productCategory = eachResult.ProductCategory;
                    clientInventoryDTO.description = eachResult.Description;
                    clientInventoryDTO.quantity = eachResult.Quantity;
                    clientInventoryDTO.tonne = eachResult.Tonne;
                    clientInventoryDTO.manufacturer = eachResult.Manufacturer;
                    clientInventoryDTO.location = eachResult.Location;
                    clientInventoryDTO.comments = eachResult.Comments;
                    clientInventoryDTO.clientId = selectedClient.value;
                    clientInventoryDTO.clientId = selectedClient.value;
                    clientInventoryDTO.clientId = selectedClient.value;
                    clientInventoryDTO.siteId = selectedSite.value;
                    return clientInventoryDTO;
                })


                this.props.saveClientInventory({
                    apiObject: this._ClientInventoryAPI,
                    clientInventories: tobeSavedClientInventories
                })
            },
        });
    };
    render() {

        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;

        return (
            <>
                {selectedClient && selectedSite &&
                    <div className="custom-file-div">
                        <label htmlFor="csv-upload" className="custom-csv-upload">
                            <Icon iconName={IconConstants.excelIcon.iconName}></Icon>
                            Custom Upload
                            <Icon iconName={IconConstants.uploadIcon.iconName}></Icon>
                        </label>

                        <input
                            id="csv-upload"
                            type="file"
                            name="file"
                            accept=".csv"
                            onChange={(event) => this.changeHandler(event)}
                        />
                    </div>
                }
            </>

        )
    }
}
