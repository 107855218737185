import axios from 'axios';
import { ALERT_MESSAGE } from '../../../Constants/MessageConstants';


export class FormConfigAPIService {

    _apiBaseUrl = "";

    constructor(apiBaseUrl) {
        this._apiBaseUrl = apiBaseUrl
    }

    getAllFormConfigData() {
        return new Promise((resolve, reject) => {
            axios.get(this._apiBaseUrl.concat('Setting')
            .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?tenantId=2&objectType=Form`)).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error getAllFormConfigData", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    saveFormConfigData = (payload) => {
        return new Promise((resolve, reject) => {
            axios.post(this._apiBaseUrl.concat('Setting').concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), payload)
                .then((response) => {
                    resolve(true);
                }).catch((error) => {
                    console.error("Error saveFormConfigData", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

}