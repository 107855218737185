import * as React from 'react';
import { ISequenceService } from '../Interfaces/ISequenceService';
import { ISequenceState } from '../Interfaces/ISequenceState';
import { ISequenceStateProps } from '../Interfaces/ISequenceStateProps';
import { SequenceService } from '../Services/SequenceAPI';
import LoadingOverlay from 'react-loading-overlay';
import { FadeLoader } from 'react-spinners';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import * as CONSTANTS from './../Constants/SequenceNumberingConstants';
import { IKendoGridColumn } from '../../../CommonInterfaces/CommoInterfaces';
import { ActionButton, Label } from 'office-ui-fabric-react';
import { IconConstants } from '../../../Constants/StylesConstants';
import AlertRedux from '../../Alert/AlertRedux';
import { IdCustomCell } from './CustomSequenceColumnViews';
import { AddEditSequencePanel } from './AddEditSequencePanel';

export default class SequenceDashboard extends React.Component<ISequenceStateProps, any> {

    private _apiPayload: ISequenceService;

    public constructor(props: ISequenceStateProps) {
        super(props);
        this._apiPayload = new SequenceService(window.API_URL, window.loggedInUserDetails);
    }

    public componentDidMount(): void {
        this.loadInitData();
    }

    public loadInitData = (): void => {

        this.props.loadSequenceDashboard({
            isLoading: true,
            apiPayload: this._apiPayload,
        })
    }

    public idCustomCell = (props: ISequenceStateProps): any => (
        <IdCustomCell {...props} currentProps={this.props} />
    );

    public render(): JSX.Element {

        let {
            sequences,
            alertJSON,
            isLoadingSequenceDashboard,
            isShowSequenceAddEditPanel,
            sequenceGridDataState,
            proccessedSequences
        }: ISequenceState = this.props.SequenceState;

        return (
            <React.Fragment>

                <LoadingOverlay
                    active={isLoadingSequenceDashboard}
                    spinner={<FadeLoader />}
                    text="Loading Reports...">

                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 report-grid">
                                <Grid
                                    onExpandChange={(event: any) => {
                                        this.props.handleSequenceGridExpandChanged({
                                            event: event
                                        })
                                    }}
                                    data={proccessedSequences}
                                    expandField="expanded"
                                    resizable
                                    reorderable
                                    groupable
                                    sortable
                                    // filterable
                                    {...sequenceGridDataState}
                                    style={{ width: '100%' }}
                                    onDataStateChange={(event: any) => {
                                        this.props.handleSequenceGridGroupSortChanged({
                                            event: event
                                        })
                                    }}
                                    onFilterChange={(event) => {
                                        this.props.handleSequenceGridFilterChanged({
                                            event,
                                            apiPayload: this._apiPayload,
                                        })

                                    }}
                                    filterOperators={{
                                        text: [
                                            {
                                                text: 'grid.filterContainsOperator',
                                                operator: 'contains',
                                            }
                                        ]
                                    }}
                                >
                                    {CONSTANTS.SEQUENCE_NUMBERING_VIEW_GRID_COLUMNS
                                        .filter((column) => column.show)
                                        .map((column: IKendoGridColumn, index: number) => {
                                            return (
                                                <GridColumn
                                                    key={index}
                                                    field={column.field}
                                                    title={column.title}
                                                    // width={column.width}
                                                    // filter={column.filter === 'text' ? 'text' : 'date'}
                                                    cell={
                                                        column.title === 'Edit'
                                                            ? this.idCustomCell
                                                            : sequences[column.field]
                                                    }
                                                />
                                            );
                                        })}
                                    <GridToolbar>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                                                    <ActionButton
                                                        disabled={isLoadingSequenceDashboard}
                                                        text="Reload"
                                                        iconProps={IconConstants.refreshIcon}
                                                        onClick={() => this.loadInitData()}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <Label
                                                        styles={{
                                                            root: {
                                                                marginTop: "2.25%"
                                                            }
                                                        }}
                                                    >
                                                        {sequences?.length} Sequence(s) found
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>
                                    </GridToolbar>
                                </Grid>

                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
                {
                    isShowSequenceAddEditPanel &&
                    <AddEditSequencePanel
                        {...this.props}
                    />
                }
                {
                    !(alertJSON?.hideAlert) &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </React.Fragment >
        )
    }
}
