import * as CONSTANTS from '../Constants/ConfigConstants';
import { ConfigUtility } from '../Utility/ConfigUtility';
import { ConfigInitialState } from '../Constants/ConfigInitialState';
import { IConfigData } from '../Interfaces/ConfigInterface';
import { IError } from '../../../../CommonInterfaces/CommoInterfaces';

export function ConfigReducer(state = ConfigInitialState, action) {

    let tempState = { ...state };
    let payload = action.payload;
    let utility: ConfigUtility = new ConfigUtility();
    let CurrentConfig: IConfigData = { ...tempState.CurrentConfig };
    let NameError: IError = { ...tempState.NameError };

    switch (action.type) {

        case CONSTANTS.CLEAN_CONFIG_LIST:
            tempState.Configs = [];
            tempState.LoadingConfigPanel = true;
            tempState.CurrentConfig = {
                id: -99,
                key: -99,
                name: "",
                configJson: "{}",
                created_date: new Date()
            };
            break;

        case CONSTANTS.CLEAR_CONFIG_DATA:
            tempState.CurrentConfig = {
                id: -99,
                key: -99,
                name: "",
                configJson: "{}",
                created_date: new Date()
            };
            NameError.error = false;
            NameError.errorMessage = "";
            tempState.NameError = NameError;
            break;

        case CONSTANTS.ADD_CONFIG_DATA:
            tempState.CurrentConfig = {
                id: -1,
                key: -1,
                name: "",
                configJson: "{}",
                created_date: new Date()
            };
            NameError.error = true;
            NameError.errorMessage = "";
            tempState.NameError = NameError;
            tempState.IsValidConfigJSON = true;
            break;

        case CONSTANTS.LOAD_CONFIG_LIST:
            tempState.LoadingConfigPanel = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                let newConfigs: IConfigData[] = utility.loadConfigStructure(payload.Configs);
                tempState.Configs = tempState.Configs.concat(newConfigs);
            }
            break;

        case CONSTANTS.SELECT_CONFIG:
            CurrentConfig = payload.SelectedConfig;
            NameError.error = false;
            NameError.errorMessage = "";
            tempState.NameError = NameError;
            tempState.CurrentConfig = CurrentConfig;
            break;

        case CONSTANTS.CHANGE_CONFIG_NAME:
            CurrentConfig.name = payload.name;
            let existingConfig = tempState.Configs.find((conf) => conf.name === CurrentConfig.name && conf.id !== CurrentConfig.id);
            if (!payload.name) {
                NameError.error = true;
                NameError.errorMessage = "Configuration Name is Mandatory !!";
            }
            else if (existingConfig) {
                NameError.error = true;
                NameError.errorMessage = "Duplicate Configuration Name !!";
            }
            else {
                NameError.error = false;
                NameError.errorMessage = "";
            }
            tempState.NameError = NameError;
            tempState.CurrentConfig = CurrentConfig;
            break;

        case CONSTANTS.CHANGE_CONFIG_JSON:
            // CurrentConfig.configJson = payload.jsonData.jsObject;
            try {
                JSON.parse(payload.jsonData);
                tempState.IsValidConfigJSON = true;
            } catch (err) {
                tempState.IsValidConfigJSON = false;
            }
            CurrentConfig.configJson = payload.jsonData;
            tempState.CurrentConfig = CurrentConfig;
            break;

        case CONSTANTS.SAVE_CONFIG_DATA:
            tempState.LoadingConfigPanel = payload.isOngoingSave;
            tempState.CurrentConfig = {
                id: -99,
                key: -99,
                name: "",
                configJson: "{}",
                created_date: new Date()
            };
            break;

        case CONSTANTS.OPEN_CLOSE_CONFIGURATION_ALERT_MODAL:
            tempState.alertJSON = {
                hideAlert: payload.hideAlert,
                alertHeader: payload.alertHeader,
                alertSubText: payload.alertSubText
            }
            tempState.LoadingConfigPanel = false;
            break;

        default:
            break;
    }

    return tempState;
}