import {
    ISetting, IAssetTypeData, ISettingMetadata
} from './../Interfaces/AssetTypeInterface';
import { ISelect } from './../../../../CommonInterfaces/CommoInterfaces';
import moment from 'moment';

export class AssetTypeUtility {

    loadAssetTypeTableStructure = (AssetTypes: any[]): IAssetTypeData[] => {

        let TableFormattedAsssetType: IAssetTypeData[] = [];

        AssetTypes.forEach((element: any) => {
            let settingWithMetadata: ISetting = JSON.parse(element.settingWithMetadata);

            let settingMetatDatas: ISettingMetadata[] = settingWithMetadata.settingmetadata;

            let attribute_form_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "attribute_form_id");
            let reference_object: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "certificate_reference");

            TableFormattedAsssetType.push({
                id: settingWithMetadata.id,
                name: settingWithMetadata.name,
                acronym_code: settingWithMetadata.acronym_code,
                description: settingWithMetadata.description,
                category: settingMetatDatas.find((d: ISettingMetadata | undefined) => d.meta_key === "category")?.meta_value,
                attribute_form_id: attribute_form_object ? parseInt(attribute_form_object.meta_value) : 0,
                reference: reference_object ? reference_object.meta_value : "",
                created_date: new Date(settingWithMetadata.created_date),
                modified_date: new Date(settingWithMetadata.modified_date),
                status: settingWithMetadata.status,
                displayName: settingWithMetadata.display_name,
                external_id: settingWithMetadata.external_id
            });

        });

        return TableFormattedAsssetType;

    }

    loadFormAttributesOptions = (FormAttributes: any[]): ISelect[] => {
        let FormAttributeOptions: ISelect[] = [];

        FormAttributes.forEach((element: any) => {
            FormAttributeOptions.push({
                value: element.id,
                label: element.name
            });
        })
        return FormAttributeOptions;
    }

    resetCurrentAssetType = (): IAssetTypeData => {
        let CurrentAssetType: IAssetTypeData = {
            acronym_code: "",
            attribute_form_id: -1,
            description: "",
            id: -1,
            name: "",
            reference: "",
            category: null,
            attribute_form: null as ISelect,
            created_date: new Date(),
            modified_date: new Date(),
            status: 1,
            displayName: "",
            external_id: ""
        };

        return CurrentAssetType;
    }

    generateAssetTypeJSONPayLoad = (AssetTypeDetails: IAssetTypeData) => {

        let payloadJSON = {};
        if (AssetTypeDetails.id === -1) {
            payloadJSON = {
                "tenant_id": 2,
                "object_type": "AssetType",
                "name": AssetTypeDetails.name,
                "description": AssetTypeDetails.description,
                "type": 0,
                "status": 1,
                "parent_id": null,
                "user_data": null,
                "created_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "modified_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "created_by": 2,
                "last_modified_by": null,
                "external_id": null,
                "client_id": null,
                "display_name": AssetTypeDetails.displayName,
                "acronym_code": AssetTypeDetails.acronym_code,
                "archived": 0,
                "settingmetadata": [
                    {
                        "setting_id": AssetTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "category",
                        "meta_value": AssetTypeDetails.category,
                        "data_type": "STRING",
                        "object_type": "Category",
                        "display_name": "Category"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "attribute_form_id",
                        "meta_value": AssetTypeDetails.attribute_form ? AssetTypeDetails.attribute_form.value : null,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Form Attribute"
                    },

                ]
            }
        }
        else {
            payloadJSON = {
                "id": AssetTypeDetails.id,
                "tenant_id": 2,
                "object_type": "AssetType",
                "name": AssetTypeDetails.name,
                "description": AssetTypeDetails.description,
                "type": 0,
                "status": 1,
                "parent_id": null,
                "user_data": null,
                "created_date": moment(AssetTypeDetails.created_date).format("YYYY-MM-DD HH:mm:ss"),
                "modified_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "created_by": 2,
                "last_modified_by": null,
                "external_id": null,
                "client_id": null,
                "display_name": AssetTypeDetails.displayName,
                "acronym_code": AssetTypeDetails.acronym_code,
                "archived": 0,
                "settingmetadata": [
                    {
                        "setting_id": AssetTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "category",
                        "meta_value": AssetTypeDetails.category,
                        "data_type": "STRING",
                        "object_type": "Category",
                        "display_name": "Category"
                    },
                    {
                        "setting_id": AssetTypeDetails.id,
                        "tenant_id": 2,
                        "meta_key": "attribute_form_id",
                        "meta_value": AssetTypeDetails.attribute_form ? AssetTypeDetails.attribute_form.value : null,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Form Attribute"
                    },
                ]
            }
        }



        return payloadJSON;
    }
}
