import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb } from 'office-ui-fabric-react';
import { LO_COMPANY_BREADCRUMBS } from '../Constants/LoCompanyConstants';

// const mapStateToProps = (state: any): IReportStateProps => {
//     return {
//         ReportsState: state.ReportsReducer
//     }
// }

class LoCompanyComponent extends React.PureComponent<any, {}> {

    public render(): JSX.Element {
        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={LO_COMPANY_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


// const mapDispatchToProps = (dispatch: Dispatch) => {

//     let reportsActions: ReportsActions = new ReportsActions();

//     return bindActionCreators({ ...reportsActions }, dispatch);
// }

const LoCompany = connect(null, null)(LoCompanyComponent);
export default LoCompany;
