import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ConfigActions } from '../Actions/ConfigActions';
import ConfigPanel from './ConfigPanel';
import { Breadcrumb } from 'office-ui-fabric-react';

const mapStateToProps = (state) => {
    return {
        Config: state.ConfigReducer
    }
}

class ConfigComponent extends PureComponent {

    render() {

        let { ConfigBreadCrumb } = this.props.Config;

        return (
            <React.Fragment>
                <Breadcrumb
                    className="custom-breadcrumb"
                    items={ConfigBreadCrumb}
                />
                <ConfigPanel
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let configActions = new ConfigActions();

    return bindActionCreators({ ...configActions }, dispatch);
}

const TaskTypes = connect(mapStateToProps, mapDispatchToProps)(ConfigComponent);
export default TaskTypes;
