import * as CONSTANTS from './../Constants/TrialFitPicLibraryConstants';
// import { TrialFitPicLibraryInitialState } from "../Constants/TrialFitPicLibraryInitialState";
import { ILibraryPayload } from '../Interfaces/ILibraryPayload';

export class TrialFitPicLibraryActions {

    public cleanDocumentList = (payload) => {
        return { type: CONSTANTS.CLEAN_DOCUMENT_LIST, payload }
    }

    public loadDocumentsAsync = (payload) => {
        return { type: CONSTANTS.LOAD_DOCUMENT_LIST, payload }
    }


    public loadDocuments = (payload: ILibraryPayload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.loadDocumentsAsync(payload));
            dispatch(this.cleanDocumentList(payload));
            payload.apiObject.loadInitData(payload.clientId, payload.siteId, payload.libraryName).then((response) => {
                payload.parentLibrary = response[0].data;
                payload.llgAssetTypes = response[1].data;
                payload.apiObject.getEntityByParentId(payload.parentLibrary.id).then((response) => {
                    payload.isOngoingLoading = false;
                    payload.documentEntities = response.data;
                    dispatch(this.loadDocumentsAsync(payload));
                }).catch((error) => {
                    console.log("Error in getEntityByParentId : ", error);
                    payload.alertJSON = {
                        hideAlert: false,
                        alertHeader: "Ooops!!",
                        alertSubText: "Error while loading Library"
                    }
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.alertJSON = {
                            hideAlert: true,
                            alertHeader: "",
                            alertSubText: ""
                        }
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                });
            }).catch((error) => {
                console.log("Error in getEntityByName : ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Folders"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public openCloseAlertModal = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.OPEN_CLOSE_LIBRARY_ALERT_MODAL, payload };
    }

    public showHideFileUploadErrorMessage = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.SHOW_HIDE_FILE_UPLOAD_ERROR_MESSAGE, payload };
    }

    public openCloseEntityDeleteConfirmModal = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.OPEN_CLOSE_ENTITY_DELETE_CONFIRM_MODAL, payload };
    }

    public openCloseAddEditDocumentPanel = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.OPEN_CLOSE_ADD_EDIT_DOCUMENT_PANEL, payload };
    }

    public changeDocumentName = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.CHANGE_DOCUMENT_NAME, payload };
    }

    public saveFolderAsync = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.SAVE_FOLDER, payload };
    }

    public saveFolder = (payload: ILibraryPayload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.saveFolderAsync(payload));
            payload.apiObject.saveEntity(payload.selectedDocument).then((response) => {
                payload.selectedDocument = response.data;
                console.log("Saved Entity : ", response.data);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: "Data Saved Successfully"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    payload.clientId = payload.selectedDocument.clientId;
                    payload.siteId = payload.selectedDocument.siteId;
                    dispatch(this.loadDocuments(payload));
                }, 2000);


            }).catch((error) => {
                console.log("Error in saveEntiy : ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while saving Folders"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public changeSearchFolderString = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.CHANGE_SEARCH_FOLDER_STRING, payload }
    }

    public changeSearchFileString = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.CHANGE_SEARCH_FILE_STRING, payload }
    }

    public loadFilesAsync = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.SHOW_HIDE_FILE_SECTION, payload }
    }

    public cleanFileList = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.CLEAN_FILE_LIST, payload }
    }

    public loadFiles = (payload: ILibraryPayload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.cleanFileList(payload));
            dispatch(this.loadFilesAsync(payload));
            payload.apiObject.getEntityByParentId(payload.parentLibrary.id).then((response) => {
                payload.isOngoingLoading = false;
                payload.documentEntities = response.data;
                dispatch(this.loadFilesAsync(payload));
            }).catch((error) => {
                console.log("Error in getEntityByParentId : ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Files"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            });
        }
    }

    public saveFileAsync = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.SAVE_FILE, payload };
    }

    public saveFile = (payload: ILibraryPayload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.saveFileAsync(payload));
            payload.apiObject.saveFile(payload.formData).then((response) => {
                payload.selectedDocument = response.data;
                console.log("Saved Entity : ", response.data);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: "File Saved Successfully"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    payload.clientId = JSON.parse(payload.formData.get("documentDTO").toString()).clientId;
                    payload.siteId = JSON.parse(payload.formData.get("documentDTO").toString()).siteId;
                    dispatch(this.loadDocuments(payload));
                }, 2000);


            }).catch((error) => {
                console.log("Error in saveEntiy : ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while saving file"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public deleteFileAsync = (payload: ILibraryPayload) => {
        return { type: CONSTANTS.DELETE_ENTITY, payload };
    }

    public deleteFile = (payload: ILibraryPayload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.deleteFileAsync(payload));
            payload.apiObject.deleteEntity(payload.documentId).then((response) => {
                console.log("Deleted Entity : ", response);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: "Entity Deleted Successfully"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    dispatch(this.loadDocuments(payload));
                }, 2000);


            }).catch((error) => {
                console.log("Error in delete entity : ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while delete entity"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }


}