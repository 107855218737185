import React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { RoleAPI } from '../Services/RoleAPI';
import { IconConstants } from '../../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class ConfirmRoleRedux extends React.PureComponent {

    _roleApi = null;

    constructor(props) {
        super(props);
        this._window = window;
        this._roleApi = new RoleAPI(this._window.API_URL, window.loggedInUserDetails);

    }

    render() {
        let { hideConfirm, CurrentRole } = this.props.Role;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `Are you sure want to delete the Role?`
        };
        return (
            <Dialog
                hidden={hideConfirm}
                onDismiss={() => this.props.openCloseRoleConfirmModal({
                    hideConfirm: true,
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            this.props.deleteRoleAsync({
                                CurrentRole: CurrentRole,
                                apiObject: this._roleApi,
                            })

                        }}
                        text="Yes"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseRoleConfirmModal({
                            hideConfirm: true,
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
