import { TaskInitialState } from './../Constants/TaskInitialState';
import * as CONSTANTS from './../Constants/TaskConstants';
import { TaskUtility } from '../Utility/TaskUtility';
import { TaskTypeUtility } from '../../Settings/TaskTypes/Utility/TaskTypeUtility';

// import { JobUtility } from '../Utility/JobUtility';

export function TaskReducer(state = TaskInitialState, action) {

    let tempState = { ...state };
    let payload = action.payload;
    let attributeOptions = null;
    let attributeFiles = null;
    let userData = null;
    let formData = [];
    let tempCurrentSelectedTask = { ...tempState.SelectedTask };
    let utility = new TaskUtility();
    let taskTypeUtility = new TaskTypeUtility();

    switch (action.type) {

        case CONSTANTS.RESET_TASK_BREADCRUMB:
            tempState.TaskBreadCrumb = [
                { text: 'OPERATIONS', key: 'Operations' },
                { text: 'Tasks', key: 'TASK' }
            ]
            tempState.isShowTaskCeritificateBrowser = false;
            break;

        case CONSTANTS.CHANGE_TASK_TAB:
            tempState.TabIndex = payload.TabIndex;
            break;

        case CONSTANTS.CHANGE_LLG_TASK_TAB:
            tempState.LLGTabIndex = payload.LLGTabIndex;
            break;

        case CONSTANTS.CLEAN_TASK_DASHBOARD:
            if (!(payload.filtered)) {
                tempState.Tasks = [];
            }
            tempState.offset = 0;
            tempState.LoadingTaskDashboard = true;
            tempState.ShowActiveTasks = true;
            tempState.task_client_filter_value = "";
            tempState.task_job_number_filter_value = "";
            tempState.task_site_filter_value = "";
            tempState.task_status_filter_value = "";
            tempState.task_asset_filter_value = "";
            tempState.task_asset_type_filter_value = "";
            tempState.task_quotation_number_filter_value = "";
            tempState.sortedColumn = "modifiedDate";
            tempState.sortOrder = "desc";
            tempState.LLGTabIndex = 0;
            tempState.SelectedChildLLGTask = null;
            tempState.isShowLLGTasksOnly = false;
            tempState.SelectedTaskNewInspector = null;
            tempState.SelectedCheckListItem = null;
            tempState.SelectedCheckListSection = null;
            tempState.CurrentChecklistFormData = null;
            tempState.CurrentFormOtherData = null;
            tempState.CurrentSectionForms = [];
            tempState.CurrentSelectedForm = null;
            tempState.isShowTaskCeritificateBrowser = false;
            tempState.isOpenCreateChildtaskPanel = false;
            tempState.isLoadingCreateChildTaskPanel = false;
            tempState.TaskTypesOptions = [];
            tempState.selectedTaskType = null;
            tempState.ChildLLGTaskSearchFilterText = "";
            tempState.hideConfirmDeleteChildLLGTask = true;
            tempState.SelectedLLGTaskToBeDeleted = null;
            localStorage.removeItem("config");
            break;

        case CONSTANTS.CLEAN_TASK_DASHBOARD_WITH_FILTER:
            if (!(payload.filtered)) {
                tempState.Tasks = [];
            }
            tempState.offset = 0;
            tempState.LoadingTaskDashboard = true;
            tempState.ShowActiveTasks = true;
            tempState.sortedColumn = "modifiedDate";
            tempState.sortOrder = "desc";
            tempState.SelectedChildLLGTask = null;
            tempState.isShowLLGTasksOnly = false;
            tempState.SelectedTaskNewInspector = null;
            tempState.SelectedCheckListItem = null;
            tempState.SelectedCheckListSection = null;
            tempState.CurrentChecklistFormData = null;
            tempState.CurrentFormOtherData = null;
            tempState.CurrentSectionForms = [];
            tempState.CurrentSelectedForm = null;
            tempState.isShowTaskCeritificateBrowser = false;
            tempState.uploadFilesErrorText = "";
            tempState.LLGTabIndex = 0;
            tempState.SelectedChildLLGTask = null;
            tempState.isOpenCreateChildtaskPanel = false;
            tempState.isLoadingCreateChildTaskPanel = false;
            tempState.TaskTypesOptions = [];
            tempState.selectedTaskType = null;
            tempState.ChildLLGTaskSearchFilterText = "";
            tempState.hideConfirmDeleteChildLLGTask = true;
            tempState.SelectedLLGTaskToBeDeleted = null;
            break;

        case CONSTANTS.FIX_TASK_DASHBOARD:
            tempState.LoadingTaskDashboard = true;
            break;

        case CONSTANTS.LOAD_TASK_DASHBOARD:
            tempState.LoadingTaskDashboard = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                tempState.Tasks = !payload.filtered ? tempState.Tasks.concat(payload.Tasks) : payload.Tasks;
                tempState.TotalTasksCount = payload.TotalTasksCount;
                tempState.LoadingTaskDashboard = false;
                tempState.HasMoreTasks = tempState.TotalTasksCount > tempState.Tasks.length;
                tempState.filters = payload.filters;
                tempState.offset = payload.offset + payload.limit;
            }
            if (payload.filterType === "status") {
                tempState.ShowActiveTasks = (!payload.value || payload.value === 1 || payload.value === 2 || payload.value === 4 || payload.value === 8 || payload.value === 32);
            }
            break;

        case CONSTANTS.SORT_TASK_DASHBOARD:
            tempState.Tasks = payload.sortedTasks;
            tempState.sortedColumn = payload.sortedColumn;
            tempState.sortOrder = payload.sortOrder;
            break;

        case CONSTANTS.OPEN_CLOSE_TASK_ALERT_MODAL:
            tempState.alertJSON = {
                hideAlert: payload.hideAlert,
                alertHeader: payload.alertHeader,
                alertSubText: payload.alertSubText
            }
            tempState.LoadingTaskDashboard = false;
            tempState.LoadingTaskPreviewTab = false;
            tempState.LoadingInspectTab = false;
            tempState.LoadingTaskPreviewTabPDF = false;
            break;

        case CONSTANTS.SHOW_TASK_DETAILS_TAB:
            tempState.SelectedTask = payload.SelectedTask;
            tempState.TabIndex = 0;
            tempState.ShowTaskDetailsTab = payload.ShowTaskDetailsTab;
            tempState.SelectedChildLLGTask = null;
            tempState.SelectedCheckListSection = null;
            tempState.SelectedCheckListItem = null;
            tempState.CurrentChecklistFormData = null;
            tempState.isShowTaskCeritificateBrowser = false;
            tempState.PDFURL = "";
            tempState.uploadFilesErrorText = "";
            tempState.ChildLLGTaskSearchFilterText = "";

            if (payload.SelectedTask) {
                tempState.TaskBreadCrumb = tempState.TaskBreadCrumb
                    .concat({ text: `TASK DETAILS (${tempState.SelectedTask?.id})`, key: `TASK DETAILS (${tempState.SelectedTask?.id})` })
            }
            else {
                if (tempState.TaskBreadCrumb.length > 2) {
                    tempState.TaskBreadCrumb.pop();
                    tempState.PDFURL = "";
                }
            }
            break;

        case CONSTANTS.TOGGLE_TASK_VIEW:
            tempState.ShowActiveTasks = payload.ShowActiveTasks;
            break;

        case CONSTANTS.SHOW_HIDE_DASHBOARD_COLUMNS:
            let tempColumns = [...tempState.showColumns];
            let indexOfSelectedColumn = tempColumns.indexOf(payload.selectedColumn.key);
            if (indexOfSelectedColumn > -1) {
                tempColumns.splice(indexOfSelectedColumn, 1);
            }
            else {
                tempColumns.push(payload.selectedColumn.key);
            }
            tempState.showColumns = tempColumns;
            break;

        case CONSTANTS.SWITCH_LLG_TASK_VIEW:
            tempState.isShowLLGTasksOnly = payload.isShowLLGTasksOnly;
            tempState.ShowActiveTasks = false;
            break;

        //DASHBOARD FILTER start
        case CONSTANTS.CHANGE_TASK_STATUS_FILTER_VALUE:
            tempState.task_status_filter_value = payload.value;
            tempState.isShowLLGTasksOnly = false;
            tempState.LoadingTaskDashboard = true;
            break;

        case CONSTANTS.CHANGE_TASK_SITE_FILTER_VALUE:
            tempState.LoadingTaskDashboard = true;
            tempState.task_site_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_TASK_CLIENT_FILTER_VALUE:
            tempState.LoadingTaskDashboard = true;
            tempState.task_client_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_TASK_JOB_NUMBER_FILTER_VALUE:
            tempState.LoadingTaskDashboard = true;
            tempState.task_job_number_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_TASK_ASSET_TYPE_FILTER_VALUE:
            tempState.LoadingTaskDashboard = true;
            tempState.task_asset_type_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_TASK_ASSET_FILTER_VALUE:
            tempState.LoadingTaskDashboard = true;
            tempState.task_asset_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_TASK_CERTIFICATE_NUMBER_FILTER_VALUE:
            tempState.LoadingTaskDashboard = true;
            tempState.task_certificate_number_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_TASK_QUOTATION_NUMBER_FILTER_VALUE:
            tempState.LoadingTaskDashboard = true;
            tempState.task_quotation_number_filter_value = payload.value;
            break;
        //DASHBOARD FILTER end

        // Preview Start
        case CONSTANTS.CLEAN_TASK_PDF:
            tempState.PDFURL = payload.PDFURL;
            break;

        case CONSTANTS.SHOW_TASK_PDF:
            tempState.PDFURL = payload.PDFURL;
            tempState.isShowTaskCeritificateBrowser = payload.isShowTaskCeritificateBrowser;
            break;

        case CONSTANTS.LOAD_UNLOAD_TASK_PREVIEW:
            tempState.LLGTaskSearchFilterText = "";
            tempState.LoadingTaskPreviewTabPDF = payload.isOngoingLoading;
            break;

        case CONSTANTS.REOPEN_OR_GENERATE_TASK:
            tempState.LoadingTaskPreviewTab = payload.isOngoingUpdate;
            tempState.hideConfirm = true;
            tempState.SelectedTask = payload.SelectedTask;
            break;

        case CONSTANTS.OPEN_CLOSE_TASK_CONFIRM_MODAL:
            tempState.isLLGDelete = payload.isLLGDelete;
            tempState.ToBeUpdatedStatus = payload.ToBeUpdatedStatus;
            tempState.hideConfirm = payload.hideConfirm;
            tempState.SelectedTask = payload.SelectedTask;
            tempState.isInspectTab = payload.isInspectTab;
            tempState.SelectedTaskNewInspector = payload.SelectedTaskNewInspector;
            break;

        case CONSTANTS.CHANGE_REVISION_COMMENTS:
            var tempSelectedTask = { ...tempState.SelectedTask };
            tempSelectedTask.revisionComments = payload.revisionComments;
            tempState.SelectedTask = tempSelectedTask;
            break;

        case CONSTANTS.SHOW_TASK_PREVIEW_CERTIFICATE_BROWSER:
            tempState.SelectedChildLLGTask = null;
            // tempState.SelectedChildLLGTaskId = -1;
            // tempState.SelectedChildLLGTaskStatus = -1;
            tempState.LLGTaskSearchFilterText = "";
            tempState.isShowTaskCeritificateBrowser = payload.isShowTaskCeritificateBrowser;
            break;

        case CONSTANTS.LOAD_CHILD_LLG_TASKS:
            tempState.isLoadingChildLLGTasks = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                tempState.ChildLLGTasks = utility.createChildLLGTasksMenu(payload.ChildLLGTasks);
            };
            break;

        case CONSTANTS.LOAD_CHILD_LLG_TASK_LIST:
            tempState.isLoadingChildLLGTasks = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                tempState.ChildLLGTasks = utility.createChildLLGTasksList(payload.ChildLLGTasks);
            };
            break;

        case CONSTANTS.SELECT_CHILD_LLG_TASK:
            tempState.SelectedChildLLGTask = payload.SelectedChildLLGTask;
            // tempState.SelectedChildLLGTaskId = payload.SelectedChildLLGTaskId;
            // tempState.SelectedChildLLGTaskStatus = payload.SelectedChildLLGTaskStatus;
            break;

        case CONSTANTS.CHANGE_CHILD_LLG_TASK_SEARCH_TEXT:
            tempState.LLGTaskSearchFilterText = payload.searchText;
            break;

        case CONSTANTS.CHANGE_AND_REASSIGN_TASK_INSPECTOR:
            tempState.SelectedTaskNewInspector = payload.selectedTaskInspector;
            break;

        case CONSTANTS.LOAD_OTHER_TASK_INSPECTORS:
            tempState.LoadingTaskPreviewTabPDF = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                tempState.Inspectors = utility.setUserOptions(payload.Inspectors);
                tempState.SelectedTaskNewInspector = {
                    label: payload.SelectedTask.observationInspector,
                    value: payload.SelectedTask.inspectorId
                }
            };
            break;

        // Preview End


        // Job Details Start

        case CONSTANTS.UPLOAD_JOB_FILES_FROM_TASK:
            tempState.isLoadingJobFiles = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {
                let tempScheduleFiles = payload.files;
                tempCurrentSelectedTask.otherFiles = JSON.stringify(tempScheduleFiles);
            }
            tempState.uploadFilesErrorText = payload.errorText;
            tempState.SelectedTask = tempCurrentSelectedTask;
            break;

        case CONSTANTS.ON_CHANGE_SELECTED_TASK_JOB_DETAILS_TAB:
            tempCurrentSelectedTask[payload.field] = payload.value;
            tempState.SelectedTask = tempCurrentSelectedTask;
            break;

        case CONSTANTS.ON_SAVE_SELECTED_TASK_JOB_DETAILS_TAB:
            tempState.isLoadingJobDetailsTab = payload.isOngoingLoading;
            break;

        case CONSTANTS.SHOW_HIDE_TIME_CLOCK:
            tempState.showTimeClock = payload.showTimeClock;
            break;

        // Job Details End

        // Inspect Tab Start
        case CONSTANTS.FIND_OBSERVATION_BY_ID:
            tempState.LLGTabIndex = 0;
            tempState.SelectedChildLLGTask = null;
            tempState.LoadingInspectTab = payload.isOngoingLoading;
            tempState.CurrentTaskChecklistData = null;
            tempState.SelectedCheckListSection = null;
            tempState.SelectedCheckListItem = null;
            tempState.CurrentFormOtherData = null;
            tempState.CurrentSectionForms = [];
            tempState.CurrentSelectedForm = null;
            tempState.FormFiles = [];
            let formRenderOptsInit = {
                formData: [],
                dataType: 'json'
            };
            payload.formRenderDiv.formRender(formRenderOptsInit);
            if (!payload.isOngoingLoading) {
                tempState.CurrentTaskChecklistData = payload.CurrentTaskChecklistData;
            }
            break;

        case CONSTANTS.FIND_LLG_OBSERVATION_BY_ID:
            tempState.LoadingInspectTab = payload.isOngoingLoading;
            tempState.SelectedChildLLGTask = payload.SelectedLLGTask;
            tempState.CurrentTaskChecklistData = null;
            tempState.SelectedCheckListSection = null;
            tempState.SelectedCheckListItem = null;
            tempState.CurrentFormOtherData = null;
            tempState.CurrentSectionForms = [];
            tempState.CurrentSelectedForm = null;
            tempState.FormFiles = [];
            let llgFormRenderOptsInit = {
                formData: [],
                dataType: 'json'
            };
            payload.formRenderDiv.formRender(llgFormRenderOptsInit);
            if (!payload.isOngoingLoading) {
                tempState.CurrentTaskChecklistData = payload.CurrentTaskChecklistData;
            }
            break;

        case CONSTANTS.FIND_OBSERVATIONS_BY_PARENT_ID:
            tempState.LoadingInspectTab = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                tempState.childLLGObservations = payload.childLLGObservations;
            }
            break;

        // For Multi Form Start
        case CONSTANTS.POPULATE_FORM_DETAILS:
            tempState.CurrentSelectedForm = payload.selectedForm;
            formData = [...tempState.CurrentChecklistFormData];
            tempState.FormFiles = [];

            formData.forEach((element) => {
                if (element.type !== "file") {
                    utility.populateFormValue(element, payload.selectedForm.attributes ? payload.selectedForm.attributes[element.name] : null)
                }
            });
            if (payload.selectedForm.attributesFiles) {
                tempState.FormFiles.push(payload.selectedForm.attributesFiles);
            }
            tempState.CurrentChecklistFormData = formData;
            let formRenderOptsOnSelect = {
                formData: formData,
                dataType: 'json'
            };
            payload.formRenderDiv.formRender(formRenderOptsOnSelect);
            break;
        // For Multi Form Start

        case CONSTANTS.FIND_FORM_DETAILS_BY_ID:
            tempState.LoadingFormSection = payload.isOngoingLoading;
            tempState.SelectedCheckListSection = payload.SelectedCheckListSection;
            tempState.SelectedCheckListItem = payload.SelectedCheckListItem;
            tempState.CurrentFormOtherData = null;
            tempState.CurrentSectionForms = [];
            tempState.CurrentSelectedForm = null;
            tempState.FormFiles = [];

            if (!payload.isOngoingLoading) {

                formData = [...utility.removeIsMultipleFromFormData(JSON.parse(payload.CurrentChecklistFormData.formJson))];

                switch (payload.CurrentChecklistFormData.formType) {

                    case "MULTIPLE":

                        if (payload.SelectedCheckListItem && payload.SelectedCheckListSection) {
                            userData = payload.SelectedCheckListItem?.userData;
                            if (userData) {
                                tempState.CurrentSectionForms =
                                    utility.populateFormList(payload.SelectedCheckListItem?.userData?.records[0]?.qsUserDataFormData?.attribute_form_id);
                            }
                            else {
                                formData.forEach((element) => {
                                    if (element.type !== "file") {
                                        utility.populateFormValue(element, null)
                                    }
                                    else {
                                        // if (payload.selectedForm.attributesFiles && payload.selectedForm.attributesFiles[element.name]) {
                                        //     tempState.FormFiles = [...tempState.FormFiles, ...payload.selectedForm.attributesFiles[element.name]];
                                        // }
                                    }
                                });
                            }
                        }
                        else if (payload.SelectedCheckListSection) {
                            userData = payload.SelectedCheckListSection?.userData;
                            if (userData) {
                                tempState.CurrentSectionForms =
                                    utility.populateFormList(payload.SelectedCheckListSection?.userData?.records[0]?.qsUserDataFormData?.attribute_form_id);
                            }
                            else {
                                formData.forEach((element) => {
                                    if (element.type !== "file") {
                                        utility.populateFormValue(element, null)
                                    }
                                });
                            }
                        }
                        break;

                    default:
                        if (payload.SelectedCheckListItem && payload.SelectedCheckListSection) {
                            attributeOptions =
                                payload.SelectedCheckListItem?.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[0].attributes;
                            attributeFiles =
                                payload.SelectedCheckListItem?.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[0].attributesFiles;
                            tempState.CurrentSelectedForm = payload.SelectedCheckListItem?.userData?.records[0]?.qsUserDataFormData?.attribute_form_id
                                .find((form) => parseInt(form.formId) === payload.CurrentChecklistFormData.formId);
                        }
                        else if (payload.SelectedCheckListSection) {
                            attributeOptions =
                                payload.SelectedCheckListSection?.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[0].attributes;
                            attributeFiles =
                                payload.SelectedCheckListSection?.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[0].attributesFiles;

                            tempState.CurrentSelectedForm = payload.SelectedCheckListSection?.userData?.records[0]?.qsUserDataFormData?.attribute_form_id
                                .find((form) => parseInt(form.formId) === payload.CurrentChecklistFormData.formId)
                        }
                        if (tempState.CurrentSelectedForm) {
                            tempState.CurrentSelectedForm.value = tempState.CurrentSelectedForm.formRecordId;
                            tempState.CurrentSelectedForm.label = tempState.CurrentSelectedForm.formName;
                        }

                        formData.forEach((element) => {
                            if (element.type !== "file") {
                                utility.populateFormValue(element, attributeOptions ? attributeOptions[element.name] : null)
                            }

                        });
                        if (attributeFiles) {
                            tempState.FormFiles.push(attributeFiles);

                        }
                        break;
                }

                tempState.CurrentChecklistFormData = formData;
                tempState.CurrentFormOtherData = { ...payload.CurrentChecklistFormData };
                let formRenderOpts = {
                    formData: formData,
                    dataType: 'json'
                };
                payload.formRenderDiv.formRender(formRenderOpts);
            }
            else {
                let formRenderOpts = {
                    formData: [],
                    dataType: 'json'
                };
                payload.formRenderDiv.formRender(formRenderOpts);

            }
            break;

        case CONSTANTS.SAVE_CHECKLIST_DETAILS:
            // tempState.LoadingFormSection = payload.isOngoingLoading;
            tempState.LoadingInspectTab = payload.isOngoingLoading;

            if (!payload.isOngoingLoading) {
            }
            break;

        case CONSTANTS.LOAD_ITEM_LIST:
            tempState.SelectedCheckListSection = payload.SelectedCheckListSection;
            tempState.ShowItemList = payload.ShowItemList;
            tempState.CurrentFormOtherData = null;
            tempState.CurrentSectionForms = [];
            tempState.CurrentSelectedForm = null;
            tempState.FormFiles = [];
            let formRenderOpts = {
                formData: [],
                dataType: 'json'
            };
            tempState.CurrentChecklistFormData = payload.CurrentChecklistFormData;
            payload.formRenderDiv.formRender(formRenderOpts);

            break;

        case CONSTANTS.SUBMIT_OR_HOLD_TASK:
            tempState.LoadingInspectTab = payload.isOngoingUpdate;
            tempState.hideConfirm = true;
            tempState.SelectedTask = payload.SelectedTask;
            break;
        case CONSTANTS.OPEN_CLOSE_CREATE_CHILD_TASK_PANEL:
            tempState.isOpenCreateChildtaskPanel = payload.isOpenCreateChildtaskPanel;
            tempState.selectedTaskType = null;
            break;

        case CONSTANTS.LOAD_TASKTYPES:
            tempState.isLoadingCreateChildTaskPanel = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                tempState.TaskTypesOptions = taskTypeUtility.loadFormAttributesOptions(payload.TaskTypes);
            }
            break;

        case CONSTANTS.SELECT_TASKTYPE:
            tempState.selectedTaskType = payload.selectedTaskType;
            break;

        case CONSTANTS.SEARCH_BY_CHILD_LLG_TASK_DESCRIPTION:
            tempState.ChildLLGTaskSearchFilterText = payload.ChildLLGTaskSearchFilterText;
            break;

        case CONSTANTS.OPEN_CLOSE_CHILD_LLG_TASK_DELETE_CONFIRM_MODAL:
            tempState.hideConfirmDeleteChildLLGTask = payload.hideConfirmDeleteChildLLGTask;
            tempState.SelectedLLGTaskToBeDeleted = payload.SelectedLLGTaskToBeDeleted;
            break;

        case CONSTANTS.DELETE_CHILD_LLG_TASK:
            tempState.isLoadingChildLLGTasks = payload.isOngoingLoad;
            if(!tempState.isLoadingChildLLGTasks) {
                tempState.hideConfirmDeleteChildLLGTask = true;
            }
            break;

        // Inspect Tab End

        // Attach Asset Tab Start

        case CONSTANTS.LOAD_ASSETS_FOR_BLANK_LOCATION_TASK:
            tempState.isLoadingAttachAssetTab = payload.isOngoingLoading;
            tempState.newAttachAssetDetails = TaskInitialState.newAttachAssetDetails;
            if (!payload.isOngoingLoading) {
                tempState.AssetList = payload.AssetList;
            }
            break;

        case CONSTANTS.ATTACH_ASSET_TO_BLANK_LOCATION_TASK:
            tempState.isLoadingAttachAssetTab = payload.isOngoingLoading;
            break;

        case CONSTANTS.CHANGE_ASSET_NAME_IN_ATTACH_ASSET:
            let newAssetName = payload.NewAssetName;
            let tempNewAttachAssetDetails = { ...tempState.newAttachAssetDetails };
            let asetNameRegex = localStorage.getItem("assetNameRegex").split("");
            tempNewAttachAssetDetails = {
                value: newAssetName,
                error: !newAssetName.trim() || asetNameRegex.map((item) => newAssetName.trim().includes(item)).filter(item => item === true).length > 0,
                errorMessage: !newAssetName.trim() ?
                    "Asset Name is Mandatory" :
                    asetNameRegex.map((item) => newAssetName.trim().includes(item)).filter(item => item === true).length > 0 ? "Invalid character(s) in Asset Name" : ""
            };
            tempState.newAttachAssetDetails = tempNewAttachAssetDetails;
            break;

        case CONSTANTS.CHANGE_ATTACH_ASSET_SEARCH_FIELD_VALUE:
            switch (payload.fieldName) {
                case "name":
                    tempState.searchedAssetName = payload.value;
                    break;
                case "assetType":
                    tempState.searchedAssetType = payload.value;
                    break;
                case "site":
                    tempState.searchedAssetSiteName = payload.value;
                    break;
                case "client":
                    tempState.searchedAssetClientName = payload.value;
                    break;

                default:
                    break;
            }
            break;

        case CONSTANTS.OPEN_CLOSE_ATTACH_ASSSET_CONFIRM_MODAL:
            tempState.isShiftAndAttach = payload.isShiftAndAttach;
            tempState.selectedAsset = payload.selectedAsset;
            tempState.hideAttachAssetConfirm = payload.hideAttachAssetConfirm;
            break;


        // Attach Asset Tab End

        default:
            break;
    }

    return tempState;
}