import axios from 'axios';
import { ILoggedInUser } from '../../../CommonInterfaces/CommoInterfaces';
import * as CONSTANTS from "../Constants/ClientConstants";
import { ALERT_MESSAGE } from '../../../Constants/MessageConstants';
import { IClientService, IAddNewClientPayLoad, ILocation } from './../ClientInterface/IClient';

export class ClientAPI implements IClientService {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }

    public uploadClientLogo(companyId: number, imageData: FormData): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${CONSTANTS.UPLOAD_CLIENT_IMAGE_URL}${companyId}`)
                .concat(origin === "http://localhost:3000" ?
                    `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``),
                imageData)
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error uploadClientLogo method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        })
    }

    public loadClientLogo(companyId: number): Promise<any> {

        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(`${CONSTANTS.GET_CLIENT_IMAGE_URL}${companyId}`)
                .concat(origin === "http://localhost:3000" ?
                    `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``))
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error loadClientLogo method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        })
    }

    public loadLoOffices(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.LOAD_LOCATION_BY_TYPE_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=LO_OFFICE`)
            ).then(response => {
                resolve(response.data);
            }).catch(error => {
                console.error("Error in loadLoOffices method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }



    _getChunkClient(offset: number, limit: number, objectType: string, config: any, companyId?: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.LOAD_LOCATION_DATA_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=${objectType}&`)
                .concat(`offset=${offset}&`)
                .concat(`limit=${limit}`)
                .concat(companyId ? `&companyId=${companyId}` : ``)
                , config
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getChunkClient method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    _getClientCount(objectType: string, config: any, companyId?: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.LOAD_LOCATION_COUNT_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=${objectType}&`)
                .concat(companyId ? `companyId=${companyId}` : ``)
                , config
            ).then(response => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getClientCount method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    loadInitialData(offset: number, limit: number, objectType: string, config: number, parentId?: number): Promise<any> {
        let promises = [];
        if (!parentId) {
            promises.push(this._getChunkClient(offset, limit, objectType, config));
            promises.push(this._getClientCount(objectType, config));
        }
        else {
            promises.push(this._getChunkClient(offset, limit, objectType, config, parentId));
            promises.push(this._getClientCount(objectType, config, parentId));

        }
        return Promise.all(promises);

    }

    public loadEditClientScreen(companyId: number): Promise<any> {
        let promises = [];
        promises.push(this.loadClientLogo(companyId));
        promises.push(this.loadLoOffices());

        return Promise.all(promises);

    }

    loadRoles(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_SETTING_OBJECTS)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=ROLE`)
            ).then(response => {
                resolve(response.data);
            }).catch(error => {
                console.error("Error loadRoles method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public loadAddClientScreen(): Promise<any> {
        let promises = [];
        promises.push(this.loadRoles());
        promises.push(this.loadLoOffices());

        return Promise.all(promises);

    }

    saveNewClient(addNewClientPayload: IAddNewClientPayLoad): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(CONSTANTS.ADD_NEW_CLIENT_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                , addNewClientPayload
            ).then((response) => {
                resolve(response);
            }).catch((error: any) => {
                console.error("Error saveNewClient method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    createRoleLicenseConfig(clientName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(CONSTANTS.LOCATION_ROLE_CONFIG_URL)
                .concat(`${clientName}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then((response) => {
                resolve(response);
            }).catch((error: any) => {
                console.error("Error createRoleLicenseConfig method", error);
            });
        });
    }

    saveLocation(locationPayload: ILocation): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(CONSTANTS.SAVE_LOCATION_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                , locationPayload
            ).then((response) => {
                resolve(response);
            }).catch((error: any) => {
                console.error("Error saveLocation method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

}