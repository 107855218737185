import { IClientInventory } from "../ClientInventoryInteface/IClientInventory";

export default class ClientInventoryUtility {
    
    public static populateClientInventoryTable = (responseArray: IClientInventory[]): IClientInventory[] => {

        return responseArray.map((clientInventory: IClientInventory) => {
            clientInventory.isEnabledEdit = false;
            return clientInventory;
        });

    }
}
