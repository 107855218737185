import * as React from 'react';
import { ILooseGearStateProps } from '../Interfaces/ILooseGearStateProps';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { Grid, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import * as CONSTANTS from '../Constants/LooseGearsConstants';
import { IKendoGridColumn } from '../../../CommonInterfaces/CommoInterfaces';
import { DropdownStyles, IconConstants } from '../../../Constants/StylesConstants';
import { ActionButton, DatePicker, Dropdown, IconButton, IDropdownOption, Label, PrimaryButton, SearchBox } from 'office-ui-fabric-react';
import { ILooseGearService } from '../Interfaces/ILooseGearService';
import { LooseGearService } from '../Services/LooseGearAPI';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { LooseGearInitialState } from '../Constants/LooseGearInitialState';
import { BookletCustomCell, DateCustomCell, FileCustomCell } from './CustomLooseGearColumnViews';
import * as COMMON_CONSTANTS from './../../../Constants/CommonConstants';
import moment from 'moment-timezone';
import { TaskUtility } from '../../Tasks/Utility/TaskUtility';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { DATE_FORMAT, DATE_TIME_FORMAT_USER } from '../../../Constants/MessageConstants';
import { ILooseGear } from '../Interfaces/ILooseGear';
import { DELETE_ICON_STYLES } from '../../../Constants/CustomStyles';
import { ResultCustomCell, SpecificationsCustomCell } from '../../Assessment/Views/CustomAssessmentsColumnViews';
import { ItemStatusCustomCell } from '../../Assets/Views/CustomColumnViews';
import { LooseGearUtility } from '../Utility/LooseGearUtility';

export default class LooseGearDashboard extends React.Component<ILooseGearStateProps, any> {

    private _looseGearApi: ILooseGearService = null;
    private _taskUtility: TaskUtility;
    private _looseGearUtility: LooseGearUtility;
    private _excelExport: any;


    constructor(props: ILooseGearStateProps) {
        super(props);
        this._looseGearApi = new LooseGearService(window.API_URL, window.loggedInUserDetails);
        this._taskUtility = new TaskUtility();
        this._looseGearUtility = new LooseGearUtility();
    }

    public componentDidMount(): void {
        this.initData();
    }

    public initData = (): void => {

    }

    public changeFilter = (value: string | Date | number | any, field: string): void => {

        let {
            filterLLGSerialNumber,
            filterCertificate,
            filterTaskTypeName,
            filterSpecifications,
            selectedLooseGearSite,
            filterFromInspectionDate,
            filterToInspectionDate,
            filterFromDueDate,
            filterToDueDate,
            filterResult,
            filterAcronymCode,
            filterStatus
        } = this.props.LooseGearState;

        let config = {};

        let actualInspectionDate: string = "";
        let dueDate: string = "";


        switch (field) {
            case "result":
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: value,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config
                });
                break;
            case "taskType":
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: value,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            result: filterResult,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config
                });
                break;
            case "specifications":
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            specifications: value,
                            certificate: filterCertificate,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config
                });
                break;

            case "acronymCode":
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            acronymCode: value,
                            status: filterStatus,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config
                });
                break;
            case "status":
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            acronymCode: filterAcronymCode,
                            status: value,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config
                });
                break;
            case "llgSerialNumber":
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: value ? value : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config
                });
                break;
            case "certificate":
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            specifications: filterSpecifications,
                            certificate: value,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config
                });
                break;

            case "fromDueDate":
                if (filterToDueDate && value) {
                    dueDate = this._looseGearUtility.getDateRangeStr(value, filterToDueDate);
                }
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config,
                    isNoLoad: filterToDueDate ? false : true
                });
                break;
            case "fromInspectionDate":
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                if (value && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(value, filterToInspectionDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config,
                    isNoLoad: filterToInspectionDate ? false : true
                });
                break;

            case "toDueDate":
                if (filterFromDueDate && value) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, value);
                }
                if (filterFromInspectionDate && filterToInspectionDate) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, filterToInspectionDate);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config,
                    isNoLoad: filterFromDueDate ? false : true
                });
                break;
            case "toInspectionDate":
                if (filterFromDueDate && filterToDueDate) {
                    dueDate = this._looseGearUtility.getDateRangeStr(filterFromDueDate, filterToDueDate);
                }
                if (filterFromInspectionDate && value) {
                    actualInspectionDate = this._looseGearUtility.getDateRangeStr(filterFromInspectionDate, value);
                }
                config = {
                    headers: {
                        filter: JSON.stringify({
                            location: selectedLooseGearSite.value,
                            llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                            taskType: filterTaskTypeName,
                            result: filterResult,
                            specifications: filterSpecifications,
                            certificate: filterCertificate,
                            acronymCode: filterAcronymCode,
                            status: filterStatus,
                            actualInspectionDate,
                            dueDate
                        })
                    }
                }
                this.props.changeLooseGearDashboardFilter({
                    filterType: field,
                    filterValue: value,
                    apiPayload: this._looseGearApi,
                    selectedLocation: selectedLooseGearSite,
                    offset: 0,
                    config: config,
                    isNoLoad: filterFromInspectionDate ? false : true
                });
                break;

            default:
                break;
        }
    }

    public dateCustomCell = (props: ILooseGearStateProps): any => (
        <DateCustomCell {...props} currentProps={this.props} />
    );

    public fileCustomCell = (props: ILooseGearStateProps): any => (
        <FileCustomCell {...props} currentProps={this.props} />
    );

    public bookeletCustomCell = (props: ILooseGearStateProps): any => (
        <BookletCustomCell {...props} currentProps={this.props} />
    );

    public resultCustomCell = (props) => (
        <ResultCustomCell {...props} currentProps={this.props} />
    );

    public itemStatusCustomCell = (props) => (
        <ItemStatusCustomCell {...props} currentProps={this.props} />
    );

    public specificationsCustomCell = (props) => (
        <SpecificationsCustomCell {...props} currentProps={this.props} />
    );

    export = () => {

        let {
            filterLLGSerialNumber,
            filterCertificate,
            filterTaskTypeName,
            filterSpecifications,
            selectedLooseGearSite,
            filterFromInspectionDate,
            filterToInspectionDate,
            filterResult,
            filterFromDueDate,
            filterToDueDate,
            filterAcronymCode,
            filterStatus,
        } = this.props.LooseGearState;

        let config = {};

        let actualInspectionDate: string = "";
        let dueDate: string = "";
        let fromDateStr: string = "";
        let toDateStr: string = "";


        if (filterFromInspectionDate && filterToInspectionDate) {
            fromDateStr =
                `${moment(filterFromInspectionDate).format('YYYY-MM-DD')} 00:00:00`;
            toDateStr =
                `${moment(filterToInspectionDate).format('YYYY-MM-DD')} 23:59:59`;
            actualInspectionDate = `${fromDateStr}#${toDateStr}`;

        }
        if (filterFromDueDate && filterToDueDate) {
            fromDateStr =
                `${moment(filterFromDueDate).format('YYYY-MM-DD')} 00:00:00`;
            toDateStr =
                `${moment(filterToDueDate).format('YYYY-MM-DD')} 23:59:59`;
            dueDate = `${fromDateStr}#${toDateStr}`;

        }
        config = {
            headers: {
                filter: JSON.stringify({
                    location: selectedLooseGearSite.value,
                    llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                    taskType: filterTaskTypeName,
                    specifications: filterSpecifications,
                    certificate: filterCertificate,
                    result: filterResult,
                    acronymCode: filterAcronymCode,
                    status: filterStatus,
                    actualInspectionDate,
                    dueDate
                })
            }
        }

        this._looseGearApi._getLooseGearsListApi(-1, -1, config).then((response: any) => {
            this.savetoExcel(this._excelExport, response.data);
        }).catch((error: any) => {
            console.error("Error while excel export: ", error);
            alert("Excel Export Error");
        })


    }

    savetoExcel = (component: any, data: ILooseGear[]) => {
        const options = component.workbookOptions(data);
        const rows = options.sheets[0].rows;

        rows.forEach((row: any) => {
            if (row.type === "data") {
                row.cells.forEach((cell: any, index: number) => {
                    if (index === 5) {
                        cell.value = cell.value ? moment(cell.value).format(DATE_FORMAT) : "";
                    }
                    if (index === 6) {
                        cell.value = cell.value ? moment(cell.value).format(DATE_FORMAT) : "";
                    }
                    if (index === 4) {
                        cell.value = cell.value ? cell.value === 1 ? "Accepted" : cell.value === 2 ? "Rejected" : "OTHERS" : "";
                    }
                    if (cell.format === "custom") {
                        cell.value = ItemStatusCustomCell.getStatus(cell.value)
                    }
                });
            }
        });

        component.save(options);
    };


    public render(): JSX.Element {

        let {
            isLoadingLooseGearDashboard,
            proccessedLooseGears,
            LooseGearGridDataState,
            looseGearsCount,
            selectedLooseGearSite,
            offset,
            looseGears,
            filterLLGSerialNumber,
            filterCertificate,
            filterTaskTypeName,
            filterFromInspectionDate,
            filterToInspectionDate,
            filterFromDueDate,
            filterToDueDate,
            filterSpecifications,
            filterResult,
            filterAcronymCode,
            filterStatus,
            showColumns
        } = this.props.LooseGearState;

        let llgColumns = CommonUtility.sortByColumn(CONSTANTS.LOOSE_GEAR_VIEW_GRID_COLUMNS, "colIndex", "asc");

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 asset-grid">
                            <LoadingOverlay
                                active={isLoadingLooseGearDashboard}
                                spinner={<FadeLoader />}
                                text="Loading Loose Gears...">

                                <Grid
                                    data={proccessedLooseGears}
                                    expandField="expanded"
                                    resizable
                                    reorderable
                                    // groupable
                                    sortable
                                    {...LooseGearGridDataState}
                                    style={{ width: '100%' }}
                                    skip={offset}
                                    take={LooseGearInitialState.limit}
                                    total={looseGearsCount}
                                    pageable={true}
                                    onPageChange={(event: GridPageChangeEvent) => {
                                        let actualInspectionDate: string = "";
                                        if (filterFromInspectionDate && filterToInspectionDate) {
                                            let fromDateStr: string =
                                                `${moment(filterFromInspectionDate).format('YYYY-MM-DD')} 00:00:00`;
                                            let toDateStr: string =
                                                `${moment(filterToInspectionDate).format('YYYY-MM-DD')} 23:59:59`;

                                            actualInspectionDate = `${fromDateStr}#${toDateStr}`;

                                        }
                                        let dueDate: string = "";
                                        if (filterFromDueDate && filterToDueDate) {
                                            let fromDateStr: string =
                                                `${moment(filterFromDueDate).format('YYYY-MM-DD')} 00:00:00`;
                                            let toDateStr: string =
                                                `${moment(filterToDueDate).format('YYYY-MM-DD')} 23:59:59`;

                                            dueDate = `${fromDateStr}#${toDateStr}`;

                                        }
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify({
                                                    location: selectedLooseGearSite.value,
                                                    llgSerialNumber: filterLLGSerialNumber ? filterLLGSerialNumber : "",
                                                    taskType: filterTaskTypeName,
                                                    certificate: filterCertificate,
                                                    specifications: filterSpecifications,
                                                    result: filterResult,
                                                    actualInspectionDate,
                                                    dueDate
                                                })
                                            }
                                        }

                                        this.props.changeLooseGearSiteFilter({
                                            apiPayload: this._looseGearApi,
                                            selectedLocation: selectedLooseGearSite,
                                            offset: event.page.skip,
                                            config: config
                                        })

                                    }}
                                >
                                    {llgColumns
                                        .filter((column: IKendoGridColumn) => column.show
                                            && showColumns.indexOf(column.field) > -1)
                                        .map((column: IKendoGridColumn, index: number) => {
                                            return (
                                                <GridColumn
                                                    key={index}
                                                    field={column.field}
                                                    title={column.title}
                                                    cell={
                                                        column.field === 'actualInspectionDate'
                                                            || column.field === 'dueDate'
                                                            ? this.dateCustomCell :
                                                            column.field === 'result'
                                                                ? this.resultCustomCell :
                                                                column.field === 'itemStatus'
                                                                    ? this.itemStatusCustomCell :
                                                                    column.field === 'certificateFileContent'
                                                                        ? this.fileCustomCell :
                                                                        column.field === 'bookletContent'
                                                                            ? this.bookeletCustomCell :
                                                                            column.field === 'specification'
                                                                                ? this.specificationsCustomCell :
                                                                                looseGears[column.field]
                                                    }
                                                />
                                            );
                                        })}
                                    <GridToolbar>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <ActionButton
                                                        iconProps={IconConstants.refreshIcon}
                                                        disabled={isLoadingLooseGearDashboard}
                                                        text="Reload"
                                                        onClick={() => {
                                                            let config = {
                                                                headers: {
                                                                    filter: JSON.stringify({
                                                                        location: selectedLooseGearSite.value
                                                                    })
                                                                }
                                                            }

                                                            this.props.changeLooseGearSiteFilter({
                                                                apiPayload: this._looseGearApi,
                                                                selectedLocation: selectedLooseGearSite,
                                                                offset: 0,
                                                                config: config,
                                                                isReset: true
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <SearchBox
                                                        underlined
                                                        value={`${filterTaskTypeName}`}
                                                        placeholder="Item"
                                                        onSearch={(newVal: string) => {
                                                            this.changeFilter(newVal, "taskType")
                                                        }}
                                                        onClear={() => {
                                                            this.changeFilter("", "taskType")
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <SearchBox
                                                        underlined
                                                        value={`${filterLLGSerialNumber}`}
                                                        placeholder="Serial Number"
                                                        onSearch={(newVal: string) => {
                                                            this.changeFilter(newVal, "llgSerialNumber")
                                                        }}
                                                        onClear={() => {
                                                            this.changeFilter("", "llgSerialNumber")
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <SearchBox
                                                        underlined
                                                        value={`${filterSpecifications}`}
                                                        placeholder="Specifications"
                                                        onSearch={(newVal: string) => {
                                                            this.changeFilter(newVal, "specifications")
                                                        }}
                                                        onClear={() => {
                                                            this.changeFilter("", "specifications")
                                                        }}
                                                    />
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <SearchBox
                                                        underlined
                                                        value={`${filterCertificate}`}
                                                        placeholder="Certificate"
                                                        onSearch={(newVal: string) => {
                                                            this.changeFilter(newVal, "certificate")
                                                        }}
                                                        onClear={() => {
                                                            this.changeFilter("", "certificate")
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <SearchBox
                                                        underlined
                                                        value={`${filterAcronymCode}`}
                                                        placeholder="ID"
                                                        onSearch={(newVal: string) => {
                                                            this.changeFilter(newVal, "acronymCode")
                                                        }}
                                                        onClear={() => {
                                                            this.changeFilter("", "acronymCode")
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 label-flex">
                                                    <Label>Insp Date From:{` `}</Label>
                                                    <IconButton
                                                        iconProps={IconConstants.deleteicon}
                                                        onClick={() => {
                                                            this.changeFilter(null, "fromInspectionDate")
                                                        }}
                                                        styles={DELETE_ICON_STYLES}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <DatePicker
                                                        underlined
                                                        allowTextInput={false}
                                                        value={filterFromInspectionDate}
                                                        firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                                                        strings={COMMON_CONSTANTS.DayPickerStrings}
                                                        placeholder="Select a date..."
                                                        formatDate={(date) => this._taskUtility.onFormatDate(date)}
                                                        onSelectDate={(date: Date) => {
                                                            this.changeFilter(date, "fromInspectionDate");

                                                        }}
                                                        maxDate={filterToInspectionDate}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 label-flex">
                                                    <Label>Due Date From:{` `}</Label>
                                                    <IconButton
                                                        iconProps={IconConstants.deleteicon}
                                                        onClick={() => {
                                                            this.changeFilter(null, "fromDueDate")
                                                        }}
                                                        styles={DELETE_ICON_STYLES}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <DatePicker
                                                        underlined
                                                        allowTextInput={false}
                                                        value={filterFromDueDate}
                                                        firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                                                        strings={COMMON_CONSTANTS.DayPickerStrings}
                                                        placeholder="Select a date..."
                                                        formatDate={(date) => this._taskUtility.onFormatDate(date)}
                                                        onSelectDate={(date: Date) => {
                                                            this.changeFilter(date, "fromDueDate");

                                                        }}
                                                        maxDate={filterToDueDate}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    {' '}
                                                    <Dropdown
                                                        selectedKey={filterResult}
                                                        options={CONSTANTS.TASK_RESULT_DROPDOWN_FILTER_OPTIONS}
                                                        styles={DropdownStyles}
                                                        placeholder="Status"
                                                        onChange={(ev: any, newVal: IDropdownOption) => {
                                                            this.changeFilter(newVal.key, "result")
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 label-flex">
                                                    <Label>Insp Date To:{` `}</Label>
                                                    <IconButton
                                                        iconProps={IconConstants.deleteicon}
                                                        onClick={() => {
                                                            this.changeFilter(null, "toInspectionDate")
                                                        }}
                                                        styles={DELETE_ICON_STYLES}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <DatePicker
                                                        underlined
                                                        allowTextInput={false}
                                                        value={filterToInspectionDate}
                                                        firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                                                        strings={COMMON_CONSTANTS.DayPickerStrings}
                                                        placeholder="Select a date..."
                                                        formatDate={(date) => this._taskUtility.onFormatDate(date)}
                                                        onSelectDate={(date) => {
                                                            this.changeFilter(date, "toInspectionDate");
                                                        }}
                                                        minDate={filterFromInspectionDate}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 label-flex">
                                                    <Label>Due Date To:{` `}</Label>
                                                    <IconButton
                                                        iconProps={IconConstants.deleteicon}
                                                        onClick={() => {
                                                            this.changeFilter(null, "toDueDate")
                                                        }}
                                                        styles={DELETE_ICON_STYLES}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <DatePicker
                                                        underlined
                                                        allowTextInput={false}
                                                        value={filterToDueDate}
                                                        firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                                                        strings={COMMON_CONSTANTS.DayPickerStrings}
                                                        placeholder="Select a date..."
                                                        formatDate={(date) => this._taskUtility.onFormatDate(date)}
                                                        onSelectDate={(date) => {
                                                            this.changeFilter(date, "toDueDate");
                                                        }}
                                                        minDate={filterFromDueDate}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <Dropdown
                                                        selectedKey={filterStatus}
                                                        options={CONSTANTS.TASK_STATUS_DROPDOWN_FILTER_OPTIONS}
                                                        styles={DropdownStyles}
                                                        placeholder="Status"
                                                        onChange={(ev: any, newVal: IDropdownOption) => {
                                                            this.changeFilter(newVal.key, "status")
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    {' '}
                                                    <PrimaryButton
                                                        className="table-export-button-div"
                                                        text="Export to Excel"
                                                        iconProps={IconConstants.excelIcon}
                                                        onClick={() => this.export()}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </GridToolbar>
                                </Grid>

                                <ExcelExport
                                    // data={looseGears}
                                    ref={exporter => this._excelExport = exporter}
                                    fileName={`Loose_Gear_of_Site_${selectedLooseGearSite.label}_${moment.utc(new Date()).local().format(DATE_TIME_FORMAT_USER)}.xlsx`}
                                >
                                    {CONSTANTS.LOOSE_GEAR_VIEW_GRID_COLUMNS
                                        .filter((column) => column.field !== "id" && column.field !== "jobNumber" && column.field !== "certificateFileContent")
                                        .map((column, index) => {
                                            return (
                                                <ExcelExportColumn
                                                    key={index}
                                                    field={column.field === 'itemStatus' ? "dueDate" : column.field}
                                                    title={column.title}
                                                    cellOptions={{
                                                        format: column.filter === "date" ? "date" : column.filter === "custom" ? "custom" : "",
                                                    }}
                                                />
                                            );
                                        })}
                                </ExcelExport>
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
