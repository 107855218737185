import { DetailsList, Icon, Link, SelectionMode, Spinner, SpinnerSize, TooltipHost, initializeIcons, IconButton, Slider } from 'office-ui-fabric-react';
import React, { Component } from 'react'
import { ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH, ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH } from '../Constants/AssetConstants';
import { AssetAPI } from '../Services/AssetAPI';
import { calloutProps, hostStyles, IconConstants, tooltipId } from '../../../Constants/StylesConstants';
import * as CommonConstants from '../../../Constants/CommonConstants';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';

initializeIcons();

export default class AssetReports extends Component {

    _assetAPI = null;

    constructor(props) {
        super(props);
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);
    }

    componentDidMount() {

        let { selectedAssetId, assetReportMaxDataPerPage } = this.props.Asset;

        this.props.loadAssetReports({
            apiObject: this._assetAPI,
            selectedAssetId,
            offset: 0,
            limit: assetReportMaxDataPerPage
        })
    }

    goToFirstPage = () => {

        let { selectedAssetId, assetReportSortedColumn, assetReportMaxDataPerPage } = this.props.Asset;

        this.props.loadAssetReports({
            apiObject: this._assetAPI,
            selectedAssetId,
            assetReportSortedColumn,
            offset: 0,
            limit: assetReportMaxDataPerPage
        })
    }

    _onColumnClick = (event, column) => {
        let { assetReportSortedColumn } = this.props.Asset;
        let newAssetReportSortedColumn = {
            columnName: column.fieldName,
            sortDesc:
                assetReportSortedColumn.columnName !== column.fieldName ?
                    "asc"
                    :
                    assetReportSortedColumn.columnName === column.fieldName && assetReportSortedColumn.sortDesc === "asc" ?
                        "desc" : "asc"
        }
        this.props.loadAssetReports({
            apiObject: this._assetAPI,
            selectedAssetId: this.props.Asset.selectedAssetId,
            assetReportSortedColumn: newAssetReportSortedColumn,
            offset: 0,
            limit: this.props.Asset.assetReportMaxDataPerPage
        })
    }

    render() {

        let {
            isLoadingAssetReportsTab,
            observations,
            assetReportSortedColumn,
            assetReportOffset,
            assetReportMaxDataPerPage
        } = this.props.Asset;

        const loggedInUser = window.loggedInUserDetails;

        const OBSERVATION_COLUMNS = [
            {
                key: 'column1',
                onColumnClick: this._onColumnClick,
                isSorted: assetReportSortedColumn?.columnName === "actualInspectionDate",
                isSortedDescending: assetReportSortedColumn?.columnName === "actualInspectionDate" && assetReportSortedColumn.sortDesc === "desc",
                name: 'Inspection Date',
                fieldName: 'actualInspectionDate',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH, maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH, isResizable: true
            },
            {
                key: 'column4',
                onColumnClick: this._onColumnClick,
                name: 'Cert / Report No.',
                isSorted: assetReportSortedColumn?.columnName === "certificate",
                isSortedDescending: assetReportSortedColumn?.columnName === "certificate" && assetReportSortedColumn.sortDesc === "desc",
                fieldName: 'certificate',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true
            },
            {
                key: 'column5',
                onColumnClick: this._onColumnClick,
                name: 'Result',
                isSorted: assetReportSortedColumn?.columnName === "result",
                isSortedDescending: assetReportSortedColumn?.columnName === "result" && assetReportSortedColumn.sortDesc === "desc",
                fieldName: 'result',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true
            },
            // { key: 'column5', name: 'Due Date', fieldName: 'dueDate', minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH, maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH, isResizable: true },
            {
                key: 'column6',
                name: 'Cert / Report',
                fieldName: 'certificateLink',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true,
                onRender: (item) => {
                    let fileURL = window.API_URL + 'filedownload;jssessionid=' + window.loggedInUserDetails.jsessionid
                        + '?observationId=' + item.key + "&id=" + item.certificateLink;
                    return (
                        item.certificateLink ?
                            (
                                <TooltipHost
                                    content={`Click here to open certificate`}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    styles={hostStyles}
                                >

                                    <Link target='_blank' href={fileURL}>
                                        <Icon
                                            iconName={IconConstants.pdfIcon.iconName}
                                            styles={{
                                                root: {
                                                    color: "#F40F02"
                                                }
                                            }}
                                        />
                                    </Link>
                                </TooltipHost>
                            )
                            :
                            <span></span>
                    );
                }
            },
            {
                key: 'column9',
                onColumnClick: this._onColumnClick,
                name: 'Next Due Date',
                isSorted: assetReportSortedColumn?.columnName === "dueDate",
                isSortedDescending: assetReportSortedColumn?.columnName === "dueDate" && assetReportSortedColumn.sortDesc === "desc",
                fieldName: 'dueDate',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true
            }
        ];

        if ([CommonConstants.SCHEDULER_ROLE].indexOf(loggedInUser.role) === -1) {
            OBSERVATION_COLUMNS.push({
                key: 'column2',
                onColumnClick: this._onColumnClick,
                isSorted: assetReportSortedColumn?.columnName === "certificateDate",
                isSortedDescending: assetReportSortedColumn?.columnName === "certificateDate" && assetReportSortedColumn.sortDesc === "desc",
                name: 'Cert / Report Date',
                fieldName: 'certificateDate',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH, isResizable: true
            });
            if ([CommonConstants.SCHEDULER_ROLE, CommonConstants.CLIENT_USER_ROLE, CommonConstants.CLIENT_ADMIN_ROLE].indexOf(loggedInUser.role) === -1) {
                OBSERVATION_COLUMNS.push({
                    key: 'column3',
                    onColumnClick: this._onColumnClick,
                    name: 'Job Id',
                    isSorted: assetReportSortedColumn?.columnName === "jobNumber",
                    isSortedDescending: assetReportSortedColumn?.columnName === "jobNumber" && assetReportSortedColumn.sortDesc === "desc",
                    fieldName: 'jobNumber',
                    minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                    maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                    isResizable: true
                });
                OBSERVATION_COLUMNS.push({
                    key: 'column8',
                    onColumnClick: this._onColumnClick,
                    name: 'Inspector',
                    isSorted: assetReportSortedColumn?.columnName === "inspector",
                    isSortedDescending: assetReportSortedColumn?.columnName === "inspector" && assetReportSortedColumn.sortDesc === "desc",
                    fieldName: 'inspector',
                    minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                    maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                    isResizable: true
                });

            }
        }
        if ([CommonConstants.CLIENT_USER_ROLE, CommonConstants.REQUESTOR_ROLE, CommonConstants.CLIENT_ADMIN_ROLE]
            .indexOf(loggedInUser.role) === -1) {
            OBSERVATION_COLUMNS.push({
                key: 'column7',
                name: 'Field Report',
                fieldName: 'fieldReportLink',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true,
                onRender: (item) => {
                    return (
                        item.fieldReportLink ?
                            (
                                item.fieldReportLink.map((link, index) => {
                                    return (
                                        <TooltipHost
                                            content={`Click here to open field report: ${link.fileName}`}
                                            id={tooltipId}
                                            calloutProps={calloutProps}
                                            styles={hostStyles}
                                            key={index}
                                        >

                                            <Link target='_blank' href={window.API_URL + 'filedownload;jssessionid=' + window.loggedInUserDetails.jsessionid
                                                + '?observationId=' + item.key + "&id=" + link.blobKey}>
                                                <Icon
                                                    iconName={IconConstants.pdfIcon.iconName}
                                                    styles={{
                                                        root: {
                                                            color: "#F40F02"
                                                        }
                                                    }}
                                                />
                                            </Link>
                                        </TooltipHost>

                                    )
                                })
                            )
                            :
                            <span></span>
                    );
                }

            })
        }

        return (
            <React.Fragment>
                {
                    isLoadingAssetReportsTab ?
                        <div className="login-spinner-div">
                            <Spinner
                                label="Please wait..."
                                size={SpinnerSize.large} />
                        </div> :
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                                    <Slider
                                        min={1}
                                        max={50}
                                        step={1}
                                        defaultValue={assetReportMaxDataPerPage}
                                        showValue={true}
                                        onChange={(value) => {
                                            this.props.changeLimitOfReportPerPage({
                                                limit: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                    <Link
                                        styles={{
                                            root: {
                                                fontWeight: "600"
                                            }
                                        }}
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.loadAssetReports({
                                                apiObject: this._assetAPI,
                                                selectedAssetId: this.props.Asset.selectedAssetId,
                                                assetReportSortedColumn,
                                                offset: 0,
                                                limit: assetReportMaxDataPerPage
                                            })
                                        }}>Set Limit</Link>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                                    <IconButton
                                        disabled={assetReportOffset === 0}
                                        iconProps={IconConstants.chevronLeftEndIcon}
                                        onClick={() => this.goToFirstPage()}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg11 custom-next-prev-pagination">
                                    <IconButton
                                        disabled={assetReportOffset === 0}
                                        iconProps={IconConstants.chevronLeftIcon}
                                        onClick={() => {
                                            this.props.loadAssetReports({
                                                apiObject: this._assetAPI,
                                                selectedAssetId: this.props.Asset.selectedAssetId,
                                                assetReportSortedColumn,
                                                offset: assetReportOffset - assetReportMaxDataPerPage,
                                                limit: assetReportMaxDataPerPage
                                            })
                                        }}
                                    />
                                    <IconButton
                                        disabled={observations?.length < assetReportMaxDataPerPage}
                                        iconProps={IconConstants.chevronRightIcon}
                                        onClick={() => {
                                            this.props.loadAssetReports({
                                                apiObject: this._assetAPI,
                                                selectedAssetId: this.props.Asset.selectedAssetId,
                                                assetReportSortedColumn,
                                                offset: assetReportOffset + assetReportMaxDataPerPage,
                                                limit: assetReportMaxDataPerPage
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DetailsList
                                        items={observations}
                                        columns={CommonUtility.sortByColumn(OBSERVATION_COLUMNS, "key", "asc")}
                                        selectionMode={SelectionMode.none}
                                    />
                                </div>
                            </div>
                        </div>
                }
            </React.Fragment >
        )
    }
}
