import React, { Component } from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { IconConstants } from '../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class Confirm extends Component {

    render() {
        let { confirmJSON, deleteOrRetainItem } = this.props;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: "Are you sure want to delete?"
        };
        return (
            <Dialog
                hidden={confirmJSON.hideConfirm}
                onDismiss={() => deleteOrRetainItem(false)}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => deleteOrRetainItem(true)}
                        text="Delete"
                        menuIconProps={IconConstants.deleteicon}
                    />
                    <DefaultButton
                        onClick={() => deleteOrRetainItem(false)}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
