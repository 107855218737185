import React, { Component } from 'react'
import { Route, HashRouter } from 'react-router-dom';
import './../../App.scss';

import { Nav } from 'office-ui-fabric-react/lib/Nav';
import Header from '../Header/Header.jsx';
import { Image, Icon } from 'office-ui-fabric-react';
import Main from '../Main/Main.jsx';
// import history from '../../history';
import shortIcon from './../../images/favicon.svg';
import { navStylesSideBar } from '../../Constants/StylesConstants';
import { SideBarElements } from '../../Constants/SideBarElements.ts';
import qwikspecLogo from './../../images/logo-light-color.svg';
import { IconConstants } from '../../Constants/StylesConstants';

export default class SideBar extends Component {

    _sideBarElement;
    _menuButtonElement = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            selectedKey: window.location.hash.substr(2),
            isExpanded: true,
            isShowFullLogo: false,
        }
        this._sideBarElement = new SideBarElements(window.loggedInUserDetails).generateSideBarElements()
    }

    render() {

        let { selectedKey, isExpanded } = this.state;

        return (
            <HashRouter>
                <Route render={({ location, history }) => (
                    <React.Fragment>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <React.Fragment>

                                    <div className={isExpanded ?
                                        "ms-Grid-col ms-sm12 ms-md12 ms-lg2 qwikspec-sidebar-expanded" :
                                        "ms-Grid-col ms-sm12 ms-md12 ms-lg1 qwikspec-sidebar-collapsed"}>

                                        <div className="logo-div">
                                            {isExpanded ?
                                                <React.Fragment>

                                                    <Image
                                                        className="expand-image"
                                                        src={qwikspecLogo}
                                                        alt="Qwikspec Image"
                                                        width="100%"
                                                    // height={50}
                                                    // onClick={() => this.setState({
                                                    //     isExpanded: !isExpanded
                                                    // })}
                                                    />
                                                    <Icon
                                                        iconProps={IconConstants.globalNavButtonIcon}
                                                        iconName="GlobalNavButton"
                                                        onClick={() => this.setState({
                                                            isExpanded: !isExpanded
                                                        })}
                                                    />
                                                </React.Fragment>
                                                :

                                                <Image
                                                    className="collapse-image"
                                                    src={shortIcon}
                                                    alt="Qwikspec Image"
                                                    width="100%"
                                                    height={50}
                                                    onClick={() => this.setState({
                                                        isExpanded: !isExpanded
                                                    })}
                                                />
                                            }
                                        </div>
                                        {isExpanded &&
                                            <>
                                                <div className='menu-item'>
                                                    <Nav
                                                        onLinkClick={(ev, item) => {
                                                            const to = '/' + item.url;
                                                            sessionStorage.setItem("link", item.name);
                                                            ev.preventDefault();
                                                            // let { location, history } = this.props;
                                                            if (location.pathname !== to) {
                                                                history.push(to);
                                                            }
                                                            this.setState({
                                                                selectedKey: item.key
                                                            })
                                                        }}
                                                        selectedKey={selectedKey ? selectedKey : "Home"}
                                                        ariaLabel="Nav basic example"
                                                        styles={navStylesSideBar}
                                                        groups={this._sideBarElement}
                                                    />
                                                </div>
                                                {/* {customerLogo &&
                                                    <div className='company-logo' ref={this._menuButtonElement} onClick={() => {
                                                        this.setState({
                                                            isShowFullLogo: !isShowFullLogo
                                                        })
                                                    }}>
                                                        <Image
                                                            src={companyPersona.imageUrl}
                                                            styles={{
                                                                image: {
                                                                    display: "block",
                                                                    maxHeight: "2.85em",
                                                                    height: "auto"
                                                                }
                                                            }}
                                                        />
                                                        {isShowFullLogo &&
                                                            <Callout
                                                                className="ms-CalloutExample-callout"
                                                                ariaLabelledBy={CONSTANTS._labelId}
                                                                ariaDescribedBy={CONSTANTS._descriptionId}
                                                                role="alertdialog"
                                                                gapSpace={0}
                                                                target={this._menuButtonElement.current}
                                                                onDismiss={() => {
                                                                    this.setState({
                                                                        isShowFullLogo: false
                                                                    })
                                                                }}
                                                                setInitialFocus={true}
                                                                hidden={!isShowFullLogo}
                                                            >
                                                                <Image
                                                                    src={companyPersona.imageUrl}
                                                                    styles={{
                                                                        image: {
                                                                            display: "block",
                                                                            width: "auto",
                                                                            height: "auto",
                                                                            maxWidth: "50em",
                                                                            maxHeight: "50em",
                                                                        }
                                                                    }}
                                                                />
                                                            </Callout>
                                                        }
                                                    </div>
                                                } */}
                                            </>
                                        }
                                    </div>
                                    <div className={isExpanded ? "ms-Grid-col ms-sm12 ms-md12 ms-lg10 container-expanded" : "ms-Grid-col ms-sm12 ms-md12 ms-lg11 container-collpased"} >
                                        <Header {...this.props} />
                                        <Main {...this.props} />

                                    </div>
                                </React.Fragment>

                            </div>
                        </div>
                    </React.Fragment>
                )}
                />
            </HashRouter>
        )
    }
}



