import { IClientSiteSelectorActionKeys, IClientSiteSelectorInitialState, IClientSiteSelectorPayload }
    from "../ClientSiteSelectorInteface/IClientSiteSelector";
import { ClientSiteSelectorInitialState } from "../Constants/ClientSiteSelectorInitialState";
import ClientSiteSelectorUtility from "../Utility/ClientSiteSelectorUtility";
import * as Constants from './../Constants/ClientSiteSelectorConstants';

export function ClientSiteSelectorReducer(state: IClientSiteSelectorInitialState = ClientSiteSelectorInitialState,
    action: IClientSiteSelectorActionKeys) {

    let tempState: IClientSiteSelectorInitialState = { ...state };
    let payload: IClientSiteSelectorPayload = action.payload;

    switch (action.type) {

        case Constants.RESET_SELECTORS:
            tempState.isLoadingClients = true;
            tempState.isLoadingSites = false;
            tempState.clients = [];
            tempState.sites = [];
            tempState.selectedClient = null;
            tempState.selectedSite = null;
            break;

        case Constants.LOAD_CLIENT_SELECTOR:
            tempState.isLoadingClients = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {
                tempState.clients = ClientSiteSelectorUtility.loadSelectDropDownValue([...payload.locations]);
            }
            break;

        case Constants.LOAD_SITE_SELECTOR:
            tempState.sites = [];
            tempState.selectedSite = null;
            tempState.selectedClient = payload.selectedLocation;
            tempState.isLoadingSites = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {
                tempState.sites = ClientSiteSelectorUtility.loadSelectDropDownValue([...payload.locations]);
            }
            break;

        case Constants.CHANGE_SITE_SELECTOR:
            tempState.selectedSite = payload.selectedLocation;
            break;

        case Constants.OPEN_CLOSE_CLIENT_SITE_SELECTOR_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON;
            break;

        default:
            break;
    }

    return tempState;

}