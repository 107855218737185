import { ILoggedInUser } from "../../../CommonInterfaces/CommoInterfaces";
import axios from 'axios';
import { GET_ALL_ASSESSMENTS_URL, GET_ALL_TASKS_URL, GET_TASKS_COUNT } from "../../Tasks/Constants/TaskConstants";
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import { IAssessmentService } from "../Interfaces/IAssessmentsService";


export class AssessmentsService implements IAssessmentService {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }

    private _getTasksCount = (config: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_TASKS_COUNT)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`), config)
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getTasksCount method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    public getAssessmentsApi = (offset: number, limit: number, config: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_ALL_ASSESSMENTS_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?offset=${offset}&limit=${limit}`), config
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getAssessMentsApi method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    private _getChunkTasks = (config: any, offset: number, limit: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_ALL_ASSESSMENTS_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?offset=${offset}&limit=${limit}`), config
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getChunkTasks method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getSelectedObservationAPI = (observationId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_ALL_TASKS_URL)
                .concat(`/${observationId}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getSelectedObservationAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getTaskDetails = (config: any, offset: number, limit: number): Promise<any> => {
        let promises = [this._getChunkTasks(config, offset, limit)];
        return Promise.all(promises);

    }

}
