import * as React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { TaskAPI } from '../Services/TaskAPI';
import { IconConstants } from '../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: {
        main: {
            width: 750,
        }
    },
};

export default class ConfirmAttachAssetRedux extends React.PureComponent<any, any> {

    private _taskApi: TaskAPI;

    constructor(props) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);

    }

    render() {
        let {
            hideAttachAssetConfirm,
            isShiftAndAttach,
            SelectedTask,
            selectedAsset
        } = this.props.Task;

        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `${isShiftAndAttach ? `Do you want to Shift ${selectedAsset.name} from ${selectedAsset.site} and attach to this task?`
                : `Do you want to attach Asset ${selectedAsset.name} to this task?`}`
        };
        return (
            <Dialog
                hidden={hideAttachAssetConfirm}
                onDismiss={() => this.props.openCloseAttachAssetConfirmModal({
                    isShiftAndAttach: false,
                    selectedAsset: null,
                    hideAttachAssetConfirm: true,
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >

                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            isShiftAndAttach ?
                                this.props.attachAssetToBlankLocationTask({
                                    apiObject: this._taskApi,
                                    SelectedTask: SelectedTask,
                                    selectedAsset: selectedAsset,
                                    isShiftAndAttach: true,
                                    hideAttachAssetConfirm: true,
                                })
                                :
                                this.props.attachAssetToBlankLocationTask({
                                    apiObject: this._taskApi,
                                    SelectedTask: this.props.Task.SelectedTask,
                                    selectedAsset: selectedAsset,
                                    isShiftAndAttach: false,
                                    hideAttachAssetConfirm: true,
                                });

                        }}
                        text={isShiftAndAttach ? `Shift and Attach` : `Yes`}
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseAttachAssetConfirmModal({
                            isShiftAndAttach: false,
                            selectedAsset: null,
                            hideAttachAssetConfirm: true,
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog >
        )
    }
}
