import React, { PureComponent } from 'react'
import { Label } from 'office-ui-fabric-react'

export default class AssetTypeFiles extends PureComponent {
    render() {
        return (
            <Label> Files </Label>
        )
    }
}
