import { StatesInitialState } from "../Constants/StatesIntialState";
import { IStateAction, IStatesInitialState } from "../Interfaces/IStates";

export function StatesReducer(state: IStatesInitialState = StatesInitialState, action: IStateAction): IStatesInitialState {

    let tempState: IStatesInitialState = { ...state };
    // let payload: IStatePayload = action.payload;

    // console.log(payload);

    switch (action.type) {

        default:
            break;
    }

    return tempState;

}