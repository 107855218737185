import { ActionButton, DefaultButton, INavLink, INavLinkGroup, Label, Nav, SearchBox, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';
import { navStylesFiles } from '../../../Constants/CustomStyles';
import { IconConstants } from '../../../Constants/StylesConstants';
import { TASK_STATUS } from '../Constants/TaskConstants';
import { TaskAPI } from '../Services/TaskAPI';

export default class LLGCertificatesBrowser extends React.PureComponent<any, {}> {

    private _taskApi: TaskAPI;

    public componentDidMount(): void {
        this.props.loadChildLLGTasksAsync({
            parentObservationId: this.props.Task.SelectedTask.id,
            apiObject: this._taskApi
        });
    }

    /**
     *
     */
    public constructor(props: any) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);
    }

    public render(): JSX.Element {

        let {
            // SelectedChildLLGTaskId,
            SelectedChildLLGTask,
            SelectedTask,
            ChildLLGTasks,
            isLoadingChildLLGTasks,
            LLGTaskSearchFilterText,
            TabIndex
        } = this.props.Task;


        let tempChildLLGTasks: INavLinkGroup[] = [...ChildLLGTasks];

        if (LLGTaskSearchFilterText) {

            let tempChildLLGItem: INavLinkGroup = { ...tempChildLLGTasks[0] };
            let tempChildLLGItemLinks: INavLink[] = [...tempChildLLGItem.links];
            tempChildLLGItemLinks = tempChildLLGItemLinks.filter(
                (task: INavLink) =>
                    task.name.toUpperCase().indexOf(LLGTaskSearchFilterText.toUpperCase()) > -1 ||
                    task.title.toUpperCase().indexOf(LLGTaskSearchFilterText.toUpperCase()) > -1
            );

            tempChildLLGItem.links = tempChildLLGItemLinks;
            tempChildLLGTasks[0] = tempChildLLGItem;

        }
        let listOfItems: number = tempChildLLGTasks[0] ? tempChildLLGTasks[0]?.links?.length : 0;

        return (
            <React.Fragment>
                {isLoadingChildLLGTasks ?
                    <Spinner
                        size={SpinnerSize.large}
                        label="Loading Child LLG Tasks..." />
                    :
                    <React.Fragment>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DefaultButton
                                    text="Preview Booklet"
                                    menuIconProps={IconConstants.previewIcon}
                                    onClick={() => {
                                        this.props.cleanTaskPdf({
                                            PDFURL: ""
                                        })
                                        setTimeout(() => {
                                            this.props.showLLGTaskBookletAsync({
                                                SelectedTask,
                                                SelectedChildLLGTask: null,
                                                isShowTaskCeritificateBrowser: true,
                                                apiObject: this._taskApi,
                                                TabIndex: TabIndex,
                                            })

                                        }, 1);
                                    }}
                                />
                            </div>
                        </div>
                        <SearchBox
                            value={LLGTaskSearchFilterText}
                            onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
                                this.props.changeChildLLGTaskSearchText({
                                    searchText: newValue
                                })
                            }}
                            onClear={(ev?: any) => {
                                this.props.changeChildLLGTaskSearchText({
                                    searchText: ""
                                })
                            }}
                            placeholder="Name or Certificate Number"
                            underlined
                        />
                        <Label
                            styles={{
                                root: {
                                    background: "#e6e6e6",
                                    padding: "2.5px 10px"
                                }
                            }}
                        >
                            {`Total ${listOfItems} Item(s) Found`}</Label>
                        <Nav
                            onLinkClick={(event?: React.MouseEvent<HTMLElement>, item?: any) => {
                                event.preventDefault();
                            }}
                            selectedKey={SelectedChildLLGTask?.id}
                            ariaLabel="Nav basic example"
                            styles={navStylesFiles}
                            onRenderLink={(props) => (
                                <ActionButton
                                    text={`${props.name}`}
                                    style={{
                                        textDecoration: TASK_STATUS[props.status] === "CANCELLED" ? `line-through` : `none`
                                    }}
                                    onClick={() => {
                                        this.props.cleanTaskPdf({
                                            PDFURL: ""
                                        })
                                        setTimeout(() => {
                                            let SelectedChildLLGTask = { ...props };
                                            SelectedChildLLGTask.id = props.key;
                                            this.props.showLLGTaskPdfAsync({
                                                SelectedChildLLGTask,
                                                isShowTaskCeritificateBrowser: true,
                                                apiObject: this._taskApi,
                                                TabIndex: TabIndex,
                                            })

                                        }, 1);
                                    }}
                                />
                            )}
                            groups={tempChildLLGTasks}
                        />
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
