import React, { Component } from 'react';
import { Label, TextField, Spinner, Separator, DatePicker, Icon, TooltipHost } from 'office-ui-fabric-react';
import { JobLitesAPI } from '../Services/JobLitesAPI';
import Select from 'react-select';
import { calloutProps, columnHostStyles, IconConstants, seperatorTheme, tooltipId } from '../../../Constants/StylesConstants';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment-timezone';
import * as COMMON_CONSTANTS from '../../../Constants/CommonConstants';
import { TaskUtility } from '../../Tasks/Utility/TaskUtility';
import NewSiteDetails from './NewSiteDetails';
import NewUserDetails from './NewUserDetails';

export default class ClientDetails extends Component {

    _apiObject = null;
    _taskUtility = null;
    constructor(props) {
        super(props);
        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails)
        this._taskUtility = new TaskUtility();
    }

    render() {

        let {
            ClientOptions,
            SiteOptions,
            RequesterOptions,
            CurrentJobDetails,
            LoadingSites,
            SelectedJob,
            showTimeClock,
            ShowAddNewSiteSection,
            ShowAddNewUserSection
        } = this.props.JobLites;

        let timerStr = moment(new Date()).format('HH:mm');
        if (CurrentJobDetails?.StartDate) {
            timerStr = moment(new Date(CurrentJobDetails?.StartDate.value)).format('HH:mm');
        }


        return (

            <div className="ms-Grid" dir="ltr">
                <Separator theme={seperatorTheme}>Client Details</Separator>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>
                            Client Name <span className="mandatory-span">*</span>
                        </Label>

                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>
                            Site Name <span className="mandatory-span">*</span>
                            {`  `}
                            {!ShowAddNewSiteSection && CurrentJobDetails?.ClientName.value &&

                                <TooltipHost
                                    content={`Add New Site`}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    styles={columnHostStyles}
                                >
                                    <Icon
                                        iconName={IconConstants.addIcon.iconName}
                                        onClick={() => this.props.showHideAddNewSiteSectionJob({
                                            ShowAddNewSiteSection: true
                                        })}
                                    />
                                </TooltipHost>
                            }
                            {ShowAddNewSiteSection &&
                                <TooltipHost
                                    content={`Close Section`}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    styles={columnHostStyles}
                                >
                                    <Icon
                                        iconName={IconConstants.removeIcon.iconName}
                                        onClick={() =>
                                            this.props.showHideAddNewSiteSectionJob({
                                                ShowAddNewSiteSection: false
                                            })}
                                    />
                                </TooltipHost>
                            }
                        </Label>
                    </div>
                </div>


                <div className="ms-Grid-row">
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <Select
                            isDisabled={SelectedJob}
                            autoBlur={true}
                            placeholder="Please Select Client Name"
                            value={CurrentJobDetails?.ClientName.value}
                            onChange={(value) => {
                                if (value) {
                                    this.props.loadSites({
                                        apiObject: this._apiObject,
                                        Location: value
                                    })
                                }
                            }}
                            options={CommonUtility.sortByColumn(ClientOptions, "label", "asc")}
                        />
                        <small className='info-span'>{CurrentJobDetails?.ClientName?.value?.details}</small>

                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        {LoadingSites ?
                            <Spinner label="Loading Sites" />
                            :
                            <>
                                <Select
                                    placeholder="Please Select Site Name"
                                    value={CurrentJobDetails?.SiteDetails?.value}
                                    onChange={(value) => {
                                        if (value) {
                                            this.props.changeSite({
                                                Location: value
                                            })
                                        }
                                    }}
                                    options={CommonUtility.sortByColumn(SiteOptions.filter(d => d.locationLevel === 0), "label", "asc")}
                                />
                                <small className='info-span'>{CurrentJobDetails?.SiteDetails?.value?.details}</small>
                            </>
                        }
                        {ShowAddNewSiteSection &&
                            <NewSiteDetails {...this.props} />
                        }
                    </div>

                </div>

                {/* Start Date & name */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <DatePicker
                            label={`Start Date`}
                            allowTextInput={false}
                            firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                            strings={COMMON_CONSTANTS.DayPickerStrings}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            formatDate={(date) => this._taskUtility.onFormatDate(date)}
                            value={CurrentJobDetails.StartDate.value}
                            onSelectDate={(date) => {
                                let dateStr =
                                    moment(date).format('YYYY-MM-DD');
                                let selectedDatetime = moment(dateStr + " " + timerStr);

                                this.props.changeStartDateTime({
                                    StartDate: new Date(selectedDatetime.format())
                                });
                            }}
                            isRequired
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>Start Time</Label>
                        {!showTimeClock ?
                            <React.Fragment>
                                <TextField
                                    readOnly
                                    value={timerStr}
                                    onClick={() => {
                                        this.props.showHideClientDetailsTimeClock({
                                            showTimeClock: !showTimeClock
                                        })
                                    }}
                                    iconProps={IconConstants.timePickerIcon}
                                    styles={{
                                        field: {
                                            cursor: "pointer",
                                        }
                                    }}
                                />
                            </React.Fragment>
                            :
                            <TimeKeeper
                                time={timerStr}
                                hour24Mode={true}
                                onDoneClick={() => {
                                    this.props.showHideClientDetailsTimeClock({
                                        showTimeClock: !showTimeClock
                                    })
                                }}
                                onChange={(newTime) => {
                                    let dateStr =
                                        CurrentJobDetails?.StartDate ?
                                            moment(CurrentJobDetails?.StartDate.value).format('YYYY-MM-DD') :
                                            moment(new Date()).format('YYYY-MM-DD');

                                    let selectedDatetime = moment(dateStr + " " + newTime.formatted12);

                                    this.props.changeStartDateTime({
                                        StartDate: new Date(selectedDatetime.format())
                                    });
                                }}
                            />
                        }
                    </div>
                </div>

                {/* Requester */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>
                            Requester{`  `}
                            {!ShowAddNewUserSection && CurrentJobDetails?.ClientName.value &&
                                CurrentJobDetails?.SiteDetails.value &&
                                <TooltipHost
                                    content={`Add New Requester`}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    styles={columnHostStyles}
                                >
                                    <Icon
                                        iconName={IconConstants.addIcon.iconName}
                                        onClick={() =>
                                            this.props.showHideAddNewUserSectionJob({
                                                ShowAddNewUserSection: true
                                            })}
                                    />
                                </TooltipHost>
                            }
                            {ShowAddNewUserSection &&
                                <TooltipHost
                                    content={`Close Section`}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    styles={columnHostStyles}
                                >
                                    <Icon
                                        iconName={IconConstants.removeIcon.iconName}
                                        onClick={() =>
                                            this.props.showHideAddNewUserSectionJob({
                                                ShowAddNewUserSection: false
                                            })}
                                    />
                                </TooltipHost>
                            }
                        </Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>Site Users</Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
                        <Select
                            placeholder="Please Select Requester"
                            isClearable
                            value={CurrentJobDetails.RequesterName.value}
                            onChange={(value) => {
                                this.props.changeRequesterName({
                                    Requester: value
                                })
                            }}
                            options={CurrentJobDetails.SiteDetails.value ?
                                CommonUtility.sortByColumn(RequesterOptions.filter(requester => 
                                    requester.designation === COMMON_CONSTANTS.REQUESTOR_ROLE &&
                                    requester.siteIds?.split(",").indexOf(CurrentJobDetails.SiteDetails.value.value?.toString()) > -1), "label", "asc") : []
                            }
                        />
                        {ShowAddNewUserSection &&
                            <NewUserDetails {...this.props} />
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Select
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            value={CurrentJobDetails.ClientUsers.value}
                            onChange={(newval) => {
                                this.props.changeClientUsers({
                                    ClientUsers: newval
                                });
                            }}
                            options={CurrentJobDetails.SiteDetails.value ?
                                CommonUtility.sortByColumn(RequesterOptions.filter(requester => 
                                    requester.designation === COMMON_CONSTANTS.SITE_USER_ROLE &&
                                    requester.siteIds?.split(",").indexOf(CurrentJobDetails.SiteDetails.value.value?.toString()) > -1), "label", "asc") : []
                            }
                        />
                    </div>
                </div>

            </div >

        )
    }
}
