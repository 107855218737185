import { IBreadcrumbItem } from "office-ui-fabric-react";
import { IRoleData, IRoleLicenseConfig } from "../Interfaces/IRole";
import { ISelect } from "./../../../../CommonInterfaces/CommoInterfaces";

export const RoleInitialState = {
  Roles: [] as IRoleData[],
  LoadingRoleDashboard: true,
  LoadingRoleAddEditPanel: false,
  HasMoreRoles: false,
  SelectedRole: null,
  CurrentRole: {
    acronymCode: "",
    attribute_form_id: -1,
    description: null,
    id: -1,
    name: "",
    reference: "",
    type: 0,
    attribute_form: null as ISelect,
  } as IRoleData,
  TabIndex: 0,
  hideConfirm: true,
  offset: 0,
  limit: 15,
  filters: {},
  TotalRolesCount: 0,
  alertJSON: {
    hideAlert: true,
    alertHeader: "",
    alertSubText: "",
  },
  sortedColumn: "",
  sortOrder: "asc",
  ToBeUpdatedStatus: -1,
  PanelHeaderText: "",
  customer_name_filter_value: "",
  ShowRoleAddEditPanel: false,
  role_name_filter_value: "",
  FormAttributeOptions: [] as ISelect[],
  clients: [] as IRoleLicenseConfig[],
  RoleBreadCrumb: [
    { text: 'Admin', key: 'Admin' },
    { text: 'Roles', key: 'Roles' }
  ] as IBreadcrumbItem[],
  searchedClient: ""
};
