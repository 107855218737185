import React, { PureComponent } from 'react'
import { TextField } from 'office-ui-fabric-react'

export default class Description extends PureComponent {
    render() {

        let { CurrentDivision } = this.props.Division;

        console.log("division name : " + CurrentDivision.name);
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    {/*<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label className="breadcrumb-label"> Division Description </Label>
        </div>*/}
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField

                            value={CurrentDivision.name}
                            label="Name"
                            onChange={(event, newValue) => {
                                this.props.changeDivisionDescription({
                                    field: "name",
                                    name: newValue
                                });
                            }}
                            required
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            value={CurrentDivision.acronym_code}
                            label="Code"
                            onChange={(event, newValue) => {
                                this.props.changeDivisionDescription({
                                    field: "acronymCode",
                                    acronym_code: newValue
                                });
                            }}
                        />
                    </div>
                </div>
                { /* <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            value={CurrentDivision.description}
                            label="Description"
                            onChange={(event, newValue) => {
                                this.props.changeDivisionDescription({
                                    field: "description",
                                    description: newValue
                                });
                            }}
                            multiline
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            multiline
                            value={CurrentDivision.displayName}
                            onChange={(event, newValue) => {
                                this.props.changeDivisionDescription({
                                    field: "displayName",
                                    displayName: newValue
                                });
                            }}
                            label="Display Name"
                        />
                    </div>
                </div>*/}

            </div>
        )
    }
}
