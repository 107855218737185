import { process } from "@progress/kendo-data-query";
import { ILooseGear } from "../Interfaces/ILooseGear";
import moment from 'moment-timezone';

export class LooseGearUtility {

    public processLooseGearsForKendoGrid = (looseGears: ILooseGear[], kendoGridDataState: any): any => {

        let processedLooseGears: any = process([...looseGears], { ...kendoGridDataState });
        return processedLooseGears;
    }

    public getDateRangeStr = (filterFromDate: Date, filterToDate: Date): string => {
        const fromDateStr =
            `${moment(filterFromDate).format('YYYY-MM-DD')} 00:00:00`;
        const toDateStr =
            `${moment(filterToDate).format('YYYY-MM-DD')} 23:59:59`;

        const finalDate = `${fromDateStr}#${toDateStr}`;

        return finalDate;
    }

}
