export const TaskInitialState = {
    Tasks: [],
    LoadingTaskDashboard: false,
    LoadingTaskPreviewTab: false,
    LoadingTaskPreviewTabPDF: false,
    LoadingInspectTab: false,
    isLoadingAttachAssetTab: false,
    LoadingFormSection: false,
    LoadingQuestionSection: false,
    ShowItemList: false,
    TotalTasksCount: 0,
    HasMoreTasks: true,
    ShowTaskDetailsTab: false,
    isShowTaskCeritificateBrowser: false,
    SelectedTask: null,
    SelectedLLGTask: null,
    TabIndex: 0,
    LLGTabIndex: 0,
    selectedClientFilter: null,
    selectedSiteFilter: null,
    ShowActiveTasks: true,
    hideConfirm: true,
    offset: 0,
    limit: 500,
    filters: {},
    task_status_filter_value: "",
    task_site_filter_value: "",
    task_client_filter_value: "",
    task_job_number_filter_value: "",
    task_asset_type_filter_value: "",
    task_certificate_number_filter_value: "",
    task_asset_filter_value: "",
    task_quotation_number_filter_value: "",
    sortedColumn: "",
    sortOrder: "asc",
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    ShowPDF: false,
    PDFURL: "",
    ToBeUpdatedStatus: -1,
    isLLGDelete: false,
    TaskBreadCrumb: [
        { text: 'OPERATIONS', key: 'Operations' },
        { text: 'Tasks', key: 'TASK' }
    ],
    ChildLLGTasks: [],
    Inspectors: [],
    SelectedTaskNewInspector: null,
    isLoadingChildLLGTasks: false,
    isLoadingJobDetailsTab: false,
    SelectedChildLLGTask: null,
    LLGTaskSearchFilterText: "",
    isShowLLGTasksOnly: false,
    showTimeClock: false,
    CurrentTaskChecklistData: null,
    SelectedCheckListSection: null,
    SelectedCheckListItem: null,
    CurrentChecklistFormData: null,
    isInspectTab: false,
    showColumns: [
        'jobNumber',
        'location',
        'assetType',
        'taskType',
        'client',
        'site',
        'inspector',
        'certificateNumber',
        'actualInspectionDate',
        'quotationNumber',
        'modifiedDate',
        'endUserName',
        'endUserAddress'
    ],
    CurrentSectionForms: [],
    CurrentSelectedForm: null,
    CurrentFormOtherData: null,
    FormFiles: [],
    AssetList: [],
    newAttachAssetDetails: {
        value: "",
        error: true,
        errorMessage: ""
    },
    searchedAssetName: "",
    searchedAssetType: "",
    searchedAssetClientName: "",
    searchedAssetSiteName: "",
    hideAttachAssetConfirm: true,
    selectedAsset: null,
    isShiftAndAttach: false,
    isLoadingJobFiles: false,
    uploadFilesErrorText: "",
    isOpenCreateChildtaskPanel: false,
    isLoadingCreateChildTaskPanel: false,
    selectedTaskType: null,
    TaskTypesOptions: [],
    ChildLLGTaskSearchFilterText: "",
    hideConfirmDeleteChildLLGTask: true,
    SelectedLLGTaskToBeDeleted: null
}