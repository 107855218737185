import React, { PureComponent } from 'react';
import AddEditAssetDetails from '../../Assets/Views/AddEditAssetDetails';
// import AssetDescription from '../../Assets/Views/AssetDescription';
// import AddEditAssetPanel from './../../Assets/Views/AddEditAssetPanel'
// import { Label } from 'office-ui-fabric-react'

export default class AssetDetails extends PureComponent {
    render() {
        return (
            <AddEditAssetDetails
                {...this.props}
            />
        )
    }
}
