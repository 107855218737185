import axios, { AxiosError, AxiosResponse } from "axios";
import { ILoggedInUser } from "../../../CommonInterfaces/CommoInterfaces";
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import { IClientInventory, IClientInventoryAPI } from "../ClientInventoryInteface/IClientInventory";
import * as CONSTANTS from './../Constants/ClientInventoryConstants';

export class ClientInventoryAPI implements IClientInventoryAPI {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUserDetails: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUserDetails;
    }

    public loadClientInventoryAPI = (clientId: number, siteId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_ENTITY_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`clientId=${clientId}&siteId=${siteId}`)
            ).then((response: AxiosResponse<any>) => {
                resolve(response);
            }).catch((error: AxiosError<any>) => {
                console.error("Error loadClientInventoryAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public deleteClientInventoryByIdAPI = (clientInventoryId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(CONSTANTS.DELETE_ENTITY_URL)
                .concat(`${clientInventoryId}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then((response: AxiosResponse<any>) => {
                resolve(response);
            }).catch((error: AxiosError<any>) => {
                console.error("Error deleteClientInventoryByIdAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public saveClientInventory = (clientInventory: IClientInventory[]): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(CONSTANTS.SAVE_ENTITY_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`),
                clientInventory
            ).then((response: AxiosResponse<any>) => {
                resolve(response);
            }).catch((error: AxiosError<any>) => {
                console.error("Error saveClientInventory method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

}