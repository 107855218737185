import { IClientInventory, IClientInventoryInitialState } from "../ClientInventoryInteface/IClientInventory";

export const ClientInventoryInitialState: IClientInventoryInitialState = {
    clientInventories: [] as IClientInventory[],
    isLoadingClientInventory: true,
    searchedItem: "",
    searchedDescription: "",
    searchedQuantity: "",
    searchedTonne: "",
    searchedLocation:"",
    searchedManufacturer:"",
    searchedComments:"",
    isOpenDeleteConfirmModal: false,
    selectedClientInventory: null,
    alertJSON: {
        alertHeader: "",
        alertSubText: "",
        hideAlert: true
    }
}