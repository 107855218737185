import * as CONSTANTS from "../Constants/LoOfficeConstants";
import { ILoOfficePayLoad, ILoOfficeLocation } from "../LoOfficeInterface/ILoOffice";
import { Dispatch } from "redux";
import { LoOfficeInitialState } from "../Constants/LoOfficeInitialState";
//import { LoOfficeUtility } from "../Utility/LoOfficeUtility";

export class LoOfficeActions {

    resetLoOfficeBreadCrumb = (payload) => {
        return { type: CONSTANTS.RESET_LO_OFFICE_BREADCRUMB, payload }
    }

    // Lo Office Dashboard
    public cleanLoOfficeDashboard = (payload: ILoOfficePayLoad) => {
        return dispatch => {
            dispatch(this.cleanLoOfficeDashboardAsync(payload));
            dispatch(this.resetLoOfficeBreadCrumb(payload));
            payload.SelectedLoOfficeLocation = null;
            payload.ShowLoOfficeDetailsTab = false;
            dispatch(this.showLoOfficeDetailsTab(payload));
        }
    }

    public cleanLoOfficeDashboardAsync = (payload: ILoOfficePayLoad) => {
        return { type: CONSTANTS.CLEAN_LO_OFFICE_DASHBOARD, payload }
    }

    public loadLoOfficeDashboard = (payload: ILoOfficePayLoad): any => {
        let objectType: string = "LO_OFFICE";
        return (dispatch: any) => {
            payload.IsOngoingLoad = true;
            dispatch(this.cleanLoOfficeDashboard(payload));
            if (payload.filtered) {
                dispatch(this.changeLoOfficeFilterValue(payload));
            }
            else {
                dispatch(this.loadLoOfficeDashBoardAsync(payload));
            }
            payload.apiObject.loadInitialData(payload.offset, payload.limit, objectType, payload.config).then((response) => {
                payload.IsOngoingLoad = false;
                payload.LoOfficeLocations = response[0].data;
                dispatch(this.loadLoOfficeDashBoardAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Oops!",
                    alertSubText: "Error while loading LO Office"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public showHideAddEditLoOfficePopUp = (payload: ILoOfficePayLoad) => {
        return { type: CONSTANTS.SHOW_HIDE_ADD_EDIT_LO_OFFICE_POP_UP, payload };
    }

    public loadLoOfficeDashBoardAsync = (payload: ILoOfficePayLoad) => {
        return { type: CONSTANTS.LOAD_LO_OFFICE_DASHBOARD, payload }
    }

    public showLoOfficeDetailsTab = (payload) => {
        return { type: CONSTANTS.SHOW_LO_OFFICE_DETAILS_TAB, payload };
    }

    public openCloseLoOfficeConfirmModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_LO_OFFICE_CONFIRM_MODAL, payload };
    }

    public openCloseAlertModal = (payload: ILoOfficePayLoad) => {
        return { type: CONSTANTS.OPEN_CLOSE_LO_OFFICE_ALERT_MODAL, payload };
    }

    public changeLoOfficeFilterValue = (payload: ILoOfficePayLoad) => {
        switch (payload.filterType) {
            case "name":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_NAME_FILTER_VALUE, payload };
            case "code":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_CODE_FILTER_VALUE, payload };
            default:
                break;
        }
    }

    public showHideLoOfficeAddEditLocationPopUp = (payload: ILoOfficePayLoad) => {
        return { type: CONSTANTS.SHOW_HIDE_LO_OFFICE_ADD_EDIT_LOCATION_POP_UP, payload }
    }

    public changeLoOfficeDetails = (payload: ILoOfficePayLoad) => {
        switch (payload.loOfficeFieldType) {
            case "LoOfficeName":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_NAME, payload };
            case "LoOfficeStreet":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_STREET, payload };
            case "LoOfficeCity":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_CITY, payload };
            case "LoOfficeState":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_STATE, payload };
            case "LoOfficeCountry":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_COUNTRY, payload };
            case "LoOfficePin":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_PIN, payload };
            case "LoOfficePhone":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_PHONE, payload };
            case "LoOfficeCode":
                return { type: CONSTANTS.CHANGE_LO_OFFICE_CODE, payload };
            case "LoOfficeExternalId":
                return { type: CONSTANTS.CHANGE_LO_EXTERNAL_ID, payload };

            default:
                break;
        }
    }


    public saveLoOfficeLocationAsync = (payload: ILoOfficePayLoad) => {

        let SelectedLoOfficeLocation: ILoOfficeLocation = payload.SelectedLoOfficeLocation;

        SelectedLoOfficeLocation.objectType = "LO_OFFICE";

        SelectedLoOfficeLocation.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        SelectedLoOfficeLocation.locationLevel = 0;

        return (dispatch: Dispatch) => {

            payload.IsOngoingLoad = true;

            dispatch(this.saveLoOfficeLocation(payload));

            //let utility = new LoOfficeUtility();
            //let loOfficePayload = utility.generateLoOfficeJSONPayLoad(payload.CurrentLoOffice);

            payload.apiObject.saveLoOfficeLocation(payload.SelectedLoOfficeLocation).then(() => {
                payload.IsOngoingLoad = false;
                dispatch(this.saveLoOfficeLocation(payload));
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: "Location saved successfully"
                }
                dispatch(this.openCloseAlertModal(payload));
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    dispatch(this.cleanLoOfficeDashboardAsync(payload));
                    dispatch(this.resetLoOfficeBreadCrumb(payload));
                    payload.SelectedLoOfficeLocation = LoOfficeInitialState.SelectedLoOfficeLocation;
                    payload.ShowLoOfficeDetailsTab = false;
                    dispatch(this.showLoOfficeDetailsTab(payload));
                    dispatch(this.showHideLoOfficeAddEditLocationPopUp(payload));
                    dispatch(this.loadLoOfficeDashboard(payload));
                }, 2000);
               
            }).catch((error:string)=>{
                payload.IsOngoingLoad = false;
                dispatch(this.saveLoOfficeLocation(payload));
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Error!!",
                    alertSubText: error
                }
                dispatch(this.openCloseAlertModal(payload));
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            });
        }
    }

    public saveLoOfficeLocation = (payload: ILoOfficePayLoad) => {
        return { type: CONSTANTS.SAVE_LO_OFFICE_LOCATION, payload };
    }

    deleteLoOfficeLocation = (payload) => {
        return { type: CONSTANTS.DELETE_LO_OFFICE_LOCATION, payload };
    }
    
    deleteLoOfficeLocationAsync = (payload) => {
        return dispatch => {
            payload.isOngoingDelete = true;
            dispatch(this.deleteLoOfficeLocation(payload));
    
            payload.apiObject.deleteLoOfficeLocation(payload.CurrentLoOffice).then((response) => {
                payload.isOngoingDelete = false;
                payload.CurrentLoOffice = response.data;
                dispatch(this.deleteLoOfficeLocation(payload))
                payload.hideAlert = false;
                payload.alertHeader = "Success !!";
                payload.alertSubText = "Selected Lo Office Deleted successfully";
                dispatch(this.openCloseAlertModal(payload));

                dispatch(this.cleanLoOfficeDashboardAsync(payload));
                dispatch(this.resetLoOfficeBreadCrumb(payload));
                payload.SelectedLoOfficeLocation = LoOfficeInitialState.SelectedLoOfficeLocation;
                payload.ShowLoOfficeDetailsTab = false;
                dispatch(this.showLoOfficeDetailsTab(payload));
                dispatch(this.showHideLoOfficeAddEditLocationPopUp(payload));
                dispatch(this.loadLoOfficeDashboard(payload));
    
                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));

                }, 2000);
    
            }).catch((error) => {
                console.error("Error: ", error);
    
                payload.hideAlert = false;
                payload.alertHeader = "Error !!";
                payload.alertSubText = "Error while deleting Lo Office";
                dispatch(this.openCloseAlertModal(payload));
    
                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2500);
    
            })
        }
    }
    
}