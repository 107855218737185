import { Dispatch } from "redux"
import { IClientSiteSelectorStateProps } from "../ClientSiteSelectorInteface/IClientSiteSelectorStateProps";
import { IClientSiteSelectorActionKeys, IClientSiteSelectorPayload } from "../ClientSiteSelectorInteface/IClientSiteSelector";
import * as CONSTANTS from './../Constants/ClientSiteSelectorConstants';

export class ClientSiteSelectorActions implements IClientSiteSelectorStateProps {

    public resetSelectors = (payload: IClientSiteSelectorPayload): IClientSiteSelectorActionKeys => {
        return { type: CONSTANTS.RESET_SELECTORS, payload }
    }

    public loadClientSelectorAsync = (payload: IClientSiteSelectorPayload): IClientSiteSelectorActionKeys => {
        return { type: CONSTANTS.LOAD_CLIENT_SELECTOR, payload }
    }

    public loadClientSelector = (payload: IClientSiteSelectorPayload): any => {
        return (dispatch: Dispatch) => {
            payload.isOngoingLoad = true;
            dispatch(this.resetSelectors(payload));
            dispatch(this.loadClientSelectorAsync(payload));
            payload.apiObject.loadClientsAPI(payload.parentId).then((response) => {
                payload.isOngoingLoad = false;
                payload.locations = response.data;
                dispatch(this.loadClientSelectorAsync(payload));
            }).catch((error: any) => {
                console.error("Error while loading client locations: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading client locations"
                }
                dispatch(this.openCloseClientSiteSelectorAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseClientSiteSelectorAlertModal(payload));
                }, 2000);
            })
        }
    }

    public loadSiteSelectorAsync = (payload: IClientSiteSelectorPayload): IClientSiteSelectorActionKeys => {
        return { type: CONSTANTS.LOAD_SITE_SELECTOR, payload }
    }

    public changeClientSelector = (payload: IClientSiteSelectorPayload): any => {
        return (dispatch: Dispatch) => {
            payload.isOngoingLoad = true;
            dispatch(this.loadSiteSelectorAsync(payload));
            payload.apiObject.loadSitesAPI(payload.parentId).then((response) => {
                payload.isOngoingLoad = false;
                payload.locations = response.data;
                dispatch(this.loadSiteSelectorAsync(payload));
            }).catch((error: any) => {
                console.error("Error while loading site locations: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading site locations"
                }
                dispatch(this.openCloseClientSiteSelectorAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseClientSiteSelectorAlertModal(payload));
                }, 2000);
            })
        }
    }

    public changSiteSelector = (payload: IClientSiteSelectorPayload): IClientSiteSelectorActionKeys => {
        return { type: CONSTANTS.CHANGE_SITE_SELECTOR, payload }
    }
    
    public openCloseClientSiteSelectorAlertModal = (payload: IClientSiteSelectorPayload): IClientSiteSelectorActionKeys => {
        return { type: CONSTANTS.OPEN_CLOSE_CLIENT_SITE_SELECTOR_ALERT_MODAL, payload };
    }

}
