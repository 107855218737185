import React, { PureComponent } from 'react'
import { ActionButton, Checkbox, ChoiceGroup, Label, SearchBox, Separator, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react'
import AlertRedux from '../../../Alert/AlertRedux';
import { ROLE_TYPE } from '../Constants/RoleConstants';
import { IconConstants, seperatorTheme } from '../../../../Constants/StylesConstants';
import { CONFIG_ADMIN_ROLE } from '../../../../Constants/CommonConstants';
import { RoleAPI } from '../Services/RoleAPI';

export default class RoleDescription extends PureComponent {

    _roleAPI = null;
    constructor(props) {
        super(props);
        this._roleAPI = new RoleAPI(window.API_URL, window.loggedInUserDetails);
    }

    render() {

        const { CurrentRole, alertJSON, clients, LoadingRoleAddEditPanel, searchedClient } = this.props.Role;
        // eslint-disable-next-line
        let obj;

        const ROLE_OPTIONS = Object.entries(ROLE_TYPE).map((item) => obj = { "key": parseInt(item[0]), "text": item[1] });
        const loggedInUserRole = window.loggedInUserDetails.role;

        return (
            <>
                {LoadingRoleAddEditPanel ?
                    <Spinner
                        size={SpinnerSize.large}
                        label="Please wait..."
                    /> :
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <TextField
                                    value={CurrentRole.name}
                                    label="Name"
                                    onChange={(event, newValue) => {
                                        this.props.changeRoleDescription({
                                            field: "name",
                                            name: newValue
                                        });
                                    }}
                                    required
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <TextField
                                    value={CurrentRole.acronymCode}
                                    label="Code"
                                    onChange={(event, newValue) => {
                                        this.props.changeRoleDescription({
                                            field: "acronymCode",
                                            acronymCode: newValue
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            {CurrentRole.id > -1 ?
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        value={ROLE_TYPE[CurrentRole.type]}
                                        label="Type"
                                        readOnly
                                    />
                                </div>
                                :
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 action-span">
                                    <ChoiceGroup
                                        selectedKey={CurrentRole.type}
                                        options={ROLE_OPTIONS}
                                        onChange={(ev, val) => {
                                            this.props.changeRoleDescription({
                                                field: "type",
                                                type: parseInt(val.key)
                                            })
                                        }}
                                        label="Role Type:"
                                        required
                                    />
                                </div>
                            }
                        </div>
                        {loggedInUserRole === CONFIG_ADMIN_ROLE && CurrentRole.type === 0 && CurrentRole.id > -1 &&
                            <>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                        <Separator theme={seperatorTheme}>Clients</Separator>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                        <Separator theme={seperatorTheme}>Number of Licenses</Separator>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                        <Separator theme={seperatorTheme}>Activate Users</Separator>
                                    </div>
                                </div>
                                <div className="client-license-section">
                                    {clients?.length &&
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <SearchBox
                                                    underlined
                                                    value={searchedClient}
                                                    placeholder={`Search Client`}
                                                    onChange={(event, newValue) => {
                                                        this.props.changeSearchedClient({
                                                            field: "description",
                                                            searchedClient: newValue,
                                                        });
                                                    }}
                                                    onClear={() => {
                                                        this.props.changeSearchedClient({
                                                            searchedClient: "",
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {clients ?
                                        // eslint-disable-next-line
                                        clients.map((client, index) => {
                                            if (searchedClient && client.clientName.toUpperCase().indexOf(searchedClient.toUpperCase()) > -1) {
                                                return (
                                                    <div className="ms-Grid-row" key={index}>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                                            <Label>{client.clientName}</Label>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                                            <TextField
                                                                underlined
                                                                value={client.clientLicense}
                                                                type='Number'
                                                                min={-1}
                                                                onChange={(event, newValue) => {
                                                                    this.props.changeRoleDescription({
                                                                        field: "license",
                                                                        license: parseInt(newValue),
                                                                        client
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                                            <Checkbox
                                                                styles={{
                                                                    root: {
                                                                        justifyContent: 'center', margin: "5%"
                                                                    }
                                                                }}
                                                                checked={client.isAllUserActive}
                                                                type='Number'
                                                                min={-1}
                                                                onChange={(event, checked) => {
                                                                    this.props.changeRoleDescription({
                                                                        field: "isAllUserActive",
                                                                        client,
                                                                        checked
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else if (!searchedClient) {
                                                return (
                                                    <div className="ms-Grid-row" key={index}>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                                            <Label>{client.clientName}</Label>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                                            <TextField
                                                                underlined
                                                                value={client.clientLicense}
                                                                type='Number'
                                                                min={-1}
                                                                onChange={(event, newValue) => {
                                                                    this.props.changeRoleDescription({
                                                                        field: "license",
                                                                        license: parseInt(newValue),
                                                                        client
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                                            <Checkbox
                                                                styles={{
                                                                    root: {
                                                                        justifyContent: 'center', margin: "5%"
                                                                    }
                                                                }}
                                                                checked={client.isAllUserActive}
                                                                type='Number'
                                                                min={-1}
                                                                onChange={(event, checked) => {
                                                                    this.props.changeRoleDescription({
                                                                        field: "isAllUserActive",
                                                                        client,
                                                                        checked
                                                                    });
                                                                }}
                                                            />

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }) :
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ActionButton
                                                    text="Create License Config"
                                                    iconProps={IconConstants.configIcon}
                                                    onClick={() => {
                                                        this.props.saveRoleDetailsAsync({
                                                            CurrentRole: CurrentRole,
                                                            apiObject: this._roleAPI,
                                                            clients,
                                                            isNewConfig: true
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {
                            !alertJSON.hideAlert &&
                            <AlertRedux
                                {...this.props}
                            />
                        }
                    </div>
                }
            </>
        )
    }
}
