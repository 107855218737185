import { createStore, applyMiddleware } from 'redux';
import { RootReducer } from '../RootReducer/RootReducer';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { MODE } from '../Constants/MessageConstants';

const IslogRequired =
    window.location.host.indexOf("localhost:") > -1
        ?
        MODE.DEV : MODE.PROD;

// const QwikSpecLogger = (store) => (next) => (action) => {
//     console.log("Prev State: ", store.getState());
//     console.log("Action: ", action);
//     next(action);
// }

export const store = createStore(
    RootReducer,
    IslogRequired ?
        applyMiddleware(thunk, logger)
        :
        applyMiddleware(thunk)
);

// store.subscribe(() => {
//     console.log("Next State: ", store.getState());
// })