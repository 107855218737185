import { UserInitialState } from "../Constants/UserInitialState";
import * as USER_CONSTANTS from './../Constants/UserConstants';
import { UserUtility } from "../Utility/UserUtility";

export function UserReducer(state = UserInitialState, action) {

    let tempState = { ...state };
    let payload = action.payload;
    let userUtility = new UserUtility();
    let CurrentUser = { ...tempState.CurrentUser };

    switch (action.type) {

        case USER_CONSTANTS.CLEAN_USER_DASHBOARD:
            if (!(payload.filtered)) {
                tempState.Users = [];
            }
            tempState.offset = 0;
            tempState.loadingDashboard = payload.loadingDashboard;
            tempState.user_status_filter_value = "";
            tempState.user_name_filter_value = "";
            tempState.user_email_filter_value = "";
            tempState.user_location_filter_value = "";
            tempState.user_designation_filter_value = "";
            break;

        case USER_CONSTANTS.LOAD_USER_DASHBOARD:
            tempState.loadingDashboard = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {

                if (tempState.user_status_filter_value === 1 || tempState.user_status_filter_value === "") {
                    tempState.ShowActiveUsers = tempState.ShowActiveUsers || !payload.isScrolled;
                }
                else {
                    tempState.ShowActiveUsers = false;
                }
                tempState.Users = !payload.filtered ? tempState.Users.concat(payload.Users) : payload.Users;
                tempState.TotalUsersCount = payload.TotalUsersCount;
                tempState.HasMoreUsers = tempState.TotalUsersCount > tempState.Users.length;
                tempState.filters = payload.filters;
                tempState.offset = payload.offset + payload.limit;
            }
            break;

        case USER_CONSTANTS.FIX_USER_DASHBOARD:
            tempState.HasMoreUsers = payload.HasMoreUsers;
            break;

        case USER_CONSTANTS.SORT_USER_DASHBOARD:
            tempState.Users = payload.sortedUsers;
            tempState.sortedColumn = payload.sortedColumn;
            tempState.sortOrder = payload.sortOrder;
            break;

        case USER_CONSTANTS.DELETE_USER:
            tempState.loadingDashboard = !payload.isCompletedDelete;
            tempState.hideConfirm = true;
            break;

        case USER_CONSTANTS.OPEN_CLOSE_USER_CONFIRM_MODAL:
            tempState.CurrentUserId = payload.id;
            tempState.hideConfirm = payload.hideConfirm;
            tempState.ToBeUpdatedStatus = payload.ToBeUpdatedStatus;
            break;

        case USER_CONSTANTS.OPEN_CLOSE_USER_ALERT_MODAL:
            tempState.alertJSON = {
                hideAlert: payload.hideAlert,
                alertHeader: payload.alertHeader,
                alertSubText: payload.alertSubText
            }
            tempState.loadingDashboard = false;
            tempState.loadingAddEditUserPanel = false;
            break;

        case USER_CONSTANTS.OPEN_CLOSE_ADD_EDIT_USER_PANEL:
            tempState.CurrentUser = payload.SelectedUser;
            tempState.ShowPanel = payload.ShowPanel;
            tempState.PanelHeaderText = payload.PanelHeaderText;
            break;

        case USER_CONSTANTS.SWITCH_USER_VIEW:
            tempState.ShowActiveUsers = payload.ShowActiveUsers;
            break;

        case USER_CONSTANTS.CHANGE_USER_NAME:
            CurrentUser.name = {
                value: payload.name,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.CHANGE_USER_EMAIL:
            CurrentUser.email = {
                value: payload.email,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.CHANGE_USER_PHONE:
            CurrentUser.phone = {
                value: payload.phone,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.CHANGE_USER_DESCRIPTION:
            CurrentUser.description = {
                value: payload.description,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.CHANGE_USER_EXTERNAL_ID:
            CurrentUser.externalId = {
                value: payload.externalId,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.SAVE_USER:
            tempState.loadingAddEditUserPanel = false;
            tempState.ShowPanel = false;
            break;

        case USER_CONSTANTS.LOAD_ADD_EDIT_USER_PANEL:
            tempState.loadingAddEditUserPanel = true;
            break;

        case USER_CONSTANTS.LOAD_USER_LOCATIONS_DIVISIONS_ROLES:
            tempState.loadingAddEditUserPanel = false;
            // tempState.ClientOptions = userUtility.populateDropDownMenu(payload.Clients);
            // tempState.OfficeOptions = userUtility.populateDropDownMenu(payload.Offices);
            tempState.RoleOptions = userUtility.populateDropDownMenu(payload.Roles);
            tempState.DivisionOptions = userUtility.populateDropDownMenu(payload.Divisions);
            tempState.CurrentUser = userUtility.resetSelectedUser(
                payload.CurrentUser, tempState.CompanyOptions, tempState.OfficeOptions, tempState.SiteOptions,
                tempState.RoleOptions, tempState.DivisionOptions
            );
            break;


        case USER_CONSTANTS.CHANGE_USER_ROLE:
            CurrentUser.Designation = {
                value: payload.Designation,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.CHANGE_USER_PRIMARY_OFFICE:
            CurrentUser.PrimaryOffice = {
                value: payload.PrimaryOffice,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.CHANGE_USER_OTHER_OFFICES:
            CurrentUser.OtherOffices = {
                value: payload.OtherOffices,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.CHANGE_USER_CLIENT_SITES:
            CurrentUser.ClientSites = {
                value: payload.ClientSites,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.CHANGE_USER_DIVISIONS:
            CurrentUser.Divisions = {
                value: payload.Divisions,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.UPLOAD_USER_SIGNATURE:
            tempState.isLoadingUserSignature = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {
                CurrentUser.Signature = {
                    imageURL: payload.imgBlobKey ?
                        `${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${payload.imgBlobKey}`
                        : ``,
                    uploadImageErrorText: CurrentUser.Signature.uploadImageErrorText
                }
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.SHOW_HIDE_UPLOAD_IMAGE_ERROR_TEXT:
            CurrentUser.Signature = {
                imageURL: CurrentUser.Signature.imageURL,
                uploadImageErrorText: payload.errorText
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.DELETE_USER_SIGNATURE:
            tempState.isLoadingUserSignature = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {
                CurrentUser.Signature = {
                    imageURL: "",
                    uploadImageErrorText: "",
                    deleteImageErrorText: ""
                }
            }
            tempState.CurrentUser = CurrentUser;
            break;

        case USER_CONSTANTS.SHOW_HIDE_DELETE_IMAGE_ERROR_TEXT:
            CurrentUser.Signature = {
                imageURL: "",
                uploadImageErrorText: "",
                deleteImageErrorText: payload.deleteErrorText
            }
            tempState.CurrentUser = CurrentUser;
            break;

        //DASHBOARD FILTER start
        case USER_CONSTANTS.CHANGE_USER_NAME_FILTER_VALUE:
            tempState.loadingDashboard = true;
            tempState.user_name_filter_value = payload.value;
            break;

        case USER_CONSTANTS.CHANGE_USER_EMAIL_FILTER_VALUE:
            tempState.loadingDashboard = true;
            tempState.user_email_filter_value = payload.value;
            break;

        case USER_CONSTANTS.CHANGE_USER_ROLE_FILTER_VALUE:
            tempState.loadingDashboard = true;
            tempState.user_designation_filter_value = payload.value;
            break;

        case USER_CONSTANTS.CHANGE_USER_LOCATION_FILTER_VALUE:
            tempState.loadingDashboard = true;
            tempState.user_location_filter_value = payload.value;
            break;

        case USER_CONSTANTS.CHANGE_USER_DIVISION_FILTER_VALUE:
            tempState.loadingDashboard = true;
            tempState.user_division_filter_value = payload.value;
            break;

        case USER_CONSTANTS.CHANGE_USER_STATUS_FILTER_VALUE:
            tempState.loadingDashboard = true;
            tempState.ShowActiveUsers = (payload.value === 1 || payload.value === "");
            tempState.user_status_filter_value = payload.value;
            break;
        //DASHBOARD FILTER end

        case USER_CONSTANTS.CHANGE_USER_TYPE:
            CurrentUser.type = payload.type;

            CurrentUser.Company = {
                value: null,
                error: false,
                message: ""
            }
            CurrentUser.Designation = {
                value: null,
                error: false,
                message: ""
            }
            CurrentUser.Divisions = {
                value: null,
                error: false,
                message: ""
            }
            CurrentUser.ClientSites = {
                value: null,
                error: false,
                message: ""
            }
            CurrentUser.PrimaryOffice = {
                value: null,
                error: false,
                message: ""
            }
            CurrentUser.OtherOffices = {
                value: null,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            tempState.loadingCompanyData = payload.isOngoingLoad;
            break;

        case USER_CONSTANTS.CHANGE_USER_COMPANY:
            CurrentUser.Company = {
                value: payload.Company,
                error: false,
                message: ""
            }
            CurrentUser.ClientSites = {
                value: null,
                error: false,
                message: ""
            }
            CurrentUser.PrimaryOffice = {
                value: null,
                error: false,
                message: ""
            }
            CurrentUser.OtherOffices = {
                value: null,
                error: false,
                message: ""
            }
            tempState.CurrentUser = CurrentUser;
            tempState.loadingSiteOfficeData = payload.isOngoingLoad;
            break;

        case USER_CONSTANTS.LOAD_USER_PANEL_LOCATIONS:
            // tempState
            // if (payload.isOngoingLoad) {
            if (payload.objectType === "LO_COMPANY" || payload.objectType === "CLIENT_COMPANY") {
                if (!payload.isOngoingLoad) {
                    tempState.loadingCompanyData = payload.isOngoingLoad;
                    tempState.CompanyOptions = userUtility.populateDropDownMenu(payload.Locations);
                }
            }
            else if (payload.objectType === "SITE") {
                if (!payload.isOngoingLoad) {
                    tempState.loadingSiteOfficeData = payload.isOngoingLoad;
                    tempState.SiteOptions = userUtility.populateDropDownMenu(payload.Locations);
                }
            }
            else if (payload.objectType === "LO_OFFICE" || payload.objectType === "CLIENT_OFFICE") {
                if (!payload.isOngoingLoad) {
                    tempState.loadingSiteOfficeData = payload.isOngoingLoad;
                    tempState.OfficeOptions = userUtility.populateDropDownMenu(payload.Locations);
                }
            }
            // }

            break;

        default:
            break;
    }

    return tempState;

}