import {
    GET_USERS_URL,
    SAVE_SCHEDULE,
    GET_ASSET_URL,
    SAVE_ALL_OBSERVATIONS,
    GET_LOCATION_OBJECT_URL,
    GET_SETTING_OBJECTS,
    SAVE_LOCATION,
    SAVE_LOCATION_URL, SAVE_USER_URL, SEND_NOTIFICATION, GET_CONFIG_JSON_SETTING, SEND_NOTIFICATION_FOR_REASSIGNED_JOB, SAVE_FILES_URL
} from "../Constants/JobConstants";
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import axios from 'axios';

export class JobAPI {

    _URL = "";
    _config = "";

    constructor(URL, config) {
        this._URL = URL;
        this._config = config;
    }

    _getClientsData(objectType) {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_LOCATION_OBJECT_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?objectType=${objectType}`)
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error _getClientsData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    _getUsersData() {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_USERS_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?offset=-1&limit=-1`)
                , this._config
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error _getUsersData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    _getAllAssetType() {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat('Setting/Others').concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?objectType=AssetType`)).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getAllAssetType", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    // For New thing

    _getAllSettings(objectType, id) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(GET_SETTING_OBJECTS)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?objectType=${objectType}`)
                .concat(id ? `&taskTypeId=${id}` : ``)
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getAllDivisions", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    _getConfigJsonMetaData() {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(GET_CONFIG_JSON_SETTING)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?metaKey=config_json&name=webUIAccessConfiguration`)
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getConfigJsonMetaData", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    // _getAllTaskType() {
    //     return new Promise((resolve, reject) => {
    //         axios.get(this._URL.concat('Setting/Others').concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
    //             .concat(`?objectType=TaskType`)).then(response => {
    //                 resolve(response);
    //             }).catch(error => {
    //                 console.error("Error _getAllTaskType", error.response);
    //                 if (error.response && error.response.status === 401) {
    //                     reject(ALERT_MESSAGE.ERROR_401);
    //                 }
    //                 else {
    //                     reject(ALERT_MESSAGE.COMMON_ERROR);
    //                 }
    //             })
    //     })
    // }

    getSitesData(companyId) {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_LOCATION_OBJECT_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?objectType=SITE`)
                    .concat(`&companyId=${companyId}`)
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error getSitesData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }


    _getLocationObjects(objectType, companyId) {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_LOCATION_OBJECT_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?objectType=${objectType}`)
                    .concat(companyId ? `&companyId=${companyId}` : ``)
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error _getClientsData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    loadInitData() {
        let promises = [
            this._getLocationObjects("CLIENT_COMPANY"),
            this._getLocationObjects("LO_OFFICE"),
            this._getUsersData(),
            this._getAllSettings("Division"),
            this._getAllSettings("TaskType"),
            this._getAllSettings("ROLE"),
            this._getAllSettings("AssetTypes"),
            this._getConfigJsonMetaData(),
        ];
        return Promise.all(promises);

    }

    // For New thing ( end )

    saveJobAPI(payload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SAVE_SCHEDULE).concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), payload).then(responseData => {
                resolve(responseData);
            }).catch(error => {
                console.error("Error saveJobAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 417) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    sendNotification(payload, messageType) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SEND_NOTIFICATION)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(messageType ? `?messageType=${messageType}` : ``)
                , payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error sendNotification method", error);
                });
        });
    }

    sendNotificationForReassignedJob(payload, scheduleId, inspectorId) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SEND_NOTIFICATION_FOR_REASSIGNED_JOB)
                .concat(`/${scheduleId}/${inspectorId}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                , payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error sendNotification method", error);
                });
        });
    }

    saveTasks(payload, cancelFlag, scheduleId) {
        return new Promise((resolve, reject) => {
            axios.post(
                this._URL
                    .concat(SAVE_ALL_OBSERVATIONS)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?cancelFlag=${cancelFlag ? -1 : 1}`)
                    .concat(`&scheduleId=${scheduleId}`)
                , payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error saveTasks method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    saveAsset(payload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SAVE_LOCATION)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error saveAsset method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    if (error.response && error.response.status === 409) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    getAssetsData(assetTypeId) {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_ASSET_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?assetTypeId=${assetTypeId}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAssetsData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    // Load Jobs Start

    _getJobsCount(config) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(`Schedule/count`).concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), config).then(response => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getJobsCount method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    _getChunkJobs(offset, limit, config) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(`Schedule`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?offset=${offset}&limit=${limit}`), config
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getChunkJobs method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    _getTasksData(scheduleId) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(`Observation/Schedule`).concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?scheduleId=${scheduleId}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getTasksData method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }



    getJobDetails(offset, limit, config) {
        let promises = [this._getChunkJobs(offset, limit, config), this._getJobsCount(config)];
        return Promise.all(promises);

    }

    getSitesAndTasks(clientId, scheduleId) {
        let promises = [this.getSitesData(clientId), this._getTasksData(scheduleId)];
        return Promise.all(promises);

    }

    getAllAssetOptions(assetTypeIds) {
        let promises = [];
        assetTypeIds.forEach((assetTypeId) => {
            promises.push(this.getAssetsData(assetTypeId));
        });
        return Promise.all(promises);

    }
    // Load Jobs End

    saveLocation(locationPayload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(SAVE_LOCATION_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                , locationPayload
            ).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.error("Error saveLocation method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    saveUser(userPayload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(SAVE_USER_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat("?isJob=true")
                , userPayload
            ).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.error("Error saveUser method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    _uploadJobFiles = (id, uploadedFiles) => {

        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${SAVE_FILES_URL}/${id}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``),
                uploadedFiles)
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error _uploadJobFiles method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }
}