import { ISelect } from "../../../../CommonInterfaces/CommoInterfaces";
import { IDocumentEntity } from "../../TrialFitPicLibrary/Interfaces/IDocumentEntity";
import { IProductTechInfoInitialState } from "../Interfaces/IProductTechInfoInitialState";

export const ProductTechInfoInitialState: IProductTechInfoInitialState = {
    documentEntities: [] as IDocumentEntity[],
    fileEntities: [] as IDocumentEntity[],
    isShowAddEditDocumentPanel: false,
    isLoadingDocumentsList: true,
    isLoadingAddEditDocumentPanel: false,
    panelHeaderText: "",
    searchedFolderString: "",
    searchedFileString: "",
    selectedFolder: null,
    selectedNewDocument: null,
    selectedDocument: {
        id: null,
        category: "",
        description: "",
        entityType: "",
        filePath: "",
        fileType: "",
        name: "",
        parentId: 0,
        clientId: null,
        siteId: null,
        profilePhoto: "",
        status: 1,
        tenantId: 0
    },
    alertJSON: {
        alertHeader: "",
        alertSubText: "",
        hideAlert: true
    },
    sectionflag: "",
    parentLibrary: null,
    isLoadingFileList: false,
    isShowFileSection: false,
    hideConfirmDelete: true,
    fileUploadErrorMessage: "",
    entityNameErrorMessage: "",
    deletedEntity: null,
    llgAssetTypes: [] as ISelect[]
}
