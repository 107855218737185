import { Label, PrimaryButton, Separator, Spinner, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { ISelect } from '../../../CommonInterfaces/CommoInterfaces';
import { IconConstants, seperatorTheme } from '../../../Constants/StylesConstants';
import { IJobLiteService } from '../Interfaces/IJobLitesService';
import { JobLitesAPI } from '../Services/JobLitesAPI';

export default class NewSiteDetails extends React.Component<any, any> {

    private _apiObject: IJobLiteService;
    constructor(props) {
        super(props);
        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails);
    }


    public render(): JSX.Element {

        let {
            SiteOptions,
            CurrentJobDetails,
            LoadingNewSiteSection,
            NewSite,
            NewSiteSaveStatus
        } = this.props.JobLites;

        return (
            <React.Fragment>
                {LoadingNewSiteSection ?
                    <Spinner label="Saving New Site..." />
                    :
                    <div className="ms-Grid add-extra-pane">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Separator theme={seperatorTheme}>Add New Site</Separator>

                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <small className="mandatory-span">
                                    {NewSiteSaveStatus.message}
                                </small>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    label="Site Name"
                                    required
                                    value={NewSite.name}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewSiteField({
                                            field: "name",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label>Site Address<span className="mandatory-span">*</span></Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                    label="House No. &amp; Street:"
                                    required
                                    value={NewSite.street}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewSiteField({
                                            field: "street",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                    label="City:"
                                    value={NewSite.city}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewSiteField({
                                            field: "city",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                    label="State:"
                                    value={NewSite.state}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewSiteField({
                                            field: "state",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                    label="Coutry:"
                                    value={NewSite.country}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewSiteField({
                                            field: "country",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                    label="Pin:"
                                    type="number"
                                    min={0}
                                    value={NewSite.pin}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewSiteField({
                                            field: "pin",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                    label="Phone:"
                                    value={NewSite.phone}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewSiteField({
                                            field: "phone",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    text="Save"
                                    iconProps={IconConstants.saveicon}
                                    disabled={
                                        !NewSite.name ||
                                        SiteOptions.filter((site: ISelect) => site.locationLevel === 0)
                                            .find((site: ISelect) => site.label === NewSite.name) !== undefined ||
                                        !NewSite.street
                                    }
                                    onClick={() => {
                                        this.props.saveNewSiteJobAsync({
                                            apiObject: this._apiObject,
                                            newLocation: NewSite,
                                            Location: CurrentJobDetails.ClientName ? CurrentJobDetails.ClientName.value : -1
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}
