import { CLIENT_USER_ROLE, SITE_USER_ROLE } from "../../../Constants/CommonConstants";
import { LooseGearInitialState } from "../Constants/LooseGearInitialState";
import { ILooseGearActionKeys } from "../Interfaces/ILooseGearActions";
import { ILooseGearPayload } from "../Interfaces/ILooseGearPayload";
import { ILooseGearStateProps } from "../Interfaces/ILooseGearStateProps";
import * as CONSTANTS from "./../Constants/LooseGearsConstants";

export class LooseGearActions implements ILooseGearStateProps {

    public cleanLooseGearDashboard = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.CLEAN_LOOSE_GEAR_DASHBOARD, payload }
    }

    public cleanLooseGearFilter = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.CLEAN_LOOSE_GEAR_FILTER, payload }
    }

    public loadClientsLooseGear = (payload: ILooseGearPayload): any => {
        payload.isLoading = true;
        return (dispatch: any) => {
            dispatch(this.cleanLooseGearDashboard(payload));
            payload.assetApi.loadClientsAPI().then((response: any) => {
                let currentUserRole: string = window.loggedInUserDetails.role;
                if ([SITE_USER_ROLE, CLIENT_USER_ROLE].indexOf(currentUserRole) > -1) {
                    payload.locationFilters = response.data.filter((data: any) =>
                        data.id === window.loggedInUserDetails.companyId)
                }
                else {
                    payload.locationFilters = response.data;
                }
                payload.isLoading = false;
                dispatch(this.loadClientsLooseGearAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Clients"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public showHideLLGDashboardColumns = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.SHOW_HIDE_LLG_DASHBOARD_COLUMNS, payload }
    }

    public loadClientsLooseGearAsync = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.LOAD_CLIENTS_LOOSE_GEAR, payload }
    }

    public loadSitesLooseGear = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.LOAD_SITES_LOOSE_GEAR, payload }
    }

    public loadSitesLooseGearAsync = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        throw new Error("Method not implemented.");
    }

    public changeLooseGearClientFilter = (payload: ILooseGearPayload): any => {
        payload.isLoading = true;
        return (dispatch: any) => {
            dispatch(this.changeLooseGearClientFilterAsync(payload));
            payload.assetApi.loadSitesAPI(payload.selectedLocation.value).then((response: any) => {
                payload.isLoading = false;
                let currentUserRole: string = window.loggedInUserDetails.role;
                if ([SITE_USER_ROLE, CLIENT_USER_ROLE].indexOf(currentUserRole) > -1) {
                    payload.locationFilters = response.data.filter((data: any) =>
                        window.loggedInUserDetails.siteIds.split(",").indexOf(data.id.toString()) > -1)
                }
                else {
                    payload.locationFilters = response.data;
                }
                dispatch(this.loadSitesLooseGear(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Sites"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public changeLooseGearClientFilterAsync = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.CHANGE_LOOSE_GEAR_CLIENT_FILTER, payload }
    }

    public changeLooseGearSiteFilter = (payload: ILooseGearPayload): any => {
        payload.isLoading = true;
        return (dispatch: any) => {
            if (payload.isReset) {
                dispatch(this.cleanLooseGearFilter(payload));
            }
            dispatch(this.changeLooseGearSiteFilterAsync(payload));
            payload.apiPayload.getLooseGearsApi(payload.offset, LooseGearInitialState.limit, payload.config).then((response: any) => {
                payload.isLoading = false;
                payload.looseGears = response[0].data;
                payload.looseGearsCount = response[1].data;
                dispatch(this.loadLooseGearDashboard(payload));
            }).catch((error) => {
                console.error("Error changeLooseGearSiteFilter: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Loose Gears"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public changeLooseGearSiteFilterAsync = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.CHANGE_LOOSE_GEAR_SITE_FILTER, payload }
    }

    public loadLooseGearDashboardAsync = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.CHANGE_LOOSE_GEAR_SITE_FILTER, payload }
    }

    public loadLooseGearDashboard = (payload: ILooseGearPayload): ILooseGearActionKeys => {
        return { type: CONSTANTS.LOAD_LOOSE_GEAR_DASHBOARD, payload }
    }

    public changeLooseGearDashboardFilter = (payload: ILooseGearPayload): any => {

        payload.isLoading = true;
        return (dispatch: any) => {
            dispatch(this.changeLooseGearDashboardFilterAsync(payload));
            if (!payload.isNoLoad) {
                payload.apiPayload.getLooseGearsApi(payload.offset, LooseGearInitialState.limit, payload.config).then((response: any) => {
                    payload.isLoading = false;
                    payload.looseGears = response[0].data;
                    payload.looseGearsCount = response[1].data;
                    dispatch(this.loadLooseGearDashboard(payload));
                }).catch((error) => {
                    console.error("Error changeLooseGearDashboardFilter: ", error);
                    payload.alertJSON = {
                        hideAlert: false,
                        alertHeader: "Ooops!!",
                        alertSubText: "Error while loading Loose Gears"
                    }
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.alertJSON = {
                            hideAlert: true,
                            alertHeader: "",
                            alertSubText: ""
                        }
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
            }
        }

    }

    public changeLooseGearDashboardFilterAsync = (payload: ILooseGearPayload): any => {
        return { type: CONSTANTS.CHANGE_LOOSE_GEAR_DASHBOARD_FILTER, payload };
    }


    public handleLooseGearGridGroupSortChanged?= (payload: ILooseGearPayload): ILooseGearActionKeys => {
        throw new Error("Method not implemented.");
    }

    public handleLooseGearGridFilterChanged?= (payload: ILooseGearPayload): ILooseGearActionKeys => {
        throw new Error("Method not implemented.");
    }

    public handleLooseGearGridFilterChangedAsync?= (payload: ILooseGearPayload): ILooseGearActionKeys => {
        throw new Error("Method not implemented.");
    }

    public handleLooseGearGridExpandChanged?= (payload: ILooseGearPayload): ILooseGearActionKeys => {
        throw new Error("Method not implemented.");
    }

    public showHideAddEditLooseGearNumberingPanel?= (payload: ILooseGearPayload): ILooseGearActionKeys => {
        throw new Error("Method not implemented.");
    }

    public saveLooseGearAsync?= (payload: ILooseGearPayload): ILooseGearActionKeys => {
        throw new Error("Method not implemented.");
    }

    public changeLooseGearNumber?= (payload: ILooseGearPayload): ILooseGearActionKeys => {
        throw new Error("Method not implemented.");
    }


    public openCloseAlertModal = (payload: ILooseGearPayload) => {
        return { type: CONSTANTS.OPEN_CLOSE_LOOSE_GEAR_ALERT_MODAL, payload };
    }


}
