import React, { Component } from 'react';
// import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Panel, PanelType } from 'office-ui-fabric-react';
import AddEditAssetDetails from './AddEditAssetDetails';
// import AssetAttributes from './AssetAttributes';
// import AssetHistory from './AssetHistory';
// import AssetFiles from './AssetFiles';
// import AssetReports from './AssetReports';
// import AssetDescription from './AssetDescription';

export default class AddEditAssetPanel extends Component {

    render() {

        let {
            isOpenAddEditAssetPanel,
        } = this.props.Asset;

        return (
            <Panel
                headerText={'ADD / EDIT ASSET'}
                isOpen={isOpenAddEditAssetPanel}
                type={PanelType.extraLarge}
                onDismiss={() =>
                    this.props.openCloseAddEditAssetPanel({
                        hideAddEditAssetPanel: true,
                        selectedAssetId: -1
                    })
                }
                styles={{
                    scrollableContent: {
                        height: "90vh"
                    }
                }}
                closeButtonAriaLabel="Close">

                <AddEditAssetDetails
                    {...this.props}
                />

            </Panel>
        )
    }
}
