import { QRStickerInitialState } from "../Constants/QRStickerInitialState";
import * as QRSTICKERS_CONSTANTS from './../Constants/QRStickerConstants';

export function QRStickerReducer(state = QRStickerInitialState, action) {
    let tempState = { ...state };
    let payload = action.payload;
    let CurrentQRSticker = { ...tempState.CurrentQRSticker };
    switch (action.type){
        case QRSTICKERS_CONSTANTS.CLEAN_QRSTICKER:
            return {
                ...state,
                CurrentQRSticker: {
                    size: {
                        value: null,
                        error: true,
                        message: ''
                    },
                    color: {
                        value: null,
                        error: true,
                        message: ''
                    },
                    quantity: {
                        value: null,
                        error: true,
                        message: ''
                    }
                }
            };
        case QRSTICKERS_CONSTANTS.CHANGE_QRSTICKER_SIZE:
            CurrentQRSticker.size = {
                value: payload.size,
                error: false,
                message: ""
            }
            tempState.CurrentQRSticker = CurrentQRSticker;
            break;
        case QRSTICKERS_CONSTANTS.CHANGE_QRSTICKER_QUANTITY:
            CurrentQRSticker.quantity = {
                value: payload.quantity,
                error: false,
                message: ""
            }
            tempState.CurrentQRSticker = CurrentQRSticker;
            break;
        case QRSTICKERS_CONSTANTS.CHANGE_QRSTICKER_COLOR:
            CurrentQRSticker.color = {
                value: payload.color,
                error: false,
                message: ""
            }
            tempState.CurrentQRSticker = CurrentQRSticker;
            break;
            case QRSTICKERS_CONSTANTS.REQUEST_PROCESSING:
                return {
                    ...state,
                    isRequestProcessing: action.payload
                };
                case QRSTICKERS_CONSTANTS.SHOW_SUCCESS_MESSAGE:
                    console.log("Reducer: SHOW_SUCCESS_MESSAGE payload:", action.payload);
                    return {
                        ...state,
                        successMessage: action.payload,
                        isRequestProcessing: false
                    };
                case QRSTICKERS_CONSTANTS.CLEAR_SUCCESS_MESSAGE:
                    return {
                        ...state,
                        successMessage: ''
                    };
        default:
            return state; // Ensure to return state in default case
    }
    return tempState; // Return the updated state
}
