import $ from "jquery";
import React, { Component, createRef } from 'react';
import { Label, ActionButton, Breadcrumb } from 'office-ui-fabric-react';
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import 'react-tabs/style/react-tabs.css';
import './../../../App.scss';
import { FormConfigAPIService } from './FormConfigAPIService';
import FormDetails from "./FormDetails.jsx";
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";
import { FormUtility } from "./FormUtility";
import Alert from "../../Alert/Alert.jsx";
import moment from "moment";
import { IconConstants, navStylesForm } from "../../../Constants/StylesConstants";
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { FORM_BREADCRUMBS } from "./FormConstants";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

export default class Forms extends Component {
    apiService = null;
    fb = null;
    fbEditor = null;
    formBuilder = null;
    apiService = null;
    constructor(props) {
        super(props);
        this.apiService = new FormConfigAPIService(window.API_URL);
        this.fb = createRef();
        this.fbEditor = null;
        this.formBuilder = null;
        this.state = {
            loading: true,
            newButtonDisabled: false,
            searchFieldDisabled: false,
            saveButtonDisabled: true,
            openPanelButtonDisabled: true,
            selectedForm: {},
            selectedKey: null,
            isOpenFormDetailsPanel: false,
            alertJSON: {
                hideAlert: true,
                alertHeader: "",
                alertSubText: "",
            },
            navLinkGroups: [
                {
                    links: [],
                },
            ],
            nameFilter: "",
        }
    }

    async componentDidMount() {
        this.formBuilder = await $(this.fb.current).formBuilder().promise;
        // this.formBuilder.actions.setData([]);
        // $(this.fb.current).formBuilder().then(response => {
        //     this.formBuilder = response;
        // }).catch(error => {

        // })
        // setTimeout(() => {
        this.loadInitData();
        // }, 1500);

    }

    loadInitData = () => {

        this.setState({
            loading: true
        })
        this.apiService.getAllFormConfigData().then(response => {
            this.formBuilder.actions.setData([]);
            let navLinkGroups = [];
            let util = new FormUtility();

            let formConfigs = util.generateFormList(response);
            navLinkGroups.push({
                links: formConfigs
            });

            this.setState({
                selectedKey: null,
                selectedForm: {},
                navLinkGroups,
                loading: false,
                newButtonDisabled: false,
                saveButtonDisabled: true,
                openPanelButtonDisabled: true,
            });
        }).catch((error) => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON.alertHeader = "Error !!";
            alertJSON.alertSubText = error;
            alertJSON.hideAlert = false;
            this.setState({
                loading: false,
                alertJSON
            }, () => {
                alertJSON.alertHeader = "";
                alertJSON.alertSubText = "";
                alertJSON.hideAlert = true;
                setTimeout(() => {
                    this.setState({
                        alertJSON
                    })
                }, 2500);
                console.error("Error in Component", error);
            })
        })
    }

    addForm = () => {

        let selectedForm = { ...this.state.selectedForm };

        selectedForm = {
            name: 'New Form ' + moment(new Date()).format("HH:mm:ss"),
            formtype: null,
            formobject: null,
            samplesmin: 1,
            samplesmax: 2,
        }

        this.setState({
            selectedForm,
            newButtonDisabled: true,
            saveButtonDisabled: false,
            searchFieldDisabled: false,
            openPanelButtonDisabled: false
        }, () => {
            this.setState({
                isOpenFormDetailsPanel: true,
            });
        })

        this.formBuilder.actions.setData([]);
    }

    onCopyForm = (form) => {
        let selectedForm = {};

        selectedForm = {
            name: `${form.name} copy ` + moment(new Date()).format("HH:mm:ss"),
            formtype: form.formtype,
            formobject: form.formobject,
            samplesmin: form.samplesmin,
            samplesmax: form.samplesmax,
        }

        this.setState({
            selectedForm,
            selectedKey: null,
            newButtonDisabled: true,
            saveButtonDisabled: false,
            searchFieldDisabled: false,
            openPanelButtonDisabled: false
        }, () => {
            this.setState({
                isOpenFormDetailsPanel: true,
            });
        })

        this.formBuilder.actions.setData(form.formjson);

    }

    saveForm = () => {

        this.setState({
            loading: true
        });

        let payload = this.state.selectedForm;
        payload.formjson = this.formBuilder.actions.getData("json", true);
        // payload.formname = payload.name;

        // console.log(payload);

        let util = new FormUtility();

        let FormDetails = util.generateFormJSONPayLoad(payload);

        this.apiService.saveFormConfigData(FormDetails).then(() => {
            // tempFormConfigs[indexOfSelectedForm].isSaved = true;
            let alertJSON = { ...this.state.alertJSON };
            alertJSON.alertHeader = "Success !!";
            alertJSON.alertSubText = "Form saved successfully. Please refresh to check data";
            alertJSON.hideAlert = false;
            this.formBuilder.actions.setData([]);
            this.setState({
                loading: false,
                selectedForm: {},
                selectedKey: null,
                newButtonDisabled: false,
                openPanelButtonDisabled: true,
                searchFieldDisabled: false,
                alertJSON
            }, () => {
                setTimeout(() => {
                    alertJSON.alertHeader = "";
                    alertJSON.alertSubText = "";
                    alertJSON.hideAlert = true;
                    this.setState({
                        alertJSON
                    })
                }, 2500);
            }
            );

        }).catch(error => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON.alertHeader = "Error !!";
            alertJSON.alertSubText = error;
            alertJSON.hideAlert = false;
            this.setState({
                loading: false,
                alertJSON
            }, () => {
                console.error("Error in save form", error);
                alertJSON.alertHeader = "";
                alertJSON.alertSubText = "";
                alertJSON.hideAlert = true;
                setTimeout(() => {
                    this.setState({
                        alertJSON
                    })
                }, 2500);
            });
        })

    }

    // selectForm = (ev, form) => {
    //     ev.preventDefault();
    //     this.formBuilder.actions.setData(form.formjson);

    //     this.setState({
    //         newButtonDisabled: true,
    //         saveButtonDisabled: false,
    //         openPanelButtonDisabled: false,
    //         selectedForm: form,
    //         selectedKey: form.key,
    //         searchFieldDisabled: true
    //     })
    // }
    selectForm = (form) => {
        this.formBuilder.actions.setData(form.formjson);

        this.setState({
            newButtonDisabled: true,
            saveButtonDisabled: false,
            openPanelButtonDisabled: false,
            selectedForm: form,
            selectedKey: form.key,
            searchFieldDisabled: true
        })
    }

    // Panel Change Methods ( START )

    closeFormDetailsPanel = () => {
        this.setState({
            isOpenFormDetailsPanel: false
        });
    }

    changeFormName = (newValue) => {
        let selectedForm = { ...this.state.selectedForm };
        selectedForm.name = newValue;
        this.setState({
            selectedForm
        });
    }

    changeFormObject = (newValue) => {
        let selectedForm = { ...this.state.selectedForm };
        selectedForm.formobject = newValue.value;
        this.setState({
            selectedForm
        });
    }

    changeFormType = (newValue) => {
        let selectedForm = { ...this.state.selectedForm };
        selectedForm.formtype = newValue.value;
        this.setState({
            selectedForm
        });

    }

    changeSampleMax = (newValue) => {
        let selectedForm = { ...this.state.selectedForm };
        selectedForm.samplesmax = newValue;
        this.setState({
            selectedForm
        });

    }

    changeSampleMin = (newValue) => {
        let selectedForm = { ...this.state.selectedForm };
        selectedForm.samplesmin = newValue;
        this.setState({
            selectedForm
        });
    }

    // Panel Change Methods ( END )

    render() {


        let {
            selectedKey,
            navLinkGroups,
            newButtonDisabled,
            openPanelButtonDisabled,
            saveButtonDisabled,
            loading,
            selectedForm,
            alertJSON,
            nameFilter,
            searchFieldDisabled
        } = this.state;

        let filteredForms = [];
        filteredForms[0] = {
            links: []
        };

        // if (nameFilter) {
        // eslint-disable-next-line
        navLinkGroups[0].links.map((data) => {

            let formData = { ...data };
            if (nameFilter) {
                let filteredItems = formData.links.filter(d => d.name.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1);
                formData.links = filteredItems;
            }
            filteredForms[0].links.push(formData);
        });
        // }

        return (
            <LoadingOverlay
                active={loading}
                spinner={<FadeLoader />}
                text='Loading Data...'>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={FORM_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                            <ActionButton
                                text="Reload"
                                onClick={() => this.loadInitData()}
                                iconProps={IconConstants.refreshIcon}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <ActionButton
                                disabled={newButtonDisabled}
                                // className="horizontal-button"
                                text="Add Form"
                                iconProps={IconConstants.addIcon}
                                onClick={() => this.addForm()}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg7">
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                            <ActionButton
                                // className={
                                //     [
                                //         "horizontal-button",
                                //         "cancel-button"
                                //     ].join(" ")
                                // }
                                text="Cancel"
                                iconProps={IconConstants.cancelIcon}
                                onClick={() => {
                                    this.formBuilder.actions.setData([]);
                                    this.setState({
                                        selectedKey: null,
                                        selectedForm: {},
                                        newButtonDisabled: false,
                                        saveButtonDisabled: true,
                                        openPanelButtonDisabled: true,
                                        searchFieldDisabled: false
                                    });
                                }}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                            <ActionButton
                                // className={
                                //     [
                                //         "horizontal-button",
                                //         (saveButtonDisabled || !selectedForm.formobject) ? "disable-button" : "save-button"
                                //     ].join(" ")
                                // }
                                disabled={saveButtonDisabled || !selectedForm.formobject}
                                text="Save"
                                iconProps={IconConstants.saveicon}
                                onClick={() => this.saveForm()}
                            />
                        </div>

                    </div>
                </div>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 form-menu">
                            <SearchBox
                                placeholder="Search"
                                disabled={searchFieldDisabled}
                                onClear={ev => {
                                    this.setState({
                                        nameFilter: ""
                                    });
                                }}
                                onChange={(_, newValue) => {
                                    this.setState({
                                        nameFilter: newValue
                                    });
                                }
                                }
                            />
                            <Nav
                                onRenderLink={(props) => (
                                    <>
                                        {!props.disabled ?
                                            <ActionButton
                                                text={props.name}
                                                menuProps={{
                                                    items: [
                                                        {
                                                            key: 'showForm',
                                                            text: 'Show Form',
                                                            iconProps: IconConstants.viewIcon,
                                                            onClick: () => {
                                                                this.selectForm(props)
                                                            }
                                                        },
                                                        {
                                                            key: 'copyForm',
                                                            text: 'Copy Form',
                                                            iconProps: IconConstants.copyIcon,
                                                            onClick: () => this.onCopyForm(props)
                                                        }
                                                    ]
                                                }} />
                                            :
                                            <Label>{props.name}</Label>
                                        }
                                    </>
                                )}
                                onLinkExpandClick={(ev, item) => {
                                    let tempNavLinkGroups = [...navLinkGroups];
                                    let tempNavLinkGroupItem = { ...tempNavLinkGroups[0] };
                                    let tempNavLinkGroupItemLinks = [...tempNavLinkGroupItem.links];

                                    tempNavLinkGroupItemLinks.find(d => d.name === item.name).isExpanded =
                                        !tempNavLinkGroupItemLinks.find(d => d.name === item.name).isExpanded;
                                    tempNavLinkGroupItem.links = tempNavLinkGroupItemLinks;
                                    tempNavLinkGroups[0] = tempNavLinkGroupItem;
                                    this.setState({
                                        navLinkGroups: tempNavLinkGroups
                                    });
                                }}
                                selectedKey={selectedKey}
                                ariaLabel="Forms"
                                styles={navStylesForm}
                                groups={filteredForms}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        {/* <Label className="breadcrumb-label">Data Form</Label> */}
                                        <FormDetails
                                            openPanelButtonDisabled={openPanelButtonDisabled}
                                            selectedForm={selectedForm}
                                            closeFormDetailsPanel={this.closeFormDetailsPanel}
                                            changeFormName={this.changeFormName}
                                            changeFormObject={this.changeFormObject}
                                            changeFormType={this.changeFormType}
                                            changeSampleMin={this.changeSampleMin}
                                            changeSampleMax={this.changeSampleMax}
                                        />
                                        {/* <br /> */}
                                        <div className="div-form-builder">
                                            <div id="fb-editor" ref={this.fb} />
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {!alertJSON.hideAlert &&
                    <Alert
                        alertJSON={alertJSON}
                    />
                }

            </LoadingOverlay >
        )
    }
}
