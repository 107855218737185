import React, { PureComponent } from 'react'
import { ActionButton, IconButton, PrimaryButton, CommandButton, SearchBox, Dropdown } from 'office-ui-fabric-react'
import filterFactory from 'react-bootstrap-table2-filter';
import LoadingOverlay from 'react-loading-overlay';
import { FadeLoader } from 'react-spinners';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { DeletedRowStyle, IconConstants } from '../../../../Constants/StylesConstants';
import { TaskTypeAPI } from '../Services/TaskTypeAPI';
import AddEditTaskTypePanel from './AddEditTaskTypePanel';
import ConfirmTaskTypeRedux from './ConfirmTaskTypeRedux';
import AlertRedux from '../../../Alert/AlertRedux';
import { CommonUtility } from '../../../../CommonUtility/CommonUtility';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT_NAME } from '../../../../Constants/MessageConstants';

const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
}];

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="table-export-button-div">
            <PrimaryButton
                onClick={handleClick}
                text="Export to CSV"
                menuIconProps={IconConstants.exportIcon}
            />
        </div>
    );
};

const RemoteAll = ({
    data, columns, onTableChange, page, hideSizePerPage, style
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
            exportCSV={{
                fileName: 'Qwikspec_TaskType.csv',
                noAutoBOM: false,
                blobType: 'text/csv;charset=ansi'
            }}
        >
            {
                props => (
                    <React.Fragment>
                        <MyExportCSV {...props.csvProps} />
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({ page, hideSizePerPage })}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            rowClasses={DeletedRowStyle}
                            wrapperClasses="table-responsive" />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class TaskTypeDashboard extends PureComponent {

    _taskTypeAPI = null;

    constructor(props) {
        super(props);
        this._taskTypeAPI = new TaskTypeAPI(window.API_URL, window.loggedInUserDetails.jsessionid);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {

        this.props.cleanDashboard({
            offset: 0,
        });

        this.props.loadTaskTypeDashboard({
            objectType: "TaskType",
            offset: 0,
            limit: this.props.TaskType.limit,
            apiObject: this._taskTypeAPI,
        });

    }

    fetchMoreData = () => {

        this.props.loadTaskTypeDashboard({
            objectType: "TaskType",
            offset: this.props.TaskType.offset,
            limit: this.props.TaskType.limit,
            apiObject: this._taskTypeAPI,
        });

    };


    handleTableChange = (type, { filters, sortField, sortOrder }) => {

        // console.log(sortOrder);

        if (type.toUpperCase() === "SORT") {

            let result = [...this.props.TaskType.TaskTypes];

            // Handle column sort
            result = CommonUtility.sortByColumn(result, sortField, sortOrder);

            this.props.sortTaskTypeDashboard({
                sortedTasktypes: result,
            });

        }

    }

    render() {

        let {
            LoadingTaskTypeDashboard,
            TaskTypes,
            HasMoreTaskTypes,
            ShowTaskTypeAddEditPanel,
            hideConfirm,
            alertJSON,
            name_filter_value,
            validity_filter_value,
            reference_standard_filter_value,
            inspection_method_filter_value,
            selected_division_value,
            dashboardDivisionOption
        } = this.props.TaskType;

        let columns = [
            {
                dataField: 'manageTaskTypeIcons',
                isDummyField: true,
                text: 'Action',
                formatter: (cellContent, row) => {
                    return (
                        <span>
                            {row.status === 1 ?
                                <span className="action-span">
                                    <IconButton
                                        onClick={() => this.props.loadAttributeDropDowns({
                                            apiObject: this._taskTypeAPI,
                                            objectType: "TASK_TYPE",
                                            assetType: "AssetType",
                                            division: "AllDivisions",
                                            PanelHeaderText: "Edit ",
                                            SelectedTaskType: row,
                                        })}
                                        iconProps={IconConstants.editicon} />
                                    <IconButton
                                        onClick={() => {
                                            let copiedTaskType = { ...row };
                                            let copiedNameSuffix = moment.utc(new Date()).local().format(DATE_TIME_FORMAT_NAME);

                                            copiedTaskType.id = -1;
                                            copiedTaskType.name = row.name + " Copy " + copiedNameSuffix;
                                            this.props.openCloseTaskTypeConfirmModal({
                                                hideConfirm: false,
                                                CurrentTaskType: copiedTaskType,
                                                dialogStatus: "Copy"
                                            });
                                        }}
                                        iconProps={IconConstants.copyIcon} />
                                    <IconButton
                                        onClick={() => {
                                            this.props.openCloseTaskTypeConfirmModal({
                                                hideConfirm: false,
                                                CurrentTaskType: row,
                                                dialogStatus: "Delete"
                                            });
                                        }}
                                        iconProps={IconConstants.deleteicon} />
                                </span>
                                :
                                <span></span>
                            }
                        </span>
                    )
                },
                csvExport: false
            },
            {
                dataField: 'id',
                text: 'TaskType ID',
                hidden: true,
                csvExport: false,
                sort: true
            },
            {
                dataField: 'acronym_code',
                text: 'Code',
                sort: true,
                hidden: true,
            },
            {
                dataField: 'division_id',
                text: 'Division',
                sort: true,
                hidden: false,
                formatter: (cellContent, row) => {
                    return (
                        <span>{dashboardDivisionOption.find(division => division.key === cellContent)?.text}</span>
                    )
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
                hidden: false,
            },
            {
                dataField: 'external_id',
                text: 'External',
            },
            {
                dataField: 'reference_standard',
                text: 'Reference Standard',
                sort: true,
            },
            {
                dataField: 'inspection_method',
                text: 'Inspection Method',
                sort: true,
            },
            {
                dataField: 'validity',
                text: 'Validity',
                sort: true
            }
        ];



        let filteredTaskType = name_filter_value ?
            [
                ...TaskTypes.filter(
                    (taskType) =>
                        taskType.name.toUpperCase().indexOf(name_filter_value.toUpperCase()) > -1
                )]
            :
            [...TaskTypes];
        filteredTaskType = validity_filter_value ?
            [
                ...TaskTypes.filter(
                    (taskType) =>
                        taskType.validity === parseInt(validity_filter_value)
                )]
            :
            [...filteredTaskType]
        filteredTaskType = reference_standard_filter_value ?
            [
                ...filteredTaskType.filter(
                    (taskType) =>
                        taskType.reference_standard?.toUpperCase().indexOf(reference_standard_filter_value.toUpperCase()) > -1
                )]
            :
            [...filteredTaskType]
        filteredTaskType = inspection_method_filter_value ?
            [
                ...filteredTaskType.filter(
                    (taskType) =>
                        taskType.inspection_method?.toUpperCase().indexOf(inspection_method_filter_value.toUpperCase()) > -1
                )]
            :
            [...filteredTaskType]

        filteredTaskType = selected_division_value ?
            [
                ...filteredTaskType.filter(
                    (taskType) =>
                        taskType.division_id === selected_division_value
                )]
            :
            [...filteredTaskType]

        return (
            <React.Fragment>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <ActionButton
                            disabled={LoadingTaskTypeDashboard}
                            text="Reload"
                            iconProps={IconConstants.refreshIcon}
                            onClick={() => this.loadInitData()}
                        />
                        <ActionButton
                            text="Add"
                            iconProps={IconConstants.addIcon}
                            onClick={() => this.props.loadAttributeDropDowns({
                                apiObject: this._taskTypeAPI,
                                objectType: "TASK_TYPE",
                                assetType: "AssetType",
                                division: "AllDivisions",
                                PanelHeaderText: "Add Task Type"
                            })}
                        />
                        {HasMoreTaskTypes && !LoadingTaskTypeDashboard &&
                            <CommandButton
                                text="Load more"
                                iconProps={IconConstants.moreicon}
                                onClick={() => this.fetchMoreData()}
                            />
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <Dropdown
                            options={dashboardDivisionOption}
                            disabled={LoadingTaskTypeDashboard}
                            placeholder="Select Division"
                            selectedKey={selected_division_value}
                            onChange={(event, newVal) => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    selected_division_value: newVal.key,
                                    filterType: "division"
                                })
                            }}
                            onClear={() => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    selected_division_value: "",
                                    filterType: "division"
                                })
                            }}
                            underlined
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <SearchBox
                            placeholder="Name"
                            value={name_filter_value}
                            disabled={LoadingTaskTypeDashboard}
                            onChange={(event, newVal) => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    name_filter_value: newVal,
                                    filterType: "name"
                                })
                            }}
                            onClear={() => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    name_filter_value: "",
                                    filterType: "name"
                                })
                            }}
                            underlined
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <SearchBox
                            placeholder="Reference Standard"
                            value={reference_standard_filter_value}
                            disabled={LoadingTaskTypeDashboard}
                            onChange={(event, newVal) => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    reference_standard_filter_value: newVal,
                                    filterType: "referenceStandard"
                                })
                            }}
                            onClear={() => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    reference_standard_filter_value: "",
                                    filterType: "referenceStandard"
                                })
                            }}
                            underlined
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <SearchBox
                            placeholder="Inspection Method"
                            value={inspection_method_filter_value}
                            disabled={LoadingTaskTypeDashboard}
                            onChange={(event, newVal) => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    inspection_method_filter_value: newVal,
                                    filterType: "inspectionMethod"
                                })
                            }}
                            onClear={() => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    inspection_method_filter_value: "",
                                    filterType: "inspectionMethod"
                                })
                            }}
                            underlined
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <SearchBox
                            placeholder="Validity"
                            value={validity_filter_value}
                            disabled={LoadingTaskTypeDashboard}
                            type="number"
                            onChange={(event, newVal) => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    validity_filter_value: newVal,
                                    filterType: "validity"
                                })
                            }}
                            onClear={() => {
                                this.props.changeTaskTypeDashboardFilterValue({
                                    validity_filter_value: "",
                                    filterType: "validity"
                                })
                            }}
                            underlined
                        />
                    </div>
                </div>

                <LoadingOverlay
                    active={LoadingTaskTypeDashboard}
                    spinner={<FadeLoader />}
                    text="Loading Task Types...">
                    <div className="lazy-load-div" >
                        <RemoteAll
                            data={filteredTaskType}
                            columns={columns}
                            page={1}
                            hideSizePerPage={true}
                            onTableChange={this.handleTableChange}
                        />
                    </div>

                </LoadingOverlay>

                {
                    ShowTaskTypeAddEditPanel &&
                    <AddEditTaskTypePanel
                        {...this.props}
                    />
                }
                {!hideConfirm &&
                    <ConfirmTaskTypeRedux
                        {...this.props}
                    />
                }
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </React.Fragment>
        )
    }
}
