import React, { PureComponent } from 'react';
import { AssetTypeAPI } from '../Services/AssetTypeAPI';
import Select from 'react-select';
import { Label } from 'office-ui-fabric-react';

export default class Attributes extends PureComponent {

    _taskApi = null;

    constructor(props) {
        super(props);
        this._taskApi = new AssetTypeAPI(window.API_URL);
    }

    componentDidMount() {
        // this.handleStartStop();
        // this.props.showTaskPdfAsync({
        //     SelectedTask: this.props.Task.SelectedTask,
        //     apiObject: this._taskApi
        // })
    }

    // handleStartStop = () => {
    //     let interValVar = setInterval(timer, 2000);
    //     function timer() {
    //         try {
    //             var iframe = document.getElementById('iframeTaskPdf');
    //             if (iframe) {
    //                 // eslint-disable-next-line
    //                 var doc = iframe.contentWindow.document;
    //                 // eslint-disable-next-line
    //                 iframe.src = iframe.src;
    //             }

    //         }
    //         catch (err) {
    //             clearInterval(interValVar);
    //         }
    //     }
    // }

    render() {

        let {
            FormAttributeOptions,
            CurrentAssetType
        } = this.props.AssetType;

        return (
            <React.Fragment>
                <div className="ms-Grid task-preview-section" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Label className="breadcrumb-label">Asset Type Attributes</Label>
                            <Select
                                placeHolder="Select Template"
                                value={CurrentAssetType.attribute_form}
                                onChange={(newValue) => {
                                    this.props.changeAssetTypeAttributes({
                                        field: "form",
                                        attribute_form: newValue
                                    });
                                }}
                                options={FormAttributeOptions}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
