import { ClientInitialState } from "../Constants/ClientInitialState";
import * as CONSTANTS from "../Constants/ClientConstants";
import { IClientInitialState, IClientPayLoad, IClientAction, ILocation, IUser } from "../ClientInterface/IClient";
import { ClientUtility } from "../Utility/ClientUtility";
import { ISelect } from "../../../CommonInterfaces/CommoInterfaces";

export function ClientReducer(state = ClientInitialState, action: IClientAction) {

    let tempState: IClientInitialState = { ...state };
    let payload: IClientPayLoad = action.payload;
    let utility: ClientUtility = new ClientUtility();
    let AddClientDetails: ILocation = { ...tempState.AddClientDetails };
    let AddSiteDetails: ILocation = { ...tempState.AddSiteDetails };
    let SelectedLocation: ILocation = { ...tempState.SelectedLocation };
    let Users: IUser[] = [...tempState.Users];
    let currentNewUser = { ...Users[(payload && payload.userIndex) ? payload.userIndex : 0] };

    switch (action.type) {

        case CONSTANTS.RESET_CLIENT_BREADCRUMB:
            tempState.ClientBreadCrumb = [
                { text: 'RESOURCES', key: 'Resources' },
                { text: 'Clients', key: 'Clients' }
            ]
            break;

        case CONSTANTS.CLEAN_CLIENT_DASHBOARD:
            tempState.LoadingClientDashboard = true;
            tempState.Clients = [];
            tempState.offset = 0;
            tempState.client_name_filter = "";
            tempState.client_code_filter = "";
            tempState.AddClientDetails = utility.resetClientAndSite();
            tempState.AddSiteDetails = utility.resetClientAndSite();
            break;

        case CONSTANTS.CLEAN_LOCATION_DASHBOARD:
            tempState.LoadingLocationDashboard = true;
            tempState.Locations = [];
            tempState.locOffset = 0;
            break;

        case CONSTANTS.LOAD_CLIENT_DASHBOARD:
            tempState.LoadingClientDashboard = payload.IsOngoingLoad;
            if (!payload.IsOngoingLoad) {
                tempState.offset += tempState.limit;
                tempState.Clients = tempState.Clients.concat(payload.Clients);
                tempState.HasMoreClients = tempState.Clients.length < payload.TotalClientCount;
            }
            break;

        case CONSTANTS.LOAD_LOCATION_DASHBOARD:
            tempState.LoadingLocationDashboard = payload.IsOngoingLoad;
            if (!payload.IsOngoingLoad) {
                tempState.locOffset += tempState.limit;
                tempState.Locations = tempState.Locations.concat(payload.Locations);
                tempState.HasMoreLoctions = tempState.Locations.length < payload.TotalLocationCount;
            }
            break;

        case CONSTANTS.OPEN_CLOSE_CLIENT_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON ?
                payload.alertJSON :
                {
                    alertHeader: "",
                    alertSubText: "",
                    hideAlert: true,
                };
            tempState.LoadingClientDashboard = false;
            tempState.LoadingAddClientPopUp = false;
            tempState.LoadingAddEditLocationtPopUp = false;
            tempState.ShowAddEditLocationPopUp = false;
            if (payload.IsClientEdit) {
                tempState.HideAddClientPopUp = true;
            }
            else {
                tempState.HideAddClientPopUp = !payload.errorFlag;
            }
            break;

        case CONSTANTS.CHANGE_CLIENT_NAME_FILTER_VALUE:
            tempState.offset = 0;
            tempState.Clients = [];
            tempState.LoadingClientDashboard = true;
            tempState.client_name_filter = payload.filterValue;
            break;

        case CONSTANTS.CHANGE_CLIENT_CODE_FILTER_VALUE:
            tempState.offset = 0;
            tempState.Clients = [];
            tempState.LoadingClientDashboard = true;
            tempState.client_code_filter = payload.filterValue;
            break;

        case CONSTANTS.SHOW_HIDE_CLIENT_ADD_CLIENT_POP_UP:
            tempState.HideAddClientPopUp = payload.HideAddClientPopUp;
            break;

        case CONSTANTS.SHOW_CLIENT_DETAILS_TAB:
            tempState.SelectedClient = payload.SelectedClient;
            tempState.ShowClientDetailsTab = payload.ShowClientDetailsTab;
            if (payload.SelectedClient) {
                let clientName: string = "";
                let clientAddress: string = "";
                clientName = payload.SelectedClient.name;
                clientAddress = payload.SelectedClient.street;
                tempState.ClientBreadCrumb = tempState.ClientBreadCrumb.concat(
                    { text: `Locations ( Client : ${clientName} ,  Address: ${clientAddress} )`, key: 'Locations' }
                )
            }
            else {
                if (tempState.ClientBreadCrumb.length > 2) {
                    tempState.ClientBreadCrumb.pop();
                }
            }
            break;

        // Add Client Pop Up
        case CONSTANTS.LOAD_ADD_CLIENT_POP_UP_SCREEN:
            tempState.LoadingAddClientPopUp = payload.IsOngoingLoad;
            if (!payload.IsOngoingLoad) {
                tempState.UserRoleOptions = utility.getClientUserRoleOptions(payload.Roles);
                tempState.isSiteAddressSameAsClientAddress = false;
                tempState.Users = [].concat(utility.createNewUserRow());
                tempState.LoOffices = utility.getLoOfficeOptions(payload.LoOffices);
            }
            break;

        case CONSTANTS.ADD_NEW_USER:
            tempState.Users = tempState.Users.concat(utility.createNewUserRow());
            break;

        case CONSTANTS.DELETE_LAST_USER:
            tempState.Users.pop();
            break;

        case CONSTANTS.CHANGE_CLIENT_NAME:
            AddClientDetails.name = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_CLIENT_STREET:
            AddClientDetails.street = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_CLIENT_CITY:
            AddClientDetails.city = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_CLIENT_STATE:
            AddClientDetails.state = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_CLIENT_COUNTRY:
            AddClientDetails.country = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_CLIENT_PIN:
            AddClientDetails.pin = parseInt(payload.clientSiteFieldValue);
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_CLIENT_PHONE:
            AddClientDetails.phone = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_CLIENT_CODE:
            AddClientDetails.acronymCode = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_CLIENT_EXTERNAL_ID:
            AddClientDetails.externalId = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;
        case CONSTANTS.CHANGE_ADD_CLIENT_OFFICE_ID:
            AddClientDetails.officeIds = payload.clientOfficeIdValue ? payload.clientOfficeIdValue
                .map((val: ISelect) => val.value.toString()).join(",") : "";
            tempState.AddClientDetails = AddClientDetails;
            break;

        case CONSTANTS.CHANGE_SITE_NAME:
            AddSiteDetails.name = payload.clientSiteFieldValue;
            tempState.AddSiteDetails = AddSiteDetails;
            break;

        case CONSTANTS.POPULATE_SITE_ADDRESS_FROM_CLIENT:
            tempState.AddSiteDetails.street = payload.isSiteAddressSameAsClientAddress ? tempState.AddClientDetails.street : "";
            tempState.AddSiteDetails.city = payload.isSiteAddressSameAsClientAddress ? tempState.AddClientDetails.city : "";
            tempState.AddSiteDetails.state = payload.isSiteAddressSameAsClientAddress ? tempState.AddClientDetails.state : "";
            tempState.AddSiteDetails.country = payload.isSiteAddressSameAsClientAddress ? tempState.AddClientDetails.country : "";
            tempState.AddSiteDetails.pin = payload.isSiteAddressSameAsClientAddress ? tempState.AddClientDetails.pin : 0;
            tempState.AddSiteDetails.phone = payload.isSiteAddressSameAsClientAddress ? tempState.AddClientDetails.phone : "";
            tempState.isSiteAddressSameAsClientAddress = payload.isSiteAddressSameAsClientAddress;
            break;

        case CONSTANTS.CHANGE_SITE_STREET:
            AddSiteDetails.street = payload.clientSiteFieldValue;
            tempState.AddSiteDetails = AddSiteDetails;
            break;

        case CONSTANTS.CHANGE_SITE_CITY:
            AddSiteDetails.city = payload.clientSiteFieldValue;
            tempState.AddSiteDetails = AddSiteDetails;
            break;

        case CONSTANTS.CHANGE_SITE_STATE:
            AddSiteDetails.state = payload.clientSiteFieldValue;
            tempState.AddSiteDetails = AddSiteDetails;
            break;

        case CONSTANTS.CHANGE_SITE_COUNTRY:
            AddSiteDetails.country = payload.clientSiteFieldValue;
            tempState.AddSiteDetails = AddSiteDetails;
            break;

        case CONSTANTS.CHANGE_SITE_PIN:
            AddSiteDetails.pin = parseInt(payload.clientSiteFieldValue);
            tempState.AddSiteDetails = AddSiteDetails;
            break;

        case CONSTANTS.CHANGE_SITE_PHONE:
            AddSiteDetails.phone = payload.clientSiteFieldValue;
            tempState.AddSiteDetails = AddSiteDetails;
            break;

        case CONSTANTS.CHANGE_SITE_CODE:
            AddSiteDetails.acronymCode = payload.clientSiteFieldValue;
            tempState.AddSiteDetails = AddSiteDetails;
            break;

        case CONSTANTS.CHANGE_SITE_EXTERNAL_ID:
            AddSiteDetails.externalId = payload.clientSiteFieldValue;
            tempState.AddClientDetails = AddClientDetails;
            break;

        // Add Edit Location Pop Up

        case CONSTANTS.SHOW_HIDE_CLIENT_ADD_EDIT_LOCATION_POP_UP:
            tempState.ShowAddEditLocationPopUp = payload.ShowAddEditLocationPopUp;
            tempState.IsClientEdit = payload.IsClientEdit;
            tempState.PanelHeaderText = payload.PanelHeaderText;
            if (!payload.ShowAddEditLocationPopUp) {
                tempState.SelectedLocation = utility.resetLocation();
            }
            if (payload.SelectedLocation) {
                tempState.SelectedLocation = payload.SelectedLocation;
            }
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_NAME:
            SelectedLocation.name = payload.clientSiteFieldValue;
            tempState.SelectedLocation = SelectedLocation;
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_STREET:
            SelectedLocation.street = payload.clientSiteFieldValue;
            tempState.SelectedLocation = SelectedLocation;
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_CITY:
            SelectedLocation.city = payload.clientSiteFieldValue;
            tempState.SelectedLocation = SelectedLocation;
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_STATE:
            SelectedLocation.state = payload.clientSiteFieldValue;
            tempState.SelectedLocation = SelectedLocation;
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_COUNTRY:
            SelectedLocation.country = payload.clientSiteFieldValue;
            tempState.SelectedLocation = SelectedLocation;
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_PIN:
            SelectedLocation.pin = parseInt(payload.clientSiteFieldValue);
            tempState.SelectedLocation = SelectedLocation;
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_PHONE:
            SelectedLocation.phone = payload.clientSiteFieldValue;
            tempState.SelectedLocation = SelectedLocation;
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_CODE:
            SelectedLocation.acronymCode = payload.clientSiteFieldValue;
            tempState.SelectedLocation = SelectedLocation;
            break;

        case CONSTANTS.CHANGE_LOCATION_SITE_EXTERNAL_ID:
            SelectedLocation.externalId = payload.clientSiteFieldValue;
            tempState.SelectedLocation = SelectedLocation;
            break;
        case CONSTANTS.CHANGE_CLIENT_OFFICE_ID:
            SelectedLocation.officeIds = payload.clientOfficeIdValue ? payload.clientOfficeIdValue
                .map((val: ISelect) => val.value.toString()).join(",") : "";
            tempState.SelectedLocation = SelectedLocation;
            break;

        //client image start 
        case CONSTANTS.LOAD_EDIT_CLIENT_SCREEN:
            tempState.isLoadingImage = payload.IsOngoingLoad;
            if (!payload.IsOngoingLoad) {
                SelectedLocation.clientLogoFile = payload.clientSiteFieldValue;
                tempState.LoOffices = utility.getLoOfficeOptions(payload.LoOffices);
            }
            tempState.SelectedLocation = SelectedLocation;
            tempState.imageUploadErrorMessage = payload.imageError;
            break;

        case CONSTANTS.SAVE_CLIENT_LOGO:
            tempState.isLoadingImage = payload.IsOngoingLoad;
            if (!payload.IsOngoingLoad) {
                SelectedLocation.clientLogoFile = payload.clientSiteFieldValue;
            }
            tempState.SelectedLocation = SelectedLocation;
            tempState.imageUploadErrorMessage = payload.imageError;
            break;

        //client image end

        case CONSTANTS.CHANGE_CLIENT_USER_ROLE:
            currentNewUser.role = payload.AddUserDetails.role;
            Users[payload.userIndex] = currentNewUser;
            tempState.Users = Users;
            break;

        case CONSTANTS.CHANGE_CLIENT_USER_NAME:
            currentNewUser.name = payload.AddUserDetails.name;
            Users[payload.userIndex] = currentNewUser;
            tempState.Users = Users;
            break;

        case CONSTANTS.CHANGE_CLIENT_USER_EMAIL:
            currentNewUser.email = payload.AddUserDetails.email;
            Users[payload.userIndex] = currentNewUser;
            tempState.Users = Users;
            break;

        case CONSTANTS.CHANGE_CLIENT_USER_PHONE:
            currentNewUser.phone = payload.AddUserDetails.phone;
            Users[payload.userIndex] = currentNewUser;
            tempState.Users = Users;
            break;

        case CONSTANTS.SAVE_NEW_CLIENT:
            tempState.LoadingAddClientPopUp = payload.IsOngoingLoad;
            break;

        case CONSTANTS.SAVE_LOCATION:
            tempState.LoadingAddEditLocationtPopUp = payload.IsOngoingLoad;
            tempState.SelectedLocation = utility.resetLocation();
            break;

        default:
            break;

    }

    return tempState;

}