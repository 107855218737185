export const CHANGE_TASKTYPE_TAB = "CHANGE_TASKTYPE_TAB";
export const CLOSE_TASKTYPE_DETAILS_PANEL = "CLOSE_TASKTYPE_DETAILS_PANEL";
export const CHANGE_TASKTYPE_NAME_FILTER_VALUE = "CHANGE_TASKTYPE_NAME_FILTER_VALUE";
export const CHANGE_TASKTYPE_REFERENCE_STANDARD_FILTER_VALUE = "CHANGE_TASKTYPE_REFERENCE_STANDARD_FILTER_VALUE";
export const CHANGE_TASKTYPE_INSPECTION_METHOD_FILTER_VALUE = "CHANGE_TASKTYPE_INSPECTION_METHOD_FILTER_VALUE";
export const CHANGE_TASKTYPE_VALIDITY_FILTER_VALUE = "CHANGE_TASKTYPE_VALIDITY_FILTER_VALUE";
export const CHANGE_TASKTYPE_DIVISION_FILTER_VALUE = "CHANGE_TASKTYPE_DIVISION_FILTER_VALUE";

//DASHBOARD
export const CLEAN_TASKTYPE_DASHBOARD = 'CLEAN_TASKTYPE_DASHBOARD';
export const LOAD_TASKTYPE_DASHBOARD = 'LOAD_TASKTYPE_DASHBOARD';
export const FIX_TASKTYPE_DASHBOARD = 'FIX_TASKTYPE_DASHBOARD';
export const SORT_TASKTYPE_DASHBOARD = 'SORT_TASKTYPE_DASHBOARD';
export const OPEN_CLOSE_TASK_TYPE_ALERT_MODAL = 'OPEN_CLOSE_TASK_TYPE_ALERT_MODAL';
export const TOGGLE_TASKTYPE_VIEW = 'TOGGLE_TASKTYPE_VIEW';

//PANEL
export const LOAD_TASKTYPE_ATTRIBUTES = 'LOAD_TASKTYPE_ATTRIBUTES';

// DELETE
export const OPEN_CLOSE_TASKTYPE_CONFIRM_MODAL = 'OPEN_CLOSE_TASKTYPE_CONFIRM_MODAL';
export const DELETE_TASK_TYPE = 'DELETE_TASK_TYPE';

//Description
export const CHANGE_TASKTYPE_NAME = 'CHANGE_TASKTYPE_NAME';
export const CHANGE_TASKTYPE_ACRONYM_CODE = 'CHANGE_TASKTYPE_ACRONYM_CODE';
export const CHANGE_TASKTYPE_GROUP_NAME = 'CHANGE_TASKTYPE_GROUP_NAME';
export const CHANGE_TASKTYPE_CATEGORY = 'CHANGE_TASKTYPE_CATEGORY';
export const CHANGE_TASKTYPE_DESCRIPTION = 'CHANGE_TASKTYPE_DESCRIPTION';
export const CHANGE_TASKTYPE_REFERENCE_STANDARD = 'CHANGE_TASKTYPE_REFERENCE_STANDARD';
export const CHANGE_TASKTYPE_INSPECTION_METHOD = 'CHANGE_TASKTYPE_INSPECTION_METHOD';
export const CHANGE_TASKTYPE_DIVISION = 'CHANGE_TASKTYPE_DIVISION';
export const CHANGE_TASKTYPE_VALIDITY = 'CHANGE_TASKTYPE_VALIDITY';
export const CHANGE_TASKTYPE_IS_EIAC_APPROVED = 'CHANGE_TASKTYPE_IS_EIAC_APPROVED';

//ATTRIBUTE
export const CHANGE_TASKTYPE_FORM_ATTRIBUTE = 'CHANGE_TASKTYPE_FORM_ATTRIBUTE';
export const CHANGE_TASKTYPE_ASSET_TYPE = 'CHANGE_TASKTYPE_ASSET_TYPE';
export const CHANGE_TASKTYPE_CHECKLIST = 'CHANGE_TASKTYPE_CHECKLIST';

// SAVE
export const SAVE_TASKTYPE_DETAILS = 'SAVE_TASKTYPE_DETAILS';

//URL
export const GET_TASKTYPES_URL = "Setting";
export const GET_FORM_ATTRIBUTE_LIST = "Setting/FormObject";
export const GET_SETTING_OBJECTS = "Setting/Objects";
export const GET_ALL_SETTING_OBJECTS = "Setting/Others";

export const TASK_TYPE_TABS = {
    0: "DESCRIPTION",
    1: "ATTRIBUTE",
    2: "FILES",
};

export const TASK_TYPE_BREADCRUMBS = [
    { text: 'SETTINGS', key: 'Settings' },
    { text: 'Task Types', key: 'TaskTypes' }
];
