export const ALERT_MESSAGE = {
    ERROR_401: "You are not authorized to view this page",
    COMMON_ERROR: "Oops!! Some error occurred",
}

export const MODE = {
    PROD: 0,
    DEV: 1
}

export const DATE_TIME_FORMAT_GENERAL = 'D MMM, YYYY HH:mm:ss';
export const DATE_TIME_FORMAT = 'D-MMM-YYYY HH:mm:ss';
export const DATE_FORMAT = 'D-MMM-YYYY';
export const DATE_TIME_FORMAT_NAME = 'D-MMM-YY_HH:mm:ss';
export const DATE_TIME_FORMAT_USER = 'D-MMM-YY HH:mm';

export const NO_ALLOW_EDIT_JOB_TASK_CLIENT_ASSET_ROLE = ["CUSTOMER-USER", "CLIENT_USER", "REQUESTER", "SITE-USER"];
