export const QRStickerInitialState = {
   /* sizeValue: '',
    colorValue: '',
    quantityValue: '',*/
    CurrentQRStickerId: -1,
    successMessage: '',
    CurrentQRSticker: {
        size: {
            value: null,
            error: true,
            message: ""
        },
        color: {
            value: null,
            error: true,
            message: ""
        },
        quantity: {
            value: null,
            error: true,
            message: ""
        },
    }  , 
}