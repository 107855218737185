import { Breadcrumb, IBreadcrumbItem } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TrialFitPicLibraryUtility } from '../../TrialFitPicLibrary/Utility/TrialFitPicLibraryUtility';
import { TRIAL_FIT_PIC_LIBRARY_BREADCRUMBS } from '../Constants/ProductTechInfoConstants';
import { ProductTechInfoActions } from '../Actions/ProductTechInfoActions';
import ProductTechInfoDashboard from './ProductTechInfoDashboard';

const mapStateToProps = (state: any): any => {
    return {
        ProductTechInfoState: state.ProductTechInfoReducer
    }
}

export class ProductTechInfoComponent extends Component {


    render() {
        let breadCrumbs: IBreadcrumbItem[] = [...TRIAL_FIT_PIC_LIBRARY_BREADCRUMBS];
        breadCrumbs.push(
            {
                text: TrialFitPicLibraryUtility.getDisplayName(window.location.hash.split("/")[2]),
                key: TrialFitPicLibraryUtility.getLibraryKey(window.location.hash.split("/")[2])
            }
        )

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={breadCrumbs}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                </div>
                <ProductTechInfoDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    let productTechInfoActions: ProductTechInfoActions = new ProductTechInfoActions();
    return bindActionCreators({ ...productTechInfoActions }, dispatch);
}

const ProductTechInfo = connect(mapStateToProps, mapDispatchToProps)(ProductTechInfoComponent);
export default ProductTechInfo;
