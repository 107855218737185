export const TRIAL_FIT_PIC_LIBRARY_BREADCRUMBS = [
    { text: 'TECHNICAL LIBRARY', key: 'TECHNICAL_LIBRARY' }
];


export const CLEAN_PRODUCT_TECH_INFO_DOCUMENT_LIST: string = "CLEAN_PRODUCT_TECH_INFO_DOCUMENT_LIST";
export const LOAD_PRODUCT_TECH_INFO_DOCUMENT_LIST: string = "LOAD_PRODUCT_TECH_INFO_DOCUMENT_LIST";
export const SAVE_PRODUCT_TECH_INFO_FOLDER: string = "SAVE_PRODUCT_TECH_INFO_FOLDER";
export const SAVE_PRODUCT_TECH_INFO_FILE: string = "SAVE_PRODUCT_TECH_INFO_FILE";
export const DELETE_PRODUCT_TECH_INFO_ENTITY: string = "DELETE_PRODUCT_TECH_INFO_ENTITY";
export const CHANGE_PRODUCT_TECH_INFO_DOCUMENT_NAME: string = "CHANGE_PRODUCT_TECH_INFO_DOCUMENT_NAME";
export const OPEN_CLOSE_ADD_EDIT_PRODUCT_TECH_INFO_DOCUMENT_PANEL: string = "OPEN_CLOSE_ADD_EDIT_PRODUCT_TECH_INFO_DOCUMENT_PANEL";
export const CLEAN_PRODUCT_TECH_INFO_FILE_LIST: string = "CLEAN_PRODUCT_TECH_INFO_FILE_LIST";
export const SHOW_HIDE_PRODUCT_TECH_INFO_FILE_SECTION: string = "SHOW_HIDE_PRODUCT_TECH_INFO_FILE_SECTION";
export const SHOW_HIDE_PRODUCT_TECH_INFO_FILE_UPLOAD_ERROR_MESSAGE: string = "SHOW_HIDE_PRODUCT_TECH_INFO_FILE_UPLOAD_ERROR_MESSAGE";
export const CHANGE_PRODUCT_TECH_INFO_SEARCH_FOLDER_STRING: string = "CHANGE_PRODUCT_TECH_INFO_SEARCH_FOLDER_STRING";
export const CHANGE_PRODUCT_TECH_INFO_SEARCH_FILE_STRING: string = "CHANGE_PRODUCT_TECH_INFO_SEARCH_FILE_STRING";
export const OPEN_CLOSE_ADD_EDIT_PRODUCT_TECH_INFO_FILE_PANEL: string = "OPEN_CLOSE_ADD_EDIT_PRODUCT_TECH_INFO_FILE_PANEL";
export const OPEN_CLOSE_PRODUCT_TECH_INFO_ENTITY_DELETE_CONFIRM_MODAL: string = "OPEN_CLOSE_PRODUCT_TECH_INFO_ENTITY_DELETE_CONFIRM_MODAL";
export const OPEN_CLOSE_PRODUCT_TECH_INFO_ALERT_MODAL: string = "OPEN_CLOSE_PRODUCT_TECH_INFO_ALERT_MODAL";

export const GET_ENTITY_BY_PARENT_ID_URL: string = "document/getEntityByParentId";
export const GET_ENTITY_BY_CLIENT_ID_SITE_ID_URL: string = "document/getEntityByClientAndSiteId";
export const GET_ENTITY_BY_NAME_URL: string = "document/getEntityByName";
export const SAVE_ENTITY_URL: string = "document/saveEntity";
export const SAVE_FILE_URL: string = "document/saveFile";
export const DELETE_ENTITY_URL: string = "document/deleteEntity";
export const GET_ALL_LLG_ASSET_TYPE_URL: string = "Setting/llgAsset";

