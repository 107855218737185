import { ILoggedInUser } from "../../../CommonInterfaces/CommoInterfaces";
import axios from 'axios';
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import { ILooseGearService } from "../Interfaces/ILooseGearService";
import { ILooseGear } from "../Interfaces/ILooseGear";
import * as CONSTANTS from "../Constants/LooseGearsConstants";


export class LooseGearService implements ILooseGearService {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }

    public _getLooseGearsListApi = (offset: number, limit: number, config: any): Promise<ILooseGear[]> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_LOOSE_GEARS_LIST_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                .concat(`?offset=${offset}&limit=${limit}`)
                , config
            ).then((response: any) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getLooseGearsListApi method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public _getLooseGearsCountApi = (config: any): Promise<number> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_LOOSE_GEARS_COUNT_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                , config
            ).then((response: any) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getLooseGearsCountApi method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getLooseGearsApi = (offset: number, limit: number, config: any): Promise<any> => {

        let promises: any = [this._getLooseGearsListApi(offset, limit, config),
        this._getLooseGearsCountApi(config)];
        return Promise.all(promises);
    }


}
