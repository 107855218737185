import * as React from 'react';
import { IAssessmentStateProps } from '../Interfaces/IAssessmentStateProps';
import { IAssessmentsInitialState } from '../Interfaces/IAssessmentsInitialState';
import { IAssessmentService } from '../Interfaces/IAssessmentsService';
import { AssessmentsService } from '../Services/AssessmentsAPI';
import { Panel, PanelType, Spinner, SpinnerSize } from 'office-ui-fabric-react';

// payload.PDFURL = `${window.API_URL}filedownload;jsessionid=${window.loggedInUserDetails.jsessionid}?observationId=${payload.SelectedTask.id}&id=${payload.SelectedTask.certificateFile ? JSON.parse(payload.SelectedTask.certificateFile).blobKey : ""}`;

export class CertificatePreviewPanel extends React.PureComponent<IAssessmentStateProps, {}> {

    private _apiPayload: IAssessmentService;

    public constructor(props: IAssessmentStateProps) {
        super(props);
        this._apiPayload = new AssessmentsService(window.API_URL, window.loggedInUserDetails);
    }

    public componentDidMount(): void {
        this.loadData();
    }

    public loadData = (): void => {
        this.props.loadTaskDetails({
            apiPayload: this._apiPayload,
            selectedTaskId: this.props.AssessmentsState.selectedTaskId
        })
    }

    public render(): JSX.Element {

        let {
            isHideCertificatePanel,
            selectedTaskId,
            PDFURL,
            isLoadingCertificatePanel
        }: IAssessmentsInitialState = this.props.AssessmentsState;

        return (
            <div>
                <Panel
                    isOpen={!isHideCertificatePanel}
                    onDismiss={() => {
                        this.props.showHideCertificatePanel({
                            isHideCertificatePanel: true
                        })
                    }}
                    type={PanelType.extraLarge}
                    styles={{
                        content: {
                            height: "40em"
                        },
                        scrollableContent: {
                            height: "92vh"
                        }
                    }}
                >
                    {isLoadingCertificatePanel ?
                        <Spinner
                            size={SpinnerSize.large}
                            label="Please wait..."
                        />
                        :
                        <iframe
                            id="iframeTaskPdf"
                            title={selectedTaskId.toString()}
                            src={PDFURL}
                            // type="application/pdf"
                            className="iframe-report"
                            frameBorder={0}
                        />
                    }
                </Panel>
            </div>
        );
    }

}