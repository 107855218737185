import React, { Component } from 'react'
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { IconConstants } from '../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class LogoutConfirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogContentProps: {
                type: DialogType.largeHeader,
                title: "Confirm",
                subText: "Are you sure want to logout?"
            },
        }
    }

    logoutConfirmOrCancel = (isLogOut) => {
        let tempDialogContentProps = { ...this.state.dialogContentProps };
        tempDialogContentProps = {
            type: DialogType.largeHeader,
            title: "Please Wait",
            subText: ""
        }
        this.setState({
            dialogContentProps: tempDialogContentProps
        }, () => {
            this.props.logoutConfirmOrCancel(isLogOut);
        })
    }

    render() {
        let {
            HideLogoutConfirmationDialog,
            isLoggingOut
        } = this.props;
        return (
            <>
                <Dialog
                    hidden={HideLogoutConfirmationDialog}
                    onDismiss={() => this.logoutConfirmOrCancel(false)}
                    dialogContentProps={this.state.dialogContentProps}
                    modalProps={modalProps}
                >
                    {isLoggingOut ?
                        <Spinner
                            label="Logging out..."
                            size={SpinnerSize.large} />
                        :
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => this.logoutConfirmOrCancel(true)}
                                text="Yes"
                                menuIconProps={IconConstants.confirmIcon}
                            />
                            <DefaultButton
                                onClick={() => this.logoutConfirmOrCancel(false)}
                                text="No"
                                menuIconProps={IconConstants.cancelIcon}
                            />
                        </DialogFooter>
                    }
                </Dialog>
            </>
        )
    }
}
