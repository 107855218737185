import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoOfficeActions } from '../Actions/LoOfficeActions';
import { Breadcrumb } from 'office-ui-fabric-react';
import LoOfficeDashboard from './LoOfficeDashboard';

const mapStateToProps = (state) => {
    return {
        LoOffice: state.LoOfficeReducer
    }
}

class LoOfficeComponent extends PureComponent {

    onClickLoOfficeBreadCrumbItem = (ev, item) => {
        switch (item.key) {
            case "LOOffice":
                this.props.showLoOfficeDetailsTab({
                    SelectedLoOffice: null,
                    ShowLoOfficeDetailsTab: false
                });
                break;
            default:
                break;
        }
    }

    render() {

        let { LoOfficeBreadCrumb } = this.props.LoOffice;

        let LoOfficeConfiguredBreadCrumb = [];
        LoOfficeBreadCrumb.forEach((element, index) => {
            LoOfficeConfiguredBreadCrumb.push({
                text: element.text,
                key: element.key,
                onClick: index < LoOfficeBreadCrumb.length - 1 ? this.onClickLoOfficeBreadCrumbItem : null,
            })
        });

        return (
            <React.Fragment>
                <Breadcrumb
                    className="custom-breadcrumb"
                    items={LoOfficeConfiguredBreadCrumb}
                    ariaLabel="With last item rendered as heading"
                    overflowAriaLabel="More links"
                />
                <LoOfficeDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let loOfficeActions = new LoOfficeActions();

    return bindActionCreators({ ...loOfficeActions }, dispatch);
}

const LoOffice = connect(mapStateToProps, mapDispatchToProps)(LoOfficeComponent);
export default LoOffice;
