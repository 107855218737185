import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../../Store/store';
import Home from '../HomePage/Home.jsx';
import CustomerRegistration from '../CustomerRegistration/CustomerRegistration.jsx';
import Forms from '../Settings/Forms/Forms.jsx';
import Users from './../Users/Views/Users.jsx';
import QR from './../QRSticker/Views/QR.jsx';
import Jobs from '../Jobs/Views/Jobs.jsx';
import Tasks from '../Tasks/Views/Tasks.jsx';
import Reports from '../Reports/Views/Reports.tsx';
import Assets from '../Assets/Views/Assets.jsx';
import AssetTypes from '../Settings/AssetTypes/Views/AssetTypes.jsx';
import TaskTypes from '../Settings/TaskTypes/Views/TaskTypes.jsx';
import Configurations from '../Settings/Configurations/Views/Configurations.jsx';
import Clients from '../Clients/Views/Clients';
import Roles from '../Settings/Roles/Views/Roles.jsx';
import Division from '../Settings/Divisions/Views/Division.jsx';
import States from '../Settings/States/Views/States.jsx';
import Category from '../Settings/Category/Views/Category.jsx';
import Templates from '../Settings/Templates/Views/Templates.jsx';
import ReactTreeDemo from '../ReactTreeDemo/ReactTreeDemo';
import LooseGears from '../LooseGears/Views/LooseGears';
import LoCompany from '../LoCompany/Views/LoCompany';
import LoOffice from '../LoOffice/Views/LoOffice';
import Assessments from '../Assessment/Views/Assessments';
import SequenceNumbering from '../SequenceNumbering/Views/SequenceNumbering';
import JobLites from '../JobLites/Views/JobLites';
import TrialFitPicLibrary from '../TechnicalLibrary/TrialFitPicLibrary/Views/TrialFitPicLibrary';
import ClientInventory from '../ClientInventory/Views/ClientInventory';
import ProductTechInfo from '../TechnicalLibrary/ProductTechInfo/Views/ProductTechInfo';


export default class Main extends PureComponent {
    // shouldComponentUpdate(nextProps, nextState) {
    //     return false
    // }
    render() {
        return (
            <div>
                <main>
                    <Provider store={store}>
                        {/* <Route path="/home" exact component={() => <Home {...this.props} />} /> */}
                        <Route path="/" exact component={() => <Home {...this.props} />} />
                        <Route path="/admin/customerRegistration" component={() => <CustomerRegistration {...this.props} />} />
                        <Route path="/resources/users" component={Users} />
                       <Route path="/resources/qrsticker" component={QR} />
                        <Route path="/resources/clients" component={Clients} />
                        <Route path="/resources/assets" component={Assets} />
                        <Route path="/resources/llg" component={LooseGears} />
                        <Route path="/resources/assessment" component={Assessments} />
                        <Route path="/resources/clientInventory" component={ClientInventory} />
                        <Route path="/settings/forms" component={() => <Forms {...this.props} />} />
                        <Route path="/settings/assettypes" component={AssetTypes} />
                        <Route path="/settings/tasktypes" component={TaskTypes} />
                        <Route path="/settings/configdata" component={Configurations} />
                        <Route path="/settings/roles" component={Roles} />
                        <Route path="/settings/division" component={Division} />
                        <Route path="/settings/category" component={Category} />
                        <Route path="/admin/loCompany" component={LoCompany} />
                        <Route path="/admin/loOffice" component={LoOffice} />
                        <Route path="/admin/sequenceNumber" component={SequenceNumbering} />
                        <Route path="/operations/joblites" component={JobLites} />
                        <Route path="/settings/templates" component={Templates} />
                        <Route path="/settings/states" component={States} />
                        <Route path="/operations/jobs" component={Jobs} />
                        <Route path="/operations/tasks" component={Tasks} />
                        <Route path="/operations/reports" component={Reports} />
                        <Route path="/technicalLibrary/productTechInfo" component={ProductTechInfo} />
                        <Route path="/technicalLibrary/trialFitPicLibrary" component={TrialFitPicLibrary} />
                        <Route path="/technicalLibrary/reportLibrary" component={TrialFitPicLibrary} />
                        <Route path="/sortabletree" component={ReactTreeDemo} />
                    </Provider>
                </main>
            </div>
        )
    }
}
