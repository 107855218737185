import { ISelect } from "../../../CommonInterfaces/CommoInterfaces";
import { IClientSiteSelectorInitialState } from "../ClientSiteSelectorInteface/IClientSiteSelector";

export const ClientSiteSelectorInitialState: IClientSiteSelectorInitialState = {
    clients: [] as ISelect[],
    sites: [] as ISelect[],
    selectedClient: null,
    selectedSite: null,
    isLoadingClients: true,
    isLoadingSites: false,
    alertJSON: {
        alertHeader: "",
        alertSubText: "",
        hideAlert: true
    }
}