import React, { Component } from 'react';
import { Panel, TextField, ChoiceGroup, PrimaryButton, initializeIcons, PanelType, ActionButton, Image, Label, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { UserTypeChoice, ProjectPhaseChoice } from './CustomerConstant';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import ImageUploader from 'react-images-upload';
import { CustomerAPI } from './CustomerAPI';
import { IconConstants } from '../../Constants/StylesConstants';

export default class CustomerAddEdit extends Component {

    _customerAPI = null;
    constructor(props) {
        super(props);
        initializeIcons();
        this._customerAPI = new CustomerAPI(window.API_URL, {
            headers: {
            }
        });
        this.state = {
            isLoadingImage: true,
            SelectedCustomer: {
                name: "",
                email: "",
                mobilenumber: "",
                namespace: "",
                companyname: "",
                companyaddress: "",
                companywebsite: "",
                projectname: "",
                projectaddress: "",
                projectreraregistration: "",
                currentprojectphase: "",
                sourcetype: "",
                userdesignation: "",
                loCompanyLogo: [],
            },
            imageUploadErrorMessage: ""
        }
    }

    async UNSAFE_componentWillMount() {

        let SelectedCustomer = { ...this.state.SelectedCustomer };
        SelectedCustomer = this.props.SelectedCustomer;

        this.setState({
            SelectedCustomer,
            isLoadingImage: true
        }, async () => {
            let imageData = await this._customerAPI._getImageOfCustomer(SelectedCustomer.id);
            let imageURL = `data:${imageData.fileType};base64,${imageData.loComanyLogo}`;
            SelectedCustomer.loCompanyLogo = imageData.loComanyLogo ? imageURL : "";

            this.setState({
                SelectedCustomer,
                isLoadingImage: false
            })

        })

    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let SelectedCustomer = { ...this.state.SelectedCustomer };
        SelectedCustomer = nextProps.SelectedCustomer;

        this.setState({
            SelectedCustomer,
            isLoadingImage: true
        }, async () => {
            let imageData = await this._customerAPI._getImageOfCustomer(SelectedCustomer.id);
            let imageURL = `data:${imageData.fileType};base64,${imageData.loComanyLogo}`;
            SelectedCustomer.loCompanyLogo = imageData.loComanyLogo ? imageURL : "";

            this.setState({
                SelectedCustomer,
                isLoadingImage: false
            })
        })
    }

    onDropCustomerLogo = (pictures) => {

        let SelectedCustomer = { ...this.state.SelectedCustomer };

        if (pictures.length > 0) {
            this.setState({
                isLoadingImage: true
            })
            const uploadImageData = new FormData();
            uploadImageData.append('imageFile', pictures[0],
                pictures[0].name);
            this._customerAPI._uploadImageOfCustomer(SelectedCustomer.id, uploadImageData).then(response => {
                let imageData = `data:${response.fileType};base64,${response.loComanyLogo}`;
                SelectedCustomer.loCompanyLogo = imageData;
                sessionStorage.setItem("isNewImageUpoaded", "1");
                this.setState({
                    SelectedCustomer,
                    isLoadingImage: false,
                    imageUploadErrorMessage: ""
                });

            }).catch(error => {
                console.log("Error: ", error);
                this.setState({
                    isLoadingImage: false,
                    imageUploadErrorMessage: "Error While Uploading Image"
                })
            });

        }

    }

    render() {

        let {
            HeaderText,
            isOpenAddEditCustomerPanel,
            closeAddEditCustomerPanel,
            loadingAddEditPanel
        } = this.props;

        let {
            SelectedCustomer,
            isLoadingImage,
            imageUploadErrorMessage
        } = this.state

        return (
            <Panel
                headerText={HeaderText}
                isOpen={isOpenAddEditCustomerPanel}
                type={PanelType.medium}
                onDismiss={() => closeAddEditCustomerPanel(false)}
                closeButtonAriaLabel="Close">
                <LoadingOverlay
                    active={loadingAddEditPanel}
                    spinner={<FadeLoader />}
                    text='Saving Data...'>
                    <ActionButton
                        text="Save"
                        iconProps={IconConstants.saveicon}
                        onClick={() => closeAddEditCustomerPanel(true, SelectedCustomer)}
                    />
                    <br />
                    {SelectedCustomer.id &&
                        <React.Fragment>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>Logo:</Label>
                                    </div>
                                </div>
                                {isLoadingImage ?
                                    <Spinner size={SpinnerSize.large} label={`Loading Logo`} />
                                    :
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                            {SelectedCustomer.loCompanyLogo ?
                                                <Image
                                                    src={SelectedCustomer.loCompanyLogo}
                                                    alt="No Logo Available"
                                                    styles={{
                                                        image: {
                                                            display: "block",
                                                            maxWidth: "17em",
                                                            maxHeight: "2.85em",
                                                            width: "auto",
                                                            height: "auto"
                                                        }
                                                    }}
                                                />
                                                :
                                                <small>No Logo Available</small>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            {!isLoadingImage &&
                                <>
                                    <ImageUploader
                                        withIcon={false}
                                        withLabel={false}
                                        buttonText='Upload Customer Logo'
                                        onChange={this.onDropCustomerLogo}
                                        imgExtension={['.jpg', '.png']}
                                        maxFileSize={5242880}
                                        singleImage={true}
                                    />
                                    <span className="mandatory-span">{imageUploadErrorMessage}</span>
                                </>
                            }
                        </React.Fragment>
                    }
                    <TextField
                        value={SelectedCustomer.name}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.name = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Contact Name:" />
                    <TextField
                        value={SelectedCustomer.namespace}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.namespace = value;
                            SelectedCustomer.email = `${value}@qwikspec.com`;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Namespace" />
                    <TextField
                        readOnly
                        value={SelectedCustomer.email}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.email = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Email:" />
                    <TextField
                        value={SelectedCustomer.mobilenumber}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.mobilenumber = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Mobile Number:" />
            
                    <ChoiceGroup
                        selectedKey={SelectedCustomer.sourcetype}
                        options={UserTypeChoice}
                        onChange={(event, option) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.sourcetype = option.key;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="User Type:"
                        required={true} />
                    <TextField
                        value={SelectedCustomer.companyname}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.companyname = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Company Name" />
                    <TextField
                        value={SelectedCustomer.companyaddress}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.companyaddress = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Company Address" />
                    <TextField
                        value={SelectedCustomer.companywebsite}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.companywebsite = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Company Website:" />
                    <TextField
                        value={SelectedCustomer.projectname}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.projectname = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Project / Office Name:" />
                    <TextField
                        value={SelectedCustomer.projectaddress}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.projectaddress = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Project / Office Address:" />
                    <TextField
                        value={SelectedCustomer.projectwebsite}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.projectwebsite = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Project / Office Website:" />
                    <TextField
                        value={SelectedCustomer.projectreraregistration}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.projectreraregistration = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="Project Office Code:" />
                    <ChoiceGroup
                        selectedKey={SelectedCustomer.currentprojectphase}
                        options={ProjectPhaseChoice}
                        onChange={(ev, option) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.currentprojectphase = option.key;
                            this.setState({
                                SelectedCustomer
                            });

                        }}
                        label="Current Project Phase:"
                        required={true} />
                    <TextField
                        value={SelectedCustomer.userdesignation}
                        onChange={(event, value) => {
                            let SelectedCustomer = { ...this.state.SelectedCustomer };
                            SelectedCustomer.userdesignation = value;
                            this.setState({
                                SelectedCustomer
                            });
                        }}
                        label="User Designation:" />
                    <br />
                    <PrimaryButton
                        onClick={() => closeAddEditCustomerPanel(true, SelectedCustomer)}
                        text="Save"
                        menuIconProps={IconConstants.saveicon}
                    />
                </LoadingOverlay>
            </Panel>
        )
    }
}
