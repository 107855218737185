import { IReportsActionKeys } from "../Interfaces/IReportsActions";
import { IReportsPayload } from "../Interfaces/IReportsPayload";
import * as CONSTANTS from "../Constants/ReportsConstants";
import { Dispatch } from "redux";
import { IReportStateProps } from "../Interfaces/IReportStateProps";

export class ReportsActions implements IReportStateProps {

    public cleanReportDashboard = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.CLEAN_REPORT_DASHBOARD, payload }
    }
    
    public showLoaderDashboard = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.SHOW_LOADER_DASHBOARD, payload }
    }

    public loadReportDashboard = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.LOAD_REPORT_DASHBOARD, payload }
    }

    public loadReportDashboardAsync = (payload: IReportsPayload): any => {
        return (dispatch: Dispatch) => {
            payload.isLoading = true;
            dispatch(this.showLoaderDashboard(payload));
            if (!payload.offset) {
                dispatch(this.cleanReportDashboard(payload));
            }
            payload.apiPayload.getTaskDetails(payload.config, payload.offset, payload.limit).then((response) => {
                payload.isLoading = false;
                payload.reports = response[0].data;
                // payload.TotalClientCount = response[1].data;
                dispatch(this.loadReportDashboard(payload));
            }).catch((error: any) => {
                console.error("Error: ", error);
                // payload.alertJSON = {
                //     hideAlert: false,
                //     alertHeader: "Ooops!!",
                //     alertSubText: "Error while loading Clients"
                // }
                // dispatch(this.openCloseAlertModal(payload));

                // setTimeout(() => {
                //     payload.alertJSON = {
                //         hideAlert: true,
                //         alertHeader: "",
                //         alertSubText: ""
                //     }
                //     dispatch(this.openCloseAlertModal(payload));
                // }, 2000);
            })
        }
    }

    public handleReportsGridGroupSortChanged = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.HANDLE_REPORT_GRID_GROUP_SORT_CHANGE, payload }
    }

    public handleReportsGridFilterChangedAsync = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.HANDLE_REPORT_GRID_FILTER_CHANGE, payload }
    }

    public handleReportsGridFilterChanged = (payload: IReportsPayload): any => {
        return (dispatch: any) => {
            dispatch(this.handleReportsGridFilterChangedAsync(payload));
            payload.apiPayload.getTaskDetails(payload.config, payload.offset, payload.limit).then((response: any) => {
                payload.reports = response[0].data;
                dispatch(this.loadReportDashboard(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                // payload.alertJSON = {
                //     hideAlert: false,
                //     alertHeader: "Ooops!!",
                //     alertSubText: "Error while loading Clients"
                // }
                // dispatch(this.openCloseAlertModal(payload));

                // setTimeout(() => {
                //     payload.alertJSON = {
                //         hideAlert: true,
                //         alertHeader: "",
                //         alertSubText: ""
                //     }
                //     dispatch(this.openCloseAlertModal(payload));
                // }, 2000);
            })
        }
    }

    public handleReportsGridExpandChanged = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.HANDLE_REPORT_GRID_EXPAND_CHANGE, payload }
    }

    public showHideCertificatePanel = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.SHOW_HIDE_REPORT_CERTIFICATE_PANEL, payload }
    }

    public switchTaskAndServiceReportAsync = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.SWITCH_TASK_AND_SERVICE_REPORT, payload }
    }

    public switchTaskAndServiceReport = (payload: IReportsPayload): any => {
        return (dispatch: any) => {
            payload.isLoading = true;
            dispatch(this.cleanReportDashboard(payload));
            dispatch(this.switchTaskAndServiceReportAsync(payload));
            payload.apiPayload.getTaskDetails(payload.config, payload.offset, payload.limit).then((response: any) => {
                payload.isLoading = false;
                payload.reports = response[0].data;
                dispatch(this.loadReportDashboard(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                // payload.alertJSON = {
                //     hideAlert: false,
                //     alertHeader: "Ooops!!",
                //     alertSubText: "Error while loading Clients"
                // }
                // dispatch(this.openCloseAlertModal(payload));

                // setTimeout(() => {
                //     payload.alertJSON = {
                //         hideAlert: true,
                //         alertHeader: "",
                //         alertSubText: ""
                //     }
                //     dispatch(this.openCloseAlertModal(payload));
                // }, 2000);
            })
        }
    }

    public loadTaskDetails = (payload: IReportsPayload): any => {

        return (dispatch: any) => {
            payload.isLoading = true;
            dispatch(this.loadTaskDetailsAsync(payload));
            payload.apiPayload.getSelectedObservationAPI(payload.selectedTaskId).then((response: any) => {
                payload.isLoading = false;
                payload.task = response.data;
                dispatch(this.loadTaskDetailsAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                // payload.alertJSON = {
                //     hideAlert: false,
                //     alertHeader: "Ooops!!",
                //     alertSubText: "Error while loading Clients"
                // }
                // dispatch(this.openCloseAlertModal(payload));

                // setTimeout(() => {
                //     payload.alertJSON = {
                //         hideAlert: true,
                //         alertHeader: "",
                //         alertSubText: ""
                //     }
                //     dispatch(this.openCloseAlertModal(payload));
                // }, 2000);
            })
        }
    }

    public loadTaskDetailsAsync = (payload: IReportsPayload): IReportsActionKeys => {
        return { type: CONSTANTS.LOAD_TASK_DETAILS, payload }
    }


}
