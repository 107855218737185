import React, { Component } from 'react'
import { DivisionAPI } from '../Services/DivisionAPI';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Description from './Description';
import { Panel, PanelType, Spinner, SpinnerSize, ActionButton, PrimaryButton } from 'office-ui-fabric-react';
import * as DivisionConstants from './../Constants/DivisionConstants';
import AlertRedux from '../../../Alert/AlertRedux';
import { IconConstants } from '../../../../Constants/StylesConstants';

export default class AddEditDivisionPanel extends Component {

    _divisionAPI = null;
    constructor(props) {
        super(props);
        this._divisionAPI = new DivisionAPI (window.API_URL, window.loggedInUserDetails);
    }

    // UNSAFE_componentWillMount() {
    //     this.props.loadDropDownDataAsync({
    //         apiObject: this._divisionAPI,
    //     });
    // }

    render() {

        let {
            PanelHeaderText,
            ShowDivisionAddEditPanel,
            TabIndex,
            LoadingDivisionAddEditPanel,
            CurrentDivision,
            alertJSON
        } = this.props.Division

        return (
            <Panel
                headerText={PanelHeaderText + (CurrentDivision.id > -1 ? CurrentDivision.name : "")}
                isOpen={ShowDivisionAddEditPanel}
                type={PanelType.medium}
                onDismiss={() => this.props.closeDivisionPanel({
                    ShowPanel: false,
                    PanelHeaderText: "",
                })}
                closeButtonAriaLabel="Close">
                {LoadingDivisionAddEditPanel ?
                    <Spinner
                        size={SpinnerSize.large}
                        label="Please wait..."
                    />
                    :
                    <React.Fragment>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ActionButton
                                    disabled={!CurrentDivision.name}
                                    text="SAVE"
                                    iconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveDivisionDetailsAsync({
                                            CurrentDivision: CurrentDivision,
                                            apiObject: this._divisionAPI
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <Tabs selectedIndex={TabIndex} onSelect={(index) => this.props.changeDivisionTab({ TabIndex: index })}>
                            <TabList>
                                <Tab>{DivisionConstants.DIVISION_FORM_TABS[0]}</Tab>
                               {/*} <Tab>{DivisionConstants.DIVISION_TABS[1]}</Tab>
                                <Tab disabled>{DivisionConstants.DIVISION_TABS[2]}</Tab>*/}
                            </TabList>

                            <TabPanel>
                                <Description
                                    {...this.props}
                                />
                            </TabPanel>

                          { /* <TabPanel>
                                <Attributes
                                    {...this.props}
                                />
                            </TabPanel>

                           <TabPanel>
                                <DivisionFiles
                                    {...this.props}
                                />
                          </TabPanel>*/}

                        </Tabs>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    disabled={!CurrentDivision.name}
                                    text="SAVE"
                                    menuIconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveDivisionDetailsAsync({
                                            CurrentDivision: CurrentDivision,
                                            apiObject: this._divisionAPI
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                }
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </Panel>
        )
    }
}
