import { PrimaryButton, Separator, Spinner, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { IconConstants, seperatorTheme } from '../../../Constants/StylesConstants';
import { IJobLiteService } from '../Interfaces/IJobLitesService';
import { JobLitesAPI } from '../Services/JobLitesAPI';

export default class NewUserDetails extends React.Component<any, any> {

    private _apiObject: IJobLiteService;
    constructor(props: any) {
        super(props);
        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails);
    }


    public render(): JSX.Element {

        let {
            CurrentJobDetails,
            LoadingNewUserSection,
            NewUserSaveStatus,
            NewUser
        } = this.props.JobLites;

        return (
            <React.Fragment>
                {LoadingNewUserSection ?
                    <Spinner label="Saving New Requester..." />
                    :
                    <div className="ms-Grid add-extra-pane">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Separator theme={seperatorTheme}>Add New Requester</Separator>

                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <small className="mandatory-span">
                                    {NewUserSaveStatus.message}
                                </small>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    label="Name:"
                                    required
                                    value={NewUser.name}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewUserField({
                                            field: "name",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    label="Email:"
                                    required
                                    value={NewUser.email}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewUserField({
                                            field: "email",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    label="Mobile:"
                                    value={NewUser.phone}
                                    onChange={(ev, newVal) => {
                                        this.props.changeNewUserField({
                                            field: "phone",
                                            value: newVal
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <br />

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    text="Save"
                                    iconProps={IconConstants.saveicon}
                                    disabled={
                                        !NewUser.name ||
                                        !NewUser.email
                                    }
                                    onClick={() => {
                                        this.props.saveNewUserJobAsync({
                                            apiObject: this._apiObject,
                                            newUser: NewUser,
                                            Client: CurrentJobDetails.ClientName ? CurrentJobDetails.ClientName.value : -1,
                                            Location: CurrentJobDetails.SiteDetails ? CurrentJobDetails.SiteDetails.value : -1
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}
