import { IKendoGridColumn } from "../../../CommonInterfaces/CommoInterfaces";

export const CLEAN_SEQUENCE_NUMBERING_DASHBOARD: string = 'CLEAN_SEQUENCE_NUMBERING_DASHBOARD';
export const LOAD_SEQUENCE_NUMBERING_DASHBOARD: string = 'LOAD_SEQUENCE_NUMBERING_DASHBOARD';
export const CHANGE_SEQUENCE_NUMBER: string = 'CHANGE_SEQUENCE_NUMBER';
export const OPEN_CLOSE_SEQUENCE_ALERT_MODAL: string = 'OPEN_CLOSE_SEQUENCE_ALERT_MODAL';
export const SHOW_HIDE_SEQUENCE_NUMBERING_ADD_EDIT_PANEL: string = 'SHOW_HIDE_SEQUENCE_NUMBERING_ADD_EDIT_PANEL';
export const HANDLE_SEQUENCE_NUMBERING_GRID_EXPAND_CHANGE: string = "HANDLE_SEQUENCE_NUMBERING_GRID_EXPAND_CHANGE";
export const HANDLE_SEQUENCE_NUMBERING_GRID_FILTER_CHANGE: string = "HANDLE_SEQUENCE_NUMBERING_GRID_FILTER_CHANGE";
export const HANDLE_SEQUENCE_NUMBERING_GRID_GROUP_SORT_CHANGE: string = "HANDLE_SEQUENCE_NUMBERING_GRID_GROUP_SORT_CHANGE";
export const SAVE_SEQUENCE_NUMBER: string = "SAVE_SEQUENCE_NUMBER";

export const GET_SAVE_SEQUENCE_URL: string = 'sequence';


export const SEQUENCE_NUMBERING_BREADCRUMBS = [
    { text: 'Admin', key: 'Admin' },
    { text: 'Sequence Numbering', key: 'SequenceNumbering' }
];

// Sequence Kendo Grid Columns
export const SEQUENCE_NUMBERING_VIEW_GRID_COLUMNS: IKendoGridColumn[] = [
    {
        title: "Edit",
        field: "id",
        show: true,
        filter: "text",
        width: 80,
        colIndex: 1
    },
    {
        title: "Id",
        field: "id",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 2
    },
    {
        title: "Office",
        field: "officeName",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 3
    },
    {
        title: "Division",
        field: "divisionName",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 4
    },
    {
        title: "Site",
        field: "site",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 5
    },
    {
        title: "Sequence Name",
        field: "sequenceName",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 6
    },
    {
        title: "Current Number",
        field: "sequenceNumber",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 7
    },
];
