import { Dispatch } from "redux"
import { IClientInventoryStateProps } from "../ClientInventoryInteface/IClientInventoryStateProps";
import { IClientInventoryActionKeys, IClientInventoryPayload } from "../ClientInventoryInteface/IClientInventory";
import * as CONSTANTS from '../Constants/ClientInventoryConstants';

export class ClientInventoryActions implements IClientInventoryStateProps {

    public resetClientInventoryDashboard = (payload: IClientInventoryPayload): IClientInventoryActionKeys => {
        return { type: CONSTANTS.RESET_CLIENT_INVENTORY_DASHBOARD, payload }
    }

    public loadClientInventoryAsync = (payload: IClientInventoryPayload): IClientInventoryActionKeys => {
        return { type: CONSTANTS.LOAD_CLIENT_INVENTORY, payload }
    }

    public loadClientInventory = (payload: IClientInventoryPayload): any => {
        return (dispatch: Dispatch) => {
            payload.isOngoingLoad = true;
            dispatch(this.resetClientInventoryDashboard(payload));
            payload.apiObject.loadClientInventoryAPI(payload.clientId, payload.siteId).then((response) => {
                payload.isOngoingLoad = false;
                payload.clientInventories = response.data;
                dispatch(this.loadClientInventoryAsync(payload));
            }).catch((error: any) => {
                console.error("Error while loading client inventory: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading client inventory"
                }
                dispatch(this.openCloseClientInventoryAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseClientInventoryAlertModal(payload));
                }, 2000);
            })
        }
    }

    public saveClientInventoryAsync = (payload: IClientInventoryPayload): IClientInventoryActionKeys => {
        return { type: CONSTANTS.SAVE_CLIENT_INVENTORY, payload }
    }

    public saveClientInventory = (payload: IClientInventoryPayload): any => {
        return (dispatch: Dispatch) => {
            payload.isOngoingLoad = true;
            dispatch(this.saveClientInventoryAsync(payload));
            payload.apiObject.saveClientInventory(payload.clientInventories).then((response) => {
                payload.isOngoingLoad = false;
                payload.clientInventories = response.data;
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: "Data Saved Successfully"
                }
                dispatch(this.openCloseClientInventoryAlertModal(payload));
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseClientInventoryAlertModal(payload));
                    dispatch(this.saveClientInventoryAsync(payload));
                }, 2000);
            }).catch((error: any) => {
                console.error("Error while saving client inventory: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while saving client inventory"
                }
                dispatch(this.openCloseClientInventoryAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseClientInventoryAlertModal(payload));
                }, 2000);
            })
        }
    }

    public searchClientInventory = (payload: IClientInventoryPayload): IClientInventoryActionKeys => {
        switch (payload.field) {
            case CONSTANTS.PRODUCT_CATEGORY:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_ITEM_SEARCH, payload }

            case CONSTANTS.DESCRIPTION:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_DESCRIPTION_SEARCH, payload }

            case CONSTANTS.QUANTITY:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_QUANTITY_SEARCH, payload }
            case CONSTANTS.TONNE:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_TONNE_SEARCH, payload }
            case CONSTANTS.MANUFACTURER:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_MANUFACTURER_SEARCH, payload }
            case CONSTANTS.LOCATION:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_LOCATION_SEARCH, payload }
            case CONSTANTS.COMMENTS:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_COMMENTS_SEARCH, payload }

            default:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_QUANTITY_SEARCH, payload };
        }
    }

    public changeClientInventory = (payload: IClientInventoryPayload): IClientInventoryActionKeys => {
        switch (payload.field) {
            case CONSTANTS.PRODUCT_CATEGORY:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_ITEM, payload }

            case CONSTANTS.DESCRIPTION:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_DESCRIPTION, payload }

            case CONSTANTS.QUANTITY:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_QUANTITY, payload }
            case CONSTANTS.TONNE:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_TONNE, payload }

             case CONSTANTS.MANUFACTURER:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_MANUFACTURER, payload }

                case CONSTANTS.LOCATION:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_LOCATION, payload }

                case CONSTANTS.COMMENTS:
                    return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_COMMENTS, payload }
    

            case CONSTANTS.IS_ENABLED_EDIT:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_ROW_EDIT, payload }

            default:
                return { type: CONSTANTS.CHANGE_CLIENT_INVENTORY_QUANTITY, payload };
        }
    }

    public openCloseClientInventoryAlertModal = (payload: IClientInventoryPayload): IClientInventoryActionKeys => {
        return { type: CONSTANTS.OPEN_CLOSE_CLIENT_INVENTORY_ALERT_MODAL, payload };
    }

    public openCloseClientInventoryConfirmDeletetModal = (payload: IClientInventoryPayload): IClientInventoryActionKeys => {
        return { type: CONSTANTS.OPEN_CLOSE_CLIENT_INVENTORY_CONFIRM_DELETE_MODAL, payload };
    }

    public deleteClientInventoryByIdAsync = (payload: IClientInventoryPayload): IClientInventoryActionKeys => {
        return { type: CONSTANTS.DELETE_CLIENT_INVENTORY, payload }
    }

    public deleteClientInventoryById = (payload: IClientInventoryPayload): any => {
        return (dispatch: Dispatch) => {
            payload.isOngoingLoad = true;
            dispatch(this.resetClientInventoryDashboard(payload));
            payload.apiObject.deleteClientInventoryByIdAPI(payload.selectedClientInventory.id).then((response) => {
                payload.isOngoingLoad = false;
                payload.clientInventories = response.data;
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: "Data Deleted Successfully"
                }
                dispatch(this.openCloseClientInventoryAlertModal(payload));
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseClientInventoryAlertModal(payload));
                    dispatch(this.deleteClientInventoryByIdAsync(payload));
                }, 2000);
            }).catch((error: any) => {
                console.error("Error while deleting client inventory: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while deleting client inventory"
                }
                dispatch(this.openCloseClientInventoryAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseClientInventoryAlertModal(payload));
                }, 2000);
            })
        }
    }


}
