//export const OPEN_ROLE_DASHBOARD: string = "OPEN_ROLE_DASHBOARD";

export const CHANGE_ROLE_TAB = "CHANGE_ROLE_TAB";
export const CLOSE_ROLE_DETAILS_PANEL = "CLOSE_ROLE_DETAILS_PANEL";

//DASHBOARD
export const CLEAN_ROLE_DASHBOARD = "CLEAN_ROLE_DASHBOARD";

export const LOAD_ROLE_DASHBOARD = "LOAD_ROLE_DASHBOARD";
export const FIX_DIVISIION_DASHBOARD = "FIX_ROLE_DASHBOARD";
export const SORT_ROLE_DASHBOARD = "SORT_ROLE_DASHBOARD";
export const OPEN_CLOSE_ROLE_ALERT_MODAL = "OPEN_CLOSE_ROLE_ALERT_MODAL";
export const TOGGLE_ROLE_VIEW = "TOGGLE_ROLE_VIEW";

//PANEL
export const LOAD_ROLE_ATTRIBUTES = "LOAD_ROLE_ATTRIBUTES";

//Description
export const CHANGE_ROLE_NAME = "CHANGE_ROLE_NAME";
export const CHANGE_ROLE_ACRONYM_CODE = "CHANGE_ROLE_ACRONYM_CODE";
export const CHANGE_ROLE_TYPE = "CHANGE_ROLE_TYPE";
export const LOAD_TENANT_CLIENTS = "LOAD_TENANT_CLIENTS";
export const CHANGE_SEARCHED_CLIENT = "CHANGE_SEARCHED_CLIENT";
//export const CHANGE_ROLE_GROUP_NAME = "CHANGE_ROLE_GROUP_NAME";
//export const CHANGE_ROLE_CATEGORY = "CHANGE_ROLE_CATEGORY";
export const CHANGE_ROLE_LICENSE_COUNT = "CHANGE_ROLE_LICENSE_COUNT";
export const CHANGE_ROLE_CLIENT_ACTIVATION = "CHANGE_ROLE_CLIENT_ACTIVATION";
//export const CHANGE_ROLE_DISPLAY_NAME = "CHANGE_ROLE_DISPLAY_NAME";

//PREVIEW
export const CHANGE_ROLE_FORM_ATTRIBUTE = "CHANGE_ROLE_FORM_ATTRIBUTE";

// SAVE
export const SAVE_ROLE_DETAILS = "SAVE_ROLE_DETAILS";

// DELETE
export const OPEN_CLOSE_ROLE_CONFIRM_MODAL = "OPEN_CLOSE_ROLE_CONFIRM_MODAL";
export const DELETE_ROLE = "DELETE_ROLE";

//Filter
export const CHANGE_ROLE_NAME_FILTER_VALUE = "CHANGE_ROLE_NAME_FILTER_VALUE";
export const CHANGE_CUSTOMER_NAME_FILTER_VALUE = "CHANGE_CUSTOMER_NAME_FILTER_VALUE";

//URL
//export const GET_SEARCH_ROLE_COUNT = "Settings/Others/count";
export const GET_ROLES_URL = "Setting";
export const GET_ALL_ROLES_URL: string = "Setting/Others";
export const GET_ALL_ROLES_WITH_NAMESPACE_URL: string = "Setting/Namespace";
export const GET_FORM_ATTRIBUTE_LIST = "Setting/FormObject";

export const ROLE_FORM_TABS = {
  0: "DESCRIPTION",
  1: "CODE",
  2: "NAME",
};

export const ROLE_TYPE: any ={
  0: "CLIENT",
  1: "LO",
};
