import React from 'react'
import { IAssessmentStateProps } from '../Interfaces/IAssessmentStateProps'
import * as Loaders from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { Label } from 'office-ui-fabric-react';
import Select from 'react-select';
import { ISelect } from '../../../CommonInterfaces/CommoInterfaces';
import { IAssetAPI } from '../../Assets/AssetInterface/IAsset';
import { IAssessmentService } from '../Interfaces/IAssessmentsService';
import { AssessmentsService } from '../Services/AssessmentsAPI';
import { AssetAPI } from '../../Assets/Services/AssetAPI';
import AssessmentsDasboard from './AssessmentsDasboard';
import AlertRedux from '../../Alert/AlertRedux';
import { AssessmentsInitialState } from '../Constants/AssessmentsInitialState';
import { CLIENT_ADMIN_ROLE, CLIENT_USER_ROLE } from '../../../Constants/CommonConstants';

export default class AssessmentMainFilter extends React.Component<IAssessmentStateProps, any> {

    private _assessmentApi: IAssessmentService = null;
    private _assetApi: IAssetAPI = null;

    constructor(props: IAssessmentStateProps) {
        super(props);
        this._assessmentApi = new AssessmentsService(window.API_URL, window.loggedInUserDetails);
        this._assetApi = new AssetAPI(window.API_URL, window.loggedInUserDetails);

    }

    public componentDidMount(): void {
        this.loadInitData();
    }

    public loadInitData = (): void => {
        this.props.loadClientsAssessment({
            apiPayload: this._assessmentApi,
            assetApi: this._assetApi
        })

    }

    public render(): JSX.Element {

        let {
            isLoadingAssessmentClients,
            isLoadingAssessmentSites,
            selectedAssessmentClient,
            selectedAssessmentSite,
            assessmentClientFilters,
            assessmentSiteFilters,
            isLoadingAssessmentsDashboard,
            alertJSON,
            offset,

        } = this.props.AssessmentsState;

        const isClientRole =
            window.loggedInUserDetails.role === CLIENT_ADMIN_ROLE || window.loggedInUserDetails.role === CLIENT_USER_ROLE;

        return (
            <React.Fragment>
                {/* Main Filter */}
                <div className="ms-Grid asset-main-filter-grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Client: <span className="mandatory-span">*</span></Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                            <LoadingOverlay
                                active={isLoadingAssessmentClients}
                                spinner={<Loaders.BeatLoader />}
                                text="">
                                <Select
                                    value={selectedAssessmentClient}
                                    placeholder={assessmentClientFilters.length > 0 ? `----Select Client----` : ``}
                                    onChange={(newVal: ISelect) => {
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify({
                                                    clientNumber: newVal.value
                                                })
                                            }
                                        }
                                        this.props.changeAssessmentsClientFilter({
                                            apiPayload: this._assessmentApi,
                                            assetApi: this._assetApi,
                                            selectedLocation: newVal,
                                            offset: AssessmentsInitialState.offset,
                                            config: config,
                                        })
                                    }}
                                    options={isClientRole ?
                                        CommonUtility.sortByColumn(assessmentClientFilters
                                            .filter((client: ISelect) => window.loggedInUserDetails.companyId === client.value), "label", "asc")
                                        :
                                        CommonUtility.sortByColumn(assessmentClientFilters, "label", "asc")

                                    }
                                />

                            </LoadingOverlay>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Site:</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 asset-site-filter">
                            <LoadingOverlay
                                active={isLoadingAssessmentSites}
                                spinner={<Loaders.BeatLoader />}
                                text="">
                                <Select
                                    isMulti
                                    isDisabled={!selectedAssessmentClient || isLoadingAssessmentsDashboard}
                                    value={selectedAssessmentSite}
                                    placeholder={assessmentSiteFilters.length > 0 ? `----Select Site----` : ``}
                                    onChange={(newVal: ISelect[]) => {

                                        let siteNumbers = newVal?.filter((location: ISelect) => location.value > 0)
                                            .map((location: ISelect) => location.value).map(Number);

                                        let config = {
                                            headers: {
                                                filter: JSON.stringify({
                                                    siteNumbers: siteNumbers && siteNumbers.length ? siteNumbers : "",
                                                    clientNumber: selectedAssessmentClient.value
                                                })
                                            }
                                        }
                                        this.props.changeAssessmentsSiteFilter({
                                            apiPayload: this._assessmentApi,
                                            selectedSites: siteNumbers,
                                            selectedLocations: newVal,
                                            offset: offset,
                                            config: config
                                        })
                                    }}
                                    options={CommonUtility.sortByColumn(assessmentSiteFilters, "label", "asc")}
                                />
                            </LoadingOverlay>
                            {
                                !alertJSON.hideAlert &&
                                <AlertRedux
                                    {...this.props}
                                />
                            }
                        </div>

                    </div>
                </div>

                {/* Dashboard */}
                {selectedAssessmentClient &&
                    <AssessmentsDasboard
                        {...this.props}
                    />
                }

            </React.Fragment>
        )
    }
}
