import React, { Component } from 'react'
import Select from 'react-select';
import { TextField, Label, Icon } from 'office-ui-fabric-react';
import { CurrencyOptions } from '../Constants/JobConstants';
import { JobAPI } from '../Services/JobAPI';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { FIELD_ENGG_ROLE } from '../../../Constants/CommonConstants';

export default class TrackingDetails extends Component {

    _apiObject = null;
    constructor(props) {
        super(props);
        let config = {
            headers: {}
        }
        this._apiObject = new JobAPI(window.API_URL, config)
    }

    render() {
        let {
            OfficeOptions,
            CurrentJobDetails,
            SelectedJob,
            RequesterOptions,
        } = this.props.Job;

        const loggedInUser = window.loggedInUserDetails;

        let currentUserOfficeIds = [...new Set(loggedInUser.secondaryOfficeIds
            .split(",").map(officeId => +officeId).concat(loggedInUser.primaryOfficeId))];

        let filteredOfficeOptions = [];

        OfficeOptions.forEach((office) => {
            if (currentUserOfficeIds.indexOf(office.value) > -1) {
                filteredOfficeOptions.push(office);
            }
        });

        return (

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label>Lo Office for Job <span className="mandatory-span">*</span></Label>
                        <Select
                            value={CurrentJobDetails.hasOwnProperty("AssignOffice") ? CurrentJobDetails.AssignOffice.value : null}
                            onChange={(value) => {
                                if (value) {
                                    this.props.changeAssignOffice({
                                        AssignOffice: value
                                    })
                                }
                            }}
                            options={CommonUtility.sortByColumn(filteredOfficeOptions, "label", "asc")}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <TextField
                            label="Job Number"
                            // prefix={
                            //     SelectedJob ?
                            //         OfficeOptions.find(d => d.value === SelectedJob.officeId).label :
                            //         CurrentJobDetails.hasOwnProperty("AssignOffice") ?
                            //             CurrentJobDetails.AssignOffice.value ?
                            //                 CurrentJobDetails.AssignOffice.value.label :
                            //                 "" :
                            //             ""
                            // }
                            value={CurrentJobDetails.hasOwnProperty("JobNumber") ? CurrentJobDetails.JobNumber.value : ""}
                            onChange={(event, newval) => {
                                this.props.changeJobNumber({
                                    JobNumber: newval
                                });
                            }}
                            readOnly
                        />
                        <small className="info-span"><Icon iconName="info" /> Job Number will be auto generated</small>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label>Sales Person</Label>
                        <Select
                            value={CurrentJobDetails.hasOwnProperty("SalesPerson") ? CurrentJobDetails.SalesPerson.value : null}
                            // isDisabled={SelectedJob}
                            onChange={(value) => {
                                if (value) {
                                    this.props.changeSalesPerson({
                                        SalesPerson: value
                                    })
                                }
                            }}
                            options={CommonUtility.sortByColumn(RequesterOptions.filter(d => d.type === 0), "label", "asc")}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            label="Price"
                            type="number"
                            min={1}
                            value={CurrentJobDetails.hasOwnProperty("Price") ? CurrentJobDetails.Price.value : 0}
                            onChange={(event, newval) => {
                                this.props.changePrice({
                                    Price: newval
                                });
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>Currency</Label>
                        <Select
                            value={CurrentJobDetails.hasOwnProperty("Currency") ? CurrentJobDetails.Currency.value : null}
                            onChange={(value) => {
                                if (value) {
                                    this.props.changeCurrency({
                                        Currency: value
                                    })
                                }
                            }}
                            options={CurrencyOptions}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <TextField
                            label="PO Number"
                            disabled={SelectedJob && loggedInUser.role !== FIELD_ENGG_ROLE}
                            value={CurrentJobDetails.hasOwnProperty("PoNumber") ? CurrentJobDetails.PoNumber.value : ""}
                            onChange={(event, newval) => {
                                this.props.changePoNumber({
                                    PoNumber: newval
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <TextField
                            label="Quotation Number"
                            disabled={SelectedJob}
                            value={CurrentJobDetails.hasOwnProperty("QuotationNumber") ? CurrentJobDetails.QuotationNumber.value : ""}
                            onChange={(event, newval) => {
                                this.props.changeQuotationNumber({
                                    QuotationNumber: newval
                                });
                            }}
                        />
                    </div>
                </div>
            </div>

        )
    }
}
