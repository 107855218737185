import * as CONSTANTS from "../Constants/JobConstants";
import * as TASK_CONSTANTS from "./../../Tasks/Constants/TaskConstants";
import moment from 'moment';
import { JobInitialState } from "../Constants/JobInitialState";

export class JobActions {

    openClosePanel = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_PANEL, payload }
    }

    changeStep = (payload) => {
        return { type: CONSTANTS.CHANGE_STEP, payload }
    }

    loadPanel = (payload) => {
        return { type: CONSTANTS.LOAD_PANEL, payload }
    }

    loadSites = (payload) => {
        return { type: CONSTANTS.LOAD_SITES, payload }
    }

    setSiteOptions = (payload) => {
        return { type: CONSTANTS.SET_SITE_OPTIONS, payload }
    }

    setClientAndUserOptions = (payload) => {
        return { type: CONSTANTS.SET_CLIENT_AND_USERS, payload }
    }

    showHideJobDashboardColumns = (payload) => {
        return { type: CONSTANTS.SHOW_HIDE_JOB_DASHBOARD_COLUMNS, payload };
    }

    loadSitesAsync = (payload) => {
        return dispatch => {
            dispatch(this.loadSites(payload));
            payload.apiObject.getSitesData(payload.companyId).then((response) => {
                payload.Sites = response.data;
                dispatch(this.setSiteOptions(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Ooops!!";
                payload.alertSubText = "Error while loading sites";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    saveNewAssetAsync = (payload) => {
        payload.isOngoingSave = true;
        return dispatch => {
            dispatch(this.saveNewAsset(payload));
            payload.locationPayload.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            payload.apiObject.saveAsset(payload.locationPayload).then(() => {
                payload.isOngoingSave = false;
                dispatch(this.saveNewAsset(payload));
                payload.hideAlert = false;
                payload.alertHeader = "Success!!";
                payload.alertSubText = "New Asset added successfully";
                dispatch(this.openCloseAlertModal(payload));
                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    payload.isAsset = true;
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            }).catch((error) => {
                console.error("Error: ", error);
                payload.isOngoingSave = false;
                dispatch(this.saveNewAsset(payload));
                payload.hideAlert = false;
                payload.alertHeader = "Ooops!!";
                payload.alertSubText = error;
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    payload.isAsset = true;
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    saveNewAsset = (payload) => {
        return { type: CONSTANTS.SAVE_NEW_ASSET, payload };
    }

    changeNewAssetName = (payload) => {
        return { type: CONSTANTS.CHANGE_NEW_ASSET_NAME, payload };
    }

    changeSelectedAssetType = (payload) => {
        return { type: CONSTANTS.CHANGE_SELECTED_ASSET_TYPE, payload };
    }

    loadClientsAndUsersAsync = (payload) => {
        return dispatch => {

            dispatch(this.loadPanel());

            if (payload.SelectedJob) {

                payload.apiObject.getSitesAndTasks(payload.SelectedJob.customerId, payload.SelectedJob.id).then((responseSiteTasks) => {
                    payload.apiObject.loadInitData().then((responseInit) => {
                        // payload.Tasks = responseSiteTasks[1].data;
                        let AssetTypeIds = responseSiteTasks[1].data.filter(d => d.assetTypeId).map(d => d.assetTypeId);
                        let AssetTypeIdsNonDuplicate = AssetTypeIds.filter((v, i, a) => a.indexOf(v) === i);
                        let AssetTypeIdsPositionMap = {};
                        AssetTypeIds.forEach((data, index) => {
                            AssetTypeIdsPositionMap[index] = AssetTypeIdsNonDuplicate.indexOf(data);
                        });

                        payload.apiObject.getAllAssetOptions(AssetTypeIdsNonDuplicate.filter(d => d)).then((responseAssets) => {
                            let MappedTasks = responseSiteTasks[1].data;
                            MappedTasks.forEach((data, index) => {
                                data.AssetOptions = responseAssets[AssetTypeIdsPositionMap[index]] ? responseAssets[AssetTypeIdsPositionMap[index]].data : [];
                            })
                            payload.Tasks = MappedTasks;
                            payload.Sites = responseSiteTasks[0].data;

                            payload.Clients = responseInit[0].data;
                            payload.Offices = responseInit[1].data;
                            payload.Users = responseInit[2].data.filter(user => user.status === 1);
                            payload.Divisions = responseInit[3].data;
                            payload.TaskTypes = responseInit[4].data;
                            payload.Roles = responseInit[5].data;
                            payload.AssetTypes = responseInit[6].data;
                            payload.showLLGButton = responseInit[7].data;

                            dispatch(this.setClientAndUserOptions(payload));
                        }).catch(error => {
                            console.error("Error: ", error);
                            dispatch(this.setClientAndUserOptions(payload));
                            console.error("Error: ", error);
                            payload.hideAlert = false;
                            payload.alertHeader = "Ooops!!";
                            payload.alertSubText = "Error while loading Asset Options";
                            dispatch(this.openCloseAlertModal(payload));

                            setTimeout(() => {
                                payload.hideAlert = true;
                                payload.alertHeader = "";
                                payload.alertSubText = "";
                                dispatch(this.openCloseAlertModal(payload));
                            }, 2000);
                        })
                    }).catch((error) => {
                        console.error("Error: ", error);
                        dispatch(this.setClientAndUserOptions(payload));
                        console.error("Error: ", error);
                        payload.hideAlert = false;
                        payload.alertHeader = "Ooops!!";
                        payload.alertSubText = "Error while loading Sites";

                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2000);
                    })
                }).catch((error) => {
                    console.error("Error: ", error);
                    dispatch(this.setClientAndUserOptions(payload));
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = "Error while loading Clients and Users";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
            }
            else {
                payload.apiObject.loadInitData().then((response) => {
                    payload.Clients = response[0].data;
                    payload.Offices = response[1].data;
                    payload.Users = response[2].data.filter(user => user.status === 1);
                    payload.Divisions = response[3].data;
                    payload.TaskTypes = response[4].data;
                    payload.Roles = response[5].data;
                    payload.AssetTypes = response[6].data;
                    payload.showLLGButton = response[7].data;
                    dispatch(this.setClientAndUserOptions(payload));
                }).catch((error) => {
                    console.error("Error: ", error);
                    dispatch(this.setClientAndUserOptions(payload));
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = "Error while loading Clients and Users";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
            }
        }
    }

    changeSite = (payload) => {
        return { type: CONSTANTS.CHANGE_SITE, payload };
    }

    changeStartDateTime = (payload) => {
        return { type: CONSTANTS.CHANGE_START_DATE_TIME, payload };
    }

    changeEndDateTime = (payload) => {
        return { type: CONSTANTS.CHANGE_END_DATE_TIME, payload };
    }

    changeStartDuration = (payload) => {
        return { type: CONSTANTS.CHANGE_START_DURATION, payload };
    }

    changeRequesterName = (payload) => {
        return { type: CONSTANTS.CHANGE_REQUESTER_NAME, payload };
    }

    changeClientUsers = (payload) => {
        return { type: CONSTANTS.CHANGE_CLIENT_USERS, payload };
    }

    showHideClientDetailsTimeClock = (payload) => {
        return { type: CONSTANTS.SHOW_HIDE_CLIENT_DETAILS_TIME_CLOCK, payload };
    }

    changeLoOffice = (payload) => {
        return { type: CONSTANTS.CHANGE_LO_OFFICE, payload };
    }

    // Tracking Details Changes Start

    changeAssignOffice = (payload) => {
        return { type: CONSTANTS.CHANGE_ASSING_OFFICE, payload };
    }

    changeJobNumber = (payload) => {
        return { type: CONSTANTS.CHANGE_JOB_NUMBER, payload };
    }

    changeSalesPerson = (payload) => {
        return { type: CONSTANTS.CHANGE_SALES_PERSON, payload };
    }

    changePrice = (payload) => {
        return { type: CONSTANTS.CHNAGE_PRICE, payload };
    }

    changeCurrency = (payload) => {
        return { type: CONSTANTS.CHANGE_CURRENCY, payload };
    }

    changePoNumber = (payload) => {
        return { type: CONSTANTS.CHANGE_PO_NUMBER, payload };
    }

    changeQuotationNumber = (payload) => {
        return { type: CONSTANTS.CHANGE_QUOTATION_NUMBER, payload };
    }
    // Tracking Details Changes end

    // Site Details Changes start
    changeComments = (payload) => {
        return { type: CONSTANTS.CHANGE_COMMENTS, payload };
    }

    changeMainInspector = (payload) => {
        return { type: CONSTANTS.CHANGE_MAIN_INSPECTOR, payload };
    }

    changeInspectors = (payload) => {
        return { type: CONSTANTS.CHANGE_INSPECTORS, payload };
    }

    changeVendorDetails = (payload) => {
        return { type: CONSTANTS.CHANGE_VENDOR_DETAILS, payload };
    }

    changeSubVendorDetails = (payload) => {
        return { type: CONSTANTS.CHANGE_SUB_VENDOR_DETAILS, payload };
    }

    // Site Details Changes end

    resetValue = (payload) => {
        return { type: CONSTANTS.RESET_VALUE, payload };
    }

    openCloseAlertModal = (payload) => {

        return { type: CONSTANTS.OPEN_CLOSE_JOB_ALERT_MODAL, payload };
    }

    addJobAsync = (payload) => {

        let { CurrentJobDetails, cancelFlag, AllTaskTypeOptions, AssetTypeOptions, closeOrCancel } = payload;

        let LLGTaskType = AllTaskTypeOptions.find((taskType) => taskType.label === "LLG");
        let LLGAssetType = AssetTypeOptions.find((assetType) => assetType.label === "LLG");

        let LLGTaskTypeId = null;
        let LLGAssetTypeId = null;

        if (LLGTaskType) {
            LLGTaskTypeId = LLGTaskType.value;
        }

        if (LLGAssetType) {
            LLGAssetTypeId = LLGAssetType.value;
        }

        let jobPayload = {
            tenantId: 2,
            name: "Job" + moment(new Date()).format("_DD_MMM"),
            customerId: CurrentJobDetails.ClientName.value.value,
            objectType: "schedule",
            type: 1,
            status:
                cancelFlag ?
                    closeOrCancel === "Cancel" ? 64 : 16
                    :
                    CurrentJobDetails.status ? CurrentJobDetails.status : 1,
            siteId: CurrentJobDetails.SiteDetails.value.value,
            startDate: CurrentJobDetails.StartDate.value,
            endDate: CurrentJobDetails.EndDate.value,
            duration: CurrentJobDetails.StartDuration.value,
            requesterId: CurrentJobDetails.RequesterName.value ? CurrentJobDetails.RequesterName.value.value : null,
            secondaryOfficeId: CurrentJobDetails.AssignOffice.value.value,
            price: CurrentJobDetails.Price.value,
            salesPerson: CurrentJobDetails.SalesPerson.value ? CurrentJobDetails.SalesPerson.value.value : null,
            currency: CurrentJobDetails.Currency.value ? CurrentJobDetails.Currency.value.value : null,
            poNumber: CurrentJobDetails.PoNumber.value,
            quotationNumber: CurrentJobDetails.QuotationNumber.value,
            comments: CurrentJobDetails.Comments.value,
            mainInspectorId: CurrentJobDetails.MainInspector.value.value,
            inspectorIds: CurrentJobDetails.Inspectors.value ? CurrentJobDetails.Inspectors.value.map((data) => data.value).join(",") : null,
            clientUserIds: CurrentJobDetails.ClientUsers.value ?
                CurrentJobDetails.ClientUsers.value.map((data) => data.value).join(",") : null,
            officeId: CurrentJobDetails.AssignOffice.value.value,
            vendorDetails: CurrentJobDetails.VendorDetails.value,
            subVendorDetails: CurrentJobDetails.SubVendorDetails.value,
            isDayJob: CurrentJobDetails.isDayJob,
            isDayJobClosed: closeOrCancel === "Close" ? true : CurrentJobDetails.isDayJobClosed
        }

        if (CurrentJobDetails.id) {
            jobPayload.id = CurrentJobDetails.id;
        }

        let tasks = {
            tenantId: 2,
            TaskDetails: CurrentJobDetails.TaskDetails
        }

        let { LLGTaskDetails } = CurrentJobDetails;

        return dispatch => {

            dispatch(this.loadPanel());
            payload.apiObject.saveJobAPI(jobPayload).then((response) => {
                let scheduleResp = response.data.schedule;
                let notification = response.data.notification;
                let inspectorId = response.data.inspectorId;

                if (notification === 1) {
                    payload.apiObject.sendNotificationForReassignedJob({}, scheduleResp.id, inspectorId);
                }
                if (closeOrCancel !== "Close") {
                    let scheduleId = scheduleResp.id;
                    let type = 1;
                    let objectType = "TASK";
                    // let name = "Task" + moment(new Date()).format("_DD_MMM");
                    let taskPayload = [];
                    let newTaskName = "Task_" + moment(new Date()).format("_YYYY-MM-DD HH:mm:ss.SSS");
                    tasks.TaskDetails.forEach((data) => {

                        const observationType = 1024;

                        if (!data.id) {
                            switch (observationType) {
                                case 1024:
                                    newTaskName = `${scheduleResp.jobNumber}_${window.loggedInUserDetails.name}${moment(new Date()).format("_YYYY-MM-DD HH:mm:ss.SSS")}`;
                                    break;

                                default:
                                    break;
                            }
                        }
                        if (scheduleResp.status === CONSTANTS.JOB_STATUS_CANCELLED || data.isDirty) {
                            taskPayload.push({
                                id: data.id,
                                scheduleId: scheduleId,
                                type: type,
                                name: newTaskName,
                                objectType: objectType,
                                inspectorId: data.Inspector.value ? data.Inspector.value.value : null,
                                taskTypeId: data.TaskType.value ? data.TaskType.value.value : null,
                                assetTypeId: data.AssetType.value ? data.AssetType.value.id : null,
                                locationId: data.Asset.value ? data.Asset.value.value : null,
                                archived: 0,
                                observationType: observationType,
                                dueDate: data.dueDate.value,
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                status:
                                    scheduleResp.status === CONSTANTS.JOB_STATUS_CANCELLED ?
                                        data.status === TASK_CONSTANTS.TASK_STATUS_OPEN ?
                                            TASK_CONSTANTS.TASK_STATUS_CANCELLED : data.status : data.status
                            });
                        }
                    })
                    if (LLGTaskDetails.status !== LLGTaskDetails.previousStatus || LLGTaskDetails.isNew) {
                        taskPayload.push({
                            id: LLGTaskDetails.id ? LLGTaskDetails.id : undefined,
                            scheduleId: scheduleId,
                            type: type,
                            name: `LLG_${newTaskName}`,
                            objectType: objectType,
                            inspectorId: null,
                            taskTypeId: LLGTaskTypeId,
                            assetTypeId: LLGAssetTypeId,
                            locationId: CurrentJobDetails.SiteDetails.value.value,
                            archived: 0,
                            observationType: 1024,
                            dueDate: new Date(),
                            status: LLGTaskDetails.status === -1 ? 1024 : LLGTaskDetails.status
                        });
                    }

                    payload.apiObject.saveTasks(taskPayload, cancelFlag, scheduleId).then((responseTaskType) => {
                        payload.hideAlert = false;
                        payload.alertHeader = "Success!!";
                        payload.alertSubText =
                            scheduleResp.status !== 16 ?
                                `Job and Tasks saved successfully` :
                                `Job and Tasks cancelled successfully`;
                        if (responseTaskType.data.newObservations.length > 0) {
                            payload.apiObject.sendNotification(responseTaskType.data.newObservations);
                        }

                        // if (responseTaskType.data.reAssignedObservations.length > 0) {
                        //     payload.apiObject.sendNotification(responseTaskType.data.reAssignedObservations, "deleteTask");
                        // }

                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                            let config = {
                                headers: {
                                }
                            }
                            payload.offset = 0;
                            payload.limit = JobInitialState.limit;
                            payload.LoadingDashboard = true;
                            payload.config = config;
                            dispatch(this.loadJobDashboard(payload));
                        }, 2000);

                    }).catch((errTaskSave) => {
                        console.error("Error: ", errTaskSave);
                        payload.hideAlert = false;
                        payload.alertHeader = "Ooops!!";
                        payload.alertSubText = "Error while saving tasks";
                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2000);

                    });
                }
                else {
                    payload.hideAlert = false;
                    payload.alertHeader = "Success!!";
                    payload.alertSubText = `Job closed successfully`;
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                        let config = {
                            headers: {
                            }
                        }
                        payload.offset = 0;
                        payload.limit = JobInitialState.limit;
                        payload.LoadingDashboard = true;
                        payload.config = config;
                        dispatch(this.loadJobDashboard(payload));
                    }, 2500);
                }

            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Error!!";
                payload.alertSubText = error;
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    payload.isError = true;
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            });
        }
    }

    // Task Details Change Start

    showHideTaskDetailsTimeClock = (payload) => {
        return { type: CONSTANTS.SHOW_HIDE_TASK_DETAILS_TIME_CLOCK, payload };
    }

    createNewTaskRow = (payload) => {
        return { type: CONSTANTS.CREATE_NEW_TASK_ROW, payload };
    }

    deleteLastTaskRow = (payload) => {
        return { type: CONSTANTS.DELETE_LAST_TASK_ROW, payload };
    }

    changeJobTaskDivision = (payload) => {
        return { type: CONSTANTS.CHANGE_JOB_TASK_DIVISION, payload };
    }

    changeTaskType = (payload) => {
        return { type: CONSTANTS.CHANGE_TASK_TYPE, payload };
    }

    loadAssetTypeAndAssetOptionsAsync = (payload) => {
        return dispatch => {

            payload.OngoingLoad = true;
            dispatch(this.loadAssetTypeAndAssetOptions(payload));

            payload.apiObject._getAllSettings("AssetType", payload.TaskType.value).then((responseAssetType) => {

                if (responseAssetType.data[0]) {
                    payload.apiObject.getAssetsData(responseAssetType.data[0].id).then((responseAsset) => {
                        payload.OngoingLoad = false;
                        payload.AssetType = responseAssetType.data[0];
                        payload.Assets = responseAsset.data;
                        dispatch(this.loadAssetTypeAndAssetOptions(payload));
                    }).catch((error) => {
                        console.error("Error: ", error);
                        dispatch(this.loadAssetTypeAndAssetOptions(payload));
                        payload.hideAlert = false;
                        payload.alertHeader = "Ooops!!";
                        payload.alertSubText = "Error while loading Asset Type";
                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2000);
                    })
                }
                else {
                    payload.OngoingLoad = false;
                    dispatch(this.loadAssetTypeAndAssetOptions(payload));
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = "No Asset Type found for Tasktype: " + payload.TaskType.label;
                    dispatch(this.openCloseAlertModal(payload));
                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        payload.isAsset = true;
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                }
            }).catch((error) => {
                console.error("Error: ", error);
                dispatch(this.loadAssetTypeAndAssetOptions(payload));
                payload.hideAlert = false;
                payload.alertHeader = "Ooops!!";
                payload.alertSubText = "Error while loading Assets";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    loadAssetTypeAndAssetOptions = (payload) => {
        return { type: CONSTANTS.LOAD_ASSET_TYPE_AND_ASSET_OPTIONS, payload };
    }

    setAssetOptions = (payload) => {
        return { type: CONSTANTS.SET_ASSET_OPTIONS, payload };
    }

    changeAsset = (payload) => {
        return { type: CONSTANTS.CHANGE_ASSET, payload };
    }

    changeInspector = (payload) => {
        return { type: CONSTANTS.CHANGE_INSPECTOR, payload };
    }

    changeTaskDueDate = (payload) => {
        return { type: CONSTANTS.CHANGE_TASK_DUE_DATE, payload };
    }

    changeTaskDueDate = (payload) => {
        return { type: CONSTANTS.CHANGE_TASK_DUE_TIME, payload };
    }

    deleteOrReopenTask = (payload) => {
        return { type: CONSTANTS.DELETE_OR_REOPEN_TASK, payload };
    }

    changeIsDayJob = (payload) => {
        return { type: CONSTANTS.CHANGE_IS_DAY_JOB, payload };
    }

    openCloseJobConfirmModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_JOB_CONFIRM_MODAL, payload }
    }

    changeLLGTaskStatus = (payload) => {
        return { type: CONSTANTS.CHANGE_LLG_TASK_STATUS, payload }
    }

    // Task Details Change End

    // Dashboard Actions Start

    fixJobDashboard = (payload) => {
        return { type: CONSTANTS.FIX_JOB_DASHBOARD, payload };
    }

    cleanJobDashboard = (payload) => {
        return { type: CONSTANTS.CLEAN_JOB_DASHBOARD, payload };
    }

    loadJobDashboardAsync = (payload) => {
        return { type: CONSTANTS.LOAD_JOB_DASHBOARD, payload };
    }

    sortJobDashboard = (payload) => {
        return { type: CONSTANTS.SORT_JOB_DASHBOARD, payload };
    }

    loadJobDashboard = (payload) => {
        return dispatch => {

            dispatch(this.cleanJobDashboard(payload));

            payload.apiObject.getJobDetails(payload.offset, payload.limit, payload.config).then((response) => {
                payload.Jobs = response[0].data;
                payload.TotalJobsCount = response[1].data;
                dispatch(this.loadJobDashboardAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Ooops!!";
                payload.alertSubText = "Error while loading Jobs";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    switchJobView = (payload) => {
        return { type: CONSTANTS.SWITCH_JOB_VIEW, payload }
    }

    changeJobFilterValue = (payload) => {
        switch (payload.filterType) {
            case "status":
                return { type: CONSTANTS.CHANGE_JOB_STATUS_FILTER_VALUE, payload };
            case "site":
                return { type: CONSTANTS.CHANGE_JOB_SITE_FILTER_VALUE, payload };
            case "client":
                return { type: CONSTANTS.CHANGE_JOB_CLIENT_FILTER_VALUE, payload };
            case "jobNumber":
                return { type: CONSTANTS.CHANGE_JOB_NUMBER_FILTER_VALUE, payload };
            case "quotationNumber":
                return { type: CONSTANTS.CHANGE_QUOTATION_NUMBER_FILTER_VALUE, payload };

            default:
                break;
        }
    }

    findFilteredJobsAsync = (payload) => {
        return dispatch => {
            dispatch(this.changeJobFilterValue(payload));
            payload.apiObject.getJobDetails(payload.offset, payload.limit, payload.config).then((response) => {
                payload.Jobs = response[0].data;
                payload.TotalJobsCount = response[1].data;
                dispatch(this.loadJobDashboardAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading filtered jobs";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    // Dashboard Actions End 

    // New Site Section Start
    changeNewSiteField = (payload) => {
        switch (payload.field) {
            case "name":
                return { type: CONSTANTS.CHANGE_NEW_SITE_NAME_JOB, payload };
            case "street":
                return { type: CONSTANTS.CHANGE_NEW_SITE_STREET_JOB, payload };
            case "city":
                return { type: CONSTANTS.CHANGE_NEW_SITE_CITY_JOB, payload };
            case "state":
                return { type: CONSTANTS.CHANGE_NEW_SITE_STATE_JOB, payload };
            case "country":
                return { type: CONSTANTS.CHANGE_NEW_SITE_COUNTRY_JOB, payload };
            case "pin":
                return { type: CONSTANTS.CHANGE_NEW_SITE_PIN_JOB, payload };
            case "phone":
                return { type: CONSTANTS.CHANGE_NEW_SITE_PHONE_JOB, payload };

            default:
                break;
        }
    }

    showHideAddNewSiteSectionJob = (payload) => {
        return { type: CONSTANTS.SHOW_HIDE_ADD_NEW_SITE_SECTION_JOB, payload };
    }

    saveNewSiteJobAsync = (payload) => {

        let NewSite = payload.NewSite;

        NewSite.objectType = "SITE";
        NewSite.companyId = payload.ClientId.value;
        NewSite.parentId = payload.ClientId.value;
        NewSite.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        NewSite.locationLevel = 0;
        NewSite.type = 1;
        NewSite.status = 0;

        return (dispatch) => {

            payload.isOngoingSave = true;

            dispatch(this.saveNewSiteJob(payload));

            payload.apiObject.saveLocation(payload.NewSite).then((responseSite) => {
                payload.isOngoingSave = false;
                payload.newSite = responseSite;
                payload.NewSiteSaveStatus = {
                    flag: true,
                    message: "New Site Saved Successfully"
                };
                dispatch(this.saveNewSiteJob(payload));
                setTimeout(() => {
                    payload.ShowAddNewSiteSection = false;
                    payload.NewSiteSaveStatus = {
                        flag: true,
                        message: ""
                    };
                    dispatch(this.showHideAddNewSiteSectionJob(payload));
                }, 2000);
            }).catch((errorMessage) => {
                payload.ShowAddNewSiteSection = true;
                payload.NewSiteSaveStatus = {
                    flag: false,
                    message: errorMessage
                };
                dispatch(this.showHideAddNewSiteSectionJob(payload));
                setTimeout(() => {
                    payload.NewSiteSaveStatus = {
                        flag: true,
                        message: ""
                    };
                    dispatch(this.showHideAddNewSiteSectionJob(payload));
                }, 2000);
            });
        }
    }

    saveNewSiteJob = (payload) => {
        return { type: CONSTANTS.SAVE_NEW_SITE_JOB, payload };
    }

    // New Site Section End

    // New User Section Start
    changeNewUserField = (payload) => {
        switch (payload.field) {
            case "name":
                return { type: CONSTANTS.CHANGE_NEW_USER_NAME_JOB, payload };
            case "email":
                return { type: CONSTANTS.CHANGE_NEW_USER_EMAIL_JOB, payload };
            case "phone":
                return { type: CONSTANTS.CHANGE_NEW_USER_PHONE_JOB, payload };

            default:
                break;
        }
    }

    showHideAddNewUserSectionJob = (payload) => {
        return { type: CONSTANTS.SHOW_HIDE_ADD_NEW_USER_SECTION_JOB, payload };
    }

    saveNewUserJobAsync = (payload) => {

        let NewUser = payload.NewUser;

        NewUser.objectType = "REQUESTER";
        NewUser.companyId = payload.ClientId.value;
        NewUser.siteIds = payload.SiteId.value;
        NewUser.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        NewUser.type = 1;

        return (dispatch) => {

            payload.isOngoingSave = true;

            dispatch(this.saveNewUserJob(payload));

            payload.apiObject.saveUser(payload.NewUser).then((responseUser) => {
                payload.isOngoingSave = false;
                payload.newUser = responseUser;
                payload.NewUserSaveStatus = {
                    flag: true,
                    message: "New User Saved Successfully"
                };
                dispatch(this.saveNewUserJob(payload));
                setTimeout(() => {
                    payload.ShowAddNewUserSection = false;
                    payload.NewUserSaveStatus = {
                        flag: true,
                        message: ""
                    };
                    dispatch(this.showHideAddNewUserSectionJob(payload));
                }, 2000);
            }).catch((errorMessage) => {
                payload.ShowAddNewUserSection = true;
                payload.isOngoingSave = false;
                payload.NewUserSaveStatus = {
                    flag: false,
                    message: errorMessage
                };
                dispatch(this.showHideAddNewUserSectionJob(payload));
                setTimeout(() => {
                    payload.NewUserSaveStatus = {
                        flag: true,
                        message: ""
                    };
                    dispatch(this.showHideAddNewUserSectionJob(payload));
                }, 2000);
            });
        }
    }

    saveNewUserJob = (payload) => {
        return { type: CONSTANTS.SAVE_NEW_USER_JOB, payload };
    }

    // New User Section End

    // Job File Upload start
    uploadJobFilesAsync = (payload) => {
        return { type: CONSTANTS.UPLOAD_JOB_FILES, payload }
    }

    showHideUploadJobFilesErrorText = (payload) => {
        return { type: CONSTANTS.SHOW_HIDE_JOB_UPLOAD_FILES_ERROR_TEXT, payload }
    }

    uploadJobFiles = (payload) => {
        return dispatch => {
            payload.isOngoingLoad = true;
            dispatch(this.uploadJobFilesAsync(payload));
            payload.apiObject._uploadJobFiles(payload.SelectedJob.id, payload.uploadedFiles).then((response) => {
                payload.isOngoingLoad = false;
                payload.files = response;
                payload.errorText = "Files uploaded successfully"
                dispatch(this.uploadJobFilesAsync(payload));
                setTimeout(() => {
                    payload.errorText = ""
                    dispatch(this.uploadJobFilesAsync(payload));
                }, 2000);
            }).catch((error) => {
                console.log("Error while uploading job files: ", error);
                payload.errorText = "Error while uploading job files";
                dispatch(this.showHideUploadJobFilesErrorText(payload));

                setTimeout(() => {
                    payload.errorText = "Error while uploading job files";
                    dispatch(this.showHideUploadJobFilesErrorText(payload));
                }, 2000);
            });
        }
    }

    // Job File Upload end
}