import React, { PureComponent } from 'react'
import { ActionButton, IconButton, PrimaryButton, Toggle, SearchBox, TooltipHost, Dropdown } from 'office-ui-fabric-react'
import { TaskAPI } from '../Services/TaskAPI';
import filterFactory from 'react-bootstrap-table2-filter';
// import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import LoadingOverlay from 'react-loading-overlay';
import { FadeLoader } from 'react-spinners';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import TaskDetailsTab from './TaskDetailsTab';
import { TASK_ASSET_TYPE_DROPDOWN_FILTER_OPTIONS, TASK_STATUS, TASK_STATUS_DROPDOWN_FILTER_OPTIONS } from '../Constants/TaskConstants';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_GENERAL, NO_ALLOW_EDIT_JOB_TASK_CLIENT_ASSET_ROLE } from '../../../Constants/MessageConstants';
import { DeletedRowStyle, calloutProps, hostStyles, tooltipId, DropdownStyles, IconConstants } from '../../../Constants/StylesConstants';
import { TaskUtility } from '../Utility/TaskUtility';
// import { customToggleStyles } from '../../../Constants/CustomStyles';

const defaultSorted = [{
    dataField: 'modifiedDate',
    order: 'desc'
}];

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="table-export-button-div">
            <PrimaryButton
                onClick={handleClick}
                text="Export to Excel"
                iconProps={IconConstants.excelIcon}
            />
        </div>
    );
};

const RemoteAll = ({
    data, columns, onTableChange, page, hideSizePerPage, style
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
            exportCSV={{
                fileName: 'Qwikspec_Task.csv',
                noAutoBOM: false,
                blobType: 'text/csv;charset=ansi'
            }}
        >
            {
                props => (
                    <React.Fragment>
                        <MyExportCSV {...props.csvProps} />
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({})}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            rowClasses={DeletedRowStyle}
                            wrapperClasses="table-responsive"
                        />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class TaskDashboard extends PureComponent {

    _taskApi = null;

    constructor(props) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {

        this.props.cleanTaskDashboard({
            Tasks: [],
            offset: 0,
            loadingDashboard: true
        });
        let config = {
            headers: {
                filter: JSON.stringify({
                    "observationType": 1024
                })
            }
        }
        localStorage.setItem("config", JSON.stringify(config));
        this.props.loadTasksAsync({
            offset: 0,
            limit: this.props.Task.limit,
            apiObject: this._taskApi,
            LoadingTaskDashboard: true,
            config: config
        });

    }

    fetchMoreData = () => {

        let filterJson = {};

        let { filters } = this.props.Task;

        for (let dataField in filters) {
            filterJson[dataField] = filters[dataField].filterVal
        }

        filterJson = {
            "status": this.props.Task.task_status_filter_value,
            "site": this.props.Task.task_site_filter_value,
            "client": this.props.Task.newValue,
            "jobNumber": this.props.Task.task_job_number_filter_value,
            "quotationNumber": this.props.task_quotation_number_filter_value,
            "observationType": 1024,
        };
        let config = {
            headers: {
                filter: JSON.stringify(filterJson)
            }
        };
        if (Object.keys(filterJson).length > 0) {
            config = {
                headers: {
                    filter: JSON.stringify(filterJson)
                }
            }
        }
        localStorage.setItem("config", JSON.stringify(config));
        localStorage.setItem("filterJson", JSON.stringify(filterJson));

        if (this.props.TotalTasksCount === this.props.Task.Tasks.length) {
            this.props.fixTaskDashboard({
                // filtered: true,
                HasMoreTasks: false
            })
            return;
        }
        else {
            this.props.loadTasksAsync({
                // filtered: true,
                offset: this.props.Task.offset,
                limit: this.props.Task.limit,
                apiObject: this._taskApi,
                LoadingTaskDashboard: false,
                config: config
            });

        }
    };


    handleTableChange = (type, { filters, sortField, sortOrder }) => {

        // console.log(sortOrder);

        let filterJson = {};

        for (let dataField in filters) {
            filterJson[dataField] = filters[dataField].filterVal
        }

        let config = {
            headers: {
            }
        }
        if (Object.keys(filterJson).length > 0) {
            config = {
                headers: {
                    filter: JSON.stringify(filterJson)
                }
            }
        }

        if (type.toUpperCase() === "FILTER") {

            this.props.loadTasksAsync({
                filtered: true,
                offset: 0,
                limit: this.props.Task.limit,
                apiObject: this._taskApi,
                LoadingTaskDashboard: true,
                config: config,
                filters: filters
            })
        }

        else if (type.toUpperCase() === "SORT") {
            setTimeout(() => {

                let result = [...this.props.Task.Tasks];

                let taskutility = new TaskUtility();

                // Handle column sort
                result = taskutility.sortTasks(result, sortField, sortOrder);

                this.props.sortTaskDashboard({
                    sortedTasks: result,
                    sortedColumn: sortField,
                    sortOrder
                });

            }, 1000);
        }

    }

    render() {

        let {
            LoadingTaskDashboard,
            Tasks,
            HasMoreTasks,
            ShowTaskDetailsTab,
            ShowActiveTasks,
            task_status_filter_value,
            task_site_filter_value,
            task_client_filter_value,
            task_job_number_filter_value,
            task_asset_filter_value,
            task_asset_type_filter_value,
            task_certificate_number_filter_value,
            task_quotation_number_filter_value,
            sortedColumn,
            sortOrder,
            showColumns
        } = this.props.Task;

        let columns = [
            {
                dataField: 'manageTaskIcons',
                isDummyField: true,
                text: 'Action',
                hidden: NO_ALLOW_EDIT_JOB_TASK_CLIENT_ASSET_ROLE.indexOf(window.loggedInUserDetails.role) > -1,
                formatter: (cellContent, row) => {
                    return (
                        <span>
                            {row.status !== 1024 &&
                                <IconButton
                                    onClick={() => {
                                        this.props.showTaskDetailsTab({
                                            SelectedTask: row,
                                            ShowTaskDetailsTab: true,
                                            apiObject: this._taskApi,
                                        });
                                    }}
                                    iconProps={IconConstants.entitlementRedemptionIcon} />
                            }
                        </span>
                    )
                },
                csvExport: false
            },
            {
                dataField: 'id',
                text: 'ID',
                sort: true,
                // hidden: true,
                title: (cell) => `${cell}`,
                csvExport: false
            },
            {
                dataField: 'status',
                text: 'Status',
                sort: true,
                formatter: (cellContent, row) => {
                    return (
                        <span className={cellContent === 1024 ? "mandatory-span" : ""}>{TASK_STATUS[cellContent]}</span>
                    )
                },
                csvFormatter: (cellContent, row) => TASK_STATUS[cellContent]
            },
            {
                dataField: 'jobNumber',
                text: 'Job',
                sort: true,
                hidden: showColumns.indexOf('jobNumber') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'location',
                text: 'Asset',
                sort: true,
                hidden: showColumns.indexOf('location') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'assetType',
                text: 'Asset Type',
                sort: true,
                hidden: showColumns.indexOf('assetType') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'taskType',
                text: 'Activity',
                sort: true,
                hidden: showColumns.indexOf('taskType') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'client',
                text: 'Client',
                sort: true,
                hidden: showColumns.indexOf('client') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'site',
                text: 'Site',
                sort: true,
                hidden: showColumns.indexOf('site') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'observationInspector',
                text: 'Inspector',
                sort: true,
                hidden: showColumns.indexOf('inspector') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'certificateNumber',
                text: 'Certificate',
                sort: true,
                hidden: showColumns.indexOf('certificateNumber') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'quotationNumber',
                text: 'Quote / WO',
                sort: true,
                hidden: showColumns.indexOf('quotationNumber') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'observationType',
                text: 'Observation Type',
                sort: true,
                hidden: showColumns.indexOf('observationType') === -1,
                title: (cell) => `${cell}`,
                csvExport: false
            },
            {
                dataField: 'actualInspectionDate',
                text: 'Actual',
                sort: true,
                // title: (cellContent, row) => cellContent ? moment.utc(cellContent).local().format(DATE_TIME_FORMAT) : "",
                formatter: (cellContent, row) => {
                    return (
                        <TooltipHost
                            content={cellContent ? moment.utc(cellContent).format(DATE_TIME_FORMAT_GENERAL) : ""}
                            id={tooltipId}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            <span> {cellContent ? moment.utc(cellContent).format(DATE_TIME_FORMAT_GENERAL) : ""} </span>
                        </TooltipHost>
                    )
                },
                hidden: showColumns.indexOf('actualInspectionDate') === -1,
                csvFormatter: (cellContent, row, rowIndex) => cellContent ? moment.utc(cellContent).format(DATE_TIME_FORMAT) : ""
            },
            {
                dataField: 'modifiedDate',
                text: 'Modified',
                sort: true,
                // title: (cellContent, row) => cellContent ? moment.utc(cellContent).local().format(DATE_TIME_FORMAT_GENERAL) : "",
                formatter: (cellContent, row) => {
                    return (
                        <TooltipHost
                            content={cellContent ? moment.utc(cellContent).format(DATE_TIME_FORMAT_GENERAL) : ""}
                            id={tooltipId}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            <span> {cellContent ? moment.utc(cellContent).format(DATE_TIME_FORMAT_GENERAL) : ""} </span>
                        </TooltipHost>
                    )
                },
                hidden: showColumns.indexOf('modifiedDate') === -1,
                csvFormatter: (cellContent, row, rowIndex) =>
                    cellContent ? moment.utc(cellContent).local().format(DATE_TIME_FORMAT) : ""

            },
            {
                dataField: 'endUserName',
                text: 'End User Name',
                sort: true,
                hidden: showColumns.indexOf('endUserName') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'endUserAddress',
                text: 'End User Address',
                sort: true,
                hidden: showColumns.indexOf('endUserAddress') === -1,
                title: (cell) => `${cell}`
            },

        ];

        let filteredTask = [];
        if (ShowActiveTasks) {
            filteredTask = [...Tasks.filter(
                task =>
                (
                    (
                        task.status === 1
                        || task.status === 2
                        || task.status === 4
                        || task.status === 8
                        || task.status === 32
                    )
                    && task.observationType === 1024
                )
            )];
            // filteredTask = filteredTask.filter(task => task.id === 12741)
        }
        else {
            filteredTask = [...Tasks.filter(
                task =>
                (
                    (
                        task.status === 16
                        || task.status === 64
                        || task.status === 1024
                    )
                    && task.observationType === 1024
                )
            )]
        }
        let taskutility = new TaskUtility();

        // Handle column sort
        filteredTask = taskutility.sortTasks(filteredTask, sortedColumn, sortOrder);

        return (
            <>
                {!ShowTaskDetailsTab ?
                    <React.Fragment>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                    <ActionButton
                                        disabled={LoadingTaskDashboard}
                                        text="Reload"
                                        iconProps={IconConstants.refreshIcon}
                                        onClick={() => this.loadInitData()}
                                    />
                                    {HasMoreTasks && !LoadingTaskDashboard &&
                                        <ActionButton
                                            text="Load More"
                                            iconProps={IconConstants.moreicon}
                                            onClick={() => this.fetchMoreData()}
                                        />
                                    }
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                            <Dropdown
                                                selectedKey={task_status_filter_value}
                                                options={TASK_STATUS_DROPDOWN_FILTER_OPTIONS}
                                                styles={DropdownStyles}
                                                onChange={(ev, newVal) => {
                                                    let filterJson = {
                                                        "status": newVal.key,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "client": task_client_filter_value,
                                                        "site": task_site_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "status",
                                                        value: newVal.key,
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                            <SearchBox
                                                underlined
                                                value={task_job_number_filter_value}
                                                placeholder="Job Number"
                                                onSearch={(newValue) => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "client": task_client_filter_value,
                                                        "jobNumber": newValue,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "jobNumber",
                                                        value: newValue,
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                                onClear={() => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "client": task_client_filter_value,
                                                        "jobNumber": "",
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "jobNumber",
                                                        value: "",
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                            />
                                        </div>

                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                            <SearchBox
                                                underlined
                                                value={task_asset_filter_value}
                                                placeholder="Asset"
                                                onSearch={(newValue) => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "client": task_client_filter_value,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "asset": newValue,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "asset",
                                                        value: newValue,
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                                onClear={() => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "client": task_client_filter_value,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "asset": "",
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "asset",
                                                        value: "",
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                            <SearchBox
                                                underlined
                                                value={task_client_filter_value}
                                                placeholder="Client"
                                                onSearch={(newValue) => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "client": newValue,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "client",
                                                        value: newValue,
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                                onClear={() => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "client": "",
                                                        "jobNumber": task_job_number_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "client",
                                                        value: "",
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">

                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                            <Dropdown
                                                selectedKey={task_asset_type_filter_value}
                                                options={TASK_ASSET_TYPE_DROPDOWN_FILTER_OPTIONS}
                                                styles={DropdownStyles}
                                                onChange={(ev, newVal) => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "client": task_client_filter_value,
                                                        "site": task_site_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": newVal.key,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "assetType",
                                                        value: newVal.key,
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                            <SearchBox
                                                underlined
                                                value={task_site_filter_value}
                                                placeholder="Site"
                                                onSearch={(newValue) => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": newValue,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "client": task_client_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "site",
                                                        value: newValue,
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                                onClear={() => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": "",
                                                        "jobNumber": task_job_number_filter_value,
                                                        "client": task_client_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "site",
                                                        value: "",
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                            <SearchBox
                                                underlined
                                                value={task_certificate_number_filter_value}
                                                placeholder="Certificate No."
                                                onSearch={(newValue) => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "client": task_client_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": newValue,
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "certificateNumber",
                                                        value: newValue,
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                                onClear={() => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "client": task_client_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": "",
                                                        "quotationNumber": task_quotation_number_filter_value,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "certificateNumber",
                                                        value: "",
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                            <SearchBox
                                                underlined
                                                value={task_quotation_number_filter_value}
                                                placeholder="Quotation Number"
                                                onSearch={(newValue) => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "client": task_client_filter_value,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": newValue,
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "quotationNumber",
                                                        value: newValue,
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                                onClear={() => {
                                                    let filterJson = {
                                                        "status": task_status_filter_value,
                                                        "site": task_site_filter_value,
                                                        "client": task_client_filter_value,
                                                        "jobNumber": task_job_number_filter_value,
                                                        "asset": task_asset_filter_value,
                                                        "assetType": task_asset_type_filter_value,
                                                        "certificateNumber": task_certificate_number_filter_value,
                                                        "quotationNumber": "",
                                                        "observationType": 1024
                                                    };
                                                    let config = {
                                                        headers: {
                                                            filter: JSON.stringify(filterJson)
                                                        }
                                                    };
                                                    this.props.findFilteredTasksAsync({
                                                        filterType: "quotationNumber",
                                                        value: "",
                                                        offset: 0,
                                                        limit: this.props.Task.limit,
                                                        apiObject: this._taskApi,
                                                        config: config,
                                                        filtered: true
                                                    })
                                                }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1 inactive-toggle-col">
                                    <Toggle
                                        checked={isShowLLGTasksOnly}
                                        onText="LLG"
                                        offText="ALL"
                                        onChange={() => this.props.switchLLGTaskView({
                                            isShowLLGTasksOnly: !isShowLLGTasksOnly
                                        })}
                                        styles={customToggleStyles}
                                    />
                                </div> */}
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2 inactive-toggle-col">
                                    <Toggle
                                        checked={ShowActiveTasks}
                                        onText="Active"
                                        offText="Inactive"
                                        onChange={() => this.props.toggleTaskView({
                                            ShowActiveTasks: !ShowActiveTasks
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                <LoadingOverlay
                                    active={LoadingTaskDashboard}
                                    spinner={<FadeLoader />}
                                    text="Loading Tasks...">

                                    <div className="lazy-load-task-div" >

                                        <RemoteAll
                                            data={filteredTask}
                                            columns={columns}
                                            page={1}
                                            hideSizePerPage={true}
                                            onTableChange={this.handleTableChange}
                                        />
                                        {/* <ActionButton
                                        onClick={() => this.fetchMoreData()}
                                    />
                                    <ActionButton
                                        text="Load More..."
                                        onClick={() => this.fetchMoreData()}
                                    /> */}
                                    </div >

                                </LoadingOverlay>
                            </div>
                        </div>

                    </React.Fragment>
                    :
                    <TaskDetailsTab
                        {...this.props}
                    />
                }
            </>
        )
    }
}
