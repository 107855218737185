import React, { Component } from 'react';
import { Dialog, DialogType } from 'office-ui-fabric-react';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class AlertRedux extends Component {

    render() {
        let hideAlert = true;
        let alertHeader = "";
        let alertSubText = "";
        
        if (this.props.User) {
            hideAlert = this.props.User.alertJSON.hideAlert;
            alertHeader = this.props.User.alertJSON.alertHeader;
            alertSubText = this.props.User.alertJSON.alertSubText;

        }
        else if (this.props.Job) {
            hideAlert = this.props.Job.alertJSON.hideAlert;
            alertHeader = this.props.Job.alertJSON.alertHeader;
            alertSubText = this.props.Job.alertJSON.alertSubText;
        }

        else if (this.props.Task && this.props.Task.alertJSON.alertHeader) {
            hideAlert = this.props.Task.alertJSON.hideAlert;
            alertHeader = this.props.Task.alertJSON.alertHeader;
            alertSubText = this.props.Task.alertJSON.alertSubText;
        }

        else if (this.props.AssetType) {
            hideAlert = this.props.AssetType.alertJSON.hideAlert;
            alertHeader = this.props.AssetType.alertJSON.alertHeader;
            alertSubText = this.props.AssetType.alertJSON.alertSubText;
        }

        else if (this.props.TaskType) {
            hideAlert = this.props.TaskType.alertJSON.hideAlert;
            alertHeader = this.props.TaskType.alertJSON.alertHeader;
            alertSubText = this.props.TaskType.alertJSON.alertSubText;
        }

        else if (this.props.Config) {
            hideAlert = this.props.Config.alertJSON.hideAlert;
            alertHeader = this.props.Config.alertJSON.alertHeader;
            alertSubText = this.props.Config.alertJSON.alertSubText;
        }

        else if (this.props.Client) {
            hideAlert = this.props.Client.alertJSON.hideAlert;
            alertHeader = this.props.Client.alertJSON.alertHeader;
            alertSubText = this.props.Client.alertJSON.alertSubText;
        }

        else if (this.props.Role) {
            hideAlert = this.props.Role.alertJSON.hideAlert;
            alertHeader = this.props.Role.alertJSON.alertHeader;
            alertSubText = this.props.Role.alertJSON.alertSubText;
        }
        else if (this.props.Asset) {
            hideAlert = this.props.Asset.alertJSON.hideAlert;
            alertHeader = this.props.Asset.alertJSON.alertHeader;
            alertSubText = this.props.Asset.alertJSON.alertSubText;
        }

        else if (this.props.SequenceState) {
            hideAlert = this.props.SequenceState.alertJSON.hideAlert;
            alertHeader = this.props.SequenceState.alertJSON.alertHeader;
            alertSubText = this.props.SequenceState.alertJSON.alertSubText;
        }
        else if (this.props.LoOffice) {
            hideAlert = this.props.LoOffice.alertJSON.hideAlert;
            alertHeader = this.props.LoOffice.alertJSON.alertHeader;
            alertSubText = this.props.LoOffice.alertJSON.alertSubText;
        }
        else if (this.props.JobLites) {
            hideAlert = this.props.JobLites.alertJSON.hideAlert;
            alertHeader = this.props.JobLites.alertJSON.alertHeader;
            alertSubText = this.props.JobLites.alertJSON.alertSubText;
        }
        else if (this.props.AssessmentsState) {
            hideAlert = this.props.AssessmentsState.alertJSON.hideAlert;
            alertHeader = this.props.AssessmentsState.alertJSON.alertHeader;
            alertSubText = this.props.AssessmentsState.alertJSON.alertSubText;
        }
        else if (this.props.TrialFitPicLibraryState) {
            hideAlert = this.props.TrialFitPicLibraryState.alertJSON.hideAlert;
            alertHeader = this.props.TrialFitPicLibraryState.alertJSON.alertHeader;
            alertSubText = this.props.TrialFitPicLibraryState.alertJSON.alertSubText;
        }
        else if (this.props.ClientInventoryState) {
            hideAlert = this.props.ClientInventoryState.alertJSON.hideAlert;
            alertHeader = this.props.ClientInventoryState.alertJSON.alertHeader;
            alertSubText = this.props.ClientInventoryState.alertJSON.alertSubText;
        }
        else if (this.props.ProductTechInfoState) {
            hideAlert = this.props.ProductTechInfoState.alertJSON.hideAlert;
            alertHeader = this.props.ProductTechInfoState.alertJSON.alertHeader;
            alertSubText = this.props.ProductTechInfoState.alertJSON.alertSubText;
        }
        else if (this.props.ClientSiteSelectorState) {
            hideAlert = this.props.ClientSiteSelectorState.alertJSON.hideAlert;
            alertHeader = this.props.ClientSiteSelectorState.alertJSON.alertHeader;
            alertSubText = this.props.ClientSiteSelectorState.alertJSON.alertSubText;
        }

        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: alertHeader,
            subText: alertSubText
        };

        return (
            <Dialog
                hidden={hideAlert}
                // onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
            </Dialog>
        )
    }
}
