import React, { Component } from 'react'
import { Label, Panel, ActionButton, PanelType, PrimaryButton, DefaultButton, Spinner, SpinnerSize, TextField, Image } from 'office-ui-fabric-react'
import AlertRedux from '../../Alert/AlertRedux';
import { IconConstants } from '../../../Constants/StylesConstants';
import { ClientAPI } from '../Services/ClientAPI';
import { ClientUtility } from '../Utility/ClientUtility';
import ImageUploader from 'react-images-upload';
import * as CommonConstants from '../../../Constants/CommonConstants';
import Select from 'react-select';

export default class AddEditLocationPanel extends Component {

    _apiObject = null;
    _clientUtility;
    constructor(props) {
        super(props);

        this._apiObject = new ClientAPI(window.API_URL, window.loggedInUserDetails);
        this._clientUtility = new ClientUtility();
    }

    componentDidMount() {
        let {
            SelectedLocation
        } = this.props.Client;

        this.props.loadEditClientScreen({
            SelectedClient: SelectedLocation,
            apiObject: this._apiObject
        });

    }

    onDropClientLogo = (pictures) => {

        let {
            SelectedLocation,
        } = this.props.Client;

        if (pictures.length > 0) {
            const uploadImageData = new FormData();
            uploadImageData.append('imageFile', pictures[0],
                pictures[0].name);

            this.props.uploadClientLogo({
                SelectedClient: SelectedLocation,
                imageData: uploadImageData,
                apiObject: this._apiObject
            });
        }
    }

    saveLocation = () => {

        let { SelectedLocation, SelectedClient, IsClientEdit } = this.props.Client;

        delete SelectedLocation.clientLogoFile;
        delete SelectedLocation.clientLogoName;
        delete SelectedLocation.clientLogoType;

        this.props.saveLocationAsync({
            apiObject: this._apiObject,
            SelectedLocation: SelectedLocation,
            IsClientEdit: IsClientEdit,
            SelectedClient: SelectedClient
        });

    }

    render() {

        const {
            PanelHeaderText,
            LoadingAddEditLocationtPopUp,
            ShowAddEditLocationPopUp,
            SelectedLocation,
            alertJSON,
            IsClientEdit,
            isLoadingImage,
            imageUploadErrorMessage,
            LoOffices
        } = this.props.Client;
        let IsSaveDisbaled = "";
        if (!IsClientEdit)
            IsSaveDisbaled = !SelectedLocation.name;
        else
            IsSaveDisbaled = !SelectedLocation.name || !SelectedLocation.street || !SelectedLocation.officeIds;

        const isClientAdmin = [CommonConstants.CLIENT_ADMIN_ROLE, CommonConstants.APPROVER_ROLE,
        CommonConstants.SCHEDULER_ROLE, CommonConstants.LO_ADMIN_ROLE]
            .indexOf(window.loggedInUserDetails?.role) > -1;

        let selectedOffice = !SelectedLocation.officeIds ? null :
            SelectedLocation.officeIds?.split(",").map((office) => {
                let currentOffice = LoOffices.find((loOffice) =>
                    loOffice.value.toString() === office);
                return {
                    label: currentOffice?.label,
                    value: currentOffice?.value,
                }
            })


        return (
            <Panel
                headerText={`${PanelHeaderText}`}
                isOpen={ShowAddEditLocationPopUp}
                type={PanelType.medium}
                onDismiss={() => this.props.showHideClientAddEditLocationPopUp({
                    ShowAddEditLocationPopUp: false,
                    PanelHeaderText: "",
                })}
                closeButtonAriaLabel="Close">
                {!LoadingAddEditLocationtPopUp &&
                    <div className="ms-Grid" dir="ltr">

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ActionButton
                                    text="Save"
                                    disabled={IsSaveDisbaled}
                                    iconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.saveLocation()
                                    }}
                                />
                                <ActionButton
                                    text="Cancel"
                                    onClick={() => {
                                        this.props.showHideClientAddEditLocationPopUp({
                                            ShowAddEditLocationPopUp: false,
                                            PanelHeaderText: "",
                                        });
                                    }}
                                    iconProps={IconConstants.cancelIcon}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className="ms-Grid" dir="ltr">
                    {LoadingAddEditLocationtPopUp ?
                        <Spinner
                            label="Please Wait..."
                            size={SpinnerSize.large}
                        />
                        :
                        <React.Fragment>
                            {/* Input Field */}
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <TextField
                                            value={SelectedLocation.name}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SiteName",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            label={IsClientEdit ? "Client Name:" : "Site Name:"}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>
                                            {IsClientEdit ? `Client Address:` : `Site Address:`}
                                            {IsClientEdit ? <span className="mandatory-span">*</span> : <span className="mandatory-span"></span>}
                                        </Label>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLocation.street}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SiteStreet",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            // label="No. &amp; Street:"
                                            label={IsClientEdit ? "House No. & Street:" : "No. & Street:"}
                                            TextField required={IsClientEdit ? true : false}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLocation.city}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SiteCity",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            label="City:"
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLocation.state}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SiteState",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            label="State:"
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLocation.country}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SiteCountry",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            label="Country:"
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLocation.pin}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SitePin",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            label="Pin:"
                                            type="number"
                                            min={1}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLocation.phone}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SitePhone",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            label="Phone:"
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLocation.acronymCode}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SiteCode",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            label="Site Code:"
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLocation.externalId}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLocationDetails({
                                                    clientSiteFieldType: "SiteExternalId",
                                                    clientSiteFieldValue: newVal
                                                })
                                            }}
                                            label="External Id:"
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>LO Offices <span className="mandatory-span">*</span></Label>
                                        {isLoadingImage ?
                                            <Spinner
                                                size={SpinnerSize.large}
                                                label="Please wait..."
                                            />
                                            :
                                            // <Select
                                            //     value={selectedOffice}
                                            //     options={LoOffices}
                                            //     isMulti={false}
                                            //     onChange={(newVal) => {
                                            //         this.props.changeLocationDetails({
                                            //             clientSiteFieldType: "ClientOfficeId",
                                            //             clientOfficeIdValue: newVal
                                            //         })
                                            //     }}
                                            // />
                                            <Select
                                                value={selectedOffice}
                                                options={LoOffices}
                                                isMulti={true}
                                                onChange={(newVal) => {
                                                    this.props.changeLocationDetails({
                                                        clientSiteFieldType: "ClientOfficeId",
                                                        clientOfficeIdValue: newVal
                                                    })
                                                }}
                                            />
                                        }
                                    </div>
                                </div>
                                {SelectedLocation.id && SelectedLocation.objectType === "CLIENT_COMPANY" && isClientAdmin &&
                                    <React.Fragment>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Label>Logo:</Label>
                                            </div>
                                        </div>
                                        {isLoadingImage ?
                                            <Spinner size={SpinnerSize.large} label={`Loading Logo`} />
                                            :
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                    {SelectedLocation.clientLogoFile ?
                                                        <Image
                                                            src={SelectedLocation.clientLogoFile}
                                                            alt="No Logo Available"
                                                            styles={{
                                                                image: {
                                                                    display: "block",
                                                                    maxWidth: "17em",
                                                                    maxHeight: "2.85em",
                                                                    width: "auto",
                                                                    height: "auto"
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <small>No Logo Available!!</small>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {!isLoadingImage &&
                                            <>
                                                <ImageUploader
                                                    withIcon={false}
                                                    withLabel={false}
                                                    buttonText='Upload Client Logo'
                                                    onChange={this.onDropClientLogo}
                                                    imgExtension={['.jpg', '.png']}
                                                    maxFileSize={5242880}
                                                    singleImage={true}
                                                />
                                                <span className="mandatory-span">{imageUploadErrorMessage}</span>
                                            </>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                            <br />

                            {/* Button */}
                            <div className="ms-Grid" dir="ltr">

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <PrimaryButton
                                            text="Save"
                                            disabled={IsSaveDisbaled}
                                            menuIconProps={IconConstants.saveicon}
                                            onClick={() => {
                                                this.saveLocation()
                                            }}
                                        />
                                        {` `}
                                        <DefaultButton
                                            text="Cancel"
                                            onClick={() => {
                                                this.props.showHideClientAddEditLocationPopUp({
                                                    ShowAddEditLocationPopUp: false,
                                                    PanelHeaderText: "",
                                                });
                                            }}
                                            menuIconProps={IconConstants.cancelIcon}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </Panel >
        )
    }
}
