import React, { PureComponent } from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { IconConstants } from '../../../Constants/StylesConstants';
import { AssetAPI } from '../Services/AssetAPI';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class AssetConfirmRedux extends PureComponent {

    _userApi = null;

    constructor(props) {
        super(props);
        this._assetAPI = new AssetAPI(window.API_URL);

    }

    onArchiveAssetFile = () => {

        let { selectedAssetId, deletedFile } = this.props.Asset;

        const deletedFileData = new FormData();
        deletedFileData.append(`blobKey`, deletedFile.blobKey);

        this.props.deleteAssetFile({
            apiObject: this._assetAPI,
            deletedFile: deletedFileData,
            selectedAssetId
        });
    }

    render() {
        let { hideConfirmArchive, selectedAssetDescription, deletedFile } = this.props.Asset;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `Are you sure want to archive ${deletedFile.fileName} from Asset: ${selectedAssetDescription.name.value}?`
        };
        return (
            <Dialog
                hidden={hideConfirmArchive}
                onDismiss={() => this.props.openCloseConfirmModal({
                    hideConfirm: true,
                    deletedFile
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => this.onArchiveAssetFile()}
                        text="Yes"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseConfirmModal({
                            hideConfirm: true,
                            deletedFile
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
