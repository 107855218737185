import { DivisionUtility } from "../Utility/DivisionUtility";
import * as CONSTANTS from "./../Constants/DivisionConstants";
import { DivisionInitialState } from "./../Constants/DivisionIntialState";



export class DivisionAction {


    cleanDashboard = (payload) => {
        return { type: CONSTANTS.CLEAN_DIVISION_DASHBOARD, payload }
    }

    loadDivisionDashboard = (payload) => {
        return dispatch => {
            payload.isOngoingLoading = true;
            dispatch(this.loadDivisionDashboardAsync(payload));
            payload.apiObject.getDivisionDetails(payload.objectType, payload.offset, payload.limit)
                .then((response) => {
                    payload.isOngoingLoading = false;
                    payload.Divisions = response[0].data;
                    dispatch(this.loadDivisionDashboardAsync(payload));
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = "Error while loading Division";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
        }
    }

    loadDivisionDashboardAsync = (payload) => {
        return { type: CONSTANTS.LOAD_DIVISION_DASHBOARD, payload }
    }

   
// Add/Edit panel

loadAttributeDropDowns = (payload) => {
    return dispatch => {
        payload.isOngoingLoading = true;
        dispatch(this.loadAttributeDropDownsAsync(payload));
        payload.apiObject.getAllFormAttributes(payload.objectType)
            .then((response) => {
                payload.isOngoingLoading = false;
                payload.FormAttributes = response.data;
                dispatch(this.loadAttributeDropDownsAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Ooops!!";
                payload.alertSubText = "Error while loading Attributes";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
    }
}

loadAttributeDropDownsAsync = (payload) => {
    return { type: CONSTANTS.LOAD_DIVISION_ATTRIBUTES, payload }
}

changeDivisionTab = (payload) => {
    return { type: CONSTANTS.CHANGE_DIVISION_TAB, payload }
}

closeDivisionPanel = (payload) => {
    return { type: CONSTANTS.CLOSE_DIVISION_DETAILS_PANEL, payload }
}

changeDivisionDescription = (payload) => {
    switch (payload.field) {
        case "name":
            return { type: CONSTANTS.CHANGE_DIVISION_NAME, payload }

        case "acronymCode":
            return { type: CONSTANTS.CHANGE_DIVISION_ACRONYM_CODE, payload }

      /*  case "description":
            return { type: CONSTANTS.CHANGE_DIVISION_DESCRIPTION, payload }

        case "displayName":
            return { type: CONSTANTS.CHANGE_DIVISION_DISPLAY_NAME, payload }*/

        default:
            break;
    }
}

changeDivisionAttributes = (payload) => {
    switch (payload.field) {
        case "form":
            return { type: CONSTANTS.CHANGE_DIVISION_FORM_ATTRIBUTE, payload }

        default:
            break;
    }
}

// Save Asset Type
saveDivisionDetails = (payload) => {
    return { type: CONSTANTS.SAVE_DIVISION_DETAILS, payload };
}

saveDivisionDetailsAsync = (payload) => {
    return dispatch => {
        payload.isOnGoingSave = true;
        dispatch(this.saveDivisionDetails(payload));

        let utility = new DivisionUtility();
        let divisionPayload = utility.generateDivisionJSONPayLoad(payload.CurrentDivision);

        payload.apiObject.saveDivisionConfigData(divisionPayload).then((response) => {
            payload.isOnGoingSave = false;
            dispatch(this.saveDivisionDetails(payload))
            payload.hideAlert = false;
            payload.alertHeader = "Success !!";
            payload.alertSubText = "Data saved successfully";
            dispatch(this.openCloseAlertModal(payload));

            setTimeout(() => {
                payload.hideAlert = true;
                payload.alertHeader = "";
                payload.alertSubText = "";
                payload.objectType = "DIVISION";
                payload.offset = 0;
                payload.limit = DivisionInitialState.limit;
                dispatch(this.openCloseAlertModal(payload));
                dispatch(this.cleanDashboard(payload));
                dispatch(this.closeDivisionPanel(payload));
                dispatch(this.loadDivisionDashboard(payload));
            }, 2000);

        }).catch((error) => {
            console.error("Error: ", error);

            payload.hideAlert = false;
            payload.alertHeader = "Error !!";
            payload.alertSubText = "Error while saving Division Details";
            dispatch(this.openCloseAlertModal(payload));

            setTimeout(() => {
                payload.hideAlert = true;
                payload.alertHeader = "";
                payload.alertSubText = "";
                dispatch(this.openCloseAlertModal(payload));
            }, 2500);

        })
    }
}

openCloseAlertModal = (payload) => {
    return { type: CONSTANTS.OPEN_CLOSE_DIVISION_ALERT_MODAL, payload };
}

openCloseDivisionConfirmModal = (payload) => {
    return { type: CONSTANTS.OPEN_CLOSE_DIVISION_CONFIRM_MODAL, payload };
}

deleteDivision = (payload) => {
    return { type: CONSTANTS.DELETE_DIVISION, payload };
}

deleteDivisionAsync = (payload) => {
    return dispatch => {
        payload.isOngoingDelete = true;
        dispatch(this.deleteDivision(payload));

        payload.apiObject.deleteDivision(payload.CurrentAssetType).then((response) => {
            payload.isOngoingDelete = false;
            payload.CurrentAssetType = response.data;
            dispatch(this.deleteDivision(payload))
            payload.hideAlert = false;
            payload.alertHeader = "Success !!";
            payload.alertSubText = "Asset Type Archived successfully. Please refresh to check data";
            dispatch(this.openCloseAlertModal(payload));

            setTimeout(() => {
                payload.hideAlert = true;
                payload.alertHeader = "";
                payload.alertSubText = "";
                dispatch(this.openCloseAlertModal(payload));
            }, 2000);

        }).catch((error) => {
            console.error("Error: ", error);

            payload.hideAlert = false;
            payload.alertHeader = "Error !!";
            payload.alertSubText = "Error while deleting Division";
            dispatch(this.openCloseAlertModal(payload));

            setTimeout(() => {
                payload.hideAlert = true;
                payload.alertHeader = "";
                payload.alertSubText = "";
                dispatch(this.openCloseAlertModal(payload));
            }, 2500);

        })
    }
}

}
