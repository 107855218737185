import { ISelect } from "../../../CommonInterfaces/CommoInterfaces";

export default class ClientSiteSelectorUtility {
    
    public static loadSelectDropDownValue = (responseArray: any[]): ISelect[] => {
        let dropDownValues: ISelect[] = [];


        responseArray.forEach((data: any) => {
            dropDownValues.push({
                label: data.name,
                value: data.id,
                acronymCode: data.acronymCode
            });
        });

        return dropDownValues;
    }
}
