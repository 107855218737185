import { ILoggedInUser } from "../../CommonInterfaces/CommoInterfaces";
import axios from 'axios';
import { SETTING_API_URL, GET_LOCATION_BY_ID_URL } from "./HomeConstant";
import { ALERT_MESSAGE } from "../../Constants/MessageConstants";

export default class HomePageAPI {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;


    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }

    public loadDashboardConfigTabs(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(SETTING_API_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=ConfigData`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error loadDashboardConfigTabs method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getOffice(officeId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(`${GET_LOCATION_BY_ID_URL}/${officeId}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error officeId method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getDivision(divisionId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(`${SETTING_API_URL}/${divisionId}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getDivision method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getConfigurations(divisionIds: number[], officeIds: number[]): Promise<any> {
        let promises = [this.loadDashboardConfigTabs()];

        if (divisionIds) {
            divisionIds.forEach((divisionId: number) => {
                if (divisionId) {
                    promises.push(this.getDivision(divisionId));
                }
            });
        }

        if (officeIds) {
            officeIds.forEach((officeId: number) => {
                if (officeId) {
                    promises.push(this.getOffice(officeId))
                }
            });
        }

        return Promise.all(promises);

    }

}
