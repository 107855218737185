import React, { Component } from 'react';
import { ActionButton, Spinner, Separator, Toggle, IconButton } from 'office-ui-fabric-react';
import { JobLitesAPI } from '../Services/JobLitesAPI';
import Select from 'react-select';
import { IconConstants, seperatorTheme } from '../../../Constants/StylesConstants';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { TaskUtility } from '../../Tasks/Utility/TaskUtility';
import * as CONSTANTS from '../Constants/JobLitesConstants';
import AddAssetPopUp from './AddAssetPopUp';
import { TASK_STATUS_DELETED, TASK_STATUS_OPEN } from '../../Tasks/Constants/TaskConstants';

export default class TaskDetails extends Component {

    _apiObject = null;
    _taskUtility = null;
    constructor(props) {
        super(props);

        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails);
        this._taskUtility = new TaskUtility();
    }

    render() {

        let {
            CurrentJobDetails,
            RequesterOptions,
            AllTaskTypeOptions,
            DivisionOptions,
            SelectedJob,
            addNewAsset,
        } = this.props.JobLites;

        // let allInspectors = CurrentJobDetails.Inspectors.value ? CurrentJobDetails.Inspectors.value.concat(CurrentJobDetails.MainInspector.value)
        //     : [].concat(CurrentJobDetails.MainInspector.value);
        let allInspectors = RequesterOptions;

        const currentUserRole = window.loggedInUserDetails.role;

        const isAddTaskButtonDisabled =
            (CurrentJobDetails && CurrentJobDetails.status === 16)
            || (CurrentJobDetails && CurrentJobDetails.status === 64)
            || (CONSTANTS.USER_ADD_JOB_DISABLE.indexOf(currentUserRole) > - 1
                && !CurrentJobDetails?.isDayJob);

        return (
            <div className="ms-Grid" dir="ltr" >
                <Separator theme={seperatorTheme}>Task Details</Separator>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                        <table className="table table-sm">
                            <tbody>

                                <tr>
                                    <th>Action</th>
                                    <th>SL. NO</th>
                                    <th>DIVISION <span className="mandatory-span">*</span></th>
                                    <th>TASK TYPE <span className="mandatory-span">*</span></th>
                                    <th style={{ display: "none" }}>ASSET TYPE</th>
                                    <th>ASSET</th>
                                    <th>INSPECTOR</th>
                                </tr>
                            </tbody>
                            <tbody>
                                {CurrentJobDetails.TaskDetails &&
                                    CurrentJobDetails.TaskDetails.map((task, index) => {
                                        return (
                                            <tr key={index} className={(task.status === 1024) ? "deleted-task-row" : ""}>
                                                {!task.isNew ?
                                                    <td>
                                                        {task.status === TASK_STATUS_OPEN &&
                                                            <IconButton
                                                                menuIconProps={IconConstants.deleteicon}
                                                                onClick={() => {
                                                                    this.props.deleteOrReopenTask({
                                                                        taskStatus: 1024,
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                        {task.status === TASK_STATUS_DELETED &&
                                                            <IconButton
                                                                menuIconProps={IconConstants.confirmIcon}
                                                                onClick={() => {
                                                                    this.props.deleteOrReopenTask({
                                                                        taskStatus: 1,
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                    </td>
                                                    :
                                                    <td></td>
                                                }
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <Select
                                                        isDisabled={!task.isNew}
                                                        value={task.Division.value}
                                                        onChange={(newval) => {
                                                            this.props.changeJobTaskDivision({
                                                                Division: newval,
                                                                index
                                                            });
                                                        }}
                                                        options={DivisionOptions}
                                                    />
                                                </td>
                                                {/* TASK TYPE */}
                                                <td>
                                                    <Select
                                                        isDisabled={!task.isNew}
                                                        value={task.TaskType.value}
                                                        onChange={(newval) => {
                                                            this.props.loadAssetTypeAndAssetOptionsAsync({
                                                                apiObject: this._apiObject,
                                                                TaskType: newval,
                                                                index
                                                            });
                                                        }}
                                                        options={task.Division.value ?
                                                            AllTaskTypeOptions.filter(
                                                                d => parseInt(d.division) ===
                                                                    task.Division.value.value
                                                                    && d.label.indexOf("LLG") === -1
                                                            )
                                                            : []
                                                        }
                                                    />
                                                </td>
                                                <td style={{ display: "none" }}>
                                                    {task.Asset.loading ?
                                                        <Spinner label="Loading Asset Type..." />
                                                        :
                                                        <span>
                                                            {task.AssetType.value ? task.AssetType.value.name : ``}
                                                        </span>
                                                    }
                                                </td>
                                                <td>
                                                    {task.Asset.loading ?
                                                        <Spinner label="Loading Assets..." />
                                                        :
                                                        <Select
                                                            isClearable={true}
                                                            isDisabled={task.status === 8 || task.status === 16 || task.status === 64 || task.status === 1024}
                                                            value={task.Asset.value}
                                                            onChange={(newval) => {
                                                                this.props.changeAsset({
                                                                    Location: newval,
                                                                    index
                                                                });
                                                            }}
                                                            options={
                                                                CurrentJobDetails?.SiteDetails.value ?
                                                                    CommonUtility.sortByColumn(task.AssetOptions.filter(d => d.parentId === CurrentJobDetails?.SiteDetails.value.value), "label", "asc")
                                                                    : []
                                                            }
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    <Select
                                                        value={task.Inspector.value}
                                                        // isDisabled={task.status === 8 || task.status === 16 || task.status === 64 || task.status === 1024}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                this.props.changeInspector({
                                                                    User: value,
                                                                    index
                                                                })
                                                            }
                                                        }}
                                                        options={
                                                            task.Division.value ?
                                                                CommonUtility.sortByColumn(
                                                                    allInspectors
                                                                        .filter(
                                                                            d => d.divisionIds && d.divisionIds.split(",")
                                                                                .indexOf(task.Division.value.value.toString()) > -1), "label", "asc")

                                                                : []
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        <ActionButton
                            text="Add New"
                            disabled={isAddTaskButtonDisabled}
                            iconProps={IconConstants.addIcon}
                            onClick={() => this.props.createNewTaskRow({
                                isOnLLGState: false
                            })}
                        />
                        <ActionButton
                            disabled={
                                (CurrentJobDetails?.TaskDetails.length < 2) ||
                                CurrentJobDetails?.TaskDetails.filter(d => d.isNew).length === 0
                            }
                            text="Remove Last New Item"
                            iconProps={IconConstants.removeIcon}
                            onClick={() => this.props.deleteLastTaskRow()}
                        />
                        <ActionButton
                            text="Add New Asset"
                            iconProps={IconConstants.addIcon}
                            onClick={() => this.props.openCloseAddNewAssetPopUp({
                                isOpenAddNewAsset: true
                            })}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Toggle
                            disabled={SelectedJob}
                            onText="(Day Job Selector) Deselect to Prevent adding field task"
                            offText="(Day Job Selector) Select to Allow adding field tasks"
                            checked={CurrentJobDetails?.isDayJob}
                            onChange={() => this.props.changeIsDayJob({
                                isDayJob: !CurrentJobDetails?.isDayJob
                            })
                            }
                        />
                    </div>
                </div>
                {
                    !addNewAsset &&
                    <AddAssetPopUp
                        {...this.props}
                    />
                }
            </div >
        )
    }
}
