import React, { Component } from 'react'
import { TaskTypeAPI } from '../Services/TaskTypeAPI';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Attributes from './Attributes';
import Description from './Description';
import TaskTypeFiles from './TaskTypeFiles';
import { Panel, PanelType, Spinner, SpinnerSize, ActionButton, PrimaryButton } from 'office-ui-fabric-react';
import * as TaskTypeConstants from '../Constants/TaskTypeConstants';
import AlertRedux from '../../../Alert/AlertRedux';
import { IconConstants } from '../../../../Constants/StylesConstants';

export default class AddEditTaskTypePanel extends Component {

    _taskTypeAPI = null;
    constructor(props) {
        super(props);
        this._taskTypeAPI = new TaskTypeAPI(window.API_URL, window.loggedInUserDetails.jsessionid);
    }

    // UNSAFE_componentWillMount() {
    //     this.props.loadDropDownDataAsync({
    //         apiObject: this._taskTypeAPI,
    //     });
    // }

    render() {

        let {
            PanelHeaderText,
            ShowTaskTypeAddEditPanel,
            TabIndex,
            LoadingTaskTypeAddEditPanel,
            CurrentTaskType,
            alertJSON
        } = this.props.TaskType

        return (
            <Panel
                headerText={PanelHeaderText + (CurrentTaskType.id > -1 ? CurrentTaskType.name : "")}
                isOpen={ShowTaskTypeAddEditPanel}
                type={PanelType.extraLarge}
                onDismiss={() => this.props.closeTaskTypePanel({
                    ShowPanel: false,
                    PanelHeaderText: "",
                })}
                closeButtonAriaLabel="Close">
                {LoadingTaskTypeAddEditPanel ?
                    <div className="login-spinner-div">
                        <Spinner
                            size={SpinnerSize.large}
                            label="Please wait..."
                        />
                    </div>
                    :
                    <React.Fragment>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ActionButton
                                    disabled={!CurrentTaskType.name || !CurrentTaskType.division || !CurrentTaskType.IsValidSectionJSON || !CurrentTaskType.validity}
                                    text="SAVE"
                                    iconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveTaskTypeDetailsAsync({
                                            CurrentTaskType: CurrentTaskType,
                                            apiObject: this._taskTypeAPI
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <Tabs selectedIndex={TabIndex} onSelect={(index) => this.props.changeTaskTypeTab({ TabIndex: index })}>
                            <TabList>
                                <Tab>{TaskTypeConstants.TASK_TYPE_TABS[0]}</Tab>
                                <Tab>{TaskTypeConstants.TASK_TYPE_TABS[1]}</Tab>
                                <Tab disabled>{TaskTypeConstants.TASK_TYPE_TABS[2]}</Tab>
                            </TabList>

                            <TabPanel>
                                <Description
                                    {...this.props}
                                />
                            </TabPanel>

                            <TabPanel>
                                <Attributes
                                    {...this.props}
                                />
                            </TabPanel>

                            <TabPanel>
                                <TaskTypeFiles
                                    {...this.props}
                                />
                            </TabPanel>

                        </Tabs>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    disabled={!CurrentTaskType.name || !CurrentTaskType.division || !CurrentTaskType.IsValidSectionJSON}
                                    text="SAVE"
                                    menuIconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveTaskTypeDetailsAsync({
                                            CurrentTaskType: CurrentTaskType,
                                            apiObject: this._taskTypeAPI
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                }
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </Panel>
        )
    }
}
