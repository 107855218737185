import {SAVE_QRSTICKER,
    CHANGE_QRSTICKER_SIZE,
    CHANGE_QRSTICKER_COLOR,
    CHANGE_QRSTICKER_QUANTITY,
    CLEAN_QRSTICKER,
    REQUEST_PROCESSING,
    SHOW_SUCCESS_MESSAGE,
    CLEAR_SUCCESS_MESSAGE,
}from "../Constants/QRStickerConstants"
export class  QRStickerActions{
    changeSize = (payload) => {
        return { type: CHANGE_QRSTICKER_SIZE, payload };
    }
    changeColor = (payload) => {
        return { type: CHANGE_QRSTICKER_COLOR, payload };
    }
    changeQuantity = (payload) => {
        return { type: CHANGE_QRSTICKER_QUANTITY, payload };
    }
    saveQRSticker = (payload) => {
        return { type: SAVE_QRSTICKER, payload };
    }
    cleanQRSticker = (payload) => {
        return { type: CLEAN_QRSTICKER, payload }

    }
    showSuccessMessage = (successMessage) => {
      return{  type: SHOW_SUCCESS_MESSAGE,
       payload: successMessage}
    };
    clearSuccessMessage = () => {
        return{type: CLEAR_SUCCESS_MESSAGE
    }};
    requestProcessing = (isProcessing) => {
        return { type: REQUEST_PROCESSING, payload: isProcessing };
    }
    saveQRStickerAsync = (payload) => {
        return dispatch => {
            dispatch(this.requestProcessing(true));
           // dispatch(this.loadAddEditUserPanel(payload))
            let qrStickerPayload = {};

            qrStickerPayload = {
               // id: payload.CurrentUser.id,
                size: payload.CurrentQRSticker.size.value.value,
                color: payload.CurrentQRSticker.color.value.value,
                quantity: payload.CurrentQRSticker.quantity.value.value,
                createdBy:window.loggedInUserDetails.id,
            }
            payload.apiObject.saveQRSticker(qrStickerPayload).then((responseStatus) => {

                dispatch(this.requestProcessing(false));
                dispatch(this.saveQRSticker(payload));
                dispatch(this.showSuccessMessage('QR Sticker saved successfully!!Please check your Mail'));
                this.cleanQRSticker();
               
            }).catch((error) => {
                // console.error("Error: ", error);
                dispatch(this.requestProcessing(false));
                dispatch(this.showSuccessMessage("Error saving QR Sticker: " + error.message));

                payload.hideAlert = false;
                payload.alertHeader = "Error !!";
                payload.alertSubText = error;
            })
        }
    }
}
