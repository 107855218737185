import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, Dropdown, Label } from 'office-ui-fabric-react';
import { LOOSE_GEAR_BREADCRUMBS } from '../Constants/LooseGearsConstants';
import LooseGearMainFilter from './LooseGearMainFilter';
import { ILooseGearStateProps } from '../Interfaces/ILooseGearStateProps';
import { bindActionCreators, Dispatch } from 'redux';
import { LooseGearActions } from '../Actions/LooseGearActions';
import { TaskDropDownStyles } from '../../Tasks/Constants/TaskStylesConstants';

const mapStateToProps = (state: any): ILooseGearStateProps => {
    return {
        LooseGearState: state.LooseGearReducer
    }
}

class LooseGearsComponent extends React.PureComponent<ILooseGearStateProps, {}> {

    public render(): JSX.Element {

        let breadcrumbs = LOOSE_GEAR_BREADCRUMBS;
        breadcrumbs[1].text = sessionStorage.getItem('link');
        let {
            isLoadingLooseGearClients,
            isLoadingLooseGearSites,
            isLoadingLooseGearDashboard,
            showColumns
        } = this.props.LooseGearState;

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={LOOSE_GEAR_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Columns: </Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <Dropdown
                                disabled={isLoadingLooseGearClients || isLoadingLooseGearSites || isLoadingLooseGearDashboard}
                                placeholder="Select columns"
                                selectedKeys={showColumns}
                                onChange={(event, selectedColumn) => {
                                    this.props.showHideLLGDashboardColumns({
                                        selectedColumn: selectedColumn
                                    })
                                }}
                                multiSelect
                                options={[
                                    { key: 'taskTypeDesc', text: 'Item' },
                                    { key: 'specification', text: 'Specifications' },
                                    { key: 'llgSerialNumber', text: 'Serial Number' },
                                    { key: 'acronymCode', text: 'ID' },
                                    { key: 'result', text: 'Last Result' },
                                    { key: 'actualInspectionDate', text: 'Inspection Date' },
                                    { key: 'dueDate', text: 'Next Due' },
                                    { key: 'certificateNumber', text: 'Certificate Number' },
                                    { key: 'site', text: 'Site' },
                                    { key: 'certificateFileContent', text: 'Report' },
                                    { key: 'bookletContent', text: 'Booklet' },
                                    { key: 'itemStatus', text: 'Current Status' },
                                ]}
                                styles={TaskDropDownStyles}
                            />
                        </div>
                    </div>
                </div>
                <LooseGearMainFilter
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => {

    let looseGearActions: LooseGearActions = new LooseGearActions();

    return bindActionCreators({ ...looseGearActions }, dispatch);
}

const LooseGears = connect(mapStateToProps, mapDispatchToProps)(LooseGearsComponent);
export default LooseGears;
