import { TemplatesInitialState } from "../Constants/TemplatesIntialState";
import { ITemplateAction, ITemplatesInitialState } from "../Interfaces/ITemplates";

export function TemplatesReducer(state: ITemplatesInitialState = TemplatesInitialState, action: ITemplateAction): ITemplatesInitialState {

    let tempState: ITemplatesInitialState = { ...state };
    // let payload: ITemplatesPayload = action.payload;

    // console.log(payload);

    switch (action.type) {

        default:
            break;
    }

    return tempState;

}