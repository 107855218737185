import { IAssessmentsActionKeys } from "../Interfaces/IAssessmentsActions";
import { IAssessmentsPayload } from "../Interfaces/IAssessmentsPayload";
import * as CONSTANTS from "../Constants/AssessmentsConstants";
import { Dispatch } from "redux";
import { IAssessmentStateProps } from "../Interfaces/IAssessmentStateProps";
import { AssessmentsInitialState } from "../Constants/AssessmentsInitialState";
import { CLIENT_USER_ROLE, SITE_USER_ROLE } from "../../../Constants/CommonConstants";

export class AssessmentsActions implements IAssessmentStateProps {

    public cleanAssessmentDashboard = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.CLEAN_ASSESSMENT_DASHBOARD, payload }
    }

    public showLoaderDashboard = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.SHOW_LOADER_DASHBOARD, payload }
    }

    public loadAssessmentDashboard = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.LOAD_ASSESSMENT_DASHBOARD, payload }
    }

    public showHideAssesmentDashboardColumns = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.SHOW_HIDE_ASSESMENT_DASHBOARD_COLUMNS, payload }
    }

    public loadAssessmentDashboardAsync = (payload: IAssessmentsPayload): any => {
        return (dispatch: Dispatch) => {
            payload.isLoading = true;
            dispatch(this.showLoaderDashboard(payload));
            if (!payload.offset) {
                dispatch(this.cleanAssessmentDashboard(payload));
            }
            else if (payload.isReset) {
                dispatch(this.cleanAssessmentsFilter(payload));
            }
            payload.apiPayload.getTaskDetails(payload.config, payload.offset, payload.limit).then((response) => {
                payload.isLoading = false;
                payload.assessments = response[0].data;
                // payload.TotalClientCount = response[1].data;
                dispatch(this.loadAssessmentDashboard(payload));
            }).catch((error: any) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Assessments"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public handleAssessmentsGridGroupSortChanged = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.HANDLE_ASSESSMENT_GRID_GROUP_SORT_CHANGE, payload }
    }

    public handleAssessmentsGridFilterChangedAsync = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.HANDLE_ASSESSMENT_GRID_FILTER_CHANGE, payload }
    }

    public handleAssessmentsGridFilterChanged = (payload: IAssessmentsPayload): any => {
        return (dispatch: any) => {
            dispatch(this.handleAssessmentsGridFilterChangedAsync(payload));
            payload.apiPayload.getTaskDetails(payload.config, payload.offset, payload.limit).then((response: any) => {
                payload.assessments = response[0].data;
                dispatch(this.loadAssessmentDashboard(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while Change Filter"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public handleAssessmentsGridExpandChanged = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.HANDLE_ASSESSMENT_GRID_EXPAND_CHANGE, payload }
    }

    public changeFilterDateChange = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.CHANGE_FILTER_DATE_RANGE, payload }
    }

    public showHideCertificatePanel = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.SHOW_HIDE_ASSESSMENT_CERTIFICATE_PANEL, payload }
    }

    public openCloseObservationHistoryPanel = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.OPEN_CLOSE_ASSESSMENT_HISTORY_PANEL, payload }
    }

    public switchTaskAndServiceAssessmentAsync = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.SWITCH_TASK_AND_SERVICE_ASSESSMENT, payload }
    }

    public cleanAssessmentsFilter = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.CLEAN_ASSESSMENT_FILTER, payload }
    }

    public loadClientsAssessment = (payload: IAssessmentsPayload): any => {
        payload.isLoading = true;
        return (dispatch: any) => {
            dispatch(this.cleanAssessmentDashboard(payload));
            payload.assetApi.loadClientsAPI().then((response: any) => {
                let currentUserRole: string = window.loggedInUserDetails.role;
                if ([SITE_USER_ROLE, CLIENT_USER_ROLE].indexOf(currentUserRole) > -1) {
                    payload.locationFilters = response.data.filter((data: any) =>
                        data.id === window.loggedInUserDetails.companyId)
                }
                else {
                    payload.locationFilters = response.data;
                }
                payload.isLoading = false;
                dispatch(this.loadClientsAssessmentAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Clients"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public loadClientsAssessmentAsync = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.LOAD_CLIENTS_ASSESSMENT, payload }
    }

    public loadSitesAssessments = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.LOAD_SITES_ASSESSMENT, payload }
    }

    public loadSitesAssessmentsAsync = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        throw new Error("Method not implemented.");
    }

    public changeAssessmentsClientFilter = (payload: IAssessmentsPayload): any => {
        payload.isLoading = true;
        return (dispatch: any) => {
            dispatch(this.changeAssessmentsClientFilterAsync(payload));
            payload.assetApi.loadSitesAPI(payload.selectedLocation.value).then((response: any) => {
                payload.isLoading = false;
                let currentUserRole: string = window.loggedInUserDetails.role;
                if ([SITE_USER_ROLE, CLIENT_USER_ROLE].indexOf(currentUserRole) > -1) {
                    payload.locationFilters = response.data.filter((data: any) =>
                        window.loggedInUserDetails.siteIds.split(",").indexOf(data.id.toString()) > -1)
                }
                else {
                    payload.locationFilters = response.data;
                }
                dispatch(this.loadSitesAssessments(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Sites"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
            payload.apiPayload.getAssessmentsApi(payload.offset, AssessmentsInitialState.limit, payload.config)
                .then((response: any) => {
                    payload.isLoading = false;
                    payload.assessments = response.data;
                    dispatch(this.loadAssessmentsDashboard(payload));
                }).catch((error) => {
                    console.error("Error changeAssessmentsClientFilter: ", error);
                    payload.alertJSON = {
                        hideAlert: false,
                        alertHeader: "Ooops!!",
                        alertSubText: "Error while loading Assessments"
                    }
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.alertJSON = {
                            hideAlert: true,
                            alertHeader: "",
                            alertSubText: ""
                        }
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
        }
    }

    public changeAssessmentsClientFilterAsync = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.CHANGE_ASSESSMENT_CLIENT_FILTER, payload }
    }

    public changeAssessmentsSiteFilter = (payload: IAssessmentsPayload): any => {
        payload.isLoading = true;
        return (dispatch: any) => {
            if (payload.isReset) {
                dispatch(this.cleanAssessmentsFilter(payload));
            }
            dispatch(this.changeAssessmentsSiteFilterAsync(payload));
            payload.apiPayload.getAssessmentsApi(payload.offset, AssessmentsInitialState.limit, payload.config).then((response: any) => {
                payload.isLoading = false;
                payload.assessments = response.data;
                // payload.assessmentsCount = response[1].data;
                dispatch(this.loadAssessmentsDashboard(payload));
            }).catch((error) => {
                console.error("Error changeAssessmentsSiteFilter: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Loose Gears"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public changeAssessmentsSiteFilterAsync = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.CHANGE_ASSESSMENT_SITE_FILTER, payload }
    }

    public loadAssessmentsDashboardAsync = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.LOAD_ASSESSMENT_DASHBOARD, payload }
    }

    public loadAssessmentsDashboard = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.LOAD_ASSESSMENT_DASHBOARD, payload }
    }

    public loadTaskDetailsAsync = (payload: IAssessmentsPayload): IAssessmentsActionKeys => {
        return { type: CONSTANTS.LOAD_TASK_DETAILS, payload }
    }

    public loadTaskDetails = (payload: IAssessmentsPayload): any => {

        return (dispatch: any) => {
            payload.isLoading = true;
            dispatch(this.loadTaskDetailsAsync(payload));
            payload.apiPayload.getSelectedObservationAPI(payload.selectedTaskId).then((response: any) => {
                payload.isLoading = false;
                payload.task = response.data;
                dispatch(this.loadTaskDetailsAsync(payload));
            }).catch((error: any) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Clients"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public openCloseAlertModal = (payload: IAssessmentsPayload) => {
        return { type: CONSTANTS.OPEN_CLOSE_ASSESSMENT_ALERT_MODAL, payload };
    }


}
