import React from 'react'
import { LoOfficeAPI } from '../Services/LoOfficeAPI';
import AlertRedux from '../../Alert/AlertRedux';
import { IconButton, PrimaryButton, ActionButton, TooltipHost, SearchBox } from 'office-ui-fabric-react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import BootstrapTable from 'react-bootstrap-table-next';
import { DATE_TIME_FORMAT_USER,  } from './../../../Constants/MessageConstants';
import { DeletedRowStyle, IconConstants, calloutProps, tooltipId, hostStyles } from '../../../Constants/StylesConstants';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment-timezone';
import AddEditLoOfficePopUp from './AddEditLoOfficePopUp';
import { LoOfficeInitialState } from '../Constants/LoOfficeInitialState';
import ConfirmLoOfficeDelete from './ConfirmLoOfficeDelete';

const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
}];

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="table-export-button-div">
            <PrimaryButton
                onClick={handleClick}
                text="Export to CSV"
                menuIconProps={IconConstants.exportIcon}
            />
        </div>
    );
};

const RemoteAll = ({
    data, columns, onTableChange, page, hideSizePerPage, style
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
            exportCSV={{
                fileName: 'Qwikspec_Lo_Offices.csv',
                noAutoBOM: false,
                blobType: 'text/csv;charset=ansi'
            }}
        >
            {
                props => (
                    <React.Fragment>
                        <MyExportCSV {...props.csvProps} />
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({ page, hideSizePerPage })}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            rowClasses={DeletedRowStyle}
                            wrapperClasses="table-responsive" />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class LoOfficeDashboard extends React.PureComponent {

    _loOfficeApi = null;

    constructor(props) {
        super(props);
        this._loOfficeApi = new LoOfficeAPI(window.API_URL, window.loggedInUserDetails);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {
        this.props.loadLoOfficeDashboard({
            offset: 0,
            limit: this.props.LoOffice.limit,
            apiObject: this._loOfficeApi,
            SelectedLoOfficeLocation: this.props.LoOffice.SelectedLoOfficeLocation
        });

    }

    fetchMoreData = () => {

        this.props.loadLoOfficeDashboard({
            offset: this.props.LoOffice.offset,
            limit: this.props.LoOffice.limit,
            apiObject: this._loOfficeApi,
            SelectedLoOfficeLocation: this.props.LoOffice.SelectedLoOfficeLocation
        });

    };


    render() {

        let columns = [
            {
                dataField: 'manageLoOfficeIcons',
                isDummyField: true,
                text: 'Action',
                formatter: (cellContent, row) => {
                    return (
                        <span>
                            {row.status === 1 ?
                                <React.Fragment>
                                    <IconButton
                                        onClick={() => this.props.showHideLoOfficeAddEditLocationPopUp({
                                            ShowAddEditLoOfficePopUp: true,
                                            SelectedLoOfficeLocation: row,
                                        })}
                                        iconProps={IconConstants.editicon} />
                                    <IconButton
                                            onClick={() => {
                                                this.props.openCloseLoOfficeConfirmModal({
                                                    hideConfirm: false,
                                                    CurrentLoOffice: row
                                                });
                                            }}
                                        iconProps={IconConstants.deleteicon} />

                                </React.Fragment>
                                :
                                <span></span>
                            }
                        </span>
                    )
                },
                csvExport: false
            },
            {
                dataField: 'id',
                text: 'LO OFFICE ID',
                hidden: true,
                csvExport: false
            },
            {
                dataField: 'acronymCode',
                text: 'Code',
            },
            {
                dataField: 'name',
                text: 'Name',
            },
            {
                dataField: 'dummyField',
                text: 'Address',

                formatter: (cellContent, row) => {
                    return (
                        <TooltipHost
                            content={`${row.street} ${row.city} ${row.state} ${row.pin} ${row.country}`}
                            id={tooltipId}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            <span>{row.street} {row.city} {row.pin} {row.country}</span>

                        </TooltipHost>
                    )
                }
            },
            {
                dataField: 'createdDate',
                text: 'Created',
                formatter: (cellContent, row) => {
                    return (
                        cellContent ?
                            <span>{moment(cellContent).utc().format(DATE_TIME_FORMAT_USER)}</span> :
                            <span></span>
                    )
                }
            },
            {
                dataField: 'modifiedDate',
                text: 'Modified',
                formatter: (cellContent, row) => {
                    return (
                        cellContent ?
                            <span>{moment(cellContent).utc().format(DATE_TIME_FORMAT_USER)}</span> :
                            <span></span>
                    )
                }
            },

        ];

        let {
            LoadingLoOfficeDashboard,
            LoOfficeLocations,
            alertJSON,
            
            loOffice_name_filter,
            loOffice_code_filter,
        
            hideConfirm,
            ShowLoOfficeDetailsTab,
            ShowAddEditLoOfficePopUp,
        } = this.props.LoOffice;

        let filteredLoOffice = [...LoOfficeLocations];

        return (
            <React.Fragment>
                {
                    !ShowLoOfficeDetailsTab ?
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ActionButton
                                        disabled={LoadingLoOfficeDashboard}
                                        text="Reload"
                                        iconProps={IconConstants.refreshIcon}
                                        onClick={() => this.loadInitData()}
                                    />
                                    <ActionButton
                                        text="Add Lo Office"
                                        iconProps={IconConstants.addIcon}
                                        onClick={() => this.props.showHideLoOfficeAddEditLocationPopUp({
                                            ShowAddEditLoOfficePopUp: true,
                                            SelectedLoOfficeLocation: LoOfficeInitialState.SelectedLoOfficeLocation

                                        })}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <SearchBox
                                        value={loOffice_name_filter}
                                        placeholder="Search Lo Office Name"
                                        underlined={true}
                                        onSearch={(newValue) => {
                                            let filterJson = {
                                                "name": newValue,
                                                "code": loOffice_code_filter,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.loadLoOfficeDashboard({
                                                filterType: "name",
                                                filterValue: newValue,
                                                offset: 0,
                                                limit: this.props.LoOffice.limit,
                                                apiObject: this._loOfficeApi,
                                                config: config,
                                                filtered: true
                                            });
                                        }
                                        }
                                        onClear={() => {
                                            let filterJson = {
                                                "name": "",
                                                "code": loOffice_code_filter,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.loadLoOfficeDashboard({
                                                filterType: "name",
                                                filterValue: "",
                                                offset: 0,
                                                limit: this.props.LoOffice.limit,
                                                apiObject: this._loOfficeApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <SearchBox
                                        value={loOffice_code_filter}
                                        underlined={true}
                                        placeholder="Search Code"
                                        onSearch={(newValue) => {
                                            let filterJson = {
                                                "name": loOffice_name_filter,
                                                "acronym_code": newValue,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.loadLoOfficeDashboard({
                                                filterType: "code",
                                                filterValue: newValue,
                                                offset: 0,
                                                limit: this.props.LoOffice.limit,
                                                apiObject: this._loOfficeApi,
                                                config: config,
                                                filtered: true
                                            });
                                        }
                                        }
                                        onClear={() => {
                                            let filterJson = {
                                                "name": loOffice_name_filter,
                                                "acronym_code": "",
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.loadLoOfficeDashboard({
                                                filterType: "code",
                                                filterValue: "",
                                                offset: 0,
                                                limit: this.props.LoOffice.limit,
                                                apiObject: this._loOfficeApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                    />
                                </div>
                            </div>

                            <LoadingOverlay
                                active={LoadingLoOfficeDashboard}
                                spinner={<FadeLoader />}
                                text="Loading Lo Office...">
                                <div className="lazy-load-div" >
                                    <RemoteAll
                                        data={filteredLoOffice}
                                        columns={columns}
                                        page={1}
                                        hideSizePerPage={true}
                                    
                                    />
                                </div>

                            </LoadingOverlay>

                            {
                                !alertJSON.hideAlert &&
                                <AlertRedux
                                    {...this.props}
                                />
                            }
                            {
                                ShowAddEditLoOfficePopUp &&
                                <AddEditLoOfficePopUp
                                    {...this.props}
                                />
                            }
                            {!hideConfirm &&
                                <ConfirmLoOfficeDelete
                                {...this.props}
                                 />
                            }
                            
                        </div>
                        : null
                }
            </React.Fragment>
        )
    }
}
