import React, { Component } from 'react';
import { ActionButton, Spinner, IconButton, TextField, PrimaryButton, Separator, SpinnerSize, Toggle, DatePicker } from 'office-ui-fabric-react';
import { JobAPI } from '../Services/JobAPI';
import Select from 'react-select';
import { IconConstants, seperatorTheme } from '../../../Constants/StylesConstants';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment-timezone';
import * as COMMON_CONSTANTS from './../../../Constants/CommonConstants';
import { TaskUtility } from './../../Tasks/Utility/TaskUtility';
import * as CONSTANTS from '../Constants/JobConstants';

// const DateFieldInput = ({ value, onClick }) => (
//     <button className="date-picker-input" onClick={onClick}>
//         {value}
//     </button>
// );

export default class TaskDetails extends Component {

    _apiObject = null;
    _taskUtility = null;
    constructor(props) {
        super(props);
        let config = {
            headers: {}
        };
        this._apiObject = new JobAPI(window.API_URL, config);
        this._taskUtility = new TaskUtility();
    }

    render() {

        let {
            CurrentJobDetails,
            RequesterOptions,
            AllTaskTypeOptions,
            DivisionOptions,
            AssetTypeOptions,
            SelectedAssetType,
            NewAsset,
            LoadingAssetSection,
            SelectedJob,
            showLLGButton
        } = this.props.Job;

        let { LLGTaskDetails } = CurrentJobDetails;

        // let allInspectors = CurrentJobDetails.Inspectors.value ? CurrentJobDetails.Inspectors.value.concat(CurrentJobDetails.MainInspector.value)
        //     : [].concat(CurrentJobDetails.MainInspector.value);
        let allInspectors = RequesterOptions;

        let isOneLLGTaskExists = false;

        if (LLGTaskDetails.id > -1) {
            isOneLLGTaskExists = true;
        }

        const currentUserRole = window.loggedInUserDetails.role;

        const isAddTaskButtonDisabled =
            (CurrentJobDetails && CurrentJobDetails.status === 16)
            || (CurrentJobDetails && CurrentJobDetails.status === 64)
            || (CONSTANTS.USER_ADD_JOB_DISABLE.indexOf(currentUserRole) > - 1
                && !CurrentJobDetails.isDayJob);

        return (
            <div className="ms-Grid" dir="ltr" >
                <Separator theme={seperatorTheme}>Step 1: ASSET</Separator>
                {LoadingAssetSection ?
                    <Spinner
                        size={SpinnerSize.large}
                        label="Saving Asset...Please wait"
                    /> :
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <Select
                                placeholder="Asset Type"
                                value={SelectedAssetType}
                                onChange={(newVal) => {
                                    this.props.changeSelectedAssetType({
                                        SelectedAssetType: newVal
                                    })
                                }}
                                isMulti={false}
                                options={
                                    !AssetTypeOptions ? []
                                        :
                                        CommonUtility.sortByColumn(
                                            AssetTypeOptions.filter((assetType) => assetType.label.indexOf("LLG") === -1), "label", "asc")
                                }
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                value={NewAsset.value}
                                onChange={(ev, newVal) => {
                                    this.props.changeNewAssetName({
                                        NewAssetName: newVal,
                                    })
                                }}
                                placeholder="Asset Name"
                                errorMessage={NewAsset.errorMessage}
                                required
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <PrimaryButton
                                text="Add Asset"
                                disabled={!SelectedAssetType || NewAsset.error ||
                                    (CurrentJobDetails && CurrentJobDetails.status === 16) ||
                                    (CurrentJobDetails && CurrentJobDetails.status === 64)}
                                menuIconProps={IconConstants.addIcon}
                                onClick={() => {
                                    this.props.saveNewAssetAsync({
                                        apiObject: this._apiObject,
                                        locationPayload: {
                                            assetTypeId: SelectedAssetType?.value,
                                            companyId: CurrentJobDetails?.ClientName.value.value,
                                            parentId: CurrentJobDetails?.SiteDetails.value.value,
                                            objectType: "ASSET",
                                            name: NewAsset.value,
                                            type: 1,
                                            status: 1,
                                            locationLevel: 1
                                        }
                                    })
                                }}
                            />
                        </div>

                    </div>
                }
                <Separator theme={seperatorTheme}>Step 2: TASKS</Separator>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                        <table className="table table-sm">
                            <tbody>

                                <tr>
                                    <th>Action</th>
                                    <th>SL. NO</th>
                                    <th>DIVISION <span className="mandatory-span">*</span></th>
                                    <th>TASK TYPE <span className="mandatory-span">*</span></th>
                                    <th style={{ display: "none" }}>ASSET TYPE</th>
                                    <th>ASSET</th>
                                    <th>INSPECTOR</th>
                                    <th style={{ display: "none" }}>PLAN DATE</th>
                                    <th style={{ display: "none" }}>PLAN TIME</th>
                                </tr>
                            </tbody>
                            <tbody>
                                {CurrentJobDetails.hasOwnProperty("TaskDetails") &&
                                    CurrentJobDetails.TaskDetails.map((task, index) => {
                                        return (
                                            <tr key={index} className={(task.status === 1024) ? "deleted-task-row" : ""}>
                                                {!task.isNew ?
                                                    <td>
                                                        {task.status === 1 &&
                                                            <IconButton
                                                                menuIconProps={IconConstants.deleteicon}
                                                                onClick={() => {
                                                                    this.props.deleteOrReopenTask({
                                                                        status: 1024,
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                        {task.status === 1024 &&
                                                            <IconButton
                                                                menuIconProps={IconConstants.confirmIcon}
                                                                onClick={() => {
                                                                    this.props.deleteOrReopenTask({
                                                                        status: 1,
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                    </td>
                                                    :
                                                    <td></td>
                                                }
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <Select
                                                        isDisabled={!task.isNew}
                                                        value={task.Division.value}
                                                        onChange={(newval) => {
                                                            this.props.changeJobTaskDivision({
                                                                Division: newval,
                                                                index
                                                            });
                                                        }}
                                                        options={DivisionOptions}
                                                    />
                                                </td>
                                                {/* TASK TYPE */}
                                                <td>
                                                    <Select
                                                        isDisabled={!task.isNew}
                                                        value={task.TaskType.value}
                                                        onChange={(newval) => {
                                                            this.props.loadAssetTypeAndAssetOptionsAsync({
                                                                apiObject: this._apiObject,
                                                                TaskType: newval,
                                                                index
                                                            });
                                                        }}
                                                        options={task.Division.value ?
                                                            AllTaskTypeOptions.filter(
                                                                d => parseInt(d.division) ===
                                                                    task.Division.value.value
                                                                    && d.label.indexOf("LLG") === -1
                                                            )
                                                            : []
                                                        }
                                                    />
                                                </td>
                                                <td style={{ display: "none" }}>
                                                    {task.Asset.loading ?
                                                        <Spinner label="Loading Asset Type..." />
                                                        :
                                                        <span>
                                                            {task.AssetType.value ? task.AssetType.value.name : ``}
                                                        </span>
                                                    }
                                                </td>
                                                <td>
                                                    {task.Asset.loading ?
                                                        <Spinner label="Loading Assets..." />
                                                        :
                                                        <Select
                                                            isClearable={true}
                                                            isDisabled={task.status === 8 || task.status === 16 || task.status === 64 || task.status === 1024}
                                                            value={task.Asset.value}
                                                            onChange={(newval) => {
                                                                this.props.changeAsset({
                                                                    Asset: newval,
                                                                    index
                                                                });
                                                            }}
                                                            options={
                                                                CurrentJobDetails.SiteDetails.value ?
                                                                    CommonUtility.sortByColumn(task.AssetOptions.filter(d => d.parentId === CurrentJobDetails.SiteDetails.value.value), "label", "asc")
                                                                    : []
                                                            }
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    <Select
                                                        value={task.Inspector.value}
                                                        // isDisabled={task.status === 8 || task.status === 16 || task.status === 64 || task.status === 1024}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                this.props.changeInspector({
                                                                    Inspector: value,
                                                                    index
                                                                })
                                                            }
                                                        }}
                                                        options={
                                                            task.Division.value ?
                                                                CommonUtility.sortByColumn(
                                                                    allInspectors
                                                                        .filter(
                                                                            d => d.divisionIds && d.divisionIds.split(",")
                                                                                .indexOf(task.Division.value.value.toString()) > -1), "label", "asc")

                                                                : []
                                                        }
                                                    />
                                                </td>
                                                <td style={{ display: "none" }}>
                                                    <DatePicker
                                                        allowTextInput={false}
                                                        isDisabled={task.status === 8 || task.status === 16 || task.status === 64 || task.status === 1024}
                                                        value={task.dueDate.value}
                                                        firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                                                        strings={COMMON_CONSTANTS.DayPickerStrings}
                                                        placeholder="Select a date..."
                                                        formatDate={(date) => this._taskUtility.onFormatDate(date)}
                                                        onSelectDate={(date) => {

                                                            let dateStr =
                                                                moment(date).format('YYYY-MM-DD');
                                                            let selectedDatetime = moment(dateStr + " " + task.dueTime.value);

                                                            this.props.changeTaskDueDate({
                                                                dueDate: new Date(selectedDatetime.format()),
                                                                index
                                                            });
                                                        }}
                                                    />
                                                </td>

                                                <td style={{ display: "none" }}>
                                                    {!task.showTimeClock ?
                                                        <React.Fragment>
                                                            <TextField
                                                                readOnly
                                                                value={task.dueTime.value}
                                                                onClick={() => {
                                                                    this.props.showHideTaskDetailsTimeClock({
                                                                        showTimeClock: !task.showTimeClock,
                                                                        index
                                                                    })
                                                                }}
                                                                iconProps={IconConstants.timePickerIcon}
                                                                styles={{
                                                                    field: {
                                                                        cursor: "pointer",
                                                                    }
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                        :
                                                        <TimeKeeper
                                                            time={task.dueTime.value}
                                                            hour24Mode={true}
                                                            onDoneClick={() => {
                                                                this.props.showHideTaskDetailsTimeClock({
                                                                    showTimeClock: !task.showTimeClock,
                                                                    index
                                                                })
                                                            }}
                                                            onChange={(newTime) => {
                                                                let dateStr =
                                                                    task.dueDate.value ?
                                                                        moment(task.dueDate.value).format('YYYY-MM-DD') :
                                                                        moment(new Date()).format('YYYY-MM-DD');

                                                                let selectedDatetime = moment(dateStr + " " + newTime.formatted12);

                                                                this.props.changeTaskDueDate({
                                                                    dueDate: new Date(selectedDatetime.format()),
                                                                    index
                                                                });
                                                            }}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        <ActionButton
                            text="Add New"
                            disabled={isAddTaskButtonDisabled}
                            iconProps={IconConstants.addIcon}
                            onClick={() => this.props.createNewTaskRow({
                                isOnLLGState: false
                            })}
                        />
                        <ActionButton
                            disabled={
                                (CurrentJobDetails.TaskDetails.length < 2 && LLGTaskDetails.status === -1) ||
                                CurrentJobDetails.TaskDetails.filter(d => d.isNew).length === 0
                            }
                            text="Remove Last New Item"
                            iconProps={IconConstants.removeIcon}
                            onClick={() => this.props.deleteLastTaskRow()}
                        />
                    </div>
                </div>

                {showLLGButton &&
                    <React.Fragment>

                        <Separator theme={seperatorTheme}>Step 3: SPECIAL TASKS</Separator>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                {isOneLLGTaskExists ?
                                    <Toggle
                                        checked={LLGTaskDetails.status !== 1024}
                                        onText="Deselect to Remove LLG Task"
                                        offText="Select to Activate LLG Task"
                                        onChange={(event, checked) => {
                                            this.props.changeLLGTaskStatus({
                                                LLGTaskDetails: {
                                                    id: LLGTaskDetails.id,
                                                    isNew: false,
                                                    status: checked ? 1 : 1024,
                                                    previousStatus: LLGTaskDetails.previousStatus
                                                }
                                            })
                                        }}
                                    />
                                    :
                                    <Toggle
                                        checked={LLGTaskDetails.status !== -1}
                                        onText="Deselect to Remove LLG Task"
                                        offText="Select to Add LLG Task"
                                        onChange={(event, checked) => {
                                            this.props.changeLLGTaskStatus({
                                                LLGTaskDetails: {
                                                    id: -1,
                                                    isNew: checked,
                                                    status: checked ? 1 : -1,
                                                    previousStatus: LLGTaskDetails.previousStatus
                                                }
                                            })
                                        }}
                                    />
                                }
                            </div>
                        </div>

                    </React.Fragment>
                }
                <Separator theme={seperatorTheme} >Step 4: DAY JOB</Separator>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Toggle
                            disabled={SelectedJob}
                            onText="Deselect to Prevent adding field task"
                            offText="Select to Allow adding field tasks"
                            checked={CurrentJobDetails.isDayJob}
                            onChange={() => this.props.changeIsDayJob({
                                isDayJob: !CurrentJobDetails.isDayJob
                            })
                            }
                        />
                    </div>
                </div>


            </div >
        )
    }
}
