import React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { TaskTypeAPI } from '../Services/TaskTypeAPI';
import { IconConstants } from '../../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class ConfirmTaskTypeRedux extends React.PureComponent {

    _taskTypeApi = null;

    constructor(props) {
        super(props);
        this._window = window;
        this._taskTypeApi = new TaskTypeAPI(this._window.API_URL, this._window.loggedInUserDetails.jsessionid);

    }

    render() {
        let { hideConfirm, CurrentTaskType, dialogStatus } = this.props.TaskType;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `Are you sure want to ${dialogStatus} the Tasktype?`
        };
        return (
            <Dialog
                hidden={hideConfirm}
                onDismiss={() => this.props.openCloseTaskTypeConfirmModal({
                    hideConfirm: true,
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            dialogStatus === "Copy" ?
                                this.props.saveTaskTypeDetailsAsync({
                                    CurrentTaskType: CurrentTaskType,
                                    apiObject: this._taskTypeApi,
                                    dialogStatus
                                })
                                :
                                this.props.deleteTaskTypeAsync({
                                    CurrentTaskType: CurrentTaskType,
                                    apiObject: this._taskTypeApi,
                                })

                        }}
                        text={dialogStatus}
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseTaskTypeConfirmModal({
                            hideConfirm: true,
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
