import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Label } from 'office-ui-fabric-react';
import { DivisionAction } from '../Actions/DivisionAction';
import DivisionDashboard from './DivisionDashboard';

const mapStateToProps = (state) => {
    return {
        Division: state.DivisionReducer
    }
}

class DivisionComponent extends PureComponent {

    render() {
        return (
            <React.Fragment>
                <Label className="breadcrumb-label">Settings &gt; Divisions</Label>
                <DivisionDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let divisionAction = new DivisionAction();

    return bindActionCreators({ ...divisionAction }, dispatch);
}

const Division = connect(mapStateToProps, mapDispatchToProps)(DivisionComponent);
export default Division;
