import axios from "axios";
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import { GET_USER_COUNT_URL, USERS_MAIN_URL, USER_STATUS, GET_CLIENT_URL, GET_SETTING_DATA_URL, GET_LOCATION_OBJECT_URL, UPLOAD_DELETE_USER_SIGNATURE_URL } from "../Constants/UserConstants";

export class UserAPI {

    _URL = "";
    constructor(URL) {
        this._URL = URL;
    }

    _getUsersCount(config) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_USER_COUNT_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), config)
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getUsersCount method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    _getChunkUsers(offset, limit, config) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(USERS_MAIN_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?offset=${offset}&limit=${limit}`), config
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getAllUsers method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }



    getUserDetails(offset, limit, config) {
        let promises = [this._getChunkUsers(offset, limit, config), this._getUsersCount(config)];
        return Promise.all(promises);

    }

    deleteUser(users_id, user_status) {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(`${USERS_MAIN_URL}/${users_id}/${USER_STATUS[user_status]}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``))
                .then((response) => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error deleteUser method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else if (error.response && (error.response.status === 424 || error.response.status === 409 || error.response.status === 402)) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    saveUser(payload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${USERS_MAIN_URL}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), payload)
                .then((response) => {
                    resolve(response.status);
                }).catch(error => {
                    // console.error("Error saveUser method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else if (error.response && [409, 402].indexOf(error.response.status)) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    _getLocationObjects(objectType, companyId) {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_LOCATION_OBJECT_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?objectType=${objectType}`)
                    .concat(companyId ? `&companyId=${companyId}` : ``)
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error _getClientsData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    _getOfficeData(locationLevel) {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_CLIENT_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?locationLevel=${locationLevel}`)
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error _getClientsData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    _getAllRoles() {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(GET_SETTING_DATA_URL).concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?objectType=ROLE`)).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getAllRoles", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    _getAllDivisions() {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(GET_SETTING_DATA_URL).concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?objectType=DIVISION`)).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getAllDivisions", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    loadDropDownData(companyType) {
        let promises = [
            // this._getLocationObjects(ClientLocationLevel, companyId),
            this._getAllRoles(),
            this._getAllDivisions()
        ];
        if (companyType) {
            promises.push(this._getLocationObjects(companyType));
        }
        return Promise.all(promises);

    }

    _uploadUserSigntureFile = (id, uploadImageData) => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${UPLOAD_DELETE_USER_SIGNATURE_URL}/${id}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``),
                uploadImageData)
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error _uploadUserSigntureFile method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    _deleteUserSigntureFile = (id) => {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(`${UPLOAD_DELETE_USER_SIGNATURE_URL}/${id}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``))
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error _deleteUserSigntureFile method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }
}