import { Icon, Label, Spinner, SpinnerSize } from 'office-ui-fabric-react'
import React, { Component } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { IconConstants } from '../../Constants/StylesConstants';
import HomePageAPI from './HomePageAPI';


export default class Home extends Component {

    _homeAPI = null;

    constructor(props) {
        super(props);
        this._homeAPI = new HomePageAPI(window.API_URL, window.loggedInUserDetails);
        this.state = {
            isLoading: true,
            tabsList: [],
            userDivisions: "",
            offices: []
        }

    }

    fetchData = () => {

        let userOfficeIds = window.loggedInUserDetails.secondaryOfficeIds ?
            window.loggedInUserDetails.secondaryOfficeIds.split(",").map(officeId => +officeId).concat(window.loggedInUserDetails.primaryOfficeId) :
            [window.loggedInUserDetails.primaryOfficeId];
        let divisionIds = window.loggedInUserDetails.divisionIds ?
            window.loggedInUserDetails.divisionIds.split(",").map(divisionId => +divisionId) : [];

        this._homeAPI.getConfigurations(divisionIds, userOfficeIds).then((response) => {

            let tempTabLists = [];
            let divisions = [];
            let offices = [];

            response.forEach((eachResponse, index) => {
                if (index === 0) {
                    eachResponse.data.forEach((data) => {
                        let settingDetails = JSON.parse(data.settingWithMetadata);
                        if (settingDetails.name === "webDashboardConfiguration") {
                            tempTabLists = JSON.parse(settingDetails.settingmetadata[0].meta_value);
                        }
                    })
                }
                else if (eachResponse.data.objectType === "DIVISION") {
                    divisions.push(eachResponse.data.name.toUpperCase());
                }
                else {
                    offices.push(eachResponse.data.name.toUpperCase());
                }
            })
            this.setState({
                isLoading: false,
                tabsList: tempTabLists,
                userDivisions: divisions.join(","),
                offices
            })
        }).catch((error) => {
            console.error("Error: ", error);
            this.setState({
                isLoading: false
            })
        })

    }

    componentDidMount() {

        this.fetchData()
    }

    render() {

        let userRole = window.loggedInUserDetails.role;

        let {
            isLoading,
            tabsList,
            userDivisions,
            offices
        } = this.state;

        let filteredTabList = [];

        if (tabsList) {
            tabsList.forEach((tab) => {
                let showFlag = false;
                tab.viewRules.forEach((rule) => {
                    let role = rule.roles;
                    let division = rule.division;
                    let office = rule.office;

                    if (userDivisions.split(",").indexOf(division) > -1) {
                        if ((role.split(",").indexOf(userRole) > -1 || role.toUpperCase() === "ALL") && role.toUpperCase() !== "NONE") {
                            if (office && offices.indexOf(office.toUpperCase()) > -1) {
                                showFlag = true;
                            }
                        }
                    }

                });

                if (showFlag && tab.isEnabled) {
                    filteredTabList.push(tab);
                };
            })
        }

        return (

            <div className="ms-Grid" dir="ltr">
                <React.Fragment>
                    <div className="ms-Grid-row">
                        <section
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Label className="breadcrumb-label">
                                Dashboard
                                <Icon
                                    iconName={IconConstants.refreshIcon.iconName}
                                    title="Reload"
                                    onClick={() => {
                                        this.setState({
                                            isLoading: true
                                        }, () => {
                                            this.fetchData()
                                        })
                                    }}
                                    styles={{
                                        root: {
                                            marginLeft: "10px",
                                            cursor: "pointer"
                                        }
                                    }}
                                />
                            </Label>
                        </section>
                    </div>
                    <hr />
                    {isLoading ?
                        <div className="login-spinner-div">
                            <Spinner label="Loading Dashboard..." size={SpinnerSize.large} />
                        </div>
                        :
                        filteredTabList.length === 0 ?
                            <h4>No Tabs Found</h4>
                            :
                            <Tabs>
                                <TabList>
                                    {filteredTabList.map((tab, index) => {
                                        return (
                                            <Tab
                                                onDoubleClick={() => window.open(tab.url, '_blank')}
                                                key={index}>{tab.name}
                                            </Tab>
                                        )

                                    }
                                    )}
                                </TabList>
                                {filteredTabList.map((tab, index) => {
                                    return (
                                        <TabPanel key={index}>
                                            <iframe
                                                id={index}
                                                title={index}
                                                src={tab.url}
                                                className="iframe-class-dashboard"
                                                frameBorder={0}
                                            />
                                        </TabPanel>
                                    )

                                }
                                )}

                            </Tabs>
                    }

                </React.Fragment>
            </div>
        )
    }
}
