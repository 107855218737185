import * as React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb } from 'office-ui-fabric-react';
import { SEQUENCE_NUMBERING_BREADCRUMBS } from '../Constants/SequenceNumberingConstants';
import { ISequenceStateProps } from '../Interfaces/ISequenceStateProps';
import { bindActionCreators, Dispatch } from 'redux';
import { SequenceActions } from '../Actions/SequenceActions';
import SequenceDashboard from './SequenceDashboard';

const mapStateToProps = (state: any): ISequenceStateProps => {
    return {
        SequenceState: state.SequenceReducer
    }
}

class SequenceNumberingComponent extends React.PureComponent<any, {}> {

    public render(): JSX.Element {
        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={SEQUENCE_NUMBERING_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                </div>
                <SequenceDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => {

    let sequenceActions: SequenceActions = new SequenceActions();

    return bindActionCreators({ ...sequenceActions }, dispatch);
}

const SequenceNumbering = connect(mapStateToProps, mapDispatchToProps)(SequenceNumberingComponent);
export default SequenceNumbering;
