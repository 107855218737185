import { JobInitialState } from './../Constants/JobInitialState';
import * as CONSTANTS from '../Constants/JobConstants';
import { JobUtility } from '../Utility/JobUtility';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import moment from 'moment-timezone';

export function JobReducer(state = JobInitialState, action) {

    let tempState = { ...state };
    let payload = action.payload;
    let jobUtility = new JobUtility();
    let CurrentJobDetails = { ...tempState.CurrentJobDetails };
    let tempNewSite = { ...tempState.NewSite };
    let tempNewUser = { ...tempState.NewUser };
    let duration = 0;
    let startDate = null;
    let resultDate = null;

    switch (action.type) {

        case CONSTANTS.LOAD_PANEL:
            tempState.LoadingPanel = true;
            tempState.hideConfirm = true;
            break;

        case CONSTANTS.SHOW_HIDE_JOB_DASHBOARD_COLUMNS:
            let tempColumns = [...tempState.jobDashboardColumns];
            let indexOfSelectedColumn = tempColumns.indexOf(payload.selectedColumn.key);
            if (indexOfSelectedColumn > -1) {
                tempColumns.splice(indexOfSelectedColumn, 1);
            }
            else {
                tempColumns.push(payload.selectedColumn.key);
            }
            tempState.jobDashboardColumns = tempColumns;
            break;

        case CONSTANTS.LOAD_SITES:
            tempState.LoadingSites = true;
            CurrentJobDetails.ClientName = {
                value: payload.Client,
                error: false,
                errorMessage: ""
            };
            CurrentJobDetails.ClientAddress = {
                value: payload.Client.details,
                error: false,
                errorMessage: ""
            };
            CurrentJobDetails.SiteDetails = {
                value: null,
                error: true,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_SITE:
            CurrentJobDetails.SiteDetails = {
                value: payload.Site,
                error: false,
                errorMessage: ""
            };
            CurrentJobDetails.SiteAddress = {
                value: payload.Site.details,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_START_DATE_TIME:
            CurrentJobDetails.StartDate = {
                value: payload.StartDate,
                error: false,
                errorMessage: ""
            };
            duration = CurrentJobDetails.StartDuration.value;
            startDate = new Date(payload.StartDate);
            resultDate = startDate.getTime() + (parseInt(duration) * 86400000);

            CurrentJobDetails.EndDate = {
                value: new Date(resultDate),
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_END_DATE_TIME:
            CurrentJobDetails.EndDate = {
                value: payload.EndDate,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_START_DURATION:
            CurrentJobDetails.StartDuration = {
                value: payload.StartDuration,
                error: false,
                errorMessage: ""
            };
            duration = payload.StartDuration;
            startDate = new Date(CurrentJobDetails.StartDate.value);
            resultDate = startDate.getTime() + (parseInt(duration) * 86400000);

            CurrentJobDetails.EndDate = {
                value: new Date(resultDate),
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_REQUESTER_NAME:
            CurrentJobDetails.RequesterName = {
                value: payload.Requester,
                error: false,
                errorMessage: ""
            };
            CurrentJobDetails.RequesterDetails = {
                value: payload.Requester?.details,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.OPEN_CLOSE_PANEL:
            tempState.ShowPanel = payload.ShowPanel;
            tempState.PanelHeaderText = payload.PanelHeaderText;
            tempState.ActiveStep = 0;
            tempState.LoadingPanel = false;
            tempState.SelectedJob = payload.SelectedJob;
            tempState.IsJobEditable = true;
            tempState.ShowAddNewSiteSection = false;
            tempState.NewSite = {
                name: "",
                street: "",
                city: "",
                state: "",
                country: "",
                pin: 0,
                phone: "",
                locationLevel: 0
            }
            tempState.ShowAddNewUserSection = false;
            tempState.NewUser = {
                name: "",
                street: "",
                email: ""
            }
            break;

        case CONSTANTS.SET_CLIENT_AND_USERS:
            tempState.LoadingPanel = false;
            tempState.showLLGButton = payload.showLLGButton;
            tempState.ClientOptions = jobUtility.setClientOptions(payload.Clients);
            tempState.OfficeOptions = jobUtility.setClientOptions(payload.Offices);
            tempState.RequesterOptions = jobUtility.setUserOptions(payload.Users);
            tempState.AllInspectorOptions = jobUtility.setInspetorOptions(payload.Users);
            tempState.AllTaskTypeOptions = jobUtility.setSettingsOptions(payload.TaskTypes);
            tempState.DivisionOptions = jobUtility.setSettingsOptions(payload.Divisions);
            tempState.AssetTypeOptions = jobUtility.setSettingsOptions(payload.AssetTypes);
            tempState.Roles = payload.Roles;
            tempState.IsJobEditable = true;
            // tempState.AllAssetTypeOptions = jobUtility.setAssetTypeOptions(payload.AssetTypes);
            if (!payload.SelectedJob) {
                tempState.CurrentJobDetails = jobUtility.CreateJobObject(
                    null, null, tempState.OfficeOptions, null, null, null, null, null
                );
            }
            else {
                tempState.SiteOptions = jobUtility.setSiteOptions(payload.Sites);
                // tempState.InspectorOptions = jobUtility.getInspectorOptions(tempState.RequesterOptions, payload.SelectedJob.officeId, payload.Roles);
                let jobObject = jobUtility.CreateJobObject(
                    payload.SelectedJob,
                    tempState.ClientOptions,
                    tempState.OfficeOptions,
                    tempState.SiteOptions,
                    tempState.RequesterOptions,
                    tempState.AllInspectorOptions,
                    payload.Tasks,
                    tempState.AllTaskTypeOptions,
                    tempState.DivisionOptions
                );
                if (jobObject) {
                    tempState.CurrentJobDetails = jobObject;
                }
                else {
                    tempState.IsJobEditable = false;
                }
            }
            break;

        case CONSTANTS.SET_SITE_OPTIONS:
            tempState.LoadingSites = false;
            tempState.SiteOptions = jobUtility.setSiteOptions(payload.Sites);
            break;

        case CONSTANTS.CHANGE_STEP:
            if (payload.flag === "I") {
                tempState.ActiveStep += 1;
            }
            else if (payload.flag === "D") {
                tempState.ActiveStep -= 1;
            }
            break;

        case CONSTANTS.CHANGE_CLIENT_USERS:
            CurrentJobDetails.ClientUsers = {
                value: payload.ClientUsers,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;
        case CONSTANTS.CHANGE_LO_OFFICE:
            CurrentJobDetails.LoOffice = {
                value: payload.LoOffice,
                error: false,
                errorMessage: ""
            };
            CurrentJobDetails.AssignOffice = {
                value: payload.LoOffice,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        // Tracking Details Changes Start

        case CONSTANTS.CHANGE_ASSING_OFFICE:
            CurrentJobDetails.AssignOffice = {
                value: payload.AssignOffice,
                error: false,
                errorMessage: ""
            };

            CurrentJobDetails.MainInspector = {
                value: null,
                error: false,
                errorMessage: ""
            };

            CurrentJobDetails.Inspectors = {
                value: null,
                error: false,
                errorMessage: ""
            };

            let tempInspectorOptions = [];
            // let UserOptions = tempState.RequesterOptions;

            // tempInspectorOptions = jobUtility.getInspectorOptions(UserOptions, payload.AssignOffice.value, tempState.Roles);
            tempState.CurrentJobDetails = CurrentJobDetails;

            tempState.InspectorOptions = tempInspectorOptions;
            break;

        case CONSTANTS.CHANGE_PO_NUMBER:
            CurrentJobDetails.PoNumber = {
                value: payload.PoNumber,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_SALES_PERSON:
            CurrentJobDetails.SalesPerson = {
                value: payload.SalesPerson,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_QUOTATION_NUMBER:
            CurrentJobDetails.QuotationNumber = {
                value: payload.QuotationNumber,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_NUMBER:
            let isValidJobNumber = !tempState.Jobs.find(job => job.jobNumber === payload.JobNumber);

            CurrentJobDetails.JobNumber = {
                value: payload.JobNumber,
                error: !isValidJobNumber || !payload.JobNumber,
                errorMessage:
                    isValidJobNumber ?
                        payload.JobNumber ?
                            "" :
                            "Job Number is mandatory" :
                        "Duplicate Job Number"
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHNAGE_PRICE:
            CurrentJobDetails.Price = {
                value: payload.Price,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_CURRENCY:
            CurrentJobDetails.Currency = {
                value: payload.Currency,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        // Tracking Details Changes End

        // Site Details Changes Start
        case CONSTANTS.CHANGE_COMMENTS:
            CurrentJobDetails.Comments = {
                value: payload.Comments,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_MAIN_INSPECTOR:
            CurrentJobDetails.MainInspector = {
                value: payload.MainInspector,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_INSPECTORS:
            CurrentJobDetails.Inspectors = {
                value: payload.Inspectors,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_SUB_VENDOR_DETAILS:
            CurrentJobDetails.SubVendorDetails = {
                value: payload.SubVendorDetails,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_VENDOR_DETAILS:
            CurrentJobDetails.VendorDetails = {
                value: payload.VendorDetails,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.SHOW_HIDE_CLIENT_DETAILS_TIME_CLOCK:
            tempState.showTimeClock = payload.showTimeClock;
            break;

        // Site Details Changes End

        // Task Details Changes Start

        case CONSTANTS.CREATE_NEW_TASK_ROW:
            var TaskDetails = [...CurrentJobDetails.TaskDetails];

            TaskDetails.push({
                isNew: true,
                status: 1,
                Division: {
                    value: null,
                    error: false,
                    errorMessage: ""
                },
                dueDate: {
                    value: CommonUtility.formatDateTimeToZeroHour(CurrentJobDetails.StartDate.value),
                    error: false,
                    errorMessage: ""
                },
                dueTime: {
                    value: moment(new Date(CurrentJobDetails.StartDate.value)).format('HH:mm'),
                    error: false,
                    errorMessage: ""
                },
                TaskType: {
                    value: null,
                    error: false,
                    errorMessage: ""
                },
                AssetType: {
                    value: null,
                    error: false,
                    errorMessage: ""
                },
                Asset: {
                    value: null,
                    error: false,
                    errorMessage: "",
                    loading: false
                },
                Inspector: {
                    value: null,
                    error: false,
                    errorMessage: ""
                },
                AssetOptions: [],
                isDirty: true,
                showTimeClock: false,
                isOnLLGState: payload.isOnLLGState
            })
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.DELETE_LAST_TASK_ROW:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            TaskDetails.pop();
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_TASK_TYPE:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            var changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.TaskType = {
                value: payload.TaskType,
                error: false,
                errorMessage: ""
            };
            changedTaskRow.isDirty = true;
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.LOAD_ASSET_TYPE_AND_ASSET_OPTIONS:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.TaskType = {
                value: payload.TaskType,
                error: false,
                errorMessage: "",
            };
            changedTaskRow.Asset = {
                value: null,
                error: false,
                errorMessage: "",
                loading: payload.OngoingLoad
            };
            changedTaskRow.AssetType = {
                value: payload.AssetType ? payload.AssetType : null,
                error: false,
                errorMessage: ""
            };

            TaskDetails[payload.index] = changedTaskRow;
            changedTaskRow.AssetOptions = jobUtility.setAssetOptions(payload.Assets ? payload.Assets : []);
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_TASK_DIVISION:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.TaskType = {
                value: null,
                error: false,
                errorMessage: "",
            };
            changedTaskRow.Asset = {
                value: null,
                error: false,
                errorMessage: "",
                loading: payload.OngoingLoad
            };
            changedTaskRow.AssetType = {
                value: null,
                error: false,
                errorMessage: ""
            };
            changedTaskRow.Division = {
                value: payload.Division,
                error: false,
                errorMessage: ""
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_ASSET:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.Asset = {
                value: payload.Asset,
                error: false,
                errorMessage: "",
                loading: false
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_INSPECTOR:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.Inspector = {
                value: payload.Inspector,
                error: false,
                errorMessage: ""
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_TASK_DUE_DATE:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.dueDate = {
                value: payload.dueDate,
                error: false,
                errorMessage: ""
            };
            changedTaskRow.dueDate = {
                value: moment(new Date(payload.dueDate)).format('HH:mm'),
                error: false,
                errorMessage: ""
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_TASK_DUE_TIME:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.dueTime = {
                value: moment(new Date(payload.dueDate)).format('HH:mm'),
                error: false,
                errorMessage: ""
            };
            changedTaskRow.dueDate = {
                value: payload.dueDate,
                error: false,
                errorMessage: ""
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.SHOW_HIDE_TASK_DETAILS_TIME_CLOCK:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.showTimeClock = payload.showTimeClock;
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.DELETE_OR_REOPEN_TASK:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.status = payload.status;
            changedTaskRow.isDirty = true;
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.SET_ASSET_OPTIONS:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.Asset = {
                value: null,
                error: false,
                errorMessage: "",
                loading: false
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_NEW_ASSET_NAME:
            let newAssetName = payload.NewAssetName;
            let tempNewAsset = { ...tempState.NewAsset };
            let asetNameRegex = localStorage.getItem("assetNameRegex").split("");
            tempNewAsset = {
                value: newAssetName,
                error: !newAssetName.trim() || asetNameRegex.map((item) => newAssetName.trim().includes(item)).filter(item => item === true).length > 0,
                errorMessage: !newAssetName.trim() ?
                    "Asset Name is Mandatory" :
                    asetNameRegex.map((item) => newAssetName.trim().includes(item)).filter(item => item === true).length > 0 ? "Invalid character(s) in Asset Name" : ""
            }
            tempState.NewAsset = tempNewAsset;
            break;

        case CONSTANTS.CHANGE_SELECTED_ASSET_TYPE:
            tempState.SelectedAssetType = payload.SelectedAssetType;
            break;

        case CONSTANTS.SAVE_NEW_ASSET:
            tempState.LoadingAssetSection = payload.isOngoingSave;
            if (!payload.isOngoingSave) {
                tempState.NewAsset = {
                    value: "",
                    error: true,
                    errorMessage: ""
                };
                tempState.SelectedAssetType = null;
            }
            break;

        case CONSTANTS.OPEN_CLOSE_JOB_CONFIRM_MODAL:
            tempState.hideConfirm = payload.hideConfirm;
            tempState.CurrentJobDetails = payload.CurrentJobDetails;
            tempState.closeOrCancel = payload.closeOrCancel
            break;

        case CONSTANTS.CHANGE_IS_DAY_JOB:
            CurrentJobDetails.isDayJob = payload.isDayJob;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_LLG_TASK_STATUS:
            let tempLLGTaskDetails = { ...CurrentJobDetails.LLGTaskDetails };
            tempLLGTaskDetails = payload.LLGTaskDetails;
            CurrentJobDetails.LLGTaskDetails = tempLLGTaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        // Task Details Changes End

        case CONSTANTS.RESET_VALUE:
            switch (payload.step) {
                case 0:
                    CurrentJobDetails.ClientName = jobUtility.resetClient().ClientName;
                    CurrentJobDetails.ClientAddress = jobUtility.resetClient().ClientAddress;
                    CurrentJobDetails.SiteDetails = jobUtility.resetClient().SiteDetails;
                    CurrentJobDetails.SiteAddress = jobUtility.resetClient().SiteAddress;
                    CurrentJobDetails.StartDate = jobUtility.resetClient().StartDate;
                    CurrentJobDetails.StartDuration = jobUtility.resetClient().StartDuration;
                    CurrentJobDetails.RequesterName = jobUtility.resetClient().RequesterName;
                    CurrentJobDetails.RequesterDetails = jobUtility.resetClient().RequesterDetails;
                    CurrentJobDetails.ClientUsers = jobUtility.resetClient().ClientUsers;
                    break;

                case 1:
                    CurrentJobDetails.AssignOffice = jobUtility.resetTracking().AssignOffice;
                    CurrentJobDetails.JobNumber = jobUtility.resetTracking().JobNumber;
                    CurrentJobDetails.SalesPerson = jobUtility.resetTracking().SalesPerson;
                    CurrentJobDetails.Price = jobUtility.resetTracking().Price;
                    CurrentJobDetails.Currency = jobUtility.resetTracking().Currency;
                    CurrentJobDetails.PoNumber = jobUtility.resetTracking().PoNumber;
                    CurrentJobDetails.QuotationNumber = jobUtility.resetTracking().QuotationNumber;
                    break;

                case 2:
                    CurrentJobDetails.Comments = jobUtility.resetSiteDetails().Comments;
                    CurrentJobDetails.MainInspector = jobUtility.resetSiteDetails().MainInspector;
                    CurrentJobDetails.Inspectors = jobUtility.resetSiteDetails().Inspectors;
                    break;

                case 3:
                    CurrentJobDetails.TaskDetails = jobUtility.resetTasks(CurrentJobDetails).TaskDetails;
                    break;

                default:
                    break;
            }

            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.OPEN_CLOSE_JOB_ALERT_MODAL:
            tempState.alertJSON = {
                hideAlert: payload.hideAlert,
                alertHeader: payload.alertHeader,
                alertSubText: payload.alertSubText
            }
            tempState.LoadingPanel = false;
            if (payload.isAsset || payload.isError) {
                tempState.ShowPanel = true;
            }
            else {
                tempState.ShowPanel = !payload.hideAlert;
            }
            break;

        // Job Dashboard Operations Start
        case CONSTANTS.CLEAN_JOB_DASHBOARD:
            if (!(payload.isScrolled)) {
                tempState.Jobs = [];
                tempState.offset = 0;
            }
            tempState.job_status_filter_value = "";
            tempState.job_number_filter_value = "";
            tempState.job_client_filter_value = "";
            tempState.job_site_filter_value = "";
            tempState.job_qn_filter_value = "";
            tempState.ShowActiveJobs = true;
            tempState.LoadingDashboard = true;
            break;

        case CONSTANTS.LOAD_JOB_DASHBOARD:
            tempState.LoadingDashboard = false;
            tempState.jobDashboardColumns = JobInitialState.jobDashboardColumns;
            if (payload.filterType === "status") {
                tempState.ShowActiveJobs =
                    (!payload.value || payload.value === 1 || payload.value === 2 || payload.value === 4);
            }
            tempState.Jobs = !payload.filtered ? tempState.Jobs.concat(payload.Jobs) : payload.Jobs;
            tempState.TotalJobsCount = payload.TotalJobsCount;
            tempState.HasMoreJobs = tempState.TotalJobsCount > tempState.Jobs.length;
            tempState.filters = payload.filters;
            tempState.offset = payload.offset + payload.limit;
            break;

        case CONSTANTS.FIX_JOB_DASHBOARD:
            tempState.HasMoreJobs = payload.HasMoreJobs;
            break;

        case CONSTANTS.SORT_JOB_DASHBOARD:
            tempState.Jobs = payload.sortedJobs;
            tempState.sortedColumn = payload.sortedColumn;
            tempState.sortOrder = payload.sortOrder;
            break;

        case CONSTANTS.SWITCH_JOB_VIEW:
            tempState.ShowActiveJobs = payload.ShowActiveJobs;
            break;


        // Job Dashboard Operations End

        //DASHBOARD FILTER start
        case CONSTANTS.CHANGE_JOB_STATUS_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_status_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_JOB_SITE_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_site_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_JOB_CLIENT_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_client_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_JOB_NUMBER_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_number_filter_value = payload.value;
            break;

        case CONSTANTS.CHANGE_QUOTATION_NUMBER_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_qn_filter_value = payload.value;
            break;
        //DASHBOARD FILTER end

        // New Site Section Start
        case CONSTANTS.SHOW_HIDE_ADD_NEW_SITE_SECTION_JOB:
            tempState.ShowAddNewSiteSection = payload.ShowAddNewSiteSection;
            tempState.NewSiteSaveStatus = payload.NewSiteSaveStatus ?
                payload.NewSiteSaveStatus :
                {
                    flag: true,
                    message: ""
                };
            break;

        case CONSTANTS.CHANGE_NEW_SITE_NAME_JOB:
            tempNewSite.name = payload.name;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_NEW_SITE_STREET_JOB:
            tempNewSite.street = payload.street;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_NEW_SITE_CITY_JOB:
            tempNewSite.city = payload.city;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_NEW_SITE_STATE_JOB:
            tempNewSite.state = payload.state;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_NEW_SITE_COUNTRY_JOB:
            tempNewSite.country = payload.country;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_NEW_SITE_PIN_JOB:
            tempNewSite.pin = payload.pin;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_NEW_SITE_PHONE_JOB:
            tempNewSite.phone = payload.phone;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.SAVE_NEW_SITE_JOB:
            tempState.LoadingNewSiteSection = payload.isOngoingSave;
            tempState.NewSiteSaveStatus = payload.NewSiteSaveStatus ?
                payload.NewSiteSaveStatus :
                {
                    flag: true,
                    message: ""
                };
            if (!payload.isOngoingSave) {
                tempState.NewSite = {
                    name: "",
                    street: "",
                    city: "",
                    state: "",
                    country: "",
                    pin: 0,
                    phone: "",
                    locationLevel: 0
                };
                let tempSiteOptions = [...tempState.SiteOptions];

                tempSiteOptions.push({
                    value: payload.newSite.id,
                    label: payload.newSite.name,
                    locationLevel: payload.newSite.locationLevel,
                    details: `${payload.newSite.street}, ${payload.newSite.pin}`
                })
                tempState.SiteOptions = tempSiteOptions;
            }
            break;

        // New Site Section End

        // New User Section Start
        case CONSTANTS.SHOW_HIDE_ADD_NEW_USER_SECTION_JOB:
            tempState.ShowAddNewUserSection = payload.ShowAddNewUserSection;
            if (payload.hasOwnProperty("isOngoingSave")) {
                tempState.LoadingNewUserSection = payload.isOngoingSave;
            }
            tempState.NewUserSaveStatus = payload.NewUserSaveStatus ?
                payload.NewUserSaveStatus :
                {
                    flag: true,
                    message: ""
                };
            tempState.NewUser = {
                email: tempState.CurrentJobDetails.SiteDetails.value.label + new Date().getMilliseconds() + '@xlsys.co',
                name: "",
                phone: ""
            }
            break;

        case CONSTANTS.CHANGE_NEW_USER_NAME_JOB:
            tempNewUser.name = payload.name;
            tempState.NewUser = tempNewUser;
            break;

        case CONSTANTS.CHANGE_NEW_USER_EMAIL_JOB:
            tempNewUser.email = payload.email;
            tempState.NewUser = tempNewUser;
            break;

        case CONSTANTS.CHANGE_NEW_USER_PHONE_JOB:
            tempNewUser.phone = payload.phone;
            tempState.NewUser = tempNewUser;
            break;

        case CONSTANTS.SAVE_NEW_USER_JOB:
            tempState.LoadingNewUserSection = payload.isOngoingSave;
            tempState.NewUserSaveStatus = payload.NewUserSaveStatus ?
                payload.NewUserSaveStatus :
                {
                    flag: true,
                    message: ""
                };
            if (!payload.isOngoingSave) {
                tempState.NewUser = {
                    name: "",
                    email: "",
                    phone: ""
                };
                let tempRequesterOptions = [...tempState.RequesterOptions];

                tempRequesterOptions.push({
                    value: payload.newUser.id,
                    label: payload.newUser.name,
                    type: payload.newUser.type,
                    details: `${payload.newUser.email}, ${payload.newUser.phone}`,
                    companyId: payload.newUser.companyId,
                    officeId: payload.newUser.officeId,
                    secondaryOfficeIds: payload.newUser.secondaryOfficeIds,
                    siteIds: payload.newUser.siteIds,
                    divisionIds: payload.newUser.divisionIds,
                    roleId: payload.newUser.roleId
                })
                tempState.RequesterOptions = tempRequesterOptions;
            }
            break;

        // New User Section End

        // Job File Upload Start

        case CONSTANTS.UPLOAD_JOB_FILES:
            tempState.isLoadingJobFiles = payload.isOngoingLoad;
            if (!payload.isOngoingLoad) {
                CurrentJobDetails.otherFiles = {
                    files: payload.files,
                    uploadFilesErrorText: payload.errorText
                }
            }
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.SHOW_HIDE_JOB_UPLOAD_FILES_ERROR_TEXT:
            CurrentJobDetails.otherFiles = {
                files: CurrentJobDetails?.otherFiles?.files,
                uploadFilesErrorText: payload.errorText
            }
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        // Job File Upload End

        default:
            break;
    }

    return tempState;
}