import * as CONSTANTS from "../Constants/ConfigConstants";
import { ALERT_MESSAGE } from "../../../../Constants/MessageConstants";
import axios from 'axios';

export class ConfigAPI {

    private _URL: string;
    private _jsessionid: string;

    constructor(URL, jsessionid) {
        this._URL = URL;
        this._jsessionid = jsessionid;
    }

    getAllConfigs(tenantId: number, objectType: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.CONFIG_URL)
                .concat(`;jsessionid=${this._jsessionid}`)
                .concat(`?`)
                .concat(`tenantId=${tenantId}&`)
                .concat(`objectType=${objectType}&`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllConfigs method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    saveConfigData(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(CONSTANTS.CONFIG_URL)
                .concat(`;jsessionid=${this._jsessionid}`), payload)
                .then((response) => {
                    resolve(true);
                }).catch((error) => {
                    console.error("Error saveFormConfigData", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

}