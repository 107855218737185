import React from 'react';
import { PrimaryButton, DefaultButton, TextField, Spinner, SpinnerSize, ActionButton } from 'office-ui-fabric-react';
import { ConfigAPI } from '../Services/ConfigAPI';
import ConfigList from './ConfigList';
import { IconConstants } from '../../../../Constants/StylesConstants';
import ConfigJSONViewer from './ConfigJSONViewer';
import AlertRedux from '../../../Alert/AlertRedux';

export default class ConfigPanel extends React.PureComponent {

    _configApi = null;

    constructor(props) {
        super(props);
        this._window = window;
        this._configApi = new ConfigAPI(this._window.API_URL, this._window.loggedInUserDetails.jsessionid);

    }

    componentDidMount() {
        this.loadInitiData();
    }

    loadInitiData = () => {

        this.props.loadConfigList({
            tenantId: -1,
            objectType: "ConfigData",
            apiObject: this._configApi
        })
    }

    render() {

        let {
            LoadingConfigPanel,
            CurrentConfig,
            IsValidConfigJSON,
            NameError,
            alertJSON
        } = this.props.Config;

        let isSaveDisabled = CurrentConfig.id === -99 || !IsValidConfigJSON || !CurrentConfig.name || NameError.error;

        return (
            <React.Fragment>
                {LoadingConfigPanel ?
                    <div className="login-spinner-div">
                        <Spinner
                            size={SpinnerSize.large}
                            label="Please wait..."
                        />
                    </div>
                    :
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                <ActionButton
                                    text="Reload"
                                    iconProps={IconConstants.refreshIcon}
                                    onClick={() => this.loadInitiData()}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">
                                <TextField
                                    placeholder="Conguration Name"
                                    disabled={CurrentConfig.id === -99}
                                    value={CurrentConfig.name}
                                    onChange={(ev, newVal) => {
                                        this.props.changeConfigName({
                                            name: newVal
                                        })
                                    }}
                                    errorMessage={NameError.errorMessage}
                                    required
                                    underlined
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <PrimaryButton
                                    text="Add Configuration"
                                    className="horizontal-button"
                                    menuIconProps={IconConstants.saveicon}
                                    disabled={CurrentConfig.id > 0 || CurrentConfig.id === -1}
                                    onClick={() => {
                                        this.props.addConfigData();
                                    }}
                                />
                                <DefaultButton
                                    text="Cancel"
                                    className={
                                        [
                                            "horizontal-button",
                                            "cancel-button"
                                        ].join(" ")
                                    }
                                    menuIconProps={IconConstants.cancelIcon}
                                    onClick={() => {
                                        this.props.clearConfigData();
                                    }}
                                />
                                <DefaultButton
                                    text="Save"
                                    className={
                                        [
                                            "horizontal-button",
                                            (isSaveDisabled) ? "disable-button" : "save-button"
                                        ].join(" ")
                                    }
                                    disabled={isSaveDisabled}
                                    menuIconProps={IconConstants.saveicon}
                                    onClick={() => this.props.saveConfigDetailsAsync({
                                        CurrentConfig,
                                        apiObject: this._configApi
                                    })}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 form-menu config-menu">
                                <ConfigList
                                    {...this.props}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 config-json-viewer">
                                {CurrentConfig.id > -99 &&
                                    <ConfigJSONViewer
                                        {...this.props}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </React.Fragment>
        )
    }
}
