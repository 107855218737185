export const CHANGE_CONFIG_JSON = "CHANGE_CONFIG_JSON";
export const CHANGE_CONFIG_NAME = "CHANGE_CONFIG_NAME";
export const CLEAN_CONFIG_LIST = 'CLEAN_CONFIG_LIST';
export const CLEAR_CONFIG_DATA = 'CLEAR_CONFIG_DATA';
export const ADD_CONFIG_DATA = 'ADD_CONFIG_DATA';
export const LOAD_CONFIG_LIST = 'LOAD_CONFIG_LIST';
export const SAVE_CONFIG_DATA = 'SAVE_CONFIG_DATA';
export const SELECT_CONFIG = 'SELECT_CONFIG';
export const OPEN_CLOSE_CONFIGURATION_ALERT_MODAL = 'OPEN_CLOSE_CONFIGURATION_ALERT_MODAL';

//URL
export const CONFIG_URL = "Setting";
export const GET_FORM_ATTRIBUTE_LIST = "Setting/FormObject";