import * as CONSTANTS from "../Constants/JobLitesConstants";
import * as TASK_CONSTANTS from "./../../Tasks/Constants/TaskConstants";
import { JobLitesInitialState } from "../Constants/JobLitesInitialState";
import { IJobPayload } from "../Interfaces/IJobPayload";
import moment from 'moment-timezone';

export class JobLitesActions {

    // Dashboard start

    public sortJobDashboard = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SORT_JOB_LITES_DASHBOARD, payload };
    }

    public fixJobDashboard = (payload: IJobPayload): any => {
        return { type: CONSTANTS.FIX_JOB_LITES_DASHBOARD, payload };
    }

    public cleanJobDashboard = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CLEAN_JOB_LITES_DASHBOARD, payload };
    }

    public loadJobDashboardAsync = (payload: IJobPayload): any => {
        return { type: CONSTANTS.LOAD_JOB_LITES_DASHBOARD, payload };
    }

    public switchJobView = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SWITCH_JOB_LITES_VIEW, payload }
    }

    public changeJobFilterValue = (payload: IJobPayload): any => {
        switch (payload.filterType) {
            case "status":
                return { type: CONSTANTS.CHANGE_JOB_LITES_JOB_STATUS_FILTER_VALUE, payload };
            case "site":
                return { type: CONSTANTS.CHANGE_JOB_LITES_JOB_SITE_FILTER_VALUE, payload };
            case "client":
                return { type: CONSTANTS.CHANGE_JOB_LITES_JOB_CLIENT_FILTER_VALUE, payload };
            case "jobNumber":
                return { type: CONSTANTS.CHANGE_JOB_LITES_JOB_NUMBER_FILTER_VALUE, payload };
            case "quotationNumber":
                return { type: CONSTANTS.CHANGE_JOB_LITES_QUOTATION_NUMBER_FILTER_VALUE, payload };

            default:
                break;
        }
    }

    public findFilteredJobsAsync = (payload: IJobPayload): any => {
        return (dispatch: any) => {
            dispatch(this.changeJobFilterValue(payload));
            payload.apiObject.getJobLitesApi(payload.offset, payload.limit, payload.config).then((response: any) => {
                payload.Jobs = response[0].data;
                payload.TotalJobsCount = response[1].data;
                dispatch(this.loadJobDashboardAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading filtered jobs",
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: "",
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }


    public loadJobDashboard = (payload: IJobPayload): any => {
        return (dispatch: any) => {

            dispatch(this.cleanJobDashboard(payload));

            payload.apiObject.getJobLitesApi(payload.offset, payload.limit, payload.config).then((response) => {
                payload.Jobs = response[0].data;
                payload.TotalJobsCount = response[1].data;
                dispatch(this.loadJobDashboardAsync(payload));
            }).catch((error: any) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Jobs",
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: "",
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    // Dashboard end

    public loadJobLitesPanel = (payload: IJobPayload): any => {
        return { type: CONSTANTS.LOAD_JOB_LITES_PANEL, payload }
    }

    public openClosePanel = (payload: IJobPayload): any => {
        return { type: CONSTANTS.OPEN_CLOSE_JOB_LITES_PANEL, payload }
    }

    public loadClientsAndUsersAsync = (payload: IJobPayload): any => {

        return (dispatch: any) => {

            if (payload.SelectedJob) {

                payload.apiObject.getSitesAndTasks(payload.SelectedJob.customerId, payload.SelectedJob.id).then((responseSiteTasks) => {
                    payload.apiObject.loadInitData().then((responseInit) => {
                        // payload.Tasks = responseSiteTasks[1].data;
                        let AssetTypeIds = responseSiteTasks[1].data.filter(d => d.assetTypeId).map(d => d.assetTypeId);
                        let AssetTypeIdsNonDuplicate = AssetTypeIds.filter((v, i, a) => a.indexOf(v) === i);
                        let AssetTypeIdsPositionMap = {};
                        AssetTypeIds.forEach((data, index: number) => {
                            AssetTypeIdsPositionMap[index] = AssetTypeIdsNonDuplicate.indexOf(data);
                        });

                        payload.apiObject.getAllAssetOptions(AssetTypeIdsNonDuplicate.filter(d => d)).then((responseAssets) => {
                            let MappedTasks = responseSiteTasks[1].data;
                            MappedTasks.forEach((data, index: number) => {
                                data.AssetOptions = responseAssets[AssetTypeIdsPositionMap[index]] ? responseAssets[AssetTypeIdsPositionMap[index]].data : [];
                            })
                            payload.Tasks = MappedTasks;
                            payload.Locations = responseSiteTasks[0].data;

                            payload.Clients = responseInit[0].data;
                            payload.Offices = responseInit[1].data;
                            payload.Users = responseInit[2].data.filter(user => user.status === 1);
                            payload.Divisions = responseInit[3].data;
                            payload.TaskTypes = responseInit[4].data;
                            payload.Roles = responseInit[5].data;
                            payload.AssetTypes = responseInit[6].data;
                            payload.showLLGButton = responseInit[7].data;

                            dispatch(this.setClientAndUserOptions(payload));
                        }).catch((error: any) => {
                            console.error("Error: ", error);
                            dispatch(this.setClientAndUserOptions(payload));
                            console.error("Error: ", error);
                            payload.alertJSON = {
                                hideAlert: false,
                                alertHeader: "Ooops!!",
                                alertSubText: "Error while loading Asset Options",
                            }
                            dispatch(this.openCloseAlertModal(payload));

                            setTimeout(() => {
                                payload.alertJSON = {
                                    hideAlert: true,
                                    alertHeader: "",
                                    alertSubText: "",
                                }
                                dispatch(this.openCloseAlertModal(payload));
                            }, 2000);
                        })
                    }).catch((error) => {
                        console.error("Error: ", error);
                        dispatch(this.setClientAndUserOptions(payload));
                        console.error("Error: ", error);
                        payload.alertJSON = {
                            hideAlert: false,
                            alertHeader: "Ooops!!",
                            alertSubText: "Error while loading Sites",

                        }

                        setTimeout(() => {
                            payload.alertJSON = {
                                hideAlert: true,
                                alertHeader: "",
                                alertSubText: "",
                            }
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2000);
                    })
                }).catch((error) => {
                    console.error("Error: ", error);
                    dispatch(this.setClientAndUserOptions(payload));
                    console.error("Error: ", error);
                    payload.alertJSON = {
                        hideAlert: false,
                        alertHeader: "Ooops!!",
                        alertSubText: "Error while loading Clients and Users",
                    }
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.alertJSON = {
                            hideAlert: true,
                            alertHeader: "",
                            alertSubText: "",
                        }
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
            }
            else {
                payload.apiObject.loadInitData().then((response) => {
                    payload.Clients = response[0].data;
                    payload.Offices = response[1].data;
                    payload.Users = response[2].data.filter(user => user.status === 1);
                    payload.Divisions = response[3].data;
                    payload.TaskTypes = response[4].data;
                    payload.Roles = response[5].data;
                    payload.AssetTypes = response[6].data;
                    payload.showLLGButton = response[7].data;
                    dispatch(this.setClientAndUserOptions(payload));
                }).catch((error) => {
                    console.error("Error: ", error);
                    dispatch(this.setClientAndUserOptions(payload));
                    console.error("Error: ", error);
                    payload.alertJSON = {
                        hideAlert: false,
                        alertHeader: "Ooops!!",
                        alertSubText: "Error while loading Clients and Users",
                    }
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.alertJSON = {
                            hideAlert: true,
                            alertHeader: "",
                            alertSubText: "",
                        }
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
            }
        }
    }

    loadSites = (payload: IJobPayload): any => {
        return (dispatch: any) => {
            payload.isLoading = true;
            dispatch(this.loadSitesAsync(payload));
            payload.apiObject.getSitesData(payload.Location.value).then((response: any) => {
                payload.isLoading = false;
                payload.Locations = response.data;
                dispatch(this.loadSitesAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading sites",
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: "",
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public loadSitesAsync = (payload: IJobPayload): any => {
        return { type: CONSTANTS.LOAD_JOB_LITES_SITES, payload }
    }

    public changeSite = (payload: IJobPayload) => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_SITE, payload };
    }

    public changeStartDateTime = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_START_DATE_TIME, payload };
    }

    public showHideClientDetailsTimeClock = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SHOW_HIDE_JOB_LITES_CLIENT_DETAILS_TIME_CLOCK, payload };
    }

    public setSiteOptions = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SET_JOB_LITES_SITE_OPTIONS, payload }
    }

    public changeRequesterName = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_REQUESTER_NAME, payload };
    }

    public changeClientUsers = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_CLIENT_USERS, payload };
    }

    public setClientAndUserOptions = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SET_JOB_LITES_CLIENT_AND_USERS, payload }
    }

    public showHideJobDashboardColumns = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SHOW_HIDE_JOB_DASHBOARD_COLUMNS, payload };
    }

    public openCloseAlertModal = (payload: IJobPayload): any => {

        return { type: CONSTANTS.OPEN_CLOSE_JOB_LITES_ALERT_MODAL, payload };
    }

    // New User Section Start
    public changeNewUserField = (payload: IJobPayload): any => {
        switch (payload.field) {
            case "name":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_USER_NAME_JOB, payload };
            case "email":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_USER_EMAIL_JOB, payload };
            case "phone":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_USER_PHONE_JOB, payload };

            default:
                break;
        }
    }

    public showHideAddNewUserSectionJob = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SHOW_HIDE_JOB_LITES_ADD_NEW_USER_SECTION_JOB, payload };
    }

    public saveNewUserJobAsync = (payload: IJobPayload): any => {

        let NewUser = payload.newUser;

        NewUser.objectType = "REQUESTER";
        NewUser.companyId = payload.Client.value;
        NewUser.siteIds = payload.Location.value;
        NewUser.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        NewUser.type = 1;

        return (dispatch: any) => {

            payload.isLoading = true;

            dispatch(this.saveNewUserJob(payload));

            payload.apiObject.saveUser(payload.newUser).then((responseUser: any) => {
                payload.isLoading = false;
                payload.newUser = responseUser;
                payload.NewUserSaveStatus = {
                    flag: true,
                    message: "New User Saved Successfully"
                };
                dispatch(this.saveNewUserJob(payload));
                setTimeout(() => {
                    payload.ShowAddNewUserSection = false;
                    payload.NewUserSaveStatus = {
                        flag: true,
                        message: ""
                    };
                    dispatch(this.showHideAddNewUserSectionJob(payload));
                }, 2000);
            }).catch((errorMessage: string) => {
                payload.ShowAddNewUserSection = true;
                payload.isLoading = false;
                payload.NewUserSaveStatus = {
                    flag: false,
                    message: errorMessage
                };
                dispatch(this.showHideAddNewUserSectionJob(payload));
                setTimeout(() => {
                    payload.NewUserSaveStatus = {
                        flag: true,
                        message: ""
                    };
                    dispatch(this.showHideAddNewUserSectionJob(payload));
                }, 2000);
            });
        }
    }

    saveNewUserJob = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SAVE_JOB_LITES_NEW_USER_JOB, payload };
    }

    // New User Section End

    // New Site Section Start
    changeNewSiteField = (payload: IJobPayload): any => {
        switch (payload.field) {
            case "name":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_NAME_JOB, payload };
            case "street":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_STREET_JOB, payload };
            case "city":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_CITY_JOB, payload };
            case "state":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_STATE_JOB, payload };
            case "country":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_COUNTRY_JOB, payload };
            case "pin":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_PIN_JOB, payload };
            case "phone":
                return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_PHONE_JOB, payload };

            default:
                break;
        }
    }

    public showHideAddNewSiteSectionJob = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SHOW_HIDE_JOB_LITES_ADD_NEW_SITE_SECTION_JOB, payload };
    }

    public saveNewSiteJobAsync = (payload: IJobPayload): any => {

        let NewSite = payload.newLocation;

        NewSite.objectType = "SITE";
        NewSite.companyId = payload.Location.value;
        NewSite.parentId = payload.Location.value;
        NewSite.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        NewSite.locationLevel = 0;
        NewSite.type = 1;
        NewSite.status = 0;

        return (dispatch: any) => {

            payload.isLoading = true;

            dispatch(this.saveNewSiteJob(payload));

            payload.apiObject.saveLocation(payload.newLocation).then((responseSite: any) => {
                payload.isLoading = false;
                payload.newLocation = responseSite;
                payload.NewSiteSaveStatus = {
                    flag: true,
                    message: "New Site Saved Successfully"
                };
                dispatch(this.saveNewSiteJob(payload));
                setTimeout(() => {
                    payload.ShowAddNewSiteSection = false;
                    payload.NewSiteSaveStatus = {
                        flag: true,
                        message: ""
                    };
                    dispatch(this.showHideAddNewSiteSectionJob(payload));
                }, 2000);
            }).catch((errorMessage: string) => {
                payload.ShowAddNewSiteSection = true;
                payload.NewSiteSaveStatus = {
                    flag: false,
                    message: errorMessage
                };
                dispatch(this.showHideAddNewSiteSectionJob(payload));
                setTimeout(() => {
                    payload.NewSiteSaveStatus = {
                        flag: true,
                        message: ""
                    };
                    dispatch(this.showHideAddNewSiteSectionJob(payload));
                }, 2000);
            });
        }
    }

    public saveNewSiteJob = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SAVE_JOB_LITES_NEW_SITE_JOB, payload };
    }

    // Tracking Details Changes Start

    public changeAssignOffice = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_ASSING_OFFICE, payload };
    }

    public changePrice = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHNAGE_JOB_LITES_PRICE, payload };
    }
    // Tracking Details Changes Start

    // Site Details Changes Start

    changeComments = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_COMMENTS, payload };
    }

    changeMainInspector = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_MAIN_INSPECTOR, payload };
    }
    // Site Details Changes End

    // Task Details Changes Start

    createNewTaskRow = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CREATE_JOB_LITES_NEW_TASK_ROW, payload };
    }

    deleteOrReopenTask = (payload) => {
        return { type: CONSTANTS.DELETE_OR_REOPEN_JOB_LITES_TASK, payload };
    }

    deleteLastTaskRow = (payload: IJobPayload): any => {
        return { type: CONSTANTS.DELETE_JOB_LITES_LAST_TASK_ROW, payload };
    }

    changeJobTaskDivision = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_JOB_TASK_DIVISION, payload };
    }

    changeTaskType = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_TASK_TYPE, payload };
    }

    loadAssetTypeAndAssetOptionsAsync = (payload: IJobPayload): any => {
        return (dispatch: any) => {

            payload.isLoading = true;
            dispatch(this.loadAssetTypeAndAssetOptions(payload));

            payload.apiObject._getAllSettings("AssetType", payload.TaskType.value).then((responseAssetType: any) => {

                if (responseAssetType.data[0]) {
                    payload.apiObject.getAssetsData(responseAssetType.data[0].id).then((responseAsset: any) => {
                        payload.isLoading = false;
                        payload.AssetType = responseAssetType.data[0];
                        payload.Locations = responseAsset.data;
                        dispatch(this.loadAssetTypeAndAssetOptions(payload));
                    }).catch((error: any) => {
                        console.error("Error: ", error);
                        dispatch(this.loadAssetTypeAndAssetOptions(payload));
                        payload.alertJSON = {
                            hideAlert: false,
                            alertHeader: "Ooops!!",
                            alertSubText: "Error while loading Asset Type"
                        }
                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.alertJSON = {
                                hideAlert: true,
                                alertHeader: "",
                                alertSubText: "",
                            }
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2000);
                    })
                }
                else {
                    payload.isLoading = false;
                    dispatch(this.loadAssetTypeAndAssetOptions(payload));
                    payload.alertJSON = {
                        hideAlert: false,
                        alertHeader: "Ooops!!",
                        alertSubText: "No Asset Type found for Tasktype: " + payload.TaskType.label
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    setTimeout(() => {
                        payload.alertJSON = {
                            hideAlert: true,
                            alertHeader: "",
                            alertSubText: "",
                        }
                        payload.isAsset = true;
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                }
            }).catch((error) => {
                console.error("Error: ", error);
                dispatch(this.loadAssetTypeAndAssetOptions(payload));
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Assets"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: "",
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    loadAssetTypeAndAssetOptions = (payload: IJobPayload): any => {
        return { type: CONSTANTS.LOAD_JOB_LITES_ASSET_TYPE_AND_ASSET_OPTIONS, payload };
    }

    setAssetOptions = (payload: IJobPayload): any => {
        return { type: CONSTANTS.SET_ASSET_OPTIONS, payload };
    }

    changeAsset = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_ASSET, payload };
    }

    changeInspector = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_INSPECTOR, payload };
    }

    changeIsDayJob = (payload: IJobPayload) => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_IS_DAY_JOB, payload };
    }

    // Task Details Changes End

    // Service Action Job (Start)
    addJobAsync = (payload: IJobPayload) => {

        let { CurrentJobDetails, cancelFlag, AllTaskTypeOptions, AssetTypeOptions, closeOrCancel } = payload;

        let LLGTaskType = AllTaskTypeOptions.find((taskType) => taskType.label === "LLG");
        let LLGAssetType = AssetTypeOptions.find((assetType) => assetType.label === "LLG");

        let LLGTaskTypeId = null;
        let LLGAssetTypeId = null;

        if (LLGTaskType) {
            LLGTaskTypeId = LLGTaskType.value;
        }

        if (LLGAssetType) {
            LLGAssetTypeId = LLGAssetType.value;
        }

        let jobPayload = {
            tenantId: 2,
            name: "Job" + moment(new Date()).format("_DD_MMM"),
            customerId: CurrentJobDetails.ClientName.value.value,
            objectType: "schedule",
            type: 1,
            status:
                cancelFlag ?
                    closeOrCancel === "Cancel" ? 64 : 16
                    :
                    CurrentJobDetails.status ? CurrentJobDetails.status : 1,
            siteId: CurrentJobDetails.SiteDetails.value.value,
            startDate: CurrentJobDetails.StartDate.value,
            endDate: CurrentJobDetails.EndDate.value,
            duration: CurrentJobDetails.StartDuration.value,
            requesterId: CurrentJobDetails.RequesterName.value ? CurrentJobDetails.RequesterName.value.value : null,
            secondaryOfficeId: CurrentJobDetails.AssignOffice.value.value,
            price: CurrentJobDetails.Price.value,
            salesPerson: CurrentJobDetails.SalesPerson.value ? CurrentJobDetails.SalesPerson.value.value : null,
            currency: CurrentJobDetails.Currency.value ? CurrentJobDetails.Currency.value.value : null,
            poNumber: CurrentJobDetails.PoNumber.value,
            quotationNumber: CurrentJobDetails.QuotationNumber.value,
            comments: CurrentJobDetails.Comments.value,
            mainInspectorId: CurrentJobDetails.MainInspector.value.value,
            inspectorIds: CurrentJobDetails.Inspectors.value ?
                CurrentJobDetails.Inspectors.value.map((data) => data.value).join(",") : null,
            clientUserIds: CurrentJobDetails.ClientUsers.value ?
                CurrentJobDetails.ClientUsers.value.map((data) => data.value).join(",") : null,
            officeId: CurrentJobDetails.AssignOffice.value.value,
            vendorDetails: CurrentJobDetails.VendorDetails.value,
            subVendorDetails: CurrentJobDetails.SubVendorDetails.value,
            isDayJob: CurrentJobDetails.isDayJob,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            isDayJobClosed: closeOrCancel === "Close" ? true : CurrentJobDetails.isDayJobClosed
        }

        if (CurrentJobDetails.id) {
            jobPayload["id"] = CurrentJobDetails.id;
        }

        let tasks = {
            tenantId: 2,
            TaskDetails: CurrentJobDetails.TaskDetails
        }

        let { LLGTaskDetails } = CurrentJobDetails;

        return (dispatch: any) => {
            payload.isLoading = true;
            dispatch(this.loadJobLitesPanel(payload));

            payload.apiObject.saveJobAPI(jobPayload).then((response) => {
                let scheduleResp = response.data.schedule;
                let notification = response.data.notification;
                let inspectorId = response.data.inspectorId;

                if (notification === 1) {
                    payload.apiObject.sendNotificationForReassignedJob({}, scheduleResp.id, inspectorId);
                }
                if (closeOrCancel !== "Close") {
                    let scheduleId = scheduleResp.id;
                    let type = 1;
                    let objectType = "TASK";
                    let taskPayload = [];
                    let newTaskName = "Task_" + moment(new Date()).format("_YYYY-MM-DD HH:mm:ss.SSS");
                    tasks.TaskDetails.forEach((data: any) => {

                        const observationType = 1024;

                        if (!data.id) {
                            switch (observationType) {
                                case 1024:
                                    newTaskName = `${scheduleResp.jobNumber}_${window.loggedInUserDetails.name}${moment(new Date()).format("_YYYY-MM-DD HH:mm:ss.SSS")}`;
                                    break;

                                default:
                                    break;
                            }
                        }
                        if (scheduleResp.status === CONSTANTS.JOB_STATUS_CANCELLED || data.isDirty) {
                            taskPayload.push({
                                id: data.id,
                                scheduleId: scheduleId,
                                type: type,
                                name: newTaskName,
                                objectType: objectType,
                                inspectorId: data.Inspector.value ? data.Inspector.value.value : null,
                                taskTypeId: data.TaskType.value ? data.TaskType.value.value : null,
                                assetTypeId: data.AssetType.value ? data.AssetType.value.id : null,
                                locationId: data.Asset.value ? data.Asset.value.value : null,
                                archived: 0,
                                observationType: observationType,
                                dueDate: null,
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                status:
                                    scheduleResp.status === CONSTANTS.JOB_STATUS_CANCELLED ?
                                        data.status === TASK_CONSTANTS.TASK_STATUS_OPEN ?
                                            TASK_CONSTANTS.TASK_STATUS_CANCELLED : data.status : data.status
                            });
                        }
                    })
                    if (LLGTaskDetails.status !== LLGTaskDetails.previousStatus || LLGTaskDetails.isNew) {
                        taskPayload.push({
                            id: LLGTaskDetails.id ? LLGTaskDetails.id : undefined,
                            scheduleId: scheduleId,
                            type: type,
                            name: `LLG_${newTaskName}`,
                            objectType: objectType,
                            inspectorId: null,
                            taskTypeId: LLGTaskTypeId,
                            assetTypeId: LLGAssetTypeId,
                            locationId: CurrentJobDetails.SiteDetails.value.value,
                            archived: 0,
                            observationType: 1024,
                            dueDate: new Date(),
                            status: LLGTaskDetails.status === -1 ? 1024 : LLGTaskDetails.status
                        });
                    }

                    payload.apiObject.saveTasks(taskPayload, cancelFlag, scheduleId).then((responseTaskType) => {
                        payload.alertJSON = {
                            hideAlert: false,
                            alertHeader: "Success!!",
                            alertSubText: scheduleResp.status !== 16 ?
                                `Job and Tasks saved successfully` :
                                `Job and Tasks cancelled successfully`,
                        }
                        if (responseTaskType.data.newObservations.length > 0) {
                            payload.apiObject.sendNotification(responseTaskType.data.newObservations);
                        }

                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.alertJSON = {
                                hideAlert: true,
                                alertHeader: "",
                                alertSubText: "",
                            }
                            dispatch(this.openCloseAlertModal(payload));
                            let config = {
                                headers: {
                                }
                            }
                            payload.offset = 0;
                            payload.limit = JobLitesInitialState.limit;
                            payload.config = config;
                            payload.isLoading = false;
                            dispatch(this.loadJobLitesPanel(payload));
                            payload.isLoading = true;
                            dispatch(this.loadJobDashboard(payload));
                        }, 2000);

                    }).catch((errTaskSave) => {
                        console.error("Error: ", errTaskSave);
                        payload.alertJSON = {
                            hideAlert: false,
                            alertHeader: "Ooops!!",
                            alertSubText: "Error while saving tasks",
                        }
                        payload.isLoading = false;
                        payload.isError = true;
                        dispatch(this.loadJobLitesPanel(payload));
                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.alertJSON = {
                                hideAlert: true,
                                alertHeader: "",
                                alertSubText: "",
                            }
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2000);

                    });
                }
                else {
                    payload.alertJSON = {
                        hideAlert: false,
                        alertHeader: "Success!!",
                        alertSubText: `Job closed successfully`,
                    }
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.alertJSON = {
                            hideAlert: true,
                            alertHeader: "",
                            alertSubText: "",
                        }
                        dispatch(this.openCloseAlertModal(payload));
                        let config = {
                            headers: {
                            }
                        }
                        payload.offset = 0;
                        payload.limit = JobLitesInitialState.limit;
                        payload.isLoading = true;
                        payload.config = config;
                        dispatch(this.loadJobDashboard(payload));
                    }, 2500);
                }

            }).catch((error) => {
                console.error("Error: ", error);
                payload.isError = true;
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Error!!",
                    alertSubText: error,
                }
                payload.isLoading = false;
                dispatch(this.loadJobLitesPanel(payload));
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: "",
                    }
                    payload.isError = true;
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            });
        }
    }
    // Service Action Job (End)

    // Add New Asset PopUp (Start)
    public saveNewAsset = (payload: IJobPayload): any => {
        payload.isLoading = true;
        return dispatch => {
            dispatch(this.saveNewAssetAsync(payload));
            payload.locationPayload.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            payload.apiObject.saveAsset(payload.locationPayload).then(() => {
                payload.isLoading = false;
                payload.isOpenAddNewAsset = true;
                payload.NewAsset = {
                    value: "",
                    error: true,
                    errorMessage: "Asset Save Succesfully"
                };
                payload.SelectedAssetType = null;
                dispatch(this.saveNewAssetAsync(payload));
                setTimeout(() => {
                    payload.isOpenAddNewAsset = false;
                    payload.NewAsset = JobLitesInitialState.NewAsset;
                    payload.SelectedAssetType = null;
                    dispatch(this.saveNewAssetAsync(payload));
                }, 2000);
            }).catch((error: any) => {
                console.error("Error: ", error);
                payload.isLoading = false;
                payload.isOpenAddNewAsset = true;
                payload.NewAsset = {
                    value: "",
                    error: true,
                    errorMessage: error
                };;
                payload.SelectedAssetType = null;
                dispatch(this.saveNewAssetAsync(payload));

                setTimeout(() => {
                    payload.isOpenAddNewAsset = true;
                    payload.NewAsset = JobLitesInitialState.NewAsset;
                    payload.SelectedAssetType = null;
                    dispatch(this.saveNewAssetAsync(payload));
                }, 2000);
            })
        }
    }

    public saveNewAssetAsync = (payload: IJobPayload): any => {
        return { type: CONSTANTS.ADD_NEW_JOB_LITES_ASSET, payload }
    }

    public openCloseAddNewAssetPopUp = (payload: IJobPayload): any => {
        return { type: CONSTANTS.OPEN_CLOSE_ADD_NEW_ASSET_POPUP, payload }
    }

    public changeSelectedAssetType = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_SELECTED_ASSET_TYPE, payload }
    }

    public changeAssetName = (payload: IJobPayload): any => {
        return { type: CONSTANTS.CHANGE_JOB_LITES_NEW_ASSET_NAME, payload }
    }

    // Add New Asset PopUp (End)

    // Close or Cancel Job Start

    public openCloseJobConfirmModal = (payload: IJobPayload) => {
        return { type: CONSTANTS.OPEN_CLOSE_JOB_LITES_CONFIRM_MODAL, payload }
    }

    public closeOrCancelJobAsync = (payload: IJobPayload) => {
        return { type: CONSTANTS.CLOSE_OR_CANCEL_JOB, payload }
    }

    public closeOrCancelJob = (payload: IJobPayload): any => {
        payload.isLoading = true;
        return (dispatch: any) => {
            dispatch(this.closeOrCancelJobAsync(payload));
            payload.apiObject.closeOrCancelJob(payload.scheduleId, payload.status).then((response: any) => {
                payload.isLoading = false;
                dispatch(this.closeOrCancelJobAsync(payload));
                payload.isCancelClose = true;
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: payload.status === "Cancel" ?
                        "Job and Task Cancelled Successfully" : "Job Closed Sucessfully",
                }
                dispatch(this.openCloseAlertModal(payload));
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: "",
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    let config = {
                        headers: {
                        }
                    }
                    payload.offset = 0;
                    payload.limit = JobLitesInitialState.limit;
                    payload.isLoading = true;
                    payload.config = config;
                    dispatch(this.loadJobDashboard(payload));
                }, 2000);
            }).catch((error) => {
                console.error("Error: ", error);
                payload.isLoading = false;
                dispatch(this.closeOrCancelJobAsync(payload));
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Oops!!",
                    alertSubText: error,
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: "",
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }
    // Close or Cancel Job End

    // Job Reset Start
    
    public resetJobValue = (payload: IJobPayload) => {
        return { type: CONSTANTS.RESET_JOB_LITES_VALUE, payload }
    }
    
    // Job Reset end
}