import { Label } from 'office-ui-fabric-react';
import * as React from 'react';
import Select from 'react-select';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { LooseGearService } from '../Services/LooseGearAPI';
import * as Loaders from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { ILooseGearService } from '../Interfaces/ILooseGearService';
import { ILooseGearStateProps } from '../Interfaces/ILooseGearStateProps';
import { ISelect } from '../../../CommonInterfaces/CommoInterfaces';
import { IAssetAPI } from '../../Assets/AssetInterface/IAsset';
import { AssetAPI } from '../../Assets/Services/AssetAPI';
import LooseGearDashboard from './LooseGearDashboard';
import { CLIENT_ADMIN_ROLE, CLIENT_USER_ROLE } from '../../../Constants/CommonConstants';

export default class LooseGearMainFilter extends React.Component<ILooseGearStateProps, any> {

    private _looseGearApi: ILooseGearService = null;
    private _assetApi: IAssetAPI = null;

    constructor(props: ILooseGearStateProps) {
        super(props);
        this._looseGearApi = new LooseGearService(window.API_URL, window.loggedInUserDetails);
        this._assetApi = new AssetAPI(window.API_URL, window.loggedInUserDetails);

    }

    public componentDidMount(): void {
        this.loadInitData();
    }

    public loadInitData = (): void => {
        this.props.loadClientsLooseGear({
            apiPayload: this._looseGearApi,
            assetApi: this._assetApi
        })

    }

    render() {

        let {
            isLoadingLooseGearClients,
            isLoadingLooseGearSites,
            selectedLooseGearClient,
            selectedLooseGearSite,
            looseGearClientFilters,
            looseGearSiteFilters,
            offset
        } = this.props.LooseGearState;

        const isClientRole =
            window.loggedInUserDetails.role === CLIENT_ADMIN_ROLE || window.loggedInUserDetails.role === CLIENT_USER_ROLE

        return (
            <React.Fragment>
                {/* Main Filter */}
                <div className="ms-Grid asset-main-filter-grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Client: <span className="mandatory-span">*</span></Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                            <LoadingOverlay
                                active={isLoadingLooseGearClients}
                                spinner={<Loaders.BeatLoader />}
                                text="">
                                <Select
                                    value={selectedLooseGearClient}
                                    placeholder={looseGearClientFilters.length > 0 ? `----Select Client----` : ``}
                                    onChange={(newVal: ISelect) => {
                                        this.props.changeLooseGearClientFilter({
                                            apiPayload: this._looseGearApi,
                                            assetApi: this._assetApi,
                                            selectedLocation: newVal,
                                        })
                                    }}
                                    options={isClientRole ?
                                        CommonUtility.sortByColumn(looseGearClientFilters
                                            .filter((client: ISelect) => window.loggedInUserDetails.companyId === client.value), "label", "asc")
                                        :
                                        CommonUtility.sortByColumn(looseGearClientFilters, "label", "asc")

                                    }
                                />

                            </LoadingOverlay>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Site: <span className="mandatory-span">*</span></Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 asset-site-filter">
                            <LoadingOverlay
                                active={isLoadingLooseGearSites}
                                spinner={<Loaders.BeatLoader />}
                                text="">
                                <Select
                                    isDisabled={!selectedLooseGearClient}
                                    value={selectedLooseGearSite}
                                    placeholder={looseGearSiteFilters.length > 0 ? `----Select Site----` : ``}
                                    onChange={(newVal: ISelect) => {
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify({
                                                    location: newVal.value
                                                })
                                            }
                                        }

                                        this.props.changeLooseGearSiteFilter({
                                            apiPayload: this._looseGearApi,
                                            selectedLocation: newVal,
                                            offset: offset,
                                            config: config
                                        })
                                    }}
                                    options={CommonUtility.sortByColumn(looseGearSiteFilters, "label", "asc")}
                                />
                            </LoadingOverlay>
                        </div>

                    </div>
                </div>

                {/* Dashboard */}
                {selectedLooseGearClient && selectedLooseGearSite &&
                    <LooseGearDashboard
                        {...this.props}
                    />
                }

            </React.Fragment>
        )
    }
}
