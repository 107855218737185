import { ILoggedInUser } from "../../../../CommonInterfaces/CommoInterfaces";
import * as Constants from "../Constants/TrialFitPicLibraryConstants";
import { IDocumentEntity } from "../Interfaces/IDocumentEntity";
import { ITrialFitPicLibraryAPI } from "../Interfaces/ITrialFitPicLibraryAPI";
import { ALERT_MESSAGE } from "./../../../../Constants/MessageConstants";
import axios from 'axios';

export class TrialFitPicLibraryAPI implements ITrialFitPicLibraryAPI {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUserDetails: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUserDetails;
    }

    public loadInitData = (clientId: number, siteId: number, name: string): Promise<any> => {
        let promises = [this.getEntityByClientIdSiteIdName(clientId, siteId, name), this.getAllLLGAssetTypes()];
        return Promise.all(promises);
    }

    public getEntityByName = (name: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(Constants.GET_ENTITY_BY_NAME_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?parentId=${name}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getEntityByName method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getEntityByClientIdSiteIdName = (clientId: number, siteId: number, name: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(Constants.GET_ENTITY_BY_CLIENT_ID_SITE_ID_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(clientId ?
                    `?clientId=${clientId}&siteId=${siteId}&name=${name}`
                    : `?name=${name}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getEntityByClientIdSiteIdName method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getEntityByParentId = (parentId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(Constants.GET_ENTITY_BY_PARENT_ID_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?parentId=${parentId}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getEntityByParentId method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public saveEntity = (payload: IDocumentEntity): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(Constants.SAVE_ENTITY_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``), payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error saveEntiy method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else if (error.response && error.response.status === 417) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    public saveFile = (payload: FormData): Promise<any> => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(Constants.SAVE_FILE_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                , payload, config)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error saveEntiy method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else if (error.response && error.response.status === 417) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    public deleteEntity = (id: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(Constants.DELETE_ENTITY_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?documentId=${id}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error deleteEntity method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getAllLLGAssetTypes = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(Constants.GET_ALL_LLG_ASSET_TYPE_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllLLGAssetTypes method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

}