import { TextField } from 'office-ui-fabric-react';
import React, { PureComponent } from 'react';
// import { TaskTypeAPI } from '../Services/TaskTypeAPI';

export default class ConfigJSONViewer extends PureComponent {

    // _taskApi = null;

    // constructor(props) {
    //     super(props);
    //     this._taskApi = new TaskTypeAPI(window.API_URL);
    // }

    render() {

        let {
            CurrentConfig,
            IsValidConfigJSON
        } = this.props.Config;

        return (
            <React.Fragment>
                {/* <JSONInput
                    id='1'
                    placeholder={CurrentConfig.configJson}
                    onChange={(value) => {
                        value.plainText = value.plainText.replace(/"/g, "'");
                        this.props.changeConfigJson({
                            jsonData: value
                        })
                    }}
                    style={JSONViewStyleConstants}
                    locale={locale}
                    height='80vh'
                // onC
                /> */}
                <TextField
                    value={CurrentConfig.configJson}
                    onChange={(ev, newVal) => {
                        // value.plainText = value.plainText.replace(/"/g, "'");
                        this.props.changeConfigJson({
                            jsonData: newVal
                        })
                    }}
                    multiline
                    errorMessage={!IsValidConfigJSON ? "Please Enter a valid JSON" : ""}
                    styles={{
                        fieldGroup: {
                            height: "82.8vh",
                            border: "0.25px solid #e6e6e6",
                        },
                    }}
                />
            </React.Fragment>
        )
    }
}
