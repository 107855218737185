import axios from 'axios';
import { ALERT_MESSAGE } from '../../Constants/MessageConstants';
import { GET_CLIENT_IMAGE_URL } from '../Clients/Constants/ClientConstants';

export class LoginAPI {

    _URL = "";

    constructor(URL) {
        this._URL = URL;
    }

    getClientLogo() {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(`${GET_CLIENT_IMAGE_URL}${window.loggedInUserDetails.companyId}`)
                .concat(origin === "http://localhost:3000" ?
                    `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``))
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error loadClientLogo method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        })
    }

    doLogin(payload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat('weblogin'), payload).then(responseData => {
                localStorage.setItem("assetNameRegex", "\"'<.>#%|/\\\\.&")
                resolve(responseData.data.response.data);
            }).catch(error => {
                // console.error("Error in doLogin method", error.status);
                reject(error.response.data);
            });
        });
    }

    doLogout() {
        return new Promise((resolve, reject) => {
            localStorage.removeItem("assetNameRegex");
            sessionStorage.removeItem("jsessionid");
            sessionStorage.removeItem("companyLogo");
            sessionStorage.removeItem("fileType");
            sessionStorage.removeItem("isNewImageUpoaded");
            sessionStorage.removeItem("link");
            axios.get(this._URL.concat('login').concat(`?logout=true`)).then(responseData => {
                resolve(responseData.data.response.data);
            }).catch(error => {
                console.error("Error in doLogout method", error);
                reject("Error while Log out");
            });
        });
    }

    generateOTP(otpPayload) {
        return new Promise((resolve, reject) => {
            axios.post(
                this._URL.concat('generateOTP'),
                otpPayload
            )
                .then(responseData => {
                    resolve(responseData.data);
                }).catch((error) => {
                    // console.error("Error in generateOTP method", error);
                    reject(error.response.data.error);
                });
        });
    }

    resetPassword(resetPasswordPayload) {
        return new Promise((resolve, reject) => {
            axios.post(
                this._URL.concat('resetPassword'),
                resetPasswordPayload
            )
                .then(responseData => {
                    resolve(responseData.data);
                }).catch((error) => {
                    console.error("Error in resetPassword method", error);
                    reject(error.response.data.error);
                });
        });
    }

}