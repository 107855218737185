import * as CONSTANTS from "../Constants/AssetTypeConstants";
import { ALERT_MESSAGE } from "./../../../../Constants/MessageConstants";
import axios from 'axios';

export class AssetTypeAPI {

    _URL = "";

    constructor(URL) {
        this._URL = URL;
    }

    _getChunkAssetTypes(objectType, offset, limit) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_ASSETTYPES_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?`)
                .concat(`objectType=${objectType}&`)
                .concat(`offset=${offset}&`)
                .concat(`limit=${limit}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getChunkAssetTypes method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    getAllFormAttributes(objectType) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_FORM_ATTRIBUTE_LIST)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?`)
                .concat(`formobject=${objectType}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllFormAttributes method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    saveAssetTypeConfigData = (payload) => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(CONSTANTS.GET_ASSETTYPES_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), payload)
                .then((response) => {
                    resolve(true);
                }).catch((error) => {
                    console.error("Error saveFormConfigData", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    deleteAssetType = (payload) => {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(CONSTANTS.GET_ASSETTYPES_URL)
                .concat(`/${payload.id}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``))
                .then((response) => {
                    resolve(response);
                }).catch((error) => {
                    console.error("Error deleteAssetType", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    getAssetTypeDetails(objectType, offset, limit) {
        let promises = [this._getChunkAssetTypes(objectType, offset, limit)];
        return Promise.all(promises);

    }

}