import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Label } from 'office-ui-fabric-react';
import { StatesAction } from '../Actions/StatesAction';

const mapStateToProps = (state) => {
    return {
        States: state.StatesReducer
    }
}

class StatesComponent extends PureComponent {

    render() {
        return (
            <React.Fragment>
                <Label className="breadcrumb-label">Lifting / Settings / States</Label>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let statesAction = new StatesAction();

    return bindActionCreators({ ...statesAction }, dispatch);
}

const States = connect(mapStateToProps, mapDispatchToProps)(StatesComponent);
export default States;
