import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ReportsActions } from '../Actions/ReportsActions';
import { Breadcrumb } from 'office-ui-fabric-react';
import { IReportStateProps } from '../Interfaces/IReportStateProps';
import ReportsDasboard from './ReportsDasboard';
import { REPORT_BREADCRUMBS } from '../Constants/ReportsConstants';

const mapStateToProps = (state: any): IReportStateProps => {
    return {
        ReportsState: state.ReportsReducer
    }
}

class ReportsComponent extends React.PureComponent<IReportStateProps, {}> {

    public render(): JSX.Element {
        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={REPORT_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                </div>
                <ReportsDasboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => {

    let reportsActions: ReportsActions = new ReportsActions();

    return bindActionCreators({ ...reportsActions }, dispatch);
}

const Clients = connect(mapStateToProps, mapDispatchToProps)(ReportsComponent);
export default Clients;
