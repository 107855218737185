import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
// import AssetAttributes from './AssetAttributes';
// import AssetHistory from './AssetHistory';
import AssetReports from './AssetReports';
import AssetDescription from './AssetDescription';
import AssetFiles from './AssetFiles';
import AssetAttributes from './AssetAttributes';
import * as CommonConstants from './../../../Constants/CommonConstants';
import { AssetAPI } from '../Services/AssetAPI';

export default class AddEditAssetDetails extends Component {

    _loggedInUserDetails;
    _role;
    _isAssetDescriptionEnabled = false;
    _isAssetAttributesEnabled = false;
    _assetAPI = null;

    constructor(props) {
        super(props);
        this._loggedInUserDetails = window.loggedInUserDetails;
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);
        this._primaryOfficeId = this._loggedInUserDetails.primaryOfficeId;
        this._role = this._loggedInUserDetails.role;
        this._isAssetDescriptionEnabled = this._role === CommonConstants.LO_ADMIN_ROLE || origin === "http://localhost:3000";
        this._isAssetAttributesEnabled = this._role === CommonConstants.LO_ADMIN_ROLE || origin === "http://localhost:3000";

        if (this._loggedInUserDetails.webAccess && !this._isAssetDescriptionEnabled) {

            const descriptionView = JSON.parse(this._loggedInUserDetails.webAccess)["ASSET-DESCRIPTION"];
            const descRule = descriptionView.viewRules.find(
                (rule) => rule.roles.split(",").indexOf(this._role) > -1);

            if (descRule?.isEnabled) {
                this._isAssetDescriptionEnabled = true;
            }
        }
        if (this._loggedInUserDetails.webAccess && !this._isAssetAttributesEnabled) {

            const attributesView = JSON.parse(this._loggedInUserDetails.webAccess)["ASSET-ATTRIBUTES"];
            const attrRule = attributesView.viewRules.find(
                (rule) => rule.roles.split(",").indexOf(this._role) > -1);

            if (attrRule?.isEnabled) {
                this._isAssetAttributesEnabled = true;
            }
        }

    }

    componentDidMount() {
        this.props.loadAssetDescription({
            apiObject: this._assetAPI,
            selectedAssetId: this.props.Asset.selectedAssetId
        })
    }


    render() {

        let {
            selectedAssetId,
            TabIndex,
            isLoadingAssetDescriptionTab,
        } = this.props.Asset;

        return (
            <Tabs selectedIndex={TabIndex} onSelect={(index) => this.props.changeAssetPanelTab({ TabIndex: index })}>
                <TabList>
                    {this._isAssetDescriptionEnabled &&
                        <Tab>Description</Tab>
                    }
                    {this._isAssetAttributesEnabled &&
                        <Tab disabled={selectedAssetId === -1 || isLoadingAssetDescriptionTab}>Attributes</Tab>
                    }
                    <Tab disabled={selectedAssetId === -1}>History</Tab>
                    <Tab disabled={selectedAssetId === -1 || isLoadingAssetDescriptionTab}>Files</Tab>
                    {/* <Tab disabled>Logs</Tab> */}
                </TabList>

                {this._isAssetDescriptionEnabled &&
                    <TabPanel>
                        <AssetDescription
                            {...this.props}
                        />
                    </TabPanel>
                }
                {this._isAssetAttributesEnabled &&
                    <TabPanel>
                        <AssetAttributes
                            {...this.props}
                        />
                    </TabPanel>
                }

                <TabPanel>
                    <AssetReports
                        {...this.props}
                    />
                </TabPanel>

                <TabPanel>
                    <AssetFiles
                        {...this.props}
                    />
                </TabPanel>

                {/* <TabPanel>
                        <AssetHistory
                            {...this.props}
                        />
                    </TabPanel> */}

            </Tabs>

        )
    }
}
