import React, { PureComponent } from 'react'
import { FadeLoader } from 'react-spinners';
import {
    Panel, TextField, PrimaryButton, ActionButton, DefaultButton, PanelType, Label, ChoiceGroup,
    Spinner, SpinnerSize, Image, Shimmer, IconButton,
} from 'office-ui-fabric-react';
import AlertRedux from '../../Alert/AlertRedux';
import LoadingOverlay from 'react-loading-overlay';
import { UserAPI } from '../Services/UserAPI';
import Select from 'react-select';
import moment from 'moment';
import { DATE_TIME_FORMAT_USER } from '../../../Constants/MessageConstants';
import { USER_TYPE_MENU, CLIENT_USERS_DESIGNATIONS, LO_USERS_DESIGNATIONS, CLIENT_ADMIN_DESIGNATIONS, LO_ADMIN_DESIGNATIONS, LO_ADMIN_CLIENT_DESIGNATIONS } from '../Constants/UserConstants';
import ImageUploader from 'react-images-upload';
import { IconConstants } from '../../../Constants/StylesConstants';
import { CLIENT_ADMIN_ROLE, CONFIG_ADMIN_ROLE, LO_ADMIN_ROLE } from '../../../Constants/CommonConstants';

export default class AddEditUserPanel extends PureComponent {

    _userAPI = null;

    constructor(props) {
        super(props);
        this._userAPI = new UserAPI(window.API_URL);
    }

    UNSAFE_componentWillMount() {
        this.props.loadDropDownDataAsync({
            apiObject: this._userAPI,
            locationLevel: 0,
            IsEdit: false,
            CurrentUser: this.props.User.CurrentUser
        });
    }

    onDropUserSignature = (pictures) => {

        let { CurrentUser } = this.props.User;

        if (pictures.length > 0) {
            const uploadImageData = new FormData();
            uploadImageData.append('imageFile', pictures[0],
                pictures[0].name);

            this.props.uploadUserSignature({
                apiObject: this._userAPI,
                uploadImageData,
                CurrentUser
            });
        }
    }

    render() {

        let {
            PanelHeaderText,
            loadingAddEditUserPanel,
            ShowPanel,
            CurrentUser,
            alertJSON,
            CompanyOptions,
            SiteOptions,
            OfficeOptions,
            RoleOptions,
            loadingCompanyData,
            loadingSiteOfficeData,
            DivisionOptions,
            isLoadingUserSignature
        } = this.props.User;

        let isSaveDisabled =
            (CurrentUser && !CurrentUser.name.value)
            || (CurrentUser && !CurrentUser.email.value)
            || (CurrentUser && !CurrentUser.Designation.value)
            || (CurrentUser && !CurrentUser.Company.value)
            || (CurrentUser && CurrentUser.type === 0 && !CurrentUser.PrimaryOffice.value)
            || (CurrentUser && CurrentUser.type === 1 && !CurrentUser.ClientSites.value)
            ;

        let HeaderText = PanelHeaderText;
        if (!HeaderText && CurrentUser.name.value) {
            HeaderText = `${CurrentUser.name.value} 
            (Created: ${moment(CurrentUser.createdDate).format(DATE_TIME_FORMAT_USER)} | 
            Modified: ${moment(CurrentUser.modifiedDate).format(DATE_TIME_FORMAT_USER)})
            `;
        }

        const isClientAdmin = window.loggedInUserDetails?.role === CLIENT_ADMIN_ROLE;
        const isLOAdmin = window.loggedInUserDetails?.role === LO_ADMIN_ROLE;
        const isConfgAdmin = window.loggedInUserDetails?.role === CONFIG_ADMIN_ROLE;

        let userType = USER_TYPE_MENU;

        userType[0].disabled = isClientAdmin;

        const filterCompanyOptions =
            isClientAdmin ? CompanyOptions.filter(company => company.id === window.loggedInUserDetails.companyId) : CompanyOptions;

        const filteredRoleOptions = CurrentUser && CurrentUser.type !== null && CurrentUser.type !== undefined ?
            CurrentUser.type === 0 ?
                isLOAdmin || isConfgAdmin ?
                    RoleOptions.filter(role => LO_ADMIN_DESIGNATIONS.indexOf(role.label) > -1)
                    :
                    RoleOptions.filter(role => LO_USERS_DESIGNATIONS.indexOf(role.label) > -1)
                :
                isClientAdmin ?
                    RoleOptions.filter(role => CLIENT_ADMIN_DESIGNATIONS.indexOf(role.label) > -1)
                    :
                    isLOAdmin || isConfgAdmin ?
                        RoleOptions.filter(role => LO_ADMIN_CLIENT_DESIGNATIONS.indexOf(role.label) > -1)
                        :
                        RoleOptions.filter(role => CLIENT_USERS_DESIGNATIONS.indexOf(role.label) > -1)
            :
            []

        return (
            <Panel
                headerText={HeaderText}
                isOpen={ShowPanel}
                type={PanelType.medium}
                onDismiss={() => this.props.opencloseAddEditUserPanel({
                    ShowPanel: false,
                    PanelHeaderText: "",
                })}
                closeButtonAriaLabel="Close">
                <LoadingOverlay
                    active={loadingAddEditUserPanel}
                    spinner={<FadeLoader />}
                    text='Loading Please Wait...'>

                    <ActionButton
                        text="Save"
                        disabled={isSaveDisabled}
                        menuIconProps={IconConstants.saveicon}
                        onClick={() => {
                            this.props.saveUserAsync({
                                apiObject: this._userAPI,
                                CurrentUser: CurrentUser
                            });
                        }}
                    />
                    {` `}
                    <ActionButton
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                        onClick={() => {
                            this.props.opencloseAddEditUserPanel({
                                ShowPanel: false,
                                PanelHeaderText: "",
                            });
                        }}
                    />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 action-span">
                            <ChoiceGroup
                                selectedKey={CurrentUser ? CurrentUser.type : null}
                                disabled={CurrentUser?.id}
                                options={USER_TYPE_MENU}
                                onChange={(ev, val) => {
                                    this.props.changeUserType({
                                        apiObject: this._userAPI,
                                        type: val.key
                                    })
                                }}
                                label="User Type:"
                                required={true} />
                        </div>
                    </div>

                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    label="Name:"
                                    prefix="Name"
                                    value={CurrentUser && CurrentUser.hasOwnProperty("name") ? CurrentUser.name.value : ""}
                                    onChange={(ev, newVal) => {
                                        this.props.changeUserName({
                                            name: newVal
                                        })
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    label="Email:"
                                    value={CurrentUser && CurrentUser.hasOwnProperty("email") ? CurrentUser.email.value : ""}
                                    prefix="Email"
                                    onChange={(ev, newVal) => {
                                        this.props.changeUserEmail({
                                            email: newVal
                                        })
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    label="Phone:"
                                    value={CurrentUser && CurrentUser.hasOwnProperty("phone") ? CurrentUser.phone.value : ""}
                                    prefix="Phone"
                                    onChange={(ev, newVal) => {
                                        this.props.changeUserPhone({
                                            phone: newVal
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label>Role: <span className="mandatory-span">*</span></Label>
                                <Select
                                    value={CurrentUser && CurrentUser.hasOwnProperty("Designation") ? CurrentUser.Designation.value : null}
                                    onChange={(newval) => {
                                        this.props.changeUserRole({
                                            Designation: newval
                                        });
                                    }}
                                    options={filteredRoleOptions}
                                />
                            </div>
                        </div>
                        {!isClientAdmin &&
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        label="Description:"
                                        value={CurrentUser && CurrentUser.description?.value}
                                        onChange={(ev, newVal) => {
                                            this.props.changeUserDescription({
                                                description: newVal
                                            })
                                        }}
                                        multiline
                                    />
                                </div>
                            </div>
                        }
                        {CurrentUser && CurrentUser.type !== undefined && CurrentUser.type !== null &&   // COMPANY

                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Label>
                                        {CurrentUser.type === 0 ? "LO" : "Client"} Company: <span className="mandatory-span">*</span>
                                    </Label>
                                    {loadingCompanyData ?
                                        <Spinner
                                            size={SpinnerSize.medium}
                                            label="Loading Companies..."
                                        />
                                        :
                                        <Select
                                            isClearable
                                            value={CurrentUser && CurrentUser.hasOwnProperty("Company") ? CurrentUser.Company.value : null}
                                            onChange={(newval) => {
                                                this.props.changeUserCompany({
                                                    Company: newval,
                                                    apiObject: this._userAPI,
                                                    objectType: CurrentUser.type === 0 ? "LO_OFFICE" : "SITE"
                                                });
                                            }}
                                            options={filterCompanyOptions}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {CurrentUser && CurrentUser.type === 1 &&   // CLIENT USERS
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Label>Client Sites: <span className="mandatory-span">*</span></Label>
                                    {loadingSiteOfficeData ?
                                        <Spinner
                                            size={SpinnerSize.medium}
                                            label="Loading Sites..."
                                        />
                                        :
                                        <Select
                                            isMulti={true}
                                            isClearable
                                            value={CurrentUser && CurrentUser.hasOwnProperty("ClientSites") ? CurrentUser.ClientSites.value : null}
                                            onChange={(newval) => {
                                                this.props.changeUserClientSites({
                                                    ClientSites: newval
                                                });
                                            }}
                                            options={SiteOptions}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {CurrentUser && CurrentUser.type === 0 && // LO USERS
                            <React.Fragment>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>Primary Office: <span className="mandatory-span">*</span></Label>
                                        {loadingSiteOfficeData ?
                                            <Spinner
                                                size={SpinnerSize.medium}
                                                label="Loading Offices..."
                                            />
                                            :
                                            <Select
                                                value={CurrentUser && CurrentUser.hasOwnProperty("PrimaryOffice") ? CurrentUser.PrimaryOffice.value : null}
                                                onChange={(newval) => {
                                                    this.props.changeUserPrimaryOffice({
                                                        PrimaryOffice: newval
                                                    });
                                                }}
                                                options={OfficeOptions}
                                            />
                                        }
                                    </div>
                                </div>

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>Other Offices:</Label>
                                        {loadingSiteOfficeData ?
                                            <Spinner
                                                size={SpinnerSize.medium}
                                                label="Loading Offices..."
                                            />
                                            :
                                            <Select
                                                isMulti
                                                isClearable
                                                value={CurrentUser && CurrentUser.hasOwnProperty("OtherOffices") ? CurrentUser.OtherOffices.value : null}
                                                onChange={(newval) => {
                                                    this.props.changeUserOtherOffices({
                                                        OtherOffices: newval
                                                    });
                                                }}
                                                options={OfficeOptions}
                                                closeMenuOnSelect={false}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>Divisions:</Label>
                                        <Select
                                            isMulti
                                            isClearable
                                            value={CurrentUser && CurrentUser.hasOwnProperty("Divisions") ? CurrentUser.Divisions.value : null}
                                            onChange={(newval) => {
                                                this.props.changeUserDivisions({
                                                    Divisions: newval
                                                });
                                            }}
                                            options={DivisionOptions}
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {!isClientAdmin &&
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        label="External Id:"
                                        value={CurrentUser?.externalId?.value}
                                        onChange={(ev, newVal) => {
                                            this.props.changeUserExternalId({
                                                externalId: newVal
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        }

                        {CurrentUser && CurrentUser.id && !isClientAdmin &&
                            <>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>Signature:</Label>
                                    </div>
                                </div>
                                {isLoadingUserSignature ?
                                    // <Spinner
                                    //     size={SpinnerSize.large}
                                    //     label={`Loading Signature`}
                                    // />
                                    <Shimmer
                                        styles={{
                                            root: {
                                                width: "10em"
                                            },
                                            shimmerWrapper: {
                                                height: "10em",
                                            }
                                        }} />
                                    :
                                    <>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                {CurrentUser.Signature && CurrentUser.Signature.imageURL ?
                                                    <Image
                                                        src={CurrentUser.Signature.imageURL}
                                                        alt="No Logo Available"
                                                        styles={{
                                                            image: {
                                                                display: "block",
                                                                maxWidth: "17em",
                                                                maxHeight: "2.85em",
                                                                width: "auto",
                                                                height: "auto"
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <small>No Signature Available</small>
                                                }
                                            </div>
                                        </div>

                                        {CurrentUser.Signature?.imageURL &&
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                        <IconButton
                                                            iconProps={IconConstants.deleteicon}
                                                            onClick={() => {
                                                                this.props.deleteUserSignature({
                                                                    apiObject: this._userAPI,
                                                                    CurrentUser
                                                                });
                                                            }}
                                                        />
                                                        <span className="mandatory-span">{CurrentUser.Signature ? CurrentUser.Signature.deleteImageErrorText : ``}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ImageUploader
                                                    withIcon={false}
                                                    withLabel={false}
                                                    buttonText='Upload Signature'
                                                    onChange={this.onDropUserSignature}
                                                    imgExtension={['.jpg', '.png']}
                                                    maxFileSize={5242880}
                                                    singleImage={true}
                                                />
                                                <span className="mandatory-span">{CurrentUser.Signature ? CurrentUser.Signature.uploadImageErrorText : ``}</span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }


                    </div>

                    <br />

                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    text="Save"
                                    disabled={isSaveDisabled}
                                    menuIconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveUserAsync({
                                            apiObject: this._userAPI,
                                            CurrentUser: CurrentUser
                                        });
                                    }}
                                />
                                {` `}
                                <DefaultButton
                                    text="Cancel"
                                    menuIconProps={IconConstants.cancelIcon}
                                    onClick={() => {
                                        this.props.opencloseAddEditUserPanel({
                                            ShowPanel: false,
                                            PanelHeaderText: "",
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {!alertJSON.hideAlert &&
                        <AlertRedux
                            {...this.props}
                        />
                    }
                </LoadingOverlay>

            </Panel >
        )
    }
}
