import { IConfigData } from "../Interfaces/ConfigInterface";
import { ISelect, IError, IAlert } from "../../../../CommonInterfaces/CommoInterfaces";

export const ConfigInitialState = {
    Configs: [] as IConfigData[],
    LoadingConfigPanel: false,
    SelectedConfig: null,
    CurrentConfig: {
        id: -99,
        name: "",
        configJson: {},
    } as IConfigData,
    IsValidConfigJSON: true,
    NameError: {
        error: true,
        errorMessage: ""
    } as IError,
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    } as IAlert,
    PanelHeaderText: "",
    ShowTaskTypeAddEditPanel: false,
    FormAttributeOptions: [] as ISelect[],
    ConfigBreadCrumb: [
        { text: 'SETTINGS', key: 'Setting' },
        { text: 'Configurations', key: 'Configuration' }
    ],
}