import React, { PureComponent } from 'react'
import { ActionButton, IconButton, TooltipHost, Toggle, SearchBox, Dropdown } from 'office-ui-fabric-react'
import AddEditJobPanel from './AddEditJobPanel';
import { JobAPI } from '../Services/JobAPI';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import filterFactory from 'react-bootstrap-table2-filter';
// import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import LoadingOverlay from 'react-loading-overlay';
import { FadeLoader } from 'react-spinners';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { calloutProps, tooltipId, hostStyles, IconConstants, DropdownStyles, columnHostStyles } from '../../../Constants/StylesConstants';
import { JOB_STATUS, JOB_STATUS_DROPDOWN_FILTER_OPTIONS } from '../Constants/JobConstants';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { NO_ALLOW_EDIT_JOB_TASK_CLIENT_ASSET_ROLE } from '../../../Constants/MessageConstants';
import { StatusIconCustomCell } from '../../Assets/Views/CustomColumnViews';
import * as CONSTANTS from '../Constants/JobConstants';

const defaultSorted = [{
    dataField: 'startDate',
    order: 'desc'
}];

const RemoteAll = ({
    data, columns, onTableChange, page, hideSizePerPage
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
        >
            {
                props => (
                    <BootstrapTable
                        remote
                        condensed
                        hover
                        striped
                        filter={filterFactory()}
                        defaultSorted={defaultSorted}
                        pagination={paginationFactory({})}
                        onTableChange={onTableChange}
                        {...props.baseProps}
                        wrapperClasses="table-responsive" />
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class JobDashboard extends PureComponent {

    _jobApi = null;

    constructor(props) {
        super(props);
        this._jobApi = new JobAPI(window.API_URL);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {

        let config = {
            headers: {
            }
        }
        this.props.loadJobDashboard({
            offset: 0,
            limit: this.props.Job.limit,
            apiObject: this._jobApi,
            LoadingDashboard: true,
            config: config,
            filter: false
        });
    }

    fetchMoreData = () => {

        let filterJson = {};

        let { filters } = this.props.Job;

        for (let dataField in filters) {
            filterJson[dataField] = filters[dataField].filterVal
        }

        let config = {
            headers: {
            }
        }
        if (Object.keys(filterJson).length > 0) {
            config = {
                headers: {
                    filter: JSON.stringify(filterJson)
                }
            }
        }

        if (this.props.TotalJobsCount === this.props.Job.Jobs.length) {
            this.props.fixJobDashboard({
                HasMoreJobs: false
            })
            return;
        }
        else {
            this.props.loadJobDashboard({
                offset: this.props.Job.offset,
                limit: this.props.Job.limit,
                apiObject: this._jobApi,
                LoadingDashboard: false,
                config: config,
                isScrolled: true,
                filter: false
            });

        }
    };


    handleTableChange = (type, { filters, sortField, sortOrder }) => {

        // console.log(sortOrder);

        let filterJson = {};

        for (let dataField in filters) {
            filterJson[dataField] = filters[dataField].filterVal
        }

        let config = {
            headers: {
            }
        }
        if (Object.keys(filterJson).length > 0) {
            config = {
                headers: {
                    filter: JSON.stringify(filterJson)
                }
            }
        }

        if (type.toUpperCase() === "FILTER") {

            this.props.loadJobDashboard({
                filtered: true,
                offset: 0,
                limit: this.props.Job.limit,
                apiObject: this._jobApi,
                LoadingDashboard: true,
                config: config,
                filters: filters
            })
        }

        else if (type.toUpperCase() === "SORT") {
            setTimeout(() => {

                let result = [...this.props.Job.Jobs];


                // Handle column sort
                if (sortOrder === 'asc') {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return 1;
                        } else if (b[sortField] > a[sortField]) {
                            return -1;
                        }
                        return 0;
                    });
                } else {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return -1;
                        } else if (b[sortField] > a[sortField]) {
                            return 1;
                        }
                        return 0;
                    });
                }

                this.props.sortJobDashboard({
                    sortedJobs: result,
                    sortedColumn: sortField,
                    sortOrder
                });

            }, 1000);
        }

    }

    render() {

        let {
            ShowPanel,
            Jobs,
            HasMoreJobs,
            LoadingDashboard,
            ShowActiveJobs,
            sortedColumn,
            sortOrder,
            job_status_filter_value,
            job_number_filter_value,
            job_client_filter_value,
            job_site_filter_value,
            job_qn_filter_value,
            jobDashboardColumns
        } = this.props.Job;

        const loggedInUser = window.loggedInUserDetails;
        const userAddJobDisable = CONSTANTS.USER_ADD_JOB_DISABLE.indexOf(loggedInUser.role) > -1;

        let columns = [
            {
                dataField: 'manageJobIcons',
                isDummyField: true,
                text: 'Action',
                hidden: NO_ALLOW_EDIT_JOB_TASK_CLIENT_ASSET_ROLE.indexOf(window.loggedInUserDetails.role) > -1,
                formatter: (cellContent, row) => {
                    return (
                        <span>

                            <IconButton
                                onClick={() => this.props.openClosePanel({
                                    ShowPanel: true,
                                    PanelHeaderText: `EDIT JOB (${row.jobNumber})`,
                                    SelectedJob: row
                                })}
                                iconProps={IconConstants.editicon} />

                        </span>
                    )
                }
            },
            {
                dataField: 'id',
                text: 'ID',
                // sort: true,
                // filter: textFilter(),
                hidden: origin !== "http://localhost:3000",
                title: (cell) => `${cell}`
            },
            {
                dataField: 'jobNumber',
                text: 'Job Number',
                // filter: textFilter(),
                hidden: jobDashboardColumns.indexOf('jobNumber') === -1,
                sort: true,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'client',
                text: 'Client',
                sort: true,
                hidden: jobDashboardColumns.indexOf('client') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'site',
                text: 'Site',
                sort: true,
                hidden: jobDashboardColumns.indexOf('site') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'inspector',
                text: 'Personnel',
                sort: true,
                hidden: jobDashboardColumns.indexOf('inspector') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'comments',
                text: 'Comments',
                sort: true,
                // filter: textFilter(),
                // title: (cell) => `${cell}`,
                hidden: jobDashboardColumns.indexOf('comments') === -1,
                formatter: (cellContent, row) => {
                    return (
                        cellContent ?
                            cellContent.length > 12 ?
                                <TooltipHost
                                    content={cellContent}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    styles={hostStyles}
                                >
                                    <div>{`${cellContent.substr(0, 12)}...`}</div>
                                </TooltipHost>
                                :
                                <span>{cellContent}</span>
                            :
                            <span></span>
                    )
                }
            },
            {
                dataField: 'startDate',
                text: 'Start Date',
                // filter: dateFilter(),
                sort: true,
                title: (cell) => `${cell}`,
                hidden: jobDashboardColumns.indexOf('startDate') === -1,
                formatter: (cellContent, row) => {
                    return (
                        cellContent ?
                            <span>{moment(cellContent).format("DD-MMM-YYYY")}</span> :
                            <span></span>
                    )
                }
            },
            {
                dataField: 'quotationNumber',
                text: 'Quote / WO',
                sort: true,
                hidden: jobDashboardColumns.indexOf('quotationNumber') === -1,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'status',
                text: 'Status',
                // filter: textFilter(),
                sort: true,
                // title: (cell) => `${cell}`,
                hidden: jobDashboardColumns.indexOf('status') === -1,
                formatter: (cellContent, row) => {
                    return (
                        cellContent ?
                            <TooltipHost
                                content={JOB_STATUS[cellContent]}
                                id={tooltipId}
                                calloutProps={calloutProps}
                                styles={columnHostStyles}
                            >
                                <StatusIconCustomCell cellContent={cellContent} />
                            </TooltipHost>
                            :
                            <span></span>
                    )

                },
            },
            {
                dataField: 'type',
                text: 'Type',
                // filter: textFilter(),
                sort: true,
                hidden: true,
                title: (cell) => `${cell}`,
            }
        ];

        let filteredJobs = ShowActiveJobs ?
            Jobs.filter(job => job.status === 1 || job.status === 2 || job.status === 4 || job.status === 8)
            :
            Jobs.filter(job => job.status === 16 || job.status === 64 || job.status === 1024)

        filteredJobs = CommonUtility.sortByColumn(filteredJobs, sortedColumn, sortOrder);

        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ActionButton
                                text="Reload"
                                disabled={LoadingDashboard}
                                iconProps={IconConstants.refreshIcon}
                                onClick={() => this.loadInitData()}
                            />
                            <ActionButton
                                onClick={() => this.props.openClosePanel({
                                    ShowPanel: true,
                                    PanelHeaderText: "ADD NEW JOB",
                                })}
                                text="Add"
                                iconProps={IconConstants.addIcon}
                                disabled={userAddJobDisable}
                            />
                            {HasMoreJobs && !LoadingDashboard &&
                                <ActionButton
                                    text="Load More"
                                    iconProps={IconConstants.moreicon}
                                    onClick={() => this.fetchMoreData()}
                                />
                            }
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                    <Dropdown
                                        options={JOB_STATUS_DROPDOWN_FILTER_OPTIONS}
                                        selectedKey={job_status_filter_value}
                                        styles={DropdownStyles}
                                        onChange={(ev, newVal) => {
                                            let filterJson = {
                                                "status": newVal.key,
                                                "jobNumber": job_number_filter_value,
                                                "client": job_client_filter_value,
                                                "site": job_site_filter_value,
                                                "quotationNumber": job_qn_filter_value,

                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "status",
                                                value: newVal.key,
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                    <SearchBox
                                        placeholder="Job Number"
                                        value={job_number_filter_value}
                                        onSearch={(newValue) => {
                                            let filterJson = {
                                                "status": job_status_filter_value,
                                                "jobNumber": newValue,
                                                "client": job_client_filter_value,
                                                "site": job_site_filter_value,
                                                "quotationNumber": job_qn_filter_value,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "jobNumber",
                                                value: newValue,
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                        onClear={() => {
                                            let filterJson = {
                                                "status": job_status_filter_value,
                                                "site": job_site_filter_value,
                                                "client": job_client_filter_value,
                                                "jobNumber": "",
                                                "quotationNumber": job_qn_filter_value,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "jobNumber",
                                                value: "",
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                        underlined
                                    />

                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                    <SearchBox
                                        placeholder="Client"
                                        value={job_client_filter_value}
                                        onSearch={(newValue) => {
                                            let filterJson = {
                                                "status": job_status_filter_value,
                                                "jobNumber": job_number_filter_value,
                                                "client": newValue,
                                                "site": job_site_filter_value,
                                                "quotationNumber": job_qn_filter_value,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "client",
                                                value: newValue,
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                        onClear={() => {
                                            let filterJson = {
                                                "status": job_status_filter_value,
                                                "site": job_site_filter_value,
                                                "client": "",
                                                "jobNumber": job_number_filter_value,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "client",
                                                value: "",
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                        underlined
                                    />

                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                    <SearchBox
                                        placeholder="Site"
                                        value={job_site_filter_value}
                                        onSearch={(newValue) => {
                                            let filterJson = {
                                                "status": job_status_filter_value,
                                                "jobNumber": job_number_filter_value,
                                                "client": job_client_filter_value,
                                                "site": newValue,
                                                "quotationNumber": job_qn_filter_value,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "site",
                                                value: newValue,
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                        onClear={() => {
                                            let filterJson = {
                                                "status": job_status_filter_value,
                                                "site": "",
                                                "client": job_client_filter_value,
                                                "jobNumber": job_number_filter_value,
                                                "quotationNumber": job_qn_filter_value,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "site",
                                                value: "",
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                        underlined
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <SearchBox
                                        placeholder="Quotation Number"
                                        value={job_qn_filter_value}
                                        onSearch={(newValue) => {
                                            let filterJson = {
                                                "status": job_status_filter_value,
                                                "jobNumber": job_number_filter_value,
                                                "client": job_client_filter_value,
                                                "site": job_site_filter_value,
                                                "quotationNumber": newValue,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "quotationNumber",
                                                value: newValue,
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                        onClear={() => {
                                            let filterJson = {
                                                "status": job_status_filter_value,
                                                "site": job_site_filter_value,
                                                "client": job_client_filter_value,
                                                "jobNumber": job_number_filter_value,
                                                "quotationNumber": "",
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.findFilteredJobsAsync({
                                                filterType: "quotationNumber",
                                                value: "",
                                                offset: 0,
                                                limit: this.props.Job.limit,
                                                apiObject: this._jobApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                        underlined
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1 inactive-toggle-col">
                            <Toggle
                                checked={ShowActiveJobs}
                                onText="Active"
                                offText="Inactive"
                                onChange={() => this.props.switchJobView({
                                    ShowActiveJobs: !ShowActiveJobs
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                        <LoadingOverlay
                            active={LoadingDashboard}
                            spinner={<FadeLoader />}
                            text="Loading Jobs...">

                            <div className="lazy-load-job-div" >
                                <RemoteAll
                                    data={filteredJobs}
                                    columns={columns}
                                    page={1}
                                    hideSizePerPage={true}
                                    onTableChange={this.handleTableChange}
                                />

                                {/* </InfiniteScroll> */}
                            </div >
                        </LoadingOverlay>
                    </div>
                </div>
                {
                    ShowPanel &&
                    <AddEditJobPanel
                        {...this.props}
                    />
                }
            </div >
        )
    }
}
