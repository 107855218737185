import * as CONSTANTS from "../Constants/TaskTypeConstants";
import { ALERT_MESSAGE } from "../../../../Constants/MessageConstants";
import axios from 'axios';

export class TaskTypeAPI {

    private _URL: string;
    private _jsessionid: string;

    constructor(URL, jsessionid) {
        this._URL = URL;
        this._jsessionid = jsessionid;
    }

    _getChunkTaskTypes(objectType: string, offset: number, limit: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_TASKTYPES_URL)
                .concat(`;jsessionid=${this._jsessionid}`)
                .concat(`?`)
                .concat(`objectType=${objectType}&`)
                .concat(`offset=${offset}&`)
                .concat(`limit=${limit}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getChunkTaskTypes method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    getAllFormAttributes(objectType: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_FORM_ATTRIBUTE_LIST)
                .concat(`;jsessionid=${this._jsessionid}`)
                .concat(`?`)
                .concat(`formobject=${objectType}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllFormAttributes method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    getAllSettingObjectsAPI(objectType: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_SETTING_OBJECTS)
                .concat(`;jsessionid=${this._jsessionid}`)
                .concat(`?`)
                .concat(`objectType=${objectType}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllSettingObjectsAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });

    }

    getAllAssetTypesAPI(objectType: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_ALL_SETTING_OBJECTS)
                .concat(`;jsessionid=${this._jsessionid}`)
                .concat(`?`)
                .concat(`objectType=${objectType}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllAssetTypesAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    getSettingObjects(objectType: string, division: string, assetType: string): Promise<any> {
        let promises = [this.getAllFormAttributes(objectType), this.getAllSettingObjectsAPI(division), this.getAllAssetTypesAPI(assetType)];
        return Promise.all(promises);
    }

    saveTaskTypeConfigData(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(CONSTANTS.GET_TASKTYPES_URL)
                .concat(`;jsessionid=${this._jsessionid}`), payload)
                .then((response) => {
                    resolve(true);
                }).catch((error) => {
                    console.error("Error saveFormConfigData", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    deleteTaskType(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(CONSTANTS.GET_TASKTYPES_URL)
                .concat(`/${payload.id}`)
                .concat(`;jsessionid=${this._jsessionid}`))
                .then((response) => {
                    resolve(response);
                }).catch((error) => {
                    console.error("Error deleteTaskType", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    getTaskTypeDetails(objectType: string, offset: number, limit: number): Promise<any> {
        let promises = [this._getChunkTaskTypes(objectType, offset, limit), this.getAllSettingObjectsAPI('AllDivisions')];
        return Promise.all(promises);
    }

}