import { ISequenceActionKeys } from "../Interfaces/ISequenceActions";
import { ISequencePayload } from "../Interfaces/ISequencePayload";
import * as CONSTANTS from "../Constants/SequenceNumberingConstants";
import { Dispatch } from "redux";
import { ISequenceStateProps } from "../Interfaces/ISequenceStateProps";

export class SequenceActions implements ISequenceStateProps {

    public cleanSequenceDashboard = (payload: ISequencePayload): ISequenceActionKeys => {
        return { type: CONSTANTS.CLEAN_SEQUENCE_NUMBERING_DASHBOARD, payload }
    }

    public loadSequenceDashboardAsync = (payload: ISequencePayload): ISequenceActionKeys => {
        return { type: CONSTANTS.LOAD_SEQUENCE_NUMBERING_DASHBOARD, payload }
    }

    public loadSequenceDashboard = (payload: ISequencePayload): any => {
        return (dispatch: Dispatch) => {
            payload.isLoading = true;
            dispatch(this.cleanSequenceDashboard(payload));
            payload.apiPayload.getSequences().then((response: any) => {
                payload.isLoading = false;
                payload.sequences = response.data;
                dispatch(this.loadSequenceDashboardAsync(payload));

            }).catch((error: any) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Clients"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    openCloseAlertModal = (payload: ISequencePayload): ISequenceActionKeys => {
        return { type: CONSTANTS.OPEN_CLOSE_SEQUENCE_ALERT_MODAL, payload };
    }

    public handleSequenceGridGroupSortChanged = (payload: ISequencePayload): ISequenceActionKeys => {
        return { type: CONSTANTS.HANDLE_SEQUENCE_NUMBERING_GRID_GROUP_SORT_CHANGE, payload }
    }

    public showHideAddEditSequenceNumberingPanel = (payload: ISequencePayload): ISequenceActionKeys => {
        return { type: CONSTANTS.SHOW_HIDE_SEQUENCE_NUMBERING_ADD_EDIT_PANEL, payload }
    }

    public changeSequenceNumber = (payload: ISequencePayload): ISequenceActionKeys => {
        return { type: CONSTANTS.CHANGE_SEQUENCE_NUMBER, payload }
    }

    public saveSequenceAsync = (payload: ISequencePayload): ISequenceActionKeys => {
        return { type: CONSTANTS.SAVE_SEQUENCE_NUMBER, payload }
    }

    public saveSequence = (payload: ISequencePayload): any => {
        return (dispatch: any) => {
            payload.isLoading = true;
            dispatch(this.saveSequenceAsync(payload));
            payload.apiPayload.saveSequence(payload.selectedSequence).then((response: any) => {
                payload.selectedSequence = response.data;
                payload.isLoading = false;
                dispatch(this.saveSequenceAsync(payload));
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: "Sequence Saved successfully"
                }
                dispatch(this.openCloseAlertModal(payload));
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    dispatch(this.cleanSequenceDashboard(payload));
                    dispatch(this.loadSequenceDashboard(payload));
                }, 1000);
            }).catch((error: string) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: error
                }
                payload.isLoading = false;
                dispatch(this.openCloseAlertModal(payload));
                dispatch(this.saveSequenceAsync(payload));
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2500);
            })
        }
    }

}
