import * as React from 'react';
import { DetailsList, IColumn, Icon, Link, Panel, PanelType, SelectionMode, TooltipHost } from 'office-ui-fabric-react';
import { IAssessmentStateProps } from '../Interfaces/IAssessmentStateProps';
import { ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH, ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH } from '../../Assets/Constants/AssetConstants';
import { calloutProps, IconConstants, tooltipId } from '../../../Constants/StylesConstants';
import { IAssessmentService } from '../Interfaces/IAssessmentsService';
import { AssessmentsService } from '../Services/AssessmentsAPI';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import moment from 'moment';
import { DATE_FORMAT } from '../../../Constants/MessageConstants';

export default class AssessmentHistoryPanel extends React.PureComponent<IAssessmentStateProps, {}> {

    private _assessmentAPI: IAssessmentService = null;

    constructor(props: IAssessmentStateProps) {
        super(props);
        this._assessmentAPI = new AssessmentsService(window.API_URL, window.loggedInUserDetails);
    }


    // public goToFirstPage = (): void => {

    //     let {
    //         selectedAssessment,
    //         assessmentReportSortedColumn,
    //         assessmentReportMaxDataPerPage
    //     } = this.props.AssessmentsState;

    //     this.props.loadAssessmentReportFile({
    //         apiPayload: this._assessmentAPI,
    //         selectedAssessment,
    //         assessmentReportSortedColumn,
    //         offset: 0,
    //         limit: assessmentReportMaxDataPerPage
    //     })
    // }

    // private _onColumnClick = (event, column): void => {
    //     let { assessmentReportSortedColumn } = this.props.AssessmentsState;
    //     let newAssessmentReportSortedColumn = {
    //         columnName: column.fieldName,
    //         sortDesc:
    //             assessmentReportSortedColumn.columnName !== column.fieldName ?
    //                 "asc"
    //                 :
    //                 assessmentReportSortedColumn.columnName === column.fieldName && assessmentReportSortedColumn.sortDesc === "asc" ?
    //                     "desc" : "asc"
    //     }
    //     this.props.loadAssessmentReportFile({
    //         apiPayload: this._assessmentAPI,
    //         selectedAssessment: this.props.AssessmentsState.selectedAssessment,
    //         assessmentReportSortedColumn: newAssessmentReportSortedColumn,
    //         offset: 0,
    //         limit: this.props.AssessmentsState.assessmentReportMaxDataPerPage
    //     })
    // }

    public render(): JSX.Element {

        const {
            selectedAssessment,
            isOpenAssessmentHistoryPanel,
        } = this.props.AssessmentsState;
        console.log("SelectedAssessment: ", selectedAssessment);

        const OBSERVATION_COLUMNS: IColumn[] = [
            {
                key: 'column1',
                name: 'Inspection Date',
                fieldName: 'actualInspectionDate',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH, maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true,
                onRender: (item: any) => {
                    return (
                        item ?
                            (
                                <span>{moment.utc(item).local().format(DATE_FORMAT)}</span>
                            )
                            :
                            <span></span>
                    );
                }
            },
            {
                key: 'column4',
                name: 'Cert / Report No.',
                fieldName: 'certificate',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true,
            },
            {
                key: 'column5',
                name: 'Result',
                fieldName: 'result',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true,
                onRender: (item: any) => {
                    return (
                        item ?
                            (
                                <span>{item.result === "1" ? "PASS" : item.result === "2" ? "FAIL" : ""}</span>
                            )
                            :
                            <span></span>
                    );
                }
            },
            {
                key: 'column6',
                name: 'Cert / Report',
                fieldName: 'certificateFile',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true,
                onRender: (item:any) => {
                    const itemJson = item.certificateFile ? JSON.parse(item.certificateFile) : null;
                    const blobKey = itemJson.blobKey;
                    let fileURL = blobKey ? window.API_URL + 'filedownload;jssessionid=' + window.loggedInUserDetails.jsessionid
                        + '?observationId=' + selectedAssessment.id + "&id=" + blobKey : "";
                    return (
                        blobKey ?
                            (
                                <TooltipHost
                                    content={`Click here to open certificate`}
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    styles={{
                                        root:
                                        {
                                            display: 'inline-block',

                                        }
                                    }}
                                >

                                    <Link target='_blank' href={fileURL}>
                                        <Icon
                                            iconName={IconConstants.pdfIcon.iconName}
                                            styles={{
                                                root: {
                                                    color: "#F40F02"
                                                }
                                            }}
                                        />
                                    </Link>
                                </TooltipHost>
                            )
                            :
                            <span></span>
                    );
                }
            },
            {
                key: 'column9',
                name: 'Next Due Date',
                fieldName: 'dueDate',
                minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
                maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
                isResizable: true,
                onRender: (item: any) => {
                    return (
                        item ?
                            (
                                <span>{moment.utc(item).local().format(DATE_FORMAT)}</span>
                            )
                            :
                            <span></span>
                    );
                }
            }
        ];


        return (
            <React.Fragment>
                <Panel
                    isOpen={isOpenAssessmentHistoryPanel}
                    onDismiss={() => {
                        this.props.openCloseObservationHistoryPanel({
                            isOpenAssessmentHistoryPanel: false,
                            selectedAssessment: null
                        })
                    }}
                    headerText={`Assessment History`}
                    type={PanelType.extraLarge}
                    styles={{
                        content: {
                            height: "40em"
                        },
                        scrollableContent: {
                            height: "92vh"
                        }
                    }}
                >

                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg10">
                                <DetailsList
                                    items={[selectedAssessment]}
                                    columns={CommonUtility.sortByColumn(OBSERVATION_COLUMNS, "key", "asc")}
                                    selectionMode={SelectionMode.none}
                                />
                            </div>
                        </div>
                    </div>
                </Panel >
            </React.Fragment >
        )
    }
}

