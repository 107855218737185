import * as React from 'react';
import { DatePicker as UIDatePicker, Icon, Label, PrimaryButton, Separator, Shimmer, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react';
import { IconConstants, seperatorTheme } from '../../../Constants/StylesConstants';
import { TaskAPI } from '../Services/TaskAPI';
import AlertRedux from '../../Alert/AlertRedux';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment-timezone';
import * as COMMON_CONSTANTS from './../../../Constants/CommonConstants';
import { TaskUtility } from '../Utility/TaskUtility';
import { JobAPI } from '../../Jobs/Services/JobAPI';


export default class JobDetails extends React.PureComponent<any, any> {

    private _taskApi: TaskAPI;
    private _jobAPi: JobAPI;

    public constructor(props: any) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);
        this._jobAPi = new JobAPI(window.API_URL);
    }

    public onDropJobFiles = (event: any): void => {

        let files = event.target.files;
        let { SelectedTask } = this.props.Task;

        if (files.length > 0) {
            const uploadedFiles = new FormData();
            for (let index = 0; index < files.length; index++) {
                uploadedFiles.append(`scheduleFile`, files[index], files[index].name);
            }

            this.props.uploadJobFiles({
                apiObject: this._jobAPi,
                uploadedFiles,
                scheduleId: SelectedTask.scheduleId
            });
        }
    }

    public render(): JSX.Element {

        const {
            SelectedTask,
            isLoadingJobDetailsTab,
            alertJSON,
            showTimeClock,
            isLoadingJobFiles,
            uploadFilesErrorText
        } = this.props.Task;

        // let timerStr: string = moment(new Date()).format('HH:mm');
        let timerStr: string = "";
        if (SelectedTask.actualInspectionDate) {
            timerStr = moment(new Date(SelectedTask.actualInspectionDate)).format('HH:mm');
        }

        let isSaveButtonDisabled: boolean =
            SelectedTask.status !== 8 ||
            !SelectedTask.actualInspectionDate ||
            !SelectedTask.sitePin ||
            !SelectedTask.site;

        const scheduleFiles = SelectedTask.otherFiles;
        const parsedScheduleFiles = scheduleFiles ? JSON.parse(scheduleFiles) : [];
        const loggedInUser = window.loggedInUserDetails;

        let taskUtility: TaskUtility = new TaskUtility();

        return (
            <React.Fragment>
                {isLoadingJobDetailsTab ?
                    <div className="login-spinner-div">
                        <Spinner
                            size={SpinnerSize.large}
                            label="Please wait..."
                        />
                    </div>
                    :
                    <React.Fragment>

                        <div className="ms-Grid" dir="ltr">


                            <Separator theme={seperatorTheme}>Site Details</Separator>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        label="Site Name"
                                        value={SelectedTask.site}
                                        onChange={(ev: any, newval: string) => {
                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "site",
                                                value: newval
                                            });
                                        }}
                                        required
                                        errorMessage={SelectedTask.site ? "" : "Site Name is Required"}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        label="Site House No. &amp; Street"
                                        value={SelectedTask.siteStreet}
                                        onChange={(ev: any, newval: string) => {
                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "siteStreet",
                                                value: newval
                                            });
                                        }}
                                        errorMessage={SelectedTask.siteStreet ? "" : "Street is Required"}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        label="Site City"
                                        value={SelectedTask.siteCity}
                                        onChange={(ev: any, newval: string) => {
                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "siteCity",
                                                value: newval
                                            });
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        label="Site State"
                                        value={SelectedTask.siteState}
                                        onChange={(ev: any, newval: string) => {
                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "siteState",
                                                value: newval
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        label="Site Country"
                                        value={SelectedTask.siteCountry}
                                        onChange={(ev: any, newval: string) => {
                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "siteCountry",
                                                value: newval
                                            });
                                        }}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        label="Site Pincode"
                                        value={SelectedTask.sitePin}
                                        type="number"
                                        onChange={(ev: any, newval: string) => {
                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "sitePin",
                                                value: newval
                                            });
                                        }}
                                        errorMessage={SelectedTask.sitePin ? "" : "Pincode is Required"}
                                        required
                                    />
                                </div>
                            </div>

                            <Separator theme={seperatorTheme}>Vendor Details</Separator>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        label="Vendor Details"
                                        multiline
                                        value={SelectedTask.vendorDetails}
                                        onChange={(ev: any, newval: string) => {
                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "vendorDetails",
                                                value: newval
                                            });
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        label="Sub Vendor Details"
                                        multiline
                                        value={SelectedTask.subVendorDetails}
                                        onChange={(ev: any, newval: string) => {
                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "subVendorDetails",
                                                value: newval
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            
                            <Separator theme={seperatorTheme}>Job Details</Separator>
                            
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                    <UIDatePicker
                                        label={`Actual Inspection Date`}
                                        allowTextInput={false}
                                        value={SelectedTask.actualInspectionDate ? new Date(SelectedTask.actualInspectionDate) : null}
                                        firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                                        strings={COMMON_CONSTANTS.DayPickerStrings}
                                        placeholder="Select a date..."
                                        ariaLabel="Select a date"
                                        formatDate={(date: Date) => taskUtility.onFormatDate(date)}
                                        onSelectDate={(date: Date) => {
                                            let dateStr: string =
                                                moment(date).format('YYYY-MM-DD');

                                            let selectedDatetime = moment(dateStr + " " + timerStr);

                                            this.props.onChangeSelectedTaskJobDetailsTab({
                                                field: "actualInspectionDate",
                                                value: new Date(selectedDatetime.format())
                                            });
                                        }}
                                        isRequired
                                    />

                                    {!SelectedTask.actualInspectionDate &&
                                        <Label>
                                            <span className="mandatory-span">Actual Inspection Date is required</span>
                                        </Label>
                                    }

                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                    <Label>Actual Inspection Time <span className="mandatory-span">*</span></Label>
                                    {!showTimeClock ?
                                        <React.Fragment>
                                            <TextField
                                                readOnly
                                                value={timerStr}
                                                onClick={() => {
                                                    this.props.showHideTimeClock({
                                                        showTimeClock: !showTimeClock
                                                    })
                                                }}
                                                iconProps={IconConstants.timePickerIcon}
                                                styles={{
                                                    field: {
                                                        cursor: "pointer",
                                                    }
                                                }}
                                            />
                                            {!SelectedTask.actualInspectionDate &&
                                                <Label>
                                                    <span className="mandatory-span">Actual Inspection Time is required</span>
                                                </Label>
                                            }
                                        </React.Fragment>
                                        :
                                        <TimeKeeper
                                            time={timerStr}
                                            // switchToMinuteOnHourSelect={true}
                                            hour24Mode={true}
                                            // closeOnMinuteSelect={true}
                                            onDoneClick={() => {
                                                this.props.showHideTimeClock({
                                                    showTimeClock: !showTimeClock
                                                })

                                            }}
                                            onChange={(newTime: any) => {
                                                let dateStr: string =
                                                    moment(SelectedTask.actualInspectionDate).format('YYYY-MM-DD');

                                                let selectedDatetime = moment(dateStr + " " + newTime.formatted12);

                                                this.props.onChangeSelectedTaskJobDetailsTab({
                                                    field: "actualInspectionDate",
                                                    value: new Date(selectedDatetime.format())
                                                });
                                            }}
                                        />
                                    }
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6"></div>
                            </div>
                            
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Label>Job Files</Label>

                                    {isLoadingJobFiles ?
                                        <Shimmer
                                            styles={{
                                                root: {
                                                    width: "10em"
                                                },
                                                shimmerWrapper: {
                                                    height: "10em",
                                                }
                                            }} />
                                        :
                                        <>

                                            <div>
                                                {parsedScheduleFiles?.map((file: any, index: number) => {
                                                    return (
                                                        <div className='file-list-view' key={index} onClick={() => {
                                                            window.open(
                                                                `${window.API_URL}filedownload;jssessionid='+${loggedInUser.jsessionid}'?id=${file.blobKey}`,
                                                                "_blank"
                                                            );
                                                        }}>
                                                            <Icon iconName={file.fileType.toUpperCase() === "PDF" ?
                                                                IconConstants.pdfIcon.iconName : IconConstants.imageIcon.iconName}></Icon> {file.fileName}
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            <input
                                                type="file"
                                                multiple
                                                onChange={this.onDropJobFiles}
                                                accept="image/png, image/jpeg, application/pdf,.doc,.docx,.xls,.xlsx,.csv"
                                            />
                                        </>
                                    }
                                    <span className="info-span">
                                        {uploadFilesErrorText}
                                    </span>
                                </div>
                            </div>

                            <br />


                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <PrimaryButton
                                        text="Save"
                                        disabled={isSaveButtonDisabled}
                                        menuIconProps={IconConstants.saveicon}
                                        onClick={() => this.props.onSaveSelectedTaskJobDetailsTab({
                                            SelectedTask: this.props.Task.SelectedTask,
                                            apiObject: this._taskApi
                                        })}
                                    />
                                </div>
                            </div>

                        </div>
                    </React.Fragment>
                }

                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </React.Fragment>
        )
    }
}
