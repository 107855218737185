export const FormObjectOptions = [
    { value: 'ASSET TYPE', label: 'ASSET TYPE' },
    { value: 'TASK TYPE', label: 'TASK TYPE' },
    { value: 'OBSERVATIONS', label: 'OBSERVATIONS' },
    { value: 'LLG', label: 'LLG' },
    { value: 'MISC', label: 'MISC' },
    { value: 'SERVICE REPORT', label: 'SERVICE REPORT' },
    { value: 'JOB', label: 'JOB', isdisabled: true },
    { value: 'LOCATION', label: 'LOCATION', isdisabled: true },
    { value: 'USER', label: 'USER', isdisabled: true },
    { value: 'MATERIAL', label: 'MATERIAL', isdisabled: true }
]

export const FormTypeOptions = [
    { value: 'FORM', label: 'FORM' },
    { value: 'MULTIPLE', label: 'MULTIPLE' },
    { value: 'CHECKPOINT', label: 'CHECKPOINT' },
    { value: 'NC', label: 'NC' }
]

export const FORM_BREADCRUMBS = [
    { text: 'SETTINGS', key: 'Settings' },
    { text: 'Data Forms', key: 'Data Forms' }
];

