import * as React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { ITrialFitPicLibraryAPI } from '../Interfaces/ITrialFitPicLibraryAPI';
import { TrialFitPicLibraryAPI } from '../Service/TrialFitPicLibraryAPI';
import { IDocumentEntity } from '../Interfaces/IDocumentEntity';
import { IconConstants } from '../../../../Constants/StylesConstants';
import { TrialFitPicLibraryUtility } from '../Utility/TrialFitPicLibraryUtility';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class EntityDeleteConfirmModal extends React.PureComponent<any, any> {


    private _apiPayload: ITrialFitPicLibraryAPI = null;
    constructor(props) {
        super(props);
        this._apiPayload = new TrialFitPicLibraryAPI(window.API_URL, window.loggedInUserDetails);
    }

    public componentDidMount() {
    }

    public deleteEntity = (): void => {
        let {
            deletedEntity,
            documentEntities,
            fileEntities
        } = this.props.TrialFitPicLibraryState;
        let selectedDocument: IDocumentEntity =
            documentEntities.find((doc: IDocumentEntity) => `${doc.id}` === deletedEntity.data.key);
        if (!selectedDocument) {
            selectedDocument = fileEntities.find((doc: IDocumentEntity) => `${doc.id}` === deletedEntity.data.key);
        }

        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;

        this.props.deleteFile({
            apiObject: this._apiPayload,
            documentId: selectedDocument.id,
            clientId: selectedClient.value,
            siteId: selectedSite.value,
            libraryName: TrialFitPicLibraryUtility.getLibraryName(window.location.hash.split("/")[2])
        })
    }


    public render(): JSX.Element {
        let { hideConfirmDelete,
            deletedEntity,
        } = this.props.TrialFitPicLibraryState;
        const headerSubtext: string = deletedEntity ? deletedEntity.data.name ?
            `Are you sure want to delete ${deletedEntity?.data?.name}? All files under this folder will be deleted `
            :
            deletedEntity.data.fileName ? `Are you sure want to delete ${deletedEntity?.data?.fileName}?` : `` : ``;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: headerSubtext
        };
        return (
            <Dialog
                hidden={hideConfirmDelete}
                onDismiss={() => this.props.openCloseEntityDeleteConfirmModal({
                    hideConfirm: true,
                    deletedEntity: deletedEntity
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => this.deleteEntity()}
                        text="Yes"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseEntityDeleteConfirmModal({
                            hideConfirm: true,
                            deletedEntity: deletedEntity
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
