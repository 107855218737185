import { ReportInitialState } from "../Constants/ReportsInitialState";
import * as CONSTANTS from "../Constants/ReportsConstants";
import * as TASK_CONSTANTS from "./../../Tasks/Constants/TaskConstants";
import { IReportsInitialState } from "../Interfaces/IReportsInitialState";
import { IReportsActionKeys } from "../Interfaces/IReportsActions";
import { IReportsPayload } from "../Interfaces/IReportsPayload";
import { ReportsUtility } from "../Utility/ReportsUtility";

export function ReportsReducer(state: IReportsInitialState = ReportInitialState, action: IReportsActionKeys): IReportsInitialState {

    let tempState: IReportsInitialState = { ...state };
    let payload: IReportsPayload = action.payload;
    let utility: ReportsUtility = new ReportsUtility();
    let newReportGridDataState = { ...tempState.reportGridDataState };

    switch (action.type) {

        case CONSTANTS.SHOW_LOADER_DASHBOARD:
            tempState.isLoadingReports = payload.isLoading;
            break;

        case CONSTANTS.CLEAN_REPORT_DASHBOARD:
            tempState.isLoadingReports = payload.isLoading;
            tempState.reportGridDataState = {
                sort: [{ field: "name", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                skip: 0
            };
            tempState.reportType = "TASK";
            tempState.reports = [];
            tempState.offset = 0;
            tempState.hasMoreReports = true;
            break;


        case CONSTANTS.LOAD_REPORT_DASHBOARD:
            tempState.reports = (payload.offset > 0) ? ([...tempState.reports, ...payload.reports]) : [...payload.reports];
            tempState.hasMoreReports = payload.reports.length === ReportInitialState.limit;
            tempState.proccessedReports = utility.processReportsForKendoGrid([...tempState.reports],
                {
                    sort: newReportGridDataState.sort,
                    filter: { filters: [], logic: 'and' },
                    skip: 0
                });
            tempState.isLoadingReports = payload.isLoading;
            tempState.offset = payload.offset + ReportInitialState.limit;
            break;

        case CONSTANTS.LOAD_REPORT_CERTIFICATE:
            break;

        case CONSTANTS.SHOW_HIDE_REPORT_CERTIFICATE_PANEL:
            tempState.isHideCertificatePanel = payload.isHideCertificatePanel;
            tempState.selectedTaskId = payload.selectedTaskId;
            break;

        case CONSTANTS.SWITCH_TASK_AND_SERVICE_REPORT:
            tempState.reportType = payload.reportType;
            break;

        case CONSTANTS.LOAD_TASK_DETAILS:
            tempState.isLoadingCertificatePanel = payload.isLoading;
            if (!payload.isLoading) {

                if (payload.task.certificateFile) {
                    tempState.PDFURL = `${window.API_URL}filedownload;jsessionid=${window.loggedInUserDetails.jsessionid}?observationId=${payload.task.id}&id=${payload.task.certificateFile.blobKey}`;
                }
                else {
                    tempState.PDFURL = `${window.API_URL}${TASK_CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.task.id}`;
                }

            }
            else {
                tempState.PDFURL = "";
            }
            break;

        case CONSTANTS.HANDLE_REPORT_GRID_GROUP_SORT_CHANGE:
            newReportGridDataState.sort = payload.event.dataState.sort;
            let indexOfId = -1;
            indexOfId = payload.event.dataState.group?.findIndex((d: any) => d.field === "id");
            if (indexOfId > -1) {
                payload.event.dataState.group.splice(indexOfId, 1);
            }
            newReportGridDataState.group = payload.event.dataState.group;
            tempState.reportGridDataState = newReportGridDataState;
            tempState.proccessedReports = utility.processReportsForKendoGrid(tempState.reports, {
                sort: newReportGridDataState.sort,
                filter: { filters: [], logic: 'and' },
                skip: 0
            });
            break;

        case CONSTANTS.HANDLE_REPORT_GRID_EXPAND_CHANGE:
            payload.event.dataItem[payload.event.target.props.expandField] = payload.event.value;
            tempState.reportGridDataState = newReportGridDataState;
            break;

        case CONSTANTS.HANDLE_REPORT_GRID_FILTER_CHANGE:
            let reportGridDataFilter = payload.event.filter ? payload.event.filter : { filters: [], logic: 'and' };
            newReportGridDataState.filter = reportGridDataFilter;
            tempState.reportGridDataState = newReportGridDataState;
            tempState.proccessedReports = [];
            tempState.reports = [];
            tempState.isLoadingReports = true;
            break;

        default:
            break;
    }

    return tempState;
}