import React from 'react';
import { calloutProps, hostStyles, IconConstants, tooltipId } from '../../Constants/StylesConstants';
import { DetailsList,Icon,SelectionMode,Link,TooltipHost,Panel} from 'office-ui-fabric-react';
import { ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH, ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH } from '../Assets/Constants/AssetConstants';
import { CommonUtility } from '../../CommonUtility/CommonUtility';

class CustomPanel extends React.Component {
    
  render() {
const observations =this.props.observations;
console.log(this.props.observations);
    
    const OBSERVATION_COLUMNS = [
        {
            key: 'column1',
            name: 'Inspection Date',
            fieldName: 'actualInspectionDate',
            minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
            maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
            isResizable: true,
            onRender: (item) => {
                return (
                    <span>
                        {CommonUtility.formatDateToReadableString(item.actualInspectionDate)}
                    </span>
                );
            }
        },{
            key: 'column2',
            onColumnClick: this._onColumnClick,
            name: 'Cert / Report Date',
            fieldName: 'certificateDate',
            minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
            maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH, isResizable: true,
            onRender: (item) => {
                return (
                    <span>
                        {CommonUtility.formatDateToReadableString(item.certificateDate)}
                    </span>
                );
            }
        },
        {
            key: 'column3',
            name: 'Cert / Report No.',
            fieldName: 'certificateNumber',
            minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
            maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
            isResizable: true
        },
        {
            key: 'column4',
            name: 'Result',
            fieldName: 'result',
            minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
            maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
            onRender: (item) => {
                return (
                    item ?
                        (
                            <span>{item.result === 1 ? "PASS" : item.result === 2 ? "FAIL" : ""}</span>
                        )
                        :
                        <span></span>
                );
            }
        },
        {
            key: 'column5',
            name: 'Cert / Report',
            fieldName: 'certificateFile',
            minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
            maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
            
            onRender: (item) => {
                // Check if the certificateLink exists in the item
                console.log(item);
                if (item.certificateFileContent) {
                    // Construct the file URL using the certificateLink
                    const certificateFileContent = JSON.parse(item.certificateFileContent);
                
                    // Extract the blobKey
                    const blobKey = certificateFileContent.blobKey;
                    let fileURL = `${window.API_URL}filedownload;jssessionid=${window.loggedInUserDetails.jsessionid}?observationId=${item.id}&id=${blobKey}`;
    
                    // Debugging log to check the generated URL and item data
                    console.log('Generated file URL: ', fileURL);
                    console.log('Item data: ', item);
    
                    // Return the link with a tooltip and icon
                    return (
                        <TooltipHost
                            content={`Click here to open certificate`}
                            id={tooltipId}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            <Link target='_blank' href={fileURL}>
                                <Icon
                                    iconName={IconConstants.pdfIcon.iconName}
                                    styles={{
                                        root: {
                                            color: "#F40F02"
                                        }
                                    }}
                                />
                            </Link>
                        </TooltipHost>
                    );
                } else {
                    // Return an empty span if no certificateLink is available
                    return <span>No Certificate Available</span>;
                }
            }
        },
        {
            key: 'column6',
            name: 'Next Due Date',
            fieldName: 'dueDate',
            minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
            maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
            onRender: (item) => {
                return (
                    <span>
                        {CommonUtility.formatDateToReadableString(item.dueDate)}
                    </span>
                );
            }
        }
    ];
   
    return (
      <Panel
        isOpen={this.props.showPopup}
        headerText="Asset History"
        closeButtonAriaLabel="Close"
        onDismiss={() => {
          this.props.onClose();
        }}
        styles={{
            main: { width: '1100px !important' },
            content: { padding: '20px' }, // Adjust padding as needed
          }}
      >
        <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row"></div>
      {/*  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
          <Slider
            min={1}
            max={50}
            step={1}
            defaultValue={20}
            showValue={true}
            onChange={(value) => {
                this.props.changeLimitOfReportPerPage({
                    limit: value
                })
            }} 
          />
        </div>*/}
        <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DetailsList
                                        items={observations}
                                        columns={CommonUtility.sortByColumn(OBSERVATION_COLUMNS, "key", "asc")}
                                        selectionMode={SelectionMode.none}
                                    />
                                </div>
                            </div>
                            </div>
      </Panel>
    );
  }
}

export default CustomPanel;
