import { IButtonStyles, INavStyles, IToggleStyleProps, IToggleStyles } from "office-ui-fabric-react";
import { mergeStyleSets, getTheme, DefaultFontStyles, FontSizes, getFocusStyle } from 'office-ui-fabric-react/lib/Styling';

export const navStylesFiles: Partial<INavStyles> = {
  root: {
    boxSizing: 'border-box',
    borderRight: '1px solid #eee',
    height: 'auto',
    overflowY: 'auto',
  },
  navItem: {
    height: "auto",
    "&:hover": {
      height: "auto",
    },
  },
};

export const customToggleStyles = (props: IToggleStyleProps): Partial<IToggleStyles> => ({
  ...(props.checked && {
    pill: {
      backgroundColor: "#65d36e",
      selectors: {
        "&:hover": {
          backgroundColor: "#29a133",
        },
      },
    },
  }),
});

export type IExampleItem = { name: string; thumbnail: string };

export interface IListGhostingExampleProps {
  items: IExampleItem[];
}

export const theme = getTheme();
export const classNames = mergeStyleSets({
  container: {
    overflow: 'auto',
    maxHeight: 500
  },
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 40,
      padding: 2.5,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
      display: 'flex',
      selectors: {
        '&:hover': { background: theme.palette.neutralLight }
      }
    }
  ],
  itemImage: {
    flexShrink: 0
  },
  itemContent: {
    marginLeft: 10,
    overflow: 'hidden',
    flexGrow: 1
  },
  itemName: [
    DefaultFontStyles.medium,
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  ],
  itemIndex: {
    fontSize: FontSizes.small,
    color: theme.palette.neutralTertiary,
    marginBottom: 2.5
  },
  chevron: {
    alignSelf: 'center',
    marginLeft: 2.5,
    color: theme.palette.neutralTertiary,
    fontSize: FontSizes.large,
    flexShrink: 0
  }
});

export const DELETE_ICON_STYLES: IButtonStyles = {
  root: {
    color: "#f44",
  }
}