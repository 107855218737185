import React, { Component } from 'react'
import { ClientAPI } from '../Services/ClientAPI';
import { TextField, Label, DialogType, PrimaryButton, DefaultButton, Dialog, DialogFooter, Separator, Spinner, SpinnerSize, ActionButton, Toggle } from 'office-ui-fabric-react';
import { IconConstants, seperatorTheme } from '../../../Constants/StylesConstants';
import Select from 'react-select';
import { ClientUtility } from '../Utility/ClientUtility';

const modalProps = {
    isBlocking: false,
    styles: {
        main: {
            width: 750
        }
    },
    dragOptions: true
};


export default class AddClientPopUp extends Component {

    _clientApi = null;
    constructor(props) {
        super(props);
        this._clientApi = new ClientAPI(window.API_URL, window.loggedInUserDetails);

    }

    componentDidMount() {
        this.props.loadAddClientPopUpAsync({
            apiObject: this._clientApi
        });
    }

    render() {

        let HideAddClientPopUp = true;
        let UserRoleOptions = [];
        let LoadingAddClientPopUp = true;
        let Users = [];
        let AddClientDetails = null;
        let AddSiteDetails = null;
        let isSiteAddressSameAsClientAddress = false;
        let LoOffices = [];

        if (this.props.Client) {
            HideAddClientPopUp = this.props.Client.HideAddClientPopUp;
            UserRoleOptions = this.props.Client.UserRoleOptions;
            LoadingAddClientPopUp = this.props.Client.LoadingAddClientPopUp;
            Users = this.props.Client.Users;
            AddClientDetails = this.props.Client.AddClientDetails;
            AddSiteDetails = this.props.Client.AddSiteDetails;
            isSiteAddressSameAsClientAddress = this.props.Client.isSiteAddressSameAsClientAddress;
            LoOffices = this.props.Client.LoOffices;
        }

        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Add New Client",
        };

        let isInvalidClientForm = ClientUtility.isInvalidClientForm(AddClientDetails, AddSiteDetails, Users);

        let selectedOffice = !AddClientDetails.officeIds ? null :
            AddClientDetails.officeIds?.split(",").map((office) => {
                let currentOffice = LoOffices.find((loOffice) =>
                    loOffice.value.toString() === office);
                return {
                    label: currentOffice?.label,
                    value: currentOffice?.value,
                }
            })

        return (
            <Dialog
                hidden={HideAddClientPopUp}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                onDismiss={() => this.props.showHideClientAddClientPopUp({
                    HideAddClientPopUp: true
                })}
                className="fullwidthModal"
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ActionButton
                                text="Save"
                                disabled={isInvalidClientForm}
                                iconProps={IconConstants.saveicon}
                                onClick={() => this.props.saveNewClientAsync({
                                    Users,
                                    AddClientDetails,
                                    AddSiteDetails,
                                    apiObject: this._clientApi
                                })}
                            />
                            <ActionButton
                                text="Cancel"
                                iconProps={IconConstants.cancelIcon}
                                onClick={() => this.props.showHideClientAddClientPopUp({
                                    HideAddClientPopUp: true,
                                })}
                            />
                        </div>
                    </div>
                </div>

                {LoadingAddClientPopUp ?
                    <Spinner
                        label="Please wait..."
                        size={SpinnerSize.large}
                    />
                    :
                    <div className="ms-Grid" dir="ltr">

                        <Separator theme={seperatorTheme}>ADD CLIENT DETAILS</Separator>
                        {/* CLIENT DETAILS */}
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <TextField
                                    autoFocus
                                    label="Client Name:"
                                    required
                                    value={AddClientDetails.name}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientName",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <Label>LO Office <span className="mandatory-span">*</span></Label>
                                <Select
                                    value={selectedOffice}
                                    options={LoOffices}
                                    isMulti={true}
                                    onChange={(newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientOfficeId",
                                            clientOfficeIdValue: newVal
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <Label className="client-pop-up-label">Client Address: <span className="mandatory-span">*</span></Label>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    label="House No. &amp; Street:"
                                    required
                                    value={AddClientDetails.street}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientStreet",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddClientDetails.city}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientCity",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="City:"
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddClientDetails.state}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientState",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="State:"
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddClientDetails.country}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientCountry",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Country:"
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddClientDetails.pin}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientPin",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Pin:"
                                    type="number"
                                    min={1}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddClientDetails.phone}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientPhone",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Phone:"
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <TextField
                                    value={AddClientDetails.acronymCode}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientCode",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Client Code:"
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <TextField
                                    value={AddClientDetails.externalId}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "ClientExternalId",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="External Id:"
                                />
                            </div>
                        </div>
                        <br />
                        {/* SITE DETAILS */}
                        <Separator theme={seperatorTheme}>ADD SITE DETAILS</Separator>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    value={AddSiteDetails.name}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "SiteName",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Site Name:"
                                    required
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                                <Label>
                                    Site Address:
                                </Label>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg11 add-client-toggle">
                                <Toggle
                                    onText="Reset Site Address"
                                    offText="Copy Client Address"
                                    checked={isSiteAddressSameAsClientAddress}
                                    onChange={(ev, checked) => {
                                        this.props.populateSiteAddressFromClient({
                                            isSiteAddressSameAsClientAddress: checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddSiteDetails.street}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "SiteStreet",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="No. &amp; Street:"
                                //required
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddSiteDetails.city}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "SiteCity",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="City:"
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddSiteDetails.state}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "SiteState",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="State:"
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddSiteDetails.country}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "SiteCountry",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Country:"
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={AddSiteDetails.pin}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "SitePin",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Pin:"
                                    type="number"
                                    min={1}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                <TextField
                                    value={`${AddSiteDetails.phone ? AddSiteDetails.phone : ""}`}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "SitePhone",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Phone:"
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    value={`${AddSiteDetails.acronymCode ? AddSiteDetails.acronymCode : ""}`}
                                    onChange={(ev, newVal) => {
                                        this.props.changeClientSiteDetails({
                                            clientSiteFieldType: "SiteCode",
                                            clientSiteFieldValue: newVal
                                        })
                                    }}
                                    label="Site Code:"
                                />
                            </div>
                        </div>
                        <br />
                        <Separator theme={seperatorTheme}>ADD USER DETAILS</Separator>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                <Label>Type <span className="mandatory-span">*</span></Label>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                <Label>Name <span className="mandatory-span">*</span></Label>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                <Label>Email <span className="mandatory-span">*</span></Label>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                <Label>Phone</Label>
                            </div>
                        </div>
                        {Users.map((user, index) => {
                            return (
                                <div className="ms-Grid-row add-client-user-row" key={index}>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                        <Select
                                            value={user.role}
                                            options={UserRoleOptions}
                                            onChange={(newVal) => {
                                                this.props.changeAddUserDetails({
                                                    clientSiteFieldType: "role",
                                                    userIndex: index,
                                                    AddUserDetails: {
                                                        role: newVal,
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                        <TextField
                                            value={user.name}
                                            onChange={(ev, newVal) => {
                                                this.props.changeAddUserDetails({
                                                    clientSiteFieldType: "name",
                                                    userIndex: index,
                                                    AddUserDetails: {
                                                        name: newVal
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                        <TextField
                                            value={user.email}
                                            onChange={(ev, newVal) => {
                                                this.props.changeAddUserDetails({
                                                    clientSiteFieldType: "email",
                                                    userIndex: index,
                                                    AddUserDetails: {
                                                        email: newVal
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                        <TextField
                                            value={user.phone}
                                            onChange={(ev, newVal) => {
                                                this.props.changeAddUserDetails({
                                                    clientSiteFieldType: "phone",
                                                    userIndex: index,
                                                    AddUserDetails: {
                                                        phone: newVal
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>

                            )
                        })}

                        <ActionButton
                            text="Add New User"
                            iconProps={IconConstants.addIcon}
                            onClick={() => {
                                this.props.addOrRemoveUser({
                                    addFlag: true
                                });
                            }}
                        />
                        <ActionButton
                            text="Remove Last User"
                            disabled={Users.length === 0}
                            iconProps={IconConstants.removeIcon}
                            onClick={() => {
                                this.props.addOrRemoveUser({
                                    addFlag: false
                                });
                            }}
                        />
                    </div>
                }
                <DialogFooter>
                    <PrimaryButton
                        text="Save"
                        disabled={isInvalidClientForm}
                        menuIconProps={IconConstants.saveicon}
                        onClick={() => this.props.saveNewClientAsync({
                            Users,
                            AddClientDetails,
                            AddSiteDetails,
                            apiObject: this._clientApi
                        })}
                    />
                    <DefaultButton
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                        onClick={() => this.props.showHideClientAddClientPopUp({
                            HideAddClientPopUp: true,
                        })}
                    />
                </DialogFooter>

            </Dialog>

        )
    }
}
