import { CurrencyOptions } from "../Constants/JobConstants";
import { CommonUtility } from "../../../CommonUtility/CommonUtility";
import moment from 'moment-timezone';

export class JobUtility {

    setUserOptions = (Users) => {
        let UserOptions = [];

        Users.forEach((item) => {
            let UserOption = {
                value: item.id,
                label: item.name,
                type: item.type,
                details: `${item.email}, ${item.phone}`,
                companyId: item.companyId,
                officeId: item.officeId,
                secondaryOfficeIds: item.secondaryOfficeIds,
                siteIds: item.siteIds,
                divisionIds: item.divisionIds,
                roleId: item.roleId,
                designation: item.designation
            }
            UserOptions.push(UserOption);
        });

        return UserOptions;
    }

    setInspetorOptions = (Users) => {
        let InspectorOptions = [];

        Users.forEach((item) => {
            let UserOption = {
                value: item.id,
                label: `${item.name} ${item.division ? "(" + item.division + ")" : ""}`,
                type: item.type,
                details: `${item.email}, ${item.phone}`,
                companyId: item.companyId,
                officeId: item.officeId,
                secondaryOfficeIds: item.secondaryOfficeIds,
                siteIds: item.siteIds,
                divisionIds: item.divisionIds,
                roleId: item.roleId
            }
            InspectorOptions.push(UserOption);
        });

        return InspectorOptions;
    }

    setSiteOptions = (Sites) => {
        let SiteOptions = [];


        Sites.forEach((item) => {
            let detailsArray = [];

            let { street, city, pin } = item;

            if (street) {
                detailsArray.push(street);
            }

            if (city) {
                detailsArray.push(city);
            }

            if (pin) {
                detailsArray.push(pin);
            }
            let SiteOption = {
                value: item.id,
                label: item.name,
                locationLevel: item.locationLevel,
                details: detailsArray.join(",")
            }
            SiteOptions.push(SiteOption);
        });
        return SiteOptions;
    }

    setClientOptions = (Clients) => {
        let ClientOptions = [];

        Clients.forEach((item) => {
            let detailsArray = [];

            let { street, city, pin } = item;

            if (street) {
                detailsArray.push(street);
            }

            if (city) {
                detailsArray.push(city);
            }

            if (pin) {
                detailsArray.push(pin);
            }

            let ClientOption = {
                value: item.id,
                label: item.name,
                locationLevel: item.locationLevel,
                officeId: item.officeId,
                details: detailsArray.join(",")
            }
            ClientOptions.push(ClientOption);
        });
        return ClientOptions;
    }

    setSettingsOptions = (SettingItems) => {
        let SettingOptions = [];

        SettingItems.forEach((item) => {
            let SettingOption = {
                division: item.division,
                value: item.id,
                label: item.name,
            }
            SettingOptions.push(SettingOption);
        });
        return CommonUtility.sortByColumn(SettingOptions, "label", "asc");
    }

    setAssetTypeOptions = (AssetTypes) => {
        let AssetTypeOptions = [];

        AssetTypes.forEach((item) => {
            let AssetTypeOption = {
                value: item.id,
                label: item.name,
            }
            AssetTypeOptions.push(AssetTypeOption);
        });
        return CommonUtility.sortByColumn(AssetTypeOptions, "label", "asc");
    }

    setAssetOptions = (Assets) => {
        let AssetOptions = [];

        Assets.forEach((item) => {
            let AssetOption = {
                value: item.id,
                label: item.name,
                parentId: item.parentId
            }
            AssetOptions.push(AssetOption);
        });
        return AssetOptions;
    }

    CreateJobObject = (Job, Clients, Offices, Sites, Users, AllInspectors, Tasks, TaskTypes, DivisionOptions) => {
        // eslint-disable-next-line
        var y = {};
        let TaskDetails = [];
        let LLGTaskDetails = {
            id: -1,
            isNew: false,
            status: -1,
            previousStatus: -1
        };
        let errorFlag = false;
        if (Tasks) {
            // eslint-disable-next-line
            Tasks.map((task, index) => {
                let AssetOptions = this.setAssetOptions(task.AssetOptions);
                let TaskType = TaskTypes.find(data => data.value === task.taskTypeId);
                if (!TaskType) {
                    errorFlag = true;
                }
                let TaskTypeName = TaskType ? TaskType.label : "";
                let AsstType = task.assetTypeName;

                if (TaskTypeName === "LLG" && AsstType === "LLG") {
                    LLGTaskDetails = {
                        id: task.id,
                        isNew: false,
                        status: task.status,
                        previousStatus: task.status,
                    }
                }
                else {
                    let taskDivision = DivisionOptions.find(data => data.value === task.divisionId);
                    let taskTaskType = TaskTypes.find(data => data.value === task.taskTypeId);
                    let taskAsset = AssetOptions.find(data => data.value === task.locationId);
                    let taskInspector = Users.find(data => data.value === task.inspectorId);

                    if (!taskDivision || !taskTaskType || (!taskAsset && task.locationId && task.locationType !== "SITE") || (!taskInspector && task.inspectorId)) {
                        errorFlag = true;
                    }

                    TaskDetails.push({
                        isNew: false,
                        id: task.id,
                        status: task.status,
                        Division: {
                            value: task.divisionId ? {
                                value: task.divisionId,
                                label: taskDivision ? taskDivision.label : ""
                            } : null,
                            error: false,
                            errorMessage: ""
                        },
                        TaskType: {
                            value: task.taskTypeId ? {
                                value: task.taskTypeId,
                                label: taskTaskType ? taskTaskType.label : ""
                            } : null,
                            error: false,
                            errorMessage: ""
                        },
                        AssetType: {
                            value: task.assetTypeId ? {
                                id: task.assetTypeId,
                                name: task.assetTypeName
                            } : null,
                            error: false,
                            errorMessage: ""
                        },
                        Asset: {
                            value: task.locationId ? {
                                value: task.locationId,
                                label: taskAsset ? taskAsset.label : ""
                            } : null,
                            error: false,
                            errorMessage: "",
                            loading: false
                        },
                        Inspector: {
                            value: task.inspectorId ? {
                                value: task.inspectorId,
                                label: taskInspector ? taskInspector.label : ""
                            } : null,
                            error: false,
                            errorMessage: ""
                        },
                        dueDate: {
                            value: task.dueDate ? new Date(task.dueDate) : CommonUtility.formatDateTimeToZeroHour(Job.startDate),
                            error: false,
                            errorMessage: ""
                        },
                        dueTime: {
                            value: task.dueDate ? moment(new Date(task.dueDate)).format('HH:mm') : moment(new Date()).format('HH:mm'),
                            error: false,
                            errorMessage: ""
                        },
                        AssetOptions: AssetOptions,
                        showTimeClock: false,
                        isDirty: false
                    });
                }
            });
        }
        if (errorFlag) {
            return null
        }
        return {

            // Client Details
            id: Job ? Job.id : null,
            ClientName: {
                value: Job ? {
                    value: Job.customerId,
                    label: Clients.find(data => data.value === Job.customerId).label
                } : null,
                error: true,
                errorMessage: ""
            },
            ClientAddress: {
                value: Job ? Clients.find(data => data.value === Job.customerId).details : "",
                error: true,
                errorMessage: ""
            },
            SiteDetails: {
                value: Job ? {
                    value: Job.siteId,
                    label: Sites.find(data => data.value === Job.siteId).label
                } : null,
                error: true,
                errorMessage: ""
            },
            SiteAddress: {
                value: Job ? Sites.find(data => data.value === Job.siteId).details : "",
                error: true,
                errorMessage: ""
            },
            StartDate: {
                value: Job ? new Date(Job.startDate) : new Date(),
                error: false,
                errorMessage: ""
            },
            EndDate: {
                value: Job ?
                    Job.endDate ?
                        new Date(Job.endDate)
                        : new Date(Job.startDate + (24 * 60 * 60 * 1000))
                    : new Date(Date.now() + (24 * 60 * 60 * 1000)),
                error: false,
                errorMessage: ""
            },
            StartDuration: {
                value: Job ? Job.duration : 1,
                error: false,
                errorMessage: ""
            },
            RequesterName: {
                value: Job && Job.requesterId ? {
                    value: Job.requesterId,
                    label: Users.find(data => data.value === Job.requesterId).label
                } : null,
                error: false,
                errorMessage: ""
            },
            RequesterDetails: {
                value: Job && Job.requesterId ? Users.find(data => data.value === Job.requesterId).details : "",
                error: false,
                errorMessage: ""
            },
            ClientUsers: {
                value: Job && Job.clientUserIds ? Job.clientUserIds.split(",").map(d =>
                    y =
                    {
                        value: parseInt(d),
                        label: Users.find(data => parseInt(data.value) === parseInt(d)).label,
                        details: Users.find(data => parseInt(data.value) === parseInt(d)).details
                    }
                ) : null,
                error: false,
                errorMessage: ""
            },
            // Tracking Details
            AssignOffice: {
                value: Job ? {
                    value: Job.secondaryOfficeId,
                    label: Offices.find(data => data.value === Job.secondaryOfficeId).label
                } :
                    {
                        value: window.loggedInUserDetails.primaryOfficeId,
                        label: Offices.find(data => data.value === window.loggedInUserDetails.primaryOfficeId).label
                    },
                error: false,
                errorMessage: ""
            },
            JobNumber: {
                value: Job ? Job.jobNumber : "",
                error: Job ? false : true,
                errorMessage: ""
            },
            SalesPerson: {
                value: Job && Job.salesPerson ? {
                    value: Job.salesPerson,
                    label: Users.find(data => data.value === Job.salesPerson).label
                } : null,
                error: false,
                errorMessage: ""
            },
            Price: {
                value: Job ? parseFloat(Job.price) : 0,
                error: false,
                errorMessage: ""
            },
            Currency: {
                value: Job && Job.currency ? {
                    value: Job.currency,
                    label: CurrencyOptions.find(data => data.value === Job.currency).label
                } : null,
                error: false,
                errorMessage: ""
            },
            PoNumber: {
                value: Job ? Job.poNumber : 0,
                error: false,
                errorMessage: ""
            },
            QuotationNumber: {
                value: Job ? Job.quotationNumber : 0,
                error: false,
                errorMessage: ""
            },

            // Site Details
            Comments: {
                value: Job ? Job.comments : "",
                error: false,
                errorMessage: ""
            },
            MainInspector: {
                value: Job ? {
                    value: Job.mainInspectorId,
                    label: AllInspectors.find(data => data.value === Job.mainInspectorId).label,
                    details: AllInspectors.find(data => data.value === Job.mainInspectorId).details
                } : null,
                error: false,
                errorMessage: ""
            },
            Inspectors: {
                value: Job && Job.inspectorIds ? Job.inspectorIds.split(",").map(d =>
                    y =
                    {
                        value: parseInt(d),
                        label: AllInspectors.find(data => parseInt(data.value) === parseInt(d)).label,
                        details: AllInspectors.find(data => parseInt(data.value) === parseInt(d)).details
                    }
                ) : null,
                error: false,
                errorMessage: ""
            },
            VendorDetails: {
                value: Job ? Job.vendorDetails : "",
                error: false,
                errorMessage: ""
            },
            SubVendorDetails: {
                value: Job ? Job.subVendorDetails : "",
                error: false,
                errorMessage: ""
            },
            status: Job ? Job.status : 1,
            otherFiles: {
                files: Job ? Job.otherFiles : [],
                uploadFilesErrorText: ""
            },
            // Task Details
            TaskDetails: Job ? TaskDetails : [
                {
                    Division: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    TaskType: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    AssetType: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    Asset: {
                        value: null,
                        error: false,
                        errorMessage: "",
                        loading: false
                    },
                    Inspector: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    AssetOptions: [],
                    status: 1,
                    dueDate: {
                        value: CommonUtility.formatDateTimeToZeroHour(new Date()),
                        error: false,
                        errorMessage: ""
                    },
                    dueTime: {
                        value: moment(new Date()).format('HH:mm'),
                        error: false,
                        errorMessage: ""
                    },
                    isNew: true,
                    showTimeClock: false,
                }
            ],
            LLGTaskDetails: LLGTaskDetails,
            isDayJob: Job ? Job.isDayJob : true,
            isDayJobClosed: Job ? Job.isDayJobClosed : true
        }
    }

    resetClient = () => {
        return {
            ClientName: {
                value: null,
                error: true,
                errorMessage: ""
            },
            ClientAddress: {
                value: "",
                error: true,
                errorMessage: ""
            },
            SiteDetails: {
                value: null,
                error: true,
                errorMessage: ""
            },
            SiteAddress: {
                value: "",
                error: true,
                errorMessage: ""
            },
            StartDate: {
                value: new Date(),
                error: false,
                errorMessage: ""
            },
            EndDate: {
                value: new Date(Date.now() + (24 * 60 * 60 * 100)),
                error: false,
                errorMessage: ""
            },
            StartDuration: {
                value: 1,
                error: false,
                errorMessage: ""
            },
            RequesterName: {
                value: null,
                error: false,
                errorMessage: ""
            },
            RequesterDetails: {
                value: "",
                error: false,
                errorMessage: ""
            },
            ClientUsers: {
                value: null,
                error: false,
                errorMessage: ""
            },
        }
    }

    resetTracking = () => {
        return {
            AssignOffice: {
                value: null,
                error: false,
                errorMessage: ""
            },
            JobNumber: {
                value: "",
                error: true,
                errorMessage: ""
            },
            SalesPerson: {
                value: null,
                error: false,
                errorMessage: ""
            },
            Price: {
                value: 0,
                error: false,
                errorMessage: ""
            },
            Currency: {
                value: "",
                error: false,
                errorMessage: ""
            },
            PoNumber: {
                value: "",
                error: false,
                errorMessage: ""
            },
            QuotationNumber: {
                value: "",
                error: false,
                errorMessage: ""
            },
        }
    }

    resetSiteDetails = () => {
        return {
            Comments: {
                value: "",
                error: false,
                errorMessage: ""
            },
            MainInspector: {
                value: null,
                error: false,
                errorMessage: ""
            },
            Inspectors: {
                value: null,
                error: false,
                errorMessage: ""
            },
            VendorDetails: {
                value: "",
                error: false,
                errorMessage: ""
            },
            SubVendorDetails: {
                value: "",
                error: false,
                errorMessage: ""
            },
            otherFiles: {
                files: [],
                uploadFilesErrorText: ""
            }
        }
    }

    resetTasks = (CurrentJobDetails) => {
        return {
            TaskDetails: [
                {
                    isNew: true,
                    status: 1,
                    Division: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    dueDate: {
                        value: CommonUtility.formatDateTimeToZeroHour(CurrentJobDetails.StartDate.value),
                        error: false,
                        errorMessage: ""
                    },
                    dueTime: {
                        value: moment(new Date()).format('HH:mm'),
                        error: false,
                        errorMessage: ""
                    },
                    TaskType: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    AssetType: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    Asset: {
                        value: null,
                        error: false,
                        errorMessage: "",
                        loading: false
                    },
                    Inspector: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    AssetOptions: [],
                    isDirty: true
                }
            ],
            isDayJob: true,
            LLGTaskDetails: {
                id: -1,
                isNew: true,
                status: -1,
                previousStatus: CurrentJobDetails.LLGTaskDetails.previousStatus
            }
        }
    }

    isDisabledNextOrSave = (CurrentJobDetails, ActiveStep) => {
        let isDisabled = false;

        switch (ActiveStep) {
            case 0:
                isDisabled =
                    (CurrentJobDetails.Id === -1 && CurrentJobDetails.LoOffice && !CurrentJobDetails.LoOffice.value) ||
                    (CurrentJobDetails.ClientName && !CurrentJobDetails.ClientName.value) ||
                    (CurrentJobDetails.SiteDetails && !CurrentJobDetails.SiteDetails.value) ||
                    (CurrentJobDetails.StartDate && !CurrentJobDetails.StartDate.value);
                // (CurrentJobDetails.RequesterName && !CurrentJobDetails.RequesterName.value) ||
                // (CurrentJobDetails.ClienUsers && !CurrentJobDetails.ClienUsers.value);
                break;
            case 1:
                isDisabled =
                    // (CurrentJobDetails.Currency && !CurrentJobDetails.Currency.value) ||
                    // (CurrentJobDetails.JobNumber && CurrentJobDetails.JobNumber.error) ||
                    (CurrentJobDetails.AssignOffice && !CurrentJobDetails.AssignOffice.value);
                break;
            case 2:
                isDisabled =
                    (CurrentJobDetails.MainInspector && !CurrentJobDetails.MainInspector.value);
                break;
            case 3:
                let isValidLLGTask = CurrentJobDetails.LLGTaskDetails.status !== -1;
                let isValidOtherTask = true;
                let isAnyAssetLoading = false;

                CurrentJobDetails.TaskDetails.forEach((task, index) => {
                    if (!task.Division.value && isValidOtherTask) {
                        isValidOtherTask = false;
                    }
                    if (task.Asset.loading) {
                        isAnyAssetLoading = true;
                    }
                });
                CurrentJobDetails.TaskDetails.forEach((task, index) => {
                    if (!task.TaskType.value && isValidOtherTask) {
                        isValidOtherTask = false;
                    }
                });

                isDisabled = (!isValidLLGTask && !isValidOtherTask)
                    || (isValidLLGTask && !isValidOtherTask)
                    || (!isValidLLGTask && CurrentJobDetails.TaskDetails.length === 0)
                    || isAnyAssetLoading
                break;

            default:
                break;
        }

        return isDisabled;

    }

    getInspectorOptions = (UserOptions, SelectedOffice, Designations) => {
        let inspectorOptions = [];

        // if(SelectedOffice)

        UserOptions.forEach((element) => {
            // let OfficeIds = [];
            // if (element.secondaryOfficeIds) {
            //     OfficeIds = element.secondaryOfficeIds.split(",").map(Number);
            // }
            // if (element.officeId) {
            //     OfficeIds.push(element.officeId);
            // }
            if (
                // OfficeIds.indexOf(SelectedOffice) > -1 &&
                element.type === 0 &&
                Designations.filter(d => d.name === "FIELD-ENGG" || d.name === "FIELD-JUNIOR").map(x => x.id).indexOf(element.roleId) > -1
            ) {
                inspectorOptions.push(element);
            }
        });

        return inspectorOptions;
    }
}
