import React, { Component } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './../../App.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import { PrimaryButton, IconButton, ActionButton } from 'office-ui-fabric-react/lib/Button';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import LoadingOverlay from 'react-loading-overlay';
import { FadeLoader } from 'react-spinners';
import { Label } from 'office-ui-fabric-react';
import { CustomerAPI } from './CustomerAPI';
import Alert from '../Alert/Alert.jsx';
import CustomerAddEdit from './CustomerAddEdit.jsx';
import Confirm from '../Alert/Confirm.jsx';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { IconConstants } from '../../Constants/StylesConstants';

let nameFilter;

const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
}];

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="table-export-button-div">
            <PrimaryButton
                onClick={handleClick}
                text="Export to CSV"
                menuIconProps={IconConstants.exportIcon}
            />
        </div>
    );
};

const RemoteAll = ({
    data, page, sizePerPage, onTableChange,
    totalSize, columns, hideSizePerPage }) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
        >
            {
                props => (
                    <div>
                        <MyExportCSV {...props.csvProps} />
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage })}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            wrapperClasses="table-responsive" />
                    </div>
                )
            }
        </ToolkitProvider>

    </React.Fragment>
);


export default class CustomerRegistration extends Component {


    constructor(props) {
        super(props);
        this.state = {
            Customers: [],
            SelectedCustomer: {},
            CurrentPage: 1,
            sizePerPage: 10,
            totalSize: 0,
            finalDataSize: 0,
            loading: false,
            HeaderText: "",
            isOpenAddEditCustomerPanel: false,
            loadingAddEditPanel: false,
            alertJSON: {
                hideAlert: true,
                alertHeader: "",
                alertSubText: "",
            },
            confirmJSON: {
                hideConfirm: true,
            }
        }
    }

    componentDidMount() {
        this.loadInitData()
    }

    loadInitData() {

        nameFilter('');

        this.setState({
            loading: true
        });

        let config = {
            headers: {
            }
        }

        let customerAPI = new CustomerAPI(window.API_URL, config);

        customerAPI.loadData().then(response => {
            this.setState({
                CurrentPage: 1,
                totalSize: response[1].data,
                finalDataSize: response[0].data,
                Customers: response[0].data,
                loading: false
            })
        }).catch(error => {

            console.log("Error", error);
            let alertJSON = { ...this.state.alertJSON };
            alertJSON.alertHeader = "Error !!";
            alertJSON.alertSubText = error;
            alertJSON.hideAlert = false;

            this.setState({
                alertJSON,
                loading: false
            }, () => {
                setTimeout(() => {
                    alertJSON.alertHeader = "";
                    alertJSON.alertSubText = "";
                    alertJSON.hideAlert = true;
                    this.setState({
                        alertJSON,
                        loading: false
                    });
                }, 2500);
            })

        })

    }

    componentDidCatch(error, info) {
        console.error("error : ", error);
        console.error("info : ", info.componentStack);
    }

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {

        // console.log("type", type);
        this.setState({
            loading: true
        });
        // console.log(sortOrder);

        let filterJson = {};

        for (let dataField in filters) {
            filterJson[dataField] = filters[dataField].filterVal
        }

        let config = {
            headers: {
            }
        }
        if (Object.keys(filterJson).length > 0) {
            config = {
                headers: {
                    filter: JSON.stringify(filterJson)
                }
            }
        }

        let customerAPI = new CustomerAPI(window.API_URL, config);


        if (type.toUpperCase() === "PAGINATION") {

            customerAPI.loadData(sortOrder, sortField, page).then(response => {
                this.setState({
                    totalSize: response[1].data,
                    Customers: response[0].data,
                    CurrentPage: page,
                    loading: false
                })
            }).catch(error => {

                console.log("Error", error);
                let alertJSON = { ...this.state.alertJSON };
                alertJSON.alertHeader = "Error !!";
                alertJSON.alertSubText = error;
                alertJSON.hideAlert = false;

                this.setState({
                    alertJSON,
                    loading: false
                }, () => {
                    setTimeout(() => {
                        alertJSON.alertHeader = "";
                        alertJSON.alertSubText = "";
                        alertJSON.hideAlert = true;
                        this.setState({
                            alertJSON,
                            loading: false
                        });
                    }, 5000);
                })

            })

        }
        else if (type.toUpperCase() === "FILTER") {
            customerAPI.loadData(sortOrder, sortField).then(response => {
                this.setState({
                    totalSize: response[1].data,
                    Customers: response[0].data,
                    CurrentPage: 1,
                    loading: false
                })
            }).catch(error => {

                console.log("Error", error);
                let alertJSON = { ...this.state.alertJSON };
                alertJSON.alertHeader = "Error !!";
                alertJSON.alertSubText = error;
                alertJSON.hideAlert = false;

                this.setState({
                    alertJSON,
                    loading: false
                }, () => {
                    setTimeout(() => {
                        alertJSON.alertHeader = "";
                        alertJSON.alertSubText = "";
                        alertJSON.hideAlert = true;
                        this.setState({
                            alertJSON,
                            loading: false
                        });
                    }, 5000);
                })

            })

        }
        else if (type.toUpperCase() === "SORT") {

            customerAPI.loadData(sortOrder, sortField).then(response => {
                this.setState({
                    totalSize: response[1].data,
                    Customers: response[0].data,
                    CurrentPage: 1,
                    loading: false
                })
            }).catch(error => {

                console.log("Error", error);
                let alertJSON = { ...this.state.alertJSON };
                alertJSON.alertHeader = "Error !!";
                alertJSON.alertSubText = error;
                alertJSON.hideAlert = false;

                this.setState({
                    alertJSON,
                    loading: false
                }, () => {
                    setTimeout(() => {
                        alertJSON.alertHeader = "";
                        alertJSON.alertSubText = "";
                        alertJSON.hideAlert = true;
                        this.setState({
                            alertJSON,
                            loading: false
                        });
                    }, 5000);
                })

            })

        }
        else {
            setTimeout(() => {

                let result = this.state.Customers;


                // Handle column sort
                if (sortOrder === 'asc') {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return 1;
                        } else if (b[sortField] > a[sortField]) {
                            return -1;
                        }
                        return 0;
                    });
                } else {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return -1;
                        } else if (b[sortField] > a[sortField]) {
                            return 1;
                        }
                        return 0;
                    });
                }

                this.setState({
                    CurrentPage: page,
                    Customers: result,
                    loading: false
                });

            }, 1000);
        }

    }

    openAddEditCustomerPanel = (SelectedCustomer) => {
        let HeaderText = "EDIT CUSTOMER";
        if (!SelectedCustomer) {
            SelectedCustomer = {
                name: "",
                email: "",
                mobilenumber: "",
                namespace: "",
                companyname: "",
                companyaddress: "",
                companywebsite: "",
                projectname: "",
                projectaddress: "",
                projectreraregistration: "",
                currentprojectphase: "",
                sourceType: "",
                userdesignation: "",
                loCompanyLogo: []
            };
            HeaderText = "ADD CUSTOMER";

        }

        this.setState({
            SelectedCustomer,
            isOpenAddEditCustomerPanel: true,
            HeaderText: HeaderText
        });
    }

    closeAddEditCustomerPanel = (saveFlag, SelectedCustomer) => {
        if (saveFlag) {
            this.setState({
                loadingAddEditPanel: true
            });

            let config = {
                headers: {
                }
            }

            let customerAPI = new CustomerAPI(window.API_URL, config);
            if (SelectedCustomer.id) {
                customerAPI.updateCustomer(SelectedCustomer).then(response => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON.alertHeader = "Success !!";
                    alertJSON.alertSubText = "Customer Updated Successfully. Please refresh table to check data";
                    alertJSON.hideAlert = false;

                    this.setState({
                        alertJSON,
                        loadingAddEditPanel: false
                    }, () => {
                        setTimeout(() => {
                            alertJSON.alertHeader = "";
                            alertJSON.alertSubText = "";
                            alertJSON.hideAlert = true;
                            this.setState({
                                isOpenAddEditCustomerPanel: false,
                                alertJSON,
                            });
                        }, 2500);
                    });

                }).catch(error => {
                    console.log("Error: ", error);
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON.alertHeader = "Error !!";
                    alertJSON.alertSubText = error;
                    alertJSON.hideAlert = false;

                    this.setState({
                        alertJSON,
                        loadingAddEditPanel: false
                    }, () => {
                        setTimeout(() => {
                            alertJSON.alertHeader = "";
                            alertJSON.alertSubText = "";
                            alertJSON.hideAlert = true;
                            this.setState({
                                isOpenAddEditCustomerPanel: false,
                                alertJSON,
                            });
                        }, 2500);
                    })
                });
            }
            else {
                customerAPI.addCustomer(SelectedCustomer).then(response => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON.alertHeader = "Success !!";
                    alertJSON.alertSubText = "Customer Added Successfully. Please refresh table to check data";
                    alertJSON.hideAlert = false;

                    this.setState({
                        alertJSON,
                        loadingAddEditPanel: false
                    }, () => {
                        setTimeout(() => {
                            alertJSON.alertHeader = "";
                            alertJSON.alertSubText = "";
                            alertJSON.hideAlert = true;
                            this.setState({
                                isOpenAddEditCustomerPanel: false,
                                alertJSON,
                            });
                        }, 2500);
                    });

                }).catch(error => {
                    console.log("Error: ", error);
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON.alertHeader = "Error !!";
                    alertJSON.alertSubText = error;
                    alertJSON.hideAlert = false;

                    this.setState({
                        alertJSON,
                        loadingAddEditPanel: false
                    }, () => {
                        setTimeout(() => {
                            alertJSON.alertHeader = "";
                            alertJSON.alertSubText = "";
                            alertJSON.hideAlert = true;
                            this.setState({
                                alertJSON,
                            });
                        }, 2500);
                    })
                });
            }
        }
        else {
            this.setState({
                isOpenAddEditCustomerPanel: false,
            });
        }
    }

    openConfirmModalForDelete = (SelectedCustomer) => {

        let confirmJSON = { ...this.state.confirmJSON };
        confirmJSON.hideConfirm = false;

        this.setState({
            SelectedCustomer,
            confirmJSON
        });
    }

    deleteOrRetainCustomer = (deleteFlag) => {

        let confirmJSON = { ...this.state.confirmJSON };
        confirmJSON.hideConfirm = true;
        if (deleteFlag) {

            this.setState({
                loading: true
            });
            let config = {
                headers: {
                }
            }

            let customerAPI = new CustomerAPI(window.API_URL, config);

            customerAPI.deleteCustomer(this.state.SelectedCustomer.id).then(response => {
                let alertJSON = { ...this.state.alertJSON };
                alertJSON.alertHeader = "Success !!";
                alertJSON.alertSubText = "Customer Deleted Successfully. Please refresh table to check data";
                alertJSON.hideAlert = false;

                this.setState({
                    alertJSON,
                    confirmJSON,
                    loading: false
                }, () => {
                    setTimeout(() => {
                        alertJSON.alertHeader = "";
                        alertJSON.alertSubText = "";
                        alertJSON.hideAlert = true;
                        this.setState({
                            alertJSON,
                            loading: false
                        });
                    }, 2500);
                });

            }).catch(error => {
                console.log("Error: ", error);
                let alertJSON = { ...this.state.alertJSON };
                alertJSON.alertHeader = "Error !!";
                alertJSON.alertSubText = error;
                alertJSON.hideAlert = false;

                this.setState({
                    alertJSON,
                    confirmJSON,
                    loading: false
                }, () => {
                    setTimeout(() => {
                        alertJSON.alertHeader = "";
                        alertJSON.alertSubText = "";
                        alertJSON.hideAlert = true;
                        this.setState({
                            alertJSON,
                            loading: false
                        });
                    }, 2500);
                })
            });
        }
        else {
            this.setState({
                SelectedCustomer: {},
                confirmJSON
            });

        }
    }

    render() {

        let {
            Customers,
            CurrentPage,
            sizePerPage,
            totalSize,
            loading,
            alertJSON,
            isOpenAddEditCustomerPanel,
            SelectedCustomer,
            HeaderText,
            confirmJSON,
            loadingAddEditPanel
        } = this.state

        let columns = [
            {
                dataField: 'manageCustomerIcons',
                isDummyField: true,
                text: 'Action',
                formatter: (cellContent, row) => {
                    return (
                        <h6>
                            <IconButton
                                onClick={() => this.openAddEditCustomerPanel(row)}
                                iconProps={IconConstants.editicon} />
                            <IconButton
                                onClick={() => this.openConfirmModalForDelete(row)}
                                iconProps={IconConstants.deleteicon} />

                        </h6>
                    )
                }
            },
            {
                dataField: 'id',
                text: 'Customer ID',
                sort: true,
                filter: textFilter(),
                hidden: true,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'name',
                text: 'Customer Name',
                // filter: textFilter(),
                filter: textFilter({
                    getFilter: (filter) => {
                        nameFilter = filter;
                    }
                }),
                sort: true,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'companyname',
                text: 'Company Name',
                sort: true,
                filter: textFilter(),
                title: (cell) => `${cell}`
            },
            {
                dataField: 'email',
                text: 'Email',
                filter: textFilter(),
                sort: true,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'mobilenumber',
                text: 'Mobile Number',
                sort: true,
                filter: textFilter(),
                title: (cell) => `${cell}`

            },
            {
                dataField: 'namespace',
                text: 'NameSpace',
                filter: textFilter(),
                sort: true,
                title: (cell) => `${cell}`

            },
            {
                dataField: 'currentstate',
                text: 'Status',
                filter: textFilter(),
                sort: true,
                title: (cell) => `${cell}`,
                csvFormatter: (cell, row, rowIndex) => `${cell === 1 ? 'Active' : "Inactive"}`
            },
            {
                dataField: 'sourcetype',
                text: 'Source Type',
                filter: textFilter(),
                sort: true,
                title: (cell) => `${cell}`,
                csvFormatter: (cell, row, rowIndex) => `${cell === 1 ? 'Non Lite User' : "Lite User"}`

            }
        ];


        return (
            <section className="App">
                <LoadingOverlay
                    active={loading}
                    spinner={<FadeLoader />}
                    text='Loading Data...'>
                    <Label className="breadcrumb-label">Customer Registration</Label>
                    {/* <h4 className="text-left">
                        Customer Registration
                    <IconButton
                            onClick={() => this.loadInitData()}
                            iconProps={IconConstants.refreshIcon} />
                    </h4> */}
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ActionButton
                                    text="Reload"
                                    iconProps={IconConstants.refreshIcon}
                                    onClick={() => this.loadInitData()}
                                />
                                <ActionButton
                                    onClick={() => this.openAddEditCustomerPanel()}
                                    text="Add"
                                    iconProps={IconConstants.addIcon}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lazy-load-customer-div" >
                        <RemoteAll
                            data={Customers}
                            columns={columns}
                            page={CurrentPage}
                            sizePerPage={sizePerPage}
                            hideSizePerPage={true}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange}
                        />
                    </div>

                </LoadingOverlay>
                {isOpenAddEditCustomerPanel &&
                    <CustomerAddEdit
                        isOpenAddEditCustomerPanel={isOpenAddEditCustomerPanel}
                        loadingAddEditPanel={loadingAddEditPanel}
                        closeAddEditCustomerPanel={this.closeAddEditCustomerPanel}
                        SelectedCustomer={SelectedCustomer}
                        HeaderText={HeaderText}
                    />
                }
                {!alertJSON.hideAlert &&
                    <Alert
                        alertJSON={alertJSON}
                    />
                }
                {!confirmJSON.hideConfirm &&
                    <Confirm
                        confirmJSON={confirmJSON}
                        deleteOrRetainItem={this.deleteOrRetainCustomer}
                    />
                }
            </section>
        );
    }
}
