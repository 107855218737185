import { ISelect } from "../../../CommonInterfaces/CommoInterfaces";
import { IUser, ILocation } from "../ClientInterface/IClient";

export class ClientUtility {

    public getClientUserRoleOptions = (UserRoles): ISelect[] => {

        let UserRoleOptions = [] as ISelect[];

        UserRoles.forEach((element: any) => {
            UserRoleOptions.push({
                label: element.name,
                value: element.id,
            })
        });

        return UserRoleOptions;
    }

    public createNewUserRow = (): IUser => {

        return {
            role: null,
            email: "",
            name: "",
            phone: ""
        } as IUser
        // return {
        //     role: null,
        //     email: "custuser12@qs.com",
        //     name: "Customer User 1",
        //     phone: "956321470"
        // } as IUser
    }

    public resetClientAndSite = (): ILocation => {
        return {
            name: "",
            street: "",
            city: "",
            state: "",
            country: "",
            pin: 0,
            phone: "",
            acronymCode: "",
            officeId: 0,
            officeIds: ""
        }
    }

    public resetLocation = (): ILocation => {
        return {
            Id: -1,
            name: "",
            street: "",
            city: "",
            state: "",
            country: "",
            pin: 0,
            phone: "",
            acronymCode: ""
        }
    }

    public static isInvalidClientForm = (AddClientDetails: ILocation, AddSiteDetails: ILocation, Users: IUser[]): boolean => {

        let isInvalid: boolean = false;

        isInvalid = (!AddClientDetails.name || !AddClientDetails.street || !AddClientDetails.officeIds);

        isInvalid = isInvalid || (!AddSiteDetails.name);

        Users.forEach((user: IUser) => {
            if (!user.role || !user.name || !user.email) {
                isInvalid = true;
            }
        })

        return isInvalid;
    }

    public getLoOfficeOptions = (LoOffices: ILocation[]): ISelect[] => {

        let LoOfficeOptions = [] as ISelect[];

        LoOffices.forEach((element: any) => {
            LoOfficeOptions.push({
                label: element.name,
                value: element.id,
            })
        });

        return LoOfficeOptions;
    }
}