export const QRSTICKER_BREADCRUMBS = [
    { text: 'RESOURCES', key: 'Resources' },
    { text: 'QRSticker', key: 'QRSticker' }
];
export const SizeOptions = [
    //{ value: 'A6', label: '4 in 1 A4(9x9 cm)' },
    { value: 'A4', label: '12 in 1 A4(5x5 cm) ' }
]
export const ColorOptions = [
    //{ value: 'Blue', label: 'Blue' },
   // { value: 'Red', label: 'Red' },
    { value: 'Black', label:'Black'}
]
export const QuantityOptions = [
    { value: '5', label: '5' },
    { value: '120', label: '120' },
    { value: '240', label: '240' },
    { value: '480', label: '480' }
    
]

export const QRSTICKER_MAIN_URL = 'QRCodeWithImage';
export const SAVE_QRSTICKER = 'SAVE_QRSTICKER';
export const CHANGE_QRSTICKER_SIZE = 'CHANGE_QRSTICKER_SIZE';
export const CHANGE_QRSTICKER_COLOR = 'CHANGE_QRSTICKER_COLOR';
export const CHANGE_QRSTICKER_QUANTITY = 'CHANGE_QRSTICKER_QUANTITY';
export const CLEAN_QRSTICKER = 'CLEAN_QRSTICKER';
export const REQUEST_PROCESSING = 'REQUEST_PROCESSING';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const CLEAR_SUCCESS_MESSAGE ='CLEAR_SUCCESS_MESSAGE';

