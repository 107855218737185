import * as React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from "react-loading-overlay";
import AlertRedux from '../../Alert/AlertRedux';
import TaskInspectLLGParent from './TaskInspectLLGParent';
import TaskInspectLLGChild from './TaskInspectLLGChild';


require("jquery-ui-sortable");
require("formBuilder");
require('formBuilder/dist/form-render.min.js');

export default class TaskInspectLLG extends React.PureComponent<any, any>  {

    public constructor(props: any) {
        super(props);
    }

    public render(): JSX.Element {

        let {
            LoadingInspectTab,
            LLGTabIndex,
            alertJSON,
        } = this.props.Task;


        return (

            <LoadingOverlay
                active={LoadingInspectTab}
                spinner={<FadeLoader />}
                text='Please Wait...'>
                <Tabs selectedIndex={LLGTabIndex} onSelect={(index: number) => this.props.changeLLGTaskTab({ LLGTabIndex: index })}>
                    <TabList>
                        <Tab>TASKS</Tab>
                        <Tab>RECORDS</Tab>
                    </TabList>

                    <TabPanel>
                        <TaskInspectLLGParent
                            {...this.props}
                        />
                    </TabPanel>
                    <TabPanel>
                        <TaskInspectLLGChild
                            {...this.props}
                        />
                    </TabPanel>
                </Tabs>
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </LoadingOverlay >
        )
    }

}
