import React, { PureComponent } from "react";
import {
  ActionButton,
  IconButton,
  SearchBox,
  PrimaryButton,
} from "office-ui-fabric-react";
import filterFactory from "react-bootstrap-table2-filter";
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";
//import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  DeletedRowStyle,
  IconConstants,
} from "./../../../../Constants/StylesConstants";
import { CommonUtility } from "./../../../../CommonUtility/CommonUtility";
import { RoleAPI } from "./../Services/RoleAPI";
import AddEditRolePanel from "./AddEditRolePanel";
import ConfirmRoleRedux from "./ConfirmRoleRedux";
import AlertRedux from "../../../Alert/AlertRedux";
import { ROLE_TYPE } from "../Constants/RoleConstants";

const defaultSorted = [
  {
    dataField: "status",
    order: "asc",
  },
];

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div className="table-export-button-div">
      <PrimaryButton
        onClick={handleClick}
        text="Export to CSV"
        menuIconProps={IconConstants.exportIcon}
      />
    </div>
  );
};

const RemoteAll = ({
  data,
  columns,
  onTableChange,
  page,
  hideSizePerPage,
  style,
}) => (
  <React.Fragment>
    <ToolkitProvider
      bootstrap4
      keyField="id"
      columns={columns}
      data={data}
      exportCSV={{
        fileName: "Qwikspec_Role.csv",
        noAutoBOM: false,
        blobType: "text/csv;charset=ansi",
      }}
    >
      {(props) => (
        <React.Fragment>
          <MyExportCSV {...props.csvProps} />
          <BootstrapTable
            remote
            condensed
            hover
            striped
            filter={filterFactory()}
            defaultSorted={defaultSorted}
            // pagination={paginationFactory({ page, hideSizePerPage })}
            onTableChange={onTableChange}
            {...props.baseProps}
            rowClasses={DeletedRowStyle}
            wrapperClasses="table-responsive" />
        </React.Fragment>
      )}
    </ToolkitProvider>
  </React.Fragment>
);

export default class RoleDashboard extends PureComponent {
  _roleApi = null;

  constructor(props) {
    super(props);
    this._roleApi = new RoleAPI(window.API_URL, window.loggedInUserDetails);
  }

  componentDidMount() {
    this.loadInitData();
  }

  loadInitData = () => {
    let config = {
      headers: {},
    };

    this.props.loadRoleDashboard({
      Roles: [],
      objectType: "ROLE",
      offset: 0,
      apiObject: this._roleApi,
      config: config,
      filtered: false
    });
  };

  fetchMoreData = () => {
    let filterJson = {};

    let Role = this.props.Role;

    filterJson = {
      name: Role.role_name_filter_value,
    };
    let config = {
      headers: {
        filter: JSON.stringify(filterJson),
      },
    };

    // for (let dataField in filters) {
    //     filterJson[dataField] = filters[dataField].filterVal
    // }

    // if (Object.keys(filterJson).length > 0) {
    //     config = {
    //         headers: {
    //             filter: JSON.stringify(filterJson)
    //         }
    //     }
    // }

    this.props.loadRoleDashboard({
      objectType: "ROLE",
      Roles: [],
      // offset: this.props.Role.offset,
      //limit: this.props.Role.limit,
      apiObject: this._roleApi,
      config: config,
    });
  };

  /// Filter and sort Method  Implementation
  handleTableChange = (type, { filters, sortField, sortOrder }) => {
    // console.log(sortOrder);

    let filterJson = {};

    for (let dataField in filters) {
      filterJson[dataField] = filters[dataField].filterVal;
    }

    let config = {
      headers: {},
    };
    if (Object.keys(filterJson).length > 0) {
      config = {
        headers: {
          filter: JSON.stringify(filterJson),
        },
      };
    }

    if (type.toUpperCase() === "FILTER") {
      this.props.cleanDashboard({
        filtered: true,
        offset: 0,
        loadingDashboard: true,
      });

      this.props.loadDashboard({
        // offset: 0,
        //limit: this.props.User.limit,
        objectType: "ROLE",
        apiObject: this._roleApi,
        loadingDashboard: false,
        filtered: true,
        config: config,
        isScrolled: true,
        filters: filters,
      });
    } else if (type.toUpperCase() === "SORT") {
      setTimeout(() => {
        let result = [...this.props.Role.Roles];

        // Handle column sort
        if (sortOrder === "asc") {
          result = result.sort((a, b) => {
            if (a[sortField] > b[sortField]) {
              return 1;
            } else if (b[sortField] > a[sortField]) {
              return -1;
            }
            return 0;
          });
        } else {
          result = result.sort((a, b) => {
            if (a[sortField] > b[sortField]) {
              return -1;
            } else if (b[sortField] > a[sortField]) {
              return 1;
            }
            return 0;
          });
        }

        this.props.sortDashboard({
          sortedRoles: result,
          sortedColumn: sortField,
          sortOrder,
        });
      }, 1000);
    }
  };

  render() {
    let columns = [
      {
        dataField: "manageRoleIcons",
        isDummyField: true,
        text: "Action",
        formatter: (cellContent, row) => {
          return (
            <span>
              {row.status === 1 ? (
                <React.Fragment>
                  <IconButton
                    onClick={() =>
                      this.props.loadAttributeDropDowns({
                        apiObject: this._roleApi,
                        objectType: "ROLE",
                        PanelHeaderText: "ADD ROLE",
                        tenantId: row.tenantId,
                      })
                    }
                    iconProps={IconConstants.addIcon}
                  />
                  <IconButton
                    onClick={() =>
                      this.props.loadAttributeDropDowns({
                        apiObject: this._roleApi,
                        objectType: "ROLE",
                        PanelHeaderText: row?.name,
                        SelectedRole: row,
                        tenantId: row.tenantId,
                      })
                    }
                    iconProps={IconConstants.editicon}
                  />
                  <IconButton
                    onClick={() => {
                      this.props.openCloseRoleConfirmModal({
                        hideConfirm: false,
                        CurrentRole: row,
                      });
                    }}
                    iconProps={IconConstants.deleteicon}
                  />
                </React.Fragment>
              ) : (
                <span></span>
              )}
            </span>
          );
        },
        csvExport: false,
      },
      {
        dataField: "id",
        text: "Role ID",
        hidden: true,
        csvExport: false,
      },

      {
        dataField: "name",
        text: "Name",

        // filter: textFilter(),
        sort: true,
        title: (cell) => `${cell}`,
      },
      {
        dataField: "type",
        text: "Type",
        formatter: (cellContent, row) => {
          return (
            <span>
              {ROLE_TYPE[cellContent]}
            </span>
          )
        },

        // filter: textFilter(),
        sort: true,
        // title: (cell) => `${cell}`,
      },
      {
        dataField: "namespace",
        text: "Customer",

        // filter: textFilter(),
        sort: true,
        // title: (cell) => `${cell}`,
      },
      {
        dataField: "acronymCode",
        text: "Code",
        sort: true,
        title: (cell) => `${cell}`,
      },

      // {
      //     dataField: 'group',
      //     text: 'Group',
      // },
      // {
      //     dataField: 'category',
      //     text: 'Category',
      // },
      // {
      //   dataField: 'description',
      // text: 'Description',
      //},
      // {
      //     dataField: 'reference',
      //     text: 'Data Form',
      // },
    ];

    let {
      LoadingRoleDashboard,
      Roles,
      ShowRoleAddEditPanel,
      role_name_filter_value,
      customer_name_filter_value,
      hideConfirm,
      alertJSON,
    } = this.props.Role;

    let filteredRole = [];
    // if (ShowActiveUsers) {
    // } else {
    //   filteredRole = [...Roles.filter((role) => role.status !== 1)];
    // }
    // filteredRole = [...Roles.filter((role) => role.status === 1 && (customer_name_filter_value ? role.name)];

    Roles.forEach((role) => {
      if (customer_name_filter_value) {
        if (
          role.status === 1 &&
          role.namespace?.toUpperCase().indexOf(customer_name_filter_value.toUpperCase()) > -1
        ) {
          filteredRole.push(role)
        }
      }
      else {
        if (role.status === 1) {
          filteredRole.push(role)
        }
      }
    });
    filteredRole = CommonUtility.sortByColumn(filteredRole, "status", "asc");

    return (
      <React.Fragment>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">

            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
              <ActionButton
                disabled={LoadingRoleDashboard}
                text="Reload"
                iconProps={IconConstants.refreshIcon}
                onClick={() => this.loadInitData()}
              />
              {/* <ActionButton
                text="Add"
                iconProps={IconConstants.addIcon}
                onClick={() =>
                  this.props.loadAttributeDropDowns({
                    apiObject: this._roleApi,
                    objectType: "ROLE",
                    PanelHeaderText: "Add Role",
                  })
                }
              /> */}
            </div>

            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg2">
              <SearchBox
                value={role_name_filter_value}
                placeholder="Role Name"
                underlined={true}
                onSearch={(newValue) => {
                  let filterJson = {
                    name: newValue,
                  };
                  let config = {
                    headers: {
                      filter: JSON.stringify(filterJson),
                    },
                  };
                  this.props.findFilteredRolesAsync({
                    filterType: "name",
                    value: newValue,
                    objectType: "ROLE",
                    //offset: 0,
                    // limit: this.props.User.limit,
                    apiObject: this._roleApi,
                    config: config,
                    filtered: true,
                  });
                }}
                onClear={() => {
                  let filterJson = {
                    name: "",
                  };
                  let config = {
                    headers: {
                      filter: JSON.stringify(filterJson),
                    },
                  };
                  this.props.findFilteredRolesAsync({
                    filterType: "name",
                    value: "",
                    objectType: "ROLE",
                    apiObject: this._roleApi,
                    config: config,
                    filtered: true,
                  });
                }}
              />
            </div>

            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg2">
              <SearchBox
                value={customer_name_filter_value}
                placeholder="Customer Name"
                underlined={true}
                onChange={(event, newValue) => {
                  this.props.changeRoleFilterValue({
                    filterType: "customer",
                    value: newValue,
                  });
                }}
              // onClear={() => {
              //   this.props.changeRoleFilterValue({
              //     filterType: "name",
              //     value: newValue,
              //   });
              // }}
              />
            </div>

          </div>

        </div>

        <LoadingOverlay
          active={LoadingRoleDashboard}
          spinner={<FadeLoader />}
          text="Loading Roles..."
        >
          <div className="lazy-load-div">
            <RemoteAll
              data={filteredRole}
              columns={columns}
              page={1}
              hideSizePerPage={true}
              onTableChange={this.handleTableChange}
            />
          </div>
        </LoadingOverlay>

        {ShowRoleAddEditPanel && <AddEditRolePanel {...this.props} />}
        {!hideConfirm && <ConfirmRoleRedux {...this.props} />}
        {!alertJSON.hideAlert && <AlertRedux {...this.props} />}
      </React.Fragment>
    );
  }
}
