export class TrialFitPicLibraryUtility {

    public static getLibraryName = (path: string): string => {
        switch (path) {
            case "productTechInfo":
                return "PRODUCT TECHNICAL INFORMATION";
            case "reportLibrary":
                return "REPORT LIBRARY";
            default:
                return "TRIAL FIT PICTURE LIBRARY";
        }
    }

    public static getDisplayName = (path: string): string => {
        switch (path) {
            case "productTechInfo":
                return "PRODUCT TECHNICAL INFORMATION";
            case "reportLibrary":
                return "REPORT LIBRARY";
            default:
                return "PICTORIAL LIBRARY";
        }
    }


    public static getLibraryKey = (path: string): string => {
        switch (path) {
            case "productTechInfo":
                return "PRODUCT_TECHNICAL_INFORMATION";
            case "reportLibrary":
                return "REPORT_LIBRARY";
            default:
                return "TRIAL_FIT_PIC_LIBRARY";
        }
    }

    public static getFileUploadErrorMessage = (): string => {
        return "Invalid file type or size";
    }

    public static getFileUploadInfo = (path: string): string => {
        switch (path) {
            case "productTechInfo":
            case "reportLibrary":
                return "Type - .png, .jpg, .pdf, .doc, .docx, .xls, .xlsx, .csv | Size - Upto 45mb";
            default:
                return "Type - .png, .jpg | Size - Upto 45mb";
        }
    }

    public static getFiletypeAllowed = (path: string): string => {
        switch (path) {
            case "productTechInfo":
            case "reportLibrary":
                return "image/png, image/jpeg, application/pdf,.doc,.docx,.xls,.xlsx,.csv";
            default:
                return "image/png, image/jpeg";
        }
    }
}