import { INavLink } from "office-ui-fabric-react";

export const JOBS: INavLink =
{
    name: 'Jobs',
    icon: 'Workflow',
    url: 'operations/jobs',
    key: 'operations/jobs',
}

export const JOBLITES: INavLink =
{
    name: 'Job Lite',
    icon: 'Workflow',
    url: 'operations/joblites',
    key: 'operations/joblites',
}

export const TASKS: INavLink =
{
    name: 'Tasks',
    icon: 'TaskManager',
    url: 'operations/tasks',
    key: 'operations/tasks',
}

export const REPORTS: INavLink =
{
    name: 'Reports',
    icon: 'AnalyticsReport',
    url: 'operations/reports',
    key: 'operations/reports',
}

export const ASSETS: INavLink =
{
    name: 'Assets',
    icon: 'AssetLibrary',
    url: 'resources/assets',
    key: 'resources/assets',
}

export const LOOSEGEAR: INavLink =
{
    name: 'Loose Gear',
    icon: 'AnchorLock',
    url: 'resources/llg',
    key: 'resources/llg',
};

export const ASSESSMENT: INavLink =
{
    name: 'Assessment',
    icon: 'AnchorLock',
    url: 'resources/assessment',
    key: 'resources/assessment',
};

export const CLIENTS: INavLink =
{
    name: 'Clients',
    icon: 'BuildIssue',
    url: 'resources/clients',
    key: 'resources/clients',
};
export const QRSTICKER: INavLink =
{
    name: 'QR Sticker',
    icon: 'QRCode',
    url: 'resources/qrsticker',
    key: 'resources/qrsticker',
};
export const USERS: INavLink =
{
    name: 'Users',
    icon: 'People',
    url: 'resources/users',
    key: 'resources/users',
}

export const DATAFORMS: INavLink =
{
    name: 'Data Forms',
    icon: "FormLibrary",
    url: 'settings/forms',
    key: 'settings/forms',
};

export const ASSETTYPES: INavLink =
{
    name: 'Asset Types',
    icon: 'WorkItem',
    url: 'settings/assettypes',
    key: 'settings/assettypes',
};

export const TASKTYPES: INavLink =
{
    name: 'Task Types',
    icon: 'ProcessMetaTask',
    url: 'settings/tasktypes',
    key: 'settings/tasktypes',
};

export const CONFIGURATIONS: INavLink =
{
    name: 'Configurations',
    icon: 'Settings',
    url: 'settings/configdata',
    key: 'settings/configdata',
};

export const CATEGORY: INavLink =
{
    name: 'Category',
    url: 'settings/category',
    key: 'settings/category',
    disabled: true,
};

export const TEMPLATES: INavLink =
{
    name: 'Templates',
    url: 'settings/templates',
    key: 'settings/templates',
    disabled: true,
};

export const STATES: INavLink =
{
    name: 'States',
    url: 'settings/states',
    key: 'settings/states',
    disabled: true,
}

export const CUSTOMER_REGISTRATION: INavLink =
{
    name: 'Customer Registration',
    icon: 'Admin',
    url: 'admin/customerRegistration',
    key: 'admin/customerRegistration',
};

export const ROLES: INavLink =
{
    name: 'Roles',
    icon: 'Admin',
    url: 'settings/roles',
    key: 'settings/roles'
};

export const DIVISION: INavLink =
{
    name: 'Division',
    icon: 'Admin',
    url: 'settings/division',
    key: 'settings/division'
};

export const LO_OFFICE: INavLink =
{
    name: 'LO Office',
    icon: 'Admin',
    url: 'admin/loOffice',
    key: 'admin/loOffice'
};

export const SEQUENCE_NUMBER: INavLink =
{
    name: 'Sequence Numbering',
    icon: 'Admin',
    url: 'admin/sequenceNumber',
    key: 'admin/sequenceNumber'
}

export const PRODUCT_TECH_INFO: INavLink =
{
    name: 'Product Technical Information',
    icon: 'FabricFolder',
    url: 'technicalLibrary/productTechInfo',
    key: 'technicalLibrary/productTechInfo'
}
export const TRIAL_FIT_PIC_LIBRARY: INavLink =
{
    name: 'Pictorial Library',
    icon: 'FabricFolder',
    url: 'technicalLibrary/trialFitPicLibrary',
    key: 'technicalLibrary/trialFitPicLibrary'
}

export const REPORT_LIBRARY: INavLink =
{
    name: 'Reports',
    icon: 'FabricFolder',
    url: 'technicalLibrary/reportLibrary',
    key: 'technicalLibrary/reportLibrary'
}

export const CLIENT_INVENTORY: INavLink =
{
    name: 'Client Inventory',
    icon: 'ProcessMetaTask',
    url: 'resources/clientInventory',
    key: 'resources/clientInventory'
}
