import {
    CLEAN_USER_DASHBOARD,
    LOAD_USER_DASHBOARD,
    FIX_USER_DASHBOARD,
    SORT_USER_DASHBOARD,
    DELETE_USER,
    OPEN_CLOSE_USER_ALERT_MODAL,
    OPEN_CLOSE_USER_CONFIRM_MODAL,
    OPEN_CLOSE_ADD_EDIT_USER_PANEL,
    CHANGE_USER_PHONE,
    CHANGE_USER_EMAIL,
    CHANGE_USER_NAME,
    LOAD_ADD_EDIT_USER_PANEL,
    SAVE_USER,
    SWITCH_USER_VIEW,
    USER_STATUS,
    USER_STATUS_REV,
    LOAD_USER_LOCATIONS_DIVISIONS_ROLES,
    CHANGE_USER_TYPE,
    CHANGE_USER_DIVISIONS,
    CHANGE_USER_CLIENT_SITES,
    CHANGE_USER_OTHER_OFFICES,
    CHANGE_USER_PRIMARY_OFFICE,
    CHANGE_USER_ROLE,
    CHANGE_USER_NAME_FILTER_VALUE,
    CHANGE_USER_EMAIL_FILTER_VALUE,
    CHANGE_USER_ROLE_FILTER_VALUE,
    CHANGE_USER_LOCATION_FILTER_VALUE,
    CHANGE_USER_STATUS_FILTER_VALUE,
    LOAD_USER_PANEL_LOCATIONS,
    CHANGE_USER_COMPANY,
    CHANGE_USER_DIVISION_FILTER_VALUE,
    UPLOAD_USER_SIGNATURE,
    SHOW_HIDE_UPLOAD_IMAGE_ERROR_TEXT,
    CHANGE_USER_DESCRIPTION,
    SHOW_HIDE_DELETE_IMAGE_ERROR_TEXT,
    DELETE_USER_SIGNATURE,
    CHANGE_USER_EXTERNAL_ID,
} from "../Constants/UserConstants";
import { UserInitialState } from "../Constants/UserInitialState";

export class UserActions {

    cleanDashboard = (payload) => {
        return { type: CLEAN_USER_DASHBOARD, payload }

    }

    loadDashboard = (payload) => {
        return dispatch => {
            payload.isOngoingLoad = true;
            dispatch(this.loadDashBoardAsync(payload));
            payload.apiObject.getUserDetails(payload.offset, payload.limit, payload.config).then((response) => {
                payload.isOngoingLoad = false;
                payload.Users = response[0].data;
                payload.TotalUsersCount = response[1].data;
                dispatch(this.loadDashBoardAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Ooops!!";
                payload.alertSubText = "Error while loading Users";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    loadDashBoardAsync = (payload) => {
        return { type: LOAD_USER_DASHBOARD, payload }
    }

    fixDashboard = (payload) => {
        return { type: FIX_USER_DASHBOARD, payload }
    }

    sortDashboard = (payload) => {
        return { type: SORT_USER_DASHBOARD, payload }
    }

    deleteUser = (payload) => {
        return dispatch => {
            payload.isCompletedDelete = false;
            dispatch(this.deleteUserAsync(payload))

            payload.apiObject.deleteUser(payload.id, payload.ToBeUpdatedStatus).then((response) => {
                payload.isCompletedDelete = true;
                payload.response = response;
                dispatch(this.deleteUserAsync(payload))

                payload.hideAlert = false;
                payload.alertHeader = "Success !!";
                payload.alertSubText = `User ${USER_STATUS_REV[USER_STATUS[payload.ToBeUpdatedStatus]]} successfully`;
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 3500);

            }).catch((error) => {
                console.error("Error: ", error);

                payload.hideAlert = false;
                payload.alertHeader = "Error !!";
                payload.alertSubText = error;
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2500);

            })
        }
    }

    deleteUserAsync = (payload) => {
        return { type: DELETE_USER, payload };
    }

    openCloseConfirmModal = (payload) => {
        return { type: OPEN_CLOSE_USER_CONFIRM_MODAL, payload };
    }

    openCloseAlertModal = (payload) => {
        return { type: OPEN_CLOSE_USER_ALERT_MODAL, payload };
    }

    // User Panel
    opencloseAddEditUserPanel = (payload) => {
        return { type: OPEN_CLOSE_ADD_EDIT_USER_PANEL, payload };
    }

    changeUserName = (payload) => {
        return { type: CHANGE_USER_NAME, payload };
    }

    changeUserEmail = (payload) => {
        return { type: CHANGE_USER_EMAIL, payload };
    }

    changeUserPhone = (payload) => {
        return { type: CHANGE_USER_PHONE, payload };
    }
    
    changeUserDescription = (payload) => {
        return { type: CHANGE_USER_DESCRIPTION, payload };
    }

    changeUserExternalId = (payload) => {
        return { type: CHANGE_USER_EXTERNAL_ID, payload };
    }

    saveUser = (payload) => {
        return { type: SAVE_USER, payload };
    }

    loadAddEditUserPanel = (payload) => {
        return { type: LOAD_ADD_EDIT_USER_PANEL, payload };
    }

    saveUserAsync = (payload) => {
        return dispatch => {
            dispatch(this.loadAddEditUserPanel(payload))
            let userPayload = {};

            let divisionIds = "";
            let secondaryOfficeIds = "";
            let siteIds = "";

            if (payload.CurrentUser.Divisions.value) {
                payload.CurrentUser.Divisions.value.forEach((element, index) => {
                    divisionIds += index === payload.CurrentUser.Divisions.value.length - 1 ? element.value : element.value + ",";
                });
            }
            if (payload.CurrentUser.OtherOffices.value) {
                payload.CurrentUser.OtherOffices.value.forEach((element, index) => {
                    secondaryOfficeIds += index === payload.CurrentUser.OtherOffices.value.length - 1 ? element.value : element.value + ",";
                });
            }
            if (payload.CurrentUser.ClientSites.value) {
                payload.CurrentUser.ClientSites.value.forEach((element, index) => {
                    siteIds += index === payload.CurrentUser.ClientSites.value.length - 1 ? element.value : element.value + ",";
                });
            }

            userPayload = {
                id: payload.CurrentUser.id,
                name: payload.CurrentUser.name.value,
                email: payload.CurrentUser.email.value,
                phone: payload.CurrentUser.phone.value,
                description: payload.CurrentUser.description.value,
                type: payload.CurrentUser.type,
                tenantId: payload.CurrentUser.tenantId ? payload.CurrentUser.tenantId : 2,
                roleId: payload.CurrentUser.Designation.value.value,
                companyId: payload.CurrentUser.Company.value.value,
                officeId: payload.CurrentUser.PrimaryOffice.value ? payload.CurrentUser.PrimaryOffice.value.value : null,
                secondaryOfficeIds: secondaryOfficeIds,
                divisionIds: divisionIds,
                externalId: payload.CurrentUser.externalId.value,
                siteIds: siteIds,
            }
            payload.apiObject.saveUser(userPayload).then((responseStatus) => {

                dispatch(this.saveUser(payload))
                payload.hideAlert = false;
                payload.alertHeader = "Success !!";
                payload.alertSubText = "User saved successfully";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                    let config = {
                        headers: {
                        }
                    }
                    payload.Users = [];
                    payload.offset = 0;
                    payload.loadingDashboard = true;
                    dispatch(this.cleanDashboard(payload));
                    payload.Users = [];
                    payload.offset = 0;
                    payload.limit = UserInitialState.limit;
                    payload.isScrolled = false;
                    payload.loadingDashboard = false;
                    payload.config = config;
                    dispatch(this.loadDashboard(payload));
                }, 2500);

            }).catch((error) => {
                // console.error("Error: ", error);

                payload.hideAlert = false;
                payload.alertHeader = "Error !!";
                payload.alertSubText = error;
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 3500);

            })
        }
    }

    switchUserView = (payload) => {
        return { type: SWITCH_USER_VIEW, payload }
    }

    loadUserLocationDivisionRoles = (payload) => {
        return { type: LOAD_USER_LOCATIONS_DIVISIONS_ROLES, payload }
    }

    loadDropDownDataAsync = (payload) => {
        return dispatch => {
            dispatch(this.loadAddEditUserPanel(payload))

            if (!payload.CurrentUser) {
                payload.apiObject.loadDropDownData().then((response) => {
                    // payload.Clients = response[0].data;
                    // payload.Offices = response[1].data;
                    payload.Roles = response[0].data;
                    payload.Divisions = response[1].data;
                    payload.isPreloaded = false;
                    dispatch(this.loadUserLocationDivisionRoles(payload))

                }).catch((error) => {
                    console.error("Error: ", error);

                    payload.hideAlert = false;
                    payload.alertHeader = "Error !!";
                    payload.alertSubText = "Error while loading Location Division and Roles";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2500);

                })
            }
            else {
                let CurrentUser = payload.CurrentUser;
                let userType = CurrentUser.type;
                let companyId = CurrentUser.companyId;
                userType = CurrentUser.type;
                let companyType = "";
                let subLocationType = "";

                if (userType === 0) {
                    companyType = "LO_COMPANY";
                    subLocationType = "LO_OFFICE";
                }
                else if (userType === 1) {
                    companyType = "CLIENT_COMPANY";
                    subLocationType = "SITE";
                }

                payload.apiObject.loadDropDownData(companyType).then((response) => {
                    payload.isOngoingLoad = false;
                    payload.Locations = response.data;
                    payload.Roles = response[0].data;
                    payload.Divisions = response[1].data;
                    payload.Locations = response[2].data;
                    payload.objectType = companyType;
                    dispatch(this.loadUserPanelLocations(payload));
                    payload.apiObject._getLocationObjects(subLocationType, companyId).then((response) => {
                        payload.isOngoingLoad = false;
                        payload.Locations = response.data;
                        payload.objectType = subLocationType;
                        dispatch(this.loadUserPanelLocations(payload));
                        dispatch(this.loadUserLocationDivisionRoles(payload));
                    }).catch((error) => {
                        console.error("Error: ", error);
                        payload.hideAlert = false;
                        payload.alertHeader = "Oops!!";
                        payload.alertSubText = "Error while loading Company";
                        dispatch(this.openCloseAlertModal(payload));

                        setTimeout(() => {
                            payload.hideAlert = true;
                            payload.alertHeader = "";
                            payload.alertSubText = "";
                            dispatch(this.openCloseAlertModal(payload));
                        }, 2000);
                    })
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Oops!!";
                    payload.alertSubText = "Error while loading Sub Locations";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })

            }
        }
    }

    uploadUserSignatureAsync = (payload) => {
        return { type: UPLOAD_USER_SIGNATURE, payload }
    }

    showHideUploadImageErrorText = (payload) => {
        return { type: SHOW_HIDE_UPLOAD_IMAGE_ERROR_TEXT, payload }
    }

    uploadUserSignature = (payload) => {
        return dispatch => {
            payload.isOngoingLoad = true;
            dispatch(this.uploadUserSignatureAsync(payload));
            payload.apiObject._uploadUserSigntureFile(payload.CurrentUser.id, payload.uploadImageData).then((responseImg) => {
                payload.isOngoingLoad = false;
                payload.imgBlobKey = responseImg.blobKey;
                dispatch(this.uploadUserSignatureAsync(payload));
            }).catch((error) => {
                console.log("Error while uploading signature: ", error);
                payload.errorText = "Error while uploading user signature";
                dispatch(this.showHideUploadImageErrorText(payload));

                setTimeout(() => {
                    payload.errorText = "Error while uploading user signature";
                    dispatch(this.showHideUploadImageErrorText(payload));
                }, 2000);
            });
        }
    }
    
    deleteUserSignatureAsync = (payload) => {
        return { type: DELETE_USER_SIGNATURE, payload }
    }

    showHideDeleteImageErrorText = (payload) => {
        return { type: SHOW_HIDE_DELETE_IMAGE_ERROR_TEXT, payload }
    }

    deleteUserSignature = (payload) => {
        return dispatch => {
            payload.isOngoingLoad = true;
            dispatch(this.deleteUserSignatureAsync(payload));
            payload.apiObject._deleteUserSigntureFile(payload.CurrentUser.id).then((responseImg) => {
                payload.isOngoingLoad = false;
                payload.imgDeleteResponse = responseImg;
                dispatch(this.deleteUserSignatureAsync(payload));
            }).catch((error) => {
                console.log("Error while uploading signature: ", error);
                payload.deleteErrorText = "Error while uploading user signature";
                dispatch(this.showHideDeleteImageErrorText(payload));

                setTimeout(() => {
                    payload.deleteErrorText = "Error while uploading user signature";
                    dispatch(this.showHideDeleteImageErrorText(payload));
                }, 2000);
            });
        }
    }

    changeUserTypeAsync = (payload) => {
        return { type: CHANGE_USER_TYPE, payload };
    }

    changeUserType = (payload) => {
        switch (payload.type) {
            case 0:
                payload.objectType = "LO_COMPANY";
                break;
            case 1:
                payload.objectType = "CLIENT_COMPANY";
                break;

            default:
                break;
        }
        return dispatch => {
            payload.isOngoingLoad = true;
            dispatch(this.changeUserTypeAsync(payload));

            payload.apiObject._getLocationObjects(payload.objectType).then((response) => {
                payload.isOngoingLoad = false;
                payload.Locations = response.data;
                dispatch(this.loadUserPanelLocations(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading Locations";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    changeUserRole = (payload) => {
        return { type: CHANGE_USER_ROLE, payload };
    }

    changeUserPrimaryOffice = (payload) => {
        return { type: CHANGE_USER_PRIMARY_OFFICE, payload };
    }

    changeUserCompany = (payload) => {
        return dispatch => {
            payload.isOngoingLoad = true;
            dispatch(this.changeUserCompanyAsync(payload));
            payload.apiObject._getLocationObjects(payload.objectType, payload.Company.value).then((response) => {
                payload.isOngoingLoad = false;
                payload.Locations = response.data;
                dispatch(this.loadUserPanelLocations(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading Sub Locations";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    changeUserCompanyAsync = (payload) => {
        return { type: CHANGE_USER_COMPANY, payload }
    }

    changeUserOtherOffices = (payload) => {
        return { type: CHANGE_USER_OTHER_OFFICES, payload };
    }

    changeUserClientSites = (payload) => {
        return { type: CHANGE_USER_CLIENT_SITES, payload };
    }

    changeUserDivisions = (payload) => {
        return { type: CHANGE_USER_DIVISIONS, payload };
    }

    // Load Company, Office and Sites

    loadUserPanelLocations = (payload) => {
        return { type: LOAD_USER_PANEL_LOCATIONS, payload };
    }

    //DASHBOARD FILTER start

    findFilteredUsersAsync = (payload) => {
        return dispatch => {
            dispatch(this.changeUserFilterValue(payload));
            payload.apiObject.getUserDetails(payload.offset, payload.limit, payload.config).then((response) => {
                payload.Users = response[0].data;
                payload.TotalUsersCount = response[1].data;
                dispatch(this.loadDashBoardAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.hideAlert = false;
                payload.alertHeader = "Oops!!";
                payload.alertSubText = "Error while loading Users";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    changeUserFilterValue = (payload) => {
        switch (payload.filterType) {
            case "name":
                return { type: CHANGE_USER_NAME_FILTER_VALUE, payload };
            case "email":
                return { type: CHANGE_USER_EMAIL_FILTER_VALUE, payload };
            case "role":
                return { type: CHANGE_USER_ROLE_FILTER_VALUE, payload };
            case "location":
                return { type: CHANGE_USER_LOCATION_FILTER_VALUE, payload };
            case "status":
                return { type: CHANGE_USER_STATUS_FILTER_VALUE, payload };
            case "division":
                return { type: CHANGE_USER_DIVISION_FILTER_VALUE, payload };
                
            default:
                break;
        }
    }

    //DASHBOARD FILTER end

}