import { ProductTechInfoInitialState } from '../Constants/ProductTechInfoInitialState';
import { IDocumentEntity } from './../../TrialFitPicLibrary/Interfaces/IDocumentEntity';
import { ILibraryPayload } from './../../TrialFitPicLibrary/Interfaces/ILibraryPayload';

import * as CONSTANTS from '../Constants/ProductTechInfoConstants';

export function ProductTechInfoReducer(state = ProductTechInfoInitialState, action: any) {

    let tempState = { ...state };
    let payload: ILibraryPayload = action.payload;
    let tempSelectedDocument = { ...tempState.selectedDocument };

    switch (action.type) {

        case CONSTANTS.CLEAN_PRODUCT_TECH_INFO_DOCUMENT_LIST:
            tempState.documentEntities = [];
            tempState.llgAssetTypes = [];
            tempState.fileEntities = [];
            tempState.isShowAddEditDocumentPanel = false;
            tempState.isLoadingDocumentsList = true;
            tempState.parentLibrary = null;
            tempState.selectedDocument = null;
            tempState.selectedFolder = null;
            tempState.selectedNewDocument = null;
            tempState.isLoadingAddEditDocumentPanel = false;
            tempState.sectionflag = "";
            tempState.isShowFileSection = false;
            tempState.isLoadingFileList = false;
            tempState.hideConfirmDelete = true;
            tempState.fileUploadErrorMessage = "";
            tempState.entityNameErrorMessage = "";
            tempState.searchedFolderString = "";
            tempState.searchedFileString = "";
            tempState.deletedEntity = null;
            break;

        case CONSTANTS.LOAD_PRODUCT_TECH_INFO_DOCUMENT_LIST:
            tempState.isLoadingDocumentsList = payload.isOngoingLoading;
            if (!(payload.isOngoingLoading)) {
                tempState.documentEntities = payload.documentEntities;
                tempState.llgAssetTypes = payload.llgAssetTypes;
                tempState.parentLibrary = payload.parentLibrary;
            }
            break;

        case CONSTANTS.CHANGE_PRODUCT_TECH_INFO_DOCUMENT_NAME:
            tempSelectedDocument.name = payload.documentName;
            tempState.selectedDocument = tempSelectedDocument;
            if (payload.documentType === "folder") {
                tempState.selectedNewDocument = payload.selectedNewDocument;
                const folderId = tempState.documentEntities
                    .find((entity: IDocumentEntity) => entity.name.trim().toUpperCase()
                        === payload.documentName.trim().toUpperCase())?.id;

                tempState.entityNameErrorMessage = folderId && (folderId !== tempState.selectedDocument.id)
                    ? "Can not allow duplicate folder name" : "";

            } else if (payload.documentType === "file") {
                const fileId = tempState.fileEntities
                    .find((entity: IDocumentEntity) => entity.name.trim().toUpperCase()
                        === payload.documentName.trim().toUpperCase())?.id;

                tempState.entityNameErrorMessage = fileId && (fileId !== tempState.selectedDocument.id)
                    ? "Can not allow duplicate file name" : "";

            }
            break;

        case CONSTANTS.CHANGE_PRODUCT_TECH_INFO_SEARCH_FOLDER_STRING:
            tempState.searchedFolderString = payload.searchedFolderString;
            break;

        case CONSTANTS.CHANGE_PRODUCT_TECH_INFO_SEARCH_FILE_STRING:
            tempState.searchedFileString = payload.searchedFileString;
            break;

        case CONSTANTS.SHOW_HIDE_PRODUCT_TECH_INFO_FILE_UPLOAD_ERROR_MESSAGE:
            tempState.fileUploadErrorMessage = payload.fileUploadErrorMessage;
            break;

        case CONSTANTS.SAVE_PRODUCT_TECH_INFO_FOLDER:
        case CONSTANTS.SAVE_PRODUCT_TECH_INFO_FILE:
            tempState.isLoadingAddEditDocumentPanel = payload.isOngoingLoading;
            break;

        case CONSTANTS.OPEN_CLOSE_ADD_EDIT_PRODUCT_TECH_INFO_DOCUMENT_PANEL:
            tempState.isShowAddEditDocumentPanel = payload.isShowAddEditDocumentPanel;
            if (!payload.isShowAddEditDocumentPanel) {
                tempState.fileUploadErrorMessage = "";
                tempState.entityNameErrorMessage = "";
            }
            tempState.selectedDocument = payload.selectedDocument;
            tempState.panelHeaderText = payload.panelHeaderText;
            tempState.sectionflag = payload.sectionflag;
            break;

        case CONSTANTS.CLEAN_PRODUCT_TECH_INFO_FILE_LIST:
            tempState.fileEntities = [];
            tempState.isLoadingFileList = true;
            break;

        case CONSTANTS.SHOW_HIDE_PRODUCT_TECH_INFO_FILE_SECTION:
            tempState.isShowAddEditDocumentPanel = payload.isShowAddEditDocumentPanel;
            tempState.selectedDocument = payload.selectedDocument;
            tempState.selectedFolder = payload.selectedFolder;
            tempState.isLoadingFileList = payload.isOngoingLoading;
            tempState.isShowFileSection = true;
            if (!payload.isOngoingLoading) {
                tempState.fileEntities = payload.documentEntities;
            }
            break;

        case CONSTANTS.DELETE_PRODUCT_TECH_INFO_ENTITY:
            tempState.isLoadingDocumentsList = true;
            tempState.hideConfirmDelete = true;
            if (!payload.isOngoingLoading) {
                tempState.deletedEntity = null;
                tempState.fileEntities = [];
                tempState.documentEntities = [];
                tempState.isShowFileSection = false;
            }
            break;

        case CONSTANTS.OPEN_CLOSE_PRODUCT_TECH_INFO_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON ?
                payload.alertJSON :
                {
                    alertHeader: "",
                    alertSubText: "",
                    hideAlert: true,
                };
            break;
        case CONSTANTS.OPEN_CLOSE_PRODUCT_TECH_INFO_ENTITY_DELETE_CONFIRM_MODAL:
            tempState.hideConfirmDelete = payload.hideConfirmDelete;
            tempState.deletedEntity = payload.deletedEntity;
            break;


        default:
            break;
    }

    return tempState;
}