import { combineReducers } from "redux";
import { UserReducer } from './../Components/Users/Reducers/UserReducers';
import { JobReducer } from './../Components/Jobs/Reducers/JobReducers';
import { TaskReducer } from './../Components/Tasks/Reducers/TaskReducers';
import { AssetTypeReducer } from './../Components/Settings/AssetTypes/Reducers/AssetTypeReducers.ts';
import { TaskTypeReducer } from './../Components/Settings/TaskTypes/Reducers/TaskTypeReducers.ts';
import { ConfigReducer } from './../Components/Settings/Configurations/Reducers/ConfigReducer.ts';
import { ClientReducer } from '../Components/Clients/Reducers/ClientReducer.ts';
import { AssetReducer } from '../Components/Assets/Reducers/AssetReducer.ts';
import { RoleReducer } from '../Components/Settings/Roles/Reducers/RoleReducer.ts';
import { DivisionReducer } from '../Components/Settings/Divisions/Reducers/DivisionReducer.ts';
import { StatesReducer } from '../Components/Settings/States/Reducers/StatesReducer.ts';
import { CategoryReducer } from '../Components/Settings/Category/Reducers/CategoryReducer.ts';
import { TemplatesReducer } from '../Components/Settings/Templates/Reducers/TemplatesReducer.ts';
import { ReportsReducer } from '../Components/Reports/Reducer/ReportsReducer.ts';
import { AssessmentsReducer } from '../Components/Assessment/Reducer/AssessmentsReducer.ts'
import { SequenceReducer } from '../Components/SequenceNumbering/Reducer/SequenceReducer.ts';
import { LoOfficeReducer } from '../Components/LoOffice/Reducers/LoOfficeReducer.ts';
import { LooseGearReducer } from '../Components/LooseGears/Reducer/LooseGearReducer.ts';
import { JobLitesReducers } from '../Components/JobLites/Reducers/JobLitesReducers.ts';
import { ClientSiteSelectorReducer } from '../Components/ClientSiteSelector/Reducers/ClientSiteSelectorReducer.ts';
import { ClientInventoryReducer } from '../Components/ClientInventory/Reducers/ClientInventoryReducer.ts';
import { TrialFitPicLibraryReducer } from '../Components/TechnicalLibrary/TrialFitPicLibrary/Reducers/TrialFitPicLibraryReducers.ts';
import { ProductTechInfoReducer } from '../Components/TechnicalLibrary/ProductTechInfo/Reducers/ProductTechInfoReducers.ts';
import { QRStickerReducer } from "../Components/QRSticker/Reducers/QRStickerReducer";
export const RootReducer = combineReducers({
    UserReducer,
    JobReducer,
    TaskReducer,
    AssetTypeReducer,
    TaskTypeReducer,
    ConfigReducer,
    ClientReducer,
    AssetReducer,
    RoleReducer,
    DivisionReducer,
    StatesReducer,
    CategoryReducer,
    TemplatesReducer,
    ReportsReducer,
    AssessmentsReducer,
    SequenceReducer,
    LoOfficeReducer,
    LooseGearReducer,
    JobLitesReducers,
    TrialFitPicLibraryReducer,
    ClientInventoryReducer,
    ClientSiteSelectorReducer,
    ProductTechInfoReducer,
    QRStickerReducer
})