import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Breadcrumb } from 'office-ui-fabric-react';
import { RoleAction } from '../Actions/RoleAction';
import RoleDashboard from './RoleDashboard'

const mapStateToProps = (state) => {
    return {
        Role: state.RoleReducer
    }
}

class RoleComponent extends PureComponent {


    render() {
        const { RoleBreadCrumb } = this.props.Role;
        return (
            <React.Fragment>
                <Breadcrumb
                    className="custom-breadcrumb"
                    items={RoleBreadCrumb}
                    ariaLabel="With last item rendered as heading"
                    overflowAriaLabel="More links"
                />
                <RoleDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let roleAction = new RoleAction();

    return bindActionCreators({ ...roleAction }, dispatch);
}

const Role = connect(mapStateToProps, mapDispatchToProps)(RoleComponent);
export default Role;
