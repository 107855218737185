import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Route, Redirect, HashRouter, Switch } from 'react-router-dom';
import SideBar from './Components/SideBar/SideBar.jsx';
import { BASE_URL } from './Constants/URLConstants';
import CommonAPIServices from './CommonAPIService/CommonAPIServices';
// import history from './history';
// import { TempLogin } from './TempLogin.ts';
import { initializeIcons } from '@uifabric/icons';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import CustomLogin from './Components/Login/CustomLogin';
import QwikspecLogin from './Components/Login/QwikspecLogin';

initializeIcons();

let CurrentLocation = window.origin;
window.API_URL = CurrentLocation.concat("/");

if (CurrentLocation === "http://localhost:3000" || CurrentLocation === "http://localhost:9000") {
  window.API_URL = BASE_URL.LOCAL;
}

const isLiftMax = window.location.href.includes("liftmax");

const PrivateRouteHome = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    window.isAuthenticated ? (
      <Component
        {...props}
      />
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    )
  )} />
)
const PrivateRouteLogin = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    window.isAuthenticated ? (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }} />
    ) : (
      <Component
        {...props}
      />
    )
  )} />
)

ReactDOM.render(
  <div className="login-spinner-div">
    <Spinner
      size={SpinnerSize.large}
      label="Please wait..."
    />
  </div>
  ,
  document.getElementById('root')
);

let commonAPIService = new CommonAPIServices(window.API_URL);

commonAPIService.getUserDetails().then((responses) => {

  if (responses[0] && responses[1]) {
    window.isAuthenticated = true;
    window.loggedInUserDetails = responses[0][0];
    window.loggedInUserDetails.customerLogoType = responses[1].fileType;
    window.loggedInUserDetails.customerLogo = responses[1].companyLogo;
  }
  ReactDOM.render(
    <React.Fragment>
      <HashRouter URL={URL}>
        <Switch>
          <PrivateRouteLogin exact path="/login" name="Login Page" component={isLiftMax ? CustomLogin : QwikspecLogin} />
          <PrivateRouteHome path="/" name="SideBar" component={SideBar} />
        </Switch>
      </HashRouter>
    </React.Fragment>
    ,
    document.getElementById('root')
  );

}).catch((error) => {
  console.error("Error in commonAPIService: ", error);

})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



