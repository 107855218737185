import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridFilterCellProps, GridToolbar } from '@progress/kendo-react-grid';
import { ActionButton, PrimaryButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { FadeLoader } from 'react-spinners';
import { IKendoGridColumn, IKendoGridFilter, ISelect } from '../../../CommonInterfaces/CommoInterfaces';
import { IconConstants } from '../../../Constants/StylesConstants';
import { IAssessmentsInitialState } from '../Interfaces/IAssessmentsInitialState';
import { IAssessmentService } from '../Interfaces/IAssessmentsService';
import { IAssessmentStateProps } from '../Interfaces/IAssessmentStateProps';
import { AssessmentsService } from '../Services/AssessmentsAPI';
import * as CONSTANTS from '../Constants/AssessmentsConstants';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT_USER, DATE_FORMAT } from '../../../Constants/MessageConstants';
import { IdCustomCell, DateCustomCell, ResultCustomCell, SpecificationsCustomCell, HistoryCustomCell } from './CustomAssessmentsColumnViews';
import { CertificatePreviewPanel } from './CertificatePreviewPanel';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { DateRangePicker, DateRangePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import DropdownFilterCell from './DropdownFilterCell';
import { ItemStatusCustomCell } from '../../Assets/Views/CustomColumnViews';
import AssessmentHistoryPanel from './AssessmentHistoryPanel';

export default class AssessmentsDasboard extends React.PureComponent<IAssessmentStateProps, {}> {

    private _apiPayload: IAssessmentService;
    private _excelExport: any;

    public constructor(props: IAssessmentStateProps) {
        super(props);
        this._apiPayload = new AssessmentsService(window.API_URL, window.loggedInUserDetails);
    }

    public componentDidMount(): void {
    }

    public export = (): void => {
        // this._excelExport.save();
        this.savetoExcel(this._excelExport);
    }

    assessmentsDateCustomCell = (props) => (
        <DateCustomCell {...props} currentProps={this.props} />
    );

    idCustomCell = (props) => (
        <IdCustomCell {...props} currentProps={this.props} />
    );

    historyCustomCell = (props) => (
        <HistoryCustomCell {...props} currentProps={this.props} />
    );

    resultCustomCell = (props) => (
        <ResultCustomCell {...props} currentProps={this.props} />
    );

    itemStatusCustomCell = (props) => (
        <ItemStatusCustomCell {...props} currentProps={this.props} />
    );

    public specificationsCustomCell = (props) => (
        <SpecificationsCustomCell {...props} currentProps={this.props} />
    );

    cellRender = (td: any, props: any) => {
        let groupColDetails = CONSTANTS.ASSESSMENT_VIEW_GRID_COLUMNS.find(
            (d) => d.field === props.dataItem.field
        );
        let groupColTitle = '';
        if (groupColDetails) {
            groupColTitle = groupColDetails.title;
        }

        if (td && td.props.children && props.rowType === 'groupHeader') {
            let children = (
                <span>
                    {td.props.children.props.children[0]}{' '}
                    {groupColTitle + ': ' + props.dataItem.value}
                </span>
            );
            return React.cloneElement(td, td.props, children);
        }
        return td;
    };

    public onChangeDateInput = (dateFieldName: string, dateChangeEvent: DateRangePickerChangeEvent): void => {
        let {
            assessmentGridDataState,
            selectedAssessmentSite,
            selectedAssessmentClient
        } = this.props.AssessmentsState;

        let issueDate: string = "";
        let dueDate: string = "";
        let filterJson = {};
        let fromDateStr: string = "";
        let toDateStr: string = "";
        if (dateChangeEvent) {
            const {
                start,
                end
            } = dateChangeEvent?.target?.value;
            fromDateStr =
                `${moment(start).format('YYYY-MM-DD')} 00:00:00`;
            toDateStr =
                `${moment(end).format('YYYY-MM-DD')} 23:59:59`;
        }
        switch (dateFieldName) {
            case "actualInspectionDate":
                issueDate = dateChangeEvent ? `${fromDateStr}#${toDateStr}` : ``;
                filterJson[dateFieldName] = issueDate;

                let startDueDate = this.props.AssessmentsState.expiryDateRange?.start;
                let endDueDate = this.props.AssessmentsState.expiryDateRange?.end;
                if (startDueDate && endDueDate) {
                    const fromDueDateStr: string =
                        `${moment(startDueDate).format('YYYY-MM-DD')} 00:00:00`;
                    const toDueDateStr: string =
                        `${moment(endDueDate).format('YYYY-MM-DD')} 23:59:59`;
                    dueDate = `${fromDueDateStr}#${toDueDateStr}`;
                    filterJson["dueDate"] = dueDate;
                }
                break;
            case "dueDate":
                dueDate = dateChangeEvent ? `${fromDateStr}#${toDateStr}` : ``;
                filterJson[dateFieldName] = dueDate;

                let startIssueDate = this.props.AssessmentsState.issueDateRange?.start;
                let endIssueDate = this.props.AssessmentsState.issueDateRange?.end;
                if (startIssueDate && endIssueDate) {
                    const fromStartDateStr: string =
                        `${moment(startIssueDate).format('YYYY-MM-DD')} 00:00:00`;
                    const toStartDateStr: string =
                        `${moment(endIssueDate).format('YYYY-MM-DD')} 23:59:59`;
                    issueDate = `${fromStartDateStr}#${toStartDateStr}`;
                    filterJson["actualInspectionDate"] = issueDate;
                }
                break;

            default:
                break;
        }


        if (assessmentGridDataState.filter) {
            assessmentGridDataState.filter.filters.forEach((filter: IKendoGridFilter) => {
                filterJson[filter.field] = filter.value;
            });
        }

        let siteNumbers = selectedAssessmentSite?.filter((location: ISelect) => location.value > 0)
            .map((location: ISelect) => location.value).map(Number);

        filterJson["siteNumbers"] = siteNumbers && siteNumbers.length ? siteNumbers : "";
        filterJson["clientNumber"] = selectedAssessmentClient.value;

        let config = {
            headers: {
                filter: JSON.stringify(filterJson)
            }
        }
        let event: any = {
            filter: assessmentGridDataState.filter
        }
        this.props.handleAssessmentsGridFilterChanged({
            event,
            config,
            apiPayload: this._apiPayload,
            offset: 0,
            limit: this.props.AssessmentsState.limit
        })
    }

    public savetoExcel = (component: any): void => {
        const options = component.workbookOptions();
        const rows = options.sheets[0].rows;

        let cellIndexOfStatus = [];
        rows.forEach((row: any) => {
            if (row.type === "data") {
                row.cells.forEach((cell: any, index: any) => {
                    //cell.background = "#fff";
                    if (cell.format === "date") {
                        cell.value = cell.value ? moment.utc(new Date(cell.value)).local().format(DATE_FORMAT) : ""
                    }
                    if (index === 5)
                        cell.value = cell.value ? cell.value === "1" ? "Accepted" : cell.value === "2" ? "Rejected" : "OTHERS" : "";
                    if (cell.format === "custom") {
                        cell.value = ItemStatusCustomCell.getStatus(cell.value)
                    }
                    // if (index === cellIndexOfStatus[0] && CONSTANTS.ASSET_STATUS[cell.value]) {
                    //     cell.background = CONSTANTS.ASSET_STATUS[cell.value].color;
                    //     cell.value = CONSTANTS.ASSET_STATUS[cell.value].status;
                    // }
                });
            }
            if (row.type === "header") {
                row.cells.forEach((cell: any, index: number) => {
                    if (cell.value === "Status") {
                        cellIndexOfStatus.push(index);
                    }
                });
            }
        });

        component.save(options);
    };

    public refreshData = (): void => {

        let {
            selectedAssessmentClient,
            selectedAssessmentSite,
        }: IAssessmentsInitialState = this.props.AssessmentsState;

        let siteNumbers = selectedAssessmentSite?.filter((location: ISelect) => location.value > 0)
            .map((location: ISelect) => location.value).map(Number);

        let config = {
            headers: {
                filter: JSON.stringify({
                    siteNumbers: (siteNumbers && siteNumbers.length) ? siteNumbers : "",
                    clientNumber: selectedAssessmentClient.value
                })
            }
        }


        this.props.loadAssessmentDashboardAsync({
            isLoading: true,
            apiPayload: this._apiPayload,
            offset: -1,
            limit: this.props.AssessmentsState.limit,
            config: config,
            isReset: true
        })
    }


    public CategoryFilterCell = (props: GridFilterCellProps) => (
        <DropdownFilterCell
            {...props}
            data={["1-Accepted", "2-Rejected"]}
            defaultValue={"--Select--"}
        />
    );

    public StatusFilterCell = (props: GridFilterCellProps) => (
        <DropdownFilterCell
            {...props}
            data={["Valid", "Due Shortly", "Expired"]}
            defaultValue={"--Select--"}
        />
    );

    public render(): JSX.Element {

        let {
            isLoadingAssessmentsDashboard,
            proccessedAssessments,
            assessmentGridDataState,
            assessments,
            isHideCertificatePanel,
            isOpenAssessmentHistoryPanel,
            issueDateRange,
            expiryDateRange,
            selectedAssessmentClient,
            selectedAssessmentSite,
            showColumns
        }: IAssessmentsInitialState = this.props.AssessmentsState;

        return (
            <React.Fragment>

                <LoadingOverlay
                    active={isLoadingAssessmentsDashboard}
                    spinner={<FadeLoader />}
                    text="Loading Assessments...">

                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 assessment-grid">
                                <Grid
                                    onExpandChange={(event: any) => {
                                        this.props.handleAssessmentsGridExpandChanged({
                                            event: event
                                        })
                                    }}
                                    data={proccessedAssessments}
                                    expandField="expanded"
                                    resizable
                                    reorderable
                                    // groupable
                                    sortable
                                    filterable
                                    {...assessmentGridDataState}
                                    // cellRender={this.cellRender}
                                    style={{ width: '100%' }}
                                    onDataStateChange={(event) => {
                                        this.props.handleAssessmentsGridGroupSortChanged({
                                            event: event
                                        })
                                    }}
                                    onFilterChange={(event) => {

                                        let filterJson = {};

                                        let siteNumbers = selectedAssessmentSite?.filter((location: ISelect) => location.value > 0)
                                            .map((location: ISelect) => location.value).map(Number);

                                        filterJson["siteNumbers"] = siteNumbers && siteNumbers.length ? siteNumbers : "";
                                        filterJson["clientNumber"] = selectedAssessmentClient.value;

                                        /**** */
                                        let startDueDate = expiryDateRange?.start;
                                        let endDueDate = expiryDateRange?.end;

                                        if (startDueDate && endDueDate) {
                                            const fromDueDateStr: string =
                                                `${moment(startDueDate).format('YYYY-MM-DD')} 00:00:00`;
                                            const toDueDateStr: string =
                                                `${moment(endDueDate).format('YYYY-MM-DD')} 23:59:59`;
                                            filterJson["dueDate"] = `${fromDueDateStr}#${toDueDateStr}`;
                                        }

                                        let startIssueDate = issueDateRange?.start;
                                        let endIssueDate = issueDateRange?.end;

                                        if (startIssueDate && endIssueDate) {
                                            const fromStartDateStr: string =
                                                `${moment(startIssueDate).format('YYYY-MM-DD')} 00:00:00`;
                                            const toStartDateStr: string =
                                                `${moment(endIssueDate).format('YYYY-MM-DD')} 23:59:59`;
                                            filterJson["actualInspectionDate"] = `${fromStartDateStr}#${toStartDateStr}`;
                                        }

                                        if (event.filter) {
                                            event.filter.filters.forEach((filter: IKendoGridFilter) => {
                                                filterJson[filter.field] = filter.value;
                                            });
                                        }
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify(filterJson)
                                            }
                                        }
                                        this.props.handleAssessmentsGridFilterChanged({
                                            event,
                                            config,
                                            apiPayload: this._apiPayload,
                                            offset: 0,
                                            limit: this.props.AssessmentsState.limit
                                        })

                                    }}
                                    filterOperators={{
                                        text: [
                                            {
                                                text: 'grid.filterContainsOperator',
                                                operator: 'contains',
                                            }
                                        ],
                                        'numeric': [
                                            { text: 'grid.filterGtOperator', operator: 'eq' }
                                        ],
                                    }}
                                >
                                    {CommonUtility.sortByColumn(CONSTANTS.ASSESSMENT_VIEW_GRID_COLUMNS, "colIndex", "asc")
                                        .filter((column: IKendoGridColumn) => column.show
                                            && showColumns.indexOf(column.field) > -1)
                                        .map((column: IKendoGridColumn, index: number) => {
                                            return (
                                                <GridColumn
                                                    key={index}
                                                    field={column.field}
                                                    title={column.title}
                                                    width={column.width}
                                                    filter={column.filter === 'text' ? 'text' : 'numeric' ? 'numeric' : 'date'}
                                                    filterable={
                                                        column.field === 'jobNumber' ||
                                                        column.field === 'quotationNumber' ||
                                                        column.field === 'location' ||
                                                        column.field === 'acronymCode' ||
                                                        column.field === 'certificateNumber' ||
                                                        column.field === 'specifications' ||
                                                        column.field === 'result' ||
                                                        column.field === 'itemStatus' ||
                                                        column.field === 'taskType'
                                                    }
                                                    cell={
                                                        column.filter === 'date'
                                                            ? this.assessmentsDateCustomCell :
                                                            column.field === 'id' && column.title === 'Report'
                                                                ? this.idCustomCell
                                                                :
                                                                column.field === 'id' && column.title === 'History'
                                                                    ? this.historyCustomCell
                                                                    :
                                                                    column.field === 'result'
                                                                        ? this.resultCustomCell
                                                                        : column.field === 'itemStatus'
                                                                            ? this.itemStatusCustomCell
                                                                            : column.field === 'specifications'
                                                                                ? this.specificationsCustomCell
                                                                                : assessments[column.field]
                                                    }
                                                    filterCell={
                                                        column.field === 'result'
                                                            ? this.CategoryFilterCell :
                                                            column.field === 'itemStatus' ? this.StatusFilterCell :
                                                                null}
                                                />
                                            );
                                        })}
                                    <GridToolbar>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                                                    <ActionButton
                                                        disabled={isLoadingAssessmentsDashboard}
                                                        text="Reload"
                                                        iconProps={IconConstants.refreshIcon}
                                                        onClick={() => this.refreshData()}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 px-0">
                                                    <DateRangePicker
                                                        style={{
                                                            color: "#000"
                                                        }}
                                                        endDateInputSettings={
                                                            {
                                                                label: `Issue Date To`,
                                                            }
                                                        }
                                                        startDateInputSettings={
                                                            {
                                                                label: `Issue Date From`,
                                                            }
                                                        }
                                                        value={issueDateRange}
                                                        onChange={(event: DateRangePickerChangeEvent) => {
                                                            const {
                                                                start,
                                                                end
                                                            } = event.target.value;

                                                            this.props.changeFilterDateChange({
                                                                dateField: "actualInspectionDate",
                                                                dateRangeValue: event.target.value
                                                            })
                                                            if (start && end) {
                                                                this.onChangeDateInput("actualInspectionDate", event);
                                                            }

                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1  px-0">
                                                    <ActionButton
                                                        disabled={isLoadingAssessmentsDashboard || (!issueDateRange?.start && !issueDateRange?.end)}
                                                        text="Clear"
                                                        iconProps={IconConstants.deleteicon}
                                                        onClick={() => {
                                                            this.props.changeFilterDateChange({
                                                                dateField: "actualInspectionDate",
                                                                dateRangeValue: {
                                                                    start: null,
                                                                    end: null
                                                                }
                                                            });
                                                            this.onChangeDateInput("actualInspectionDate", null);

                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 px-0">
                                                    <DateRangePicker
                                                        style={{
                                                            color: "#000"
                                                        }}
                                                        endDateInputSettings={
                                                            {
                                                                label: `Expiry Date To`,
                                                            }
                                                        }
                                                        startDateInputSettings={
                                                            {
                                                                label: `Expiry Date From`,
                                                            }
                                                        }
                                                        value={expiryDateRange}
                                                        onChange={(event: DateRangePickerChangeEvent) => {
                                                            const {
                                                                start,
                                                                end
                                                            } = event.target.value;
                                                            this.props.changeFilterDateChange({
                                                                dateField: "dueDate",
                                                                dateRangeValue: event.target.value
                                                            })
                                                            if (start && end) {
                                                                this.onChangeDateInput("dueDate", event);
                                                            }

                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1 px-0">
                                                    <ActionButton
                                                        disabled={isLoadingAssessmentsDashboard || (!expiryDateRange?.start && !expiryDateRange?.end)}
                                                        text="Clear"
                                                        iconProps={IconConstants.deleteicon}
                                                        onClick={() => {
                                                            this.props.changeFilterDateChange({
                                                                dateField: "dueDate",
                                                                dateRangeValue: {
                                                                    start: null,
                                                                    end: null
                                                                }
                                                            });
                                                            this.onChangeDateInput("dueDate", null);

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <PrimaryButton
                                                        className="table-export-button-div"
                                                        text="Export to Excel"
                                                        iconProps={IconConstants.excelIcon}
                                                        onClick={() => this.export()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </GridToolbar>
                                </Grid>

                                <ExcelExport
                                    data={assessments}
                                    ref={exporter => this._excelExport = exporter}
                                    fileName={`Tasks_on_${moment.utc(new Date()).local().format(DATE_TIME_FORMAT_USER)}.xlsx`}
                                >
                                    {CONSTANTS.ASSESSMENT_VIEW_GRID_COLUMNS
                                        .filter((column) => column.field !== "id" && column.show)
                                        .map((column, index) => {
                                            return (
                                                <ExcelExportColumn
                                                    key={index}
                                                    field={column.field === 'itemStatus' ? "dueDate" : column.field}
                                                    title={column.title}
                                                    cellOptions={{
                                                        format: column.filter === "date" ? "date"
                                                            : column.filter === "custom" ? "custom" : "",
                                                    }}
                                                />
                                            );
                                        })}
                                </ExcelExport>

                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
                {
                    !isHideCertificatePanel &&
                    <CertificatePreviewPanel
                        {...this.props}
                    />
                }
                {
                    isOpenAssessmentHistoryPanel &&
                    <AssessmentHistoryPanel
                        {...this.props}
                    />
                }
            </React.Fragment >
        )
    }
}
