import { ILoggedInUser } from "../../../CommonInterfaces/CommoInterfaces";
import axios from 'axios';
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import { ISequenceService } from "../Interfaces/ISequenceService";
import { ISequence } from "../Interfaces/ISequence";
import { GET_SAVE_SEQUENCE_URL } from "../Constants/SequenceNumberingConstants";


export class SequenceService implements ISequenceService {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }

    public saveSequence = (sequence: ISequence): Promise<ISequence> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(GET_SAVE_SEQUENCE_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                , sequence
            ).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                console.error("Error saveSequence method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getSequences = (): Promise<ISequence[]> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_SAVE_SEQUENCE_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then((response: any) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getSequences method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

}
