import axios from 'axios';
import {
    GET_COUNT_URL,
    GET_DATA_URL,
    ADD_UPDATE_DELETE_COUNT_URL,
    UPLOAD_CUSTOMER_IMAGE_URL,
    GET_CUSTOMER_IMAGE_URL
} from './CustomerConstant';
import { ALERT_MESSAGE } from '../../Constants/MessageConstants';

export class CustomerAPI {

    _URL = "";
    _config = "";

    constructor(URL, config) {
        this._URL = URL;
        this._config = config;
    }

    _getDataSize() {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(GET_COUNT_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), this._config).then(responseSize => {
                    resolve(responseSize);
                }).catch(error => {
                    console.error("Error _getDataSize method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    _getData(sortOrder, sortField, page) {
        let DATA_URL = GET_DATA_URL;
        DATA_URL += origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}?` : "?";
        if (page) {
            DATA_URL += `offset=${parseInt((page - 1) * 10)}&&limit=10`;
        }
        else {
            DATA_URL += `offset=0&&limit=10`;
        }
        if (sortField && sortField) {
            DATA_URL += `&&sortOrder=${sortOrder}&&sortField=${sortField}`;
        }
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(DATA_URL), this._config).then(responseData => {
                resolve(responseData);
            }).catch(error => {
                console.error("Error _getData method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    loadData(sortOrder, sortField, page) {
        let promises = [this._getData(sortOrder, sortField, page), this._getDataSize()];
        return Promise.all(promises);
    }

    addCustomer(payload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(ADD_UPDATE_DELETE_COUNT_URL)
                .concat(origin === "http://localhost:3000" ?
                    `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error addCustomer method", error);
                    if (error.response && error.response.data) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    updateCustomer(payload) {
        return new Promise((resolve, reject) => {
            axios.put(this._URL.concat(ADD_UPDATE_DELETE_COUNT_URL)
                .concat(origin === "http://localhost:3000" ?
                    `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), payload).then(responseData => {
                        resolve(responseData);
                    }).catch(error => {
                        console.error("Error updateCustomer method", error);
                        if (error.response && error.response.data) {
                            reject(error.response.data.error);
                        }
                        else {
                            reject(ALERT_MESSAGE.COMMON_ERROR);
                        }
                    });
        });
    }

    deleteCustomer(id) {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL.concat(`${ADD_UPDATE_DELETE_COUNT_URL}/${id}`)
                .concat(origin === "http://localhost:3000" ?
                    `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), this._config).then(responseData => {
                        resolve(responseData);
                    }).catch(error => {
                        console.error("Error deleteCustomer method", error);
                        if (error.response && error.response.status === 401) {
                            reject(ALERT_MESSAGE.ERROR_401);
                        }
                        else {
                            reject(ALERT_MESSAGE.COMMON_ERROR);
                        }
                    });
        });
    }

    _uploadImageOfCustomer = (id, uploadImageData) => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${UPLOAD_CUSTOMER_IMAGE_URL}/${id}`)
                .concat(origin === "http://localhost:3000" ?
                    `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``),
                uploadImageData)
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error _uploadImageOfCustomer method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    async _getImageOfCustomer(id) {

        let imageResponse = await axios.get(this._URL
            .concat(`${GET_CUSTOMER_IMAGE_URL}/${id}`)
            .concat(origin === "http://localhost:3000" ?
                `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``));

        return imageResponse.data;

        // return new Promise((resolve, reject) => {
        //     axios.get(this._URL
        //         .concat(`${GET_CUSTOMER_IMAGE_URL}/${id}`)
        //         .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``))
        //         .then(responseData => {
        //             resolve(responseData);
        //         }).catch(error => {
        //             console.error("Error _getImageOfCustomer method", error);
        //             if (error.response && error.response.status === 401) {
        //                 reject(ALERT_MESSAGE.ERROR_401);
        //             }
        //             else {
        //                 reject(ALERT_MESSAGE.COMMON_ERROR);
        //             }
        //         });
        // });
    }

}
