export const JobInitialState = {
    Jobs: [],
    TotalJobsCount: 0,
    HasMoreJobs: true,
    SelectedJob: null,
    tabIndex: 0,
    offset: 0,
    limit: 500,
    filters: {},
    ActiveStep: 0,
    CurrentJobDetails: {},
    LoadingDashboard: false,
    LoadingPanel: false,
    LoadingSites: false,
    ShowPanel: false,
    ShowActiveJobs: true,
    PanelHeaderText: "",
    showLLGButton: false,
    showTimeClock: false,
    ClientOptions: [],
    OfficeOptions: [],
    SiteOptions: [],
    RequesterOptions: [],
    AllInspectorOptions: [],
    InspectorOptions: [],
    AllTaskTypeOptions: [],
    SelectedAssetType: null,
    NewAsset: {
        value: "",
        error: true,
        errorMessage: ""
    },
    LoadingAssetSection: false,
    DivisionOptions: [],
    Roles: [],
    AssetTypeOptions: [],
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    hideConfirm: true,
    sortedColumn: "",
    sortOrder: "asc",
    job_status_filter_value: "",
    job_number_filter_value: "",
    job_client_filter_value: "",
    job_site_filter_value: "",
    job_qn_filter_value: "",
    ShowAddNewSiteSection: false,
    LoadingNewSiteSection: false,
    NewSiteSaveStatus: {
        flag: true,
        message: ""
    },
    NewSite: {
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin: 0,
        phone: "",
        locationLevel: 0
    },
    ShowAddNewUserSection: false,
    LoadingNewUserSection: false,
    NewUserSaveStatus: {
        flag: true,
        message: ""
    },
    NewUser: {
        name: "",
        email: "",
        phone: ""
    },
    IsJobEditable: true,
    closeOrCancel: "",
    jobDashboardColumns: [
        'jobNumber',
        'client',
        'site',
        'inspector',
        'comments',
        'startDate',
        'quotationNumber',
        'status',
    ],
    JobBreadCrumb: [
        { text: 'OPERATIONS', key: 'Operations' },
        { text: 'Jobs', key: 'Jobs' }
    ],
    isLoadingJobFiles: false,

}