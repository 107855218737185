import * as React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { IClientInventoryAPI } from '../ClientInventoryInteface/IClientInventory';
import { ClientInventoryAPI } from '../Services/ClientInventoryAPI';
import { IClientInventoryStateProps } from '../ClientInventoryInteface/IClientInventoryStateProps';
import { IconConstants } from '../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class ClientInventoryDeleteConfirmModal extends React.PureComponent<IClientInventoryStateProps, {}> {


    private _apiPayload: IClientInventoryAPI = null;
    constructor(props) {
        super(props);
        this._apiPayload = new ClientInventoryAPI(window.API_URL, window.loggedInUserDetails);
    }

    public componentDidMount() {
    }

    public deleteEntity = (): void => {
        let {
            selectedClientInventory
        } = this.props.ClientInventoryState;

        this.props.deleteClientInventoryById({
            apiObject: this._apiPayload,
            selectedClientInventory,
        })
    }


    public render(): JSX.Element {
        let {
            selectedClientInventory,
            isOpenDeleteConfirmModal,
        } = this.props.ClientInventoryState;

        const headerSubtext: string = `Are you sure want to delete ?`;

        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: headerSubtext
        };
        return (
            <Dialog
                hidden={!isOpenDeleteConfirmModal}
                onDismiss={() => this.props.openCloseClientInventoryConfirmDeletetModal({
                    isOpenDeleteConfirmModal: false,
                    selectedClientInventory: null
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => this.deleteEntity()}
                        text="Yes"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseClientInventoryConfirmDeletetModal({
                            isOpenDeleteConfirmModal: false,
                            selectedClientInventory: selectedClientInventory
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
