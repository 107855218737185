export const BASE_URL = {
    PROD: "https://liftekandroid.appspot.com/",
    PREPROD: "https://qrcode-dot-liftekandroid.appspot.com/",
    LIFTMAX: "https://qrcode-dot-liftekandroid.appspot.com/",
    LOCAL: "http://localhost:8080/",
}

export const PDF_BUCKET = "https://storage.googleapis.com/docxtopdf/";
export const IFRAME_GOOGLE_VIEW = "https://docs.google.com/viewer?SameSite=None&Secure&url=";
export const IFRAME_EMBED = "&embedded=true";

// Roby/clientcompany/1/site/5/schedule/1/task/1_preview.pdf