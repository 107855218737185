import { CategoryInitialState } from "../Constants/CategoryIntialState";
import { ICategoryAction, ICategoryInitialState } from "../Interfaces/ICategory";

export function CategoryReducer(state: ICategoryInitialState = CategoryInitialState, action: ICategoryAction): ICategoryInitialState {

    let tempState: ICategoryInitialState = { ...state };
    // let payload: ICategoryPayload = action.payload;

    // console.log(payload);

    switch (action.type) {

        default:
            break;
    }

    return tempState;

}