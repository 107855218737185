import { Icon, TooltipHost } from 'office-ui-fabric-react';
import React from 'react';
import { calloutProps, hostStyles, IconConstants, tooltipId } from '../../../Constants/StylesConstants';
import * as CONSTANTS from './../Constants/AssetConstants';
import { JOB_STATUS } from './../../Jobs/Constants/JobConstants';
import moment from 'moment-timezone';

export class StatusCustomCell extends React.Component {
    render() {

        const value = this.props.dataItem[this.props.field];

        return (
            <td className="no-group-show">
                <span>{CONSTANTS.ASSET_STATUS[value].status}</span>
            </td>
        );
    }
}

export class ResultCustomCell extends React.Component {
    render() {

        const value = this.props.dataItem[this.props.field];
        const viewValue = value === 1 ? 'Pass' : value === 2 ? 'Fail' : value === 3 ? 'Pending' : 'N/A';
        const spanClassName = value === 1 ? 'success-span' : value === 2 ? 'mandatory-span' : 'info-span';

        return (
            <td className="no-group-show">
                <span className={spanClassName}>{viewValue}</span>
            </td>
        );
    }
}

export class StatusIconCustomCell extends React.Component {
    render() {

        const value = this.props.cellContent;
        // 1: "Active",
        // 2: "Ongoing",
        // 4: "Completed",
        // 16: "Closed",
        // 64: "Cancelled",
        // 1024: "Deleted"
        switch (JOB_STATUS[value]) {
            case "Active":
                return (
                    <Icon iconName={IconConstants.activeStatusIcon.iconName} />
                );
            case "Ongoing":
                return (
                    <Icon iconName={IconConstants.onGoingStatusIcon.iconName} />
                );
            case "Completed":
                return (
                    <Icon iconName={IconConstants.completedStatusIcon.iconName} />
                );
            case "Closed":
                return (
                    <Icon iconName={IconConstants.closeStatusIcon.iconName} />
                );
            case "Cancelled":
                return (
                    <Icon iconName={IconConstants.cancelStatusIcon.iconName} />
                );
            case "Deleted":
                return (
                    <Icon iconName={IconConstants.deleteicon.iconName} />
                );

            default:
                return (
                    <Icon iconName={IconConstants.deleteicon.iconName} />
                );
        }

    }
}

export class ItemStatusCustomCell extends React.Component {

    static getStatus = (value) => {
        let diffToDueDate = 0;
        if (value) {
            const momentDueDate = moment(new Date(value))
            const momentToday = moment(new Date())
            diffToDueDate = momentDueDate.diff(momentToday, 'days'); // if greater than zero then due date is greater
            // console.log("Due Date: ", momentDueDate, " Today: ", momentToday, " Diff:", momentDueDate.diff(momentToday, 'days'));
        }
        return diffToDueDate <= 0 ? 'Expired' : diffToDueDate > 15 ? 'Valid' : 'Due Shortly';
    }

    render() {

        const value = this.props.dataItem['dueDate'];
        const viewValue = ItemStatusCustomCell.getStatus(value);
        const spanClassName = viewValue === 'Expired' ? 'mandatory-span' : viewValue === 'Valid' ? 'success-span' : 'warning-span';

        return (
            <td className="no-group-show">
                <span className={spanClassName}>{viewValue}</span>
            </td>
        );

    }
}

export class IdCustomCell extends React.Component {
    render() {

        const value = this.props.dataItem[this.props.field];

        return (
            <td className="no-group-show">
                <TooltipHost
                    //content={`${value}`}
                    id={tooltipId}
                    calloutProps={calloutProps}
                    styles={hostStyles}
                >

                    <Icon
                        onClick={() => {
                            this.props.currentProps.openCloseAddEditAssetPanel({
                                hideAddEditAssetPanel: false,
                                selectedAssetId: value
                            })
                        }}
                        iconName={IconConstants.editicon.iconName}
                    />
                </TooltipHost>
            </td>
        );
    }
}