import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ClientActions } from '../Actions/ClientActions';
import { Breadcrumb } from 'office-ui-fabric-react';
import ClientDashboard from './ClientDashboard';

const mapStateToProps = (state) => {
    return {
        Client: state.ClientReducer
    }
}

class ClientComponent extends PureComponent {

    onClickClientBreadCrumbItem = (ev, item) => {
        switch (item.key) {
            case "Clients":
                this.props.showClientDetailsTab({
                    SelectedClient: null,
                    ShowClientDetailsTab: false
                });
                break;
            default:
                break;
        }
    }

    render() {

        let { ClientBreadCrumb } = this.props.Client;

        let ConfiguredBreadCrumb = [];
        ClientBreadCrumb.forEach((element, index) => {
            ConfiguredBreadCrumb.push({
                text: element.text,
                key: element.key,
                onClick: index < ClientBreadCrumb.length - 1 ? this.onClickClientBreadCrumbItem : null,
            })
        });

        return (
            <React.Fragment>
                {/* <Label className="breadcrumb-label">Lifting / Resources / Clients</Label> */}
                <Breadcrumb
                    className="custom-breadcrumb"
                    items={ConfiguredBreadCrumb}
                    ariaLabel="With last item rendered as heading"
                    overflowAriaLabel="More links"
                />
                <ClientDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let clientActions = new ClientActions();

    return bindActionCreators({ ...clientActions }, dispatch);
}

const Clients = connect(mapStateToProps, mapDispatchToProps)(ClientComponent);
export default Clients;
