import React, { PureComponent } from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { UserAPI } from '../Users/Services/UserAPI';
import { IconConstants } from '../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class ConfirmRedux extends PureComponent {

    _userApi = null;

    constructor(props) {
        super(props);
        this._userApi = new UserAPI(window.API_URL);

    }

    render() {
        let { hideConfirm, CurrentUserId, ToBeUpdatedStatus } = this.props.User;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `Are you sure want to ${ToBeUpdatedStatus} the user?`
        };
        return (
            <Dialog
                hidden={hideConfirm}
                onDismiss={() => this.props.openCloseConfirmModal({
                    CurrentUserId: -1,
                    hideConfirm: true
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            this.props.deleteUser({
                                id: CurrentUserId,
                                apiObject: this._userApi,
                                ToBeUpdatedStatus: ToBeUpdatedStatus
                            })

                        }}
                        text="Yes"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseConfirmModal({
                            CurrentUserId: -1,
                            hideConfirm: true
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
