import React, { PureComponent } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { IconButton, ActionButton, Toggle, SearchBox, PrimaryButton, Dropdown } from 'office-ui-fabric-react';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import filterFactory from 'react-bootstrap-table2-filter';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { UserAPI } from '../Services/UserAPI';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ConfirmRedux from '../../Alert/ConfirmRedux.jsx';
import AlertRedux from '../../Alert/AlertRedux.jsx';
import AddEditUserPanel from './AddEditUserPanel';
import { USER_STATUS_REV } from '../Constants/UserConstants';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { USER_STATUS_DROPDOWN_FILTER_OPTIONS } from './../Constants/UserConstants';
import { DropdownStyles, IconConstants } from '../../../Constants/StylesConstants';
import { CLIENT_ADMIN_ROLE } from '../../../Constants/CommonConstants';
import * as CONSTANTS from './../Constants/UserConstants';

const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
}];

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="table-export-button-div">
            <PrimaryButton
                onClick={handleClick}
                text="Export to CSV"
                menuIconProps={IconConstants.exportIcon}
            />
        </div>
    );
};

const RemoteAll = ({
    data, columns, onTableChange
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
            exportCSV={{
                fileName: 'Qwikspec_Users.csv',
                noAutoBOM: false,
                blobType: 'text/csv;charset=ansi'
            }}
        >
            {
                props => (
                    <React.Fragment>

                        <MyExportCSV {...props.csvProps} />
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({})}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            wrapperClasses="table-responsive" />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class UserDashboard extends PureComponent {

    _userApi = null;

    constructor(props) {
        super(props);
        this._userApi = new UserAPI(window.API_URL);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {
        this.props.cleanDashboard({
            Users: [],
            offset: 0,
            loadingDashboard: true
        });
        let config = {
            headers: {
            }
        }
        this.props.loadDashboard({
            offset: 0,
            limit: this.props.User.limit,
            apiObject: this._userApi,
            isScrolled: false,
            config: config
        });
    }

    fetchMoreData = () => {

        let filterJson = {};

        let User = this.props.User;

        filterJson = {
            "name": User.user_name_filter_value,
            "email": User.user_email_filter_value,
            "role": User.user_designation_filter_value,
            "location": User.user_location_filter_value,
            "status": User.user_status_filter_value,
            "division": User.user_division_filter_value,
        };
        let config = {
            headers: {
                filter: JSON.stringify(filterJson)
            }
        }


        // for (let dataField in filters) {
        //     filterJson[dataField] = filters[dataField].filterVal
        // }

        // if (Object.keys(filterJson).length > 0) {
        //     config = {
        //         headers: {
        //             filter: JSON.stringify(filterJson)
        //         }
        //     }
        // }

        if (this.props.User.TotalUsersCount === this.props.User.Users.length) {
            this.props.fixDashboard({
                HasMoreUsers: false
            })
            return;
        }
        else {
            this.props.loadDashboard({
                offset: this.props.User.offset,
                limit: this.props.User.limit,
                apiObject: this._userApi,
                isScrolled: true,
                config: config
            });

        }
    };


    handleTableChange = (type, { filters, sortField, sortOrder }) => {

        // console.log(sortOrder);

        let filterJson = {};

        for (let dataField in filters) {
            filterJson[dataField] = filters[dataField].filterVal
        }

        let config = {
            headers: {
            }
        }
        if (Object.keys(filterJson).length > 0) {
            config = {
                headers: {
                    filter: JSON.stringify(filterJson)
                }
            }
        }

        if (type.toUpperCase() === "FILTER") {

            this.props.cleanDashboard({
                filtered: true,
                offset: 0,
                loadingDashboard: true
            });

            this.props.loadDashboard({
                offset: 0,
                limit: this.props.User.limit,
                apiObject: this._userApi,
                loadingDashboard: false,
                filtered: true,
                config: config,
                isScrolled: true,
                filters: filters
            })
        }
        else if (type.toUpperCase() === "SORT") {
            setTimeout(() => {

                let result = [...this.props.User.Users];


                // Handle column sort
                if (sortOrder === 'asc') {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return 1;
                        } else if (b[sortField] > a[sortField]) {
                            return -1;
                        }
                        return 0;
                    });
                } else {
                    result = result.sort((a, b) => {
                        if (a[sortField] > b[sortField]) {
                            return -1;
                        } else if (b[sortField] > a[sortField]) {
                            return 1;
                        }
                        return 0;
                    });
                }

                this.props.sortDashboard({
                    sortedUsers: result,
                    sortedColumn: sortField,
                    sortOrder
                });

            }, 1000);
        }

    }

    removeArchiveSuspendUser = (event, val, e) => {

        let ToBeUpdatedStatus = val.key;

        this.props.openCloseConfirmModal({
            id: 1,
            hideConfirm: false,
            ToBeUpdatedStatus: ToBeUpdatedStatus
        });

    }

    render() {

        let {
            Users,
            HasMoreUsers,
            loadingDashboard,
            hideConfirm,
            alertJSON,
            ShowPanel,
            ShowActiveUsers,
            sortedColumn,
            sortOrder,
            user_name_filter_value,
            user_email_filter_value,
            user_designation_filter_value,
            user_location_filter_value,
            user_status_filter_value,
            user_division_filter_value
        } = this.props.User;

        const isClientAdmin = window.loggedInUserDetails?.role === CLIENT_ADMIN_ROLE;

        let columns = [
            {
                dataField: 'manageUserIcons',
                isDummyField: true,
                text: 'Action',
                csvExport: false,
                formatter: (cellContent, row) => {
                    return (
                        <span className="action-span">
                            {row.status === 1 &&
                                <IconButton
                                    // disabled
                                    onClick={() => this.props.opencloseAddEditUserPanel({
                                        ShowPanel: true,
                                        PanelHeaderText: "",
                                        SelectedUser: row
                                    })}
                                    iconProps={IconConstants.editicon} />
                            }
                            {row.status !== -1
                                && (row.status === CONSTANTS.USER_STATUS["Archive"]
                                    || row.status === CONSTANTS.USER_STATUS["Suspend"]
                                    || row.status === CONSTANTS.USER_STATUS["Activate"])
                                &&
                                <IconButton
                                    title="Remove User"
                                    onClick={() => this.props.openCloseConfirmModal({
                                        id: row.id,
                                        hideConfirm: false,
                                        ToBeUpdatedStatus: "Remove"
                                    })}
                                    iconProps={IconConstants.deleteicon} />
                            }
                            {row.status !== -2
                                && (row.status === CONSTANTS.USER_STATUS["Suspend"]
                                    || row.status === CONSTANTS.USER_STATUS["Activate"])
                                &&
                                <IconButton
                                    title="Archive User"
                                    onClick={() => this.props.openCloseConfirmModal({
                                        id: row.id,
                                        hideConfirm: false,
                                        ToBeUpdatedStatus: "Archive"
                                    })}
                                    iconProps={IconConstants.archiveIcon} />
                            }
                            {row.status !== -3
                                && (row.status === CONSTANTS.USER_STATUS["Archive"]
                                    || row.status === CONSTANTS.USER_STATUS["Activate"])
                                &&
                                <IconButton
                                    title="Suspend User"
                                    onClick={() => this.props.openCloseConfirmModal({
                                        id: row.id,
                                        hideConfirm: false,
                                        ToBeUpdatedStatus: "Suspend"
                                    })}
                                    iconProps={IconConstants.suspendIcon} />
                            }
                            {row.status < 0
                                && (row.status === CONSTANTS.USER_STATUS["Archive"]
                                    || row.status === CONSTANTS.USER_STATUS["Suspend"])
                                &&
                                <IconButton
                                    title="Activate User"
                                    onClick={() => this.props.openCloseConfirmModal({
                                        id: row.id,
                                        hideConfirm: false,
                                        ToBeUpdatedStatus: "Activate"
                                    })}
                                    iconProps={IconConstants.activateOrdersIcon} />
                            }

                        </span>
                    )
                }
            },
            {
                csvExport: false,
                dataField: 'id',
                text: 'User ID',
                sort: true,
                // filter: textFilter(),
                hidden: true,
                title: (cell) => `${cell}`
            },

            {
                dataField: 'name',
                text: 'Name',
                // filter: textFilter(),
                sort: true,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'email',
                text: 'Email',
                sort: true,
                // filter: textFilter(),
                title: (cell) => `${cell}`
            },

            {
                dataField: 'designation',
                text: 'Role',
                // filter: textFilter(),
                sort: true,
                title: (cell) => `${cell}`
            },
            {
                dataField: 'description',
                text: 'Description',
                // filter: textFilter(),
                sort: true,
                hidden: true,
                title: (cell) => `${cell}`
            },

            {
                dataField: 'status',
                text: 'Status',
                // filter: textFilter(),
                hidden: ShowActiveUsers,
                sort: true,
                formatter: (cellContent, row) => {
                    return (
                        <span>{USER_STATUS_REV[cellContent]}</span>
                    )
                }
                // title: (cell) => `${cell}`,

            }
        ];

        if (!isClientAdmin) {
            columns.splice(7, 0,
                {
                    dataField: 'type',
                    text: 'Type',
                    sort: true,
                    // title: (cell) => `${cell}`,
                    formatter: (cellContent) => {
                        return (
                            <span>{cellContent === 0 ? `LO` : `CLIENT`}</span>
                        )
                    }
                },
                {
                    dataField: 'externalId',
                    text: 'External',
                    sort: true,
                    // filter: textFilter(),
                    title: (cell) => `${cell}`

                },

                {
                    dataField: 'location',
                    text: 'Location',
                    // filter: textFilter(),
                    sort: true,
                    title: (cell) => `${cell}`
                },
                {
                    dataField: 'division',
                    text: 'Division',
                    // filter: textFilter(),
                    sort: true,
                    title: (cell) => `${cell}`
                },
                {
                    dataField: 'phone',
                    text: 'Mobile',
                    sort: true,
                    // filter: textFilter(),
                    title: (cell) => `${cell}`

                },
                {
                    dataField: 'location',
                    text: 'Company',
                    // filter: textFilter(),
                    sort: true,
                    hidden: false,
                    title: (cell) => `${cell}`
                },)
        }

        let filteredUsers = [];
        if (ShowActiveUsers) {
            filteredUsers = [...Users.filter(user => user.status === 1)]
        }
        else {
            filteredUsers = [...Users.filter(user => user.status !== 1)]
        }

        filteredUsers = CommonUtility.sortByColumn(filteredUsers, sortedColumn, sortOrder);

        return (
            <React.Fragment>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <SearchBox
                                value={user_name_filter_value}
                                placeholder="Name"
                                underlined={true}
                                onSearch={(newValue) => {
                                    let filterJson = {
                                        "name": newValue,
                                        "email": user_email_filter_value,
                                        "role": user_designation_filter_value,
                                        "location": user_location_filter_value,
                                        "division": user_division_filter_value,
                                        "status": user_status_filter_value
                                    };
                                    let config = {
                                        headers: {
                                            filter: JSON.stringify(filterJson)
                                        }
                                    };
                                    this.props.findFilteredUsersAsync({
                                        filterType: "name",
                                        value: newValue,
                                        offset: 0,
                                        limit: this.props.User.limit,
                                        apiObject: this._userApi,
                                        config: config,
                                        filtered: true
                                    });
                                }
                                }
                                onClear={() => {
                                    let filterJson = {
                                        "name": "",
                                        "email": user_email_filter_value,
                                        "role": user_designation_filter_value,
                                        "location": user_location_filter_value,
                                        "division": user_division_filter_value,
                                        "status": user_status_filter_value
                                    };
                                    let config = {
                                        headers: {
                                            filter: JSON.stringify(filterJson)
                                        }
                                    };
                                    this.props.findFilteredUsersAsync({
                                        filterType: "name",
                                        value: "",
                                        offset: 0,
                                        limit: this.props.User.limit,
                                        apiObject: this._userApi,
                                        config: config,
                                        filtered: true
                                    })
                                }
                                }
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <SearchBox
                                value={user_email_filter_value}
                                underlined={true}
                                placeholder="Email"
                                onSearch={(newValue) => {
                                    let filterJson = {
                                        "name": user_name_filter_value,
                                        "email": newValue,
                                        "role": user_designation_filter_value,
                                        "location": user_location_filter_value,
                                        "status": user_status_filter_value,
                                        "division": user_division_filter_value
                                    };
                                    let config = {
                                        headers: {
                                            filter: JSON.stringify(filterJson)
                                        }
                                    };
                                    this.props.findFilteredUsersAsync({
                                        filterType: "email",
                                        value: newValue,
                                        offset: 0,
                                        limit: this.props.User.limit,
                                        apiObject: this._userApi,
                                        config: config,
                                        filtered: true
                                    });
                                }
                                }
                                onClear={() => {
                                    let filterJson = {
                                        "name": user_name_filter_value,
                                        "email": "",
                                        "role": user_designation_filter_value,
                                        "location": user_location_filter_value,
                                        "division": user_division_filter_value,
                                        "status": user_status_filter_value
                                    };
                                    let config = {
                                        headers: {
                                            filter: JSON.stringify(filterJson)
                                        }
                                    };
                                    this.props.findFilteredUsersAsync({
                                        filterType: "email",
                                        value: "",
                                        offset: 0,
                                        limit: this.props.User.limit,
                                        apiObject: this._userApi,
                                        config: config,
                                        filtered: true
                                    })
                                }
                                }
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <SearchBox
                                value={user_designation_filter_value}
                                underlined={true}
                                placeholder="Role"
                                onSearch={(newValue) => {
                                    let filterJson = {
                                        "name": user_name_filter_value,
                                        "email": user_email_filter_value,
                                        "role": newValue,
                                        "location": user_location_filter_value,
                                        "division": user_division_filter_value,
                                        "status": user_status_filter_value
                                    };
                                    let config = {
                                        headers: {
                                            filter: JSON.stringify(filterJson)
                                        }
                                    };
                                    this.props.findFilteredUsersAsync({
                                        filterType: "role",
                                        value: newValue,
                                        offset: 0,
                                        limit: this.props.User.limit,
                                        apiObject: this._userApi,
                                        config: config,
                                        filtered: true
                                    });
                                }
                                }
                                onClear={() => {
                                    let filterJson = {
                                        "name": user_name_filter_value,
                                        "email": user_email_filter_value,
                                        "role": "",
                                        "location": user_location_filter_value,
                                        "division": user_division_filter_value,
                                        "status": user_status_filter_value
                                    };
                                    let config = {
                                        headers: {
                                            filter: JSON.stringify(filterJson)
                                        }
                                    };
                                    this.props.findFilteredUsersAsync({
                                        filterType: "role",
                                        value: "",
                                        offset: 0,
                                        limit: this.props.User.limit,
                                        apiObject: this._userApi,
                                        config: config,
                                        filtered: true
                                    })
                                }
                                }
                            />
                        </div>
                        {!isClientAdmin &&
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                <SearchBox
                                    value={user_location_filter_value}
                                    underlined={true}
                                    placeholder="Location"
                                    onSearch={(newValue) => {
                                        let filterJson = {
                                            "name": user_name_filter_value,
                                            "email": user_email_filter_value,
                                            "role": user_designation_filter_value,
                                            "location": newValue,
                                            "division": user_division_filter_value,
                                            "status": user_status_filter_value
                                        };
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify(filterJson)
                                            }
                                        };
                                        this.props.findFilteredUsersAsync({
                                            filterType: "location",
                                            value: newValue,
                                            offset: 0,
                                            limit: this.props.User.limit,
                                            apiObject: this._userApi,
                                            config: config,
                                            filtered: true
                                        });
                                    }
                                    }
                                    onClear={() => {
                                        let filterJson = {
                                            "name": user_name_filter_value,
                                            "email": user_email_filter_value,
                                            "role": user_designation_filter_value,
                                            "location": "",
                                            "division": user_division_filter_value,
                                            "status": user_status_filter_value
                                        };
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify(filterJson)
                                            }
                                        };
                                        this.props.findFilteredUsersAsync({
                                            filterType: "location",
                                            value: "",
                                            offset: 0,
                                            limit: this.props.User.limit,
                                            apiObject: this._userApi,
                                            config: config,
                                            filtered: true
                                        });
                                    }
                                    }
                                />
                            </div>
                        }
                        {!isClientAdmin
                            &&
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                <SearchBox
                                    value={user_division_filter_value}
                                    underlined={true}
                                    placeholder="Division"
                                    onSearch={(newValue) => {
                                        let filterJson = {
                                            "name": user_name_filter_value,
                                            "email": user_email_filter_value,
                                            "role": user_designation_filter_value,
                                            "location": user_location_filter_value,
                                            "status": user_status_filter_value,
                                            "division": newValue
                                        };
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify(filterJson)
                                            }
                                        };
                                        this.props.findFilteredUsersAsync({
                                            filterType: "division",
                                            value: newValue,
                                            offset: 0,
                                            limit: this.props.User.limit,
                                            apiObject: this._userApi,
                                            config: config,
                                            filtered: true
                                        });
                                    }
                                    }
                                    onClear={() => {
                                        let filterJson = {
                                            "name": user_name_filter_value,
                                            "email": user_email_filter_value,
                                            "role": user_designation_filter_value,
                                            "location": user_location_filter_value,
                                            "status": user_status_filter_value,
                                            "division": ""
                                        };
                                        let config = {
                                            headers: {
                                                filter: JSON.stringify(filterJson)
                                            }
                                        };
                                        this.props.findFilteredUsersAsync({
                                            filterType: "division",
                                            value: "",
                                            offset: 0,
                                            limit: this.props.User.limit,
                                            apiObject: this._userApi,
                                            config: config,
                                            filtered: true
                                        })
                                    }
                                    }
                                />
                            </div>
                        }

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <Dropdown
                                selectedKey={user_status_filter_value}
                                options={USER_STATUS_DROPDOWN_FILTER_OPTIONS}
                                styles={DropdownStyles}
                                onChange={(ev, newVal) => {
                                    let filterJson = {
                                        "name": user_name_filter_value,
                                        "email": user_email_filter_value,
                                        "role": user_designation_filter_value,
                                        "location": user_location_filter_value,
                                        "division": user_division_filter_value,
                                        "status": newVal.key
                                    };
                                    let config = {
                                        headers: {
                                            filter: JSON.stringify(filterJson)
                                        }
                                    };
                                    this.props.findFilteredUsersAsync({
                                        filterType: "status",
                                        value: newVal.key,
                                        offset: 0,
                                        limit: this.props.User.limit,
                                        apiObject: this._userApi,
                                        config: config,
                                        filtered: true
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                            <ActionButton
                                disabled={loadingDashboard}
                                text="Reload"
                                onClick={() => this.loadInitData()}
                                iconProps={IconConstants.refreshIcon}
                            />
                            {HasMoreUsers && !loadingDashboard &&
                                <ActionButton
                                    disabled={loadingDashboard}
                                    text="Load More"
                                    onClick={() => this.fetchMoreData()}
                                    iconProps={IconConstants.moreicon}
                                />
                            }
                            <ActionButton
                                text="Add"
                                onClick={() => this.props.opencloseAddEditUserPanel({
                                    ShowPanel: true,
                                    PanelHeaderText: "ADD USER",
                                })}
                                iconProps={IconConstants.addIcon}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8 inactive-toggle-col">
                            <Toggle
                                checked={ShowActiveUsers}
                                onText="Show Inactive"
                                offText="Show Active"
                                onChange={() => this.props.switchUserView({
                                    ShowActiveUsers: !ShowActiveUsers
                                })}
                            />
                        </div>
                    </div>
                </div>

                <LoadingOverlay
                    active={loadingDashboard}
                    spinner={<FadeLoader />}
                    text="Loading Users...">

                    {/* {Users.length > 0 && */}
                    <div className="lazy-load-double-row-div" >
                        <RemoteAll
                            data={filteredUsers}
                            columns={columns}
                            page={1}
                            hideSizePerPage={true}
                            onTableChange={this.handleTableChange}
                        />
                        {/* <InfiniteScroll
                            dataLength={filteredUsers.length}
                            next={this.fetchMoreData}
                            hasMore={HasMoreUsers}
                            loader={<Spinner label="Loading..." size={SpinnerSize.large} />}
                            scrollableTarget="lazy-load-user-div"
                        // endMessage={
                        //     <p></p>
                        // }
                        > */}

                        {/* </InfiniteScroll> */}
                    </div >
                    {/* } */}
                    {
                        !hideConfirm &&
                        <ConfirmRedux
                            {...this.props}
                        />
                    }
                    {
                        !alertJSON.hideAlert &&
                        <AlertRedux
                            {...this.props}
                        />
                    }
                </LoadingOverlay>
                {ShowPanel &&
                    <AddEditUserPanel
                        {...this.props}
                    />
                }
            </React.Fragment>
        )
    }
}
