import { Breadcrumb } from 'office-ui-fabric-react';
import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { QRSTICKER_BREADCRUMBS } from '../Constants/QRStickerConstants';
import { QRStickerActions } from '../Actions/QRStickerActions';
import QRSticker from './QRSticker.jsx';

const mapStateToProps = (state) => {
    return {
        QRS: state.QRStickerReducer,
        isRequestProcessing: state.QRStickerReducer.isRequestProcessing,
        successMessage: state.QRStickerReducer.successMessage,
    }
}

class QRComponent extends PureComponent {

    render() {
        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={QRSTICKER_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                </div>
                <QRSticker
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let qrStickerAction = new QRStickerActions();

    return bindActionCreators({ ...qrStickerAction }, dispatch);
}
const QR = connect(mapStateToProps, mapDispatchToProps)(QRComponent);
export default QR;