import { process } from "@progress/kendo-data-query";
import { ISequence } from "../Interfaces/ISequence";

export class SequenceNumberingUtility {
    
    public processSequencesForKendoGrid = (sequences: ISequence[], kendoGridDataState: any): any => {

        let processedSequences: any = process([...sequences], { ...kendoGridDataState });
        return processedSequences;
    }

}
