import { LoOfficeInitialState } from "../Constants/LoOfficeInitialState";
import * as CONSTANTS from "../Constants/LoOfficeConstants";
import { ILoOfficeInitialState, ILoOfficePayLoad, ILoOfficeAction, ILoOfficeLocation, } from "../LoOfficeInterface/ILoOffice";
import { LoOfficeUtility } from "../Utility/LoOfficeUtility";

export function LoOfficeReducer(state = LoOfficeInitialState, action: ILoOfficeAction) {

    let tempState: ILoOfficeInitialState = { ...state };
    let payload: ILoOfficePayLoad = action.payload;
    let utility: LoOfficeUtility = new LoOfficeUtility();
    let SelectedLoOfficeLocation: ILoOfficeLocation = { ...tempState.SelectedLoOfficeLocation };


    switch (action.type) {

        case CONSTANTS.RESET_LO_OFFICE_BREADCRUMB:
            tempState.LoOfficeBreadCrumb = [
                { text: 'Admin', key: 'Admin' },
                { text: 'Lo Office', key: 'LoOffice' }
            ]
            break;

        case CONSTANTS.CLEAN_LO_OFFICE_DASHBOARD:
            tempState.LoadingLoOfficeDashboard = true;
            tempState.LoOfficeLocations = [];
            tempState.locOffset = 0;
            tempState.loOffice_name_filter = "";
            tempState.loOffice_code_filter = "";
            break;

        case CONSTANTS.LOAD_LO_OFFICE_DASHBOARD:
            tempState.LoadingLoOfficeDashboard = payload.IsOngoingLoad;
            if (!payload.IsOngoingLoad) {
                tempState.LoOfficeLocations = payload.LoOfficeLocations;
            }
            break;

        case CONSTANTS.OPEN_CLOSE_LO_OFFICE_CONFIRM_MODAL:
            tempState.hideConfirm = payload.hideConfirm;
            if (payload.hideConfirm) {
                tempState.CurrentLoOffice = utility.resetLoOffice();
            } else {
                tempState.CurrentLoOffice = payload.CurrentLoOffice;
            }
            break;

        case CONSTANTS.OPEN_CLOSE_LO_OFFICE_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON ?
                payload.alertJSON :
                {
                    alertHeader: "",
                    alertSubText: "",
                    hideAlert: true,
                };
            tempState.LoadingLoOfficeDashboard = false;
            tempState.LoadingAddEditLoOfficePopUp = false;

            if (payload.IsLoOfficeEdit) {
                tempState.ShowAddEditLoOfficePopUp = true;
            }
            else {
                tempState.ShowAddEditLoOfficePopUp = !payload.errorFlag;
            }
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_NAME_FILTER_VALUE:
            tempState.offset = 0;
            tempState.LoOfficeLocations = [];
            tempState.LoadingLoOfficeDashboard = true;
            tempState.loOffice_name_filter = payload.filterValue;
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_CODE_FILTER_VALUE:
            tempState.offset = 0;
            tempState.LoOfficeLocations = [];
            tempState.LoadingLoOfficeDashboard = true;
            tempState.loOffice_code_filter = payload.filterValue;
            break;

        case CONSTANTS.SHOW_HIDE_LO_OFFICE_ADD_EDIT_LOCATION_POP_UP:
            tempState.ShowAddEditLoOfficePopUp = payload.ShowAddEditLoOfficePopUp;
            tempState.SelectedLoOfficeLocation = payload.SelectedLoOfficeLocation;
            break;

        case CONSTANTS.SHOW_LO_OFFICE_DETAILS_TAB:
            tempState.SelectedLoOfficeLocation = payload.SelectedLoOfficeLocation;
            tempState.ShowLoOfficeDetailsTab = payload.ShowLoOfficeDetailsTab;
            if (payload.SelectedLoOfficeLocation) {
                let loOfficeName: string = "";
                let loOfficeAddress: string = "";
                loOfficeName = payload.SelectedLoOfficeLocation.name;
                loOfficeAddress = payload.SelectedLoOfficeLocation.street;
                tempState.LoOfficeBreadCrumb = tempState.LoOfficeBreadCrumb.concat(
                    { text: `Locations ( Lo Office Client : ${loOfficeName} ,  Address: ${loOfficeAddress} )`, key: 'Locations' }
                )
            }
            else {
                if (tempState.LoOfficeBreadCrumb.length > 2) {
                    tempState.LoOfficeBreadCrumb.pop();
                }
            }
            break;

        case CONSTANTS.SHOW_HIDE_ADD_EDIT_LO_OFFICE_POP_UP:
            tempState.ShowAddEditLoOfficePopUp = payload.ShowAddEditLoOfficePopUp;
            tempState.IsLoOfficeEdit = payload.IsLoOfficeEdit;
            tempState.PanelHeaderText = payload.PanelHeaderText;
            if (!payload.ShowAddEditLoOfficePopUp) {
                tempState.SelectedLoOfficeLocation = utility.resetLoOffice();
            }
            if (payload.SelectedLoOfficeLocation) {
                tempState.SelectedLoOfficeLocation = payload.SelectedLoOfficeLocation;
            }
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_NAME:
            SelectedLoOfficeLocation.name = payload.loOfficeFieldValue;
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_STREET:
            SelectedLoOfficeLocation.street = payload.loOfficeFieldValue;
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_CITY:
            SelectedLoOfficeLocation.city = payload.loOfficeFieldValue;
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_STATE:
            SelectedLoOfficeLocation.state = payload.loOfficeFieldValue;
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_COUNTRY:
            SelectedLoOfficeLocation.country = payload.loOfficeFieldValue;
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_PIN:
            SelectedLoOfficeLocation.pin = parseInt(payload.loOfficeFieldValue);
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_PHONE:
            SelectedLoOfficeLocation.phone = payload.loOfficeFieldValue;
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.CHANGE_LO_OFFICE_CODE:
            SelectedLoOfficeLocation.acronymCode = payload.loOfficeFieldValue;
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.CHANGE_LO_EXTERNAL_ID:
            SelectedLoOfficeLocation.externalId = payload.loOfficeFieldValue;
            tempState.SelectedLoOfficeLocation = SelectedLoOfficeLocation;
            break;

        case CONSTANTS.SAVE_LO_OFFICE_LOCATION:
            tempState.LoadingAddEditLoOfficePopUp = payload.IsOngoingLoad;
            break;

        case CONSTANTS.DELETE_LO_OFFICE_LOCATION:
            tempState.LoadingLoOfficeDashboard = payload.isOngoingDelete;
            tempState.hideConfirm = true;
            break;

        default:
            break;

    }
    return tempState;
}