import { INavLink } from "office-ui-fabric-react";
import { ILoggedInUser, IView, IViewRule } from "../CommonInterfaces/CommoInterfaces";
import * as CommonConstants from "./CommonConstants";
import * as NavConstants from "./ViewNavLinkConstants";



export class SideBarElements {

    private _loggedInUserDetails: ILoggedInUser;
    private _viewRules: IViewRule[];
    private _assetView: IView;
    private _assessmentView: IView;
    private _looseGearView: IView;
    private _primaryOfficeId: number;
    private _role: string;
    private _divisions: string;
    private _isAssetViewEnabled: boolean = false;

    constructor(loggedInUserDetails: ILoggedInUser) {
        this._loggedInUserDetails = loggedInUserDetails;

        this._primaryOfficeId = this._loggedInUserDetails.primaryOfficeId;
        this._role = this._loggedInUserDetails.role;
        this._divisions = this._loggedInUserDetails.divisions;
        this._divisions = this._loggedInUserDetails.divisions;
        this._isAssetViewEnabled = this._role === CommonConstants.LO_ADMIN_ROLE || origin === "http://localhost:3000";
        this._assetView = JSON.parse(this._loggedInUserDetails.webAccess)?.ASSET;
        this._looseGearView = JSON.parse(this._loggedInUserDetails.webAccess)?.LOOSEGEAR;
        this._assessmentView = JSON.parse(this._loggedInUserDetails.webAccess)?.ASSESSMENT;

        if (this._loggedInUserDetails.webAccess && !this._isAssetViewEnabled) {

            this._viewRules = this._assetView?.viewRules;
            const rule: IViewRule = this._viewRules?.find(
                (rule: IViewRule) => rule.roles.split(",").indexOf(this._loggedInUserDetails.role) > -1);

            if (rule?.isEnabled) {
                this._isAssetViewEnabled = true;
                if (rule?.office) {
                    const officeRules: string[] = rule.office.split(",");
                    this._isAssetViewEnabled = officeRules.indexOf(this._primaryOfficeId.toString()) > -1;
                }
                if (rule?.division) {
                    const divisionRules: string[] = rule.division.split(",");
                    this._isAssetViewEnabled = divisionRules.filter((division: string) =>
                        this._divisions.includes(division)).length > 0;
                }
            }
        }

    }

    generateSideBarElements = () => {

        let SideBarLinkArray = [];
        let NAV_ASSET = NavConstants.ASSETS;
        let NAV_ASSESSEMENT = NavConstants.ASSESSMENT;
        let NAV_LOOSEGEAR = NavConstants.LOOSEGEAR;
        NAV_ASSET.name = this._assetView ? this._assetView.name : NavConstants.ASSETS.name;
        NAV_ASSESSEMENT.name = this._assessmentView ? this._assessmentView.name : NavConstants.ASSESSMENT.name;
        NAV_LOOSEGEAR.name = this._looseGearView ? this._looseGearView.name : NavConstants.LOOSEGEAR.name;

        let namespace: string = window.loggedInUserDetails.namespace;

        SideBarLinkArray.push({
            id: "homelink",
            name: 'HOME',
            icon: 'Home',
            url: '',
            isExpanded: true,
            key: "Home",
        });

        let RESOURCE_LINKS: INavLink[] = [];
        switch (this._loggedInUserDetails.role) {
            case CommonConstants.CORPORATE_ROLE:
                break;
            case CommonConstants.FIELD_ENGG_ROLE:
                SideBarLinkArray.push({
                    name: 'OPERATIONS',
                    disabled: true,
                    links: [
                        NavConstants.JOBS,
                        NavConstants.TASKS
                    ],
                    isExpanded: true,
                });
                break;
            case CommonConstants.APPROVER_ONLY_ROLE:
                SideBarLinkArray.push({
                    name: 'OPERATIONS',
                    disabled: true,
                    links: [
                        NavConstants.TASKS,
                        NavConstants.REPORTS
                    ],
                    isExpanded: true,
                });
                break;
            case CommonConstants.CLIENT_USER_ROLE:
            case CommonConstants.REQUESTOR_ROLE:
            case CommonConstants.SITE_USER_ROLE:
                if (this._isAssetViewEnabled) {
                    RESOURCE_LINKS.push(NAV_ASSET, NAV_LOOSEGEAR, NavConstants.ASSESSMENT)
                }
                else {
                    RESOURCE_LINKS.push(NAV_LOOSEGEAR, NavConstants.ASSESSMENT)

                }
                SideBarLinkArray.push({
                    name: 'RESOURCES',
                    disabled: true,
                    links: RESOURCE_LINKS,
                    isExpanded: true,
                });
                break;
            case CommonConstants.CLIENT_ADMIN_ROLE:
                if (this._isAssetViewEnabled) {
                    RESOURCE_LINKS.push(NAV_ASSET, NAV_LOOSEGEAR, NavConstants.ASSESSMENT, NavConstants.USERS)
                }
                else {
                    RESOURCE_LINKS.push(NavConstants.CLIENTS, NAV_LOOSEGEAR, NavConstants.ASSESSMENT, NavConstants.USERS)

                }
                SideBarLinkArray.push({
                    name: 'RESOURCES',
                    disabled: true,
                    links: RESOURCE_LINKS,
                    isExpanded: true,
                });
                break;

            default:
                if (origin === "http://localhost:3000") {

                    SideBarLinkArray.push({
                        name: 'OPERATIONS',
                        disabled: true,
                        links: [
                            NavConstants.JOBS,
                            NavConstants.JOBLITES,
                            NavConstants.TASKS,
                            NavConstants.REPORTS
                        ],
                        isExpanded: true,
                    });
                }
                else if (namespace === "HALLMARK-PILOT" || namespace === "doka-pilot" || namespace === "doka-live") {

                    SideBarLinkArray.push({
                        name: 'OPERATIONS',
                        disabled: true,
                        links: [
                            NavConstants.JOBLITES,
                            NavConstants.TASKS,
                            NavConstants.REPORTS
                        ],
                        isExpanded: true,
                    });
                }
                else {
                    SideBarLinkArray.push({
                        name: 'OPERATIONS',
                        disabled: true,
                        links: [
                            NavConstants.JOBS,
                            NavConstants.TASKS,
                            NavConstants.REPORTS
                        ],
                        isExpanded: true,
                    });
                }
                RESOURCE_LINKS = [];

                // RESOURCE_LINKS

                if (origin === "http://localhost:3000") {
                    RESOURCE_LINKS.push(
                        NavConstants.CLIENTS,
                        NAV_ASSET,
                        NAV_LOOSEGEAR,
                        NAV_ASSESSEMENT,
                        NavConstants.USERS,
                        NavConstants.QRSTICKER
                    )
                }
                else if (this._isAssetViewEnabled) {
                    if(namespace==='templatemerge'||namespace==='liftek-pilot'||namespace==='liftek'||namespace==='sales-demo'){
                        RESOURCE_LINKS.push(
                            NavConstants.CLIENTS,
                            NAV_ASSET,
                            NAV_LOOSEGEAR,
                            NAV_ASSESSEMENT,
                            NavConstants.USERS,
                            NavConstants.QRSTICKER
                        )
                    } else{
                        RESOURCE_LINKS.push(
                            NavConstants.CLIENTS,
                            NAV_ASSET,
                            NAV_LOOSEGEAR,
                            NAV_ASSESSEMENT,
                            NavConstants.USERS
                        )
                    }
                    
                }
                else {
                    RESOURCE_LINKS.push(
                        NavConstants.CLIENTS,
                        NAV_LOOSEGEAR,
                        NAV_ASSESSEMENT,
                        NavConstants.USERS
                    )

                }
                SideBarLinkArray.push({
                    name: 'RESOURCES',
                    disabled: true,
                    links: RESOURCE_LINKS,
                    isExpanded: true,
                });

                break;
        }
        if (origin === "http://localhost:3000"||namespace==='liftmax'||namespace==='templatemerge'||namespace==='sales-demo') {
        SideBarLinkArray.push({
            name: 'TECHNICAL LIBRARY',
            disabled: true,
            links: [
                NavConstants.PRODUCT_TECH_INFO,
                NavConstants.TRIAL_FIT_PIC_LIBRARY,
                NavConstants.REPORT_LIBRARY,
                NavConstants.CLIENT_INVENTORY,
            ],
            isExpanded: true,
        });
    }
        if (this._loggedInUserDetails.role === CommonConstants.LO_ADMIN_ROLE ||
            this._loggedInUserDetails.role === CommonConstants.CONFIG_ADMIN_ROLE) {

            SideBarLinkArray.push({
                name: 'SETTINGS',
                disabled: true,
                links: [
                    NavConstants.DATAFORMS,
                    NavConstants.ASSETTYPES,
                    NavConstants.TASKTYPES,
                    NavConstants.CONFIGURATIONS,
                    NavConstants.CATEGORY,
                    NavConstants.TEMPLATES,
                    NavConstants.STATES
                ],
                isExpanded: true,
            });
        }

        if (this._loggedInUserDetails.role === CommonConstants.MASTER_ADMIN_ROLE ||
            this._loggedInUserDetails.role === CommonConstants.CONFIG_ADMIN_ROLE) {

            SideBarLinkArray.push({
                name: 'ADMIN',
                disabled: true,
                links: [
                    NavConstants.CUSTOMER_REGISTRATION,
                    NavConstants.ROLES,
                    NavConstants.DIVISION,
                    NavConstants.LO_OFFICE,
                    NavConstants.SEQUENCE_NUMBER
                ],
                isExpanded: true,

            });
        }



        return ([{
            links: SideBarLinkArray,
        },
        ])

    }
}

