import React, { Component } from 'react';
import Select from 'react-select';
import { Label, TextField } from "office-ui-fabric-react";
import { FormTypeOptions, FormObjectOptions } from './FormConstants';

export default class FormDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        let {
            name,
            samplesmin,
            samplesmax,
            formtype,
            formobject,
        } = this.props.selectedForm;

        let {
            openPanelButtonDisabled,
            // closeFormDetailsPanel,
            changeFormName,
            changeFormObject,
            changeFormType,
            changeSampleMin,
            changeSampleMax,
        } = this.props;

        return (
            <React.Fragment>
                {/* <Panel
                    headerText="Form Details"
                    isOpen={openPanelButtonDisabled}
                    type={PanelType.smallFixedFar}
                    onDismiss={() => closeFormDetailsPanel(false)}
                    closeButtonAriaLabel="Close"> */}
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                disabled={openPanelButtonDisabled}
                                label="Name"
                                value={name}
                                onChange={(ev, newValue) => changeFormName(newValue)}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <Label>Form Object <span className="icon-required" aria-hidden="true">*</span>
                            </Label>
                            <Select
                                isDisabled={openPanelButtonDisabled}
                                value={{ value: formobject, label: formobject }}
                                onChange={(newValue) => changeFormObject(newValue)}
                                options={FormObjectOptions}
                                isOptionDisabled={(option) => option.isdisabled}
                            />

                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <Label>Form Type</Label>
                            <Select
                                isDisabled={openPanelButtonDisabled}
                                value={{ value: formtype, label: formtype }}
                                onChange={(newValue) => changeFormType(newValue)}
                                options={FormTypeOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8">
                            <Label>Multiple Forms</Label>
                            <div className="ms-Grid-row">

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        disabled={openPanelButtonDisabled}
                                        label="Min"
                                        min={1}
                                        max={samplesmax}
                                        step={1}
                                        type="number"
                                        value={samplesmin}
                                        onChange={(ev, newValue) => changeSampleMin(newValue)}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <TextField
                                        disabled={openPanelButtonDisabled}
                                        label="Max"
                                        min={samplesmin}
                                        step={1}
                                        type="number"
                                        value={samplesmax}
                                        onChange={(ev, newValue) => changeSampleMax(newValue)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </Panel> */}
            </React.Fragment >
        )
    }
}
