import React, { PureComponent } from 'react'
import { ActionButton, IconButton, PrimaryButton, CommandButton, SearchBox } from 'office-ui-fabric-react'
import filterFactory from 'react-bootstrap-table2-filter';
import LoadingOverlay from 'react-loading-overlay';
import { FadeLoader } from 'react-spinners';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { DeletedRowStyle, IconConstants } from './../../../../Constants/StylesConstants';
import { CommonUtility } from './../../../../CommonUtility/CommonUtility';
import { AssetTypeAPI } from './../Services/AssetTypeAPI';
import AddEditAssetTypePanel from './AddEditAssetTypePanel';
import ConfirmAssetTypeRedux from './ConfirmAssetTypeRedux';
import AlertRedux from '../../../Alert/AlertRedux';

const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
}];

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="table-export-button-div">
            <PrimaryButton
                onClick={handleClick}
                text="Export to CSV"
                menuIconProps={IconConstants.exportIcon}
            />
        </div>
    );
};

const RemoteAll = ({
    data, columns, onTableChange, page, hideSizePerPage, style
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
            exportCSV={{
                fileName: 'Qwikspec_AssetType.csv',
                noAutoBOM: false,
                blobType: 'text/csv;charset=ansi'
            }}
        >
            {
                props => (
                    <React.Fragment>
                        <MyExportCSV {...props.csvProps} />
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({ page, hideSizePerPage })}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            rowClasses={DeletedRowStyle}
                            wrapperClasses="table-responsive" />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class AssetTypeDashboard extends PureComponent {

    _assetTypeApi = null;

    constructor(props) {
        super(props);
        this._assetTypeApi = new AssetTypeAPI(window.API_URL);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {

        this.props.cleanDashboard({
            offset: 0,
        });

        this.props.loadAssetTypeDashboard({
            objectType: "AssetType",
            offset: 0,
            limit: this.props.AssetType.limit,
            apiObject: this._assetTypeApi,
        });

    }

    fetchMoreData = () => {

        this.props.loadAssetTypeDashboard({
            objectType: "AssetType",
            offset: this.props.AssetType.offset,
            limit: this.props.AssetType.limit,
            apiObject: this._assetTypeApi,
        });

    };


    handleTableChange = (type, { filters, sortField, sortOrder }) => {

        // console.log(sortOrder);

        if (type.toUpperCase() === "SORT") {

            let result = [...this.props.AssetType.AssetTypes];

            // Handle column sort
            result = CommonUtility.sortByColumn(result, sortField, sortOrder);

            this.props.sortAssetTypeDashboard({
                sortedAssetTypes: result,
            });

        }

    }

    render() {

        let columns = [
            {
                dataField: 'manageAssetTypeIcons',
                isDummyField: true,
                text: 'Action',
                formatter: (cellContent, row) => {
                    return (
                        <span>
                            {row.status === 1 ?
                                <React.Fragment>
                                    <IconButton
                                        onClick={() => this.props.loadAttributeDropDowns({
                                            apiObject: this._assetTypeApi,
                                            objectType: "AssetType",
                                            PanelHeaderText: "",
                                            SelectedAssetType: row,
                                        })}
                                        iconProps={IconConstants.editicon} />
                                    <IconButton
                                        onClick={() => {
                                            this.props.openCloseAssetTypeConfirmModal({
                                                hideConfirm: false,
                                                CurrentAssetType: row
                                            });
                                        }}
                                        iconProps={IconConstants.deleteicon} />
                                </React.Fragment>
                                :
                                <span></span>
                            }
                        </span>
                    )
                },
                csvExport: false
            },
            {
                dataField: 'id',
                text: 'AssetType ID',
                hidden: true,
                csvExport: false
            },
            {
                dataField: 'acronym_code',
                text: 'Code',
                sort: true,
            },
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
            },
            {
                dataField: 'external_id',
                text: 'External',
            },
            // {
            //     dataField: 'category',
            //     text: 'Category',
            // },
            {
                dataField: 'category',
                text: 'Category',
                sort: true,
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
            },
            // {
            //     dataField: 'reference',
            //     text: 'Data Form',
            // },

        ];

        let {
            LoadingAssetTypeDashboard,
            AssetTypes,
            HasMoreAssetTypes,
            ShowAssetTypeAddEditPanel,
            hideConfirm,
            alertJSON,
            name_filter_value
        } = this.props.AssetType;

        let filteredAssetType = name_filter_value ?
            [
                ...AssetTypes.filter(
                    (assetType) =>
                        assetType.name.toUpperCase().indexOf(name_filter_value.toUpperCase()) > -1
                )]
            :
            [...AssetTypes];

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <ActionButton
                                disabled={LoadingAssetTypeDashboard}
                                text="Reload"
                                iconProps={IconConstants.refreshIcon}
                                onClick={() => this.loadInitData()}
                            />
                            <ActionButton
                                text="Add"
                                iconProps={IconConstants.addIcon}
                                onClick={() => this.props.loadAttributeDropDowns({
                                    apiObject: this._assetTypeApi,
                                    objectType: "ASSET_TYPE",
                                    PanelHeaderText: "Add Asset Type"
                                })}
                            />
                            {HasMoreAssetTypes && !LoadingAssetTypeDashboard &&
                                <CommandButton
                                    text="Load more"
                                    iconProps={IconConstants.moreicon}
                                    onClick={() => this.fetchMoreData()}
                                />
                            }
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <SearchBox
                                placeholder="Name"
                                value={name_filter_value}
                                onChange={(event, newVal) => {
                                    this.props.changeAssetTypeNameFilterValue({
                                        name_filter_value: newVal
                                    })
                                }}
                                onClear={() => {
                                    this.props.changeAssetTypeNameFilterValue({
                                        name_filter_value: ""
                                    })
                                }}
                                underlined
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6"></div>
                    </div>
                </div>

                <LoadingOverlay
                    active={LoadingAssetTypeDashboard}
                    spinner={<FadeLoader />}
                    text="Loading Asset Types...">
                    <div className="lazy-load-div" >
                        <RemoteAll
                            data={filteredAssetType}
                            columns={columns}
                            page={1}
                            hideSizePerPage={true}
                            onTableChange={this.handleTableChange}
                        />
                    </div>

                </LoadingOverlay>

                {
                    ShowAssetTypeAddEditPanel &&
                    <AddEditAssetTypePanel
                        {...this.props}
                    />
                }
                {!hideConfirm &&
                    <ConfirmAssetTypeRedux
                        {...this.props}
                    />
                }
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </React.Fragment>
        )
    }
}
