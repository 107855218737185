import * as React from "react";

import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

interface DropdownFilterCellProps extends GridFilterCellProps {
  defaultValue: string;
  data: string[];
}

const DropdownFilterCell = (props: DropdownFilterCellProps) => {
  const hasValue = (value: string) =>
    Boolean(value && value !== props.defaultValue);

  const onChange = (event: DropDownListChangeEvent) => {
    const hasValueNew = hasValue(event.target.value);
    props.onChange({
      value: hasValueNew ? event.target.value : "",
      operator: hasValueNew ? "eq" : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    props.onChange({
      value: "",
      operator: "",
      syntheticEvent: event,
    });
  };
  return (
    <div className="k-filterable">
      <DropDownList
        data={props.data}
        onChange={onChange}
        value={props.value || props.defaultValue}
        defaultItem={props.defaultValue}
      />
      <Button
        title="Clear"
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};

export default DropdownFilterCell;