import React, { PureComponent } from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, TextField, Label } from 'office-ui-fabric-react';
import { TaskAPI } from './../Services/TaskAPI';
import { TASK_STATUS_TO_UPDATE } from '../Constants/TaskConstants';
import Select from 'react-select';
import { IconConstants } from '../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: {
        main: {
            width: 750,
        }
    },
};

export default class ConfirmTaskRedux extends PureComponent {

    _taskApi = null;

    constructor(props) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);

    }

    render() {
        let {
            hideConfirm,
            SelectedTask,
            ToBeUpdatedStatus,
            isLLGDelete,
            Inspectors,
            SelectedTaskNewInspector,
            isInspectTab
        } = this.props.Task;

        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `${isLLGDelete ? `This change can't be reverted. Are you sure you want to remove this LLG record`
                : `Are you sure want to ${TASK_STATUS_TO_UPDATE[ToBeUpdatedStatus]} the task?`}`
        };
        return (
            <Dialog
                hidden={hideConfirm}
                onDismiss={() => this.props.openCloseTaskConfirmModal({
                    SelectedTask: SelectedTask,
                    hideConfirm: true,
                    ToBeUpdatedStatus: -1
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                id="confirm-task-dialog"
            >
                {(ToBeUpdatedStatus === 2 || ToBeUpdatedStatus === 32) &&
                    <React.Fragment>
                        <TextField
                            label="Comments"
                            value={SelectedTask.revisionComments ? decodeURIComponent(SelectedTask.revisionComments) : ""}
                            maxLength={500}
                            multiline
                            onChange={(ev, newVal) => {
                                this.props.changeRevisionComments({
                                    revisionComments: newVal
                                });
                            }}
                        />
                        {ToBeUpdatedStatus === 32 && SelectedTask.taskType !== "LLG" &&
                            <>
                                <TextField
                                    label={`Original Inspector`}
                                    readOnly
                                    value={SelectedTask.observationInspector}
                                />
                                <Label>Reassign Inspector</Label>
                                <Select
                                    placeholder="Please Select Inspector"
                                    value={SelectedTaskNewInspector}
                                    onChange={(value) => {
                                        this.props.changeSelectedTaskInspector({
                                            selectedTaskInspector: value
                                        })
                                    }}
                                    options={Inspectors}
                                />
                            </>
                        }
                    </React.Fragment>

                }
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            !isInspectTab ?
                                this.props.generateOrReopenTask({
                                    SelectedTask: SelectedTask,
                                    apiObject: this._taskApi,
                                    ToBeUpdatedStatus: ToBeUpdatedStatus,
                                    revisionComments: SelectedTask.revisionComments,
                                    SelectedTaskNewInspector,
                                })
                                :
                                this.props.submitOrHoldTask({
                                    SelectedTask: SelectedTask,
                                    apiObject: this._taskApi,
                                    ToBeUpdatedStatus: ToBeUpdatedStatus,
                                })

                        }}
                        text={
                            (ToBeUpdatedStatus === 32 && SelectedTask.taskType !== "LLG"
                                && SelectedTask.inspectorId !== SelectedTaskNewInspector?.value) ? `Yes and Reassign` : `Yes`}
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <PrimaryButton
                        onClick={() => this.props.openCloseTaskConfirmModal({
                            SelectedTask: SelectedTask,
                            hideConfirm: true,
                            ToBeUpdatedStatus: -1
                        })}
                        text="No"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog >
        )
    }
}
