import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Label } from 'office-ui-fabric-react';
import { CategoryAction } from '../Actions/CategoryAction';

const mapStateToProps = (state) => {
    return {
        Category: state.CategoryReducer
    }
}

class CategoryComponent extends PureComponent {

    render() {
        return (
            <React.Fragment>
                <Label className="breadcrumb-label">Lifting / Settings / Category</Label>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let categoryAction = new CategoryAction();

    return bindActionCreators({ ...categoryAction }, dispatch);
}

const Category = connect(mapStateToProps, mapDispatchToProps)(CategoryComponent);
export default Category;
