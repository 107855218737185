import React, { PureComponent } from 'react'
import { Checkbox, Label, TextField } from 'office-ui-fabric-react'
import Select from 'react-select';

export default class Description extends PureComponent {

    render() {

        let { CurrentTaskType, DivisionOptions } = this.props.TaskType;

        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label className="breadcrumb-label"> Task Type Description </Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <TextField
                            value={CurrentTaskType.name}
                            label="Type Name"
                            onChange={(event, newValue) => {
                                this.props.changeTaskTypeDescription({
                                    field: "name",
                                    name: newValue
                                });
                            }}
                            required
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <TextField
                            value={CurrentTaskType.acronym_code}
                            label="Type Code"
                            onChange={(event, newValue) => {
                                this.props.changeTaskTypeDescription({
                                    field: "acronymCode",
                                    acronym_code: newValue
                                });
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <Label>Division <span className="mandatory-span">*</span></Label>
                        <Select
                            placeholder="Select Division"
                            value={CurrentTaskType.division}
                            options={DivisionOptions}
                            isClearable={true}
                            onChange={(newValue) => {
                                this.props.changeTaskTypeDescription({
                                    field: "division",
                                    division: newValue
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <TextField
                            value={CurrentTaskType.reference_standard}
                            onChange={(event, newValue) => {
                                this.props.changeTaskTypeDescription({
                                    field: "reference_standard",
                                    reference_standard: newValue
                                });
                            }}
                            label="Reference Standard"
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <TextField
                            value={CurrentTaskType.inspection_method}
                            onChange={(event, newValue) => {
                                this.props.changeTaskTypeDescription({
                                    field: "inspection_method",
                                    inspection_method: newValue
                                });
                            }}
                            label="Inspection Method"
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <TextField
                            value={CurrentTaskType.validity}
                            label="Validity"
                            type="number"
                            onChange={(event, newValue) => {
                                this.props.changeTaskTypeDescription({
                                    field: "validity",
                                    validity: parseFloat(newValue)
                                });
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <TextField
                            value={CurrentTaskType.description}
                            label="Description"
                            onChange={(event, newValue) => {
                                this.props.changeTaskTypeDescription({
                                    field: "description",
                                    description: newValue
                                });
                            }}
                            multiline
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <TextField
                            readOnly
                            value={CurrentTaskType.external_id}
                            label="External Id"
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <Label>EIAC</Label>
                        <Checkbox
                            checked={CurrentTaskType.eiac}
                            onChange={(event, checked) => {
                                this.props.changeTaskTypeDescription({
                                    field: "eiac",
                                    eiac: checked
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
