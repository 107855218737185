import * as React from 'react'
import { IconConstants } from '../../../../Constants/StylesConstants';
import { ITrialFitPicLibraryAPI } from '../Interfaces/ITrialFitPicLibraryAPI';
import { TrialFitPicLibraryAPI } from '../Service/TrialFitPicLibraryAPI';
import { TextField, PrimaryButton } from 'office-ui-fabric-react';
import { TrialFitPicLibraryUtility } from '../Utility/TrialFitPicLibraryUtility';
import Select from 'react-select';
import { ISelect } from './../../../../CommonInterfaces/CommoInterfaces';
import { IDocumentEntity } from '../Interfaces/IDocumentEntity';

export default class AddEditFolderSection extends React.PureComponent<any, any> {

    private _apiObject: ITrialFitPicLibraryAPI = null;
    // _jobutility;
    constructor(props) {
        super(props);

        this.state = {
            error: false,
        };

        this._apiObject = new TrialFitPicLibraryAPI(window.API_URL, window.loggedInUserDetails);
        // this._jobutility = new JobLitesUtility();
    }

    public saveOrUpdateFolder = () => {

        let { selectedDocument } = this.props.TrialFitPicLibraryState;

        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;

        selectedDocument.clientId = selectedClient.value;
        selectedDocument.siteId = selectedSite.value;

        this.props.saveFolder({
            apiObject: this._apiObject,
            selectedDocument: selectedDocument,
            libraryName: TrialFitPicLibraryUtility.getLibraryName(window.location.hash.split("/")[2]),
        });

    }

    public render(): JSX.Element {

        const {
            selectedDocument,
            entityNameErrorMessage,
            llgAssetTypes,
            selectedNewDocument,
            documentEntities
        } = this.props.TrialFitPicLibraryState;

        const filtertedLLGAssetType: ISelect[] = llgAssetTypes.map((llgAsstType: ISelect) => {
            if (documentEntities.map((entity: IDocumentEntity) => entity.name).indexOf(llgAsstType.label) === -1) {
                return llgAsstType;
            }
            return null;
        }).filter((llgAsstType: ISelect) => llgAsstType !== null);


        return (

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    {window.location.hash.split("/")[2] === "productTechInfo" && !selectedDocument.id ?
                        <>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg11">
                                <Select
                                    value={selectedNewDocument}
                                    placeholder={`----Select Folder Name----`}
                                    onChange={(newVal: ISelect) => {
                                        this.props.changeDocumentName({
                                            selectedNewDocument: newVal,
                                            documentName: newVal.label,
                                            documentType: "folder"
                                        })
                                    }}
                                    options={filtertedLLGAssetType}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                                <span className="mandatory-span">*</span>
                            </div>
                        </>
                        :
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                placeholder={`Folder Name`}
                                value={selectedDocument.name}
                                onChange={(ev, newVal) => {
                                    this.props.changeDocumentName({
                                        documentName: newVal,
                                        documentType: "folder"
                                    })
                                }}
                                errorMessage={entityNameErrorMessage}
                                required
                                underlined
                            />
                        </div>
                    }
                </div>
                <br />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <PrimaryButton
                            text="Save"
                            className="horizontal-button"
                            menuIconProps={IconConstants.saveicon}
                            disabled={!selectedDocument.name || entityNameErrorMessage}
                            onClick={() => this.saveOrUpdateFolder()}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10" />
                </div>
            </div>

        )
    }
}
