import React from 'react'
import { ClientAPI } from '../Services/ClientAPI';
import AlertRedux from '../../Alert/AlertRedux';
import { IconButton, PrimaryButton, CommandButton, ActionButton, TooltipHost, SearchBox } from 'office-ui-fabric-react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import BootstrapTable from 'react-bootstrap-table-next';
import { DATE_TIME_FORMAT_USER, NO_ALLOW_EDIT_JOB_TASK_CLIENT_ASSET_ROLE } from './../../../Constants/MessageConstants';
import { DeletedRowStyle, IconConstants, calloutProps, tooltipId, hostStyles } from '../../../Constants/StylesConstants';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment-timezone';
import AddClientPopUp from './AddClientPopUp';
import LocationDashboard from './LocationDashboard';
import AddEditLocationPanel from './AddEditLocationPanel';
import { CLIENT_ADMIN_ROLE } from '../../../Constants/CommonConstants';

const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
}];

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="table-export-button-div">
            <PrimaryButton
                onClick={handleClick}
                text="Export to CSV"
                menuIconProps={IconConstants.exportIcon}
            />
        </div>
    );
};

const RemoteAll = ({
    data, columns, onTableChange, page, hideSizePerPage, style
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
            exportCSV={{
                fileName: 'Qwikspec_Clients.csv',
                noAutoBOM: false,
                blobType: 'text/csv;charset=ansi'
            }}
        >
            {
                props => (
                    <React.Fragment>
                        <MyExportCSV {...props.csvProps} />
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({ page, hideSizePerPage })}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            rowClasses={DeletedRowStyle}
                            wrapperClasses="table-responsive" />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class ClientDashboard extends React.PureComponent {

    _clientApi = null;

    constructor(props) {
        super(props);
        this._clientApi = new ClientAPI(window.API_URL, window.loggedInUserDetails);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {

        this.props.cleanClientDashboard({
            offset: 0,
        });

        this.props.loadClientDashboard({
            offset: 0,
            limit: this.props.Client.limit,
            apiObject: this._clientApi,
        });

    }

    fetchMoreData = () => {

        this.props.loadClientDashboard({
            offset: this.props.Client.offset,
            limit: this.props.Client.limit,
            apiObject: this._clientApi,
        });

    };


    // handleTableChange = (type, { filters, sortField, sortOrder }) => {

    //     // console.log(sortOrder);

    //     let filterJson = {};

    //     for (let dataField in filters) {
    //         filterJson[dataField] = filters[dataField].filterVal
    //     }

    //     let config = {
    //         headers: {
    //         }
    //     }
    //     if (Object.keys(filterJson).length > 0) {
    //         config = {
    //             headers: {
    //                 filter: JSON.stringify(filterJson)
    //             }
    //         }
    //     }

    // }

    render() {

        let columns = [
            {
                dataField: 'manageClientIcons',
                isDummyField: true,
                text: 'Action',
                hidden: NO_ALLOW_EDIT_JOB_TASK_CLIENT_ASSET_ROLE.indexOf(window.loggedInUserDetails.role) > -1,
                formatter: (cellContent, row) => {
                    return (
                        <span className="action-span">
                            <React.Fragment>
                                <IconButton
                                    onClick={() => this.props.showHideClientAddEditLocationPopUp({
                                        ShowAddEditLocationPopUp: true,
                                        SelectedLocation: row,
                                        PanelHeaderText: row.name,
                                        IsClientEdit: true
                                    })}
                                    iconProps={IconConstants.editicon} />
                                <IconButton
                                    onClick={() => {
                                        this.props.showClientDetailsTab({
                                            SelectedClient: row,
                                            ShowClientDetailsTab: true
                                        });
                                    }}
                                    iconProps={IconConstants.expandicon} />
                            </React.Fragment>
                        </span>
                    )
                },
                csvExport: false
            },
            {
                dataField: 'id',
                text: 'Client ID',
                hidden: true,
                csvExport: false
            },
            {
                dataField: 'acronymCode',
                text: 'Code',
            },
            {
                dataField: 'name',
                text: 'Name',
            },
            {
                dataField: 'dummyField',
                text: 'Address',

                formatter: (cellContent, row) => {
                    return (
                        <TooltipHost
                            content={`${row.street} ${row.city} ${row.state} ${row.pin} ${row.country}`}
                            id={tooltipId}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            <span>{row.street} {row.city} {row.pin} {row.country}</span>

                        </TooltipHost>
                    )
                }
            },
            {
                dataField: 'externalId',
                text: 'External',
            },
            {
                dataField: 'createdDate',
                text: 'Created',
                formatter: (cellContent, row) => {
                    return (
                        cellContent ?
                            <span>{moment(cellContent).utc().format(DATE_TIME_FORMAT_USER)}</span> :
                            <span></span>
                    )
                },
                csvFormatter: (cellContent, row, rowIndex) => cellContent ? moment.utc(cellContent).format("DD-MM-YYYY") : ""
            },
            {
                dataField: 'modifiedDate',
                text: 'Modified',
                formatter: (cellContent, row) => {
                    return (
                        cellContent ?
                            <span>{moment(cellContent).utc().format(DATE_TIME_FORMAT_USER)}</span> :
                            <span></span>
                    )
                },
                csvFormatter: (cellContent, row, rowIndex) => cellContent ? moment.utc(cellContent).format("DD-MM-YYYY") : ""
            },

        ];

        let {
            LoadingClientDashboard,
            Clients,
            alertJSON,
            HasMoreClients,
            client_name_filter,
            client_code_filter,
            HideAddClientPopUp,
            ShowClientDetailsTab,
            ShowAddEditLocationPopUp
        } = this.props.Client;

        let filteredClients = [...Clients];

        const isClientAdmin = window.loggedInUserDetails?.role === CLIENT_ADMIN_ROLE;

        return (
            <React.Fragment>
                {
                    !ShowClientDetailsTab ?
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ActionButton
                                        disabled={LoadingClientDashboard}
                                        text="Reload"
                                        iconProps={IconConstants.refreshIcon}
                                        onClick={() => this.loadInitData()}
                                    />
                                    {HasMoreClients && !LoadingClientDashboard &&
                                        <CommandButton
                                            text="Load more"
                                            iconProps={IconConstants.moreicon}
                                            onClick={() => this.fetchMoreData()}
                                        />
                                    }
                                    {!isClientAdmin &&
                                        <ActionButton
                                            text="Add"
                                            iconProps={IconConstants.addIcon}
                                            onClick={() => this.props.showHideClientAddClientPopUp({
                                                HideAddClientPopUp: false
                                            })}
                                        />
                                    }
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <SearchBox
                                        value={client_name_filter}
                                        placeholder="Search Name"
                                        underlined={true}
                                        onSearch={(newValue) => {
                                            let filterJson = {
                                                "name": newValue,
                                                "code": client_code_filter,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.loadClientDashboard({
                                                filterType: "name",
                                                filterValue: newValue,
                                                offset: 0,
                                                limit: this.props.Client.limit,
                                                apiObject: this._clientApi,
                                                config: config,
                                                filtered: true
                                            });
                                        }
                                        }
                                        onClear={() => {
                                            let filterJson = {
                                                "name": "",
                                                "code": client_code_filter,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.loadClientDashboard({
                                                filterType: "name",
                                                filterValue: "",
                                                offset: 0,
                                                limit: this.props.Client.limit,
                                                apiObject: this._clientApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <SearchBox
                                        value={client_code_filter}
                                        underlined={true}
                                        placeholder="Search Code"
                                        onSearch={(newValue) => {
                                            let filterJson = {
                                                "name": client_name_filter,
                                                "acronym_code": newValue,
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.loadClientDashboard({
                                                filterType: "code",
                                                filterValue: newValue,
                                                offset: 0,
                                                limit: this.props.Client.limit,
                                                apiObject: this._clientApi,
                                                config: config,
                                                filtered: true
                                            });
                                        }
                                        }
                                        onClear={() => {
                                            let filterJson = {
                                                "name": client_name_filter,
                                                "acronym_code": "",
                                            };
                                            let config = {
                                                headers: {
                                                    filter: JSON.stringify(filterJson)
                                                }
                                            };
                                            this.props.loadClientDashboard({
                                                filterType: "code",
                                                filterValue: "",
                                                offset: 0,
                                                limit: this.props.Client.limit,
                                                apiObject: this._clientApi,
                                                config: config,
                                                filtered: true
                                            })
                                        }
                                        }
                                    />
                                </div>
                            </div>

                            <LoadingOverlay
                                active={LoadingClientDashboard}
                                spinner={<FadeLoader />}
                                text="Loading Clients...">
                                <div className="lazy-load-div" >
                                    <RemoteAll
                                        data={filteredClients}
                                        columns={columns}
                                        page={1}
                                        hideSizePerPage={true}
                                    // onTableChange={this.handleTableChange}
                                    />
                                </div>

                            </LoadingOverlay>

                            {
                                !alertJSON.hideAlert &&
                                <AlertRedux
                                    {...this.props}
                                />
                            }
                            {
                                !HideAddClientPopUp &&
                                <AddClientPopUp
                                    {...this.props}
                                />
                            }
                            {
                                ShowAddEditLocationPopUp &&
                                <AddEditLocationPanel
                                    {...this.props}
                                />
                            }
                        </div>
                        :
                        <LocationDashboard
                            {...this.props}
                        />
                }
            </React.Fragment>
        )
    }
}
