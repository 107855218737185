import { IClientInitialState } from "../ClientInterface/IClient";
import { IUser } from "../ClientInterface/IClient";

export const ClientInitialState: IClientInitialState = {
    Clients: [],
    Locations: [],
    HasMoreClients: true,
    HasMoreLoctions: true,
    LoadingClientDashboard: true,
    LoadingLocationDashboard: true,
    limit: 50,
    offset: 0,
    locOffset: 0,
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    client_name_filter: "",
    client_code_filter: "",
    HideAddClientPopUp: true,
    UserRoleOptions: [],
    LoadingAddClientPopUp: true,
    Users: [] as IUser[],
    AddClientDetails: {
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin: 0,
        phone: "",
        acronymCode: ""
    },
    AddSiteDetails: {
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin: 0,
        phone: "",
        acronymCode: ""
    },
    isSiteAddressSameAsClientAddress: false,
    ClientBreadCrumb: [
        { text: 'RESOURCES', key: 'Resources' },
        { text: 'Clients', key: 'Clients' }
    ],
    SelectedClient: null,
    ShowClientDetailsTab: false,
    LoadingAddEditLocationtPopUp: false,
    ShowAddEditLocationPopUp: false,
    SelectedLocation: {
        Id: -1,
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pin: 0,
        phone: "",
        acronymCode: ""
    },
    PanelHeaderText: "",
    IsClientEdit: false,
    isLoadingImage: false,
    imageUploadErrorMessage: "",
    LoOffices: [],
    SelectedLoOffice: null
    // AddClientDetails: {
    //     name: "CLIENT B",
    //     street: "22, ABC Street",
    //     city: "Kolkata",
    //     state: "West Bengal",
    //     country: "India",
    //     pin: 743123,
    //     phone: "743123",
    //     acronymCode: "CC-123"
    // },
    // AddSiteDetails: {
    //     name: "SITE B",
    //     street: "11, DEF Road",
    //     city: "Kolkata",
    //     state: "West Bengal",
    //     country: "India",
    //     pin: 652314,
    //     phone: "9856320147",
    //     acronymCode: "SC-123"
    // },
}