import React from 'react';
import { DATE_FORMAT } from '../../../Constants/MessageConstants';
import moment from 'moment-timezone';
import { IFile } from '../../../CommonInterfaces/CommoInterfaces';
import { Icon, Link, TooltipHost } from 'office-ui-fabric-react';
import { calloutProps, IconConstants, tooltipId } from '../../../Constants/StylesConstants';
import { GET_TASK_PDF } from '../../Tasks/Constants/TaskConstants';

export class DateCustomCell extends React.Component<any, any> {
    public render(): JSX.Element {

        const value = this.props.dataItem[this.props.field];

        return (
            <td className="no-group-show">
                {value ?
                    <span>{moment.utc(new Date(value)).local().format(DATE_FORMAT)}</span>
                    :
                    <span></span>
                }
            </td>
        );
    }
}

export class FileCustomCell extends React.Component<any, any> {
    public render(): JSX.Element {

        const fileContentStr: string | null = this.props.dataItem[this.props.field];

        const fileContent: IFile | null = fileContentStr ? JSON.parse(fileContentStr) : null;

        const observationId: number = this.props.dataItem["id"];

        const blobKey: string = fileContent ? fileContent.blobKey : "";

        const fileDownloadUrl: string = blobKey ?
            `${window.API_URL}filedownload;jsessionid=${window.loggedInUserDetails.jsessionid}?observationId=${observationId}&id=${blobKey}`
            : ``;


        return (
            <td className="no-group-show">
                {blobKey ?
                    <TooltipHost
                        content={`Click here to open field report: ${fileContent.fileName}`}
                        id={tooltipId}
                        calloutProps={calloutProps}
                        styles={{
                            root:
                            {
                                textAlign: "center",
                                display: 'inline-block'
                            }
                        }}
                        key={fileContent.blobKey}
                    >

                        <Link target='_blank' href={fileDownloadUrl}>
                            <Icon
                                iconName={IconConstants.pdfIcon.iconName}
                                styles={{
                                    root: {
                                        color: "#403cbd"
                                    }
                                }}
                            />
                        </Link>
                    </TooltipHost>
                    :
                    <span></span>
                }
            </td>
        );
    }
}

export class BookletCustomCell extends React.Component<any, any> {
    public render(): JSX.Element {

       const observationId: number = this.props.dataItem["parentId"];
        //const observationId: number = this.props.dataItem["id"];

        // const blobKey: string = fileContent ? fileContent.blobKey : "";
      //  const fileDownloadUrl: string = `${window.API_URL}${GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${observationId}`;
        const fileDownloadUrl: string = `${window.API_URL}${GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${observationId}&certificatebooklet=1`;

        // const fileDownloadUrl: string = blobKey ?
        //     `${window.API_URL}filedownload;jsessionid=${window.loggedInUserDetails.jsessionid}?observationId=${observationId}&id=${blobKey}`
        //     : ``;


        return (
            <td className="no-group-show">
                <TooltipHost
                    content={`Click here to open booklet`}
                    id={tooltipId}
                    calloutProps={calloutProps}
                    styles={{
                        root:
                        {
                            textAlign: "center",
                            display: 'inline-block'
                        }
                    }}
                    key={observationId}
                >

                    <Link target='_blank' href={fileDownloadUrl}>
                        <Icon
                            iconName={IconConstants.pdfIcon.iconName}
                            styles={{
                                root: {
                                    color: "#403cbd"
                                }
                            }}
                        />
                    </Link>
                </TooltipHost>
            </td>
        );
    }
}

