import { RoleInitialState } from "../Constants/RoleIntialState";
import { IRoleData, IRoleLicenseConfig } from "../Interfaces/IRole";
import * as CONSTANTS from "./../Constants/RoleConstants";
import { RoleUtility } from "../Utility/RoleUtility";

export function RoleReducer(state = RoleInitialState, action) {
  let tempState = { ...state };
  let payload = action.payload;
  let utility: RoleUtility = new RoleUtility();
  let CurrentRole: IRoleData = { ...tempState.CurrentRole };

  switch (action.type) {

    case CONSTANTS.CLEAN_ROLE_DASHBOARD:
      tempState.Roles = [];
      tempState.offset = 0;
      tempState.role_name_filter_value = "";
      tempState.customer_name_filter_value = "";
      tempState.searchedClient = "";
      tempState.CurrentRole = CurrentRole;
      tempState.LoadingRoleDashboard = true;
      break;

    case CONSTANTS.LOAD_ROLE_DASHBOARD:
      tempState.LoadingRoleDashboard = payload.isOngoingLoading;

      if (!payload.isOngoingLoading) {
        tempState.offset += tempState.limit;
        let newRole: IRoleData[] = utility.loadRoleTableStructure(
          payload.Roles
        );
        tempState.Roles = tempState.Roles.concat(newRole);
      }

      if (payload.filtered) {
        tempState.Roles = [];
        let newRole: IRoleData[] = utility.loadRoleTableStructure(
          payload.Roles
        );
        tempState.Roles = newRole;
      }

      /**  tempState.Roles = !payload.filtered
        ? tempState.Roles.concat(payload.Roles)
        : payload.Roles;
      tempState.Roles = utility.loadRoleTableStructure(payload.Roles);*/

      /**   if (!payload.isOngoingLoading) {
        tempState.offset += tempState.limit;
        let newRole: IRoleData[] = utility.loadRoleTableStructure(
          payload.Roles
        );
        tempState.Roles = tempState.Roles.concat(newRole);
        tempState.HasMoreRoles = payload.Roles.length >= tempState.limit;
      }
*/
      break;

    case CONSTANTS.LOAD_ROLE_ATTRIBUTES:
      // tempState.LoadingRoleAddEditPanel = payload.isOngoingLoading;
      tempState.PanelHeaderText = payload.PanelHeaderText;
      tempState.searchedClient = "";
      tempState.ShowRoleAddEditPanel = true;
      CurrentRole = payload.SelectedRole ? payload.SelectedRole : CurrentRole;
      CurrentRole.tenantId = payload.tenantId;
      tempState.CurrentRole = CurrentRole;

      tempState.clients = CurrentRole.metaValue ? JSON.parse(CurrentRole.metaValue.toString()) : null;
      break;

    //SORT
    case CONSTANTS.SORT_ROLE_DASHBOARD:
      tempState.Roles = payload.sortedRoles;
      tempState.sortedColumn = payload.sortedColumn;
      tempState.sortOrder = payload.sortOrder;
      break;

    case CONSTANTS.CHANGE_ROLE_TAB:
      tempState.TabIndex = payload.TabIndex;
      break;
    // Description

    case CONSTANTS.CHANGE_SEARCHED_CLIENT:
      tempState.searchedClient = payload.searchedClient;
      break;

    case CONSTANTS.CHANGE_ROLE_NAME:
      CurrentRole.name = payload.name;
      tempState.CurrentRole = CurrentRole;
      break;

    case CONSTANTS.CHANGE_ROLE_ACRONYM_CODE:
      CurrentRole.acronymCode = payload.acronymCode;
      tempState.CurrentRole = CurrentRole;
      break;

    case CONSTANTS.CHANGE_ROLE_TYPE:
      CurrentRole.type = payload.type;
      tempState.CurrentRole = CurrentRole;
      break;

    // case CONSTANTS.LOAD_TENANT_CLIENTS:
    //   tempState.LoadingRoleAddEditPanel = payload.isOngoingLoading;
    //   if (!payload.isOngoingLoading) {
    //     tempState.clients = utility.populateLicenseConfig(payload.clients, CurrentRole.description ?
    //       JSON.parse(CurrentRole.description.toString()) : null);
    //   }
    //   break;

    case CONSTANTS.CHANGE_ROLE_LICENSE_COUNT:
      const tempClient: IRoleLicenseConfig[] = [...tempState.clients];
      const clientIndex: number = tempClient.findIndex((client: IRoleLicenseConfig) => client.clientId === payload.client.clientId);
      const client: IRoleLicenseConfig = { ...tempClient[clientIndex] };
      client.clientLicense = payload.license;
      tempClient[clientIndex] = client;
      tempState.clients = tempClient;
      break;

    case CONSTANTS.CHANGE_ROLE_CLIENT_ACTIVATION:
      const tempClientActive: IRoleLicenseConfig[] = [...tempState.clients];
      const indexOfClient: number = tempClientActive.findIndex((client: IRoleLicenseConfig) => client.clientId === payload.client.clientId);
      const finalClient: IRoleLicenseConfig = { ...tempClientActive[indexOfClient] };
      finalClient.isAllUserActive = payload.checked;
      tempClientActive[indexOfClient] = finalClient;
      tempState.clients = tempClientActive;
      break;

    /**  
      case CONSTANTS.CHANGE_ROLE_DISPLAY_NAME:
        CurrentRole.displayName = payload.displayName;
        tempState.CurrentRole = CurrentRole;
        break;
  */
    // Attributes
    case CONSTANTS.CHANGE_ROLE_FORM_ATTRIBUTE:
      // CurrentRole.attribute_form = payload.attribute_form;
      tempState.CurrentRole = CurrentRole;
      break;

    // Save Role
    case CONSTANTS.SAVE_ROLE_DETAILS:
      tempState.LoadingRoleAddEditPanel = payload.isOnGoingSave;
      break;

    case CONSTANTS.OPEN_CLOSE_ROLE_ALERT_MODAL:
      tempState.alertJSON = {
        hideAlert: payload.hideAlert,
        alertHeader: payload.alertHeader,
        alertSubText: payload.alertSubText,
      };
      tempState.LoadingRoleAddEditPanel = false;
      break;

    case CONSTANTS.OPEN_CLOSE_ROLE_CONFIRM_MODAL:
      tempState.hideConfirm = payload.hideConfirm;
      if (payload.hideConfirm) {
        tempState.CurrentRole = utility.resetCurrentRole(payload.tenantId);
      } else {
        tempState.CurrentRole = payload.CurrentRole;
      }
      break;

    case CONSTANTS.DELETE_ROLE:
      tempState.LoadingRoleDashboard = payload.isOngoingDelete;
      tempState.hideConfirm = true;
      break;

    case CONSTANTS.CLOSE_ROLE_DETAILS_PANEL:
      tempState.ShowRoleAddEditPanel = false;
      tempState.PanelHeaderText = "";
      tempState.TabIndex = 0;
      tempState.CurrentRole = utility.resetCurrentRole(payload.tenantId);
      break;

    //DASHBOARD FILTER start
    case CONSTANTS.CHANGE_ROLE_NAME_FILTER_VALUE:
      tempState.LoadingRoleDashboard = true;
      tempState.role_name_filter_value = payload.value;
      break;
    case CONSTANTS.CHANGE_CUSTOMER_NAME_FILTER_VALUE:
      tempState.customer_name_filter_value = payload.value;
      break;

    default:
      break;
  }

  return tempState;
}
