import React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { AssetTypeAPI } from '../Services/AssetTypeAPI';
import { IconConstants } from '../../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class ConfirmAssetTypeRedux extends React.PureComponent {

    _assetTypeApi = null;

    constructor(props) {
        super(props);
        this._window = window;
        this._assetTypeApi = new AssetTypeAPI(this._window.API_URL);

    }

    render() {
        let { hideConfirm, CurrentAssetType } = this.props.AssetType;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `Are you sure want to delete the Asset Type?`
        };
        return (
            <Dialog
                hidden={hideConfirm}
                onDismiss={() => this.props.openCloseAssetTypeConfirmModal({
                    hideConfirm: true,
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            this.props.deleteAssetTypeAsync({
                                CurrentAssetType: CurrentAssetType,
                                apiObject: this._assetTypeApi,
                            })

                        }}
                        text="Yes"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseAssetTypeConfirmModal({
                            hideConfirm: true,
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
