import { FIELD_ENGG_ROLE } from '../../../Constants/CommonConstants';

export const OPEN_CLOSE_PANEL = "OPEN_CLOSE_PANEL";
export const CHANGE_STEP = "CHANGE_STEP";
export const SWITCH_JOB_VIEW = "SWITCH_JOB_VIEW";

// CLIENT DETAILS
export const CHANGE_LO_OFFICE = "CHANGE_LO_OFFICE";
export const SET_CLIENT_AND_USERS = "SET_CLIENT_AND_USERS";
export const LOAD_SITES = "LOAD_SITES";
export const SET_SITE_OPTIONS = "SET_SITE_OPTIONS";
export const LOAD_PANEL = "LOAD_PANEL";
export const CHANGE_SITE = "CHANGE_SITE";
export const CHANGE_START_DATE_TIME = "CHANGE_START_DATE_TIME";
export const CHANGE_END_DATE_TIME = "CHANGE_END_DATE_TIME";
export const CHANGE_START_DURATION = "CHANGE_START_DURATION";
export const CHANGE_END_DURATION = "CHANGE_END_DURATION";
export const CHANGE_REQUESTER_NAME = "CHANGE_REQUESTER_NAME";
export const SHOW_HIDE_CLIENT_DETAILS_TIME_CLOCK = "SHOW_HIDE_CLIENT_DETAILS_TIME_CLOCK";
export const RESET_VALUE = "RESET_VALUE";

// TRACKING DETAILS
export const CHANGE_ASSING_OFFICE = "CHANGE_ASSING_OFFICE";
export const CHANGE_PO_NUMBER = "CHANGE_PO_NUMBER";
export const CHANGE_QUOTATION_NUMBER = "CHANGE_QUOTATION_NUMBER";
export const CHANGE_SALES_PERSON = "CHANGE_SALES_PERSON";
export const CHANGE_JOB_NUMBER = "CHANGE_JOB_NUMBER";
export const CHNAGE_PRICE = "CHNAGE_PRICE";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// SITE DETAILS
export const CHANGE_COMMENTS = "CHANGE_COMMENTS";
export const CHANGE_MAIN_INSPECTOR = "CHANGE_MAIN_INSPECTOR";
export const CHANGE_INSPECTORS = "CHANGE_INSPECTORS";
export const CHANGE_CLIENT_USERS = "CHANGE_CLIENT_USERS";
export const CHANGE_VENDOR_DETAILS = "CHANGE_VENDOR_DETAILS";
export const CHANGE_SUB_VENDOR_DETAILS = "CHANGE_SUB_VENDOR_DETAILS";

//TASK DETAILS
export const CREATE_NEW_TASK_ROW = "CREATE_NEW_TASK_ROW";
export const DELETE_LAST_TASK_ROW = "DELETE_LAST_TASK_ROW";
export const CHANGE_LLG_TASK_STATUS = "CHANGE_LLG_TASK_STATUS";
export const CHANGE_JOB_TASK_DIVISION = "CHANGE_JOB_TASK_DIVISION";
export const CHANGE_TASK_TYPE = "CHANGE_TASK_TYPE";
export const CHANGE_ASSET_TYPE = "CHANGE_ASSET_TYPE";
export const LOAD_ASSETS = "LOAD_ASSETS";
export const SET_ASSET_OPTIONS = "SET_ASSET_OPTIONS";
export const CHANGE_ASSET = "CHANGE_ASSET";
export const CHANGE_INSPECTOR = "CHANGE_INSPECTOR";
export const CHANGE_TASK_DUE_DATE = "CHANGE_TASK_DUE_DATE";
export const CHANGE_TASK_DUE_TIME = "CHANGE_TASK_DUE_TIME";
export const DELETE_OR_REOPEN_TASK = "DELETE_OR_REOPEN_TASK";
export const LOAD_ASSET_TYPE_AND_ASSET_OPTIONS = "LOAD_ASSET_TYPE_AND_ASSET_OPTIONS";
export const CHANGE_NEW_ASSET_NAME = "CHANGE_NEW_ASSET_NAME";
export const CHANGE_SELECTED_ASSET_TYPE = "CHANGE_SELECTED_ASSET_TYPE";
export const SAVE_NEW_ASSET = "SAVE_NEW_ASSET";
export const CHANGE_IS_DAY_JOB = "CHANGE_IS_DAY_JOB";
export const OPEN_CLOSE_JOB_CONFIRM_MODAL = "OPEN_CLOSE_JOB_CONFIRM_MODAL";
export const SHOW_HIDE_TASK_DETAILS_TIME_CLOCK = "SHOW_HIDE_TASK_DETAILS_TIME_CLOCK";
export const OPEN_CLOSE_JOB_ALERT_MODAL = "OPEN_CLOSE_JOB_ALERT_MODAL";

//DASHBOARD
export const SHOW_HIDE_JOB_DASHBOARD_COLUMNS = 'SHOW_HIDE_JOB_DASHBOARD_COLUMNS';
export const CLEAN_JOB_DASHBOARD = 'CLEAN_JOB_DASHBOARD';
export const LOAD_JOB_DASHBOARD = 'LOAD_JOB_DASHBOARD';
export const FIX_JOB_DASHBOARD = 'FIX_JOB_DASHBOARD';
export const SORT_JOB_DASHBOARD = 'SORT_JOB_DASHBOARD';
export const CHANGE_JOB_STATUS_FILTER_VALUE = 'CHANGE_JOB_STATUS_FILTER_VALUE';
export const CHANGE_JOB_NUMBER_FILTER_VALUE = 'CHANGE_JOB_NUMBER_FILTER_VALUE';
export const CHANGE_QUOTATION_NUMBER_FILTER_VALUE = 'CHANGE_QUOTATION_NUMBER_FILTER_VALUE';
export const CHANGE_JOB_CLIENT_FILTER_VALUE = 'CHANGE_JOB_CLIENT_FILTER_VALUE';
export const CHANGE_JOB_SITE_FILTER_VALUE = 'CHANGE_JOB_SITE_FILTER_VALUE';

//EDIT JOB
export const GET_CLIENT_DETAILS_IN_EDIT_PANEL = 'GET_CLIENT_DETAILS_IN_EDIT_PANEL';
export const GET_SITE_DETAILS_IN_EDIT_PANEL = 'GET_SITE_DETAILS_IN_EDIT_PANEL';

// ADD NEW SITE
export const SHOW_HIDE_ADD_NEW_SITE_SECTION_JOB = 'SHOW_HIDE_ADD_NEW_SITE_SECTION_JOB';
export const CHANGE_NEW_SITE_NAME_JOB = 'CHANGE_NEW_SITE_NAME_JOB';
export const CHANGE_NEW_SITE_STREET_JOB = 'CHANGE_NEW_SITE_STREET_JOB';
export const CHANGE_NEW_SITE_CITY_JOB = 'CHANGE_NEW_SITE_CITY_JOB';
export const CHANGE_NEW_SITE_STATE_JOB = 'CHANGE_NEW_SITE_STATE_JOB';
export const CHANGE_NEW_SITE_COUNTRY_JOB = 'CHANGE_NEW_SITE_COUNTRY_JOB';
export const CHANGE_NEW_SITE_PIN_JOB = 'CHANGE_NEW_SITE_PIN_JOB';
export const CHANGE_NEW_SITE_PHONE_JOB = 'CHANGE_NEW_SITE_PHONE_JOB';
export const SAVE_NEW_SITE_JOB = 'SAVE_NEW_SITE_JOB';

// FILE UPLOAD

export const SHOW_HIDE_JOB_UPLOAD_FILES_ERROR_TEXT = 'SHOW_HIDE_JOB_UPLOAD_FILES_ERROR_TEXT';
export const UPLOAD_JOB_FILES = 'UPLOAD_JOB_FILES';

// ADD NEW USER
export const SHOW_HIDE_ADD_NEW_USER_SECTION_JOB = 'SHOW_HIDE_ADD_NEW_USER_SECTION_JOB';
export const CHANGE_NEW_USER_NAME_JOB = 'CHANGE_NEW_USER_NAME_JOB';
export const CHANGE_NEW_USER_EMAIL_JOB = 'CHANGE_NEW_USER_EMAIL_JOB';
export const CHANGE_NEW_USER_PHONE_JOB = 'CHANGE_NEW_USER_PHONE_JOB';
export const SAVE_NEW_USER_JOB = 'SAVE_NEW_USER_JOB';


//URL
export const GET_CLIENT_URL = "Location/Client";
export const GET_LOCATION_OBJECT_URL = "Location/Object";
export const GET_SETTING_OBJECTS = "Setting/Objects";
export const GET_CONFIG_JSON_SETTING = "Setting/metadata";
export const GET_SITE_URL = "Location/Site";
export const GET_ASSET_URL = "Location/Asset";
export const GET_USERS_URL = "Users/Others";
export const SAVE_SCHEDULE = "Schedule";
export const SEND_NOTIFICATION = "getNotification";
export const SEND_NOTIFICATION_FOR_REASSIGNED_JOB = "getNotificationForReassignedJob";
export const SAVE_LOCATION = "Location";
export const SAVE_LOCATION_URL = "Location/Job";
export const SAVE_USER_URL = "Users";
export const SAVE_FILES_URL = "Schedule/attachment";
export const SAVE_ALL_OBSERVATIONS = "Observation/All";

export const JobSteps = {
    0: "Client Details",
    1: "Tracking Details",
    2: "Site Details",
    3: "Task Details",
}

export const CurrencyOptions = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "KWD", label: "KWD" },
    { value: "OMR", label: "OMR" },
    { value: "QR", label: "QR" },
    { value: "AED", label: "AED" },
    { value: "GBP", label: "GBP" },
    { value: "DZD", label: "DZD" },
    { value: "ARP", label: "ARP" },
    { value: "AUD", label: "AUD" },
    { value: "ATS", label: "ATS" },
    { value: "BSD", label: "BSD" },
    { value: "BBD", label: "BBD" },
    { value: "BEF", label: "BEF" },
    { value: "BMD", label: "BMD" },
    { value: "BRR", label: "BRR" },
    { value: "BGL", label: "BGL" },
    { value: "CAD", label: "CAD" },
    { value: "CLP", label: "CLP" },
    { value: "CNY", label: "CNY" },
    { value: "CYP", label: "CYP" },
    { value: "CSK", label: "CSK" },
    { value: "DKK", label: "DKK" },
    { value: "NLG", label: "NLG" },
    { value: "XCD", label: "XCD" },
    { value: "EGP", label: "EGP" },
    { value: "FJD", label: "FJD" },
    { value: "FIM", label: "FIM" },
    { value: "FRF", label: "FRF" },
    { value: "DEM", label: "DEM" },
    { value: "XAU", label: "XAU" },
    { value: "GRD", label: "GRD" },
    { value: "HKD", label: "HKD" },
    { value: "HUF", label: "HUF" },
    { value: "ISK", label: "ISK" },
    { value: "IDR", label: "IDR" },
    { value: "IEP", label: "IEP" },
    { value: "ILS", label: "ILS" },
    { value: "ITL", label: "ITL" },
    { value: "JMD", label: "JMD" },
    { value: "JPY", label: "JPY" },
    { value: "JOD", label: "JOD" },
    { value: "KRW", label: "KRW" },
    { value: "LBP", label: "LBP" },
    { value: "LUF", label: "LUF" },
    { value: "MYR", label: "MYR" },
    { value: "MXP", label: "MXP" },
    { value: "NLG", label: "NLG" },
    { value: "NZD", label: "NZD" },
    { value: "NOK", label: "NOK" },
    { value: "PKR", label: "PKR" },
    { value: "XPD", label: "XPD" },
    { value: "PHP", label: "PHP" },
    { value: "XPT", label: "XPT" },
    { value: "PLZ", label: "PLZ" },
    { value: "PTE", label: "PTE" },
    { value: "ROL", label: "ROL" },
    { value: "RUR", label: "RUR" },
    { value: "SAR", label: "SAR" },
    { value: "XAG", label: "XAG" },
    { value: "SGD", label: "SGD" },
    { value: "SKK", label: "SKK" },
    { value: "ZAR", label: "ZAR" },
    { value: "KRW", label: "KRW" },
    { value: "ESP", label: "ESP" },
    { value: "XDR", label: "XDR" },
    { value: "SDD", label: "SDD" },
    { value: "SEK", label: "SEK" },
    { value: "CHF", label: "CHF" },
    { value: "TWD", label: "TWD" },
    { value: "THB", label: "THB" },
    { value: "TTD", label: "TTD" },
    { value: "TRL", label: "TRL" },
    { value: "VEB", label: "VEB" },
    { value: "ZMK", label: "ZMK" },
    { value: "EUR", label: "EUR" },
    { value: "XCD", label: "XCD" },
    { value: "XDR", label: "XDR" },
    { value: "XAG", label: "XAG" },
    { value: "XAU", label: "XAU" },
    { value: "XPD", label: "XPD" },
    { value: "XPT", label: "XPT" }
];

export const JOB_STATUS = {
    1: "Active",
    2: "Ongoing",
    4: "Completed",
    16: "Closed",
    64: "Cancelled",
    1024: "Deleted"
}

export const JOB_STATUS_DROPDOWN_FILTER_OPTIONS = [
    { key: '', text: 'All States' },
    { key: 1, text: 'Active' },
    { key: 2, text: 'Inactive' },
    { key: 4, text: 'Completed' },
    { key: 16, text: 'Closed' },
    { key: 64, text: 'Cancelled' },
    { key: 1024, text: 'Deleted' },
];

export const JOB_STATUS_OPEN = 1;
export const JOB_STATUS_ONGOING = 2;
export const JOB_STATUS_COMPLETED = 4;
export const JOB_STATUS_CLOSED = 16;
export const JOB_STATUS_CANCELLED = 64;
export const JOB_STATUS_DELETED = 1024;

export const USER_ADD_JOB_DISABLE = [FIELD_ENGG_ROLE];