import React, { Component } from 'react'
import { RoleAPI } from '../Services/RoleAPI';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
//import Attributes from './Attributes';
import RoleDescription from './RoleDescription';
//import RoleFiles from './RoleFiles';
import { Panel, PanelType, Spinner, SpinnerSize, ActionButton, PrimaryButton } from 'office-ui-fabric-react';
import * as RoleConstants from './../Constants/RoleConstants';
import AlertRedux from '../../../Alert/AlertRedux';
import { IconConstants } from '../../../../Constants/StylesConstants';
import { AssetAPI } from '../../../Assets/Services/AssetAPI';

export default class AddEditRolePanel extends Component {

    _roleAPI = null;
    constructor(props) {
        super(props);
        this._roleAPI = new RoleAPI(window.API_URL, window.loggedInUserDetails);
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);
    }

    // UNSAFE_componentWillMount() {
    //     this.props.loadDropDownDataAsync({
    //         apiObject: this._roleAPI,
    //     });
    // }

    // componentDidMount() {
    //     const loggedInUserRole = window.loggedInUserDetails.role;
    //     let {
    //         CurrentRole,
    //     } = this.props.Role;
    //     if (loggedInUserRole === CONFIG_ADMIN_ROLE && CurrentRole.type === 0) {
    //         this.props.loadClients({
    //             assetApiObject: this._assetAPI
    //         })
            
    //     }
    // }

    render() {

        let {
            PanelHeaderText,
            ShowRoleAddEditPanel,
            TabIndex,
            LoadingRoleAddEditPanel,
            CurrentRole,
            alertJSON,
            clients
        } = this.props.Role;

        return (
            <Panel
                headerText={PanelHeaderText}
                isOpen={ShowRoleAddEditPanel}
                type={PanelType.medium}
                onDismiss={() => this.props.closeRolePanel({
                    ShowPanel: false,
                    PanelHeaderText: "",
                })}
                closeButtonAriaLabel="Close">
                {LoadingRoleAddEditPanel ?
                    <Spinner
                        size={SpinnerSize.large}
                        label="Please wait..."
                    />
                    :
                    <React.Fragment>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ActionButton
                                    disabled={!CurrentRole.name}
                                    text="SAVE"
                                    iconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveRoleDetailsAsync({
                                            CurrentRole: CurrentRole,
                                            apiObject: this._roleAPI,
                                            clients
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <Tabs selectedIndex={TabIndex} onSelect={(index) => this.props.changeRoleTab({ TabIndex: index })}>
                            <TabList>
                                <Tab>{RoleConstants.ROLE_FORM_TABS[0]}</Tab>
                                {/*} <Tab>{RoleConstants.DIVISION_TABS[1]}</Tab>
                                <Tab disabled>{RoleConstants.DIVISION_TABS[2]}</Tab>*/}
                            </TabList>

                            <TabPanel>
                                <RoleDescription
                                    {...this.props}
                                />
                            </TabPanel>

                            { /* <TabPanel>
                                <Attributes
                                    {...this.props}
                                />
                            </TabPanel>

                           <TabPanel>
                                <RoleFiles
                                    {...this.props}
                                />
                          </TabPanel>*/}

                        </Tabs>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    disabled={!CurrentRole.name}
                                    text="SAVE"
                                    menuIconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.props.saveRoleDetailsAsync({
                                            CurrentRole: CurrentRole,
                                            apiObject: this._roleAPI,
                                            clients
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                }
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </Panel>
        )
    }
}
