import React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { LoOfficeAPI } from '../Services/LoOfficeAPI';
import { IconConstants } from '../../../Constants/StylesConstants';

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 500 } },
};

export default class ConfirmLoOfficeDelete extends React.PureComponent {

    _loOfficeApi = null;

    constructor(props) {
        super(props);
        this._window = window;
        this._loOfficeApi = new LoOfficeAPI(this._window.API_URL, window.loggedInUserDetails);

    }

    render() {
        let { hideConfirm, CurrentLoOffice } = this.props.LoOffice;
        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Confirm",
            subText: `Are you sure want to delete the Lo Office?`
        };
        return (
            <Dialog
                hidden={hideConfirm}
                onDismiss={() => this.props.openCloseLoOfficeConfirmModal({
                    hideConfirm: true,
                })}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            this.props.deleteLoOfficeLocationAsync({
                                CurrentLoOffice: CurrentLoOffice,
                                apiObject: this._loOfficeApi,
                            })

                        }}
                        text="Yes"
                        menuIconProps={IconConstants.confirmIcon}
                    />
                    <DefaultButton
                        onClick={() => this.props.openCloseLoOfficeConfirmModal({
                            hideConfirm: true,
                        })}
                        text="Cancel"
                        menuIconProps={IconConstants.cancelIcon}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
