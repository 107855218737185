import { JobLitesInitialState } from '../Constants/JobLitesInitialState';
import * as CONSTANTS from '../Constants/JobLitesConstants';
import { IJobAction, IJobPayload } from '../Interfaces/IJobPayload';
import { JobLitesUtility } from '../Utility/JobLitesUtility';
import { IError } from '../../../CommonInterfaces/CommoInterfaces';
import { JobInitialState } from '../../Jobs/Constants/JobInitialState';
// import moment from 'moment-timezone';

export function JobLitesReducers(state = JobLitesInitialState, action: IJobAction) {

    let tempState = { ...state };
    let payload: IJobPayload = action.payload;
    let jobUtility = new JobLitesUtility();
    let CurrentJobDetails = { ...tempState.CurrentJobDetails };
    let TaskDetails = [...CurrentJobDetails.TaskDetails];
    let tempNewSite = { ...tempState.NewSite };
    let tempNewUser = { ...tempState.NewUser };
    // let startDate = null;

    switch (action.type) {

        case CONSTANTS.LOAD_JOB_LITES_PANEL:
            tempState.LoadingPanel = payload.isLoading;
            if (!payload.isLoading) {
                tempState.CurrentJobDetails = (payload.isError || payload.isAsset) ? tempState.CurrentJobDetails :
                    JobLitesInitialState.CurrentJobDetails;
            }
            tempState.hideConfirm = true;
            break;

        case CONSTANTS.SHOW_HIDE_JOB_DASHBOARD_COLUMNS:
            let tempColumns = [...tempState.jobDashboardColumns];
            let indexOfSelectedColumn: number = tempColumns.indexOf(payload.selectedColumn.key.toString());
            if (indexOfSelectedColumn > -1) {
                tempColumns.splice(indexOfSelectedColumn, 1);
            }
            else {
                tempColumns.push(payload.selectedColumn.key.toString());
            }
            tempState.jobDashboardColumns = tempColumns;
            break;

        case CONSTANTS.OPEN_CLOSE_JOB_LITES_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON;
            tempState.LoadingPanel = false;
            if (payload.isAsset || payload.isError) {
                tempState.ShowPanel = true;
            }
            else if (payload.isCancelClose) {
                tempState.ShowPanel = false;
            }
            else {
                tempState.ShowPanel = !payload.alertJSON.hideAlert;
            }
            break;
        // Job Dashboard Operations Start
        case CONSTANTS.CLEAN_JOB_LITES_DASHBOARD:
            if (!payload.isScrolled) {
                tempState.Jobs = [];
                tempState.job_status_filter_value = "";
                tempState.job_number_filter_value = "";
                tempState.job_client_filter_value = "";
                tempState.job_site_filter_value = "";
                tempState.job_qn_filter_value = "";
                tempState.offset = 0;
            }
            tempState.ShowActiveJobs = true;
            tempState.ShowPanel = false;
            tempState.LoadingDashboard = true;
            tempState.CurrentJobDetails = JobLitesInitialState.CurrentJobDetails;
            tempState.LoadingAssetSection = false;
            tempState.NewAsset = JobLitesInitialState.NewAsset;
            tempState.SelectedAssetType = JobLitesInitialState.SelectedAssetType;
            break;

        case CONSTANTS.LOAD_JOB_LITES_DASHBOARD:
            tempState.LoadingDashboard = false;
            tempState.jobDashboardColumns = JobLitesInitialState.jobDashboardColumns;
            if (payload.filterType === "status") {
                tempState.ShowActiveJobs =
                    !payload.filterValue ||
                    parseInt(payload.filterValue) === CONSTANTS.JOB_STATUS_ONGOING ||
                    parseInt(payload.filterValue) === CONSTANTS.JOB_STATUS_OPEN ||
                    parseInt(payload.filterValue) === CONSTANTS.JOB_STATUS_COMPLETED;
            }
            tempState.Jobs = !payload.filtered ? tempState.Jobs.concat(payload.Jobs) : payload.Jobs;
            tempState.TotalJobsCount = payload.TotalJobsCount;
            tempState.HasMoreJobs = tempState.TotalJobsCount > tempState.Jobs.length;
            tempState.filters = payload.filters;
            tempState.offset = payload.offset + JobLitesInitialState.limit;
            tempState.scheduleId = JobLitesInitialState.scheduleId;
            break;

        case CONSTANTS.FIX_JOB_LITES_DASHBOARD:
            tempState.HasMoreJobs = payload.HasMoreJobs;
            break;

        case CONSTANTS.SORT_JOB_LITES_DASHBOARD:
            tempState.Jobs = payload.sortedJobs;
            tempState.sortedColumn = payload.sortedColumn;
            tempState.sortOrder = payload.sortOrder;
            break;

        case CONSTANTS.SWITCH_JOB_LITES_VIEW:
            tempState.ShowActiveJobs = payload.ShowActiveJobs;
            break;

        case CONSTANTS.CLOSE_OR_CANCEL_JOB:
            tempState.LoadingDashboard = payload.isLoading;
            tempState.ShowPanel = false;
            tempState.hideConfirm = true;
            break;

        case CONSTANTS.OPEN_CLOSE_JOB_LITES_CONFIRM_MODAL:
            tempState.hideConfirm = payload.hideConfirm;
            tempState.scheduleId = payload.scheduleId;
            tempState.closeOrCancel = payload.status;
            tempState.ShowPanel = false;
            break;

        //DASHBOARD FILTER start

        case CONSTANTS.CHANGE_JOB_LITES_JOB_STATUS_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_status_filter_value = payload.filterValue;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_JOB_SITE_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_site_filter_value = payload.filterValue;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_JOB_CLIENT_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_client_filter_value = payload.filterValue;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_JOB_NUMBER_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_number_filter_value = payload.filterValue;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_QUOTATION_NUMBER_FILTER_VALUE:
            tempState.LoadingDashboard = true;
            tempState.job_qn_filter_value = payload.filterValue;
            break;

        //DASHBOARD FILTER end

        // Job Dashboard Operations End

        // Job Add Edit Panel End

        case CONSTANTS.OPEN_CLOSE_JOB_LITES_PANEL:
            tempState.ShowPanel = payload.ShowPanel;
            tempState.PanelHeaderText = payload.PanelHeaderText;
            tempState.ActiveStep = 0;
            tempState.LoadingPanel = true;
            tempState.SelectedJob = payload.SelectedJob;
            tempState.CurrentJobDetails = JobLitesInitialState.CurrentJobDetails;
            tempState.IsJobEditable = true;
            tempState.ShowAddNewSiteSection = false;
            tempState.NewSite = JobLitesInitialState.NewSite;
            tempState.ShowAddNewUserSection = false;
            tempState.NewUser = JobLitesInitialState.NewUser
            break;

        case CONSTANTS.LOAD_JOB_LITES_SITES:
            tempState.LoadingSites = payload.isLoading;
            CurrentJobDetails.ClientName = {
                value: payload.Location,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            if (!payload.isLoading) {
                tempState.SiteOptions = jobUtility.setSiteOptions(payload.Locations);
            }
            break;

        case CONSTANTS.CHANGE_JOB_LITES_SITE:
            CurrentJobDetails.SiteDetails = {
                value: payload.Location,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_START_DATE_TIME:
            CurrentJobDetails.StartDate = {
                value: payload.StartDate,
                error: false,
                errorMessage: ""
            };

            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.SHOW_HIDE_JOB_LITES_CLIENT_DETAILS_TIME_CLOCK:
            tempState.showTimeClock = payload.showTimeClock;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_REQUESTER_NAME:
            CurrentJobDetails.RequesterName = {
                value: payload.Requester,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.SET_JOB_LITES_CLIENT_AND_USERS:
            tempState.LoadingPanel = false;
            tempState.showLLGButton = payload.showLLGButton;
            tempState.ClientOptions = jobUtility.setLocationOptions(payload.Clients);
            tempState.OfficeOptions = jobUtility.setLocationOptions(payload.Offices);
            tempState.RequesterOptions = jobUtility.setUserOptions(payload.Users);
            tempState.AllInspectorOptions = jobUtility.setInspetorOptions(payload.Users);
            tempState.AllTaskTypeOptions = jobUtility.setSettingsOptions(payload.TaskTypes);
            tempState.DivisionOptions = jobUtility.setSettingsOptions(payload.Divisions);
            tempState.AssetTypeOptions = jobUtility.setSettingsOptions(payload.AssetTypes);
            tempState.Roles = payload.Roles;
            tempState.IsJobEditable = true;
            // tempState.AllAssetTypeOptions = jobUtility.setAssetTypeOptions(payload.AssetTypes);
            if (!payload.SelectedJob) {
                tempState.CurrentJobDetails = jobUtility.CreateJobObject(
                    null, null, tempState.OfficeOptions, null, null, null, null, null
                );
            }
            else {
                tempState.SiteOptions = jobUtility.setLocationOptions(payload.Locations);
                // tempState.InspectorOptions = jobUtility.getInspectorOptions(tempState.RequesterOptions, payload.SelectedJob.officeId, payload.Roles);
                let jobObject = jobUtility.CreateJobObject(
                    payload.SelectedJob,
                    tempState.ClientOptions,
                    tempState.OfficeOptions,
                    tempState.SiteOptions,
                    tempState.RequesterOptions,
                    tempState.AllInspectorOptions,
                    payload.Tasks,
                    tempState.AllTaskTypeOptions,
                    tempState.DivisionOptions
                );
                if (jobObject) {
                    tempState.CurrentJobDetails = jobObject;
                }
                else {
                    tempState.IsJobEditable = false;
                }
            }
            break;


        case CONSTANTS.CHANGE_JOB_LITES_CLIENT_USERS:
            CurrentJobDetails.ClientUsers = {
                value: payload.ClientUsers,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        // Tracking Details ( start )
        case CONSTANTS.CHANGE_JOB_LITES_ASSING_OFFICE:
            CurrentJobDetails.AssignOffice = {
                value: payload.Location,
                error: false,
                errorMessage: ""
            };

            CurrentJobDetails.MainInspector = {
                value: null,
                error: false,
                errorMessage: ""
            };

            let tempInspectorOptions = [];
            tempState.CurrentJobDetails = CurrentJobDetails;

            tempState.InspectorOptions = tempInspectorOptions;
            break;

        case CONSTANTS.CHNAGE_JOB_LITES_PRICE:
            CurrentJobDetails.Price = {
                value: payload.Price,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        // Tracking Details ( end )

        // Site Details ( start )
        case CONSTANTS.CHANGE_JOB_LITES_COMMENTS:
            CurrentJobDetails.Comments = {
                value: payload.Comments,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_MAIN_INSPECTOR:
            CurrentJobDetails.MainInspector = {
                value: payload.User,
                error: false,
                errorMessage: ""
            };
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;
        // Site Details ( end )

        // Task Details Changes Start
        case CONSTANTS.CREATE_JOB_LITES_NEW_TASK_ROW:
            TaskDetails.push(JobLitesInitialState.CurrentJobDetails.TaskDetails[0]);
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.DELETE_JOB_LITES_LAST_TASK_ROW:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            TaskDetails.pop();
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_TASK_TYPE:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            var changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.TaskType = {
                value: payload.TaskType,
                error: false,
                errorMessage: ""
            };
            changedTaskRow.isDirty = true;
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.DELETE_OR_REOPEN_JOB_LITES_TASK:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.status = payload.taskStatus;
            changedTaskRow.isDirty = true;
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.LOAD_JOB_LITES_ASSET_TYPE_AND_ASSET_OPTIONS:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.TaskType = {
                value: payload.TaskType,
                error: false,
                errorMessage: "",
            };
            changedTaskRow.Asset = {
                value: null,
                error: false,
                errorMessage: "",
                loading: payload.isLoading
            };
            changedTaskRow.AssetType = {
                value: payload.AssetType ? payload.AssetType : null,
                error: false,
                errorMessage: ""
            };

            TaskDetails[payload.index] = changedTaskRow;
            changedTaskRow.AssetOptions = jobUtility.setAssetOptions(payload.Locations ? payload.Locations : []);
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_JOB_TASK_DIVISION:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.TaskType = {
                value: null,
                error: false,
                errorMessage: "",
            };
            changedTaskRow.Asset = {
                value: null,
                error: false,
                errorMessage: "",
                loading: payload.isLoading
            };
            changedTaskRow.AssetType = {
                value: null,
                error: false,
                errorMessage: ""
            };
            changedTaskRow.Division = {
                value: payload.Division,
                error: false,
                errorMessage: ""
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_ASSET:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.Asset = {
                value: payload.Location,
                error: false,
                errorMessage: "",
                loading: false
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_INSPECTOR:
            TaskDetails = [...CurrentJobDetails.TaskDetails];
            changedTaskRow = { ...TaskDetails[payload.index] };
            changedTaskRow.isDirty = true;
            changedTaskRow.Inspector = {
                value: payload.User,
                error: false,
                errorMessage: ""
            };
            TaskDetails[payload.index] = changedTaskRow;
            CurrentJobDetails.TaskDetails = TaskDetails;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_IS_DAY_JOB:
            CurrentJobDetails.isDayJob = payload.isDayJob;
            tempState.CurrentJobDetails = CurrentJobDetails;
            break;
        // Task Details Changes End

        case CONSTANTS.SHOW_HIDE_JOB_LITES_ADD_NEW_SITE_SECTION_JOB:
            tempState.ShowAddNewSiteSection = payload.ShowAddNewSiteSection;
            tempState.NewSiteSaveStatus = payload.NewSiteSaveStatus ?
                payload.NewSiteSaveStatus :
                {
                    flag: true,
                    message: ""
                };
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_NAME_JOB:
            tempNewSite.name = `${payload.value}`;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_STREET_JOB:
            tempNewSite.street = `${payload.value}`;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_CITY_JOB:
            tempNewSite.city = `${payload.value}`;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_STATE_JOB:
            tempNewSite.state = `${payload.value}`;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_COUNTRY_JOB:
            tempNewSite.country = `${payload.value}`;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_PIN_JOB:
            tempNewSite.pin = parseInt(`${payload.value}`);
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_SITE_PHONE_JOB:
            tempNewSite.phone = `${payload.value}`;
            tempState.NewSite = tempNewSite;
            break;

        case CONSTANTS.SAVE_JOB_LITES_NEW_SITE_JOB:
            tempState.LoadingNewSiteSection = payload.isLoading;
            tempState.NewSiteSaveStatus = payload.NewSiteSaveStatus ?
                payload.NewSiteSaveStatus :
                {
                    flag: true,
                    message: ""
                };
            if (!payload.isLoading) {
                tempState.NewSite = JobLitesInitialState.NewSite;
                let tempSiteOptions = [...tempState.SiteOptions];

                tempSiteOptions.push({
                    value: payload.newLocation.id,
                    label: payload.newLocation.name,
                    locationLevel: payload.newLocation.locationLevel,
                    details: `${payload.newLocation.street}, ${payload.newLocation.pin}`
                })
                tempState.SiteOptions = tempSiteOptions;
            }
            break;

        // New Site Section End

        // New User Section Start
        case CONSTANTS.SHOW_HIDE_JOB_LITES_ADD_NEW_USER_SECTION_JOB:
            tempState.ShowAddNewUserSection = payload.ShowAddNewUserSection;
            tempState.LoadingNewUserSection = payload.isLoading;
            tempState.NewUserSaveStatus = payload.NewUserSaveStatus ?
                payload.NewUserSaveStatus :
                {
                    flag: true,
                    message: ""
                };
            tempState.NewUser = {
                email: tempState.CurrentJobDetails.SiteDetails.value.label + new Date().getMilliseconds() + '@xlsys.co',
                name: "",
                phone: ""
            }
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_USER_NAME_JOB:
            tempNewUser.name = `${payload.value}`;
            tempState.NewUser = tempNewUser;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_USER_EMAIL_JOB:
            tempNewUser.email = `${payload.value}`;
            tempState.NewUser = tempNewUser;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_USER_PHONE_JOB:
            tempNewUser.phone = `${payload.value}`;
            tempState.NewUser = tempNewUser;
            break;

        case CONSTANTS.SAVE_JOB_LITES_NEW_USER_JOB:
            tempState.LoadingNewUserSection = payload.isLoading;
            tempState.NewUserSaveStatus = payload.NewUserSaveStatus ?
                payload.NewUserSaveStatus :
                {
                    flag: true,
                    message: ""
                };
            if (!payload.isLoading) {
                tempState.NewUser = {
                    name: "",
                    email: "",
                    phone: ""
                };
                let tempRequesterOptions = [...tempState.RequesterOptions];

                tempRequesterOptions.push({
                    value: payload.newUser.id,
                    label: payload.newUser.name,
                    type: payload.newUser.type,
                    details: `${payload.newUser.email}, ${payload.newUser.phone}`,
                    companyId: payload.newUser.companyId,
                    officeId: payload.newUser.officeId,
                    secondaryOfficeIds: payload.newUser.secondaryOfficeIds,
                    siteIds: payload.newUser.siteIds,
                    divisionIds: payload.newUser.divisionIds,
                    roleId: payload.newUser.roleId
                })
                tempState.RequesterOptions = tempRequesterOptions;
            }
            break;

        // New User Section End

        // Asset Section start

        case CONSTANTS.OPEN_CLOSE_ADD_NEW_ASSET_POPUP:
            tempState.isOpenAddNewAsset = payload.isOpenAddNewAsset;
            tempState.SelectedAssetType = null;
            tempState.NewAsset = JobInitialState.NewAsset;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_SELECTED_ASSET_TYPE:
            tempState.SelectedAssetType = payload.SelectedAssetType;
            break;

        case CONSTANTS.CHANGE_JOB_LITES_NEW_ASSET_NAME:
            let newAssetName = `${payload.value}`;
            let tempNewAsset: IError = { ...tempState.NewAsset };
            let asetNameRegex = localStorage.getItem("assetNameRegex").split("");
            tempNewAsset = {
                value: newAssetName,
                error: !newAssetName.trim() || asetNameRegex.map((item) => newAssetName.trim().includes(item)).filter(item => item === true).length > 0,
                errorMessage: !newAssetName.trim() ?
                    "Asset Name is Mandatory" :
                    asetNameRegex.map((item) => newAssetName.trim().includes(item)).filter(item => item === true).length > 0 ? "Invalid character(s) in Asset Name" : ""
            }
            tempState.NewAsset = tempNewAsset;
            break;

        case CONSTANTS.ADD_NEW_JOB_LITES_ASSET:
            tempState.LoadingAssetSection = payload.isLoading;
            if (!payload.isLoading) {
                tempState.isOpenAddNewAsset = payload.isOpenAddNewAsset;
                tempState.NewAsset = payload.NewAsset;
                tempState.SelectedAssetType = null;
            }
            break;

        // Asset Section end

        // Job Reset Start
        case CONSTANTS.RESET_JOB_LITES_VALUE:
            tempState.CurrentJobDetails = JobLitesInitialState.CurrentJobDetails;
            break;

        // Job Reset End

        // Job Add Edit Panel End

        default:
            break;
    }

    return tempState;
}