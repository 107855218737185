import {
    ActionButton,
    DefaultButton, Icon,
    INavLink,
    INavLinkGroup,
    Label, Nav, PrimaryButton, SearchBox, Separator, TooltipHost
} from 'office-ui-fabric-react';
import * as React from 'react';
import $ from "jquery";
import { calloutProps, IconConstants, seperatorTheme, tooltipId } from '../../../Constants/StylesConstants';
import { TaskAPI } from '../Services/TaskAPI';
import Select from 'react-select';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from "react-loading-overlay";
import AlertRedux from '../../Alert/AlertRedux';
import { IAttributeFormId, IChecklistData, IQsUserDataFormData, IRecord, IUserData } from '../Interfaces/TaskInterfaces';
import ConfirmTaskRedux from './ConfirmTaskRedux';
import { TaskUtility } from '../Utility/TaskUtility';
import { IFile } from '../../../CommonInterfaces/CommoInterfaces';
import { classNames } from '../../../Constants/CustomStyles';
import { FIELD_ENGG_ROLE } from '../../../Constants/CommonConstants';
import * as TaskConstants from '../Constants/TaskConstants';
import CreateChildTaskPanel from './CreateChildTaskPanel';
import ConfirmDeleteLLGChildTasks from './ConfirmDeleteLLGChildTasks';

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require('formBuilder/dist/form-render.min.js');

export default class TaskInspectLLGChild extends React.PureComponent<any, any> {
    private _taskApi: TaskAPI;
    private formRenderDiv = null;
    private _taskUtil: TaskUtility;

    public constructor(props: any) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);
        this._taskUtil = new TaskUtility();
    }

    public componentDidMount() {
        this.formRenderDiv = $('#checkListForm');
        this.props.loadChildLLGTaskList({
            parentObservationId: this.props.Task.SelectedTask.id,
            apiObject: this._taskApi
        });
    }

    public loadChildLLGTaskDetails = (selectedTask: any) => {
        this.formRenderDiv = $('#checkListForm');
        this.props.findLLGObservationById({
            SelectedLLGTask: selectedTask,
            apiObject: this._taskApi,
            formRenderDiv: $(this.formRenderDiv),
        });
    }

    public loadFormData = (checkList: IChecklistData, isRedirectedFromSection?: boolean): void => {
        if (checkList.sectionFormId) {
            this.props.findFormDetailsById({
                SelectedCheckListSection: isRedirectedFromSection ? checkList : this.props.Task.SelectedCheckListSection,
                SelectedCheckListItem: isRedirectedFromSection ? null : checkList,
                apiObject: this._taskApi,
                formRenderDiv: $(this.formRenderDiv),
                isRedirectedFromSection
            })
        }
    }

    public loadCheckListItem = (checkList: IChecklistData): void => {
        let isChildPresent =
            this.props.Task.CurrentTaskChecklistData.find((data: IChecklistData) =>
                data.parentSectionId === checkList.id.toString())

        if (isChildPresent) {
            this.props.loadItemList({
                SelectedCheckListSection: checkList,
                ShowItemList: true,
                formRenderDiv: $(this.formRenderDiv),
            })
        }
        else {
            this.loadFormData(checkList, true);
        }
    }

    public saveForm = (createFlag?: boolean): void => {

        let { CurrentSelectedForm } = this.props.Task;
        let oldCheckListData = [...this.props.Task.CurrentTaskChecklistData];
        let currentSelectedCheckList: IChecklistData = null;

        if (this.props.Task.SelectedCheckListSection && this.props.Task.SelectedCheckListItem) {
            currentSelectedCheckList = this.props.Task.SelectedCheckListItem;
        }
        else if (this.props.Task.SelectedCheckListSection) {
            currentSelectedCheckList = this.props.Task.SelectedCheckListSection;
        }
        let indexOfCheckList: number =
            oldCheckListData.findIndex((checkList: IChecklistData) => checkList.id === currentSelectedCheckList.id);

        let tempSelectedCheckList: IChecklistData = oldCheckListData[indexOfCheckList];

        let indexOfCurrentFormInAttributes: number = tempSelectedCheckList.userData?.records[0]?.qsUserDataFormData?.attribute_form_id
            .findIndex((form: any) => parseInt(form.formRecordId) === parseInt(CurrentSelectedForm?.formRecordId));

        let attributes: any = indexOfCurrentFormInAttributes > -1 ?
            tempSelectedCheckList.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[indexOfCurrentFormInAttributes]?.attributes :
            tempSelectedCheckList.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[0]?.attributes;
        let attributesFiles: any = indexOfCurrentFormInAttributes > -1 ?
            tempSelectedCheckList.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[indexOfCurrentFormInAttributes]?.attributesFiles :
            tempSelectedCheckList.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[0]?.attributesFiles;

        let formRenderDiv = $(this.formRenderDiv);
        let formUserdata = formRenderDiv.formRender('userData');
        const files = this.setAndGetFilesInForm(formRenderDiv, formUserdata);
        attributesFiles = this._taskUtil.addAttributesFiles(formUserdata, attributesFiles);
        if (!attributes) {
            let newAttributes: any = this._taskUtil.addAttributes(formUserdata);

            tempSelectedCheckList["userData"] = this.populateUserData(newAttributes, attributesFiles, tempSelectedCheckList);

        }
        else if (createFlag) {
            let newAttributes = this._taskUtil.addAttributes(this._taskUtil.removeUserData(formUserdata));

            let userData: IUserData = { ...tempSelectedCheckList.userData };
            let records: IRecord[] = [...userData.records];
            let record: IRecord = { ...records[0] };
            let qsUserDataFormData: IQsUserDataFormData = { ...record.qsUserDataFormData }
            let attribute_form_id: IAttributeFormId[] = [...qsUserDataFormData.attribute_form_id];

            attribute_form_id.push({
                attributes: newAttributes,
                attributesFiles: {},
                formId: tempSelectedCheckList.sectionFormId,
                formName: `Form Name ${attribute_form_id.length + 1}`,
                formRevision: new Date().getTime(),
                formRecordId: new Date().getTime(),
            });

            qsUserDataFormData.attribute_form_id = attribute_form_id;
            record.qsUserDataFormData = qsUserDataFormData;
            records[0] = record;
            userData.records = records;
            tempSelectedCheckList.userData = userData;
        }
        else {
            let updatedAttributes = this._taskUtil.updateAttributes(attributes, formUserdata);

            let attribute_form_id: IAttributeFormId = tempSelectedCheckList.userData.records[0].qsUserDataFormData
                .attribute_form_id[indexOfCurrentFormInAttributes];
            tempSelectedCheckList.userData.records[0].qsUserDataFormData
                .attribute_form_id[indexOfCurrentFormInAttributes > -1 ? indexOfCurrentFormInAttributes : 0] = {
                attributes: updatedAttributes,
                attributesFiles: attributesFiles,
                formId: tempSelectedCheckList.sectionFormId,
                formName: attribute_form_id?.formName ?
                    attribute_form_id?.formName : `Form Name ${indexOfCurrentFormInAttributes > -1 ? (indexOfCurrentFormInAttributes + 1) : 1}`,
                formRevision: attribute_form_id?.formRevision ? attribute_form_id.formRevision : new Date().getTime(),
                formRecordId: attribute_form_id?.formRevision ? attribute_form_id.formRevision : new Date().getTime(),
            }

        }

        oldCheckListData[indexOfCheckList] = tempSelectedCheckList;
        let payload = {
            SelectedTask: this.props.Task.SelectedChildLLGTask,
            CheckListData: oldCheckListData,
            formRenderDiv: formRenderDiv,
            apiObject: this._taskApi,
            files: files

        };
        this.props.saveCheckListDetails(payload);

    }

    public setAndGetFilesInForm = (formRenderDiv: any, formUserdata: any) => {
        var files = [];

        formRenderDiv.find('input').each(
            function (unusedIndex: number, child: any) {
                if (child.files) {
                    formUserdata.forEach((element: any) => {
                        if (child.name && child.name.includes(element.name)) {
                            element.userData = [];
                            for (let i = 0; i < child.files.length; i++) {
                                element.userData.push(child.files[i].name);
                                files.push(child.files[i]);

                            }
                        }
                    });
                }
            });
        return files;
    }

    public populateUserData = (newAttributes: any, attributesFiles: any, checkList: IChecklistData): IUserData => {

        let attribute_form_id: IAttributeFormId[] = [];
        attribute_form_id.push({
            "attributes": newAttributes,
            "attributesFiles": attributesFiles,
            formId: checkList.sectionFormId,
            formName: `Form Name 1`,
            formRevision: new Date().getTime(),
            formRecordId: new Date().getTime(),
        });

        let qsUserDataFormData: IQsUserDataFormData = {};
        qsUserDataFormData["attribute_form_id"] = attribute_form_id;

        let records: IRecord[] = [] as IRecord[];

        records.push({ "qsUserDataFormData": qsUserDataFormData });

        let userData: IUserData = {} as IUserData;
        userData["records"] = records;

        return userData;
    }

    public deleteFile = (
        fileName: string,
        blobKey?: string,
        jsonKey?: string,
        groupedFiles?: any,
    ): void => {
        //To do , get all the variables used in below line, refer saveForm code for it
        let { CurrentSelectedForm } = this.props.Task;
        let oldCheckListData = [...this.props.Task.CurrentTaskChecklistData];
        let currentSelectedCheckList: IChecklistData = null;

        if (
            this.props.Task.SelectedCheckListSection &&
            this.props.Task.SelectedCheckListItem
        ) {
            currentSelectedCheckList = this.props.Task.SelectedCheckListItem;
        }
        else if (this.props.Task.SelectedCheckListSection) {
            currentSelectedCheckList = this.props.Task.SelectedCheckListSection;
        }
        let indexOfCheckList: number = oldCheckListData.findIndex(
            (checkList: IChecklistData) =>
                checkList.id === currentSelectedCheckList.id
        );


        let tempSelectedCheckList: IChecklistData =
            oldCheckListData[indexOfCheckList];

        let indexOfCurrentFormInAttributes: number =
            tempSelectedCheckList.userData?.records[0]?.qsUserDataFormData?.attribute_form_id.findIndex(
                (form: any) =>
                    parseInt(form.formRecordId) ===
                    parseInt(CurrentSelectedForm?.formRecordId)
            );

        let attributesFiles: any =
            indexOfCurrentFormInAttributes > -1
                ? tempSelectedCheckList.userData?.records[0]?.qsUserDataFormData
                    ?.attribute_form_id[indexOfCurrentFormInAttributes]?.attributesFiles
                : tempSelectedCheckList.userData?.records[0]?.qsUserDataFormData
                    ?.attribute_form_id[0]?.attributesFiles;

        let FileListForJSONKey: any = attributesFiles[jsonKey];
        const deletedFile = FileListForJSONKey.find((file) => {
            return file.blobKey === blobKey;
        });
        deletedFile.isDeletedLocally = !deletedFile.isDeletedLocally;

        let fileObjects = groupedFiles.find(obj => {
            return obj.name === jsonKey
        })

        let links = fileObjects.links;

        for (let i = 0; i < links.length; i++) {
            if (links[i].blobKey === blobKey) {
                links[i].isDeletedLocally = !links[i].isDeletedLocally;
                break;
            }
        }

    };


    public render(): JSX.Element {

        let {
            isLoadingChildLLGTasks,
            CurrentTaskChecklistData,
            SelectedCheckListSection,
            LoadingFormSection,
            alertJSON,
            ShowItemList,
            SelectedCheckListItem,
            SelectedChildLLGTask,
            ChildLLGTasks,
            CurrentSectionForms,
            CurrentFormOtherData,
            CurrentSelectedForm,
            hideConfirm,
            hideConfirmDeleteChildLLGTask,
            FormFiles,
            SelectedTask,
            isOpenCreateChildtaskPanel,
            ChildLLGTaskSearchFilterText
        } = this.props.Task;

        let filteredChildLLGTasks: any[] = ChildLLGTaskSearchFilterText ?
            [...ChildLLGTasks]
                .filter((item) => item.description.toUpperCase().indexOf(ChildLLGTaskSearchFilterText.toUpperCase()) > -1)
            : [...ChildLLGTasks];

        let groupedFiles: INavLinkGroup[] = [];

        FormFiles.forEach((fileItems: any, mainIndex: number) => {
            for (let jsonKey in fileItems) {
                let fileContents: IFile[] = fileItems[jsonKey];
                let links: INavLink[] = [];
                fileContents.forEach((file: IFile, index: number) => {
                    if (file.blobKey) {
                        links.push({
                            key: `${file.fileName}_${mainIndex}_${index}`,
                            name: `${file.fileName}`,
                            url: '#',
                            icon: IconConstants.fileIcon.iconName,
                            blobKey: `${file.blobKey}`,
                            jsonKey: jsonKey,
                        });
                    }
                });
                groupedFiles.push({
                    name: jsonKey,
                    links: links
                })
            }

        });

        let filteredCheckListData: IChecklistData[] = [];
        let filteredItemListData: IChecklistData[] = [];

        if (SelectedChildLLGTask && CurrentTaskChecklistData) {
            CurrentTaskChecklistData.forEach((checkList: IChecklistData) => {
                if (checkList.nodeType === "SECTION" && checkList.parentSectionId === "-100") {
                    filteredCheckListData.push({
                        id: checkList.id,
                        nodeType: checkList.nodeType,
                        parentSectionId: checkList.parentSectionId,
                        displayName: checkList.displayName,
                        isSelected: SelectedCheckListSection?.id === checkList.id,
                        sectionFormId: checkList.sectionFormId,
                        description: checkList.description,
                        iconGroupId: checkList.iconGroupId,
                        itemKey: checkList.itemKey,
                        itemResult: checkList.itemResult,
                        name: checkList.name,
                        reportCategory: checkList.reportCategory,
                        sectionLevel: checkList.sectionLevel,
                        sequenceNumber: checkList.sequenceNumber,
                        taskTypeId: checkList.taskTypeId,
                        userData: checkList.userData,
                    });
                }
            });
        }
        if (CurrentTaskChecklistData && SelectedCheckListSection) {
            filteredItemListData =
                CurrentTaskChecklistData.filter((checkList: IChecklistData) =>
                    checkList.parentSectionId === SelectedCheckListSection.id && checkList.nodeType === "ITEM");
        }

        let isInspectorAndTaskOpen = window.loggedInUserDetails?.role === FIELD_ENGG_ROLE
            && (SelectedTask.status === TaskConstants.TASK_STATUS_OPEN ||
                SelectedTask.status === TaskConstants.TASK_STATUS_ONGOING ||
                SelectedTask.status === TaskConstants.TASK_STATUS_CANCELLED ||
                SelectedTask.status === TaskConstants.TASK_STATUS_ONHOLD ||
                SelectedTask.status === TaskConstants.TASK_STATUS_AMENDED);

        return (

            <LoadingOverlay
                active={isLoadingChildLLGTasks}
                spinner={<FadeLoader />}
                text='Please Wait...'>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Separator theme={seperatorTheme}>CHILDREN
                                        {isInspectorAndTaskOpen &&
                                            <TooltipHost
                                                content={`Add New Task`}
                                                id={tooltipId}
                                                calloutProps={calloutProps}
                                                styles={{
                                                    root:
                                                    {
                                                        display: 'inline-block',

                                                    }
                                                }}
                                            >
                                                <Icon styles={{
                                                    root: {
                                                        marginLeft: "10px",
                                                        cursor: "pointer",
                                                    }
                                                }} iconName={IconConstants.addIcon.iconName}
                                                    onClick={() => this.props.openCloseCreateChildTaskPanel({
                                                        isOpenCreateChildtaskPanel: true
                                                    })
                                                    }
                                                />
                                            </TooltipHost>
                                        }
                                    </Separator>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <SearchBox
                                        placeholder="Search Child LLG Task"
                                        value={ChildLLGTaskSearchFilterText}
                                        type="Text"
                                        underlined
                                        onChange={(ev: any, value: string) => {
                                            this.props.searchByChildLLGTaskDescription({
                                                ChildLLGTaskSearchFilterText: value,
                                            })
                                        }}
                                        onClear={(ev: any) => {
                                            this.props.searchByChildLLGTaskDescription({
                                                ChildLLGTaskSearchFilterText: "",
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                                    style={{
                                        height: "30vh",
                                        overflowY: "scroll"
                                    }}>
                                    {filteredChildLLGTasks?.length > 0 ?
                                        <ul className="child-checklist-section">
                                            {filteredChildLLGTasks.map((childLLG: any) => {
                                                return (
                                                    <div className='custom-next-prev-pagination'>
                                                        <li
                                                            key={childLLG.id}
                                                            className={SelectedChildLLGTask?.id === childLLG.id ?
                                                                "selected-checklist" : ""}
                                                            onClick={() => {
                                                                this.loadChildLLGTaskDetails(childLLG);
                                                            }}
                                                        >
                                                            {childLLG.description}
                                                        </li>
                                                        <Icon
                                                            iconName={IconConstants.deleteicon.iconName}
                                                            styles={{
                                                                root: {
                                                                    color: "#F40F02",
                                                                    margin: "5% 0 0 2.5%",
                                                                    cursor: "Pointer"
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                this.props.openCloseChildLLGTaskDeleteConfirmModal({
                                                                    SelectedLLGTaskToBeDeleted: childLLG,
                                                                    hideConfirmDeleteChildLLGTask: false,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </ul>
                                        :
                                        <Label>No Section Found</Label>
                                    }
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Separator theme={seperatorTheme}>SECTIONS</Separator>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 section-item"
                                    style={{
                                        height: "35vh",
                                        overflowY: "scroll"
                                    }}>

                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            {filteredCheckListData.length > 0 ?
                                                <ul className="child-checklist-section">
                                                    {filteredCheckListData.map((checkList: IChecklistData) => {
                                                        return (
                                                            <li
                                                                key={checkList.id}
                                                                className={checkList.isSelected ? "selected-checklist" : ""}
                                                                onClick={() => {
                                                                    this.loadCheckListItem(checkList);
                                                                }}
                                                            >
                                                                {checkList.displayName}
                                                                {/* {checkList.id} */}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                :
                                                <Label>No Section Found</Label>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <Separator theme={seperatorTheme}>ITEMS</Separator>
                            {ShowItemList && filteredItemListData.length > 0 ?
                                <ul className="checklist-item">
                                    {filteredItemListData.map((checkList: IChecklistData, index: number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div key={`${index} _div`}
                                                    onClick={() => {
                                                        this.loadFormData(checkList)
                                                    }}
                                                    className={checkList.id === SelectedCheckListItem?.id ?
                                                        "item-list-help-div selected-checklist" : "item-list-help-div"}>
                                                    <li key={checkList.id}>
                                                        {checkList.displayName}
                                                    </li>
                                                    <TooltipHost
                                                        content={`${checkList.description ? checkList.description : ""} `}
                                                        id={tooltipId}
                                                        calloutProps={calloutProps}
                                                        styles={{
                                                            root:
                                                            {
                                                                display: 'inline-block',
                                                                marginTop: '5px'
                                                            }
                                                        }}
                                                    >
                                                        <Icon
                                                            iconName={IconConstants.helpIcon.iconName}
                                                        />
                                                    </TooltipHost>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </ul>
                                :
                                <Label>No Item Found</Label>
                            }
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Separator theme={seperatorTheme}>
                                        FORM
                                    </Separator>
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                                style={{
                                    height: "70vh",
                                    overflowY: "scroll"
                                }}>
                                <LoadingOverlay
                                    active={LoadingFormSection}
                                    spinner={<FadeLoader />}
                                    text='Please Wait...'>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                            {CurrentFormOtherData?.formType === "MULTIPLE" &&
                                                <Select
                                                    placeHolder="--Select Form--"
                                                    value={CurrentSelectedForm}
                                                    onChange={(newVal: any) => {
                                                        this.props.populateFormDetails({
                                                            selectedForm: newVal,
                                                            formRenderDiv: $(this.formRenderDiv)
                                                        })
                                                    }}
                                                    options={CurrentSectionForms}
                                                />

                                            }
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 button-right">
                                            <PrimaryButton
                                                disabled={
                                                    LoadingFormSection
                                                    || (!SelectedCheckListSection)
                                                    || (filteredItemListData.length > 0 && !SelectedCheckListItem)
                                                    || (CurrentFormOtherData?.formType === "MULTIPLE" && !CurrentSelectedForm)
                                                }
                                                text="Save"
                                                menuIconProps={IconConstants.saveicon}
                                                onClick={() => this.saveForm()}
                                            />
                                        </div>
                                    </div>
                                    {CurrentFormOtherData?.formType === "MULTIPLE" &&
                                        <React.Fragment>
                                            <div className="ms-Grid-row">

                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                                    <Label>Min: {CurrentFormOtherData?.samplesMin}</Label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                                    <Label>Max: {CurrentFormOtherData?.samplesMax}</Label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 button-right">
                                                    <DefaultButton
                                                        disabled={
                                                            CurrentSectionForms.length >= CurrentFormOtherData?.samplesMax
                                                        }
                                                        className={
                                                            (CurrentSectionForms.length >= CurrentFormOtherData?.samplesMax) ?
                                                                "disable-button" : "open-panel-button"
                                                        }
                                                        text="New"
                                                        menuIconProps={IconConstants.addIcon}
                                                        onClick={() => this.saveForm(true)}
                                                    />
                                                </div>
                                            </div>
                                            <Separator theme={seperatorTheme}></Separator>
                                        </React.Fragment>
                                    }


                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <form id="checkListForm"></form>
                                    </div>
                                    {groupedFiles && groupedFiles.length > 0 &&
                                        <React.Fragment>
                                            <h6 className="text-center sub-header-item">Files</h6>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className={classNames.container} data-is-scrollable={true}>
                                                    <Nav
                                                        ariaLabel="Nav basic example"
                                                        styles={{
                                                            root: {
                                                                boxSizing: "border-box",
                                                                border: "none",
                                                                height: 750,
                                                            },
                                                            link: {
                                                                height: "2.5em",
                                                                padding: "0px 20px 0px 28px",
                                                            },
                                                        }}
                                                        onLinkClick={(
                                                            event?: React.MouseEvent<HTMLElement>,
                                                            item?: any
                                                        ) => {
                                                            event.preventDefault();
                                                        }}
                                                        onRenderLink={(props) => (
                                                            <React.Fragment>
                                                                <span
                                                                    onClick={() => {
                                                                        window.open(
                                                                            `${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${props.blobKey}`,
                                                                            "_blank"
                                                                        );
                                                                    }}

                                                                    style={{ textDecoration: props.isDeletedLocally ? 'line-through' : '' }}
                                                                >
                                                                    {props.name}
                                                                </span>
                                                                <ActionButton
                                                                    menuIconProps={props.isDeletedLocally ? IconConstants.revertIcon : IconConstants.deleteicon}
                                                                    onClick={() => {
                                                                        this.deleteFile(
                                                                            props.name,
                                                                            props.blobKey,
                                                                            props.jsonKey,
                                                                            groupedFiles,
                                                                        );
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        )}
                                                        groups={groupedFiles}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }

                                </LoadingOverlay >
                            </div>
                        </div>



                    </div>
                </div>
                {
                    isOpenCreateChildtaskPanel &&
                    <CreateChildTaskPanel
                        {...this.props}
                    />
                }
                {
                    !hideConfirm &&
                    <ConfirmTaskRedux
                        {...this.props}
                    />
                }
                {
                    !hideConfirmDeleteChildLLGTask &&
                    <ConfirmDeleteLLGChildTasks
                        {...this.props}
                    />
                }
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </LoadingOverlay >
        )
    }

}
