import React from 'react';
import { connect } from 'react-redux';
import { IClientSiteSelectorStateProps } from '../ClientSiteSelectorInteface/IClientSiteSelectorStateProps'
import { ClientSiteSelectorActions } from '../Actions/ClientSiteSelectorActions'
import { Dispatch, bindActionCreators } from 'redux'
import ClientSiteSelectorDashboard from './ClientSiteSelectorDashboard';

const mapStateToProps = (state: any): IClientSiteSelectorStateProps => {
    return {
        ClientSiteSelectorState: state.ClientSiteSelectorReducer,
    }
}

class ClientSiteSelectorComponent extends React.PureComponent<IClientSiteSelectorStateProps, {}> {

    public render(): JSX.Element {

        return (
            <React.Fragment>
                <ClientSiteSelectorDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    let clientSiteSelectorActions: ClientSiteSelectorActions = new ClientSiteSelectorActions();
    return bindActionCreators({ ...clientSiteSelectorActions }, dispatch);
}

const ClientSiteSelector = connect(mapStateToProps, mapDispatchToProps)(ClientSiteSelectorComponent);
export default ClientSiteSelector;