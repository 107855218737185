import { APPROVER_ROLE, APPROVER_ONLY_ROLE, CLIENT_ADMIN_ROLE, CLIENT_USER_ROLE, CORPORATE_ROLE, FIELD_ENGG_ROLE, FIELD_JUNIOR_ROLE, LO_ADMIN_ROLE, REQUESTOR_ROLE, SALES_PERSON_ROLE, SCHEDULER_ROLE, SITE_USER_ROLE } from "../../../Constants/CommonConstants";

export const CLEAN_USER_DASHBOARD = 'CLEAN_USER_DASHBOARD';
export const LOAD_USER_DASHBOARD = 'LOAD_USER_DASHBOARD';
export const FIX_USER_DASHBOARD = 'FIX_USER_DASHBOARD';
export const SORT_USER_DASHBOARD = 'SORT_USER_DASHBOARD';
export const DELETE_USER = 'DELETE_USER';
export const OPEN_CLOSE_USER_CONFIRM_MODAL = 'OPEN_CLOSE_USER_CONFIRM_MODAL';
export const OPEN_CLOSE_USER_ALERT_MODAL = 'OPEN_CLOSE_USER_ALERT_MODAL';
export const SWITCH_USER_VIEW = 'SWITCH_USER_VIEW';

// USER PANEL

export const OPEN_CLOSE_ADD_EDIT_USER_PANEL = 'OPEN_CLOSE_ADD_EDIT_USER_PANEL';
export const CHANGE_USER_NAME = 'CHANGE_USER_NAME';
export const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL';
export const CHANGE_USER_PHONE = 'CHANGE_USER_PHONE';
export const CHANGE_USER_DESCRIPTION = 'CHANGE_USER_DESCRIPTION';
export const CHANGE_USER_EXTERNAL_ID = 'CHANGE_USER_EXTERNAL_ID';
export const CHANGE_USER_TYPE = 'CHANGE_USER_TYPE';
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export const CHANGE_USER_COMPANY = 'CHANGE_USER_COMPANY';
export const CHANGE_USER_PRIMARY_OFFICE = 'CHANGE_USER_PRIMARY_OFFICE';
export const CHANGE_USER_OTHER_OFFICES = 'CHANGE_USER_OTHER_OFFICES';
export const CHANGE_USER_CLIENT_SITES = 'CHANGE_USER_CLIENT_SITES';
export const CHANGE_USER_DIVISIONS = 'CHANGE_USER_DIVISIONS';
export const SAVE_USER = 'SAVE_USER';
export const LOAD_ADD_EDIT_USER_PANEL = 'LOAD_ADD_EDIT_USER_PANEL';
export const LOAD_USER_LOCATIONS_DIVISIONS_ROLES = 'LOAD_USER_LOCATIONS_DIVISIONS_ROLES';
export const LOAD_USER_PANEL_LOCATIONS = 'LOAD_USER_PANEL_LOCATIONS';
export const UPLOAD_USER_SIGNATURE = 'UPLOAD_USER_SIGNATURE';
export const SHOW_HIDE_UPLOAD_IMAGE_ERROR_TEXT = 'SHOW_HIDE_UPLOAD_IMAGE_ERROR_TEXT';
export const DELETE_USER_SIGNATURE = 'DELETE_USER_SIGNATURE';
export const SHOW_HIDE_DELETE_IMAGE_ERROR_TEXT = 'SHOW_HIDE_DELETE_IMAGE_ERROR_TEXT';

// FILTER
export const CHANGE_USER_NAME_FILTER_VALUE = "CHANGE_USER_NAME_FILTER_VALUE";
export const CHANGE_USER_EMAIL_FILTER_VALUE = "CHANGE_USER_EMAIL_FILTER_VALUE";
export const CHANGE_USER_ROLE_FILTER_VALUE = "CHANGE_USER_ROLE_FILTER_VALUE";
export const CHANGE_USER_LOCATION_FILTER_VALUE = "CHANGE_USER_LOCATION_FILTER_VALUE";
export const CHANGE_USER_STATUS_FILTER_VALUE = "CHANGE_USER_STATUS_FILTER_VALUE";
export const CHANGE_USER_DIVISION_FILTER_VALUE = "CHANGE_USER_DIVISION_FILTER_VALUE";

//URL
export const GET_USER_COUNT_URL = 'Users/count';
export const USERS_MAIN_URL = 'Users';
export const GET_CLIENT_URL = "Location/Client";
export const GET_LOCATION_OBJECT_URL = "Location/Object";
export const GET_SETTING_DATA_URL = "Setting/Others";
export const UPLOAD_DELETE_USER_SIGNATURE_URL = "Users/signature";


//STATUS
export const USER_STATUS = {
    "Remove": -1,
    "Archive": -2,
    "Suspend": -3,
    "Activate": 1
}

export const USER_STATUS_REV = {
    "-1": "Removed",
    "-2": "Archived",
    "-3": "Suspended",
    "1": "Activated"
}

export const USER_STATUS_DROPDOWN_FILTER_OPTIONS = [
    { key: '', text: 'All States' },
    { key: -1, text: 'Removed' },
    { key: -2, text: 'Archived' },
    { key: -3, text: 'Suspended' },
    { key: 1, text: 'Active' }
];

export const USER_TYPE_MENU = [
    { key: 0, text: 'LO USERS' },
    { key: 1, text: 'CLIENT USERS' }
];

export const LO_USERS_DESIGNATIONS =
    [SCHEDULER_ROLE, FIELD_ENGG_ROLE, APPROVER_ROLE,APPROVER_ONLY_ROLE,
        CORPORATE_ROLE, FIELD_JUNIOR_ROLE, SALES_PERSON_ROLE];

export const LO_ADMIN_DESIGNATIONS =
    [SCHEDULER_ROLE, FIELD_ENGG_ROLE, APPROVER_ROLE, LO_ADMIN_ROLE,APPROVER_ONLY_ROLE,
        CORPORATE_ROLE, FIELD_JUNIOR_ROLE, SALES_PERSON_ROLE];

export const CLIENT_USERS_DESIGNATIONS =
    [REQUESTOR_ROLE, SITE_USER_ROLE, CLIENT_USER_ROLE];

export const LO_ADMIN_CLIENT_DESIGNATIONS =
    [REQUESTOR_ROLE, SITE_USER_ROLE, CLIENT_USER_ROLE, CLIENT_ADMIN_ROLE];

export const CLIENT_ADMIN_DESIGNATIONS =
    [REQUESTOR_ROLE, SITE_USER_ROLE, CLIENT_USER_ROLE];

export const USER_BREADCRUMBS = [
    { text: 'RESOURCES', key: 'Resources' },
    { text: 'Users', key: 'Users' }
];
