import { DefaultButton, Label, PrimaryButton, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react';
import React, { Component } from 'react'
import { AssetAPI } from '../Services/AssetAPI';
import Select from 'react-select';
import { IconConstants } from '../../../Constants/StylesConstants';
import AlertRedux from '../../Alert/AlertRedux';
import ShiftAssetModal from './ShiftAssetModal';
import { ASSET_SAVE_DESCRIPTION_DISABLED } from '../../Tasks/Constants/TaskConstants';

export default class AssetDescription extends Component {

    _assetAPI = null;

    constructor(props) {
        super(props);
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);
    }

    // componentDidMount() {
    //     this.props.loadAssetDescription({
    //         apiObject: this._assetAPI,
    //         selectedAssetId: this.props.Asset.selectedAssetId
    //     })
    // }

    saveOrUpdateAsset = () => {

        let {
            selectedClientFilter,
            selectedAssetDescription,
            selectedAssetId,
            isUnderTaskDetailsView,
        } = this.props.Asset;

        let assetPayload = {
            id: selectedAssetId,
            assetTypeId: selectedAssetDescription.assetType.value,
            companyId: selectedClientFilter.value,
            parentId: selectedAssetDescription.parentSite.value,
            objectType: "ASSET",
            name: selectedAssetDescription.name.value,
            endUserName: selectedAssetDescription.endUserName,
            endUserAddress: selectedAssetDescription.endUserAddress,
            externalId: selectedAssetDescription.externalId,
            acronymCode: selectedAssetDescription.acronymCode,
            type: 1,
            status: 1,
            locationLevel: 1,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        this.props.saveAssetDescription({
            apiObject: this._assetAPI,
            locationPayload: assetPayload,
            isUnderTaskDetailsView
        });
    }

    render() {

        let {
            isLoadingAssetDescriptionTab,
            clients,
            sites,
            selectedClientFilter,
            selectedAssetDescription,
            assetTypes,
            responseStatusDetails,
            selectedAssetId,
            hideShiftAssetDialogue,
            alertJSON,
            isUnderTaskDetailsView,
            selectedTask
        } = this.props.Asset;

        let currentUserRole = window.loggedInUserDetails.role;

        let isSaveOrShiftDisabled = selectedAssetDescription.name?.error
            || !selectedAssetDescription.assetType
            || !selectedAssetDescription.parentSite
            || currentUserRole === "CLIENT-USER"
            || currentUserRole === "SITE-USER"
            || (isUnderTaskDetailsView && ASSET_SAVE_DESCRIPTION_DISABLED.indexOf(selectedTask.status) > -1);


        return (
            <React.Fragment>
                {isLoadingAssetDescriptionTab ?
                    <div className="login-spinner-div">
                        <Spinner
                            label="Please wait..."
                            size={SpinnerSize.large} />
                    </div>
                    :
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label className="breadcrumb-label">Asset Description</Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <TextField
                                    label="Name"
                                    value={selectedAssetDescription.name?.value}
                                    onChange={(event, newVal) => {
                                        this.props.changeFieldAssetDescriptionTab({
                                            changedField: "name",
                                            changedText: newVal
                                        })
                                    }}
                                    required
                                    errorMessage={selectedAssetDescription.name?.errorMessage}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <TextField
                                    label="Serial Number"
                                    value={selectedAssetDescription?.acronymCode}
                                    onChange={(ev, newVal) => {
                                        this.props.changeFieldAssetDescriptionTab({
                                            changedField: "acronymCode",
                                            changedText: newVal
                                        })
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <TextField
                                    label="External Id"
                                    value={selectedAssetDescription?.externalId}
                                    onChange={(ev, newVal) => {
                                        this.props.changeFieldAssetDescriptionTab({
                                            changedField: "externalId",
                                            changedText: newVal
                                        })
                                    }}
                                />

                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <Label>Type <span className="mandatory-span">*</span></Label>
                                <Select
                                    isDisabled={isUnderTaskDetailsView}
                                    value={selectedAssetDescription.assetType}
                                    options={assetTypes}
                                    onChange={(newVal) => {
                                        this.props.changeFieldAssetDescriptionTab({
                                            changedField: "assetType",
                                            changedOption: newVal
                                        })
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                {/* <TextField
                                    label="Type Code"
                                    readOnly
                                    value={selectedAssetDescription.assetType ? selectedAssetDescription.assetType.acronymCode : ""}
                                /> */}
                                <Label>Client</Label>
                                <Select
                                    isDisabled
                                    value={selectedClientFilter}
                                    options={clients}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <Label>Site <span className="mandatory-span">*</span></Label>
                                <Select
                                    value={selectedAssetDescription.parentSite}
                                    isDisabled={selectedAssetId > 0}
                                    options={sites}
                                    onChange={(newVal) => {
                                        this.props.changeFieldAssetDescriptionTab({
                                            changedField: "site",
                                            selectedLocation: newVal
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <TextField
                                    label="End User Name"
                                    maxLength={200}
                                    value={selectedAssetDescription.endUserName}
                                    onChange={(ev, newVal) => {
                                        this.props.changeFieldAssetDescriptionTab({
                                            changedField: "endUserName",
                                            changedText: newVal
                                        })
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <TextField
                                    label="End User Address"
                                    multiline
                                    maxLength={500}
                                    value={selectedAssetDescription.endUserAddress}
                                    onChange={(ev, newVal) => {
                                        this.props.changeFieldAssetDescriptionTab({
                                            changedField: "endUserAddress",
                                            changedText: newVal
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <PrimaryButton
                                    disabled={isSaveOrShiftDisabled}
                                    text="Save"
                                    menuIconProps={IconConstants.saveicon}
                                    onClick={() => this.saveOrUpdateAsset()}
                                />
                                {` `}
                                <DefaultButton
                                    disabled={isSaveOrShiftDisabled || isUnderTaskDetailsView}
                                    text="SHIFT ASSET"
                                    menuIconProps={IconConstants.moveIcon}
                                    onClick={() => this.props.loadOpenObservations({
                                        apiObject: this._assetAPI,
                                        selectedAssetId: selectedAssetId,
                                    })}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label className={responseStatusDetails?.errorFlag ? "mandatory-span" : "success-span"}>
                                    {responseStatusDetails?.statusMessage}
                                </Label>
                            </div>
                        </div>
                    </div>
                }
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
                {
                    !hideShiftAssetDialogue &&
                    <ShiftAssetModal
                        {...this.props}
                    />
                }
            </React.Fragment>
        )
    }
}
