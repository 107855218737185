import React, { PureComponent } from 'react'
import { Label, TextField } from 'office-ui-fabric-react';
import Select from 'react-select';
import { ASSET_TYPE_CATEGORY } from '../Constants/AssetTypeConstants';

export default class Description extends PureComponent {
    render() {

        const { CurrentAssetType } = this.props.AssetType;

        const AssetTypeCategory = { label: CurrentAssetType.category, value: CurrentAssetType.category };

        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label className="breadcrumb-label"> Asset Type Description </Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            value={CurrentAssetType.name}
                            label="Type Name"
                            onChange={(event, newValue) => {
                                this.props.changeAssetTypeDescription({
                                    field: "name",
                                    name: newValue
                                });
                            }}
                            required
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            value={CurrentAssetType.acronym_code}
                            label="Type Code"
                            onChange={(event, newValue) => {
                                this.props.changeAssetTypeDescription({
                                    field: "acronymCode",
                                    acronym_code: newValue
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Label>Category</Label>
                        <Select
                            isClearable
                            value={AssetTypeCategory}
                            onChange={(newValue) => {
                                this.props.changeAssetTypeDescription({
                                    field: "category",
                                    description: newValue.value
                                });
                            }}
                            options={ASSET_TYPE_CATEGORY}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            value={CurrentAssetType.description}
                            label="Description"
                            onChange={(event, newValue) => {
                                this.props.changeAssetTypeDescription({
                                    field: "description",
                                    description: newValue
                                });
                            }}
                            multiline
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            multiline
                            value={CurrentAssetType.displayName}
                            onChange={(event, newValue) => {
                                this.props.changeAssetTypeDescription({
                                    field: "displayName",
                                    displayName: newValue
                                });
                            }}
                            label="Display Name"
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <TextField
                            readOnly
                            value={CurrentAssetType.external_id}
                            label="External Id"
                        />
                    </div>
                </div>

            </div>
        )
    }
}
