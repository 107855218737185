import {
    ISetting, IConfigData, ISettingMetadata
} from '../Interfaces/ConfigInterface';
import { ISelect } from '../../../../CommonInterfaces/CommoInterfaces';
import moment from 'moment';

export class ConfigUtility {

    loadConfigStructure = (Configs: any[]): IConfigData[] => {

        let FormattedConfigs: IConfigData[] = [];

        Configs.forEach((element: any) => {
            let settingWithMetadata: ISetting = JSON.parse(element.settingWithMetadata);

            let settingMetatDatas: ISettingMetadata[] = settingWithMetadata.settingmetadata;

            let configJson: ISettingMetadata | undefined = settingMetatDatas
                .find((d: ISettingMetadata) => d.meta_key === "config_json");

            FormattedConfigs.push({
                id: settingWithMetadata.id,
                key: settingWithMetadata.id,
                name: settingWithMetadata.name,
                // configJson: JSON.parse(configJson.meta_value),
                configJson: JSON.stringify(JSON.parse(configJson.meta_value), null, 2),
                created_date: settingWithMetadata.created_date
            });

        });

        return FormattedConfigs;

    }

    loadFormAttributesOptions = (FormAttributes: any[]): ISelect[] => {
        let FormAttributeOptions: ISelect[] = [];

        FormAttributes.forEach((element: any) => {
            FormAttributeOptions.push({
                value: element.id,
                label: element.name
            });
        })
        return FormAttributeOptions;
    }

    resetCurrentConfig = (): IConfigData => {
        let CurrentConfig: IConfigData = {
            id: -1,
            key: -1,
            name: "",
            configJson: "{}",
            created_date: new Date()
        };

        return CurrentConfig;
    }

    generateConfigJSONPayLoad = (Configuration: IConfigData) => {

        let payloadJSON = {};
        if (Configuration.id === -1) {
            payloadJSON = {
                "tenant_id": 2,
                "object_type": "ConfigData",
                "name": Configuration.name,
                "description": "",
                "type": 0,
                "status": 1,
                "parent_id": null,
                "user_data": null,
                "created_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "modified_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "created_by": 2,
                "last_modified_by": null,
                "external_id": null,
                "client_id": null,
                "display_name": null,
                "acronym_code": 0,
                "archived": 0,
                "settingmetadata": [
                    {
                        "tenant_id": 2,
                        "meta_key": "config_json",
                        "meta_value": JSON.stringify(JSON.parse(Configuration.configJson)),
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Certificate Reference"
                    },
                ]
            }
        }
        else {
            payloadJSON = {
                "id": Configuration.id,
                "tenant_id": 2,
                "object_type": "ConfigData",
                "name": Configuration.name,
                "description": "",
                "type": 0,
                "status": 1,
                "parent_id": null,
                "user_data": null,
                "created_date": moment(Configuration.created_date).format("YYYY-MM-DD HH:mm:ss"),
                "modified_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "created_by": 2,
                "last_modified_by": null,
                "external_id": null,
                "client_id": null,
                "display_name": null,
                "acronym_code": "1",
                "archived": 0,
                "settingmetadata": [
                    {
                        "setting_id": Configuration.id,
                        "tenant_id": 2,
                        "meta_key": "config_json",
                        "meta_value": JSON.stringify(JSON.parse(Configuration.configJson)),
                        "data_type": "STRING",
                        "object_type": "Config JSON",
                        "display_name": "Certificate Reference"
                    }
                ]
            }
        }

        return payloadJSON;
    }
}
