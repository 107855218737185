import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AssetActions } from '../Actions/AssetActions';
import AssetMainFilter from './AssetMainFilter';
import { Breadcrumb } from 'office-ui-fabric-react';
import { ASSET_BREADCRUMBS } from '../Constants/AssetConstants';

const mapStateToProps = (state) => {
    return {
        Asset: state.AssetReducer
    }
}


class AssetComponent extends Component {

    render() {
        let breadcrumbs = ASSET_BREADCRUMBS;
        breadcrumbs[1].text = sessionStorage.getItem('link');
        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={ASSET_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                </div>
                <AssetMainFilter
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let assetAction = new AssetActions();

    return bindActionCreators({ ...assetAction }, dispatch);
}

const Assets = connect(mapStateToProps, mapDispatchToProps)(AssetComponent);
export default Assets;
