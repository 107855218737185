import React, { Component } from 'react';
import { Label, TextField, Spinner, Separator, PrimaryButton, TooltipHost, IconButton, DatePicker } from 'office-ui-fabric-react';
import { JobAPI } from '../Services/JobAPI';
import Select from 'react-select';
import { calloutProps, hostStyles, IconConstants, seperatorTheme, tooltipId } from '../../../Constants/StylesConstants';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment-timezone';
import * as COMMON_CONSTANTS from './../../../Constants/CommonConstants';
import { TaskUtility } from './../../Tasks/Utility/TaskUtility';

export default class ClientDetails extends Component {

    _apiObject = null;
    _taskUtility = null;
    constructor(props) {
        super(props);
        let config = {
            headers: {}
        }
        this._apiObject = new JobAPI(window.API_URL, config)
        this._taskUtility = new TaskUtility();
    }

    render() {

        let {
            ClientOptions,
            SiteOptions,
            RequesterOptions,
            CurrentJobDetails,
            LoadingSites,
            SelectedJob,
            ShowAddNewSiteSection,
            LoadingNewSiteSection,
            NewSite,
            NewSiteSaveStatus,
            ShowAddNewUserSection,
            LoadingNewUserSection,
            NewUser,
            NewUserSaveStatus,
            showTimeClock,
            OfficeOptions
        } = this.props.Job;

        let timerStr = moment(new Date()).format('HH:mm');

        const loggedInUser = window.loggedInUserDetails;

        if (CurrentJobDetails.StartDate) {
            timerStr = moment(new Date(CurrentJobDetails.StartDate.value)).format('HH:mm');
        }

        let currentUserOfficeIds = [...new Set(loggedInUser.secondaryOfficeIds
            .split(",").map(officeId => +officeId).concat(loggedInUser.primaryOfficeId))];

        let filteredOfficeOptions = [];
       // let filteredClientOptions = ClientOptions?.filter((client) => client.officeId === CurrentJobDetails?.LoOffice?.value?.officeId);
       let filteredClientOptions = [...ClientOptions];
        OfficeOptions.forEach((office) => {
            if (currentUserOfficeIds.indexOf(office.value) > -1) {
                filteredOfficeOptions.push(office);
            }
        });


        return (

            <div className="ms-Grid" dir="ltr">
                {/*!SelectedJob &&
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Label>Lo Office <span className="mandatory-span">*</span></Label>
                            <Select
                                value={CurrentJobDetails.hasOwnProperty("LoOffice") ? CurrentJobDetails.LoOffice.value : null}
                                placeholder="Please Select Lo Office"
                                onChange={(value) => {
                                    if (value) {
                                        this.props.changeLoOffice({
                                            LoOffice: value
                                        })
                                    }
                                }}
                                options={CommonUtility.sortByColumn(filteredOfficeOptions, "label", "asc")}
                            />
                        </div>
                    </div>*/
                }
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg11">
                        <Label>Client Name <span className="mandatory-span">*</span></Label>
                        <Select
                            // isClearable
                           // isDisabled={SelectedJob || (!SelectedJob && !CurrentJobDetails.LoOffice)}
                           isDisabled={SelectedJob}
                            autoBlur={true}
                            placeholder="Please Select Client Name"
                            value={CurrentJobDetails.hasOwnProperty("ClientName") ? CurrentJobDetails.ClientName.value : null}
                            onChange={(value) => {
                                if (value) {
                                    this.props.loadSitesAsync({
                                        companyId: value.value,
                                        apiObject: this._apiObject,
                                        Client: value
                                    })
                                }
                            }}
                           
                            options={filteredClientOptions ?
                                CommonUtility.sortByColumn(filteredClientOptions, "label", "asc") : []}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {CurrentJobDetails.hasOwnProperty("ClientAddress") && CurrentJobDetails.ClientAddress.value &&
                            <React.Fragment>
                                <Label>Client Address <span className="mandatory-span">*</span></Label>
                                <code>
                                    <i className="code-details">
                                        {CurrentJobDetails.ClientAddress.value}
                                    </i>
                                </code>
                            </React.Fragment>
                        }
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label>
                            Site Details <span className="mandatory-span">*</span>
                        </Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg11">
                        {LoadingSites ?
                            <Spinner label="Loading Sites" />
                            :
                            <Select
                                placeholder="Please Select Site Name"
                                value={CurrentJobDetails.hasOwnProperty("SiteDetails") ? CurrentJobDetails.SiteDetails.value : null}
                                onChange={(value) => {
                                    if (value) {
                                        this.props.changeSite({
                                            Site: value
                                        })
                                    }
                                }}
                                options={CommonUtility.sortByColumn(SiteOptions.filter(d => d.locationLevel === 0), "label", "asc")}
                            />
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md2 ms-lg1">

                        <TooltipHost
                            content={ShowAddNewSiteSection ? `Close Add Site Panel` : `Add New Site`}
                            id={tooltipId}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            {ShowAddNewSiteSection ?
                                <IconButton
                                    iconProps={IconConstants.cancelIcon}
                                    onClick={() => {
                                        this.props.showHideAddNewSiteSectionJob({
                                            ShowAddNewSiteSection: false
                                        })
                                    }}
                                />
                                :
                                <IconButton
                                    iconProps={IconConstants.addIcon}
                                    disabled={!CurrentJobDetails.ClientName || !CurrentJobDetails.ClientName.value}
                                    onClick={() => {
                                        this.props.showHideAddNewSiteSectionJob({
                                            ShowAddNewSiteSection: true
                                        })
                                    }}
                                />
                            }

                        </TooltipHost>
                    </div>

                </div>
                {/* Add Site Start */}
                {ShowAddNewSiteSection &&
                    <React.Fragment>
                        {LoadingNewSiteSection ?
                            <Spinner label="Saving New Site..." />
                            :
                            <div className="ms-Grid add-extra-pane">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Separator theme={seperatorTheme}>Add New Site</Separator>

                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <small className="mandatory-span">
                                            {NewSiteSaveStatus.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <TextField
                                            label="Site Name"
                                            required
                                            value={NewSite.name}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewSiteField({
                                                    field: "name",
                                                    name: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>Site Address<span className="mandatory-span">*</span></Label>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <TextField
                                            label="House No. &amp; Street:"
                                            required
                                            value={NewSite.street}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewSiteField({
                                                    field: "street",
                                                    street: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <TextField
                                            label="City:"
                                            value={NewSite.city}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewSiteField({
                                                    field: "city",
                                                    city: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <TextField
                                            label="State:"
                                            value={NewSite.state}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewSiteField({
                                                    field: "state",
                                                    state: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <TextField
                                            label="Coutry:"
                                            value={NewSite.country}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewSiteField({
                                                    field: "country",
                                                    country: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <TextField
                                            label="Pin:"
                                            type="number"
                                            min={0}
                                            value={NewSite.pin}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewSiteField({
                                                    field: "pin",
                                                    pin: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <TextField
                                            label="Phone:"
                                            value={NewSite.phone}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewSiteField({
                                                    field: "phone",
                                                    phone: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <PrimaryButton
                                            text="Save"
                                            iconProps={IconConstants.saveicon}
                                            disabled={
                                                !NewSite.name ||
                                                SiteOptions.filter(d => d.locationLevel === 0).find(d => d.label === NewSite.name) !== undefined ||
                                                !NewSite.street
                                            }
                                            onClick={() => {
                                                this.props.saveNewSiteJobAsync({
                                                    apiObject: this._apiObject,
                                                    NewSite: NewSite,
                                                    ClientId: CurrentJobDetails.ClientName ? CurrentJobDetails.ClientName.value : -1
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                }

                {/* Add Site End */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {CurrentJobDetails.hasOwnProperty("SiteAddress") && CurrentJobDetails.SiteAddress.value &&
                            <React.Fragment>
                                <Label>Site Address</Label>
                                <code>
                                    <i className="code-details">
                                        {CurrentJobDetails.SiteAddress.value}
                                    </i>
                                </code>
                            </React.Fragment>
                        }
                    </div>
                </div>

                {/* Start Date & name */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">
                        <DatePicker
                            label={`Start Date`}
                            allowTextInput={false}
                            value={CurrentJobDetails.hasOwnProperty("StartDate") ? CurrentJobDetails.StartDate.value : new Date()}
                            firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                            strings={COMMON_CONSTANTS.DayPickerStrings}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            formatDate={(date) => this._taskUtility.onFormatDate(date)}
                            onSelectDate={(date) => {
                                let dateStr =
                                    moment(date).format('YYYY-MM-DD');
                                let selectedDatetime = moment(dateStr + " " + timerStr);

                                this.props.changeStartDateTime({
                                    StartDate: new Date(selectedDatetime.format())
                                });
                            }}
                            isRequired
                        // minDate={new Date(CurrentJobDetails.EndDate?.value.getTime() - (24 * 3600 * 1000))}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">
                        <Label>Start Time</Label>
                        {!showTimeClock ?
                            <React.Fragment>
                                <TextField
                                    readOnly
                                    value={timerStr}
                                    onClick={() => {
                                        this.props.showHideClientDetailsTimeClock({
                                            showTimeClock: !showTimeClock
                                        })
                                    }}
                                    iconProps={IconConstants.timePickerIcon}
                                    styles={{
                                        field: {
                                            cursor: "pointer",
                                        }
                                    }}
                                />
                            </React.Fragment>
                            :
                            <TimeKeeper
                                time={timerStr}
                                hour24Mode={true}
                                onDoneClick={() => {
                                    this.props.showHideClientDetailsTimeClock({
                                        showTimeClock: !showTimeClock
                                    })
                                }}
                                onChange={(newTime) => {
                                    let dateStr =
                                        CurrentJobDetails.StartDate ?
                                            moment(CurrentJobDetails.StartDate.value).format('YYYY-MM-DD') :
                                            moment(new Date()).format('YYYY-MM-DD');

                                    let selectedDatetime = moment(dateStr + " " + newTime.formatted12);

                                    this.props.changeStartDateTime({
                                        StartDate: new Date(selectedDatetime.format())
                                    });
                                }}
                            />
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1"></div>

                </div>

                {/* End Date and Duration */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">
                        <TextField
                            value={CurrentJobDetails.hasOwnProperty("StartDuration") ? CurrentJobDetails.StartDuration.value : 1}
                            label="Duration (Days)"
                            type="number"
                            min={1}
                            onChange={(event, newval) => {
                                this.props.changeStartDuration({
                                    StartDuration: newval
                                });
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">
                        <DatePicker
                            label={`End Date (Calculated)`}
                            allowTextInput={false}
                            disabled
                            value={CurrentJobDetails.EndDate?.value}
                            firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                            strings={COMMON_CONSTANTS.DayPickerStrings}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            formatDate={(date) => this._taskUtility.onFormatDate(date)}
                            onSelectDate={(date) => {
                                this.props.changeEndDateTime({
                                    EndDate: date
                                });
                            }}
                        // minDate={new Date(CurrentJobDetails.StartDate?.value.getTime() + (24 * 3600 * 1000))}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1"></div>

                </div>

                {/* Requester */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label>Requester Name</Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg11">
                        <Select
                            placeholder="Please Select Requester"
                            isClearable
                            value={CurrentJobDetails.hasOwnProperty("RequesterName") ? CurrentJobDetails.RequesterName.value : null}
                            onChange={(value) => {
                                this.props.changeRequesterName({
                                    Requester: value
                                })
                            }}
                            options={CurrentJobDetails.SiteDetails.value ?
                                CommonUtility.sortByColumn(RequesterOptions.filter(requester =>
                                    requester.designation === COMMON_CONSTANTS.REQUESTOR_ROLE &&
                                    requester.siteIds?.split(",").indexOf(CurrentJobDetails.SiteDetails.value.value?.toString()) > -1), "label", "asc") : []
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md2 ms-lg1">
                        <TooltipHost
                            content={ShowAddNewSiteSection ? `Close Add Requester Panel` : `Add New Requester`}
                            id={tooltipId}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            {ShowAddNewUserSection ?
                                <IconButton
                                    iconProps={IconConstants.cancelIcon}
                                    onClick={() => {
                                        this.props.showHideAddNewUserSectionJob({
                                            ShowAddNewUserSection: false
                                        })
                                    }}
                                />
                                :
                                <IconButton
                                    iconProps={IconConstants.addIcon}
                                    disabled={!CurrentJobDetails.SiteDetails || !CurrentJobDetails.SiteDetails.value}
                                    onClick={() => {
                                        this.props.showHideAddNewUserSectionJob({
                                            ShowAddNewUserSection: true
                                        })
                                    }}
                                />
                            }

                        </TooltipHost>
                    </div>
                </div>
                {/* Add Requester Start */}
                {ShowAddNewUserSection &&
                    <React.Fragment>
                        {LoadingNewUserSection ?
                            <Spinner label="Saving New Requester..." />
                            :
                            <div className="ms-Grid add-extra-pane">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Separator theme={seperatorTheme}>Add New Requester</Separator>

                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <small className="mandatory-span">
                                            {NewUserSaveStatus.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <TextField
                                            label="Name:"
                                            required
                                            value={NewUser.name}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewUserField({
                                                    field: "name",
                                                    name: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <TextField
                                            label="Email:"
                                            required
                                            value={NewUser.email}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewUserField({
                                                    field: "email",
                                                    email: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <TextField
                                            label="Mobile:"
                                            value={NewUser.phone}
                                            onChange={(ev, newVal) => {
                                                this.props.changeNewUserField({
                                                    field: "phone",
                                                    phone: newVal
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <br />

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <PrimaryButton
                                            text="Save"
                                            iconProps={IconConstants.saveicon}
                                            disabled={
                                                !NewUser.name ||
                                                !NewUser.email
                                            }
                                            onClick={() => {
                                                this.props.saveNewUserJobAsync({
                                                    apiObject: this._apiObject,
                                                    NewUser: NewUser,
                                                    ClientId: CurrentJobDetails.ClientName ? CurrentJobDetails.ClientName.value : -1,
                                                    SiteId: CurrentJobDetails.SiteDetails ? CurrentJobDetails.SiteDetails.value : -1
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                }

                {/* Add Requester End */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {CurrentJobDetails.hasOwnProperty("RequesterDetails") && CurrentJobDetails.RequesterDetails.value &&
                            <React.Fragment>
                                <Label>Requester Details</Label>
                                <code>
                                    <i className="code-details">
                                        {CurrentJobDetails.RequesterDetails.value}
                                    </i>
                                </code>
                            </React.Fragment>
                        }
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg11">
                        <Label>Client Users</Label>
                        <Select
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            value={CurrentJobDetails.hasOwnProperty("ClientUsers") ? CurrentJobDetails.ClientUsers.value : null}
                            onChange={(newval) => {
                                this.props.changeClientUsers({
                                    ClientUsers: newval
                                });
                            }}
                            options={CurrentJobDetails.SiteDetails.value ?
                                CommonUtility.sortByColumn(RequesterOptions.filter(requester => requester.siteIds?.split(",").indexOf(CurrentJobDetails.SiteDetails.value.value?.toString()) > -1), "label", "asc") : []
                            }
                        />
                    </div>
                </div>
            </div >

        )
    }
}
