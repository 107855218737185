import { IAssetTypeData } from "../Interfaces/AssetTypeInterface";
import { ISelect } from "./../../../../CommonInterfaces/CommoInterfaces";

export const AssetTypeInitialState = {
    AssetTypes: [] as IAssetTypeData[],
    LoadingAssetTypeDashboard: false,
    LoadingAssetTypeAddEditPanel: false,
    HasMoreAssetTypes: true,
    SelectedAssetType: null,
    CurrentAssetType: {
        acronym_code: "",
        attribute_form_id: -1,
        description: "",
        id: -1,
        name: "",
        reference: "",
        attribute_form: null as ISelect,
        category: null,
    } as IAssetTypeData,
    TabIndex: 0,
    ShowActiveAssetType: true,
    hideConfirm: true,
    offset: 0,
    limit: 50,
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    ToBeUpdatedStatus: -1,
    PanelHeaderText: "",
    ShowAssetTypeAddEditPanel: false,
    FormAttributeOptions: [] as ISelect[],
    name_filter_value: ""
}