import { Dispatch } from "redux";
import { IRolePayload } from "../Interfaces/IRole";
import { RoleUtility } from "../Utility/RoleUtility";
import * as CONSTANTS from "./../Constants/RoleConstants";
import { RoleInitialState } from "./../Constants/RoleIntialState";

export class RoleAction {

  cleanDashboard = (payload: IRolePayload) => {
    return { type: CONSTANTS.CLEAN_ROLE_DASHBOARD, payload };
  };

  loadRoleDashboard = (payload: IRolePayload) => {
    return (dispatch: Dispatch) => {
      dispatch(this.cleanDashboard(payload));
      payload.isOngoingLoading = true;
      dispatch(this.loadRoleDashboardAsync(payload));

      payload.apiObject
        .getRoleDetails(payload.objectType)
        .then((response) => {
          payload.isOngoingLoading = false;
          payload.Roles = response[0].data;
          dispatch(this.loadRoleDashboardAsync(payload));
        })
        .catch((error) => {
          console.error("Error: ", error);
          payload.hideAlert = false;
          payload.alertHeader = "Ooops!!";
          payload.alertSubText = "Error while loading Role";
          dispatch(this.openCloseAlertModal(payload));

          setTimeout(() => {
            payload.hideAlert = true;
            payload.alertHeader = "";
            payload.alertSubText = "";
            dispatch(this.openCloseAlertModal(payload));
          }, 2000);
        });
    };
  };

  loadRoleDashboardAsync = (payload) => {
    return { type: CONSTANTS.LOAD_ROLE_DASHBOARD, payload };
  };

  loadAttributeDropDowns = (payload) => {
    return { type: CONSTANTS.LOAD_ROLE_ATTRIBUTES, payload };
  };

  changeRoleTab = (payload) => {
    return { type: CONSTANTS.CHANGE_ROLE_TAB, payload };
  };

  closeRolePanel = (payload) => {
    return { type: CONSTANTS.CLOSE_ROLE_DETAILS_PANEL, payload };
  };

  changeSearchedClient = (payload) => {
    return { type: CONSTANTS.CHANGE_SEARCHED_CLIENT, payload };
  };

  changeRoleDescription = (payload) => {
    switch (payload.field) {
      case "name":
        return { type: CONSTANTS.CHANGE_ROLE_NAME, payload };

      case "acronymCode":
        return { type: CONSTANTS.CHANGE_ROLE_ACRONYM_CODE, payload };

      case "license":
        return { type: CONSTANTS.CHANGE_ROLE_LICENSE_COUNT, payload };

      case "type":
        return { type: CONSTANTS.CHANGE_ROLE_TYPE, payload };

      case "isAllUserActive":
        return { type: CONSTANTS.CHANGE_ROLE_CLIENT_ACTIVATION, payload };

      default:
        break;
    }
  };

  changeRoleAttributes = (payload) => {
    switch (payload.field) {
      case "form":
        return { type: CONSTANTS.CHANGE_ROLE_FORM_ATTRIBUTE, payload };

      default:
        break;
    }
  };

  // Save Role
  saveRoleDetails = (payload) => {
    return { type: CONSTANTS.SAVE_ROLE_DETAILS, payload };
  };

  saveRoleDetailsAsync = (payload) => {
    return (dispatch) => {
      payload.isOnGoingSave = true;
      dispatch(this.saveRoleDetails(payload));

      let utility = new RoleUtility();
      let rolePayload = utility.generateRoleJSONPayLoad(payload.CurrentRole, payload.clients);

      payload.apiObject
        .saveRoleConfigData(rolePayload, payload.isNewConfig)
        .then((response) => {
          payload.isOnGoingSave = false;
          dispatch(this.saveRoleDetails(payload));
          payload.hideAlert = false;
          payload.alertHeader = "Success !!";
          payload.alertSubText = "Data saved successfully";
          dispatch(this.openCloseAlertModal(payload));

          setTimeout(() => {
            payload.hideAlert = true;
            payload.alertHeader = "";
            payload.alertSubText = "";
            payload.objectType = "ROLE";
            payload.offset = 0;
            payload.limit = RoleInitialState.limit;
            dispatch(this.openCloseAlertModal(payload));
            dispatch(this.cleanDashboard(payload));
            dispatch(this.closeRolePanel(payload));
            dispatch(this.loadRoleDashboard(payload));
          }, 2000);
        })
        .catch((error) => {
          console.error("Error: ", error);

          payload.hideAlert = false;
          payload.alertHeader = "Error !!";
          payload.alertSubText = "Error while saving Role Details";
          dispatch(this.openCloseAlertModal(payload));

          setTimeout(() => {
            payload.hideAlert = true;
            payload.alertHeader = "";
            payload.alertSubText = "";
            dispatch(this.openCloseAlertModal(payload));
          }, 2500);
        });
    };
  };

  openCloseAlertModal = (payload) => {
    return { type: CONSTANTS.OPEN_CLOSE_ROLE_ALERT_MODAL, payload };
  };

  openCloseRoleConfirmModal = (payload) => {
    return { type: CONSTANTS.OPEN_CLOSE_ROLE_CONFIRM_MODAL, payload };
  };

  deleteRole = (payload) => {
    return { type: CONSTANTS.DELETE_ROLE, payload };
  };

  deleteRoleAsync = (payload) => {
    return (dispatch) => {
      payload.isOngoingDelete = true;
      dispatch(this.deleteRole(payload));

      payload.apiObject
        .deleteRole(payload.CurrentRole)
        .then((response: any) => {
          payload.isOngoingDelete = false;
          payload.CurrentRole = response.data;
          dispatch(this.deleteRole(payload));
          payload.hideAlert = false;
          payload.alertHeader = "Success !!";
          payload.alertSubText =
            "Role has Archived successfully. Please refresh to check data";
          dispatch(this.openCloseAlertModal(payload));

          setTimeout(() => {
            payload.hideAlert = true;
            payload.alertHeader = "";
            payload.alertSubText = "";
            dispatch(this.openCloseAlertModal(payload));
          }, 2000);
        })
        .catch((error) => {
          console.error("Error: ", error);

          payload.hideAlert = false;
          payload.alertHeader = "Error !!";
          payload.alertSubText = "Error while deleting Role";
          dispatch(this.openCloseAlertModal(payload));

          setTimeout(() => {
            payload.hideAlert = true;
            payload.alertHeader = "";
            payload.alertSubText = "";
            payload.isOngoingDelete = false;
            dispatch(this.deleteRole(payload));
            dispatch(this.openCloseAlertModal(payload));
          }, 2500);
        });
    };
  };

  //DASHBOARD FILTER start
  changeRoleFilterValue = (payload) => {
    switch (payload.filterType) {
      case "name":
        return { type: CONSTANTS.CHANGE_ROLE_NAME_FILTER_VALUE, payload };
      case "customer":
        return { type: CONSTANTS.CHANGE_CUSTOMER_NAME_FILTER_VALUE, payload };
    }
  };

  findFilteredRolesAsync = (payload) => {
    return (dispatch) => {
      dispatch(this.changeRoleFilterValue(payload));
      payload.apiObject
        .getRoleFilterDetails(payload.objectType, payload.config, payload.value)
        .then((response) => {
          payload.Roles = response[0].data;
          //payload.TotalRoleCount = response[0].data;
          dispatch(this.loadRoleDashboardAsync(payload));
        })
        .catch((error) => {
          console.error("Error: ", error);
          payload.hideAlert = false;
          payload.alertHeader = "Oops!!";
          payload.alertSubText = "Error while loading Roles";
          dispatch(this.openCloseAlertModal(payload));

          setTimeout(() => {
            payload.hideAlert = true;
            payload.alertHeader = "";
            payload.alertSubText = "";
            dispatch(this.openCloseAlertModal(payload));
          }, 2000);
        });
    };
  };

  //DASHBOARD FILTER end

  sortDashboard = (payload) => {
    return { type: CONSTANTS.SORT_ROLE_DASHBOARD, payload };
  };

  // loadClientsAsync = (payload: any) => {
  //   return { type: CONSTANTS.LOAD_TENANT_CLIENTS, payload };
  // };

  // loadClients = (payload: any): any => {
  //   payload.isOngoingLoading = true;

  //   return (dispatch: any) => {
  //     payload.isOngoingLoading = true;
  //     dispatch(this.loadClientsAsync(payload));
  //     payload.assetApiObject.loadClientsAPI().then((response: any) => {
  //       payload.isOngoingLoading = false;
  //       payload.clients = response.data;
  //       dispatch(this.loadClientsAsync(payload));
  //     }).catch((error) => {
  //       console.error("Error: ", error);
  //       payload.hideAlert = false;
  //       payload.alertHeader = "Oops!!";
  //       payload.alertSubText = "Error while loading Clients";
  //       dispatch(this.openCloseAlertModal(payload));

  //       setTimeout(() => {
  //         payload.hideAlert = true;
  //         payload.alertHeader = "";
  //         payload.alertSubText = "";
  //         dispatch(this.openCloseAlertModal(payload));
  //       }, 2000);
  //     });
  //   }
  // }

}
