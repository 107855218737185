import * as CONSTANTS from '../Constants/AssetTypeConstants';
import { AssetTypeUtility } from '../Utility/AssetTypeUtility';
import { AssetTypeInitialState } from './../Constants/AssetTypeInitialState';
import { IAssetTypeData } from '../Interfaces/AssetTypeInterface';
import { ISelect } from './../../../../CommonInterfaces/CommoInterfaces';
// import { AssetTypeUtility } from '../Utility/AssetTypeUtility.ts';
// import { JobUtility } from '../Utility/JobUtility';

export function AssetTypeReducer(state = AssetTypeInitialState, action) {

    let tempState = { ...state };
    let payload = action.payload;
    let utility: AssetTypeUtility = new AssetTypeUtility();
    let CurrentAssetType: IAssetTypeData = { ...tempState.CurrentAssetType };

    switch (action.type) {

        case CONSTANTS.CLEAN_ASSETTYPE_DASHBOARD:
            tempState.offset = 0;
            tempState.LoadingAssetTypeDashboard = true;
            tempState.AssetTypes = [];
            break;

        case CONSTANTS.LOAD_ASSETTYPE_DASHBOARD:
            tempState.LoadingAssetTypeDashboard = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                tempState.offset += tempState.limit;
                let newAssetTypes: IAssetTypeData[] = utility.loadAssetTypeTableStructure(payload.AssetTypes);
                tempState.AssetTypes = tempState.AssetTypes.concat(newAssetTypes);
                tempState.HasMoreAssetTypes = payload.AssetTypes.length >= tempState.limit;
            }
            break;

        case CONSTANTS.CHANGE_ASSETTYPE_NAME_FILTER_VALUE:
            tempState.name_filter_value = payload.name_filter_value;
            break;

        case CONSTANTS.SORT_ASSETTYPE_DASHBOARD:
            tempState.AssetTypes = payload.sortedAssetTypes;
            break;

        case CONSTANTS.LOAD_ASSETTYPE_ATTRIBUTES:
            tempState.LoadingAssetTypeAddEditPanel = payload.isOngoingLoading;
            tempState.PanelHeaderText = payload.PanelHeaderText;
            tempState.ShowAssetTypeAddEditPanel = true;
            if (!payload.isOngoingLoading) {
                tempState.FormAttributeOptions = utility.loadFormAttributesOptions(payload.FormAttributes);
                CurrentAssetType = payload.SelectedAssetType ? payload.SelectedAssetType : CurrentAssetType;
                CurrentAssetType.category = payload.SelectedAssetType?.category;
                CurrentAssetType.attribute_form = (payload.SelectedAssetType && payload.SelectedAssetType.attribute_form_id) ? {
                    value: tempState.FormAttributeOptions.find((d: ISelect) => d.value === payload.SelectedAssetType.attribute_form_id).value,
                    label: tempState.FormAttributeOptions.find((d: ISelect) => d.value === payload.SelectedAssetType.attribute_form_id).label
                } : null;
                tempState.CurrentAssetType = CurrentAssetType;
            }
            break;

        case CONSTANTS.CHANGE_ASSETTYPE_TAB:
            tempState.TabIndex = payload.TabIndex;
            break;

        case CONSTANTS.CLOSE_ASSETTYPE_DETAILS_PANEL:
            tempState.ShowAssetTypeAddEditPanel = false;
            tempState.PanelHeaderText = "";
            tempState.TabIndex = 0;
            tempState.CurrentAssetType = utility.resetCurrentAssetType();
            break;

        // Description
        case CONSTANTS.CHANGE_ASSETTYPE_NAME:
            CurrentAssetType.name = payload.name;
            tempState.CurrentAssetType = CurrentAssetType;
            break;

        case CONSTANTS.CHANGE_ASSETTYPE_ACRONYM_CODE:
            CurrentAssetType.acronym_code = payload.acronym_code;
            tempState.CurrentAssetType = CurrentAssetType;
            break;

        case CONSTANTS.CHANGE_ASSETTYPE_DESCRIPTION:
            CurrentAssetType.description = payload.description;
            tempState.CurrentAssetType = CurrentAssetType;
            break;

        case CONSTANTS.CHANGE_ASSETTYPE_CATEGORY:
            CurrentAssetType.category = payload.description;
            tempState.CurrentAssetType = CurrentAssetType;
            break;

        case CONSTANTS.CHANGE_ASSETTYPE_DISPLAY_NAME:
            CurrentAssetType.displayName = payload.displayName;
            tempState.CurrentAssetType = CurrentAssetType;
            break;

        // Attributes
        case CONSTANTS.CHANGE_ASSETTYPE_FORM_ATTRIBUTE:
            CurrentAssetType.attribute_form = payload.attribute_form;
            tempState.CurrentAssetType = CurrentAssetType;
            break;

        // Save Asset Type
        case CONSTANTS.SAVE_ASSETTYPE_DETAILS:
            tempState.LoadingAssetTypeAddEditPanel = payload.isOnGoingSave;
            break;

        case CONSTANTS.OPEN_CLOSE_ASSET_TYPES_ALERT_MODAL:
            tempState.alertJSON = {
                hideAlert: payload.hideAlert,
                alertHeader: payload.alertHeader,
                alertSubText: payload.alertSubText
            };
            tempState.LoadingAssetTypeAddEditPanel = false;
            break;

        case CONSTANTS.OPEN_CLOSE_ASSETTYPE_CONFIRM_MODAL:
            tempState.hideConfirm = payload.hideConfirm;
            if (payload.hideConfirm) {
                tempState.CurrentAssetType = utility.resetCurrentAssetType();
            }
            else {
                tempState.CurrentAssetType = payload.CurrentAssetType;
            }
            break;

        case CONSTANTS.DELETE_ASSET_TYPE:
            tempState.LoadingAssetTypeDashboard = payload.isOngoingDelete;
            tempState.hideConfirm = true;
            break;


        default:
            break;
    }

    return tempState;
}