import * as React from 'react';
import { Panel, PanelType, PrimaryButton } from 'office-ui-fabric-react';
import { TaskAPI } from '../Services/TaskAPI';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from "react-loading-overlay";
import { TaskTypeAPI } from '../../Settings/TaskTypes/Services/TaskTypeAPI';
import Select from 'react-select';
import { ISelect } from '../../../CommonInterfaces/CommoInterfaces';
import { IconConstants } from '../../../Constants/StylesConstants';
import AlertRedux from '../../Alert/AlertRedux';


export default class CreateChildTaskPanel extends React.PureComponent<any, any> {

    private _taskApi: TaskAPI;
    private _taskTypeApi: TaskTypeAPI;

    public constructor(props: any) {
        super(props);
        this._taskApi = new TaskAPI(window.API_URL);
        this._taskTypeApi = new TaskTypeAPI(window.API_URL, window.loggedInUserDetails.jsessionid);
    }

    public componentDidMount(): void {
        this.loadAllTaskTypes();
    }

    public loadAllTaskTypes = (): void => {

        this.props.loadTaskTypesDropDowns({
            apiObject: this._taskTypeApi,
            objectType: "TaskType"
        });

    }

    public render(): JSX.Element {

        let {
            alertJSON,
            selectedTaskType,
            isOpenCreateChildtaskPanel,
            isLoadingCreateChildtaskPanel,
            TaskTypesOptions,
            SelectedTask
        } = this.props.Task;

        return (
            <LoadingOverlay
                active={isLoadingCreateChildtaskPanel}
                spinner={<FadeLoader />}
                text='Please Wait...'>
                <Panel
                    isOpen={isOpenCreateChildtaskPanel}
                    onDismiss={() => {
                        this.props.openCloseCreateChildTaskPanel({
                            isOpenCreateChildtaskPanel: false,
                        })
                    }}
                    headerText={`Add New Child Task`}
                    type={PanelType.smallFixedFar}
                    styles={{
                        content: {
                            height: "40em"
                        },
                        scrollableContent: {
                            height: "92vh"
                        }
                    }}
                >
                    <Select
                        value={selectedTaskType}
                        placeholder={`----Select Task Type----`}
                        onChange={(newVal: ISelect) => {
                            this.props.selectTaskType({
                                selectedTaskType: newVal,
                            })
                        }}
                        options={TaskTypesOptions.filter((item: ISelect) => item.label.toLowerCase().indexOf("llg-") > -1)}
                    />
                    <br />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <PrimaryButton
                                disabled={!selectedTaskType}
                                text="CREATE"
                                menuIconProps={IconConstants.addIcon}
                                onClick={() => {
                                    this.props.saveNewChildLLGTask({
                                        parentLLGTaskId: SelectedTask.id, 
                                        taskTypeId: selectedTaskType.value, 
                                        taskTypeName: selectedTaskType.label,
                                        apiObject: this._taskApi
                                    })
                                }}
                            />
                        </div>
                    </div>
                </Panel>
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </LoadingOverlay>
        )
    }
}

