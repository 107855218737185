import React, { Component } from 'react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import { JobLitesAPI } from '../Services/JobLitesAPI';
import { JobLitesUtility } from '../Utility/JobLitesUtility';
import { IconConstants } from '../../../Constants/StylesConstants';

export default class JobLiteOperation extends Component {

    _apiObject = null;
    constructor(props) {
        super(props);
        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails)
    }

    saveJob = (cancelFlag) => {

        let { CurrentJobDetails, AllTaskTypeOptions, AssetTypeOptions } = this.props.JobLites;

        this.props.addJobAsync({
            apiObject: this._apiObject,
            CurrentJobDetails: CurrentJobDetails,
            cancelFlag: cancelFlag,
            AllTaskTypeOptions,
            AssetTypeOptions
        });

    }

    render() {

        let {
            CurrentJobDetails,
            SelectedJob
        } = this.props.JobLites;

        let isInvalidJobLiteForm = JobLitesUtility.isInvalidJobLiteForm(CurrentJobDetails);

        return (

            <div className="ms-Grid" dir="ltr">

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                        <PrimaryButton
                            text={`Save`}
                            menuIconProps={IconConstants.saveicon}
                            disabled={isInvalidJobLiteForm}
                            onClick={() => {
                                this.saveJob();
                            }}
                        />

                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                        {!SelectedJob &&
                            <DefaultButton
                                menuIconProps={IconConstants.resetIcon}
                                text={`Clear`}
                                onClick={() => {
                                    this.props.resetJobValue();
                                }}
                            />
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">

                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">

                    </div>
                </div>

            </div >

        )
    }
}
