import { Breadcrumb } from 'office-ui-fabric-react';
import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { USER_BREADCRUMBS } from '../Constants/UserConstants';
import { UserActions } from './../Actions/UserActions';
import UserDashboard from './UserDashboard.jsx';

const mapStateToProps = (state) => {
    return {
        User: state.UserReducer
    }
}

class UserComponent extends PureComponent {

    render() {
        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={USER_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                    </div>
                </div>
                <UserDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let userAction = new UserActions();

    return bindActionCreators({ ...userAction }, dispatch);
}
const Users = connect(mapStateToProps, mapDispatchToProps)(UserComponent);
export default Users;