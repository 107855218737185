import { AssessmentsInitialState } from "../Constants/AssessmentsInitialState";
import * as CONSTANTS from "../Constants/AssessmentsConstants";
import * as TASK_CONSTANTS from "../../Tasks/Constants/TaskConstants";
import { IAssessmentsInitialState } from "../Interfaces/IAssessmentsInitialState";
import { IAssessmentsActionKeys } from "../Interfaces/IAssessmentsActions";
import { IAssessmentsPayload } from "../Interfaces/IAssessmentsPayload";
import { AssessmentsUtility } from "../Utility/AssessmentsUtility";
import { AssetUtility } from "../../Assets/Utility/AssetUtility";

export function AssessmentsReducer(state: IAssessmentsInitialState = AssessmentsInitialState, action: IAssessmentsActionKeys): IAssessmentsInitialState {

    let tempState: IAssessmentsInitialState = { ...state };
    let payload: IAssessmentsPayload = action.payload;
    let utility: AssessmentsUtility = new AssessmentsUtility();
    let assetUtility: AssetUtility = new AssetUtility();
    let newAssessmentGridDataState = { ...tempState.assessmentGridDataState };

    switch (action.type) {

        case CONSTANTS.SHOW_LOADER_DASHBOARD:
            tempState.isLoadingAssessmentsDashboard = payload.isLoading;
            break;

        case CONSTANTS.CLEAN_ASSESSMENT_DASHBOARD:
            tempState.isLoadingAssessmentClients = payload.isLoading;
            tempState.selectedAssessmentClient = null;
            tempState.selectedAssessmentSite = null;
            tempState.assessmentGridDataState = {
                sort: [{ field: "name", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                skip: 0
            };
            tempState.assessments = [];
            tempState.proccessedAssessments = [];
            tempState.offset = 0;
            tempState.issueDateRange = null;
            tempState.isOpenAssessmentHistoryPanel = false;
            tempState.expiryDateRange = null;
            break;
        case CONSTANTS.SHOW_HIDE_ASSESMENT_DASHBOARD_COLUMNS:
                let tempColumns = [...tempState.showColumns];
                let indexOfSelectedColumn: number = tempColumns.indexOf(payload.selectedColumn.key.toString());
                if (indexOfSelectedColumn > -1) {
                    tempColumns.splice(indexOfSelectedColumn, 1);
                }
                else {
                    tempColumns.push(payload.selectedColumn.key.toString());
                }
                tempState.showColumns = tempColumns;
                break;

        case CONSTANTS.CLEAN_ASSESSMENT_FILTER:
            tempState.assessments = [];
            tempState.proccessedAssessments = [];
            tempState.assessments = [];
            tempState.proccessedAssessments = [];
            tempState.offset = 0;
            tempState.issueDateRange = null;
            tempState.expiryDateRange = null;
            tempState.isOpenAssessmentHistoryPanel = false;
            tempState.assessmentGridDataState = {
                sort: [{ field: "name", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                skip: 0
            };
            break;


        case CONSTANTS.LOAD_CLIENTS_ASSESSMENT:
            tempState.isLoadingAssessmentClients = payload.isLoading;
            tempState.assessments = [];
            tempState.isOpenAssessmentHistoryPanel = false;
            tempState.proccessedAssessments = [];
            tempState.assessmentSiteFilters = [];
            if (!payload.isLoading) {
                tempState.assessmentClientFilters = assetUtility.loadSelectDropDownValue([...payload.locationFilters]);
            }
            break;

        case CONSTANTS.CHANGE_ASSESSMENT_CLIENT_FILTER:
            tempState.isLoadingAssessmentSites = payload.isLoading;
            tempState.isLoadingAssessmentsDashboard = payload.isLoading;
            tempState.assessments = [];
            tempState.proccessedAssessments = [];
            tempState.assessmentSiteFilters = [];
            tempState.selectedAssessmentClient = payload.selectedLocation;
            tempState.selectedAssessmentSite = null;
            tempState.offset = 0;
            tempState.issueDateRange = null;
            tempState.expiryDateRange = null;
            tempState.assessmentGridDataState = {
                sort: [{ field: "name", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                skip: 0
            };
            break;

        case CONSTANTS.CHANGE_ASSESSMENT_SITE_FILTER:
            tempState.isLoadingAssessmentsDashboard = payload.isLoading;
            tempState.assessments = [];
            tempState.isOpenAssessmentHistoryPanel = false;
            tempState.proccessedAssessments = [];
            tempState.selectedAssessmentSite = payload.selectedLocations;
            tempState.offset = 0;
            tempState.offset = 0;
            tempState.issueDateRange = null;
            tempState.expiryDateRange = null;
            tempState.assessmentGridDataState = {
                sort: [{ field: "name", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                skip: 0
            };
            break;

        case CONSTANTS.LOAD_SITES_ASSESSMENT:
            tempState.isLoadingAssessmentSites = payload.isLoading;
            tempState.assessmentSiteFilters = assetUtility.loadSelectDropDownValue([...payload.locationFilters]);
            break;

        case CONSTANTS.LOAD_ASSESSMENT_DASHBOARD:
            tempState.isLoadingAssessmentsDashboard = payload.isLoading;
            tempState.isOpenAssessmentHistoryPanel = false;
            if (!payload.isLoading) {
                tempState.assessments = [...payload.assessments];
                // tempState.offset += payload.offset;
                // tempState.assessmentsCount = payload.assessmentsCount;
                tempState.proccessedAssessments = utility.processAssessmentsForKendoGrid([...tempState.assessments], {
                    sort: [{ field: "name", dir: "asc" }],
                    filter: { filters: [], logic: 'and' },
                    skip: 0
                });
            }
            break;


        // case CONSTANTS.LOAD_ASSESSMENT_DASHBOARD:
        //     tempState.assessments = (payload.offset > 0) ? ([...tempState.assessments, ...payload.assessments]) : [...payload.assessments];
        //     tempState.hasMoreAssessments = payload.assessments.length === AssessmentsInitialState.limit;
        //     tempState.proccessedAssessments = utility.processAssessmentsForKendoGrid([...tempState.assessments], newAssessmentGridDataState);
        //     tempState.isLoadingAssessmentsDashboard = payload.isLoading;
        //     tempState.offset = payload.offset + AssessmentsInitialState.limit;
        //     break;

        case CONSTANTS.CHANGE_FILTER_DATE_RANGE:
            switch (payload.dateField) {
                case "actualInspectionDate":
                    tempState.issueDateRange = payload.dateRangeValue;
                    break;
                case "dueDate":
                    tempState.expiryDateRange = payload.dateRangeValue;
                    break;

                default:
                    break;
            }
            break;

        case CONSTANTS.LOAD_ASSESSMENT_CERTIFICATE:
            break;

        case CONSTANTS.SHOW_HIDE_ASSESSMENT_CERTIFICATE_PANEL:
            tempState.isHideCertificatePanel = payload.isHideCertificatePanel;
            tempState.selectedTaskId = payload.selectedTaskId;
            break;

        case CONSTANTS.SWITCH_TASK_AND_SERVICE_ASSESSMENT:
            tempState.assessmentType = payload.assessmentType;
            break;

        case CONSTANTS.OPEN_CLOSE_ASSESSMENT_HISTORY_PANEL:
            tempState.isOpenAssessmentHistoryPanel = payload.isOpenAssessmentHistoryPanel;
            tempState.selectedAssessment = payload.selectedAssessment;
            break;

        case CONSTANTS.LOAD_TASK_DETAILS:
            tempState.isLoadingCertificatePanel = payload.isLoading;
            if (!payload.isLoading) {

                if (payload.task.certificateFile) {
                    tempState.PDFURL = `${window.API_URL}filedownload;jsessionid=${window.loggedInUserDetails.jsessionid}?observationId=${payload.task.id}&id=${payload.task.certificateFile.blobKey}`;
                }
                else {
                    tempState.PDFURL = `${window.API_URL}${TASK_CONSTANTS.GET_TASK_PDF};jsessionid=${window.loggedInUserDetails.jsessionid}?id=${payload.task.id}`;
                }

            }
            else {
                tempState.PDFURL = "";
            }
            break;

        case CONSTANTS.HANDLE_ASSESSMENT_GRID_GROUP_SORT_CHANGE:
            newAssessmentGridDataState.sort = payload.event.dataState.sort;
            let indexOfId = -1;
            indexOfId = payload.event.dataState.group?.findIndex((d: any) => d.field === "id");
            if (indexOfId > -1) {
                payload.event.dataState.group.splice(indexOfId, 1);
            }
            newAssessmentGridDataState.group = payload.event.dataState.group;
            tempState.assessmentGridDataState = newAssessmentGridDataState;
            tempState.proccessedAssessments = utility.processAssessmentsForKendoGrid(tempState.assessments,
                {
                    sort: newAssessmentGridDataState.sort,
                    filter: { filters: [], logic: 'and' },
                    skip: 0
                });
            break;

        case CONSTANTS.HANDLE_ASSESSMENT_GRID_EXPAND_CHANGE:
            payload.event.dataItem[payload.event.target.props.expandField] = payload.event.value;
            tempState.assessmentGridDataState = newAssessmentGridDataState;
            break;

        case CONSTANTS.HANDLE_ASSESSMENT_GRID_FILTER_CHANGE:
            let assessmentGridDataFilter = payload.event.filter ? payload.event.filter : { filters: [], logic: 'and' };
            newAssessmentGridDataState.filter = assessmentGridDataFilter;
            tempState.assessmentGridDataState = newAssessmentGridDataState;
            tempState.proccessedAssessments = [];
            tempState.assessments = [];
            tempState.isLoadingAssessmentsDashboard = true;
            break;

        case CONSTANTS.OPEN_CLOSE_ASSESSMENT_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON ?
                payload.alertJSON :
                {
                    alertHeader: "",
                    alertSubText: "",
                    hideAlert: true,
                };
            tempState.isLoadingAssessmentsDashboard = false;
            break;

        default:
            break;

    }

    return tempState;
}