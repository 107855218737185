import { DatePicker, Label, PrimaryButton, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { IFormBuilderKey } from '../../../CommonInterfaces/CommoInterfaces';
import { IconConstants } from '../../../Constants/StylesConstants';
import { TaskUtility } from '../../Tasks/Utility/TaskUtility';
import { IAssetAPI } from '../AssetInterface/IAsset';
import { AssetAPI } from '../Services/AssetAPI';
import * as COMMON_CONSTANTS from './../../../Constants/CommonConstants';

export default class AssetAttributes extends React.Component<any, any> {

    private _assetAPI: IAssetAPI;
    private _taskUtility: TaskUtility;

    constructor(props: any) {
        super(props);
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);
        this._taskUtility = new TaskUtility();
    }

    componentDidMount() {

        let {
            selectedAssetUserData
        } = this.props.Asset;

        let formId = selectedAssetUserData?.records[0]?.qsUserDataFormData?.attribute_form_id[0]?.formId;

        if (formId) {

            this.props.loadAssetAttributes({
                apiObject: this._assetAPI,
                formId: parseInt(formId)
            })
        }
    }

    public saveAssetAttributes = (): void => {
        let {
            selectedAssetFormAttributes,
            selectedAssetUserData,
            selectedAssetId,
            isUnderTaskDetailsView
        } = this.props.Asset;

        let attributeValues = selectedAssetUserData?.records[0]?.qsUserDataFormData?.attribute_form_id[0]?.attributes;

        let formId = selectedAssetUserData?.records[0]?.qsUserDataFormData?.attribute_form_id[0]?.formId;

        selectedAssetFormAttributes.forEach((attribute: IFormBuilderKey) => {
            const value: any = (document.getElementById(attribute.label) as HTMLInputElement)?.value;
            if (value !== undefined) {
                attributeValues[attribute.name] = value;
            }
        })

        this.props.saveAssetAttributes({
            apiObject: this._assetAPI,
            formId: parseInt(formId),
            selectedAssetUserData,
            selectedAssetId,
            isUnderTaskDetailsView
        })

    }

    public onTextFieldChange = (value: string, fieldName: string) => {
        let {
            selectedAssetUserData,
        } = this.props.Asset;

        let attributeValues = selectedAssetUserData?.records[0]?.qsUserDataFormData?.attribute_form_id[0]?.attributes;

        attributeValues[fieldName] = value;

        this.props.changeAssetAttributeValues({
            selectedAssetUserData
        })
        
    }

    public render(): JSX.Element {

        let {
            isLoadingAssetAttributesTab,
            selectedAssetFormAttributes,
            selectedAssetUserData,
            responseStatusDetails
        } = this.props.Asset;

        let attributeValues = selectedAssetUserData?.records[0]?.qsUserDataFormData?.attribute_form_id[0]?.attributes;

        return (
            <div className="ms-Grid" dir="ltr">
                {isLoadingAssetAttributesTab ?
                    <div className="login-spinner-div">
                        <Spinner
                            label="Please wait..."
                            size={SpinnerSize.large} />
                    </div>
                    :
                    <>
                        {selectedAssetFormAttributes?.map((formAttribute: IFormBuilderKey, index: number) => {
                            if (formAttribute.type === "text" || formAttribute.type === "number") {
                                return (
                                    <div className="ms-Grid-row" key={index}>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <TextField
                                                label={formAttribute.label}
                                                id={formAttribute.label}
                                                required={formAttribute.required}
                                                type={formAttribute.type}
                                                value={attributeValues[formAttribute.name]}
                                                onChange={(ev: any, value: string) => {
                                                    this.onTextFieldChange(value, formAttribute.name);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            else if (formAttribute.type === 'date') {
                                return (
                                    <div className="ms-Grid-row" key={index}>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <DatePicker
                                                label={formAttribute.label}
                                                id={formAttribute.label}
                                                allowTextInput={false}
                                                // value={attributeValues[formAttribute.name] ? new Date(parseInt(attributeValues[formAttribute.name])) : new Date()}
                                                firstDayOfWeek={COMMON_CONSTANTS.firstDayOfWeek}
                                                strings={COMMON_CONSTANTS.DayPickerStrings}
                                                placeholder="Select a date..."
                                                formatDate={(date) => this._taskUtility.onFormatDate(date)}
                                                isRequired={formAttribute.required}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        <br />
                        {selectedAssetFormAttributes?.length > 0 ?
                            <PrimaryButton
                                onClick={() => this.saveAssetAttributes()}
                                text="Save"
                                menuIconProps={IconConstants.saveicon}
                            />
                            :
                            <Label className={"mandatory-span"}>
                                No Attributes found
                            </Label>
                        }
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label className={responseStatusDetails?.errorFlag ? "mandatory-span" : "success-span"}>
                                    {responseStatusDetails?.statusMessage}
                                </Label>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}
