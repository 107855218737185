import * as CONSTANTS from '../Constants/TaskTypeConstants';
import { TaskTypeUtility } from '../Utility/TaskTypeUtility';
import { TaskTypeInitialState } from '../Constants/TaskTypeInitialState';
import { ITaskTypeData } from '../Interfaces/TaskTypeInterface';
import { ISelect } from '../../../../CommonInterfaces/CommoInterfaces';

export function TaskTypeReducer(state = TaskTypeInitialState, action) {

    let tempState = { ...state };
    let payload = action.payload;
    let utility: TaskTypeUtility = new TaskTypeUtility();
    let CurrentTaskType: ITaskTypeData = { ...tempState.CurrentTaskType };

    switch (action.type) {

        case CONSTANTS.CLEAN_TASKTYPE_DASHBOARD:
            tempState.offset = 0;
            tempState.LoadingTaskTypeDashboard = true;
            tempState.TaskTypes = [];
            tempState.dashboardDivisionOption = [];
            break;

        case CONSTANTS.LOAD_TASKTYPE_DASHBOARD:
            tempState.LoadingTaskTypeDashboard = payload.isOngoingLoading;
            if (!payload.isOngoingLoading) {
                tempState.dashboardDivisionOption = utility.loadDashboardDropDownOptions(payload.Divisions);
                tempState.selected_division_value = payload.Divisions.find((division: any) => division.name?.toUpperCase() === "LIFTING")?.id;
                tempState.offset += tempState.limit;
                let newTaskTypes: ITaskTypeData[] = utility.loadTaskTypeTableStructure(payload.TaskTypes);
                tempState.TaskTypes = tempState.TaskTypes.concat(newTaskTypes);
                tempState.HasMoreTaskTypes = payload.TaskTypes.length >= tempState.limit;
            }
            break;

        case CONSTANTS.LOAD_TASKTYPE_ATTRIBUTES:
            tempState.LoadingTaskTypeAddEditPanel = payload.isOngoingLoading;
            tempState.PanelHeaderText = payload.PanelHeaderText;
            tempState.ShowTaskTypeAddEditPanel = true;
            if (!payload.isOngoingLoading) {
                tempState.FormAttributeOptions = utility.loadFormAttributesOptions(payload.FormAttributes);
                tempState.AssetTypeOptions = utility.loadFormAttributesOptions(payload.AssetTypes);
                tempState.DivisionOptions = utility.loadFormAttributesOptions(payload.Divisions);
                CurrentTaskType = payload.SelectedTaskType ? payload.SelectedTaskType : CurrentTaskType;
                CurrentTaskType.attribute_form = (payload.SelectedTaskType && payload.SelectedTaskType.attribute_form_id) ? {
                    value: tempState.FormAttributeOptions.find((d: ISelect) => d.value === payload.SelectedTaskType.attribute_form_id).value,
                    label: tempState.FormAttributeOptions.find((d: ISelect) => d.value === payload.SelectedTaskType.attribute_form_id).label
                } : null;
                CurrentTaskType.division = (payload.SelectedTaskType && payload.SelectedTaskType.division_id) ? {
                    value: tempState.DivisionOptions.find((d: ISelect) => d.value === payload.SelectedTaskType.division_id).value,
                    label: tempState.DivisionOptions.find((d: ISelect) => d.value === payload.SelectedTaskType.division_id).label
                } : null;
                CurrentTaskType.asset_type = (payload.SelectedTaskType && payload.SelectedTaskType.asset_type_id) ? {
                    value: tempState.AssetTypeOptions.find((d: ISelect) => d.value === payload.SelectedTaskType.asset_type_id).value,
                    label: tempState.AssetTypeOptions.find((d: ISelect) => d.value === payload.SelectedTaskType.asset_type_id).label
                } : null;
                CurrentTaskType.sectionsJson = (payload.SelectedTaskType ? payload.SelectedTaskType.sectionsJson : "{}");
                tempState.CurrentTaskType = CurrentTaskType;
            }
            break;

        case CONSTANTS.CHANGE_TASKTYPE_TAB:
            tempState.TabIndex = payload.TabIndex;
            break;

        case CONSTANTS.SORT_TASKTYPE_DASHBOARD:
            tempState.TaskTypes = payload.sortedTasktypes;
            break;

        case CONSTANTS.CLOSE_TASKTYPE_DETAILS_PANEL:
            tempState.ShowTaskTypeAddEditPanel = false;
            tempState.PanelHeaderText = payload.PanelHeaderText;
            tempState.TabIndex = 0;
            tempState.CurrentTaskType = utility.resetCurrentTaskType();
            break;

        case CONSTANTS.CHANGE_TASKTYPE_NAME_FILTER_VALUE:
            tempState.name_filter_value = payload.name_filter_value;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_REFERENCE_STANDARD_FILTER_VALUE:
            tempState.reference_standard_filter_value = payload.reference_standard_filter_value;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_INSPECTION_METHOD_FILTER_VALUE:
            tempState.inspection_method_filter_value = payload.inspection_method_filter_value;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_VALIDITY_FILTER_VALUE:
            tempState.validity_filter_value = payload.validity_filter_value;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_DIVISION_FILTER_VALUE:
            tempState.selected_division_value = payload.selected_division_value;
            break;

        // Description
        case CONSTANTS.CHANGE_TASKTYPE_NAME:
            CurrentTaskType.name = payload.name;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_ACRONYM_CODE:
            CurrentTaskType.acronym_code = payload.acronym_code;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_DESCRIPTION:
            CurrentTaskType.description = payload.description;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_REFERENCE_STANDARD:
            CurrentTaskType.reference_standard = payload.reference_standard;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_INSPECTION_METHOD:
            CurrentTaskType.inspection_method = payload.inspection_method;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_DIVISION:
            CurrentTaskType.division = payload.division;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_VALIDITY:
            CurrentTaskType.validity = payload.validity;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        case CONSTANTS.CHANGE_TASKTYPE_IS_EIAC_APPROVED:
            CurrentTaskType.eiac = payload.eiac;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        // Attributes
        case CONSTANTS.CHANGE_TASKTYPE_FORM_ATTRIBUTE:
            CurrentTaskType.attribute_form = payload.attribute_form;
            tempState.CurrentTaskType = CurrentTaskType;
            break;
        case CONSTANTS.CHANGE_TASKTYPE_ASSET_TYPE:
            CurrentTaskType.asset_type = payload.asset_type;
            tempState.CurrentTaskType = CurrentTaskType;
            break;
        case CONSTANTS.CHANGE_TASKTYPE_CHECKLIST:
            // CurrentTaskType.sectionsJson = payload.sectionsJson.jsObject;
            try {
                JSON.parse(payload.sectionsJson);
                CurrentTaskType.IsValidSectionJSON = true;
                // tempState.IsValidConfigJSON = true;
            } catch (err) {
                CurrentTaskType.IsValidSectionJSON = false;
                // tempState.IsValidConfigJSON = false;
            }
            CurrentTaskType.sectionsJson = payload.sectionsJson;
            tempState.CurrentTaskType = CurrentTaskType;
            break;

        // Save Task Type
        case CONSTANTS.SAVE_TASKTYPE_DETAILS:
            tempState.LoadingTaskTypeAddEditPanel = payload.isOnGoingSave;
            tempState.hideConfirm = true;
            if (payload.dialogStatus === "Copy") {
                tempState.LoadingTaskTypeDashboard = true;
            }
            break;

        case CONSTANTS.OPEN_CLOSE_TASK_TYPE_ALERT_MODAL:
            tempState.alertJSON = {
                hideAlert: payload.hideAlert,
                alertHeader: payload.alertHeader,
                alertSubText: payload.alertSubText
            };
            tempState.LoadingTaskTypeAddEditPanel = false;
            break;

        case CONSTANTS.OPEN_CLOSE_TASKTYPE_CONFIRM_MODAL:
            tempState.hideConfirm = payload.hideConfirm;
            tempState.dialogStatus = payload.dialogStatus;
            if (payload.hideConfirm) {
                tempState.CurrentTaskType = utility.resetCurrentTaskType();
            }
            else {
                tempState.CurrentTaskType = payload.CurrentTaskType;
            }
            break;

        case CONSTANTS.DELETE_TASK_TYPE:
            tempState.LoadingTaskTypeDashboard = payload.isOngoingDelete;
            tempState.hideConfirm = true;
            break;


        default:
            break;
    }

    return tempState;
}