import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import JobDashboard from './JobLitesDashboard';
import { Breadcrumb, Dropdown, Label } from 'office-ui-fabric-react';
import { TaskDropDownStyles } from '../../Tasks/Constants/TaskStylesConstants';
import { JobLitesActions } from '../Actions/JobLitesActions';

const mapStateToProps = (state) => {
    return {
        JobLites: state.JobLitesReducers
    }
}

class JobLitesComponent extends PureComponent {
    render() {
        let { JobBreadCrumb, jobDashboardColumns, LoadingDashboard, Jobs, TotalJobsCount } = this.props.JobLites;

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={JobBreadCrumb}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <Label>Total {Jobs.length} of {TotalJobsCount} Job(s) Loaded</Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Columns: </Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <Dropdown
                                disabled={LoadingDashboard}
                                placeholder="Select columns"
                                selectedKeys={jobDashboardColumns}
                                onChange={(event, selectedColumn) => {
                                    this.props.showHideJobDashboardColumns({
                                        selectedColumn: selectedColumn
                                    })
                                }}
                                multiSelect
                                options={[
                                    { key: 'jobNumber', text: 'Job Number', disabled: true },
                                    { key: 'client', text: 'Client' },
                                    { key: 'site', text: 'Site' },
                                    { key: 'inspector', text: 'Personnel' },
                                    { key: 'comments', text: 'Comments' },
                                    { key: 'startDate', text: 'Start Date' },
                                    { key: 'quotationNumber', text: 'Quote / WO' },
                                    { key: 'status', text: 'Status', disabled: true }
                                ]}
                                styles={TaskDropDownStyles}
                            />
                        </div>
                    </div>
                </div>
                <JobDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {

    let jobAction = new JobLitesActions();

    return bindActionCreators({ ...jobAction }, dispatch);
}

const JobLites = connect(mapStateToProps, mapDispatchToProps)(JobLitesComponent);
export default JobLites;
