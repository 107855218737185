import moment from 'moment';

export class CommonUtility {

    static sortByColumn = (result, sortField, sortOrder) => {
        if (sortOrder === 'asc') {
            result = result.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                }
                else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                else if (!b[sortField] && a[sortField]) {
                    return 1;
                }
                else if (b[sortField] && !a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = result.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } 
                else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                else if (!b[sortField] && a[sortField]) {
                    return -1;
                }
                else if (b[sortField] && !a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }

        return result;
    }

    static formatDateTimeToZeroHour = (date) => {
        return new Date(moment(date));
    }
    static formatDateToReadableString = (dateString) => {
        return moment(dateString).format('DD-MMM-YYYY');
    }
}
