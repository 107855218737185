import { process } from "@progress/kendo-data-query";
import { ISelect } from "../../../CommonInterfaces/CommoInterfaces";
import { IAssetObservation, IAssetObservationsDetailsList } from "../AssetInterface/IAsset";
import moment from 'moment-timezone';
import { DATE_FORMAT } from "../../../Constants/MessageConstants";

export class AssetUtility {

    public loadSelectDropDownValue = (responseArray: any[]): ISelect[] => {
        let dropDownValues: ISelect[] = [];


        responseArray.forEach((data: any) => {
            dropDownValues.push({
                label: data.name,
                value: data.id,
                acronymCode: data.acronymCode
            });
        });

        return dropDownValues;
    }

    public processAssetsForKendoGrid = (assets: any[], kendoGridDataState: any): any => {

        let processAssets: any = process([...assets], { ...kendoGridDataState });
        return processAssets;
    }

    public populateAssetType = (asset: any, assetTypes: any[]): ISelect => {

        let assetType: ISelect = null;
        let assetTypeDetails: any = assetTypes.find(d => d.id === asset.assetTypeId);
        assetType = {
            value: asset.assetTypeId,
            label: assetTypeDetails?.name,
            acronymCode: assetTypeDetails?.acronymCode
        }
        return assetType;
    }

    public populateAssetObservations = (obsrvationsResponse: IAssetObservation[]): IAssetObservationsDetailsList[] => {

        let finalObservations: IAssetObservationsDetailsList[] = [];

        obsrvationsResponse.forEach((observation: IAssetObservation) => {
            finalObservations.push({
                key: observation.id,
                actualInspectionDate:
                    observation.actualInspectionDate ?
                        moment.utc(observation.actualInspectionDate).local().format(DATE_FORMAT) : null,
                certificate: `${observation.certificateNumber}${observation.revision ? ("-" + observation.revision) : ""}`,
                certificateNumber: observation.certificateNumber,
                certificateLink: observation.certificateFileContent ?
                    JSON.parse(observation.certificateFileContent).blobKey
                    :
                    "",
                fieldReportLink: observation.fieldReportContent ?
                    JSON.parse(observation.fieldReportContent)
                    :
                    "",
                dueDate: observation.dueDate ?
                    moment.utc(observation.dueDate).local().format(DATE_FORMAT) : null,
                inspector: observation.inspector,
                jobNumber: observation.jobNumber,
                certificateDate: observation.certificateDate ?
                    moment.utc(observation.certificateDate).local().format(DATE_FORMAT) : null,
                observationResult: observation.observationResult,
                result: observation.result === 1 ? 'PASS' : observation.result === 2 ? 'FAIL' : observation.result === 3
                    ? 'PENDING' : 'N/A'
            })
        })

        return finalObservations;
    }
}
