import React, { Component } from 'react'
import { IClientInventoryStateProps } from '../ClientInventoryInteface/IClientInventoryStateProps'
import ClientSiteSelector from '../../ClientSiteSelector/Views/ClientSiteSelector';
import ClientInventoryTable from './ClientInventoryTable';

export default class ClientInventoryDashboard extends Component<IClientInventoryStateProps, any> {

    public render(): JSX.Element {

        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;

        return (
            <React.Fragment>
                {/* Main Filter */}
                <ClientSiteSelector />
                {selectedClient && selectedSite &&
                    <ClientInventoryTable
                        {...this.props} />
                }

            </React.Fragment>
        )
    }
}
