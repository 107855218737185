import React, { Component } from 'react'
import '@progress/kendo-theme-default/dist/all.css';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import * as CONSTANTS from './../Constants/AssetConstants';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { IdCustomCell, ItemStatusCustomCell, ResultCustomCell, StatusCustomCell } from './CustomColumnViews';
import AddEditAssetPanel from './AddEditAssetPanel';
import { AssetAPI } from '../Services/AssetAPI';
import { ActionButton, PrimaryButton } from 'office-ui-fabric-react';
import { IconConstants } from '../../../Constants/StylesConstants';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from 'moment-timezone';
import { DATE_FORMAT, DATE_TIME_FORMAT_USER } from '../../../Constants/MessageConstants';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { DateCustomCell } from '../../Reports/Views/CustomReportsColumnViews';
import { FileCustomCell } from '../../LooseGears/Views/CustomLooseGearColumnViews';
import { CLIENT_ADMIN_ROLE, CLIENT_USER_ROLE } from '../../../Constants/CommonConstants';
import DropdownFilterCell from './DropdownFilterCell';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { SpecificationsCustomCell } from '../../Assessment/Views/CustomAssessmentsColumnViews';

export default class AssetDashboard extends Component {

    _assetAPI = null;
    _excelExport;

    constructor(props) {
        super(props);
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);
    }

    export = () => {
        // this._excelExport.save();
        this.savetoExcel(this._excelExport);
    }

    savetoExcel = (component) => {
        const options = component.workbookOptions();
        const rows = options.sheets[0].rows;

        let cellIndexOfStatus = [];
        rows.forEach((row) => {
            if (row.type === "data") {
                row.cells.forEach((cell, index) => {
                    //cell.background = "#fff";
                    if (index === cellIndexOfStatus[0] && CONSTANTS.ASSET_STATUS[cell.value]) {
                        cell.background = CONSTANTS.ASSET_STATUS[cell.value].color;
                        cell.value = CONSTANTS.ASSET_STATUS[cell.value].status;
                    }
                    if (index === 5) {
                        cell.value = cell.value ? moment(cell.value).format(DATE_FORMAT) : "";
                    }
                    if (index === 6) {
                        cell.value = cell.value ? moment(cell.value).format(DATE_FORMAT) : "";
                    }
                    if (index === 7) {
                        cell.value = cell.value ? cell.value === 1 ? "Accepted" : cell.value === 2 ? "Rejected" : "OTHERS" : "";
                    }
                    if (cell.format === "custom") {
                        console.log("Value old: ", cell.value);
                        cell.value = ItemStatusCustomCell.getStatus(cell.value)
                        console.log("Value new: ", cell.value);
                    }
                });
            }
            if (row.type === "header") {
                row.cells.forEach((cell, index) => {
                    if (cell.value === "Status") {
                        cellIndexOfStatus.push(index);
                    }
                });
            }
        });

        component.save(options);
    };

    resultCustomCell = (props) => (
        <ResultCustomCell {...props} currentProps={this.props} />
    );

    assetStatusCustomCell = (props) => (
        <StatusCustomCell {...props} currentProps={this.props} />
    );

    dateCustomCell = (props) => (
        <DateCustomCell {...props} currentProps={this.props} />
    );

    itemStatusCustomCell = (props) => (
        <ItemStatusCustomCell {...props} currentProps={this.props} />
    );

    idCustomCell = (props) => (
        <IdCustomCell {...props} currentProps={this.props} />
    );

    fileCustomCell = (props) => (
        <FileCustomCell {...props} currentProps={this.props} />
    );

    specificationsCustomCell = (props) => (
        <SpecificationsCustomCell {...props} currentProps={this.props} />
    );


    CategoryFilterCell = (props) => (
        <DropdownFilterCell
            {...props}
            data={["1-Pass", "2-Fail", "3-Pending"]}
            defaultValue={"--Select--"}
        />
    );

    StatusFilterCell = (props) => (
        <DropdownFilterCell
            {...props}
            data={["Valid", "Due Shortly", "Expired"]}
            defaultValue={"--Select--"}
        />
    );


    cellRender = (td, props) => {
        let groupColDetails = CONSTANTS.ASSET_VIEW_GRID_COLUMNS.find(
            (d) => d.field === props.dataItem.field
        );
        let groupColTitle = '';
        if (groupColDetails) {
            groupColTitle = groupColDetails.title;
        }

        if (td && td.props.children && props.rowType === 'groupHeader') {
            let children = (
                <span>
                    {td.props.children.props.children[0]}{' '}
                    {groupColTitle + ': ' + props.dataItem.value}
                </span>
            );
            return React.cloneElement(td, td.props, children);
        }
        return td;
    };

    onChangeDateInput = (dateFieldName, dateChangeEvent) => {
        let {
            assetGridDataState,
            selectedClientFilter,
            selectedSiteFilters
        } = this.props.Asset;

        let issueDate = "";
        let dueDate = "";
        let filterJson = {};
        let fromDateStr = "";
        let toDateStr = "";
        let event = {
            filter: assetGridDataState.filter
        }
        if (assetGridDataState.filter) {
            filterJson = assetGridDataState.filter.filters;
        }
        else {
            filterJson = [];
        }
        if (dateChangeEvent) {
            const {
                start,
                end
            } = dateChangeEvent?.target?.value;
            fromDateStr =
                `${moment(start).format('YYYY-MM-DD')} 00:00:00`;
            toDateStr =
                `${moment(end).format('YYYY-MM-DD')} 23:59:59`;
        }
        switch (dateFieldName) {
            case "actualInspectionDate":
                issueDate = dateChangeEvent ? `${fromDateStr}#${toDateStr}` : ``;
                // filterJson[dateFieldName] = issueDate;
                let indexOfFilterActualInspectionDate = filterJson.findIndex((filter) => filter.field === "actualInspectionDate");
                filterJson.splice(indexOfFilterActualInspectionDate, 1);
                filterJson.push(
                    { "field": "actualInspectionDate", "operator": "eq", "value": issueDate }
                )

                let startDueDate = this.props.Asset.expiryDateRange?.start;
                let endDueDate = this.props.Asset.expiryDateRange?.end;
                if (startDueDate && endDueDate) {
                    const fromDueDateStr =
                        `${moment(startDueDate).format('YYYY-MM-DD')} 00:00:00`;
                    const toDueDateStr =
                        `${moment(endDueDate).format('YYYY-MM-DD')} 23:59:59`;
                    dueDate = `${fromDueDateStr}#${toDueDateStr}`;
                    filterJson.push(
                        { "field": "dueDate", "operator": "eq", "value": `${fromDueDateStr}#${toDueDateStr}` }
                    )
                }
                break;
            case "dueDate":
                dueDate = dateChangeEvent ? `${fromDateStr}#${toDateStr}` : ``;
                let indexOfFilterDueDate = filterJson.findIndex((filter) => filter.field === "operator");
                filterJson.splice(indexOfFilterDueDate, 1);
                filterJson.push(
                    { "field": "dueDate", "operator": "eq", "value": dueDate }
                )
                let startIssueDate = this.props.Asset.issueDateRange?.start;
                let endIssueDate = this.props.Asset.issueDateRange?.end;
                if (startIssueDate && endIssueDate) {
                    const fromStartDateStr =
                        `${moment(startIssueDate).format('YYYY-MM-DD')} 00:00:00`;
                    const toStartDateStr =
                        `${moment(endIssueDate).format('YYYY-MM-DD')} 23:59:59`;
                    issueDate = `${fromStartDateStr}#${toStartDateStr}`;
                    filterJson.push(
                        { "field": "actualInspectionDate", "operator": "eq", "value": `${fromStartDateStr}#${toStartDateStr}` }
                    )
                }
                break;

            default:
                break;
        }


        let config = {
            headers: {
                filter: JSON.stringify(filterJson)
            }
        }

        this.props.handleAssetGridFilterChanged({
            event,
            config,
            apiObject: this._assetAPI,
            selectedClient: selectedClientFilter,
            selectedLocations: selectedSiteFilters,
            offset: 0,
            limit: this.props.Asset.limit
        })
    }

    render() {

        let {
            assetGridDataState,
            isLoadingAssetDashboard,
            assets,
            processedAssets,
            isOpenAddEditAssetPanel,
            selectedClientFilter,
            selectedSiteFilters,
            issueDateRange,
            expiryDateRange
        } = this.props.Asset;

        let currentUserCompanyId = window.loggedInUserDetails.companyId;
        let currentUserRole = window.loggedInUserDetails.role;

        let assetGridColumns = CommonUtility.sortByColumn(CONSTANTS.ASSET_VIEW_GRID_COLUMNS, "colIndex", "asc")

        return (
            <React.Fragment>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 asset-grid">
                            <LoadingOverlay
                                active={isLoadingAssetDashboard}
                                spinner={<FadeLoader />}
                                text="Loading Assets...">

                                <Grid
                                    onExpandChange={(event) => {
                                        this.props.handleAssetGridExpandChanged({
                                            event: event
                                        })
                                    }}
                                    data={processedAssets}
                                    expandField="expanded"
                                    resizable
                                    reorderable
                                    // groupable
                                    sortable
                                    filterable
                                    {...assetGridDataState}
                                    cellRender={this.cellRender}
                                    style={{ width: '100%' }}
                                    onSortChange={(event) => {
                                        this.props.handleAssetGridSortChanged({
                                            event: event
                                        })
                                    }}
                                    onDataStateChange={(event) => {
                                        this.props.handleAssetGridGroupSortChanged({
                                            event: event
                                        })
                                    }}
                                    onFilterChange={(event) => {
                                        let filterJson = {};

                                        // let siteNumbers = selectedSiteFilters?.filter((location) => location.value > 0)
                                        //     .map((location) => location.value).map(Number);

                                        // filterJson["siteNumbers"] = siteNumbers;
                                        // filterJson["clientNumber"] = selectedClientFilter.value;

                                        /**** */
                                        if (event.filter) {
                                            filterJson = event.filter.filters;
                                        }
                                        else {
                                            filterJson = [];
                                        }
                                        let startDueDate = expiryDateRange?.start;
                                        let endDueDate = expiryDateRange?.end;

                                        if (startDueDate && endDueDate) {
                                            const fromDueDateStr =
                                                `${moment(startDueDate).format('YYYY-MM-DD')} 00:00:00`;
                                            const toDueDateStr =
                                                `${moment(endDueDate).format('YYYY-MM-DD')} 23:59:59`;
                                            filterJson.push(
                                                { "field": "dueDate", "operator": "eq", "value": `${fromDueDateStr}#${toDueDateStr}` }
                                            )
                                        }

                                        let startIssueDate = issueDateRange?.start;
                                        let endIssueDate = issueDateRange?.end;

                                        if (startIssueDate && endIssueDate) {
                                            const fromStartDateStr =
                                                `${moment(startIssueDate).format('YYYY-MM-DD')} 00:00:00`;
                                            const toStartDateStr =
                                                `${moment(endIssueDate).format('YYYY-MM-DD')} 23:59:59`;
                                            filterJson.push(
                                                { "field": "actualInspectionDate", "operator": "eq", "value": `${fromStartDateStr}#${toStartDateStr}` }
                                            )
                                        }

                                        let config = {
                                            headers: {
                                                filter: JSON.stringify(filterJson)
                                            }
                                        }
                                        this.props.handleAssetGridFilterChanged({
                                            event,
                                            config,
                                            selectedClient: selectedClientFilter,
                                            selectedLocations: selectedSiteFilters,
                                            apiObject: this._assetAPI
                                        })

                                    }}
                                    filterOperators={{
                                        text: [
                                            {
                                                text: 'grid.filterContainsOperator',
                                                operator: 'contains',
                                            },
                                            {
                                                text: 'grid.filterStartsWithOperator',
                                                operator: 'startswith',
                                            },
                                            {
                                                text: 'grid.filterEndsWithOperator',
                                                operator: 'endswith',
                                            },
                                            {
                                                text: 'grid.filterEqOperator',
                                                operator: 'eq'
                                            },
                                        ]
                                    }}
                                >
                                    {assetGridColumns
                                        .filter((column) => column.show)
                                        .map((column, index) => {
                                            return (
                                                <GridColumn
                                                    key={index}
                                                    field={column.field}
                                                    title={column.title}
                                                    filter={column.filter === 'text' ? 'text' : 'numeric' ? 'numeric' : 'date'}
                                                    filterable={
                                                        ["name", "acronymCode", "assetTypeDesc", "specifications",
                                                            "result", "certificateNumber", "endUserName", "endUserAddress", "itemStatus"]
                                                            .indexOf(column.field) > -1
                                                    }
                                                    cell={
                                                        column.field === 'status'
                                                            ? this.assetStatusCustomCell :
                                                            column.field === 'id'
                                                                ? this.idCustomCell
                                                                :
                                                                column.field === 'itemStatus'
                                                                    ? this.itemStatusCustomCell
                                                                    :
                                                                    column.field === 'actualInspectionDate'
                                                                        ? this.dateCustomCell
                                                                        :
                                                                        column.field === 'dueDate'
                                                                            ? this.dateCustomCell
                                                                            :
                                                                            column.field === 'result'
                                                                                ? this.resultCustomCell
                                                                                : column.field === 'certificateFile'
                                                                                    ? this.fileCustomCell
                                                                                    : column.field === 'specifications'
                                                                                        ? this.specificationsCustomCell
                                                                                        : assets[column.field]
                                                    }
                                                    filterCell={column.field === 'result'
                                                        ? this.CategoryFilterCell :
                                                        column.field === 'itemStatus' ? this.StatusFilterCell :
                                                            null}
                                                />
                                            );
                                        })}
                                    <GridToolbar>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1">
                                                    <ActionButton
                                                        iconProps={IconConstants.refreshIcon}
                                                        disabled={isLoadingAssetDashboard}
                                                        title="Reload Data"
                                                        onClick={() => {
                                                            if (currentUserRole === "CLIENT-USER") {
                                                                this.props.changeClientFilterName({
                                                                    apiObject: this._assetAPI,
                                                                    selectedLocation: selectedClientFilter,
                                                                    companyId: currentUserCompanyId
                                                                })
                                                            }
                                                            else {
                                                                this.props.changeSiteFilterName({
                                                                    apiObject: this._assetAPI,
                                                                    selectedLocations: selectedSiteFilters,
                                                                    selectedClient: selectedClientFilter
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    {' '}
                                                    {currentUserRole !== CLIENT_USER_ROLE && currentUserRole !== CLIENT_ADMIN_ROLE &&
                                                        <ActionButton
                                                            iconProps={IconConstants.addIcon}
                                                            title="Add New Asset"
                                                            onClick={() => {
                                                                this.props.openCloseAddEditAssetPanel({
                                                                    hideAddEditAssetPanel: false,
                                                                    selectedAssetId: -1
                                                                })
                                                            }}
                                                        />
                                                    }
                                                    {' '}
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 px-0">
                                                    <DateRangePicker
                                                        style={{
                                                            color: "#000"
                                                        }}
                                                        endDateInputSettings={
                                                            {
                                                                label: `Insp Date To`,
                                                            }
                                                        }
                                                        startDateInputSettings={
                                                            {
                                                                label: `Insp Date From`,
                                                            }
                                                        }
                                                        value={issueDateRange}
                                                        onChange={(event) => {
                                                            const {
                                                                start,
                                                                end
                                                            } = event.target.value;

                                                            this.props.changeFilterDateChange({
                                                                dateField: "actualInspectionDate",
                                                                dateRangeValue: event.target.value
                                                            })
                                                            if (start && end) {
                                                                this.onChangeDateInput("actualInspectionDate", event);
                                                            }

                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1 px-0">
                                                    <ActionButton
                                                        disabled={isLoadingAssetDashboard || (!issueDateRange?.start && !issueDateRange?.end)}
                                                        text="Clear"
                                                        iconProps={IconConstants.deleteicon}
                                                        onClick={() => {
                                                            this.props.changeFilterDateChange({
                                                                dateField: "actualInspectionDate",
                                                                dateRangeValue: {
                                                                    start: null,
                                                                    end: null
                                                                }
                                                            });
                                                            this.onChangeDateInput("actualInspectionDate", null);

                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 px-0">
                                                    <DateRangePicker
                                                        style={{
                                                            color: "#000"
                                                        }}
                                                        endDateInputSettings={
                                                            {
                                                                label: `Due Date To`,
                                                            }
                                                        }
                                                        startDateInputSettings={
                                                            {
                                                                label: `Due Date From`,
                                                            }
                                                        }
                                                        value={expiryDateRange}
                                                        onChange={(event) => {
                                                            const {
                                                                start,
                                                                end
                                                            } = event.target.value;
                                                            this.props.changeFilterDateChange({
                                                                dateField: "dueDate",
                                                                dateRangeValue: event.target.value
                                                            })
                                                            if (start && end) {
                                                                this.onChangeDateInput("dueDate", event);
                                                            }

                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1 px-0">
                                                    <ActionButton
                                                        disabled={isLoadingAssetDashboard || (!expiryDateRange?.start && !expiryDateRange?.end)}
                                                        text="Clear"
                                                        iconProps={IconConstants.deleteicon}
                                                        onClick={() => {
                                                            this.props.changeFilterDateChange({
                                                                dateField: "dueDate",
                                                                dateRangeValue: {
                                                                    start: null,
                                                                    end: null
                                                                }
                                                            });
                                                            this.onChangeDateInput("dueDate", null);

                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                                    <PrimaryButton
                                                        className="table-export-button-div"
                                                        text="Export to Excel"
                                                        iconProps={IconConstants.excelIcon}
                                                        onClick={() => this.export()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </GridToolbar>
                                </Grid>

                                <ExcelExport
                                    data={assets}
                                    ref={exporter => this._excelExport = exporter}
                                    fileName={`Assets_of_Client_${selectedClientFilter.value}_${moment.utc(new Date()).local().format(DATE_TIME_FORMAT_USER)}.xlsx`}
                                >
                                    {CONSTANTS.ASSET_VIEW_GRID_COLUMNS
                                        .filter((column) => column.field !== "id" && column.show && column.field !== "certificateFile" )
                                        .map((column, index) => {
                                            return (
                                                <ExcelExportColumn
                                                    key={index}
                                                    field={column.field === 'itemStatus' ? "dueDate" : column.field}
                                                    title={column.title}
                                                    cellOptions={{
                                                        format: column.filter === "date" ? "date" : column.filter === "custom" ? "custom" : "",
                                                    }}
                                                />
                                            );
                                        })}
                                </ExcelExport>
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>
                {
                    isOpenAddEditAssetPanel &&
                    <AddEditAssetPanel
                        {...this.props}
                    />
                }
            </React.Fragment >
        )
    }
}
