import * as React from 'react';
import Select from 'react-select';
import { DefaultButton, Label, PrimaryButton, TextField, Dialog, DialogType, IModalProps, ProgressIndicator } from 'office-ui-fabric-react';
import { IconConstants } from '../../../Constants/StylesConstants';
import { JobLitesAPI } from '../Services/JobLitesAPI';
import { ISelect } from '../../../CommonInterfaces/CommoInterfaces';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { JOB_STATUS_CLOSED } from '../../Jobs/Constants/JobConstants';
import { JOB_STATUS_CANCELLED } from '../Constants/JobLitesConstants';

const modalProps: IModalProps = {
    isBlocking: false,
    styles: {
        main: {
            width: 500,
        }
    },
};

export default class AddAssetPopUp extends React.PureComponent<any, {}> {

    _apiObject = null;

    constructor(props: any) {
        super(props);

        this.state = {
            error: false,
        };

        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails);

    }

    componentDidMount() {
    }

    public render(): JSX.Element {

        let dialogContentProps = {
            type: DialogType.largeHeader,
            title: "ADD NEW ASSET"
        };

        let {
            isOpenAddNewAsset,
            AssetTypeOptions,
            SelectedAssetType,
            CurrentJobDetails,
            LoadingAssetSection,
            NewAsset
        } = this.props.JobLites;

        let isAddAssetDisabled: boolean = !SelectedAssetType || NewAsset.error ||
            (!CurrentJobDetails?.ClientName.value) ||
            (!CurrentJobDetails?.SiteDetails.value) ||
            (CurrentJobDetails && CurrentJobDetails.status === JOB_STATUS_CLOSED) ||
            (CurrentJobDetails && CurrentJobDetails.status === JOB_STATUS_CANCELLED);

        return (
            <React.Fragment>
                <Dialog
                    hidden={!isOpenAddNewAsset}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}
                >
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label>Choose Asset Type</Label>
                                <Select
                                    placeholder="Asset Type"
                                    value={SelectedAssetType}
                                    onChange={(newVal: ISelect) => {
                                        this.props.changeSelectedAssetType({
                                            SelectedAssetType: newVal
                                        })
                                    }}
                                    isMulti={false}
                                    options={
                                        !AssetTypeOptions ? []
                                            :
                                            CommonUtility.sortByColumn(
                                                AssetTypeOptions
                                                    .filter((assetType: ISelect) => assetType.label.indexOf("LLG") === -1), "label", "asc")
                                    }
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                    label="Add Asset Name"
                                    value={NewAsset.value}
                                    onChange={(event, newVal) => {
                                        this.props.changeAssetName({
                                            value: newVal
                                        })
                                    }}
                                    required
                                />
                                <span className='info-span'>{NewAsset.errorMessage}</span>
                            </div>
                        </div>
                        <br></br>
                        <div className="ms-Grid-row">
                            {!LoadingAssetSection ?
                                <>
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                        <PrimaryButton
                                            text="Save"
                                            menuIconProps={IconConstants.saveicon}
                                            disabled={isAddAssetDisabled}
                                            onClick={() => this.props.saveNewAsset({
                                                apiObject: this._apiObject,
                                                locationPayload: {
                                                    assetTypeId: SelectedAssetType?.value,
                                                    companyId: CurrentJobDetails?.ClientName.value.value,
                                                    parentId: CurrentJobDetails?.SiteDetails.value.value,
                                                    objectType: "ASSET",
                                                    name: NewAsset.value,
                                                    type: 1,
                                                    status: 1,
                                                    locationLevel: 1
                                                }
                                            })}
                                        />
                                    </div>
                                    {` `}
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                        <DefaultButton
                                            text="Cancel"
                                            menuIconProps={IconConstants.cancelIcon}
                                            onClick={() => this.props.openCloseAddNewAssetPopUp({
                                                isOpenAddNewAsset: false
                                            })}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                    </div>
                                </>
                                :
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                    <ProgressIndicator description="Please wait..." />
                                </div>
                            }
                        </div>
                    </div>
                </Dialog>
            </React.Fragment >
        )
    }
}
