import { IconButton, Label, PrimaryButton, SearchBox, TextField } from 'office-ui-fabric-react';
import * as React from 'react'
import { downloadExcel } from "react-export-table-to-excel";
import { IconConstants } from '../../../Constants/StylesConstants';
import { IClientInventoryStateProps } from '../ClientInventoryInteface/IClientInventoryStateProps';
import { IClientInventory, IClientInventoryAPI } from '../ClientInventoryInteface/IClientInventory';
import { ClientInventoryAPI } from '../Services/ClientInventoryAPI';
import {
    CLIENT, CLIENT_INVENTORY_TABLE_HEADER, DESCRIPTION,
    IS_ENABLED_EDIT, PRODUCT_CATEGORY, QUANTITY, SITE,
    TONNE,MANUFACTURER,LOCATION,COMMENTS
} from '../Constants/ClientInventoryConstants';
import AlertRedux from '../../Alert/AlertRedux';
import * as Loaders from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import ClientInventoryDeleteConfirmModal from './ClientInventoryDeleteConfirmModal';


export default class ClientInventoryTable extends React.PureComponent<IClientInventoryStateProps, {}> {

    private _ClientInventoryAPI: IClientInventoryAPI = null;

    constructor(props: IClientInventoryStateProps) {
        super(props);
        this._ClientInventoryAPI = new ClientInventoryAPI(window.API_URL, window.loggedInUserDetails);
    }

    public loadInitData = (): void => {

        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;

        this.props.loadClientInventory({
            apiObject: this._ClientInventoryAPI,
            clientId: selectedClient.value,
            siteId: selectedSite.value
        })

    }


    public handleDownloadExcel = () => {
        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;

        let clientInventoryExcel: any[] = this.props.ClientInventoryState.clientInventories
            .map((clientInventory: IClientInventory): any => {
                return {
                    item: clientInventory.productCategory,
                    description: clientInventory.description,
                    quantity: clientInventory.quantity,
                    tonne:clientInventory.tonne,
                    manufacturer:clientInventory.manufacturer,
                    location:clientInventory.location,
                    comments:clientInventory.comments,
                    clientId: selectedClient.label,
                    siteId: selectedSite.label,
                }
            });


        downloadExcel({
            fileName: `Client_Inventory`,
            sheet: `Details`,
            tablePayload: {
                header: CLIENT_INVENTORY_TABLE_HEADER,
                body: clientInventoryExcel,
            },
        });
    }
    public render(): JSX.Element {

        const {
            clientInventories,
            alertJSON,
            isLoadingClientInventory,
            searchedItem,
            searchedDescription,
            searchedQuantity,
            searchedTonne,
            searchedComments,
            searchedManufacturer,
            searchedLocation,
            isOpenDeleteConfirmModal
        } = this.props.ClientInventoryState;

        let fillteredClientInventories: IClientInventory[] = [...clientInventories];
        if (searchedItem) {
            fillteredClientInventories = fillteredClientInventories.filter((clientInventory: IClientInventory) =>
                clientInventory.productCategory?.toUpperCase().indexOf(searchedItem.toUpperCase()) > -1
            );
        }
        if (searchedDescription) {
            fillteredClientInventories = fillteredClientInventories.filter((clientInventory: IClientInventory) =>
                clientInventory.description?.toUpperCase().indexOf(searchedDescription.toUpperCase()) > -1
            );
        }
        if (searchedQuantity || parseInt(searchedQuantity) === 0) {
            fillteredClientInventories = fillteredClientInventories.filter((clientInventory: IClientInventory) =>
                clientInventory.quantity === parseInt(searchedQuantity)
            );
        }
        if (searchedTonne || parseFloat(searchedTonne) === 0) {
            fillteredClientInventories = fillteredClientInventories.filter((clientInventory: IClientInventory) =>
                clientInventory.tonne === parseFloat(searchedTonne)
            );
        }
        if (searchedManufacturer) {
            fillteredClientInventories = fillteredClientInventories.filter((clientInventory: IClientInventory) =>
                clientInventory.manufacturer?.toUpperCase().indexOf(searchedManufacturer.toUpperCase()) > -1
            );
        }
        if (searchedLocation) {
            fillteredClientInventories = fillteredClientInventories.filter((clientInventory: IClientInventory) =>
                clientInventory.location?.toUpperCase().indexOf(searchedLocation.toUpperCase()) > -1
            );
        }
        if (searchedComments) {
            fillteredClientInventories = fillteredClientInventories.filter((clientInventory: IClientInventory) =>
                clientInventory.comments?.toUpperCase().indexOf(searchedComments.toUpperCase()) > -1
            );
        }

        return (
            <>
                <LoadingOverlay
                    active={isLoadingClientInventory}
                    spinner={<Loaders.BeatLoader />}
                    text="Please wait...">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg10"></div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                <PrimaryButton
                                    disabled={!fillteredClientInventories.length}
                                    text="Export to Excel"
                                    menuIconProps={IconConstants.exportIcon}
                                    iconProps={IconConstants.excelIcon}
                                    onClick={() => this.handleDownloadExcel()}
                                />
                            </div>
                        </div>
                        <div className="table-section">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                    <table id="client-inventory-table">
                                        <thead>
                                            <tr>
                                                {CLIENT_INVENTORY_TABLE_HEADER
                                                    .filter((header: string) => (header !== SITE && header !== CLIENT))
                                                    .map((head: string) => (
                                                        <th key={head}>
                                                            <Label styles={{
                                                                root: {
                                                                    color: "#fff"
                                                                }
                                                            }}>{head}</Label>
                                                            <SearchBox
                                                                type="Text"
                                                                underlined
                                                                value={head === PRODUCT_CATEGORY ?
                                                                    searchedItem :
                                                                    head === DESCRIPTION ?
                                                                        searchedDescription :
                                                                    head === TONNE ?
                                                                        searchedTonne :
                                                                    head === MANUFACTURER ?
                                                                        searchedManufacturer :
                                                                    head === LOCATION ?
                                                                        searchedLocation:
                                                                    head === COMMENTS ?
                                                                        searchedComments
                                                                        :
                                                                        searchedQuantity
                                                                }
                                                                onChange={(ev: any, value: string) => {
                                                                    this.props.searchClientInventory({
                                                                        value: value,
                                                                        field: head,
                                                                    })
                                                                }}
                                                                onClear={(ev: any) => {
                                                                    this.props.searchClientInventory({
                                                                        value: "",
                                                                        field: head,
                                                                    })
                                                                }}
                                                                className="search-box-custom-width"
                                                            />
                                                        </th>
                                                    ))
                                                }
                                                <th key={"action"}>Actions</th>
                                            </tr>
                                        </thead>
                                        {fillteredClientInventories.length ?

                                            <tbody>
                                                {fillteredClientInventories.map((inventory: IClientInventory, index: number) => (
                                                    <tr key={inventory.id}>
                                                        <td >
                                                            {inventory.isEnabledEdit ?
                                                                <TextField
                                                                    type="Text"
                                                                    underlined
                                                                    value={inventory.productCategory}
                                                                    onChange={(ev: any, value: string) => {
                                                                        this.props.changeClientInventory({
                                                                            value: value,
                                                                            field: PRODUCT_CATEGORY,
                                                                            selectedClientInventory: inventory
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                inventory.productCategory
                                                            }
                                                        </td>
                                                        <td>
                                                            {inventory.isEnabledEdit ?
                                                                <TextField
                                                                    type="Text"
                                                                    underlined
                                                                    value={inventory.description}
                                                                    onChange={(ev: any, value: string) => {
                                                                        this.props.changeClientInventory({
                                                                            value: value,
                                                                            field: DESCRIPTION,
                                                                            selectedClientInventory: inventory
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                inventory.description
                                                            }
                                                        </td>
                                                        <td>
                                                            {inventory.isEnabledEdit ?
                                                                <TextField
                                                                    type="Number"
                                                                    underlined
                                                                    value={`${inventory.quantity}`}
                                                                    onChange={(ev: any, value: string) => {
                                                                        this.props.changeClientInventory({
                                                                            value: value,
                                                                            field: QUANTITY,
                                                                            selectedClientInventory: inventory
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                inventory.quantity
                                                            }
                                                        </td>
                                                        <td>
                                                            {inventory.isEnabledEdit ?
                                                                <TextField
                                                                    type="Number"
                                                                    underlined
                                                                    value={`${inventory.tonne}`}
                                                                    onChange={(ev: any, value: string) => {
                                                                        this.props.changeClientInventory({
                                                                            value: value,
                                                                            field: TONNE,
                                                                            selectedClientInventory: inventory
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                inventory.tonne
                                                            }
                                                        </td>
                                                        <td>
                                                            {inventory.isEnabledEdit ?
                                                                <TextField
                                                                    type="Text"
                                                                    underlined
                                                                    value={inventory.manufacturer}
                                                                    onChange={(ev: any, value: string) => {
                                                                        this.props.changeClientInventory({
                                                                            value: value,
                                                                            field: MANUFACTURER,
                                                                            selectedClientInventory: inventory
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                inventory.manufacturer
                                                            }
                                                        </td>
                                                        <td>
                                                            {inventory.isEnabledEdit ?
                                                                <TextField
                                                                    type="Text"
                                                                    underlined
                                                                    value={inventory.location}
                                                                    onChange={(ev: any, value: string) => {
                                                                        this.props.changeClientInventory({
                                                                            value: value,
                                                                            field: LOCATION,
                                                                            selectedClientInventory: inventory
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                inventory.location
                                                            }
                                                        </td>
                                                        <td>
                                                            {inventory.isEnabledEdit ?
                                                                <TextField
                                                                    type="Text"
                                                                    underlined
                                                                    value={inventory.comments}
                                                                    onChange={(ev: any, value: string) => {
                                                                        this.props.changeClientInventory({
                                                                            value: value,
                                                                            field: COMMENTS,
                                                                            selectedClientInventory: inventory
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                inventory.comments
                                                            }
                                                        </td>
                                                        <td>
                                                            {!inventory.isEnabledEdit ?
                                                                <IconButton
                                                                    iconProps={IconConstants.editicon}
                                                                    onClick={() => {
                                                                        this.props.changeClientInventory({
                                                                            value: "1",
                                                                            field: IS_ENABLED_EDIT,
                                                                            selectedClientInventory: inventory
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                <>
                                                                    <IconButton
                                                                        iconProps={IconConstants.cancelIcon}
                                                                        onClick={() => {
                                                                            this.loadInitData();
                                                                        }}
                                                                    />
                                                                    <IconButton
                                                                        iconProps={IconConstants.saveicon}
                                                                        onClick={() => {
                                                                            this.props.saveClientInventory({
                                                                                apiObject: this._ClientInventoryAPI,
                                                                                clientInventories: [inventory]
                                                                            })
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                            <IconButton
                                                                iconProps={IconConstants.deleteicon}
                                                                onClick={() => {
                                                                    this.props.openCloseClientInventoryConfirmDeletetModal({
                                                                        isOpenDeleteConfirmModal: true,
                                                                        selectedClientInventory: inventory
                                                                    });
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :
                                            <></>
                                        }
                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                    {
                        !alertJSON.hideAlert &&
                        <AlertRedux
                            {...this.props}
                        />
                    }
                    {
                        isOpenDeleteConfirmModal &&
                        <ClientInventoryDeleteConfirmModal
                            {...this.props}
                        />
                    }
                </LoadingOverlay>
            </>
        )
    }
}
