import { IFormBuilderKey, IQSFile } from "../../../CommonInterfaces/CommoInterfaces";
import { IAssetInitialState } from "../AssetInterface/IAsset";

export const AssetInitialState: IAssetInitialState = {
    isLoadingMainClientFilter: false,
    isLoadingMainSiteFilter: false,
    isLoadingAssetDashboard: false,
    isLoadingAssetDescriptionTab: false,
    isLoadingAssetAttributesTab: false,
    isLoadingAssetFilesTab: false,
    isLoadingAssetReportsTab: false,
    isLoadingAssetHistoryTab: false,
    isMultiSearch: false,
    selectedClientFilter: null,
    selectedSiteFilter: null,
    newAssetParentSite: null,
    selectedSiteFilters: [],
    selectedAssetId: -1,
    isOpenAddEditAssetPanel: false,
    selectedAssetUserData: null,
    selectedAssetFormAttributes: [] as IFormBuilderKey[],
    clients: [],
    sites: [],
    assets: [],
    assetGridDataState: {
        sort: [{ field: "name", dir: "asc" }],
        filter: { filters: [], logic: 'and' },
        skip: 0
    },
    processedAssets: [],
    assetTypes: [],
    selectedAssetDescription: {
        name: { value: "", error: true, errorMessage: "" },
        parentSite: null,
        assetType: null,
        endUserName: "",
        endUserAddress: "",
        externalId: "",
        acronymCode: ""
    },
    selectedAssetFiles: {
        fileList: [],
        image: null
    },
    observations: [],
    TabIndex: 0,
    responseStatusDetails: {
        errorFlag: false,
        statusMessage: ""
    },
    assetReportSortedColumn: {
        columnName: "",
        sortDesc: "asc"
    },
    assetReportMaxDataPerPage: 7,
    assetReportOffset: 0,
    alertJSON: {
        alertHeader: "",
        hideAlert: true,
        alertSubText: ""
    },
    hideShiftAssetDialogue: true,
    isUnderTaskDetailsView: false,
    selectedTask: null,
    selectedAssetOtherFiles: [] as IQSFile[],
    fileUploadDeleteStatus: {
        error: true,
        errorMessage: ""
    },
    hideConfirmArchive: true,
    deletedFile: null,
    issueDateRange: null,
    expiryDateRange: null
}
