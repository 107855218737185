import { ActionButton, IContextualMenuItem, IContextualMenuProps, INavLink, INavLinkGroup, Icon, IconButton, Image, Label, Nav, 
    SearchBox, Separator } from 'office-ui-fabric-react'
import * as React from 'react'
import { IconConstants, seperatorTheme } from '../../../../Constants/StylesConstants';
import { navStylesFiles } from '../../../../Constants/CustomStyles';
import { ITechnicalLibraryFile } from '../../../../CommonInterfaces/CommoInterfaces';
import { TrialFitPicLibraryAPI } from '../Service/TrialFitPicLibraryAPI';
import { ITrialFitPicLibraryAPI } from '../Interfaces/ITrialFitPicLibraryAPI';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import AlertRedux from '../../../Alert/AlertRedux';
import { IDocumentEntity } from '../Interfaces/IDocumentEntity';
import AddEditDocumentPanel from './AddEditDocumentPanel';
import { TrialFitPicLibraryInitialState } from '../Constants/TrialFitPicLibraryInitialState';
import EntityDeleteConfirmModal from './EntityDeleteConfirmModal';
import { TrialFitPicLibraryUtility } from '../Utility/TrialFitPicLibraryUtility';
import ClientSiteSelector from '../../../ClientSiteSelector/Views/ClientSiteSelector';

export default class TrialFitPicLibraryDashboard extends React.PureComponent<any, any> {

    private _apiPayload: ITrialFitPicLibraryAPI;

    public constructor(props: any) {
        super(props);
        this._apiPayload = new TrialFitPicLibraryAPI(window.API_URL, window.loggedInUserDetails);
    }

    public deleteEntity = (ev: any, document: IContextualMenuItem): void => {
        this.props.openCloseEntityDeleteConfirmModal({
            hideConfirmDelete: false,
            deletedEntity: document,
        })
    }

    public openAddEditFolderPanel = (ev: any, document: IContextualMenuItem): void => {
        let {
            parentLibrary,
            documentEntities
        } = this.props.TrialFitPicLibraryState;
        let selectedDocument = null;
        if (!document) {
            selectedDocument = TrialFitPicLibraryInitialState.selectedDocument;
            selectedDocument.entityType = "FOLDER";
            selectedDocument.category = "FOLDER";
            selectedDocument.parentId = parentLibrary.id;
            selectedDocument.tenantId = parentLibrary.tenantId;
        }
        else {
            selectedDocument = documentEntities.find((doc: IDocumentEntity) => `${doc.id}` === document.data.key);
        }
        this.props.openCloseAddEditDocumentPanel({
            sectionflag: "FOLDER",
            isShowAddEditDocumentPanel: true,
            selectedDocument: selectedDocument,
            panelHeaderText: !selectedDocument.name ? `Add New Folder` : `Edit ${selectedDocument.name}`,
        })
    }

    public openAddEditFilePanel = (ev: any, document: IContextualMenuItem): void => {
        let {
            fileEntities,
            parentLibrary
        } = this.props.TrialFitPicLibraryState;
        let selectedDocument: IDocumentEntity =
            fileEntities.find((doc: IDocumentEntity) => `${doc.id}` === document.data.key);
        if (!selectedDocument) {
            selectedDocument = TrialFitPicLibraryInitialState.selectedDocument;
            selectedDocument.entityType = "FILE";
            selectedDocument.category = "FILE";
            selectedDocument.parentId = parseInt(document.data.key);
            selectedDocument.tenantId = parentLibrary.tenantId;

        }
        this.props.openCloseAddEditDocumentPanel({
            sectionflag: "FILE",
            isShowAddEditDocumentPanel: true,
            selectedDocument: selectedDocument,
            panelHeaderText: !selectedDocument.name ? `Add New File` : `Edit ${selectedDocument.name}`,
        })
    }

    public getFileIconName = (fileType: string): string => {

        switch (fileType.toUpperCase()) {

            case ".PNG":
            case ".JPG":
                return IconConstants.imageIcon.iconName;

            case ".XLS":
            case ".XLSX":
            case ".CSV":
                return IconConstants.excelIcon.iconName;
            case ".DOC":
            case ".DOCX":
                return IconConstants.wordIcon.iconName;
            default:
                return IconConstants.fileIcon.iconName;
        }

    }

    public getImageView = (element: ITechnicalLibraryFile): JSX.Element => {
        return <Image
            className="collapse-image"
            src={element.url}
            alt=""
            width="100%"
            height="11em"
            onClick={() => {
                window.open(element.url, "_blank")
            }}
        />
    }

    public getFileBoxView = (element: ITechnicalLibraryFile): JSX.Element => {
        return <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 file-box" onClick={() => {
            window.open(element.url, "_blank")
        }}>
            {this.getFileBoxMenuIcon(element)}
            {this.getInnerFileView(element)}
            {this.getFileDetails(element)}
        </div>
    }

    public getInnerFileView = (element: ITechnicalLibraryFile): JSX.Element => {
        switch (element.fileType.toUpperCase()) {
            case ".PNG":
            case ".JPG":
                return this.getImageView(element);
            case ".PDF":
                return this.getPDFView(element);
            default:
                return this.getOtherFileView(element);
        }
    }

    public getPDFView = (element: ITechnicalLibraryFile): JSX.Element => {
        return <iframe
            id={element.key}
            title={element.fileName}
            src={element.url}
            className="iframe-library"
            frameBorder={0}
        />
    }

    public getOtherFileView = (element: ITechnicalLibraryFile): JSX.Element => {
        return <div className='file-icon' onClick={() => {
            window.open(element.url, "_blank")
        }}>
            <Icon iconName={this.getFileIconName(element.fileType)}></Icon>
        </div>
    }

    public getFileBoxMenuIcon = (element: ITechnicalLibraryFile): JSX.Element => {
        return <div className='file-box-menu-icon'>
            <IconButton
                text="Click for ContextualMenu"
                menuIconProps={IconConstants.verticalIcon}
                menuProps={this.getFileMenuProps(element)}

            />
        </div>
    }

    public getFileDetails = (element: ITechnicalLibraryFile): JSX.Element => {
        return <>
            <div className='ms-Grid-row file-border'></div>
            <div title={element.fileName} className='file-box-name'>{element.fileName}</div>
            <div className='file-box-size'>{Math.ceil(parseFloat(element.fileSize) / 1024)} kb</div>
        </>
    }

    public getFileMenuProps = (element: any): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: [
                {
                    key: 'Edit',
                    text: 'Edit',
                    iconProps: IconConstants.editicon,
                    data: element,
                    onClick: this.openAddEditFilePanel
                },
                {
                    key: 'Delete',
                    text: 'Delete',
                    data: element,
                    iconProps: IconConstants.deleteicon,
                    onClick: this.deleteEntity
                },
            ]
        }
    }

    public configureFileView = (files: ITechnicalLibraryFile[]) => {
        let fileContents = [];
        for (let index = 0; index < files.length; index += 3) {
            const firstElement = files[index];
            const secondElement = files[index + 1];
            const thirdElement = files[index + 2];
            fileContents.push(
                <div className="ms-Grid-row grid-image-row" key={index}>
                    {this.getFileBoxView(firstElement)}
                    {secondElement &&
                        this.getFileBoxView(secondElement)
                    }
                    {thirdElement &&
                        this.getFileBoxView(thirdElement)
                    }
                    <br />
                </div>
            )

        }
        return fileContents;
    }

    public render(): JSX.Element {

        let {
            isLoadingDocumentsList,
            documentEntities,
            alertJSON,
            isShowAddEditDocumentPanel,
            isShowFileSection,
            isLoadingFileList,
            fileEntities,
            selectedFolder,
            hideConfirmDelete,
            searchedFolderString,
            searchedFileString
        } = this.props.TrialFitPicLibraryState;

        const {
            selectedClient,
            selectedSite
        } = this.props.ClientSiteSelectorState;



        let files: ITechnicalLibraryFile[] = [];
        files = fileEntities.map((entity: IDocumentEntity) => {
            if (searchedFileString && entity.name.toUpperCase().indexOf(searchedFileString.toUpperCase()) > -1) {
                return {
                    key: `${entity.id}`,
                    fileName: entity.name + (entity.name.lastIndexOf(entity.fileType) === -1 ? entity.fileType : ""),
                    fileSize: entity.profilePhoto,
                    fileType: entity.fileType,
                    url: `${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${entity.filePath}`
                }
            } else if (!searchedFileString) {
                return {
                    key: `${entity.id}`,
                    fileName: entity.name + (entity.name.lastIndexOf(entity.fileType) === -1 ? entity.fileType : ""),
                    fileSize: entity.profilePhoto,
                    fileType: entity.fileType,
                    url: `${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${entity.filePath}`
                }
            }
            return null;
        })

        let links: INavLink[] = [];

        if (documentEntities && documentEntities.length > 0) {
            let filteredDocumentEntities: IDocumentEntity[] = [];
            if (!searchedFolderString) {
                filteredDocumentEntities = [...documentEntities];
            }
            else {
                filteredDocumentEntities = documentEntities.filter((entity: IDocumentEntity) =>
                    entity.name.toUpperCase().indexOf(searchedFolderString.toUpperCase()) > -1);
            }
            links = filteredDocumentEntities.map((document: IDocumentEntity) => {
                return {
                    name: document.name,
                    key: `${document.id}`,
                    icon: selectedFolder?.id === document.id ?
                        IconConstants.folderOpenIcon.iconName :
                        IconConstants.folderIcon.iconName,
                    isExpanded: true,
                    url: ""
                }
            });
        }

        const groups: INavLinkGroup[] = [
            {
                links: links
            }
        ];
        return (
            <>
                <ClientSiteSelector />
                {selectedClient && selectedSite &&
                    <LoadingOverlay
                        active={isLoadingDocumentsList}
                        spinner={<FadeLoader />}
                        text="Loading Documents...">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                    <SearchBox
                                        placeholder='Search Folder'
                                        value={searchedFolderString}
                                        onChange={(ev: any, newValue: string) => {
                                            this.props.changeSearchFolderString({
                                                searchedFolderString: newValue
                                            })
                                        }}
                                        onClear={(ev: any) => {
                                            this.props.changeSearchFolderString({
                                                searchedFolderString: ""
                                            })
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                                    <SearchBox
                                        placeholder='Search File'
                                        value={searchedFileString}
                                        autoComplete='off'
                                        disabled={!files?.length}
                                        onChange={(ev: any, newValue: string) => {
                                            this.props.changeSearchFileString({
                                                searchedFileString: newValue
                                            })
                                        }}
                                        onClear={(ev: any) => {
                                            this.props.changeSearchFileString({
                                                searchedFileString: ""
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12 flex-display">
                                            <Label>
                                                {TrialFitPicLibraryUtility.getDisplayName(window.location.hash.split("/")[2])}
                                            </Label>
                                            <ActionButton
                                                styles={{
                                                    root: {
                                                        height: "initial"
                                                    }
                                                }}
                                                iconProps={IconConstants.addFolderIcon}
                                                text='Add folder'
                                                onClick={() => this.openAddEditFolderPanel(null, null)}
                                            />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12 form-menu library-menu">
                                            <Nav
                                                ariaLabel="Library"
                                                selectedKey={`${selectedFolder?.id}`}
                                                styles={navStylesFiles}
                                                groups={groups}
                                                onLinkClick={(ev, link: INavLink) => {
                                                    const selectedFolder: IDocumentEntity = documentEntities
                                                        .find((entity: IDocumentEntity) => `${entity.id}` === link.key);
                                                    this.props.loadFiles({
                                                        apiObject: this._apiPayload,
                                                        parentLibrary: selectedFolder,
                                                        selectedFolder
                                                    })
                                                }}
                                                className='no-text-decoration'
                                                onRenderLink={(props) => (
                                                    <>
                                                        {!props.disabled ?
                                                            <ActionButton
                                                                styles={{
                                                                    root: {
                                                                        width: "100%"
                                                                    },
                                                                    flexContainer: {
                                                                        justifyContent: "space-between"
                                                                    }
                                                                }}
                                                                text={props.name}
                                                                menuIconProps={IconConstants.verticalIcon}
                                                                menuProps={{
                                                                    items: [
                                                                        {
                                                                            key: "Edit",
                                                                            data: props,
                                                                            text: 'Edit Folder',
                                                                            iconProps: IconConstants.editicon,
                                                                            onClick: this.openAddEditFolderPanel

                                                                        },
                                                                        {
                                                                            key: "Delete",
                                                                            data: props,
                                                                            text: 'Delete Folder',
                                                                            iconProps: IconConstants.deleteicon,
                                                                            onClick: this.deleteEntity
                                                                        },
                                                                        {
                                                                            key: "AddFile",
                                                                            data: props,
                                                                            text: 'Add New File(s)',
                                                                            iconProps: IconConstants.addFileIcon,
                                                                            onClick: this.openAddEditFilePanel
                                                                        }
                                                                    ]
                                                                }} />
                                                            :
                                                            <Label>{props.name}</Label>
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8">
                                    {isShowFileSection &&
                                        <>
                                            <Separator theme={seperatorTheme}>{selectedFolder.name}</Separator>
                                            <div className="show-file-section">
                                                <LoadingOverlay
                                                    active={isLoadingFileList}
                                                    spinner={<FadeLoader />}
                                                    text="Loading Files...">
                                                    {files?.length ?
                                                        this.configureFileView(files
                                                            .filter((file: any) => file !== null && file !== undefined))
                                                        :
                                                        <Label styles={{
                                                            root: {
                                                                fontSize: "24px",
                                                                textAlign: "center"
                                                            }
                                                        }}>No Files to Show</Label>
                                                    }
                                                </LoadingOverlay>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                    </LoadingOverlay >
                }
                {
                    isShowAddEditDocumentPanel &&
                    <AddEditDocumentPanel
                        {...this.props}
                    />
                }
                {
                    !hideConfirmDelete &&
                    <EntityDeleteConfirmModal
                        {...this.props}
                    />
                }
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </>
        )
    }
}
