import * as React from 'react'
import { Panel, PanelType, Spinner, SpinnerSize } from 'office-ui-fabric-react'
import AlertRedux from './../../../Alert/AlertRedux';
import AddEditFileSection from './AddEditFileSection';
import AddEditFolderSection from './AddEditFolderSection';

export default class AddEditDocumentPanel extends React.PureComponent<any, any> {

    _apiObject = null;
    _jobutility;

    public render(): JSX.Element {

        let {
            alertJSON,
            panelHeaderText,
            isShowAddEditDocumentPanel,
            isLoadingAddEditDocumentPanel,
            sectionflag
        } = this.props.TrialFitPicLibraryState;


        return (
            <Panel
                headerText={panelHeaderText}
                isOpen={isShowAddEditDocumentPanel}
                type={PanelType.medium}
                onDismiss={() => this.props.openCloseAddEditDocumentPanel({
                    isShowAddEditDocumentPanel: false,
                    selectedDocument: null,
                    panelHeaderText: ``,
                })}
                styles={{
                    scrollableContent: {
                        height: "95vh"
                    }
                }}
                closeButtonAriaLabel="Close">
                {isLoadingAddEditDocumentPanel ?
                    <div>
                        <Spinner
                            size={SpinnerSize.large}
                            label="Please wait..."
                        />
                    </div>
                    :
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            {sectionflag === "FOLDER" ?
                                <AddEditFolderSection {...this.props} />
                                :
                                <AddEditFileSection {...this.props} />
                            }
                        </div>
                    </div>
                }
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </Panel>
        )
    }
}
