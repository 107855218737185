import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import { IconConstants } from '../../../Constants/StylesConstants';

export class IdCustomCell extends React.Component<any, any> {
    render() {

        const value = this.props.dataItem;

        return (
            <td className="no-group-show">
                <Icon
                    onClick={() => {
                        this.props.currentProps.showHideAddEditSequenceNumberingPanel({
                            isShowSequenceAddEditPanel: true,
                            selectedSequence: value
                        })
                    }}
                    iconName={IconConstants.editicon.iconName}
                />
            </td>
        );
    }
}

