import { IDropdownOption } from "office-ui-fabric-react";
import { IKendoGridColumn } from "../../../CommonInterfaces/CommoInterfaces";

export const CLEAN_LOOSE_GEAR_DASHBOARD: string = 'CLEAN_LOOSE_GEAR_DASHBOARD';
export const CLEAN_LOOSE_GEAR_FILTER: string = 'CLEAN_LOOSE_GEAR_FILTER';
export const LOAD_LOOSE_GEAR_DASHBOARD: string = 'LOAD_LOOSE_GEAR_DASHBOARD';
export const SHOW_HIDE_LLG_DASHBOARD_COLUMNS: string = 'SHOW_HIDE_LLG_DASHBOARD_COLUMNS';
export const LOAD_CLIENTS_LOOSE_GEAR: string = 'LOAD_CLIENTS_LOOSE_GEAR';
export const LOAD_SITES_LOOSE_GEAR: string = 'LOAD_SITES_LOOSE_GEAR';
export const CHANGE_LOOSE_GEAR_CLIENT_FILTER: string = 'CHANGE_LOOSE_GEAR_CLIENT_FILTER';
export const CHANGE_LOOSE_GEAR_SITE_FILTER: string = 'CHANGE_LOOSE_GEAR_SITE_FILTER';
export const CHANGE_LOOSE_GEAR_DASHBOARD_FILTER: string = 'CHANGE_LOOSE_GEAR_DASHBOARD_FILTER';
export const OPEN_CLOSE_LOOSE_GEAR_ALERT_MODAL: string = 'OPEN_CLOSE_LOOSE_GEAR_ALERT_MODAL';
export const SHOW_HIDE_LOOSE_GEAR_ADD_EDIT_PANEL: string = 'SHOW_HIDE_LOOSE_GEAR_ADD_EDIT_PANEL';
export const HANDLE_LOOSE_GEAR_GRID_EXPAND_CHANGE: string = "HANDLE_LOOSE_GEAR_GRID_EXPAND_CHANGE";
export const HANDLE_LOOSE_GEAR_GRID_FILTER_CHANGE: string = "HANDLE_LOOSE_GEAR_GRID_FILTER_CHANGE";
export const HANDLE_LOOSE_GEAR_GRID_GROUP_SORT_CHANGE: string = "HANDLE_LOOSE_GEAR_GRID_GROUP_SORT_CHANGE";

export const GET_LOOSE_GEARS_LIST_URL: string = 'Observation/llg';
export const GET_LOOSE_GEARS_COUNT_URL: string = 'Observation/llg/count';

export const LOOSE_GEAR_BREADCRUMBS = [
    { text: 'RESOURCES', key: 'Resources' },
    { text: 'Loose Gear', key: 'LooseGear' }
];

export const LOOSE_GEAR_VIEW_GRID_COLUMNS: IKendoGridColumn[] = [
    {
        title: "Id",
        field: "id",
        show: window.origin === "http://localhost:3000",
        filter: "text",
        width: 150,
        colIndex: 1
    },
    {
        title: "Item",
        field: "taskTypeDesc",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 2
    },
    {
        title: "Serial Number",
        field: "llgSerialNumber",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 4
    },
    {
        title: "ID",
        field: "acronymCode",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 5
    },
    {
        title: "Specifications",
        field: "specification",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 3
    },
    {
        title: "Job Number",
        field: "jobNumber",
        show: false,
        filter: "text",
        width: 150,
        colIndex: 6
    },
    {
        title: "Last Result",
        field: "result",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 7
    },
    {
        title: "Inspection Date",
        field: "actualInspectionDate",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 8
    },
    {
        title: "Next Due",
        field: "dueDate",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 9
    },
    {
        title: "Certificate Number",
        field: "certificateNumber",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 10
    },
    {
        title: "Site",
        field: "site",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 11
    },
    {
        title: "Report",
        field: "certificateFileContent",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 12
    },
    {
        title: "Booklet",
        field: "bookletContent",
        show: true,
        filter: "text",
        width: 150,
        colIndex: 12
    },
    {
        title: "Current Status",
        field: "itemStatus",
        show: true,
        filter: "custom",
        width:150,
        colIndex: 14
    }
];

export const TASK_RESULT_DROPDOWN_FILTER_OPTIONS: IDropdownOption[] = [
    { key: 0, text: 'Last Result' },
    { key: 1, text: 'Accepted' },
    { key: 2, text: 'Rejected' },
];

export const TASK_STATUS_DROPDOWN_FILTER_OPTIONS: IDropdownOption[] = [
    { key: '0', text: 'All States' },
    { key: 'VALID', text: 'Valid' },
    { key: 'DUE SHORTLY', text: 'Due Shortly' },
    { key: 'EXPIRED', text: 'Expired' },
];