import { Image, TooltipHost } from 'office-ui-fabric-react';
import React, { Component } from 'react'
import { calloutProps, GRID_FILE_STYLES, hostStyles, tooltipId } from '../../../Constants/StylesConstants';
import { AssetAPI } from '../Services/AssetAPI';
import { IconConstants } from '../../../Constants/StylesConstants';
import { Label, Shimmer, Icon } from 'office-ui-fabric-react'
import AssetConfirmRedux from './AssetConfirmRedux';

export default class AssetFiles extends Component {

    _assetAPI = null;

    constructor(props) {
        super(props);
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);
    }

    onDropAssetFiles = (event) => {

        let files = event.target.files;
        let { selectedAssetFiles, selectedAssetId } = this.props.Asset;

        if (files.length > 0) {
            const uploadedFiles = new FormData();
            for (let index = 0; index < files.length; index++) {
                uploadedFiles.append(`locationFile`, files[index], files[index].name);
            }

            this.props.uploadAssetFiles({
                apiObject: this._assetAPI,
                uploadedFiles,
                selectedAssetFiles,
                selectedAssetId
            });
        }
    }

    configureFileView = (imageFiles) => {
        let fileContents = [];
        for (let index = 0; index < imageFiles.length; index += 3) {
            const firstElement = imageFiles[index];
            const secondElement = imageFiles[index + 1];
            const thirdElement = imageFiles[index + 2];
            fileContents.push(
                <div className="ms-Grid-row grid-image-row" key={index}>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                        <Image
                            src={`${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${firstElement.blobKey}`}
                            onClick={() => {
                                window.open(`${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${firstElement.blobKey}`, '_blank')
                            }}
                            alt="No Logo Available"
                            styles={GRID_FILE_STYLES}
                        />
                    </div>
                    {secondElement &&
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                            <Image
                                src={`${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${secondElement.blobKey}`}
                                onClick={() => {
                                    window.open(`${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${secondElement.blobKey}`, '_blank')
                                }}
                                alt="No Logo Available"
                                styles={GRID_FILE_STYLES}
                            />
                        </div>
                    }
                    {thirdElement &&
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                            <Image
                                src={`${window.API_URL}filedownload;jssessionid='${window.loggedInUserDetails.jsessionid}'?id=${thirdElement.blobKey}`}
                                onClick={() => {
                                    window.open(`${window.API_URL}filedownload;jssessionid='+${window.loggedInUserDetails.jsessionid}'?id=${thirdElement.blobKey}`, '_blank')
                                }}
                                alt="No Logo Available"
                                styles={GRID_FILE_STYLES}
                            />
                        </div>
                    }
                    <br />
                </div>
            )

        }
        return fileContents;
    }

    render() {
        let {
            selectedAssetFiles,
            isLoadingAssetFilesTab,
            selectedAssetOtherFiles,
            fileUploadDeleteStatus,
            hideConfirmArchive
        } = this.props.Asset;

        let allImageFiles = [];
        if (selectedAssetFiles.image) {
            allImageFiles.push(selectedAssetFiles.image);
        }

        // const listOfFiles = selectedAssetFiles.fileList?.concat(selectedAssetFiles.image ? selectedAssetFiles.image : [])
        const primaryPhoto = [].concat(selectedAssetFiles.image ? selectedAssetFiles.image : [])
        const listOfOtherPhotos = selectedAssetFiles.fileList;
        // const listOfOtherFiles = selectedAssetFiles.otherFiles;
        const attributePhotos = []
            .concat(selectedAssetFiles.userData?.records[0]?.qsUserDataFormData?.attribute_form_id[0]?.attributesFiles.images)
            .filter(attributesFile => attributesFile !== undefined);

        allImageFiles = [...allImageFiles, ...selectedAssetFiles.fileList.filter(
            file => (file?.fileName?.toUpperCase().endsWith(".JPG") || file?.fileName?.toUpperCase().endsWith(".PNG"))
        ), ...attributePhotos];
        const loggedInUser = window.loggedInUserDetails;

        return (
            <>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {selectedAssetFiles &&
                                <>
                                    {primaryPhoto.length ?
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Label className='info-span'>Primary Photo</Label>

                                                {isLoadingAssetFilesTab ?
                                                    <Shimmer
                                                        styles={{
                                                            root: {
                                                                width: "10em"
                                                            },
                                                            shimmerWrapper: {
                                                                height: "10em",
                                                            }
                                                        }} />
                                                    :
                                                    <>
                                                        <div>
                                                            {primaryPhoto?.map((file, index) => {
                                                                return (
                                                                    <div className='file-list-view' key={index} onClick={() => {
                                                                        window.open(
                                                                            `${window.API_URL}filedownload;jssessionid='+${loggedInUser.jsessionid}'?id=${file.blobKey}`,
                                                                            "_blank"
                                                                        );
                                                                    }}>
                                                                        <Icon iconName={file.fileType.toUpperCase() === "PDF" ?
                                                                            IconConstants.pdfIcon.iconName : IconConstants.imageIcon.iconName}></Icon> {file.fileName}
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {listOfOtherPhotos.length ?
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Label className='info-span'>Other Photos</Label>

                                                {isLoadingAssetFilesTab ?
                                                    <Shimmer
                                                        styles={{
                                                            root: {
                                                                width: "10em"
                                                            },
                                                            shimmerWrapper: {
                                                                height: "10em",
                                                            }
                                                        }} />
                                                    :
                                                    <>
                                                        <div>
                                                            {listOfOtherPhotos?.map((file, index) => {
                                                                return (
                                                                    <div className='file-list-view' key={index} onClick={() => {
                                                                        window.open(
                                                                            `${window.API_URL}filedownload;jssessionid='+${loggedInUser.jsessionid}'?id=${file.blobKey}`,
                                                                            "_blank"
                                                                        );
                                                                    }}>
                                                                        <Icon iconName={file.fileType.toUpperCase() === "PDF" ?
                                                                            IconConstants.pdfIcon.iconName : IconConstants.imageIcon.iconName}></Icon> {file.fileName}
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {/* {listOfOtherFiles.length ?
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Label className='info-span'>File Attachments</Label>

                                                {isLoadingAssetFilesTab ?
                                                    <Shimmer
                                                        styles={{
                                                            root: {
                                                                width: "10em"
                                                            },
                                                            shimmerWrapper: {
                                                                height: "10em",
                                                            }
                                                        }} />
                                                    :
                                                    <>
                                                        <div>
                                                            {listOfOtherFiles?.map((file, index) => {
                                                                return (
                                                                    <div className='file-list-view' key={index} onClick={() => {
                                                                        window.open(
                                                                            `${window.API_URL}filedownload;jssessionid='+${loggedInUser.jsessionid}'?id=${file.blobKey}`,
                                                                            "_blank"
                                                                        );
                                                                    }}>
                                                                        <Icon iconName={file.fileType.toUpperCase() === "PDF" ?
                                                                            IconConstants.pdfIcon.iconName : IconConstants.imageIcon.iconName}></Icon> {file.fileName}
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                    } */}
                                    {attributePhotos.length ?
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Label className='info-span'>Attribute Photos</Label>

                                                {isLoadingAssetFilesTab ?
                                                    <Shimmer
                                                        styles={{
                                                            root: {
                                                                width: "10em"
                                                            },
                                                            shimmerWrapper: {
                                                                height: "10em",
                                                            }
                                                        }} />
                                                    :
                                                    <>
                                                        <div>
                                                            {attributePhotos?.map((file, index) => {
                                                                return (
                                                                    <div className='file-list-view' key={index} onClick={() => {
                                                                        window.open(
                                                                            `${window.API_URL}filedownload;jssessionid='+${loggedInUser.jsessionid}'?id=${file.blobKey}`,
                                                                            "_blank"
                                                                        );
                                                                    }}>
                                                                        <Icon iconName={file.fileType.toUpperCase() === "PDF" ?
                                                                            IconConstants.pdfIcon.iconName : IconConstants.imageIcon.iconName}></Icon> {file.fileName}
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </>
                            }
                            {isLoadingAssetFilesTab ?
                                <Shimmer
                                    styles={{
                                        root: {
                                            width: "10em"
                                        },
                                        shimmerWrapper: {
                                            height: "5em",
                                        }
                                    }} /> :
                                <>
                                    {selectedAssetOtherFiles?.length > 0 &&
                                        <>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <Label className='info-span'>File Attachments</Label>
                                                </div>
                                            </div>
                                            {selectedAssetOtherFiles?.map((file, index) => {
                                                return (
                                                    <div className="ms-Grid-row" key={index}>
                                                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg4">
                                                            <div className='file-list-view' onClick={() => {
                                                                window.open(
                                                                    `${window.API_URL}filedownload;jssessionid='+${loggedInUser.jsessionid}'?id=${file.blobKey}`,
                                                                    "_blank"
                                                                );
                                                            }}>
                                                                <Icon iconName={file.fileType.toUpperCase() === "PDF" ?
                                                                    IconConstants.pdfIcon.iconName : IconConstants.imageIcon.iconName}>
                                                                </Icon> {file.fileName}
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg8">
                                                            <TooltipHost
                                                                content={`Archive`}
                                                                id={tooltipId}
                                                                calloutProps={calloutProps}
                                                                styles={hostStyles}
                                                            >
                                                                <Icon
                                                                    styles={{
                                                                        root: {
                                                                            cursor: "Pointer"
                                                                        }
                                                                    }}
                                                                    iconName={IconConstants.archiveIcon.iconName}
                                                                    onClick={() => {
                                                                        this.props.openCloseConfirmModal({
                                                                            hideConfirm: false,
                                                                            deletedFile: file
                                                                        })
                                                                    }}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>


                                    }
                                    <input
                                        type="file"
                                        multiple
                                        onChange={this.onDropAssetFiles}
                                        accept="image/png, image/jpeg, application/pdf,.doc,.docx,.xls,.xlsx,.csv"
                                    />
                                    <span className={`${fileUploadDeleteStatus?.error ? "mandaory-span" : "success-span"}`}>
                                        {fileUploadDeleteStatus?.errorMessage}
                                    </span>
                                </>
                            }
                        </div>
                    </div>
                    {this.configureFileView(allImageFiles)}
                </div>
                {
                    !hideConfirmArchive &&
                    <AssetConfirmRedux {...this.props} />
                }
            </>
        )
    }
}
