import React, { Component } from 'react'
import { TextField, Label, Shimmer, Icon } from 'office-ui-fabric-react'
import Select from 'react-select';
import { JobAPI } from '../Services/JobAPI';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { FIELD_ENGG_ROLE } from '../../../Constants/CommonConstants';
import { IconConstants } from '../../../Constants/StylesConstants';

export default class SiteDetails extends Component {
    _apiObject = null;
    constructor(props) {
        super(props);
        let config = {
            headers: {}
        }
        this._apiObject = new JobAPI(window.API_URL, config)
    }

    onDropJobFiles = (event) => {

        let files = event.target.files;
        let { SelectedJob } = this.props.Job;

        if (files.length > 0) {
            const uploadedFiles = new FormData();
            for (let index = 0; index < files.length; index++) {
                uploadedFiles.append(`scheduleFile`, files[index], files[index].name);
            }

            this.props.uploadJobFiles({
                apiObject: this._apiObject,
                uploadedFiles,
                SelectedJob
            });
        }
    }

    render() {
        let {
            AllInspectorOptions,
            CurrentJobDetails,
            SelectedJob,
            isLoadingJobFiles,
            Roles
        } = this.props.Job;

        let filteredInspectorOptions = [];
        const loggedInUser = window.loggedInUserDetails;

        let currentUserDivisionIds = loggedInUser.divisionIds ? loggedInUser.divisionIds.split(",") : [];
        let primaryOfficeId = CurrentJobDetails.AssignOffice.value.value;

        if (AllInspectorOptions && AllInspectorOptions.length > 0 && currentUserDivisionIds.length > 0) {
            [...AllInspectorOptions.filter(d => d.divisionIds)].forEach((inspector) => {
                let inspectorDivisionIds = inspector.divisionIds.split(",");
                //find common divisions
                let filteredArray = inspectorDivisionIds.filter(value => currentUserDivisionIds.includes(value));

                let officeIdArray = inspector.secondaryOfficeIds.split`,`.map(officeId => +officeId).concat(inspector.officeId);


                if (filteredArray.length > 0 && officeIdArray.indexOf(primaryOfficeId) > -1) {
                    filteredInspectorOptions.push(inspector);
                }
            })
        }

        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <TextField
                            label="Comments"
                            value={CurrentJobDetails.hasOwnProperty("Comments") ? CurrentJobDetails.Comments.value : ""}
                            onChange={(event, newval) => {
                                this.props.changeComments({
                                    Comments: newval
                                });
                            }}
                            multiline={true}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label>Main Inspector <span className="mandatory-span">*</span></Label>
                        <Select
                            value={CurrentJobDetails.hasOwnProperty("MainInspector") ? CurrentJobDetails.MainInspector.value : null}
                            onChange={(newval) => {
                                this.props.changeMainInspector({
                                    MainInspector: newval
                                });
                            }}
                            disabled={CurrentJobDetails && loggedInUser.role !== FIELD_ENGG_ROLE}
                            options={
                                CommonUtility.sortByColumn(filteredInspectorOptions.filter((element) =>
                                (element.type === 0 &&
                                    Roles.filter(d => d.name === FIELD_ENGG_ROLE || d.name === "FIELD-JUNIOR").map(x => x.id).indexOf(element.roleId) > -1)
                                ), "label", "asc")
                            }
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label>Other Inspectors</Label>
                        <Select
                            isClearable
                            isMulti
                            disabled={CurrentJobDetails && loggedInUser.role !== FIELD_ENGG_ROLE}
                            value={CurrentJobDetails.hasOwnProperty("Inspectors") ? CurrentJobDetails.Inspectors.value : null}
                            onChange={(newval) => {
                                this.props.changeInspectors({
                                    Inspectors: newval
                                });
                            }}
                            options={
                                CommonUtility.sortByColumn(filteredInspectorOptions.filter(
                                    (element) =>
                                    (element.type === 0 &&
                                        Roles.filter(d => d.name === "FIELD-ENGG" || d.name === "FIELD-JUNIOR").map(x => x.id).indexOf(element.roleId) > -1)
                                ), "label", "asc")
                            }
                            closeMenuOnSelect={false}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label>Vendor Name &amp; Address</Label>
                        <TextField
                            multiline
                            max={200}
                            value={CurrentJobDetails.hasOwnProperty("VendorDetails") ? CurrentJobDetails.VendorDetails.value : null}
                            onChange={(ev, newval) => {
                                this.props.changeVendorDetails({
                                    VendorDetails: newval
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label>Sub-Vendor Name &amp; Address</Label>
                        <TextField
                            multiline
                            max={200}
                            value={CurrentJobDetails.hasOwnProperty("SubVendorDetails") ? CurrentJobDetails.SubVendorDetails.value : null}
                            onChange={(ev, newval) => {
                                this.props.changeSubVendorDetails({
                                    SubVendorDetails: newval
                                });
                            }}
                        />
                    </div>
                </div>
                {SelectedJob &&
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Label>Job Files</Label>

                            {isLoadingJobFiles ?
                                <Shimmer
                                    styles={{
                                        root: {
                                            width: "10em"
                                        },
                                        shimmerWrapper: {
                                            height: "10em",
                                        }
                                    }} />
                                :
                                <>
                                    <div>
                                        {CurrentJobDetails.otherFiles.files?.map((file, index) => {
                                            return (
                                                <div className='file-list-view' key={index} onClick={() => {
                                                    window.open(
                                                        `${window.API_URL}filedownload;jssessionid='+${loggedInUser.jsessionid}'?id=${file.blobKey}`,
                                                        "_blank"
                                                    );
                                                }}>
                                                    <Icon iconName={file.fileType.toUpperCase() === "PDF" ?
                                                        IconConstants.pdfIcon.iconName : IconConstants.imageIcon.iconName}></Icon> {file.fileName}
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={this.onDropJobFiles}
                                        accept="image/png, image/jpeg, application/pdf,.doc,.docx,.xls,.xlsx,.csv"
                                    />
                                </>
                            }
                            <span className="info-span">
                                {CurrentJobDetails.otherFiles ? CurrentJobDetails.otherFiles.uploadFilesErrorText : ``}
                            </span>
                        </div>
                    </div>
                }
            </div>

        )
    }
}
