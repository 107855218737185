import { ILoOfficeLocation } from "../LoOfficeInterface/ILoOffice";
import moment from "moment-timezone";
export class LoOfficeUtility {
    

    public resetLoOffice = (): ILoOfficeLocation => {
        return {
            id: -1,
            name: "",
            street: "",
            city: "",
            state: "",
            country: "",
            pin: 0,
            phone: "",
            acronymCode: ""
        }
    }

    generateLoOfficeJSONPayLoad = (LoOfficeDetails: ILoOfficeLocation) => {
        let payloadJSON = {};
        if (LoOfficeDetails.id === -1) {
          payloadJSON = {
            object_type: "LO_OFFICE",
            name: LoOfficeDetails.name,
            status: 1,
            created_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            modified_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            created_by: null,
            last_modified_by: null,
            display_name: LoOfficeDetails.name,
            acronymCode: LoOfficeDetails.acronymCode,
          };
        } else {
          payloadJSON = {
            id: LoOfficeDetails.id,
            object_type: "LO_OFFICE",
            name: LoOfficeDetails.name,
            status: 1,
            created_date: moment(LoOfficeDetails.created_date).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            modified_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            created_by: null,
            last_modified_by: null,
            display_name: LoOfficeDetails.name,
            acronym_code: LoOfficeDetails.acronymCode,
            archived: 0,

          };
        }
    
        return payloadJSON;
      };

    public static isInvalidLoOfficeForm = (AddLoOfficeDetails: ILoOfficeLocation): boolean => {

        let isInvalid: boolean = false;

        isInvalid = (!AddLoOfficeDetails.name || !AddLoOfficeDetails.street);

        return isInvalid;
    }
}