import React, { Component } from 'react'
import { Label, Panel, ActionButton, PanelType, PrimaryButton, DefaultButton, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react'
import AlertRedux from '../../Alert/AlertRedux';
import { IconConstants } from '../../../Constants/StylesConstants';
import { LoOfficeAPI } from '../Services/LoOfficeAPI';
import { LoOfficeUtility } from '../Utility/LoOfficeUtility';
import { LoOfficeInitialState } from '../Constants/LoOfficeInitialState';

export default class AddEditLoOfficePopUp extends Component {

    _apiObject = null;
    _loOfficeUtility;
    constructor(props) {
        super(props);

        this._apiObject = new LoOfficeAPI(window.API_URL, window.loggedInUserDetails);
        this._loOfficeUtility = new LoOfficeUtility();
    }

    saveLoOffice = () => {

        let { IsLoOfficeEdit, SelectedLoOfficeLocation } = this.props.LoOffice;

        this.props.saveLoOfficeLocationAsync({
            apiObject: this._apiObject,
            IsLoOfficeEdit: IsLoOfficeEdit,
            SelectedLoOfficeLocation: SelectedLoOfficeLocation
        });

    }

    closePopUp = () => {
        this.props.showHideAddEditLoOfficePopUp({
            ShowHideAddEditLoOfficePopUp: false,
            SelectedLoOfficeLocation: LoOfficeInitialState.SelectedLoOfficeLocation,
        })
    }

    render() {

        let {
            LoadingAddEditLoOfficePopUp,
            ShowAddEditLoOfficePopUp,
            alertJSON,
            SelectedLoOfficeLocation
        } = this.props.LoOffice;

        let PanelHeaderText = SelectedLoOfficeLocation?.id ? `Edit: ${SelectedLoOfficeLocation.name}` 
        : `Add New Office`

        let IsSaveDisbaled = !SelectedLoOfficeLocation?.name || !SelectedLoOfficeLocation?.street;

        return (
            <Panel
                headerText={PanelHeaderText}
                isOpen={ShowAddEditLoOfficePopUp}
                type={PanelType.medium}
                onDismiss={() => this.closePopUp()}
                closeButtonAriaLabel="Close">
                {!LoadingAddEditLoOfficePopUp &&
                    <div className="ms-Grid" dir="ltr">

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ActionButton
                                    text="Save"
                                    disabled={IsSaveDisbaled}
                                    iconProps={IconConstants.saveicon}
                                    onClick={() => {
                                        this.saveLoOffice();
                                    }}
                                />
                                <ActionButton
                                    text="Cancel"
                                    onClick={() => this.closePopUp()}
                                    iconProps={IconConstants.cancelIcon}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className="ms-Grid" dir="ltr">
                    {LoadingAddEditLoOfficePopUp ?
                        <Spinner
                            label="Please Wait..."
                            size={SpinnerSize.large}
                        />
                        :
                        <React.Fragment>
                            {/* Input Field */}
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.name}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficeName",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label={"Lo Office Name:"}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label>
                                            {`Lo Office Address`}: <span className="mandatory-span">*</span>
                                        </Label>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.street}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficeStreet",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label="House No. &amp; Street:"
                                            required
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.city}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficeCity",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label="City:"
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.state}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficeState",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label="State:"
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.country}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficeCountry",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label="Country:"
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.pin}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficePin",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label="Pin:"
                                            type="number"
                                            min={1}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.phone}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficePhone",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label="Phone:"
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.acronymCode}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficeCode",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label="Code:"
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            value={SelectedLoOfficeLocation?.externalId}
                                            onChange={(ev, newVal) => {
                                                this.props.changeLoOfficeDetails({
                                                    loOfficeFieldType: "LoOfficeExternalId",
                                                    loOfficeFieldValue: newVal
                                                })
                                            }}
                                            label="External Id:"
                                        />
                                    </div>
                                </div>
                            </div>
                            <br />

                            {/* Button */}
                            <div className="ms-Grid" dir="ltr">

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <PrimaryButton
                                            text="Save"
                                            disabled={IsSaveDisbaled}
                                            menuIconProps={IconConstants.saveicon}
                                            onClick={() => {
                                                this.saveLoOffice()
                                            }}
                                        />
                                        {` `}
                                        <DefaultButton
                                            text="Cancel"
                                            onClick={() => this.closePopUp()}
                                            menuIconProps={IconConstants.cancelIcon}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </Panel >
        )
    }
}
