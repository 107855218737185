import { ISelect } from "../../../CommonInterfaces/CommoInterfaces";
import { ILooseGear } from "../Interfaces/ILooseGear";
import { ILooseGearState } from "../Interfaces/ILooseGearState";

export const LooseGearInitialState: ILooseGearState = {
    isLoadingLooseGearDashboard: false,
    isLoadingLooseGearAddEditPanel: false,
    isShowLooseGearAddEditPanel: false,
    isLoadingLooseGearClients: false,
    isLoadingLooseGearSites: false,
    looseGearClientFilters: [] as ISelect[],
    looseGearSiteFilters: [] as ISelect[],
    selectedLooseGearClient: null,
    selectedLooseGearSite: null,
    looseGears: [] as ILooseGear[],
    selectedLooseGear: null,
    LooseGearGridDataState: {
        sort: [{ field: "taskTypeName", dir: "asc" }],
        filter: { filters: [], logic: 'and' },
        // group: [{ field: "officeName", dir: 'desc' }],
    },
    proccessedLooseGears: [],
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    offset: 0,
    limit: 10,
    looseGearsCount: 0,
    filterLLGSerialNumber: "",
    filterCertificate: "",
    filterTaskTypeName: "",
    filterJobNumber: "",
    filterAcronymCode: "",
    filterStatus: "",
    filterSpecifications: "",
    filterFromInspectionDate: null,
    filterToInspectionDate: null,
    filterFromDueDate: null,
    filterToDueDate: null,
    filterResult: 0,
    showColumns: [
        'taskTypeDesc',
        'specification',
        'llgSerialNumber',
        'acronymCode',
        'result',
        'actualInspectionDate',
        'dueDate',
        'certificateNumber',
        'site',
        'certificateFileContent',
        'bookletContent',
        'itemStatus'
    ],
}
