import * as CONSTANTS from "../Constants/ConfigConstants";
import { ConfigUtility } from "../Utility/ConfigUtility";
import { Dispatch } from "redux";

export class ConfigActions {

    cleanConfigList = (payload) => {
        return { type: CONSTANTS.CLEAN_CONFIG_LIST, payload }
    }

    loadConfigList = (payload) => {
        return (dispatch: Dispatch) => {
            payload.isOngoingLoading = true;
            dispatch(this.cleanConfigList(payload));
            payload.apiObject.getAllConfigs(payload.tenantId, payload.objectType)
                .then((response) => {
                    payload.isOngoingLoading = false;
                    payload.Configs = response.data;
                    dispatch(this.loadConfigListAsync(payload));
                }).catch((error) => {
                    console.error("Error: ", error);
                    payload.hideAlert = false;
                    payload.alertHeader = "Ooops!!";
                    payload.alertSubText = "Error while loading Configs";
                    dispatch(this.openCloseAlertModal(payload));

                    setTimeout(() => {
                        payload.hideAlert = true;
                        payload.alertHeader = "";
                        payload.alertSubText = "";
                        dispatch(this.openCloseAlertModal(payload));
                    }, 2000);
                })
        }
    }

    loadConfigListAsync = (payload) => {
        return { type: CONSTANTS.LOAD_CONFIG_LIST, payload };
    }

    saveConfigDetails = (payload) => {
        return { type: CONSTANTS.SAVE_CONFIG_DATA, payload };
    }

    saveConfigDetailsAsync = (payload) => {
        return dispatch => {
            payload.isOngoingSave = true;
            dispatch(this.saveConfigDetails(payload));

            let utility = new ConfigUtility();
            let configPayload = utility.generateConfigJSONPayLoad(payload.CurrentConfig);

            payload.apiObject.saveConfigData(configPayload).then(() => {
                payload.isOngoingSave = false;
                dispatch(this.saveConfigDetails(payload))
                payload.hideAlert = false;
                payload.alertHeader = "Success !!";
                payload.alertSubText = "Configuration saved successfully";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                    payload.isOngoingLoading = true;
                    payload.tenantId = -1;
                    payload.objectType = "ConfigData";
                    dispatch(this.loadConfigList(payload));
                }, 2000);

            }).catch((error) => {
                console.error("Error: ", error);

                payload.hideAlert = false;
                payload.alertHeader = "Error !!";
                payload.alertSubText = "Error while saving Config Details";
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.hideAlert = true;
                    payload.alertHeader = "";
                    payload.alertSubText = "";
                    dispatch(this.openCloseAlertModal(payload));
                }, 2500);

            })
        }
    }

    selectConfig = (payload) => {
        return { type: CONSTANTS.SELECT_CONFIG, payload };
    }

    addConfigData = (payload) => {
        return { type: CONSTANTS.ADD_CONFIG_DATA, payload };
    }

    changeConfigJson = (payload) => {
        return { type: CONSTANTS.CHANGE_CONFIG_JSON, payload };
    }

    changeConfigName = (payload) => {
        return { type: CONSTANTS.CHANGE_CONFIG_NAME, payload };
    }

    clearConfigData = (payload) => {
        return { type: CONSTANTS.CLEAR_CONFIG_DATA, payload };
    }

    openCloseAlertModal = (payload) => {
        return { type: CONSTANTS.OPEN_CLOSE_CONFIGURATION_ALERT_MODAL, payload };
    }

}