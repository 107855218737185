import axios from 'axios';
import { ILoggedInUser } from '../../../CommonInterfaces/CommoInterfaces';
import * as CONSTANTS from "../Constants/LoOfficeConstants";
import { ALERT_MESSAGE } from '../../../Constants/MessageConstants';
import { ILoOfficeService, ILoOfficeLocation } from './../LoOfficeInterface/ILoOffice';

export class LoOfficeAPI implements ILoOfficeService {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }
    
    _getChunkLoOffice(offset: number, limit: number, objectType: string, config: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.LOAD_LO_OFFICE_LOCATION_DATA_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=${objectType}&`)
                , config
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getChunkLoOffice method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    loadInitialData(offset: number, limit: number, objectType: string, config: number, parentId?: number): Promise<any> {
        let promises = [];
        promises.push(this._getChunkLoOffice(offset, limit, objectType, config));
        return Promise.all(promises);

    }

    saveLoOfficeLocation(loOfficelocationPayload: ILoOfficeLocation): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(CONSTANTS.ADD_NEW_LO_OFFICE_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                , loOfficelocationPayload
            ).then((response) => {
                resolve(response);
            }).catch((error: any) => {
                console.error("Error saveLocation method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    deleteLoOfficeLocation = (payload) => {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(CONSTANTS.GET_LO_OFFICE_DELETE_URL)
                .concat(`/${payload.id}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`))
                .then((response) => {
                    resolve(response);
                }).catch((error) => {
                    console.error("Error deleteLoOfficeLocation", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

}