import { LooseGearInitialState } from "../Constants/LooseGearInitialState";
import * as CONSTANTS from "../Constants/LooseGearsConstants";
import { ILooseGearState } from "../Interfaces/ILooseGearState";
import { ILooseGearActionKeys } from "../Interfaces/ILooseGearActions";
import { ILooseGearPayload } from "../Interfaces/ILooseGearPayload";
import { LooseGearUtility } from "../Utility/LooseGearUtility";
import { AssetUtility } from "../../Assets/Utility/AssetUtility";

export function LooseGearReducer(state: ILooseGearState = LooseGearInitialState, action: ILooseGearActionKeys): ILooseGearState {

    let tempState: ILooseGearState = { ...state };
    let payload: ILooseGearPayload = action.payload;
    let utility: LooseGearUtility = new LooseGearUtility();
    let assetUtility: AssetUtility = new AssetUtility();
    let newLooseGearGridDataState = { ...tempState.LooseGearGridDataState };

    switch (action.type) {

        case CONSTANTS.CLEAN_LOOSE_GEAR_DASHBOARD:
            tempState.isLoadingLooseGearClients = true;
            tempState.LooseGearGridDataState = {
                sort: [{ field: "LooseGearName", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                // group: [{ field: "officeName", dir: 'desc' }],
                skip: 0
            };
            tempState.looseGears = [];
            tempState.proccessedLooseGears = [];
            tempState.selectedLooseGearClient = null;
            tempState.selectedLooseGearSite = null;
            tempState.filterCertificate = "";
            tempState.filterTaskTypeName = "";
            tempState.filterLLGSerialNumber = "";
            tempState.filterJobNumber = "";
            tempState.filterFromInspectionDate = null;
            tempState.filterToInspectionDate = null;
            tempState.filterFromDueDate = null;
            tempState.filterToDueDate = null;
            tempState.offset = 0;
            break;

        case CONSTANTS.CLEAN_LOOSE_GEAR_FILTER:
            tempState.looseGears = [];
            tempState.proccessedLooseGears = [];
            tempState.filterCertificate = "";
            tempState.filterTaskTypeName = "";
            tempState.filterLLGSerialNumber = "";
            tempState.filterJobNumber = "";
            tempState.filterFromInspectionDate = null;
            tempState.filterToInspectionDate = null;
            tempState.filterFromDueDate = null;
            tempState.filterToDueDate = null;
            tempState.offset = 0;
            break;


        case CONSTANTS.SHOW_HIDE_LLG_DASHBOARD_COLUMNS:
            let tempColumns = [...tempState.showColumns];
            let indexOfSelectedColumn: number = tempColumns.indexOf(payload.selectedColumn.key.toString());
            if (indexOfSelectedColumn > -1) {
                tempColumns.splice(indexOfSelectedColumn, 1);
            }
            else {
                tempColumns.push(payload.selectedColumn.key.toString());
            }
            tempState.showColumns = tempColumns;
            break;

        case CONSTANTS.LOAD_CLIENTS_LOOSE_GEAR:
            tempState.isLoadingLooseGearClients = payload.isLoading;
            if (!payload.isLoading) {
                tempState.looseGearClientFilters = assetUtility.loadSelectDropDownValue([...payload.locationFilters]);
            }
            break;

        case CONSTANTS.CHANGE_LOOSE_GEAR_CLIENT_FILTER:
            tempState.isLoadingLooseGearSites = payload.isLoading;
            tempState.looseGears = [];
            tempState.proccessedLooseGears = [];
            tempState.filterCertificate = "";
            tempState.filterTaskTypeName = "";
            tempState.filterLLGSerialNumber = "";
            tempState.filterJobNumber = "";
            tempState.filterSpecifications = "";
            tempState.filterFromInspectionDate = null;
            tempState.filterToInspectionDate = null;
            tempState.filterFromDueDate = null;
            tempState.filterToDueDate = null;
            tempState.looseGearSiteFilters = [];
            tempState.selectedLooseGearClient = payload.selectedLocation;
            tempState.selectedLooseGearSite = null;
            tempState.offset = 0;
            break;

        case CONSTANTS.CHANGE_LOOSE_GEAR_SITE_FILTER:
            tempState.isLoadingLooseGearDashboard = payload.isLoading;
            tempState.looseGears = [];
            tempState.proccessedLooseGears = [];
            tempState.filterCertificate = "";
            tempState.filterTaskTypeName = "";
            tempState.filterLLGSerialNumber = "";
            tempState.filterJobNumber = "";
            tempState.filterSpecifications = "";
            tempState.filterFromInspectionDate = null;
            tempState.filterToInspectionDate = null;
            tempState.filterFromDueDate = null;
            tempState.filterToDueDate = null;
            tempState.selectedLooseGearSite = payload.selectedLocation;
            tempState.offset = 0;
            break;

        case CONSTANTS.LOAD_SITES_LOOSE_GEAR:
            tempState.isLoadingLooseGearSites = payload.isLoading;
            tempState.looseGearSiteFilters = assetUtility.loadSelectDropDownValue([...payload.locationFilters]);
            break;

        case CONSTANTS.LOAD_LOOSE_GEAR_DASHBOARD:
            tempState.isLoadingLooseGearDashboard = payload.isLoading;
            if (!payload.isLoading) {
                tempState.looseGears = [...payload.looseGears];
                tempState.offset += payload.offset;
                tempState.looseGearsCount = payload.looseGearsCount;
                tempState.proccessedLooseGears = utility.processLooseGearsForKendoGrid([...tempState.looseGears], newLooseGearGridDataState);
            }
            break;

        case CONSTANTS.HANDLE_LOOSE_GEAR_GRID_GROUP_SORT_CHANGE:
            newLooseGearGridDataState.sort = payload.event.dataState.sort;
            let indexOfId = -1;
            indexOfId = payload.event.dataState.group?.findIndex((d: any) => d.field === "officeName");
            if (
                (indexOfId > -1 && payload.event.dataState.group?.length === 1) ||
                payload.event.dataState.group?.length === 0
            ) {
                newLooseGearGridDataState.group = payload.event.dataState.group;
            }
            tempState.LooseGearGridDataState = newLooseGearGridDataState;
            tempState.proccessedLooseGears = utility.processLooseGearsForKendoGrid(tempState.looseGears, newLooseGearGridDataState);
            break;

        case CONSTANTS.OPEN_CLOSE_LOOSE_GEAR_ALERT_MODAL:
            tempState.alertJSON = payload.alertJSON;
            break;

        case CONSTANTS.SHOW_HIDE_LOOSE_GEAR_ADD_EDIT_PANEL:
            tempState.isShowLooseGearAddEditPanel = payload.isShowLooseGearAddEditPanel;
            tempState.isLoadingLooseGearAddEditPanel = false;
            tempState.selectedLooseGear = payload.isShowLooseGearAddEditPanel ? payload.selectedLooseGear : null;
            break;

        case CONSTANTS.CHANGE_LOOSE_GEAR_DASHBOARD_FILTER:
            tempState.isLoadingLooseGearDashboard = payload.isLoading && !payload.isNoLoad;
            tempState.looseGears = payload.isNoLoad ? tempState.looseGears : [];
            tempState.LooseGearGridDataState = payload.isNoLoad ? tempState.LooseGearGridDataState : {
                sort: [{ field: "LooseGearName", dir: "asc" }],
                filter: { filters: [], logic: 'and' },
                // group: [{ field: "officeName", dir: 'desc' }],
            };
            tempState.proccessedLooseGears = payload.isNoLoad ? tempState.proccessedLooseGears : [];
            tempState.offset = payload.isNoLoad ? tempState.offset : payload.offset;
            switch (payload.filterType) {
                case "taskType":
                    tempState.filterTaskTypeName = `${payload.filterValue}`;
                    break;
                case "jobNumber":
                    tempState.filterJobNumber = `${payload.filterValue}`;
                    break;
                case "acronymCode":
                    tempState.filterAcronymCode = `${payload.filterValue}`;
                    break;
                case "status":
                    tempState.filterStatus = `${payload.filterValue}`;
                    break;
                case "specifications":
                    tempState.filterSpecifications = `${payload.filterValue}`;
                    break;
                case "llgSerialNumber":
                    tempState.filterLLGSerialNumber = `${payload.filterValue}`;
                    break;
                case "result":
                    tempState.filterResult = payload.filterValue;
                    break;
                case "certificate":
                    tempState.filterCertificate = `${payload.filterValue}`;
                    break;
                case "fromInspectionDate":
                    tempState.filterFromInspectionDate = payload.filterValue;
                    break;
                case "toInspectionDate":
                    tempState.filterToInspectionDate = payload.filterValue;
                    break;
                case "fromDueDate":
                    tempState.filterFromDueDate = payload.filterValue;
                    break;
                case "toDueDate":
                    tempState.filterToDueDate = payload.filterValue;
                    break;

                default:
                    break;
            }
            break;

        default:
            break;
    }

    return tempState;
}