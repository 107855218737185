import { process } from "@progress/kendo-data-query";
import { IKendoGridColumn, IKendoGridFilter } from "../../../CommonInterfaces/CommoInterfaces";
import { REPORT_VIEW_GRID_COLUMNS } from "../Constants/ReportsConstants";
import { IReport } from "../Interfaces/IReport";

export class ReportsUtility {

    public processReportsForKendoGrid = (reports: IReport[], kendoGridDataState: any): any => {

        let processReports: any = process([...reports], { ...kendoGridDataState });
        return processReports;
    }

    public getFilteredReports = (reports: IReport[]): IReport[] => {

        // let filteredReports: IReport[] = reports.filter((report: IReport) =>
        //     report.observationType === 256 || (report.observationType === 1024 && report.taskType !== "LLG")
        // )

        let filteredReports: IReport[] = reports.filter((report: IReport) =>
            (report.taskType !== "LLG")
        )

        return filteredReports;
    }

    public static getFilterDetails = (reportGridDataState: any): string[] => {
        let filterStringArr: string[] = [];
        reportGridDataState.filter.filters.forEach((filterDetails: IKendoGridFilter) => {
            let fieldName : string = REPORT_VIEW_GRID_COLUMNS.find((column: IKendoGridColumn) => column.field === filterDetails.field).title;
            
            filterStringArr.push(`${fieldName} ${filterDetails?.operator} ${filterDetails.value}`)
        });
        return filterStringArr;
    }


}
