import { DefaultButton, DocumentCard, Image, Label, PrimaryButton, TextField } from 'office-ui-fabric-react'
import * as React from 'react'
import { IconConstants } from '../../Constants/StylesConstants';
import qwikspecLogo from './../../images/qwikspec-black-logo.png';
import CommonAPIServices from '../../CommonAPIService/CommonAPIServices';
import { CREDENTIALS } from '../../Constants/CommonConstants';
import { LoginAPI } from './LoginAPI';
import Alert from '../Alert/Alert';
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";

export default class CustomLogin extends React.Component<any, any> {
    _apiService;
    _commonAPIService;

    constructor(props) {
        super(props);
        this.state = {
            user: "",
            password: "",
            loading: false,
            errorMessage: "",
            newPassword: "",
            confirmNewPassword: "",
            otp: "",
            newPasswordErrorMessage: "",
            confirmPasswordErrorMessage: "",
            otpErrorMessage: "",
            resetEmail: "",
            isLogin: true,
            isOTPSent: false,
            alertJSON: {
                hideAlert: true,
                alertHeader: "",
                alertSubText: ""
            },
            isShowExistingPassword: false,
            isShowNewPassword: false,
            isShowConfirmPassword: false,
        }
        this._apiService = new LoginAPI(window.API_URL);
        this._commonAPIService = new CommonAPIServices(window.API_URL);
    }

    doLogin = () => {
        this.setState({
            loading: true,
        });

        let payload = {
            data: window.origin === "http://localhost:3000" ?
                CREDENTIALS[3]
                :
                {
                    email: this.state.user.indexOf("@") > -1 ? this.state.user : this.state.user.concat("@qwikspec.com"),
                    password: this.state.password,
                }
        }
        this._apiService.doLogin(payload).then((response) => {
            sessionStorage.setItem("jsessionid", response[0].jsessionid);
            this._commonAPIService.getCustomerLogo().then((companyLogoDetailsResp) => {
                window.isAuthenticated = true;
                window.loggedInUserDetails = response[0];
                sessionStorage.setItem("companyLogo", companyLogoDetailsResp.companyLogo);
                sessionStorage.setItem("fileType", companyLogoDetailsResp.fileType);
                window.loggedInUserDetails.customerLogoType = companyLogoDetailsResp.fileType;
                window.loggedInUserDetails.customerLogo = companyLogoDetailsResp.companyLogo;
                this.setState({
                    loading: false
                }, () => {
                    this.props.history.push('/');
                })

            });
        }).catch((error) => {
            this.setState({
                loading: false,
                errorMessage: error.error
            });
        })
    }

    generateOTP = () => {
        this.setState({
            loading: true,
        });

        let payload = {
            data:
            {
                email: this.state.resetEmail,
            }
        }
        this._apiService.generateOTP(payload).then(() => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON = {
                alertHeader: "Success!!",
                alertSubText: "OTP has been sent to : " + this.state.resetEmail,
                hideAlert: false
            };
            this.setState({
                loading: false,
                alertJSON,
                isOTPSent: true,
            }, () => {
                setTimeout(() => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON = {
                        alertHeader: "",
                        alertSubText: "",
                        hideAlert: true
                    };
                    this.setState({
                        alertJSON
                    });
                }, 2000);
            });
        }).catch((error) => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON = {
                alertHeader: "Oops!!",
                alertSubText: error,
                hideAlert: false
            };
            this.setState({
                loading: false,
                alertJSON
            }, () => {
                setTimeout(() => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON = {
                        alertHeader: "",
                        alertSubText: "",
                        hideAlert: true
                    };
                    this.setState({
                        alertJSON
                    });
                }, 2000);
            });
        })
    }

    resetPassword = () => {
        this.setState({
            loading: true,
            isShowConfirmPassword: false,
            isShowExistingPassword: false,
            isShowNewPassword: false
        });

        let payload = {
            data:
            {
                email: this.state.resetEmail,
                newPassword: this.state.newPassword,
                otp: this.state.otp
            }
        }
        this._apiService.resetPassword(payload).then((response) => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON = {
                alertHeader: "Success!!",
                alertSubText: "Your password has been reset successfully ",
                hideAlert: false
            };
            this.setState({
                loading: false,
                alertJSON,
                isOTPSent: false,
                otp: "",
                newPassword: "",
                confirmNewPassword: "",
                resetEmail: "",
                user: ""
            }, () => {
                setTimeout(() => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON = {
                        alertHeader: "",
                        alertSubText: "",
                        hideAlert: true
                    };
                    this.setState({
                        alertJSON,
                        isLogin: true
                    });
                }, 2000);
            });
        }).catch((error) => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON = {
                alertHeader: "Oops!!",
                alertSubText: error,
                hideAlert: false,
                isOTPSent: false
            };
            this.setState({
                loading: false,
                alertJSON
            }, () => {
                setTimeout(() => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON = {
                        alertHeader: "",
                        alertSubText: "",
                        hideAlert: true
                    };
                    this.setState({
                        alertJSON
                    });
                }, 2000);
            });
        })
    }

    render() {

        let {
            loading,
            errorMessage,
            isLogin,
            isOTPSent,
            newPassword,
            newPasswordErrorMessage,
            confirmNewPassword,
            confirmPasswordErrorMessage,
            otp,
            otpErrorMessage,
            alertJSON,
            resetEmail,
            isShowExistingPassword,
            isShowNewPassword,
            isShowConfirmPassword
        } = this.state;

        let isEnabledConfirmButton =
            newPassword
            && confirmNewPassword
            && otp
            && newPassword === confirmNewPassword
            && newPassword.length >= 6
            && newPassword.length <= 18;

        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <p className="paragraph">
                        For any help & support please contact LiftMax operations team -  Email : operations@liftmaxeu.com Tel: +44 (0) 1467 460600
                    </p>
                    
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* Nav Bar */}
                    <div className="collapse navbar-collapse my-2 my-lg-0" id="navbarSupportedContent">
                        <div className="navbar-nav blank-div"></div>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="anchor" href="https://www.liftmaxeu.com/">www.liftmaxeu.com</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <LoadingOverlay
                    active={loading}
                    spinner={<FadeLoader />}
                    text="Please wait..."

                >
                    <div className="custom-login-component">

                        <div className="login-container">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label className='login-label'>CLIENT PORTAL</Label>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <DocumentCard>
                                            {isLogin ?
                                                <div className='login-container-item'>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                            <TextField
                                                                required
                                                                autoFocus
                                                                placeholder="Email or Namespace"
                                                                iconProps={IconConstants.publicEmailIcon}
                                                                value={this.state.user}
                                                                onKeyPress={(event) => {
                                                                    if (event.charCode === 13) {
                                                                        this.doLogin()
                                                                    }
                                                                }}
                                                                onChange={(event, newVal) => {
                                                                    this.setState({
                                                                        user: newVal,
                                                                        errorMessage: ""
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                            <TextField
                                                                placeholder='Password'
                                                                required
                                                                type={isShowExistingPassword ? `text` : `password`}
                                                                value={this.state.password}
                                                                onKeyPress={(event) => {
                                                                    if (event.charCode === 13) {
                                                                        this.doLogin()
                                                                    }
                                                                }}
                                                                onChange={(event, newVal) => {
                                                                    this.setState({
                                                                        password: newVal,
                                                                        errorMessage: ""
                                                                    });
                                                                }}
                                                                iconProps={{
                                                                    iconName: this.state.isShowExistingPassword ?
                                                                        IconConstants.hidePasswordIcon.iconName : IconConstants.showPasswordIcon.iconName,
                                                                    style: { pointerEvents: "auto", cursor: "pointer" },
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            isShowExistingPassword: !this.state.isShowExistingPassword
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                            {errorMessage &&
                                                                <div className="mandatory-span custom-login-error">{errorMessage}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                                            <PrimaryButton
                                                                text="Login"
                                                                menuIconProps={IconConstants.signinIcon}
                                                                onClick={() => {
                                                                    this.doLogin()
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                                            <DefaultButton
                                                                text="Reset Password"
                                                                className="submit cancel-button"
                                                                menuIconProps={IconConstants.resetIcon}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isLogin: false,
                                                                        isOTPSent: false,
                                                                        otp: "",
                                                                        newPassword: "",
                                                                        confirmNewPassword: "",
                                                                        resetEmail: "",
                                                                        isShowConfirmPassword: false,
                                                                        isShowExistingPassword: false,
                                                                        isShowNewPassword: false,
                                                                        password: "",
                                                                        user: ""
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                // OTP Sent
                                                :
                                                !isOTPSent ?
                                                    <>
                                                        <div className='login-container-item'>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    <TextField
                                                                        placeholder="Email"
                                                                        iconProps={IconConstants.publicEmailIcon}
                                                                        value={resetEmail}
                                                                        onChange={(event, newVal) => {
                                                                            this.setState({
                                                                                resetEmail: newVal,
                                                                                errorMessage: ""
                                                                            });
                                                                        }}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg5">
                                                                    <PrimaryButton
                                                                        text="Request OTP"
                                                                        menuIconProps={IconConstants.numberIcon}
                                                                        disabled={!this.state.resetEmail}
                                                                        onClick={() => this.generateOTP()}
                                                                    />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg7">
                                                                    <DefaultButton
                                                                        className="cancel-button"
                                                                        text="Back to Login Screen"
                                                                        iconProps={IconConstants.previousIcon}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isLogin: true,
                                                                                isShowConfirmPassword: false,
                                                                                isShowExistingPassword: false,
                                                                                isShowNewPassword: false
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className='login-container-item'>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    <TextField
                                                                        value={newPassword}
                                                                        type={isShowNewPassword ? `text` : `password`}
                                                                        placeholder="New Password"
                                                                        iconProps={{
                                                                            iconName: this.state.isShowNewPassword ?
                                                                                IconConstants.hidePasswordIcon.iconName : IconConstants.showPasswordIcon.iconName,
                                                                            style: { pointerEvents: "auto", cursor: "pointer" },
                                                                            onClick: () => {
                                                                                this.setState({
                                                                                    isShowNewPassword: !this.state.isShowNewPassword
                                                                                })
                                                                            }
                                                                        }}
                                                                        onChange={(event, newVal) => {
                                                                            this.setState({
                                                                                newPassword: newVal,
                                                                                newPasswordErrorMessage:
                                                                                    newVal ?
                                                                                        newVal.trim().length < 6 || newVal.trim().length > 18 ?
                                                                                            "Password must be between 6 to 18 characters"
                                                                                            :
                                                                                            confirmNewPassword === newVal ?
                                                                                                "" :
                                                                                                "Must match with confirm password" :
                                                                                        "New password is mandatory",
                                                                                confirmPasswordErrorMessage:
                                                                                    newVal ?
                                                                                        newVal === confirmNewPassword ?
                                                                                            "" : "Must match with new password" : "Confirm password is mandatory"
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    {newPasswordErrorMessage &&
                                                                        <div className="mandatory-span custom-login-error">{newPasswordErrorMessage}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    <TextField
                                                                        value={confirmNewPassword}
                                                                        type={isShowConfirmPassword ? `text` : `password`}
                                                                        placeholder="Confirm Password"
                                                                        iconProps={{
                                                                            iconName: this.state.isShowConfirmPassword ?
                                                                                IconConstants.hidePasswordIcon.iconName : IconConstants.showPasswordIcon.iconName,
                                                                            style: { pointerEvents: "auto", cursor: "pointer" },
                                                                            onClick: () => {
                                                                                this.setState({
                                                                                    isShowConfirmPassword: !this.state.isShowConfirmPassword
                                                                                })
                                                                            }
                                                                        }}
                                                                        onChange={(event, newVal) => {
                                                                            this.setState({
                                                                                confirmNewPassword: newVal,
                                                                                newPasswordErrorMessage:
                                                                                    newPassword ?
                                                                                        newPassword.trim().length < 6 || newPassword.trim().length > 18 ?
                                                                                            "Password must be between 6 to 18 characters"
                                                                                            :
                                                                                            newPassword === newVal ?
                                                                                                "" :
                                                                                                "Must match with confirm password" :
                                                                                        "New password is mandatory",
                                                                                confirmPasswordErrorMessage:
                                                                                    newVal ?
                                                                                        newVal === newPassword ?
                                                                                            "" : "Must match with new password" : "Confirm password is mandatory"
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    {confirmPasswordErrorMessage &&
                                                                        <div className="mandatory-span custom-login-error">{confirmPasswordErrorMessage}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    <TextField
                                                                        value={otp}
                                                                        type="password"
                                                                        placeholder="OTP"
                                                                        iconProps={IconConstants.passwordIcon}
                                                                        onChange={(event, newVal) => {
                                                                            this.setState({
                                                                                otp: newVal,
                                                                                otpErrorMessage: newVal ? "" : "OTP is mandatory"
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    {otpErrorMessage &&
                                                                        <div className="mandatory-span custom-login-error">{otpErrorMessage}</div>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <br />
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg5">
                                                                    <PrimaryButton
                                                                        text="Confirm"
                                                                        menuIconProps={IconConstants.confirmIcon}
                                                                        disabled={!isEnabledConfirmButton}
                                                                        onClick={() => this.resetPassword()}
                                                                    />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg7">
                                                                    <DefaultButton
                                                                        className="cancel-button"
                                                                        text="Back to Login Screen"
                                                                        iconProps={IconConstants.previousIcon}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isLogin: true,
                                                                                isShowConfirmPassword: false,
                                                                                isShowExistingPassword: false,
                                                                                isShowNewPassword: false
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </DocumentCard>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="mt-auto py-3 fixed-bottom">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 flex-text-image">
                                        Powered by: <Image styles={{
                                            image: {
                                                height: "60%",
                                                marginLeft: "2.5%"
                                            }
                                        }} src={qwikspecLogo} />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg7"></div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 text-right" style={{ paddingRight: '0px' }}>
                                        <a className="navbar-brand" href="https://www.liftmaxeu.com/" style={{ marginRight: '0px' }}>
                                            <img src="liftmax_transparent_logo.png" width="200" height="175" alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay >
                {!alertJSON.hideAlert &&
                    <Alert
                        alertJSON={alertJSON}
                    />
                }
            </>
        )
    }
}
