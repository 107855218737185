import React, { Component } from 'react';
import { Label, PrimaryButton, Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react'; // Import Dialog components
import Select from 'react-select';
import { QRStickerAPI } from '../Services/QRStickerAPI';
import { SizeOptions, QuantityOptions, ColorOptions } from '../Constants/QRStickerConstants';

export default class QRSticker extends Component {
    constructor(props) {
        super(props);
        this._qrStickerApi = new QRStickerAPI(window.API_URL);

    }
    render() {
        let{
          CurrentQRSticker,
        }=this.props.QRS;
        
        let isSaveDisabled= (CurrentQRSticker&&!CurrentQRSticker.size.value)
                            ||(CurrentQRSticker&&!CurrentQRSticker.color.value)
                            ||(CurrentQRSticker&&!CurrentQRSticker.quantity.value);
        return (
            <div>
                <form>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <Label>Size:<span className="mandatory-span">*</span></Label>
                        <Select
                         value={CurrentQRSticker && CurrentQRSticker.hasOwnProperty("size") ? CurrentQRSticker.size.value : null}
                         onChange={(newval) => {
                            this.props.changeSize({
                                size: newval
                            });
                            
                        }}  
                        options={SizeOptions}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                        <Label>Color:<span className="mandatory-span">*</span></Label>
                        <Select
                           value={CurrentQRSticker && CurrentQRSticker.hasOwnProperty("color") ? CurrentQRSticker.color.value : null}
                           onChange={(newval) => {
                              this.props.changeColor({
                                  color: newval
                              });
                          }}  
                            options={ColorOptions}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4"style={{ marginBottom: '1em' }}>
                        <Label>Quantity:<span className="mandatory-span">*</span></Label>
                        <Select
                           value={CurrentQRSticker && CurrentQRSticker.hasOwnProperty("quantity") ? CurrentQRSticker.quantity.value : null}
                           onChange={(newval) => {
                              this.props.changeQuantity({
                                  quantity: newval
                              });
                          }}  
                            options={QuantityOptions}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                        <PrimaryButton
                            text="Generate Random Sticker SNs"
                            disabled={isSaveDisabled}
                          //  onClick={this.handleGenerateRandomStickerSNs}
                          onClick={() => {
                            this.props.saveQRStickerAsync({
                                apiObject: this._qrStickerApi,
                                CurrentQRSticker: CurrentQRSticker
                            });
                            this.props.cleanQRSticker();
                        }}
                        />
                    </div>
                </form>

                {/* Dialog to show processing message */}
                <div>
                <Dialog hidden={!this.props.isRequestProcessing} dialogContentProps={{ title: 'Request Processing',titleProps: {
            style: {
                color: 'lightblue' // Set the color directly to light blue
            }
        } }}>
                    Your request is processing...Please Wait
                </Dialog>
            </div>
            <Dialog
                hidden={!this.props.successMessage}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Success',
                    subText: this.props.successMessage,
                    titleProps: {
                        style: {
                            color: 'lightblue' // Set the color directly to light blue
                        }
                    }
                }}
                onDismiss={this.props.clearSuccessMessage}
            >
                <DialogFooter>
                    <PrimaryButton onClick={this.props.clearSuccessMessage} text="OK" />
                </DialogFooter>
            </Dialog>
            </div>
        );
    }
}