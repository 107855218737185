import React from 'react';
import { connect } from 'react-redux';
import { IClientInventoryStateProps } from '../ClientInventoryInteface/IClientInventoryStateProps'
import { Dispatch, bindActionCreators } from 'redux'
import ClientInventoryDashboard from './ClientInventoryDashboard';
import { ClientInventoryActions } from '../Actions/ClientInventoryActions';
import { Breadcrumb } from 'office-ui-fabric-react';
import { CLIENT_INVENTORY_BREADCRUMBS, LOAD_CLIENT_INVENTORY, RESET_CLIENT_INVENTORY_DASHBOARD } from '../Constants/ClientInventoryConstants';
import DownloadTemplate from './DownloadTemplate';
import ImportCSV from './ImportCSV';
import { store } from '../../../Store/store';
import watch from 'redux-watch';
import { ClientInventoryAPI } from '../Services/ClientInventoryAPI';

const mapStateToProps = (state: any): IClientInventoryStateProps => {
    return {
        ClientInventoryState: state.ClientInventoryReducer,
        ClientSiteSelectorState: state.ClientSiteSelectorReducer,
    }
}

let w = watch(store.getState, 'ClientSiteSelectorReducer.selectedSite')
store.subscribe(w((newVal, oldVal, objectPath) => {
    console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
    let _ClientInventoryAPI = new ClientInventoryAPI(window.API_URL, window.loggedInUserDetails);
    store.dispatch({ type: RESET_CLIENT_INVENTORY_DASHBOARD, payload: { isOngoingLoad: false } })
    const selectedClient: any = store?.getState()['ClientSiteSelectorReducer']['selectedClient'];
    const selectedSite : any = store?.getState()['ClientSiteSelectorReducer']['selectedSite'];
    if(selectedSite) {
        _ClientInventoryAPI.loadClientInventoryAPI(selectedClient.value, selectedSite.value)
            .then((resp: any) => {
                store.dispatch({
                    type: LOAD_CLIENT_INVENTORY,
                    payload: {
                        isOngoingLoad: false,
                        clientInventories: resp.data
                    }
                })
            })
    }

}))

class ClientInventoryComponent extends React.PureComponent<IClientInventoryStateProps, {}> {

    public render(): JSX.Element {

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row" style={{ height: "5px" }}></div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <Breadcrumb
                                className="custom-breadcrumb"
                                items={CLIENT_INVENTORY_BREADCRUMBS}
                                ariaLabel="With last item rendered as heading"
                                overflowAriaLabel="More links"
                            />

                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1"></div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <DownloadTemplate />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <ImportCSV
                                {...this.props}
                            />
                        </div>
                    </div>
                </div>
                <ClientInventoryDashboard
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    let clientInventoryActions: ClientInventoryActions = new ClientInventoryActions();
    return bindActionCreators({ ...clientInventoryActions }, dispatch);
}

const ClientInventory = connect(mapStateToProps, mapDispatchToProps)(ClientInventoryComponent);
export default ClientInventory;