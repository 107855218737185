import * as CONSTANTS from "../Constants/ClientConstants";
import { IClientPayLoad, IUser, IUserPayload, IAddNewClientPayLoad, ILocation } from "../ClientInterface/IClient";
import { Dispatch } from "redux";
import { ClientInitialState } from "../Constants/ClientInitialState";

export class ClientActions {

    resetClientBreadCrumb = (payload) => {
        return { type: CONSTANTS.RESET_CLIENT_BREADCRUMB, payload }
    }

    // Client Dashboard
    public cleanClientDashboard = (payload: IClientPayLoad) => {
        return dispatch => {
            dispatch(this.cleanClientDashboardAsync(payload));
            dispatch(this.resetClientBreadCrumb(payload));
            payload.SelectedClient = null;
            payload.ShowClientDetailsTab = false;
            dispatch(this.showClientDetailsTab(payload));
        }
    }

    public cleanClientDashboardAsync = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.CLEAN_CLIENT_DASHBOARD, payload }
    }

    public loadClientDashboard = (payload: IClientPayLoad): any => {
        let objectType: string = "CLIENT_COMPANY";
        return (dispatch: any) => {
            payload.IsOngoingLoad = true;
            if (payload.filtered) {
                dispatch(this.changeClientFilterValue(payload));
            }
            else {
                dispatch(this.loadClientDashBoardAsync(payload));
            }
            payload.apiObject.loadInitialData(payload.offset, payload.limit, objectType, payload.config).then((response) => {
                payload.IsOngoingLoad = false;
                payload.Clients = response[0].data;
                payload.TotalClientCount = response[1].data;
                dispatch(this.loadClientDashBoardAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Clients"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public loadClientDashBoardAsync = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.LOAD_CLIENT_DASHBOARD, payload }
    }

    showClientDetailsTab = (payload) => {
        return { type: CONSTANTS.SHOW_CLIENT_DETAILS_TAB, payload };
    }

    // Location Dashboard
    public cleanLocationDashboard = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.CLEAN_LOCATION_DASHBOARD, payload }
    }

    public loadLocationDashboard = (payload: IClientPayLoad): any => {
        let objectType: string = "SITE";
        return (dispatch: any) => {
            payload.IsOngoingLoad = true;
            dispatch(this.loadLocationDashBoardAsync(payload));
            payload.apiObject.loadInitialData(payload.offset, payload.limit, objectType, payload.config, payload.SelectedClient.id).then((response) => {
                payload.IsOngoingLoad = false;
                payload.Locations = response[0].data;
                payload.TotalLocationCount = response[1].data;
                dispatch(this.loadLocationDashBoardAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Locations"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public loadLocationDashBoardAsync = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.LOAD_LOCATION_DASHBOARD, payload }
    }

    public openCloseAlertModal = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.OPEN_CLOSE_CLIENT_ALERT_MODAL, payload };
    }

    public changeClientFilterValue = (payload: IClientPayLoad) => {
        switch (payload.filterType) {
            case "name":
                return { type: CONSTANTS.CHANGE_CLIENT_NAME_FILTER_VALUE, payload };
            case "code":
                return { type: CONSTANTS.CHANGE_CLIENT_CODE_FILTER_VALUE, payload };
            default:
                break;
        }
    }

    public showHideClientAddClientPopUp = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.SHOW_HIDE_CLIENT_ADD_CLIENT_POP_UP, payload };
    }

    public loadAddClientPopUpAsync = (payload: IClientPayLoad) => {
        return (dispatch: Dispatch) => {
            payload.IsOngoingLoad = true;

            dispatch(this.loadAddClientPopUp(payload));

            payload.apiObject.loadAddClientScreen().then((response) => {
                payload.IsOngoingLoad = false;
                payload.Roles = response[0].filter((d: any) => CONSTANTS.ADD_CLIENT_USER_TYPES.indexOf(d.name) > -1);
                payload.LoOffices = response[1]
                dispatch(this.loadAddClientPopUp(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: "Error while loading Add Client Screen"
                }
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            })
        }
    }

    public loadAddClientPopUp = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.LOAD_ADD_CLIENT_POP_UP_SCREEN, payload };
    }

    public addOrRemoveUser = (payload: IClientPayLoad) => {
        return { type: payload.addFlag ? CONSTANTS.ADD_NEW_USER : CONSTANTS.DELETE_LAST_USER, payload };
    }

    public changeClientSiteDetails = (payload: IClientPayLoad) => {
        switch (payload.clientSiteFieldType) {
            case "ClientName":
                return { type: CONSTANTS.CHANGE_CLIENT_NAME, payload };
            case "ClientStreet":
                return { type: CONSTANTS.CHANGE_CLIENT_STREET, payload };
            case "ClientCity":
                return { type: CONSTANTS.CHANGE_CLIENT_CITY, payload };
            case "ClientState":
                return { type: CONSTANTS.CHANGE_CLIENT_STATE, payload };
            case "ClientCountry":
                return { type: CONSTANTS.CHANGE_CLIENT_COUNTRY, payload };
            case "ClientPin":
                return { type: CONSTANTS.CHANGE_CLIENT_PIN, payload };
            case "ClientPhone":
                return { type: CONSTANTS.CHANGE_CLIENT_PHONE, payload };
            case "ClientCode":
                return { type: CONSTANTS.CHANGE_CLIENT_CODE, payload };
            case "ClientExternalId":
                return { type: CONSTANTS.CHANGE_CLIENT_EXTERNAL_ID, payload };
            case "ClientOfficeId":
                return { type: CONSTANTS.CHANGE_ADD_CLIENT_OFFICE_ID, payload };

            case "SiteName":
                return { type: CONSTANTS.CHANGE_SITE_NAME, payload };
            case "SiteStreet":
                return { type: CONSTANTS.CHANGE_SITE_STREET, payload };
            case "SiteCity":
                return { type: CONSTANTS.CHANGE_SITE_CITY, payload };
            case "SiteState":
                return { type: CONSTANTS.CHANGE_SITE_STATE, payload };
            case "SiteCountry":
                return { type: CONSTANTS.CHANGE_SITE_COUNTRY, payload };
            case "SitePin":
                return { type: CONSTANTS.CHANGE_SITE_PIN, payload };
            case "SitePhone":
                return { type: CONSTANTS.CHANGE_SITE_PHONE, payload };
            case "SiteCode":
                return { type: CONSTANTS.CHANGE_SITE_CODE, payload };
            case "SiteExternalId":
                return { type: CONSTANTS.CHANGE_SITE_EXTERNAL_ID, payload };

            default:
                break;
        }
    }
    public changeLocationDetails = (payload: IClientPayLoad) => {

        switch (payload.clientSiteFieldType) {

            case "SiteName":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_NAME, payload };
            case "SiteStreet":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_STREET, payload };
            case "SiteCity":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_CITY, payload };
            case "SiteState":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_STATE, payload };
            case "SiteCountry":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_COUNTRY, payload };
            case "SitePin":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_PIN, payload };
            case "SitePhone":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_PHONE, payload };
            case "SiteCode":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_CODE, payload };
            case "SiteExternalId":
                return { type: CONSTANTS.CHANGE_LOCATION_SITE_EXTERNAL_ID, payload };
            case "ClientOfficeId":
                return { type: CONSTANTS.CHANGE_CLIENT_OFFICE_ID, payload };

            default:
                break;
        }
    }

    public populateSiteAddressFromClient = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.POPULATE_SITE_ADDRESS_FROM_CLIENT, payload };
    }

    public changeAddUserDetails = (payload: IClientPayLoad) => {
        switch (payload.clientSiteFieldType) {
            case "role":
                return { type: CONSTANTS.CHANGE_CLIENT_USER_ROLE, payload };
            case "name":
                return { type: CONSTANTS.CHANGE_CLIENT_USER_NAME, payload }
            case "email":
                return { type: CONSTANTS.CHANGE_CLIENT_USER_EMAIL, payload }
            case "phone":
                return { type: CONSTANTS.CHANGE_CLIENT_USER_PHONE, payload }
            default:
                break;
        }

    }

    public saveNewClientAsync = (payload: IClientPayLoad) => {

        payload.AddClientDetails.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        payload.AddSiteDetails.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        let addClientPayload: IAddNewClientPayLoad = {
            Client: payload.AddClientDetails,
            Site: payload.AddSiteDetails
        }

        let userPayload: IUserPayload[] = [];

        if (payload.Users) {
            payload.Users.forEach((user: IUser) => {
                let eachUser: IUserPayload = {
                    roleId: user.role.value,
                    email: user.email,
                    name: user.name,
                    phone: user.phone
                }
                userPayload.push(eachUser);
            });
            addClientPayload.Users = userPayload;
        }

        return (dispatch: Dispatch) => {

            payload.IsOngoingLoad = true;

            dispatch(this.saveNewClient(payload));

            payload.apiObject.saveNewClient(addClientPayload).then((response) => {
                payload.IsOngoingLoad = false;
                dispatch(this.saveNewClient(payload));
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: "New Client saved successfully, Creating Role License Config in in process..."
                }
                dispatch(this.openCloseAlertModal(payload));

                payload.apiObject.createRoleLicenseConfig(payload.AddClientDetails.name);
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    payload.offset = 0;
                    payload.limit = ClientInitialState.limit;
                    dispatch(this.cleanClientDashboardAsync(payload));
                    dispatch(this.loadClientDashboard(payload));
                }, 2000);
            }).catch((errorMessage: string) => {
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: errorMessage
                }
                payload.errorFlag = true;
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            });
        }
    }

    public saveNewClient = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.SAVE_NEW_CLIENT, payload };
    }

    // Add Edit Location Pop Up
    public showHideClientAddEditLocationPopUp = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.SHOW_HIDE_CLIENT_ADD_EDIT_LOCATION_POP_UP, payload }
    }

    public saveLocationAsync = (payload: IClientPayLoad) => {

        let SelectedLocation: ILocation = payload.SelectedLocation;

        SelectedLocation.objectType = "SITE";
        SelectedLocation.companyId = payload.IsClientEdit ? null : payload.SelectedClient.id;
        SelectedLocation.parentId = payload.IsClientEdit ? null : payload.SelectedClient.id;
        SelectedLocation.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        SelectedLocation.locationLevel = 0;

        return (dispatch: Dispatch) => {

            payload.IsOngoingLoad = true;

            dispatch(this.saveLocation(payload));

            payload.apiObject.saveLocation(payload.SelectedLocation).then(() => {
                payload.IsOngoingLoad = false;
                dispatch(this.saveLocation(payload));
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Success!!",
                    alertSubText: payload.IsClientEdit ? "Client saved successfully" : "Location saved successfully"
                }
                dispatch(this.openCloseAlertModal(payload));
                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                    if (payload.IsClientEdit) {
                        payload.offset = 0;
                        payload.limit = ClientInitialState.limit;
                        payload.errorFlag = true;
                        dispatch(this.cleanClientDashboardAsync(payload));
                        dispatch(this.loadClientDashboard(payload));
                    }
                    else {
                        payload.offset = 0;
                        payload.limit = ClientInitialState.limit;
                        payload.errorFlag = true;
                        dispatch(this.cleanLocationDashboard(payload));
                        dispatch(this.loadLocationDashboard(payload));
                    }
                }, 2000);
            }).catch((errorMessage: string) => {
                payload.alertJSON = {
                    hideAlert: false,
                    alertHeader: "Ooops!!",
                    alertSubText: errorMessage
                }
                payload.errorFlag = true;
                dispatch(this.openCloseAlertModal(payload));

                setTimeout(() => {
                    payload.alertJSON = {
                        hideAlert: true,
                        alertHeader: "",
                        alertSubText: ""
                    }
                    dispatch(this.openCloseAlertModal(payload));
                }, 2000);
            });
        }
    }

    public saveLocation = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.SAVE_LOCATION, payload };
    }

    public loadEditClientScreenAsync = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.LOAD_EDIT_CLIENT_SCREEN, payload };
    }

    public loadEditClientScreen = (payload: IClientPayLoad) => {
        return (dispatch: Dispatch) => {
            payload.IsOngoingLoad = true;

            dispatch(this.loadEditClientScreenAsync(payload));

            payload.apiObject.loadEditClientScreen(payload.SelectedClient.id).then((response) => {
                payload.IsOngoingLoad = false;
                payload.clientSiteFieldValue = `data:${response[0].clientLogoType};base64,${response[0].clientLogoFile}`;
                console.log("Loaded:", payload.clientSiteFieldValue);
                payload.LoOffices = response[1];

                payload.imageError = "";
                dispatch(this.loadEditClientScreenAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.IsOngoingLoad = false;
                payload.imageError = "Error While Loading Edit Client Screen!!!"
                dispatch(this.loadEditClientScreenAsync(payload));
            })
        }
    }

    public uploadClientLogoAsync = (payload: IClientPayLoad) => {
        return { type: CONSTANTS.SAVE_CLIENT_LOGO, payload };
    }

    public uploadClientLogo = (payload: IClientPayLoad) => {
        return (dispatch: Dispatch) => {
            payload.IsOngoingLoad = true;

            dispatch(this.uploadClientLogoAsync(payload));

            payload.apiObject.uploadClientLogo(payload.SelectedClient.id, payload.imageData).then((response) => {
                payload.IsOngoingLoad = false;


                payload.clientSiteFieldValue = `data:${response.clientLogoType};base64,${response.clientLogoFile}`;
                console.log("Saved:", payload.clientSiteFieldValue);
                payload.imageError = "";
                dispatch(this.uploadClientLogoAsync(payload));
            }).catch((error) => {
                console.error("Error: ", error);
                payload.IsOngoingLoad = false;
                payload.imageError = "Error While Uploading Logo!!!"
                dispatch(this.uploadClientLogoAsync(payload));
            })
        }
    }

}