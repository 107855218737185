import React, { Component } from 'react'
import { ConfigAPI } from '../Services/ConfigAPI';
import { Nav } from 'office-ui-fabric-react';
import { navStylesForm } from '../../../../Constants/StylesConstants';

export default class ConfigList extends Component {

    _taskTypeAPI = null;
    constructor(props) {
        super(props);
        this._taskTypeAPI = new ConfigAPI(window.API_URL, window.loggedInUserDetails.jsessionid);
    }

    // UNSAFE_componentWillMount() {
    //     this.props.loadDropDownDataAsync({
    //         apiObject: this._taskTypeAPI,
    //     });
    // }

    render() {

        let {
            Configs,
            CurrentConfig
        } = this.props.Config

        let groups = [
            {
                links: Configs
            }
        ]

        return (
            <div>

                <Nav
                    ariaLabel="Forms"
                    selectedKey={CurrentConfig.id}
                    styles={navStylesForm}
                    groups={groups}
                    onLinkClick={(ev, item) => {
                        this.props.selectConfig({
                            SelectedConfig: item
                        })
                    }}
                />

            </div>
        )
    }
}
