import { IRoleData, IRoleLicenseConfig } from "../Interfaces/IRole";
import moment from "moment-timezone";
import { ISelect } from "./../../../../CommonInterfaces/CommoInterfaces";

export class RoleUtility {
  loadRoleTableStructure = (Roles: any[]): IRoleData[] => {
    let TableFormattedRole: IRoleData[] = [];

    Roles.forEach((element: any) => {
      //let settingWithMetadata: ISetting = JSON.parse(
      //  element.settingWithMetadata
      //);

      //let settingMetatDatas: ISettingMetadata[] =
      //  settingWithMetadata.settingmetadata;

      // let attribute_form_object: ISettingMetadata | undefined = element.find(
      // (d: ISettingMetadata) => d.meta_key === "attribute_form_id"
      //);
      //let reference_object: ISettingMetadata | undefined = element.find(
      //(d: ISettingMetadata) => d.meta_key === "certificate_reference"
      //);

      TableFormattedRole.push({
        id: element.id,
        name: element.name,
        acronymCode: element.acronymCode,
        description: element.description,
        tenantId: element.tenantId,
        attribute_form_id: element.attribute_form_id,
        namespace: element.namespace,
        //? parseInt(attribute_form_object.meta_value)
        //: 0,
        reference: element.reference_object,
        created_date: new Date(element.createdDate),
        modified_date: new Date(element.createdDate),
        status: element.status,
        displayName: element.display_name,
        type: element.type,
        metaKey: element.metaKey,
        metaValue: element.metaValue,
      });
    });

    return TableFormattedRole;
  };

  loadFormAttributesOptions = (FormAttributes: any[]): ISelect[] => {
    let FormAttributeOptions: ISelect[] = [];

    FormAttributes.forEach((element: any) => {
      FormAttributeOptions.push({
        value: element.id,
        label: element.name,
      });
    });
    return FormAttributeOptions;
  };

  resetCurrentRole = (tenantId: number): IRoleData => {
    let CurrentRole: IRoleData = {
      acronymCode: "",
      attribute_form_id: -1,
      description: null,
      id: -1,
      tenantId: tenantId,
      name: "",
      reference: "",
      namespace: "",
      attribute_form: null as ISelect,
      created_date: new Date(),
      modified_date: new Date(),
      status: 1,
      displayName: "",
      type: 0,
      metaKey: null,
      metaValue: null
    };

    return CurrentRole;
  };

  generateRoleJSONPayLoad = (RoleDetails: IRoleData, clients: IRoleLicenseConfig[]) => {
    let payloadJSON = {};
    if (RoleDetails.id === -1) {
      payloadJSON = {
        tenantId: RoleDetails.tenantId,
        objectType: "ROLE",
        name: RoleDetails.name,
        description: null,
        type: RoleDetails.type,
        status: 1,
        parent_id: null,
        user_data: null,
        created_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        modified_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        created_by: null,
        last_modified_by: null,
        external_id: null,
        client_id: null,
        display_name: RoleDetails.displayName,
        acronymCode: RoleDetails.acronymCode,
        archived: 0,
        // settingmetadata: [
        // {
        //     "tenant_id": 2,
        //     "meta_key": "certificate_reference",
        //     "meta_value": AssetTypeDetails.reference,
        //     "data_type": "STRING",
        //     "object_type": null,
        //     "display_name": "Certificate Reference"
        // },
        // {
        //   tenant_id: 2,
        //   meta_key: "attribute_form_id",
        // meta_value: RoleDetails.attribute_form
        //   ? RoleDetails.attribute_form.value
        // : null,
        //   data_type: "INTEGER",
        //   object_type: null,
        //   display_name: "Form Attribute",
        //  },
        //],
      };
    } else {
      payloadJSON = {
        id: RoleDetails.id,
        tenantId: RoleDetails.tenantId,
        objectType: "ROLE",
        name: RoleDetails.name,
        description: null,
        type: RoleDetails.type,
        status: 1,
        parent_id: null,
        user_data: null,
        created_date: moment(RoleDetails.created_date).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        modified_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        created_by: null,
        last_modified_by: null,
        external_id: null,
        client_id: null,
        display_name: RoleDetails.displayName,
        acronymCode: RoleDetails.acronymCode,
        archived: 0,
        metaKey: RoleDetails.metaKey,
        metaValue: clients ? JSON.stringify(clients) : null,
        // settingmetadata: [
        // {
        //     "setting_id": RoleDetails.id,
        //     "tenant_id": RoleDetails.tenantId,
        //     "data_type": "STRING",
        //     "object_type": "CONFIG",
        //     "display_name": "LICENSE CONFIG"
        // }],
        // {
        // setting_id: RoleDetails.id,
        // tenant_id: 2,
        // meta_key: "attribute_form_id",
        // meta_value: RoleDetails.attribute_form
        // ? RoleDetails.attribute_form.value
        //: null,
        //  data_type: "INTEGER",
        // object_type: null,
        // display_name: "Form Attribute",
        //},
        //],
      };
    }

    return payloadJSON;
  };

  // public populateLicenseConfig = (licenseConfigs: IRoleLicenseConfig[]): IRoleLicenseConfig[] => {

  //   let clientLicenceArray = [] as IRoleLicenseConfig[];

  //   licenseConfigs.forEach((config: ILocation) => {

  //     let item: IRoleLicenseConfig = description?.find((desc: IRoleLicenseConfig) => desc.clientId === client.id);

  //     clientLicenceArray.push({
  //       clientId: client.id,
  //       clientName: client.name,
  //       clientLicense: item ? item.clientLicense : 0
  //     })

  //   });

  //   return clientLicenceArray;

  // };
}
