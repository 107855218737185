import * as CONSTANTS from "../Constants/RoleConstants";
import { ILoggedInUser } from "../../../../CommonInterfaces/CommoInterfaces";

import { ALERT_MESSAGE } from "../../../../Constants/MessageConstants";
import axios from "axios";
import { IRoleAPI } from "../Interfaces/IRole";

export class RoleAPI implements IRoleAPI {
  private _URL: string;
  private _loggedInUserDetails: ILoggedInUser;

  constructor(URL: string, loggedInUser: ILoggedInUser) {
    this._URL = URL;
    this._loggedInUserDetails = loggedInUser;
  }

  _getChunkRole(objectType: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this._URL
            .concat(CONSTANTS.GET_ALL_ROLES_WITH_NAMESPACE_URL)
            .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            .concat(`?`)
            .concat(`objectType=${objectType}`)
          // .concat(`offset=${offset}&`)
          //.concat(`limit=${limit}`)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("Error getAllRoles method", error);
          if (error.response && error.response.status === 401) {
            reject(ALERT_MESSAGE.ERROR_401);
          } else {
            reject(ALERT_MESSAGE.COMMON_ERROR);
          }
        });
    });
  }

  getAllFormAttributes(objectType) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this._URL
            .concat(CONSTANTS.GET_FORM_ATTRIBUTE_LIST)
            .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            .concat(`?`)
            .concat(`formobject=${objectType}`)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("Error getAllFormAttributes method", error);
          if (error.response && error.response.status === 401) {
            reject(ALERT_MESSAGE.ERROR_401);
          } else {
            reject(ALERT_MESSAGE.COMMON_ERROR);
          }
        });
    });
  }

  saveRoleConfigData = (payload, isNewConfig) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          this._URL
            .concat(CONSTANTS.GET_ALL_ROLES_URL)
            .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            .concat(isNewConfig ? `?isNewConfig=true`: ``),
          payload
        )
        .then((response) => {
          resolve(true);
        })
        .catch((error) => {
          console.error("Error saveRoleConfigData", error);
          if (error.response && error.response.status === 401) {
            reject(ALERT_MESSAGE.ERROR_401);
          } else {
            reject(ALERT_MESSAGE.COMMON_ERROR);
          }
        });
    });
  };

  deleteRole = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          this._URL
            .concat(CONSTANTS.GET_ROLES_URL)
            .concat(`/${payload.id}`)
            .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("Error deleteRole", error);
          if (error.response && error.response.status === 401) {
            reject(ALERT_MESSAGE.ERROR_401);
          } else {
            reject(ALERT_MESSAGE.COMMON_ERROR);
          }
        });
    });
  };

  public getRoleDetails(objectType: string): Promise<any> {
    let promises = [this._getChunkRole(objectType)];
    return Promise.all(promises);
  }

  getRoleFilterDetails(objectType, config, value): Promise<any> {
    let promises = [this._getSearchRolesCount(objectType, config, value)];
    return Promise.all(promises);
  }

  _getSearchRolesCount(objectType, config, value) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this._URL
            .concat(CONSTANTS.GET_ALL_ROLES_WITH_NAMESPACE_URL)
            .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            .concat(`?`)
            .concat(`objectType=${objectType}&name=${value}`)
          //.concat(`limit=${limit}`)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("Error _getRolesCount method", error);
          if (error.response && error.response.status === 401) {
            reject(ALERT_MESSAGE.ERROR_401);
          } else {
            reject(ALERT_MESSAGE.COMMON_ERROR);
          }
        });
    });
  }
}
