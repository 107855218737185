import { Label } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import Select from 'react-select';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { AssetAPI } from '../Services/AssetAPI';
import AssetDashboard from './AssetDashboard';
import * as Loaders from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { CLIENT_ADMIN_ROLE, CLIENT_USER_ROLE, SITE_USER_ROLE } from '../../../Constants/CommonConstants';

export default class AssetMainFilter extends Component {

    _assetAPI = null;

    constructor(props) {
        super(props);
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);

    }

    componentDidMount() {
        let currentUserRole = window.loggedInUserDetails.role;
        let currentUserCompanyId = window.loggedInUserDetails.companyId;

        if (currentUserRole === CLIENT_USER_ROLE || currentUserRole === SITE_USER_ROLE) {
            this.props.loadClients({
                apiObject: this._assetAPI,
                companyId: currentUserCompanyId
            });
        }
        else {
            this.props.loadClients({
                apiObject: this._assetAPI
            });
        }
    }

    render() {

        const {
            isLoadingMainClientFilter,
            isLoadingMainSiteFilter,
            selectedClientFilter,
            selectedSiteFilters,
            clients,
            sites
        } = this.props.Asset;

        const isClientRole =
            window.loggedInUserDetails.role === CLIENT_ADMIN_ROLE || window.loggedInUserDetails.role === CLIENT_USER_ROLE;

        return (
            <React.Fragment>
                {/* Main Filter */}
                <div className="ms-Grid asset-main-filter-grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Client: <span className="mandatory-span">*</span></Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                            <LoadingOverlay
                                active={isLoadingMainClientFilter}
                                spinner={<Loaders.BeatLoader />}
                                text="Loading Clients...">
                                <Select
                                    value={selectedClientFilter}
                                    placeholder={clients.length > 0 ? `----Select Client----` : ``}
                                    onChange={(newVal) => {
                                        this.props.changeClientFilterName({
                                            apiObject: this._assetAPI,
                                            selectedLocation: newVal,
                                            parentId: newVal.value,
                                            // companyId: currentUserRole === CLIENT_USER_ROLE ? currentUserCompanyId : ""
                                        })
                                    }}
                                    options={isClientRole ?
                                        CommonUtility.sortByColumn(clients
                                            .filter((client) => window.loggedInUserDetails.companyId === client.value), "label", "asc")
                                        :
                                        CommonUtility.sortByColumn(clients, "label", "asc")

                                    }
                                />

                            </LoadingOverlay>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Site: <span className="mandatory-span">*</span></Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 asset-site-filter">
                            <LoadingOverlay
                                active={isLoadingMainSiteFilter}
                                spinner={<Loaders.BeatLoader />}
                                text="">
                                <Select
                                    isMulti
                                    isDisabled={!selectedClientFilter}
                                    // value={selectedSiteFilter}
                                    value={selectedSiteFilters}
                                    placeholder={sites.length > 0 ? `----Select Site----` : ``}
                                    onChange={(newVal) => {
                                        this.props.changeSiteFilterName({
                                            apiObject: this._assetAPI,
                                            // selectedLocation: newVal,
                                            selectedLocations: newVal,
                                            selectedClient: selectedClientFilter
                                        })
                                    }}
                                    options={CommonUtility.sortByColumn(sites, "label", "asc")}
                                />
                            </LoadingOverlay>
                        </div>

                    </div>
                </div>

                {/* Dashboard */}
                {selectedClientFilter && selectedSiteFilters && selectedSiteFilters.length > 0 &&
                    <AssetDashboard
                        {...this.props}
                    />
                }

            </React.Fragment>
        )
    }
}
