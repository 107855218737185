import moment from 'moment-timezone';

export class TaskUtility {

    setUserOptions = (Users) => {
        let UserOptions = [];

        Users.forEach((item) => {
            let UserOption = {
                value: item.id,
                label: item.name,
                details: `${item.email}, ${item.phone}`
            }
            UserOptions.push(UserOption);
        });

        return UserOptions;
    }

    setSiteOptions = (Sites) => {
        let SiteOptions = [];

        Sites.forEach((item) => {
            let SiteOption = {
                value: item.id,
                label: item.name,
                details: `${item.street}, ${item.pin}`
            }
            SiteOptions.push(SiteOption);
        });
        return SiteOptions;
    }

    setClientOptions = (Clients) => {
        let ClientOptions = [];

        Clients.forEach((item) => {
            let ClientOption = {
                value: item.id,
                label: item.name,
                details: `${item.street}, ${item.city}, ${item.pin}`
            }
            ClientOptions.push(ClientOption);
        });
        return ClientOptions;
    }

    setTaskTypeOptions = (TaskTypes) => {
        let TaskTypeOptions = [];

        TaskTypes.forEach((item) => {
            let TaskTypeOption = {
                value: item.id,
                label: item.name,
            }
            TaskTypeOptions.push(TaskTypeOption);
        });
        return TaskTypeOptions;
    }

    setAssetTypeOptions = (AssetTypes) => {
        let AssetTypeOptions = [];

        AssetTypes.forEach((item) => {
            let AssetTypeOption = {
                value: item.id,
                label: item.name,
            }
            AssetTypeOptions.push(AssetTypeOption);
        });
        return AssetTypeOptions;
    }

    setAssetOptions = (Assets) => {
        let AssetOptions = [];

        Assets.forEach((item) => {
            let AssetOption = {
                value: item.id,
                label: item.name,
            }
            AssetOptions.push(AssetOption);
        });
        return AssetOptions;
    }

    resetTracking = () => {
        return {
            AssignOffice: {
                value: null,
                error: false,
                errorMessage: ""
            },
            JobNumber: {
                value: "",
                error: false,
                errorMessage: ""
            },
            SalesPerson: {
                value: "",
                error: false,
                errorMessage: ""
            },
            Price: {
                value: 0,
                error: false,
                errorMessage: ""
            },
            Currency: {
                value: "",
                error: false,
                errorMessage: ""
            },
            PoNumber: {
                value: "",
                error: false,
                errorMessage: ""
            },
            QuotationNumber: {
                value: "",
                error: false,
                errorMessage: ""
            },
        }
    }

    resetSiteDetails = () => {
        return {
            Comments: {
                value: "",
                error: false,
                errorMessage: ""
            },
            MainInspector: {
                value: null,
                error: false,
                errorMessage: ""
            },
            Inspectors: {
                value: null,
                error: false,
                errorMessage: ""
            },
            ClientUsers: {
                value: null,
                error: false,
                errorMessage: ""
            },
        }
    }

    resetTasks = () => {
        return {
            TaskDetails: [
                {
                    status: 1,
                    dueDate: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    TaskType: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    AssetType: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    Asset: {
                        value: null,
                        error: false,
                        errorMessage: "",
                        loading: false
                    },
                    Inspector: {
                        value: null,
                        error: false,
                        errorMessage: ""
                    },
                    AssetOptions: []
                }
            ]
        }
    }

    sortTasks = (result, sortField, order) => {
        switch (sortField) {
            case "modifiedDate":
            case "actualInspectionDate":
                result = result.sort((a, b) => {
                    if (moment(a[sortField]).isAfter(moment(b[sortField]))) {
                        if (order === "asc") {
                            return 1;
                        }
                        else {
                            return -1
                        }
                    }
                    else if (moment(b[sortField]).isAfter(moment(a[sortField]))) {
                        if (order === "asc") {
                            return -1;
                        }
                        else {
                            return 1
                        }
                    }
                    return 0;
                });
                break;

            default:
                result = result.sort((a, b) => {
                    if (a[sortField] > b[sortField]) {
                        if (order === "asc") {
                            return 1;
                        }
                        else {
                            return -1
                        }
                    }
                    else if (b[sortField] > a[sortField]) {
                        if (order === "asc") {
                            return -1;
                        }
                        else {
                            return 1
                        }
                    }
                    return 0;
                });
                break;
        }

        return result;
    }

    createChildLLGTasksMenu = (ChildLLGTasks) => {

        let childLLGTaskLinks = [];

        ChildLLGTasks.forEach((childLLGTask) => {
            childLLGTaskLinks.push({
                title: `${childLLGTask.description} ${childLLGTask.certificateNumber ? ", Certificate Number: " + childLLGTask.certificateNumber : ""}`,
                name: `${childLLGTask.description}`,
                displayName: `${childLLGTask.name}`,
                url: `${childLLGTask.certificateStatus}`,
                key: `${childLLGTask.id}`,
                isExpanded: true,
                status: childLLGTask.status,
                archived: childLLGTask.archived
            })
        })

        let childLLGTasksNavMenu = [
            {
                links: childLLGTaskLinks,
            },
        ];

        return childLLGTasksNavMenu;
    }


    createChildLLGTasksList = (ChildLLGTasks) => {

        return ChildLLGTasks.map(llgTask => {
            llgTask.selected = false;
            return llgTask
        })

    }


    createChildLLGTasksMenu = (ChildLLGTasks) => {

        let childLLGTaskLinks = [];

        ChildLLGTasks.forEach((childLLGTask) => {
            childLLGTaskLinks.push({
                title: `${childLLGTask.description} ${childLLGTask.certificateNumber ? ", Certificate Number: " + childLLGTask.certificateNumber : ""}`,
                name: `${childLLGTask.description}`,
                displayName: `${childLLGTask.name}`,
                url: `${childLLGTask.certificateStatus}`,
                key: `${childLLGTask.id}`,
                isExpanded: true,
                status: childLLGTask.status,
                archived: childLLGTask.archived,
                selected: false
            })
        })

        let childLLGTasksNavMenu = [
            {
                links: childLLGTaskLinks,
            },
        ];

        return childLLGTasksNavMenu;
    }

    onFormatDate = (date) => {
        if (date) {
            return moment.utc(date).local().format('DD-MMM-YYYY');
        }
        return "";
    }

    shouldAmenButtonDisabled = (ChildLLGTasks) => {

        let isAmendDisabled = false;
        ChildLLGTasks[0].links.forEach((childLLGTask) => {
            let certificateStatus = parseInt(childLLGTask.url);
            let archived = childLLGTask.archived;
            if (archived !== 1 && (certificateStatus < 30 || certificateStatus > 39)) {
                isAmendDisabled = true;
            }
        });

        return isAmendDisabled;
    }

    populateFormList = (formDatails) => {
        formDatails.forEach((element) => {
            element.value = parseInt(element.formRecordId);
            element.label = element.formName;
        });
        return formDatails;
    }

    removeIsMultipleFromFormData = (formData) => {
        formData.forEach(element => {
            if (!element.multiple) {
                delete element["multiple"]
            }
        });
        return formData;
    }

    populateFormValue = (element, value) => {

        if (value) {
            switch (element.type) {

                case "textarea":
                case "number":
                case "text":
                    element.userData = [value];
                    break;

                case "date":
                    element.userData = [moment(new Date(parseInt(value))).format('YYYY-MM-DD')];
                    break;

                case "select":
                case "radio-group":
                    element.values.forEach(item => {
                        item.selected = (item.value === value);
                    });
                    if (!element.multiple) {
                        delete element["multiple"]
                    }
                    break;
                case "file":
                    console.log("Element: ", element);
                    console.log("value: ", value);
                    break;

                default:
                    break;
            }
        }
        else {
            switch (element.type) {

                case "textarea":
                case "number":
                case "text":
                    element.userData = "";
                    break;

                case "date":
                    element.userData = "";
                    break;
                case "select":
                case "radio-group":
                    element.values.forEach(item => {
                        item.selected = false;
                    });
                    if (!element.multiple) {
                        delete element["multiple"]
                    }
                    break;

                default:
                    break;
            }

        }

        return element;
    }

    updateAttributes = (oldAttributes, newUserData) => {
        let newAttributes = { ...oldAttributes }
        newUserData.forEach(element => {
            switch (element.type) {

                case "textarea":
                case "number":
                case "text":
                    newAttributes[element.name] = element.userData ? element.userData[0] : "";
                    break;
                case "select":
                case "radio-group":
                    newAttributes[element.name] = element.userData ? element.userData[0] : "";
                    if (newAttributes.hasOwnProperty(`${element.name}_label`) && element?.userData) {
                        newAttributes[`${element.name}_label`] =
                            element.values.find(val => val.value === element.userData[0]).label;
                    }
                    else {
                        newAttributes[`${element.name}_label`] = "";
                    }
                    break;

                case "date":
                    if (element.userData) {
                        newAttributes[element.name] = new Date(element.userData[0]).getTime().toString();
                    }
                    else {
                        newAttributes[element.name] = null;
                    }
                    break;

                default:
                    break;
            }
        });
        return newAttributes;
    }

    removeUserData = (formData) => {
        formData.forEach((element) => {
            delete element["userData"]
        });

        return formData;
    }

    addAttributes = (newUserData) => {
        let newAttributes = {}
        newUserData.forEach(element => {
            newAttributes[element.name] = "";
            switch (element.type) {

                case "textarea":
                case "number":
                case "text":
                    newAttributes[element.name] = element?.userData ? element?.userData[0] : "";
                    break;
                case "select":
                case "radio-group":
                    newAttributes[element.name] = element?.userData ? element?.userData[0] : "";
                    if (element?.userData) {
                        newAttributes[`${element.name}_label`] =
                            element.values.find(val => val.value === element?.userData[0]).label;
                    }
                    else {
                        newAttributes[`${element.name}_label`] = "";
                    }
                    break;

                case "date":
                    if (element.userData) {
                        newAttributes[element.name] = new Date(element.userData[0]).getTime().toString();

                    }
                    else {
                        newAttributes[element.name] = null;
                    }
                    break;

                default:
                    break;
            }
        });
        return newAttributes;
    }

    addAttributesFiles = (newUserData, attributesFiles) => {
        attributesFiles = attributesFiles || {};
        if (attributesFiles.obsFiles) {
            attributesFiles.obsFiles = attributesFiles.obsFiles.filter((file) => !file.isDeletedLocally)
        }
        if (attributesFiles.file) {
            attributesFiles.file = attributesFiles.file.filter((file) => !file.isDeletedLocally)
        }
        if (attributesFiles.image) {
            attributesFiles.image = attributesFiles.image.filter((file) => !file.isDeletedLocally)
        }
        if (attributesFiles[Object.keys(attributesFiles)[0]]) {
            attributesFiles[Object.keys(attributesFiles)[0]] =
                attributesFiles[Object.keys(attributesFiles)[0]].filter((file) => !file.isDeletedLocally)
        }

        newUserData.forEach(element => {
            switch (element.type) {

                case "file":
                    if (element.userData) {
                        element.userData.forEach((userData) => {
                            const qsFile = {};
                            qsFile.fileName = userData;
                            if (userData.indexOf(".jpg") > -1 || userData.indexOf(".png") > -1) {
                                qsFile.description = `image-image-${element.name}-${attributesFiles[element.name] ?
                                    (attributesFiles[element.name].length + 1) : 1}`;
                                qsFile.groupName = "image";
                                qsFile.fileType = "image";
                            }
                            else {
                                qsFile.description = `file-attachment-${element.name}-${attributesFiles[element.name] ?
                                    (attributesFiles[element.name].length + 1) : 1}`;
                                qsFile.groupName = element.type;
                                qsFile.fileType = "attachment";
                            }
                            if (qsFile.fileName) {
                                if (attributesFiles[element.name]) {
                                    attributesFiles[element.name].push(qsFile);
                                } else {
                                    attributesFiles[element.name] = [qsFile];
                                }
                            }
                        });
                    }
                    break;

                default:
                    break;
            }
        });
        return attributesFiles;
    }

    filterAssets = (AssetList, name, assetType, client, site) => {
        let filteredAssetList = [...AssetList];

        if (name) {
            filteredAssetList = filteredAssetList.filter((data) => data.name.toUpperCase().indexOf(name.toUpperCase()) > -1);
        }

        if (assetType) {
            filteredAssetList = filteredAssetList.filter((data) => data.assetTypeName.toUpperCase().indexOf(assetType.toUpperCase()) > -1);
        }

        if (client) {
            filteredAssetList = filteredAssetList.filter((data) => data.client.toUpperCase().indexOf(client.toUpperCase()) > -1);
        }

        if (site) {
            filteredAssetList = filteredAssetList.filter((data) => data.site.toUpperCase().indexOf(site.toUpperCase()) > -1);
        }

        return filteredAssetList;
    }


}


