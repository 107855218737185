import moment from "moment";
import { CommonUtility } from './../../../CommonUtility/CommonUtility';

export class FormUtility {
    generateFormList = (formData) => {
        let FormObjects = [

            // {
            //     name: 'ASSET TYPE',
            //     isExpanded: true,
            //     key: "ASSET TYPE",
            //     disabled: true,
            //     links: [],
            // },
            // {
            //     name: 'TASK TYPE',
            //     disabled: true,
            //     links: [],
            //     key: "TASK TYPE",
            //     disabled: true,
            //     isExpanded: true,
            // },
            // {
            //     name: 'OBSERVATIONS',
            //     key: 'OBSERVATIONS',
            //     links: [],
            //     isExpanded: true,
            //     disabled: true,
            // },
            // {
            //     name: 'LLG',
            //     key: 'LLG',
            //     links: [],
            //     isExpanded: true,
            //     disabled: true,
            // },
            // {
            //     name: 'MISC',
            //     key: 'MISC',
            //     links: [],
            //     isExpanded: true,
            //     disabled: true,
            // },

        ];

        let structuredData = [];

        // eslint-disable-next-line
        formData.map((data) => {

            let jsonData = JSON.parse(data.settingWithMetadata);

            let samplesmin = jsonData.settingmetadata.find(data => data.meta_key === "samplesmin");
            let samplesmax = jsonData.settingmetadata.find(data => data.meta_key === "samplesmax");
            let formtype = jsonData.settingmetadata.find(data => data.meta_key === "formtype");
            let formobject = jsonData.settingmetadata.find(data => data.meta_key === "formobject");
            let formjson = jsonData.settingmetadata.find(data => data.meta_key === "formjson");

            structuredData.push({
                id: jsonData.id,
                name: jsonData.name,
                key: jsonData.id,
                samplesmin: samplesmin ? samplesmin.meta_value : null,
                samplesmax: samplesmax ? samplesmax.meta_value : null,
                formtype: formtype ? formtype.meta_value : null,
                formobject: formobject ? formobject.meta_value : null,
                formjson: formjson ? formjson.meta_value : null,
                tenantid: jsonData.tenant_id,
                createdby: jsonData.created_by,
                createdDate: jsonData.created_date
            });
        });

        // eslint-disable-next-line
        structuredData.map((data) => {
            let parentFormObject = FormObjects.findIndex(item => item.name === data.formobject);
            if (parentFormObject === -1) {
                FormObjects.push({
                    name: data.formobject,
                    key: data.formobject,
                    links: [],
                    isExpanded: false,
                    disabled: true,
                });
            }
        });

        // eslint-disable-next-line
        structuredData.map((data) => {
            let parentFormObject = FormObjects.findIndex(item => item.name === data.formobject);
            FormObjects[parentFormObject].links.push({
                id: data.id,
                name: data.name,
                key: data.id,
                samplesmin: data.samplesmin,
                samplesmax: data.samplesmax,
                formtype: data.formtype,
                formobject: data.formobject,
                formjson: JSON.parse(data.formjson),
                tenantid: data.tenantid,
                createdby: data.createdby,
                createdDate: data.createdDate
            });
            FormObjects[parentFormObject].links = CommonUtility.sortByColumn(FormObjects[parentFormObject].links, "name", "asc");
        });
        return FormObjects;
    }

    generateFormJSONPayLoad = (FormDetails) => {

        let payloadJSON = {};
        if (!FormDetails.id) {
            payloadJSON = {
                "tenant_id": 2,
                "object_type": "FORM",
                "name": FormDetails.name,
                "description": null,
                "type": 0,
                "status": null,
                "parent_id": null,
                "user_data": null,
                "created_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "modified_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "created_by": 2,
                "last_modified_by": null,
                "external_id": null,
                "client_id": null,
                "display_name": null,
                "acronym_code": null,
                "archived": 0,
                "settingmetadata": [
                    {
                        "tenant_id": 2,
                        "meta_key": "samplesmin",
                        "meta_value": FormDetails.samplesmin,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Min"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "samplesmax",
                        "meta_value": FormDetails.samplesmax,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Max"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "formtype",
                        "meta_value": FormDetails.formtype,
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Form Type"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "formobject",
                        "meta_value": FormDetails.formobject,
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Form Object"
                    },
                    {
                        "tenant_id": 2,
                        "meta_key": "formjson",
                        "meta_value": JSON.stringify(JSON.parse(FormDetails.formjson)),
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Form JSON"
                    }
                ]
            }
        }
        else {
            payloadJSON = {
                "id": FormDetails.id,
                "tenant_id": 2,
                "object_type": "FORM",
                "name": FormDetails.name,
                "description": null,
                "type": 0,
                "status": null,
                "parent_id": null,
                "user_data": null,
                "created_date": moment(new Date(FormDetails.createdDate)).format("YYYY-MM-DD HH:mm:ss"),
                "modified_date": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                "created_by": 2,
                "last_modified_by": null,
                "external_id": null,
                "client_id": null,
                "display_name": null,
                "acronym_code": null,
                "archived": 0,
                "settingmetadata": [
                    {
                        "setting_id": FormDetails.id,
                        "tenant_id": 2,
                        "meta_key": "samplesmin",
                        "meta_value": FormDetails.samplesmin,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Min"
                    },
                    {
                        "setting_id": FormDetails.id,
                        "tenant_id": 2,
                        "meta_key": "samplesmax",
                        "meta_value": FormDetails.samplesmax,
                        "data_type": "INTEGER",
                        "object_type": null,
                        "display_name": "Max"
                    },
                    {
                        "setting_id": FormDetails.id,
                        "tenant_id": 2,
                        "meta_key": "formtype",
                        "meta_value": FormDetails.formtype,
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Form Type"
                    },
                    {
                        "setting_id": FormDetails.id,
                        "tenant_id": 2,
                        "meta_key": "formobject",
                        "meta_value": FormDetails.formobject,
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Form Object"
                    },
                    {
                        "setting_id": FormDetails.id,
                        "tenant_id": 2,
                        "meta_key": "formjson",
                        "meta_value": JSON.stringify(JSON.parse(FormDetails.formjson)),
                        "data_type": "STRING",
                        "object_type": null,
                        "display_name": "Form JSON"
                    }
                ]
            }
        }



        return payloadJSON;
    }
}