import React, { PureComponent } from 'react'
import { ActionButton, IconButton, PrimaryButton, CommandButton } from 'office-ui-fabric-react'
import filterFactory from 'react-bootstrap-table2-filter';
import LoadingOverlay from 'react-loading-overlay';
import { FadeLoader } from 'react-spinners';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { DeletedRowStyle, IconConstants } from './../../../../Constants/StylesConstants';
import { CommonUtility } from './../../../../CommonUtility/CommonUtility';
import { DivisionAPI } from './../Services/DivisionAPI';
import AddEditDivisionPanel from './AddEditDivisionPanel';
import ConfirmDivisionRedux from './ConfirmDivisionRedux';
import AlertRedux from '../../../Alert/AlertRedux';

const defaultSorted = [{
    dataField: 'status',
    order: 'asc'
}];

const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="table-export-button-div">
            <PrimaryButton
                onClick={handleClick}
                text="Export to CSV"
                menuIconProps={IconConstants.exportIcon}
            />
        </div>
    );
};

const RemoteAll = ({
    data, columns, onTableChange, page, hideSizePerPage, style
}) => (

    <React.Fragment>

        <ToolkitProvider
            bootstrap4
            keyField="id"
            columns={columns}
            data={data}
            exportCSV={{
                fileName: 'Qwikspec_Division.csv',
                noAutoBOM: false,
                blobType: 'text/csv;charset=ansi'
            }}
        >
            {
                props => (
                    <React.Fragment>
                        <MyExportCSV {...props.csvProps} />
                        <BootstrapTable
                            remote
                            condensed
                            hover
                            striped
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            // pagination={paginationFactory({ page, hideSizePerPage })}
                            onTableChange={onTableChange}
                            {...props.baseProps}
                            rowClasses={DeletedRowStyle}
                            wrapperClasses="table-responsive" />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>
    </React.Fragment>
);


export default class DivisionDashboard extends PureComponent {

    _divisionApi = null;

    constructor(props) {
        super(props);
        this._divisionApi = new DivisionAPI(window.API_URL, window.loggedInUserDetails);

    }

    componentDidMount() {
        this.loadInitData();
    }

    loadInitData = () => {

        this.props.cleanDashboard({
            offset: 0,
        });

        this.props.loadDivisionDashboard({
            objectType: "DIVISION",
            offset: 0,
            limit: this.props.Division.limit,
            apiObject: this._divisionApi,
        });

    }

    fetchMoreData = () => {

        this.props.loadDivisionDashboard({
            objectType: "DIVISION",
            offset: this.props.Division.offset,
            limit: this.props.Division.limit,
            apiObject: this._divisionApi,
        });

    };




    render() {

        let columns = [
            {
                dataField: 'manageDivisionIcons',
                isDummyField: true,
                text: 'Action',
                formatter: (cellContent, row) => {
                    return (
                        <span>
                            {row.status === 1 ?
                                <React.Fragment>
                                    <IconButton
                                        onClick={() => this.props.loadAttributeDropDowns({
                                            apiObject: this._divisionApi,
                                            objectType: "DIVISION",
                                            PanelHeaderText: "",
                                            SelectedDivision: row,
                                        })}
                                        iconProps={IconConstants.editicon} />
                                    <IconButton
                                        onClick={() => {
                                            this.props.openCloseDivisionConfirmModal({
                                                hideConfirm: false,
                                                CurrentDivision: row
                                            });
                                        }}
                                        iconProps={IconConstants.deleteicon} />
                                </React.Fragment>
                                :
                                <span></span>
                            }
                        </span>
                    )
                },
                csvExport: false
            },
            {
                dataField: 'id',
                text: 'Division ID',
                hidden: true,
                csvExport: false
            },

            {
                dataField: 'name',
                text: 'Name',
            },
            {
                dataField: 'acronym_code',
                text: 'Code',
            },

            // {
            //     dataField: 'group',
            //     text: 'Group',
            // },
            // {
            //     dataField: 'category',
            //     text: 'Category',
            // },
            // {
            //   dataField: 'description',
            // text: 'Description',
            //},
            // {
            //     dataField: 'reference',
            //     text: 'Data Form',
            // },

        ];

        let {
            LoadingDivisionDashboard,
            Divisions,
            HasMoreDivisions,
            ShowDivisionAddEditPanel,
            hideConfirm,
            alertJSON
        } = this.props.Division;

        let filteredDivision = [...Divisions];
        // if (ShowActiveTasks) {
        //     filteredTask = [...Tasks.filter(task => task.status === 1 || task.status === 2)]
        // }
        // else {
        //     filteredTask = [...Tasks.filter(task => task.status === 4 || task.status === 8 || task.status === 16 || task.status === 1024)]
        // }
        filteredDivision = CommonUtility.sortByColumn(filteredDivision, "status", "asc");

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ActionButton
                                text="Reload"
                                iconProps={IconConstants.refreshIcon}
                                onClick={() => this.loadInitData()}
                            />
                            <ActionButton
                                text="Add"
                                iconProps={IconConstants.addIcon}
                                onClick={() => this.props.loadAttributeDropDowns({
                                    apiObject: this._divisionApi,
                                    objectType: "DIVISION",
                                    PanelHeaderText: "Add Division"
                                })}
                            />
                            {HasMoreDivisions && !LoadingDivisionDashboard &&
                                <CommandButton
                                    text="Load more"
                                    iconProps={IconConstants.moreicon}
                                    onClick={() => this.fetchMoreData()}
                                />
                            }
                        </div>
                    </div>
                </div>

                <LoadingOverlay
                    active={LoadingDivisionDashboard}
                    spinner={<FadeLoader />}
                    text="Loading Divisions...">
                    <div className="lazy-load-div" >
                        <RemoteAll
                            data={filteredDivision}
                            columns={columns}
                            page={1}
                            hideSizePerPage={true}
                        // onTableChange={this.handleTableChange}
                        />
                    </div>

                </LoadingOverlay>

                {
                    ShowDivisionAddEditPanel &&
                    <AddEditDivisionPanel
                        {...this.props}
                    />
                }
                {!hideConfirm &&
                    <ConfirmDivisionRedux
                        {...this.props}
                    />
                }
                {!alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
            </React.Fragment>
        )
    }
}
