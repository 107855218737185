import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import { IconConstants } from '../../../Constants/StylesConstants';
import moment from 'moment-timezone';
import { DATE_FORMAT } from '../../../Constants/MessageConstants';
import { RESULT_DETAILS } from '../Constants/ReportsConstants';

export class DateCustomCell extends React.Component<any, any> {
    render() {

        const value = this.props.dataItem[this.props.field];

        return (
            <td className="no-group-show">
                {value ?
                    <span>{moment.utc(new Date(value)).local().format(DATE_FORMAT)}</span>
                    :
                    <span></span>
                }
            </td>
        );
    }
}

export class IdCustomCell extends React.Component<any, any> {
    render() {

        const value = this.props.dataItem[this.props.field];

        return (
            <td className="no-group-show">
                <Icon
                    onClick={() => {
                        this.props.currentProps.showHideCertificatePanel({
                            isHideCertificatePanel: false,
                            selectedTaskId: value
                        })

                    }}
                    iconName={IconConstants.pdfIcon.iconName}
                    styles={{
                        root: {
                            color: "#403cbd"
                        }
                    }}
                />
            </td>
        );
    }
}

export class ResultCustomCell extends React.Component<any, any> {
    render() {

        const value = this.props.dataItem[this.props.field];
        const showText = RESULT_DETAILS[value]?.showText;
        const color = RESULT_DETAILS[value]?.color;

        return (
            <td className="no-group-show">
                <span style={{ color: color }}>{showText}</span>
            </td>
        );
    }
}