import * as CONSTANTS from "../Constants/DivisionConstants";
import { ILoggedInUser } from '../../../../CommonInterfaces/CommoInterfaces';

import { ALERT_MESSAGE } from "../../../../Constants/MessageConstants";
import axios from 'axios';


export class DivisionAPI {
    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }
    _getChunkDivision(objectType: string, offset: number, limit: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_DIVISIONS_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=${objectType}&`)
                .concat(`offset=${offset}&`)
                .concat(`limit=${limit}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllDivisions method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    getAllFormAttributes(objectType) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_FORM_ATTRIBUTE_LIST)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`formobject=${objectType}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllFormAttributes method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    saveDivisionConfigData = (payload) => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(CONSTANTS.GET_DIVISIONS_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`), payload)
                .then((response) => {
                    resolve(true);
                }).catch((error) => {
                    console.error("Error saveFormConfigData", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    deleteDivision = (payload) => {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(CONSTANTS.GET_DIVISIONS_URL)
                .concat(`/${payload.id}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`))
                .then((response) => {
                    resolve(response);
                }).catch((error) => {
                    console.error("Error deleteDivision", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    getDivisionDetails(objectType: string, offset: number, limit: number): Promise<any> {
        let promises = [this._getChunkDivision(objectType, offset, limit)];
        return Promise.all(promises);
    }


    /*_getChunkDivision(objectType, offset, limit) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_DIVISIONS_URL)
               // .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=${objectType}&`)
                .concat(`offset=${offset}&`)
                .concat(`limit=${limit}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getChunkDivision method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }*/
    
}