import { PrimaryButton } from 'office-ui-fabric-react'
import * as React from 'react'
import { IconConstants } from '../../../Constants/StylesConstants'

export default class DownloadTemplate extends React.PureComponent {
    render() {
        return (

            <a href={"ClientInventoryTemplateFormat.csv"} download="ClientInventoryTemplateFormat.csv"> <PrimaryButton
                styles={{
                    root: {
                        cursor: "pointer"
                    }
                }}
                text="Download Template"
                iconProps={IconConstants.excelIcon}
                menuIconProps={IconConstants.downloadIcon}
            /> </a>
        )
    }
}
