
export const CHANGE_DIVISION_TAB = "CHANGE_DIVISION_TAB";
export const CLOSE_DIVISION_DETAILS_PANEL = "CLOSE_DIVISION_DETAILS_PANEL";

//DASHBOARD
export const CLEAN_DIVISION_DASHBOARD = 'CLEAN_DIVISION_DASHBOARD';
export const LOAD_DIVISION_DASHBOARD = 'LOAD_DIVISION_DASHBOARD';
export const FIX_DIVISIION_DASHBOARD = 'FIX_DIVISION_DASHBOARD';
export const SORT_DIVISION_DASHBOARD = 'SORT_DIVISION_DASHBOARD';
export const OPEN_CLOSE_DIVISION_ALERT_MODAL = 'OPEN_CLOSE_DIVISION_ALERT_MODAL';
export const TOGGLE_DIVISION_VIEW = 'TOGGLE_DIVISION_VIEW';

//PANEL
export const LOAD_DIVISION_ATTRIBUTES = 'LOAD_DIVISION_ATTRIBUTES';

//Description
export const CHANGE_DIVISION_NAME = 'CHANGE_DIVISION_NAME';
export const CHANGE_DIVISION_ACRONYM_CODE = 'CHANGE_DIVISION_ACRONYM_CODE';
export const CHANGE_DIVISION_GROUP_NAME = 'CHANGE_DIVISION_GROUP_NAME';
export const CHANGE_DIVISION_CATEGORY = 'CHANGE_DIVISION_CATEGORY';
export const CHANGE_DIVISION_DESCRIPTION = 'CHANGE_DIVISION_DESCRIPTION';
export const CHANGE_DIVISION_DISPLAY_NAME = 'CHANGE_DIVISION_DISPLAY_NAME';

//PREVIEW
export const CHANGE_DIVISION_FORM_ATTRIBUTE = 'CHANGE_DIVISION_FORM_ATTRIBUTE';

// SAVE
export const SAVE_DIVISION_DETAILS = 'SAVE_DIVISION_DETAILS';

// DELETE
export const OPEN_CLOSE_DIVISION_CONFIRM_MODAL = 'OPEN_CLOSE_DIVISION_CONFIRM_MODAL';
export const DELETE_DIVISION = 'DELETE_DIVISION';

//URL
export const GET_DIVISIONS_URL = "Setting";
export const GET_FORM_ATTRIBUTE_LIST = "Setting/FormObject";

export const DIVISION_FORM_TABS = {
    0: "DESCRIPTION",
    1: "CODE",
    2: "NAME",
}

