import React, { Component } from 'react'
import { Label, Panel, PanelType } from 'office-ui-fabric-react'
import ClientDetails from './ClientDetails';
import TrackingDetails from './TrackingDetails';
import SiteDetails from './SiteDetails';
import TaskDetails from './TaskDetails';
import { JobLitesAPI } from '../Services/JobLitesAPI';
import AlertRedux from '../../Alert/AlertRedux';
import { JobLitesUtility } from '../Utility/JobLitesUtility';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import JobLiteOperation from './JobLiteOperation';

export default class AddEditJobLitesPanel extends Component {

    _apiObject = null;
    _jobutility;
    constructor(props) {
        super(props);

        this.state = {
            error: false,
        };

        this._apiObject = new JobLitesAPI(window.API_URL, window.loggedInUserDetails);
        this._jobutility = new JobLitesUtility();
    }

    componentDidMount() {

        this.props.loadClientsAndUsersAsync({
            apiObject: this._apiObject,
            locationLevelClient: 0,
            locationLevelOffice: 2,
            IsEdit: false,
            SelectedJob: this.props.JobLites.SelectedJob
        })
    }

    componentDidCatch(error) {
        // Changing the state to true
        // if some error occurs
        this.setState({
            error: true
        });
    }

    saveJob = (cancelFlag) => {

        let { CurrentJobDetails, AllTaskTypeOptions, AssetTypeOptions } = this.props.JobLites;

        this.props.addJobAsync({
            apiObject: this._apiObject,
            CurrentJobDetails: CurrentJobDetails,
            cancelFlag: cancelFlag,
            AllTaskTypeOptions,
            AssetTypeOptions
        });

    }

    render() {

        let {
            PanelHeaderText,
            ShowPanel,
            LoadingPanel,
            alertJSON,
            IsJobEditable
        } = this.props.JobLites;

        let { error } = this.state;


        return (
            <Panel
                headerText={PanelHeaderText}
                isOpen={ShowPanel}
                type={PanelType.extraLarge}
                onDismiss={() => this.props.openClosePanel({
                    ShowPanel: false,
                    PanelHeaderText: "",
                })}
                styles={{
                    scrollableContent: {
                        height: "95vh"
                    }
                }}
                closeButtonAriaLabel="Close">
                {error ?
                    <div>Some error</div> :
                    <LoadingOverlay
                        active={LoadingPanel}
                        spinner={<FadeLoader />}
                        text="Please wait...">
                        {IsJobEditable ?
                            <React.Fragment>
                                <ClientDetails  {...this.props} />
                                <TrackingDetails {...this.props} />
                                <SiteDetails {...this.props} />
                                <TaskDetails {...this.props} />
                                <JobLiteOperation {...this.props} />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <h2 className="text-secondary">
                                    <Label
                                        styles={{
                                            root: {
                                                transform: "rotate(-25deg)",
                                                fontSize: "1.75em",
                                                margin: "4.25em 0.5em"
                                            }
                                        }}
                                        className="mandatory-span">
                                        Sorry!! you can not edit this job
                                    </Label>
                                </h2>
                            </React.Fragment>
                        }

                        {
                            !alertJSON.hideAlert &&
                            <AlertRedux
                                {...this.props}
                            />
                        }
                    </LoadingOverlay>
                }
            </Panel>
        )
    }
}
