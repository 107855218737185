import React, { PureComponent } from 'react';
// import { TaskTypeAPI } from '../Services/TaskTypeAPI';
import Select from 'react-select';
import { Label, TextField } from 'office-ui-fabric-react';
import { CommonUtility } from '../../../../CommonUtility/CommonUtility';
// import { JSONViewStyleConstants } from '../../../../Constants/StylesConstants';
// import JSONInput from 'react-json-editor-ajrm';
// import locale from 'react-json-editor-ajrm/locale/en';

export default class Attributes extends PureComponent {

    // _taskApi = null;

    // constructor(props) {
    //     super(props);
    //     this._taskApi = new TaskTypeAPI(window.API_URL);
    // }

    render() {

        let {
            FormAttributeOptions,
            AssetTypeOptions,
            CurrentTaskType
        } = this.props.TaskType;

        return (
            <React.Fragment>
                <div className="ms-Grid task-preview-section" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Label className="breadcrumb-label">Asset Type</Label>
                            <Select
                                placeHolder="Select Template"
                                value={CurrentTaskType.asset_type}
                                onChange={(newValue) => {
                                    this.props.changeTaskTypeAttributes({
                                        field: "assetType",
                                        asset_type: newValue
                                    });
                                }}
                                options={CommonUtility.sortByColumn(AssetTypeOptions, "label", "asc")}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Label className="breadcrumb-label">Report Templates</Label>
                            <Select
                                placeHolder="Select Template"
                                value={CurrentTaskType.attribute_form}
                                onChange={(newValue) => {
                                    this.props.changeTaskTypeAttributes({
                                        field: "form",
                                        attribute_form: newValue
                                    });
                                }}
                                options={CommonUtility.sortByColumn(FormAttributeOptions, "label", "asc")}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Label className="breadcrumb-label">Task Checklist</Label>
                            <TextField
                                value={CurrentTaskType.sectionsJson}
                                onChange={(ev, newVal) => {
                                    // value.plainText = value.plainText.replace(/"/g, "'");
                                    this.props.changeTaskTypeAttributes({
                                        field: "checkList",
                                        sectionsJson: newVal
                                    })
                                }}
                                multiline
                                errorMessage={!CurrentTaskType.IsValidSectionJSON ? "Please Enter a valid JSON" : ""}
                                styles={{
                                    fieldGroup: {
                                        height: "50vh",
                                        border: "0.25px solid #e6e6e6",
                                    },
                                }}
                            />
                            {/* <JSONInput
                                id='2'
                                placeholder={CurrentTaskType.sectionsJson}
                                onChange={(newValue) => {
                                    newValue.plainText = newValue.plainText.replace(/"/g, "'");
                                    this.props.changeTaskTypeAttributes({
                                        field: "checkList",
                                        sectionsJson: newValue
                                    });
                                }}
                                style={JSONViewStyleConstants}
                                locale={locale}
                                height='50vh'
                            // onC
                            /> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
