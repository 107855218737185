import axios from 'axios';

export default class CommonAPIServices {

    _URL = "";

    constructor(URL) {
        this._URL = URL;
    }

    getUserDetails() {
        let promises = [this.isUserValid(), this.getCustomerLogo()];
        return Promise.all(promises);
    }

    isUserValid() {
        let jsessionid = sessionStorage.getItem("jsessionid");
        return new Promise((resolve, reject) => {
            // if (window.loggedInUserDetails) {
            axios.get(this._URL.concat('login' + (jsessionid ? `;jsessionid=${jsessionid}` : ``))).then((response) => {
                if (response.data.response.data.length > 0) {
                    resolve(response.data.response.data);
                }
                else {
                    resolve(false);
                }
            }).catch((error) => {
                reject("Error while Login: ", error);
            })

        })
    }
    getCustomerLogo() {
        let jsessionid = sessionStorage.getItem("jsessionid");
        let companyLogo = sessionStorage.getItem("companyLogo");
        let fileType = sessionStorage.getItem("fileType");
        if (companyLogo && fileType && !sessionStorage.getItem("isNewImageUpoaded")) {
            return new Promise((resolve, reject) => {
                let logoData = {
                    companyLogo, fileType
                }
                resolve(logoData);
            });
        }
        else {
            if (jsessionid) {
                return new Promise((resolve, reject) => {
                    // if (window.loggedInUserDetails) {
                    axios.get(this._URL.concat(`CustomerRegistration/getImageFromSession;jsessionid=${jsessionid}`)).then((response) => {
                        sessionStorage.setItem("isNewImageUpoaded", "");
                        resolve(response.data);
                    }).catch((error) => {
                        reject("Error while getCustomerLogo: ", error);
                    })

                })
            }
        }

    }

}
