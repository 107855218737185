import {
    GET_USERS_URL,
    SAVE_SCHEDULE,
    GET_ASSET_URL,
    SAVE_ALL_OBSERVATIONS,
    GET_LOCATION_OBJECT_URL,
    GET_SETTING_OBJECTS,
    SAVE_LOCATION,
    SAVE_LOCATION_URL, SAVE_USER_URL, SEND_NOTIFICATION, GET_CONFIG_JSON_SETTING, SEND_NOTIFICATION_FOR_REASSIGNED_JOB
} from "../Constants/JobLitesConstants";
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import axios from 'axios';
import { IJobLiteService } from "../Interfaces/IJobLitesService";
import { ILoggedInUser } from "../../../CommonInterfaces/CommoInterfaces";
import { ILocation } from "../../Clients/ClientInterface/IClient";

export class JobLitesAPI implements IJobLiteService {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;

    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }

    _getClientsData(objectType: string) {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_LOCATION_OBJECT_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?objectType=${objectType}`)
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error _getClientsData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    public _getUsersData(config: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_USERS_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?offset=-1&limit=-1`)
                , config
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error _getUsersData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    _getAllAssetType() {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat('Setting/Others').concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                .concat(`?objectType=AssetType`)).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getAllAssetType", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    // For New thing

    _getAllSettings(objectType: string, id?: number) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(GET_SETTING_OBJECTS)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                .concat(`?objectType=${objectType}`)
                .concat(id ? `&taskTypeId=${id}` : ``)
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getAllDivisions", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    public _getConfigJsonMetaData() {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(GET_CONFIG_JSON_SETTING)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                .concat(`?metaKey=config_json&name=webUIAccessConfiguration`)
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getConfigJsonMetaData", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    // _getAllTaskType() {
    //     return new Promise((resolve, reject) => {
    //         axios.get(this._URL.concat('Setting/Others').concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
    //             .concat(`?objectType=TaskType`)).then(response => {
    //                 resolve(response);
    //             }).catch(error => {
    //                 console.error("Error _getAllTaskType", error.response);
    //                 if (error.response && error.response.status === 401) {
    //                     reject(ALERT_MESSAGE.ERROR_401);
    //                 }
    //                 else {
    //                     reject(ALERT_MESSAGE.COMMON_ERROR);
    //                 }
    //             })
    //     })
    // }

    public getSitesData = (locationId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_LOCATION_OBJECT_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?objectType=SITE`)
                    .concat(`&companyId=${locationId}`)
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error getSitesData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }


    _getLocationObjects(objectType, companyId?: number) {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_LOCATION_OBJECT_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?objectType=${objectType}`)
                    .concat(companyId ? `&companyId=${companyId}` : ``)
            ).then(responseSize => {
                resolve(responseSize);
            }).catch(error => {
                console.error("Error _getClientsData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    public loadInitData = (): Promise<any> => {
        let promises = [
            this._getLocationObjects("CLIENT_COMPANY"),
            this._getLocationObjects("LO_OFFICE"),
            this._getUsersData(""),
            this._getAllSettings("Division"),
            this._getAllSettings("TaskType"),
            this._getAllSettings("ROLE"),
            this._getAllSettings("AssetTypes"),
            this._getConfigJsonMetaData(),
        ];
        return Promise.all(promises);

    }

    // For New thing ( end )

    public saveJobAPI = (payload: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SAVE_SCHEDULE).concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``), payload).then(responseData => {
                resolve(responseData);
            }).catch(error => {
                console.error("Error saveJobAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 417) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public sendNotification = (payload: any, messageType?: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SEND_NOTIFICATION)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                .concat(messageType ? `?messageType=${messageType}` : ``)
                , payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error sendNotification method", error);
                });
        });
    }

    public sendNotificationForReassignedJob = (payload: any, scheduleId: number, inspectorId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SEND_NOTIFICATION_FOR_REASSIGNED_JOB)
                .concat(`/${scheduleId}/${inspectorId}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                , payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error sendNotification method", error);
                });
        });
    }

    public saveTasks = (payload: any, cancelFlag: boolean, scheduleId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(
                this._URL
                    .concat(SAVE_ALL_OBSERVATIONS)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?cancelFlag=${cancelFlag ? -1 : 1}`)
                    .concat(`&scheduleId=${scheduleId}`)
                , payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error saveTasks method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    public saveAsset = (payload: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SAVE_LOCATION)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``), payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error saveAsset method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    if (error.response && error.response.status === 409) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    public getAssetsData = (assetTypeId): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(
                this._URL
                    .concat(GET_ASSET_URL)
                    .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                    .concat(`?assetTypeId=${assetTypeId}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAssetsData method", error);
                if (error.response && error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            })
        })
    }

    // Load Jobs Start

    public _getJobLitesCountApi = (config: any): Promise<number> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(`Schedule/count`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``), config)
                .then((response: any) => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getJobLitesCountApi method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    public _getJobLitesListApi = (offset: number, limit: number, config: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(`Schedule`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                .concat(`?offset=${offset}&limit=${limit}`), config
            ).then((response: any) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getJobLitesListApi method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getJobLitesApi = (offset: number, limit: number, config: any): Promise<any> => {

        let promises = [this._getJobLitesListApi(offset, limit, config), this._getJobLitesCountApi(config)];
        return Promise.all(promises);

    }

    public _getTasksData(scheduleId: number) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL.concat(`Observation/Schedule`).concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                .concat(`?scheduleId=${scheduleId}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getTasksData method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }


    getSitesAndTasks = (clientId: number, scheduleId: number): Promise<any> => {
        let promises = [this.getSitesData(clientId), this._getTasksData(scheduleId)];
        return Promise.all(promises);

    }

    public getAllAssetOptions = (assetTypeIds: number[]): Promise<any> => {
        let promises = [];
        assetTypeIds.forEach((assetTypeId) => {
            promises.push(this.getAssetsData(assetTypeId));
        });
        return Promise.all(promises);

    }
    // Load Jobs End

    public saveLocation = (locationPayload: ILocation): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(SAVE_LOCATION_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                , locationPayload
            ).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.error("Error saveLocation method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public saveUser = (userPayload: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(SAVE_USER_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
                .concat("?isJob=true")
                , userPayload
            ).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.error("Error saveUser method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public closeOrCancelJob = (scheduleId: number, status: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.delete(this._URL
                .concat(`${SAVE_SCHEDULE}/${scheduleId}/${status}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${this._loggedInUserDetails.jsessionid}` : ``)
            ).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.error("Error closeOrCancelJob method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else if (error.response && error.response.status === 409) {
                    reject(error.response.data.error);
                }
                else {
                    reject(error.response.data.error);
                }
            });
        });
    }

}