export const GET_ENTITY_URL = "clientInventory/getEntityByClientSite";
export const SAVE_ENTITY_URL = "clientInventory/saveEntity";
export const DELETE_ENTITY_URL = "clientInventory/deleteClientEntityById/";

export const RESET_CLIENT_INVENTORY_DASHBOARD = "RESET_CLIENT_INVENTORY_DASHBOARD";
export const LOAD_CLIENT_INVENTORY = "LOAD_CLIENT_INVENTORY";
export const DELETE_CLIENT_INVENTORY = "DELETE_CLIENT_INVENTORY";
export const CHANGE_CLIENT_SELECTOR = "CHANGE_CLIENT_SELECTOR";
export const SAVE_CLIENT_INVENTORY = "SAVE_CLIENT_INVENTORY";
export const CHANGE_CLIENT_INVENTORY_ITEM = "CHANGE_CLIENT_INVENTORY_ITEM";
export const CHANGE_CLIENT_INVENTORY_ITEM_SEARCH = "CHANGE_CLIENT_INVENTORY_ITEM_SEARCH";
export const CHANGE_CLIENT_INVENTORY_TONNE = "CHANGE_CLIENT_INVENTORY_TONNE";
export const CHANGE_CLIENT_INVENTORY_TONNE_SEARCH ="CHANGE_CLIENT_INVENTORY_TONNE_SEARCH";
export const CHANGE_CLIENT_INVENTORY_LOCATION = "CHANGE_CLIENT_INVENTORY_LOCATION";
export const CHANGE_CLIENT_INVENTORY_LOCATION_SEARCH ="CHANGE_CLIENT_INVENTORY_LOCATION_SEARCH";
export const CHANGE_CLIENT_INVENTORY_COMMENTS = "CHANGE_CLIENT_INVENTORY_COMMENTS";
export const CHANGE_CLIENT_INVENTORY_COMMENTS_SEARCH ="CHANGE_CLIENT_INVENTORY_COMMENTS_SEARCH";
export const CHANGE_CLIENT_INVENTORY_MANUFACTURER = "CHANGE_CLIENT_INVENTORY_MANUFACTURER";
export const CHANGE_CLIENT_INVENTORY_MANUFACTURER_SEARCH ="CHANGE_CLIENT_INVENTORY_MANUFACTURER_SEARCH";
export const CHANGE_CLIENT_INVENTORY_DESCRIPTION = "CHANGE_CLIENT_INVENTORY_DESCRIPTION";
export const CHANGE_CLIENT_INVENTORY_DESCRIPTION_SEARCH = "CHANGE_CLIENT_INVENTORY_DESCRIPTION_SEARCH";
export const CHANGE_CLIENT_INVENTORY_QUANTITY = "CHANGE_CLIENT_INVENTORY_QUANTITY";
export const CHANGE_CLIENT_INVENTORY_QUANTITY_SEARCH = "CHANGE_CLIENT_INVENTORY_QUANTITY_SEARCH";
export const CHANGE_CLIENT_INVENTORY_ROW_EDIT = "CHANGE_CLIENT_INVENTORY_ROW_EDIT";
export const OPEN_CLOSE_CLIENT_INVENTORY_ALERT_MODAL = "OPEN_CLOSE_CLIENT_INVENTORY_ALERT_MODAL";
export const OPEN_CLOSE_CLIENT_INVENTORY_CONFIRM_DELETE_MODAL = "OPEN_CLOSE_CLIENT_INVENTORY_CONFIRM_DELETE_MODAL";

export const PRODUCT_CATEGORY = "Product Category";
export const DESCRIPTION = "Description";
export const QUANTITY = "Quantity";
export const COMMENTS = "Comments";
export const TONNE = "Tonne";
export const LOCATION = "Location";
export const MANUFACTURER = "Manufacturer";
export const IS_ENABLED_EDIT = "isEnabledEdit";
export const SITE = "Site";
export const CLIENT = "Client";

export const CLIENT_INVENTORY_BREADCRUMBS = [
    { text: 'RESOURCES', key: 'Resources' },
    { text: 'Client Inventory', key: 'ClientInventory' }
];

export const CLIENT_INVENTORY_TABLE_HEADER =
    [PRODUCT_CATEGORY, DESCRIPTION, QUANTITY,TONNE,MANUFACTURER,LOCATION,COMMENTS,CLIENT, SITE]