import React, { Component } from 'react'
import { IClientSiteSelectorStateProps } from '../ClientSiteSelectorInteface/IClientSiteSelectorStateProps'
import { ClientSiteSelectorAPI } from '../Services/ClientSiteSelectorAPI';
import { IClientSiteSelectorAPI } from '../ClientSiteSelectorInteface/IClientSiteSelector';
import { Label } from 'office-ui-fabric-react';
import { ISelect } from '../../../CommonInterfaces/CommoInterfaces';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { CLIENT_ADMIN_ROLE, CLIENT_USER_ROLE } from '../../../Constants/CommonConstants';
import AlertRedux from '../../Alert/AlertRedux';
import * as Loaders from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';

export default class ClientSiteSelectorDashboard extends Component<IClientSiteSelectorStateProps, any> {

    private _clientSiteSelectorAPI: IClientSiteSelectorAPI = null;

    constructor(props: IClientSiteSelectorStateProps) {
        super(props);
        this._clientSiteSelectorAPI = new ClientSiteSelectorAPI(window.API_URL, window.loggedInUserDetails);
    }

    public componentDidMount(): void {
        this.loadInitData();
    }

    public loadInitData = (): void => {
        this.props.loadClientSelector({
            apiObject: this._clientSiteSelectorAPI,
        })

    }

    public render(): JSX.Element {

        let {
            isLoadingClients,
            isLoadingSites,
            selectedClient,
            selectedSite,
            clients,
            sites,
            alertJSON,

        } = this.props.ClientSiteSelectorState;

        const isClientRole =
            window.loggedInUserDetails.role === CLIENT_ADMIN_ROLE || window.loggedInUserDetails.role === CLIENT_USER_ROLE;

        return (
            <React.Fragment>
                {/* Main Filter */}
                <div className="ms-Grid asset-main-filter-grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Client: <span className="mandatory-span">*</span></Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                            <LoadingOverlay
                                active={isLoadingClients}
                                spinner={<Loaders.BeatLoader />}
                                text="">
                                <Select
                                    value={selectedClient}
                                    placeholder={clients.length > 0 ? `----Select Client----` : ``}
                                    onChange={(newVal: ISelect) => {
                                        this.props.changeClientSelector({
                                            apiObject: this._clientSiteSelectorAPI,
                                            selectedLocation: newVal,
                                            parentId: newVal.value,
                                        })
                                    }}
                                    options={isClientRole ?
                                        CommonUtility.sortByColumn(clients
                                            .filter((client: ISelect) => window.loggedInUserDetails.companyId === client.value), "label", "asc")
                                        :
                                        CommonUtility.sortByColumn(clients, "label", "asc")

                                    }
                                />

                            </LoadingOverlay>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1">
                            <Label>Site: <span className="mandatory-span">*</span></Label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 asset-site-filter">
                            <LoadingOverlay
                                active={isLoadingSites}
                                spinner={<Loaders.BeatLoader />}
                                text="">
                                <Select
                                    isDisabled={!selectedClient}
                                    value={selectedSite}
                                    placeholder={sites.length > 0 ? `----Select Site----` : ``}
                                    onChange={(newVal: ISelect) => {
                                        this.props.changSiteSelector({
                                            apiObject: this._clientSiteSelectorAPI,
                                            selectedLocation: newVal,
                                            parentId: newVal.value,
                                        })
                                    }}
                                    options={CommonUtility.sortByColumn(sites, "label", "asc")}
                                />
                            </LoadingOverlay>
                            {
                                !alertJSON.hideAlert &&
                                <AlertRedux
                                    {...this.props}
                                />
                            }
                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}
