import { ITaskTypeData } from "../Interfaces/TaskTypeInterface";
import { ISelect } from "../../../../CommonInterfaces/CommoInterfaces";
import { IDropdownOption } from "office-ui-fabric-react";

export const TaskTypeInitialState = {
    TaskTypes: [] as ITaskTypeData[],
    LoadingTaskTypeDashboard: false,
    LoadingTaskTypeAddEditPanel: false,
    HasMoreTaskTypes: true,
    SelectedTaskType: null,
    CurrentTaskType: {
        acronym_code: "",
        attribute_form_id: -1,
        division_id: -1,
        asset_type_id: -1,
        inspection_method: "",
        reference_standard: "",
        description: "",
        id: -1,
        name: "",
        attribute_form: null as ISelect,
        IsValidSectionJSON: true
    } as ITaskTypeData,
    DivisionOptions: [],
    dashboardDivisionOption: [] as IDropdownOption[],
    TabIndex: 0,
    ShowActiveTaskType: true,
    hideConfirm: true,
    offset: 0,
    limit: 50,
    alertJSON: {
        hideAlert: true,
        alertHeader: "",
        alertSubText: ""
    },
    ToBeUpdatedStatus: -1,
    PanelHeaderText: "",
    ShowTaskTypeAddEditPanel: false,
    FormAttributeOptions: [] as ISelect[],
    AssetTypeOptions: [] as ISelect[],
    dialogStatus: "copy",
    name_filter_value: "",
    validity_filter_value: "",
    reference_standard_filter_value: "",
    inspection_method_filter_value: "",
    selected_division_value: -1,
}