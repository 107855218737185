import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import { IAssetAPI, IAssetDescriptionPayLoad } from "../AssetInterface/IAsset";
import axios from 'axios';
import { ILoggedInUser, ISortColumn } from "../../../CommonInterfaces/CommoInterfaces";
import * as CONSTANTS from './../Constants/AssetConstants';


export class AssetAPI implements IAssetAPI {

    private _URL: string;
    private _loggedInUserDetails: ILoggedInUser;


    constructor(URL: string, loggedInUser: ILoggedInUser) {
        this._URL = URL;
        this._loggedInUserDetails = loggedInUser;
    }

    public loadAssetAttributesFormAPI(formId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_FORM_JSON_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?formId=${formId}`)
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error loadAssetAttributesFormAPI", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    public saveAssetAttributesAPI(assetId: number, selectedAssetUserData: any): Promise<any> {

        const formData = new FormData();
        formData.append('checklistData', JSON.stringify(selectedAssetUserData))
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${CONSTANTS.SAVE_ASSET_ATTRIBUTES_URL}/${assetId}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                , formData, config
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error saveAssetAttributesAPI", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    public loadClientsAPI(companyId?: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_LOCATION_OBJECT_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=CLIENT_COMPANY`)
                .concat(companyId ? `&companyId=${companyId}` : ``)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error loadClientsAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public loadSitesAPI(clientId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_LOCATION_OBJECT_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`objectType=SITE&`)
                .concat(`companyId=${clientId}`)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error loadSitesAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public loadAssetDashboardAPI(clientId: number, siteIds?: number[], config?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_ASSET_DASHBOARD_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?`)
                .concat(`companyId=${clientId}`)
                .concat(siteIds ? `&siteId=${siteIds}` : ``),
                // .concat(`&siteId=${siteIds}`),
                config
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error loadAssetDashboardAPI method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getAllAssetTypesAPI(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_ALL_ASSET_TYPES_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?objectType=AssetType`)
            ).then(response => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAllAssetTypesAPI", error.response);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getOpenObservaionsForAsset(assetId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_ALL_OPEN_OBSERVATIONS_FOR_ASSET_URL)
                .concat(`${assetId}`)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then(response => {
                resolve(response);
            }).catch(error => {
                console.error("Error getOpenObservaionsForAsset: ", error.response);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public getAssetDetailsAPI(assetId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_SINGLE_LOCATION_OBJECT_URL.replace("location_id", assetId.toString()))
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
            ).then(response => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAssetDetailsAPI", error.response);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public loadAssetTypeDescriptionAPI(assetId?: number): Promise<any> {

        let promises: Promise<any>[] = [];
        promises.push(this.getAllAssetTypesAPI());
        if (assetId) {
            promises.push(this.getAssetDetailsAPI(assetId));
        }

        return Promise.all(promises);

    }

    public loadAssetObservationsAPI(assetId: number, offset?: number, limit?: number, assetReportSortedColumn?: ISortColumn): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(CONSTANTS.GET_ALL_OBSERVATIONS_FOR_ASSET_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`)
                .concat(`?locationId=${assetId}`)
                .concat(`&offset=${offset}`)
                .concat(`&limit=${limit}`)
                .concat(assetReportSortedColumn ?
                    `&sortColumn=${assetReportSortedColumn.columnName}&sortOrder=${assetReportSortedColumn.sortDesc}` : ``)
            ).then(response => {
                resolve(response);
            }).catch(error => {
                console.error("Error loadAssetObservations", error.response);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }
    public loadAssetObservationsForAsnAPI(assetId: number, offset?: number, limit?: number, assetReportSortedColumn?: ISortColumn,jsessionid?: string): Promise<any> {
        return new Promise((resolve, reject) => {
           console.log(jsessionid);
            axios.get(this._URL
                .concat(CONSTANTS.GET_ALL_OBSERVATIONS_FOR_ASSET_URL)
                .concat(`;jsessionid=${jsessionid}`)
                .concat(`?locationId=${assetId}`)
                .concat(`&offset=${offset}`)
                .concat(`&limit=${limit}`)
                .concat(assetReportSortedColumn ?
                    `&sortColumn=${assetReportSortedColumn.columnName}&sortOrder=${assetReportSortedColumn.sortDesc}` : ``)
            ).then(response => {
                resolve(response);
            }).catch(error => {
                console.error("Error loadAssetObservations", error.response);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    public saveAssetDescriptionAPI(payload: IAssetDescriptionPayLoad): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(CONSTANTS.SAVE_LOCATION_URL)
                .concat(`;jsessionid=${this._loggedInUserDetails.jsessionid}`), payload)
                .then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error saveAssetDescriptionAPI method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    if (error.response && error.response.status === 409) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    _uploadAssetFiles = (id: number, uploadedFiles: any) => {

        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(CONSTANTS.SAVE_ASSET_FILES_URL)
                .concat(`/${id}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``),
                uploadedFiles)
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error _uploadJobFiles method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    _deleteAssetFile = (id: number, deletedFile: any) => {

        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(CONSTANTS.DELETE_ASSET_FILES_URL)
                .concat(`/${id}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``),
                deletedFile)
                .then(responseData => {
                    resolve(responseData.data);
                }).catch(error => {
                    console.error("Error _uploadJobFiles method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

}
