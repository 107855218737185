import { IDivisionData } from "../Interfaces/IDivision";
import { ISelect } from "./../../../../CommonInterfaces/CommoInterfaces";

export const DivisionInitialState = {
  Divisions: [] as IDivisionData[],
  LoadingDivisionDashboard: false,
  LoadingDivisionAddEditPanel: false,

  HasMoreDivisions: true,
  SelectedDivisions: null,

  CurrentDivision: {
    acronym_code: "",
    attribute_form_id: -1,
    description: "",
    id: -1,
    name: "",
    reference: "",
    attribute_form: null as ISelect,
  } as IDivisionData,
  TabIndex: 0,
  hideConfirm: true,
  offset: 0,
  limit: 15,
  alertJSON: {
    hideAlert: true,
    alertHeader: "",
    alertSubText: "",
  },
  ToBeUpdatedStatus: -1,
  PanelHeaderText: "",
  ShowDivisionAddEditPanel: false,

  FormAttributeOptions: [] as ISelect[],
};
